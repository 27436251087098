<!-- View cadastro de secretário(a)s - Responsável pelo cadastro de secretário(a)s. -->

<template>
  <b-container class="secretary" fluid>
    <b-row>
      <b-col cols="6"
        d-flex
        class="vh-100 p-0 d-flex justify-content-center align-items-center">
        <div class="form-side text-left">
          <img src="@/assets/images/eyecare-logo-colored.png" class="logo-eyecare">

          <validation-observer ref="loginForm" v-slot="{ invalid }">
            <p class="title">Definir senha de acesso</p>
            <p class="description" v-if="error === null">
              Olá {{ secretary.name }}, defina sua senha de acesso para
              entrar no EyecareBI da {{ clinicName }}.</p>
            <p class="error-message" v-else>
              <AlertOctagon class="icon"/>
              {{ error }}
            </p>

            <div>
              <div class="form-group">
                <label for="email">E-mail</label>
                <validation-provider
                  mode="lazy"
                  name="e-mail"
                  :rules="{ required: true, email: true }"
                  v-slot="{ errors, dirty }"
                >
                <input
                  autocomplete="off"
                  id="email"
                  type="text"
                  v-model="secretary.email"
                  class="form-control"
                  placeholder="Digite seu email"
                  :class="{
                    error:
                      secretary.email.length > 0 &&
                      errors.length > 0 &&
                      dirty
                  }"
                />
                </validation-provider>
              </div>

              <div v-if="!userExists" class="form-group">
                <label for="password">Senha</label>
                <validation-provider
                  name="senha"
                  rules="required|min:8|password_strength"
                  v-slot="{ errors, dirty }"
                  vid="password"
                >
                  <div class="input-password">
                    <input
                      id="password"
                      :type="!showPassword ? 'password' : 'text'"
                      v-model="password"
                      class="form-control"
                      :class="{error: errors.length > 0 && dirty }"
                    />
                    <EyeSlash
                      class="eye"
                      v-if="showPassword == false"
                      @click="showPassword = !showPassword"
                    />
                    <Eye
                      class="eye"
                      v-if="showPassword == true"
                      @click="showPassword = !showPassword"
                    />
                  </div>
                  <span
                    class="error-message"
                      v-if="errors.length > 0 && dirty"
                      >{{ errors[0] }}
                    </span>
                </validation-provider>
              </div>

              <div v-if="!userExists" class="form-group">
                <label for="repeatPassword">Confirmar senha</label>
                <validation-provider
                  name="senha"
                  rules="required|password:@password"
                  v-slot="{ errors, dirty }"
                >
                  <div class="input-password">
                    <input
                      id="repeatPassword"
                      :type="!showPassword ? 'password' : 'text'"
                      v-model="password_confirmation"
                      class="form-control"
                      :class="{ error: errors.length > 0 && dirty }"
                    />
                    <EyeSlash
                      class="eye"
                      v-if="showPassword == false"
                      @click="showPassword = !showPassword"
                    />
                    <Eye
                      class="eye"
                      v-if="showPassword == true"
                      @click="showPassword = !showPassword"
                    />
                  </div>
                    <span
                      class="error-message"
                      v-if="errors.length > 0 && dirty"
                      >{{ errors[0] }}
                    </span>
                </validation-provider>
              </div>
              <div class="form-group">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    id="Term"
                   />
                  <span :class="`checkmark`">
                    <Check />
                  </span>
                  Aceitar <a class="underline" href="https://eyecarehealth.com.br/termos-e-politicas/termos-de-uso" target="_blank">termos de uso</a> e <a class="underline" href="https://eyecarehealth.com.br/termos-e-politicas/politica-de-privacidade" target="_blank">políticas de privacidade</a>
                </label>
              </div>

              <b-button block variant="primary" :disabled="invalid" @click="acceptInvitation">Salvar e fazer Login</b-button>
            </div>
          </validation-observer>
        </div>
      </b-col>
      <b-col cols="6" class="vh-100 p-0 carousel-side position-relative">
        <carousel class="vh-100" :autoplay="true" :loop="true" :autoplayTimeout="3000" :perPage="1" :paginationEnabled="true" :paginationSize="8" :paginationColor="'#A4B1DF'" :paginationPadding="8" :paginationActiveColor="'#FFFFFF'">
          <slide :style="{ 'background-image': 'url('+require('@/assets/images/carousel-img.png')+')'}">
            <div class="container">
              <p class="title">Olá, {{ secretary.name }}</p>
              <p class="text">Estamos felizes em ter você no time da {{ clinicName }}!</p>
            </div>
          </slide>
        </carousel>

        <img src="@/assets/images/eyecare-logo-white.png" class="logo-eyecare" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AlertOctagon from '@/assets/icons/alert-octagon.svg'
import Eye from '@/assets/icons/eye.svg'
import Check from '@/assets/icons/check.svg'
import EyeSlash from '@/assets/icons/eye-slash.svg'

export default {
  name: 'Login',
  components: {
    AlertOctagon,
    Check,
    Eye,
    EyeSlash
  },
  async created() {
    const isLoading = this.$loading.show()
    try {
      await this.checkInvitationCode()
      await this.checkIfUserExists()
    } catch(err) {
      this.$router.push('/login')
    } finally {
      isLoading.hide()
    }
  },
  data () {
    return {
      userExists: false,
      showPassword: false,
      secretary: {
        name: '',
        email: ''
      },
      clinicName: '',
      email: '',
      password: '',
      password_confirmation: '',
      error: null
    }
  },
  methods: {
    async checkInvitationCode() {
      try {
        const res = await this.api.checkInvitationCode({
          code: this.$route.params.invitationCode,
          type: 'SECRETARY_INVITED'
        });
        const resData = res.data
        this.secretary = resData.person
        this.clinicName = resData.clinic
      } catch (err) {
        console.log('Err', err)
      }
    },
    async checkIfUserExists() {
      const res = await this.api.userExists(this.secretary.email);
      this.userExists = res.data.userExists
    },
    acceptInvitation () {
      const checkbox = document.getElementById('Term');

      if(!checkbox.checked){
        this.$toast.warning('Necessário aceitar termos e políticas de uso')
        return
      }

      const inviteData = {
        invitationCode: this.$route.params.invitationCode,
        password: this.password,
        password_confirmation: this.password_confirmation
      }

      const isLoading = this.$loading.show()
      this.api.acceptInvite(inviteData)
        .then(() => {
          this.$router.push('/login');
          this.$toast.success('Salvo com sucesso')
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    }
  }
}
</script>

<style lang="scss">
  .secretary {
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;

    .form-side {
      width: 70%;

      .logo-eyecare {
        width: 122px;
      }

      .title {
        font-family: 'Red Hat Display';
        font-weight: 700;
        font-size: 32px;
        line-height: 40.32px;
        color: var(--dark-blue);
        margin: 80px 0 20px 0;
      }

      .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--type-active);
        margin-bottom: 48px;
      }

      .error-message {
        background-color: #FEE8E6;
        border-radius: 8px;
        padding: 16px 26px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        margin: 4px 0 40px;

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 25px;
          stroke: var(--states-error);
        }
      }

      .mb-40 {
        margin-bottom: 40px;
      }

      .mt-48 {
        margin-top: 48px;
      }

      button {
        font-size: 18px;
        font-weight: 600;
        color: var(--neutral-000);
        padding: 16px;
        border-radius: 8px;
        line-height: 24px;
      }
    }
  }

  .carousel-side {
    .logo-eyecare {
      width: 82px;
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .underline {
      text-decoration: underline;
    }

  .VueCarousel {
    .VueCarousel-wrapper {
      height: 100%;

      .VueCarousel-inner {
        height: 100% !important;

        .VueCarousel-slide {
          background-size: cover;

          .container {
            position: absolute;
            bottom: 210px;
            padding: 0 125px;

            .title {
              font-family: 'Red Hat Display';
              font-weight: 700;
              font-size: 24px;
              line-height: 30.24px;
              color: var(--neutral-000);
            }

            .text {
              font-weight: 400;
              font-size: 18px;
              color: var(--neutral-100);
              margin-top: 24px;
            }
          }
        }
      }
    }

    .VueCarousel-pagination {
      position: absolute;
      bottom: 160px;

      .VueCarousel-dot {
        transition: all 500ms;
      }

      .VueCarousel-dot--active {
        width: 16px !important;
        border-radius: 16px !important;
      }
    }
  }
</style>
