import { customBeforeEnter } from '@/router'; 

const moduleRoute = {
    path: '/agenda/configuracoes',
    component: () => import('@/views/Home'),
    beforeEnter: (to, from, next) => customBeforeEnter('Configurações da agenda', to, from, next),
    children: [
        { 
            path: '', 
            component: () => import('./views/Home.vue'),
        },
    ]
}

export default router => { 
    router.addRoute(moduleRoute)
}