import { isNumber } from 'lodash';

export const parseMoneyToNumber = money => {
  if (!money) return 0;
  if(isNumber(money)) return money;
  return Number(money.replace(/[^0-9,]+/g,'').replace(',', '.'));
}

export const parseNumberToMoney = number => {
  if (isNaN(number)) {
    number = 0;
  }
  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formatter.format(number);
}
