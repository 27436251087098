import Vue from 'vue'

const getStatuses = async (context, filters) => {
  try {

  } catch(err) {
    Vue.prototype.$toast.error(err.message)
  }
}

export default {
  getStatuses,
}