import { updateAttendanceForm } from '@/utils/attendanceFormHelper'

const store = {
  namespaced: true,
  state: () => ({
    id: 'oculos',
    name: 'Óculos',
    opened: true,
    showing: true,
    completed: false,
    multiple: false,
    scopes: ['ATTENDANCE', 'SURGERY', 'ESSILOR_ATTENDANCE'],
    fields: {
      adicaoDireito: null,
      adicaoEsquerdo: null,
      olhoDireitoEsferico: null,
      olhoDireitoCilindrico: null,
      olhoDireitoEixo: null,
      olhoDireitoDP: null,
      olhoDireitoAdicao: null,
      olhoEsquerdoEsferico: null,
      olhoEsquerdoCilindrico: null,
      olhoEsquerdoEixo: null,
      olhoEsquerdoDP: null,
      olhoEsquerdoAdicao: null,
      orientacao: null,
      usarValoresRefracao: null,
      materiaPrima: null,
      tratamentoLente: [],
      tipoLente: null,
      marcaRecomendada: null,
      somaAutomatica: null,
      protocols: [],
      patientData: null,
      incluirLentesPrismaticas: false,
      incluirDistanciaVertex: false,
      olhoDireitoDistanciaVertex: null,
      olhoEsquerdoDistanciaVertex: null,
      olhoDireitoDioptriasPrismaticas: null,
      olhoEsquerdoDioptriasPrismaticas: null,
      olhoDireitoBasePrismaticas: null,
      olhoEsquerdoBasePrismaticas: null,
    }
  }),
  mutations: {
    handleProps: (state, { key, value }) => {
      state[key] = value
    },
    handleFields: (state, { key, value }) => {
      state.fields[key] = value
    },
    checkComplete: state => {
      state.completed = Object.keys(state.fields).some(key => state.fields[key])
    }
  },
  getters: {
    canOpenProtocolsModal: state => {
      return state.opened && state.showing
    }
  },
  actions: {
    handleProps(context, { key, value }) {
      context.commit('handleProps', { key, value })

      if(context?.rootState?.attendance?.attendance?.id && ['showing' , 'opened'].includes(key)){
        const form = context?.rootGetters['attendance/form/getSelectedForm']
        const forms = form.forms
        const index = forms?.findIndex(form => {
          return form.id === context.state.id
        })
  
        if(index && index !== -1){
          forms[index][key] = value
          form.forms = forms
          updateAttendanceForm(context?.rootState?.attendance?.attendance?.attendance_form?.id, {forms: forms})
          context.dispatch(`attendance/form/updateAttendanceForms`, form, { root: true })
        }
      }
    },
    handleFields(context, { key, value }) {
      context.commit('handleFields', { key, value })
      context.commit('checkComplete')
    },
    changeFieldsByRefraction(context, { suffix, checked, fields }) {
      const newFields = context.state.fields
      Object.keys(fields).map(key => {
        const glassKey = key.replace(suffix, '')
        if (newFields[glassKey] !== undefined) {
          newFields[glassKey] = checked ? fields[key] : null
        }
      })
      newFields.usarValoresRefracao = checked ? suffix : null
      context.commit('handleProps', { key: 'fields', value: newFields })
      context.dispatch('attendance/form/updateMedicalRecord', context.state, { root: true })
    }
  },
}

export default store
