/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'

import router from './router'
import Api from './utils/api'
import Helpers from './utils/helpers'
import EventHub from './utils/EventHub'
import { vCan, vShowFeature, vShowProduct } from './utils/directives'

import { showFeature } from '@/utils/permissionsHelp'

import store from './store'

import VueCarousel from 'vue-carousel'
import VueCalendar from 'v-calendar'
import VueMeta from 'vue-meta'

import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { extendMoment } from 'moment-range'
import draggable from "vuedraggable";

import dayjs from 'dayjs'

import 'dayjs/locale/pt-br'

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
// eslint-disable-next-line camelcase
import pt_BR from 'vee-validate/dist/locale/pt_BR'

import { cpf, cnpj } from 'cpf-cnpj-validator'

import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueTheMask from 'vue-the-mask'

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/pt-br'

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import vClickOutside from 'v-click-outside'

import VueSvgGauge from 'vue-svg-gauge'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import Notifications from 'vue-notification'

import ToggleButton from 'vue-js-toggle-button'

import money from 'v-money'

import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

import pdf from 'vue-pdf'

import infiniteScroll from 'vue-infinite-scroll'

import VueCalendarHeatmap from 'vue-calendar-heatmap'
import VuejsHeatmap from 'vuejs-heatmap'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import VueSweetalert2 from 'vue-sweetalert2';

import Pusher from 'pusher-js';

import introJs from 'intro.js'
import "intro.js/minified/introjs.min.css";
import i18n from './i18n'

Pusher.logToConsole = process.env.NODE_ENV == 'local';
var pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
  cluster: 'mt1'
});

import * as Sentry from "@sentry/vue";
// import { ReportingObserver as ReportingObserverIntegration } from "@sentry/integrations";

const domains = [
  "eyecarebi-dev.eyecarehealth.com.br",
  "eyecarebi-staging.eyecarehealth.com.br",
  "eyecarebi.eyecarehealth.com.br"
]

if (process.env.VUE_APP_URL.includes(domains)) {
  Sentry.init({
    Vue,
    dsn: "https://b1b01a9648d34afda648381066877e97@o4504893518708736.ingest.sentry.io/4504894873927680",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    trackComponents: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.8,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5,
  });
}

// Vue.use(signatureForm)
// Vue.use(signatureStartForm)
Vue.use(VueCarousel)
Vue.use(VueCalendar, {
  componentPrefix: 'vc'
})
Vue.use(BootstrapVue)
Vue.use(VueTheMask)
Vue.use(DatePicker)
Vue.use(PerfectScrollbar)
Vue.use(vClickOutside)
Vue.use(VueSvgGauge)
Vue.use(Loading, {
  canCancel: true,
  enforceFocus: false,
})
Vue.use(Notifications)
Vue.use(ToggleButton)
Vue.use(money, { decimal: ',', thousands: '.', prefix: 'R$ ', precision: 2 })
Vue.use(Viewer)
Vue.use(infiniteScroll)
Vue.use(VueMeta)
Vue.use('Encryption')
Vue.use(Toast, {
  maxToasts: 5,
  newestOnTop: true,
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
});
Vue.use(moment)
Vue.use(VueSweetalert2);
import 'sweetalert2/dist/sweetalert2.min.css';

// Vue.use(PDFLib)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.component('multiselect', Multiselect)

Vue.component('VueCropper', VueCropper)

Vue.component('pdf', pdf)
Vue.component('draggable', draggable)

Vue.use(VueCalendarHeatmap)
Vue.use(VuejsHeatmap)
// A chamada da biblioteca foi comentada porquê não está sendo utilizada no projeto.
// Vue.use(VueApexCharts);
// Vue.component("apexchart", VueApexCharts);
import draggableButton from "vue-draggable-button";
Vue.use(draggableButton);

// Validation Rules
for (const rule in rules) {
  extend(rule, {
    ...rules[rule],
    message: pt_BR.messages[rule]
  })
}

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'As senhas digitadas não são iguais'
})

extend('diff', {
  params: ['target'],
  validate(value, { target }) {
    return value !== target
  },
  message: 'A nova senha deve ser diferente da senha atual'
})

extend('cpf', {
  validate(value, _args) {
    return cpf.isValid(value)
  },
  message: 'O CPF informado é inválido'
})

extend('cnpj', {
  validate(value, _args) {
    return cnpj.isValid(value)
  },
  message: 'O CNPJ informado é inválido'
})

extend('date_format', {
  validate(value, args) {
    const date = moment(value, args[0])

    return date.isValid()
  },
  message: 'A data informada é inválida'
})

extend('password_strength', {
  message:
    'A senha deve conter pelo menos: 1 letra maiúscula, 1 letra minúscula, 1 número e um caractere especial (Ex ! @ # & $ etc)',
  validate: value => {
    // eslint-disable-next-line no-useless-escape
    var strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    )
    return strongRegex.test(value)
  }
})

Vue.config.productionTip = false

extendMoment(moment)
momentDurationFormatSetup(moment)
Vue.prototype.moment = moment
Vue.prototype.moment.locale('pt-BR')

dayjs.locale('pt-br')

Vue.prototype.dayjs = dayjs

Vue.prototype.api = Api
Vue.prototype.helpers = Helpers
Vue.prototype.eventHub = EventHub
Vue.prototype.pusher = pusher;
Vue.prototype.introJs = introJs

Vue.directive('can', vCan)
Vue.directive('show-feature', vShowFeature)
Vue.directive('show-product', vShowProduct)


import { registerModules } from './register-modules'
import financialModule from './modules/financial'
import featureRequests from './modules/featureRequests'
import bvPendingRequestsModule from './modules/bvPendingRequests'
import newFeaturesModule from './modules/newFeatures'
import nfeModule from './modules/nfe'
import matMedModule from './modules/tiss/matMed'
import manageGuidesModule from './modules/tiss/manageGuides'
import matMedModuleSus from './modules/sus/matMed'
import manageGuidesModuleSus from './modules/sus/manageGuides'
import schedule from './modules/schedule'
import schedule2 from './modules/schedule2'
import scheduleConfig from './modules/scheduleConfig'
import onlendinglModule from './modules/onlending'
import patientsModule from './modules/patients'
import scheduleSidebar from './modules/scheduleSidebar'
import tablesModule from './modules/tables'
import itemsModule from './modules/items'
import itemHealthPlanProps from './modules/itemHealthPlanProps'
import itemOtherExpenseProps from './modules/itemOtherExpenseProps'
import matMed from './modules/matMed'
import examsModule from './modules/exams'

registerModules({
  bvPendingRequests: bvPendingRequestsModule,
  featureRequests: featureRequests,
  financial: financialModule,
  manageGuides: manageGuidesModule,
  manageGuidesSus: manageGuidesModuleSus,
  matMed: matMedModule,
  matMedSus: matMedModuleSus,
  newFeatures: newFeaturesModule,
  nfe: nfeModule,
  onlending: onlendinglModule,
  schedule: schedule,
  scheduleConfig,
  scheduleSidebar,
  items: itemsModule,
  tables: tablesModule,
  itemHealthPlanProps,
  itemOtherExpenseProps,
  matMed,
  patients: patientsModule,
  scheduleSidebar,
  exams: examsModule
})

new Vue({
  created() {
    document.documentElement.setAttribute('lang', 'pt-BR')
  },
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
