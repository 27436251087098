import Vue from 'vue'
import Vuex from 'vuex'

import mutations from './mutations'
import getters from './getters'
import actions from './actions'

Vue.use(Vuex)
const store = {
  namespaced: true,
  state: () => ({}),
  mutations,
  getters,
  actions,
}

export default store
