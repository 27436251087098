import api from "@/modules/nfe/api"

const updateCNPJForm = (context, payload) => {
  context.commit('updateCNPJForm', payload)
}

const updateIsEdit = (context, payload) => {
  context.commit('updateIsEdit', payload)
}

const updateShowCNPJForm = (context, payload) => {
  context.commit('updateShowCNPJForm', payload)
}

const updateNfModalData = (context, payload) => {
  context.commit('updateNfModalData', payload)
}

const updateNfTableData = (context, payload) => {
  context.commit('updateNfTableData', payload)
}

const updateIsPatientDisabled = (context, payload) => {
  context.commit('updateIsPatientDisabled', payload)
}

const updateProviderModalSelected = (context, payload) => {
  context.commit('updateProviderModalSelected', payload)
}

const updateIsScheduledInvoice = (context, payload) => {
  context.commit('updateIsScheduledInvoice', payload)
}
// company actions
/**
 * Get companies from server
 * @param context
 * @param payload
 * @returns {Promise<Array>}
 */
const getCompanies = async (context, payload) => {
  const {data} = await api.getCompanies(payload)
  context.commit('updateCompanies', data)
  return data
}
/**
 * Update company in synchronicity with both sides
 * @param context
 * @param payload
 * @returns {Promise<void>}
 */
const updateCompany = async (context, payload) => {

  await api.updateCompany(payload.id, payload.data)

  const index = context.state.companies.findIndex(cmp => cmp.id === payload.id)
  if(index > -1) {
    context.commit('updateCompany', {index, data: payload.data})
  }
}
export default {
  updateCNPJForm,
  updateIsEdit,
  updateShowCNPJForm,
  updateNfModalData,
  updateNfTableData,
  updateIsPatientDisabled,
  updateProviderModalSelected,
  updateIsScheduledInvoice,
  getCompanies,
  updateCompany,
}
