<!-- Componente utilizado para montagem de gráficos. Foi implementado em alguma fase do projeto. -->

<script>
import { HorizontalBar } from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  props: ['data', 'options'],
  mounted () {
    this.renderChart(this.data, this.options)
  }
}
</script>

<style type="text/scss">
</style>
