import { base } from '@/utils/api'

export default {
  getSusGuideRequiredFields(clinicId) {
    return base.get(`sus-guides/clinic/${clinicId}/required-fields`);
  },
  updateSusGuideRequiredFields(id, data){
    return base.put(`sus-guides/clinic/required-fields/${id}`, data);
  },
  getBatchHeader (batchId) {
    return base.get(`sus-batch/${batchId}/header`);
  },
  getGuideHeader(guideId) {
    return base.get(`sus/guide/${guideId}/header`);
  },
  getSusGuideByBatchId(batchId, page = 1, filters = []) {
    const config = {
      params: { filters }
    }
    return base.get(`sus/batch/${batchId}?page=${page}`, config);
  },
  getSusGuideItems(susGuideId, filters = []) {
    const config = {
      params: { filters }
    }
    return base.get(`sus/guide/${susGuideId}/items`, config);
  },
  getSusGuideNumbers(batchId) {
    return base.get(`sus-guides/batch/${batchId}/numbers`);
  },
  bulkUpdateSusGuideItems(guideId, data) {
    return base.post(`sus-guides/${guideId}/bulk-update-items`, data);
  },
  bulkUpdateSusGuides(data) {
    return base.post(`sus-guides/bulk-update-situations`, data);
  },
  getBatchResume(batchId) {
    return base.get(`manage-sus-guides/batch/${batchId}/resume`);
  },
  getGuideResume(guideId) {
    return base.get(`manage-sus-guides/sus-guide/${guideId}/resume`);
  },
  updateSusBatchInfo(id, data){
    return base.put(`susBatch/updateInfos/${id}`, data);
  },
  getBatchBillData(batchId) {
    return base.get(`manage-sus-guides/batch/${batchId}/bill-data`);
  },
  finishSusBatch(batchId){
    return base.put(`manage-sus-guides/batch/${batchId}/finish`);
  },
  getSusBatches(clinicId, page, filters = {}) {
    return base.get(`invoice-sus/batches?page=${page}`, {
      params: { clinicId, ...filters }
    });
  },
  getSusBatchesInfo(clinicId, page, filters = []) {
    return base.get(`getBatchesInfo?page=${page}`, {
      params: { clinicId, filters }
    });
  },
  getOtherExpenseGuide(id) {
    return base.get(`sus-guides/other-expense/${id}`);
  },
  getExpensableId(susGuideId) {
    return base.get(`sus-guides/${susGuideId}/expensable`);
  },
  getSusBatchFiles(clinicId, batchId) {
    return base.get(`susBatchFile`, {
      params: { clinicId, susBatchId: batchId }
    });
  },
  uploadSusBatchFile(data) {
    return base.post(`susBatchFile`, data);
  },
}