<template>
  <div class="clinic-health-plan">
    <div class="body d-flex flex-column">
      <div class="top-container">
        <Breadcrumbs :options="breadcrumbs" />
        <b-button variant="primary" class="float-right" @click="saveHealthPlan" :disabled="!canSave || waitingInvoicingId">
          Salvar
        </b-button>
      </div>
      <Tab
        ref="health_plans_tabs"
        :index="currentTabIndex"
        :keys="tabs"
        @onChange="onChangeTab"
        :fontSize="'14px'"
        :tabPadding="'10px'"
      >
      <div class="w-100 general" v-if="isCurrentTab('dados-gerais')">
        <div class="d-flex">
          <div class="div-logo-healthplan">
            <HealthPlanImage
              :logo="clinicHealthPlanForm.logo"
              @changeFile="changeFile"
            />
          </div>
          <div class="col-10">
            <div class="dex-fl flex-row healthPlan">
              <div class="d-flex flex-column flex-fill">
                <b-row class="mt-2">
                  <b-col cols="9">
                    <div class="form-group">
                      <label for="name">Nome do convênio</label>
                      <multiselect
                        id="name"
                        v-model="clinicHealthPlanForm.health_plan"
                        :options="healthPlans"
                        track-by="id"
                        label="fantasy_name"
                        :showLabels="false"
                        :internal-search="false"
                        @search-change="debounceHealthPlans"
                        placeholder="Pesquise pelo nome ou código do convênio"
                        class="search-mode with-border"
                        :disabled="disabledGeneralData"
                      >
                        <template slot="caret">
                          <div class="search">
                            <Search />
                          </div>
                        </template>
                        <template slot="noOptions">Pesquise pelo nome ou código do convênio</template>
                        <template v-if="props.search && !healthPlans.filter(el => el.fantasy_name ? el.fantasy_name.toLowerCase() === props.search.toLowerCase() : false).length" slot="noResult" slot-scope="props">
                          <li @click="addNewHealthPlan(props.search)">
                            <div class="multiselect__option new-patient">
                              <Plus class="plus-icon" />
                              Cadastrar novo convênio
                              <span class="health-plan-name"
                                >"{{ props.search }}"</span
                              >
                              como
                              <span class="health-plan-type">PARTICULAR</span>
                            </div>
                          </li>
                        </template>
                      </multiselect>
                      <div
                        v-if="validated && !clinicHealthPlanForm.health_plan.id"
                        class="custom-invalid-feedback"
                      >
                        Campo obrigatório
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col cols="5">
                    <div class="form-group">
                      <label for="ans">{{ labelAns }}</label>
                      <b-form-input
                        v-if="clinicHealthPlanForm.health_plan"
                        id="ans"
                        autocomplete="off"
                        type="text"
                        v-model="clinicHealthPlanForm.health_plan.ans_code"
                        :disabled="ansDisabled"
                        @input="ansInputDebounce"
                      />
                    </div>
                  </b-col>
                  <b-col cols="4">
                    <div class="form-group">
                      <label for="returnBy">Retorno em até</label>
                      <b-input-group append="dias">
                        <input
                          ref="healthPlanReturn"
                          autocomplete="off"
                          id="returnBy"
                          v-model="clinicHealthPlanForm.return"
                          type="text"
                          class="form-control input-append"
                        />
                      </b-input-group>
                      <div
                        v-if="validated && (!clinicHealthPlanForm.return && !clinicHealthPlan?.health_plan?.is_sus_health_plan)"
                        class="custom-invalid-feedback"
                      >
                        Campo obrigatório
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-2" v-if="!clinicHealthPlan?.health_plan?.is_sus_health_plan">
                  <b-col>
                    <div
                      class="form-group"
                      v-if="showInvoicingTiss()"
                      :key="reload"
                    >
                      <toggle-button
                        id="activateTiss"
                        class="autoFillBtn mb-1"
                        :width="55"
                        :height="30"
                        v-model="invoicingTissActive"
                        :color="{ checked: '#00C773' }"
                        :disabled="disableActivateTiss || disabledGeneralData"
                      />
                      <label for="activateTiss" class="bold-text ml-2">
                        Ativar faturamento TISS
                      </label>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-2" v-if="clinicHealthPlan?.health_plan?.is_sus_health_plan">
                  <b-col>
                    <div
                      class="form-group"
                      :key="reload"
                    >
                      <toggle-button
                        id="activateSUS"
                        class="autoFillBtn mb-1"
                        :width="55"
                        :height="30"
                        v-model="invoicingSusActive"
                        :color="{ checked: '#00C773' }"
                        :disabled="!showInvoicingSus()"
                      />
                      <label for="activateSUS" class="bold-text ml-2">
                        Ativar faturamento SUS
                      </label>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
        
        <FeatureRequestFatSus
          v-if="!showInvoicingSus() && clinicHealthPlan?.health_plan?.is_sus_health_plan"
          :clinicHealthPlan="clinicHealthPlan"
        />
      </div>
      <div v-if="isCurrentTab('planos')" class="m-3">
        <b-row>
          <b-col cols="9">
            <div class="form-group">
              <label for="plan">Nome ou número do plano</label>
              <validation-provider
                name="nome ou número do plano"
                rules="required"
              >
                <multiselect
                  id="plan"
                  v-model="clinicPlanForm.plan"
                  :options="plans"
                  track-by="id"
                  label="name"
                  :disabled="clinicHealthPlanForm.health_plan.id === null"
                  :showLabels="false"
                  :allowEmpty="false"
                  :internal-search="false"
                  @search-change="debouncePlans"
                  :placeholder="
                    !clinicHealthPlanForm.health_plan.id
                      ? 'Selecione um convênio primeiro'
                      : ''
                  "
                  class="search-mode with-border"
                >
                  <template slot="caret">
                    <div class="search">
                      <Search />
                    </div>
                  </template>

                  <template slot="noOptions">Digite para pesquisar um plano</template>
                  <template
                    v-if="
                      props.search &&
                      !plans.filter(
                        el =>
                          el.name.toLowerCase() === props.search.toLowerCase()
                      ).length
                    "
                    slot="noResult"
                    slot-scope="props"
                  >
                    <li @click="addNewPlan(props.search)">
                      <div class="multiselect__option new-patient">
                        <Plus class="plus-icon" /> Cadastrar novo plano
                        <span class="health-plan-name">"{{ props.search }}"</span> em
                        <span class="health-plan-type">{{ clinicHealthPlanForm.health_plan.fantasy_name }}</span>
                      </div>
                    </li>
                  </template>
                </multiselect>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="2">
            <div class="form-group">
              <label>Retorno em até</label>
              <validation-provider name="retorno em até" rules="required">
                <b-input-group append="dias">
                  <input
                    ref="planReturn"
                    autocomplete="off"
                    v-model="clinicPlanForm.return"
                    type="text"
                    class="form-control input-append"
                    :disabled="clinicHealthPlanForm.health_plan === null"
                  />
                </b-input-group>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="1">
            <b-button
              block
              variant="primary"
              class="btn-add"
              :disabled="!this.clinicPlanForm.plan"
              @click="addClinicPlan"
            >
              Adicionar
            </b-button>
          </b-col>
        </b-row>
          <table class="table mt-4">
            <thead>
              <tr>
                <th width="60%">Nome do plano</th>
                <th>Número do plano</th>
                <th>Retorno</th>
                <th width="24"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(clinicPlan, index) of clinicHealthPlanForm.plans"
                :key="index"
              >
                <td>{{ clinicPlan.plan.name }}</td>
                <td>{{ clinicPlan.plan.ans_code }}</td>
                <td>{{ clinicPlan.return }} dias</td>
                <td>
                  <div class="more-with-menu">
                    <MoreVertical class="more-icon" />
                    <div class="menu">
                      <b-button variant="link" @click="editPlan(index)">
                        Editar
                      </b-button>
                      <b-button
                        variant="link remove mb-0"
                        @click="removePlan(index)"
                      >
                        Remover
                      </b-button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
      
        </div>
        
        <TableHealthPlanSettings 
          v-if="isCurrentTab('tables-tab')"
          :clinicHealthPlan="clinicHealthPlan"
        />

        <TablePlanSettings 
          v-if="isCurrentTab('tables-plans-tab')"
          :clinicHealthPlan="clinicHealthPlan"
          :clinicHealthPlans="clinicHealthPlanForm"
        />

        <Invoicing
          v-if="isCurrentTab('faturamento-tiss')"
          :invoicingTissSetting="tiss"
          :validated="validated"
          @updateTiss="updateInvoicingTiss"
          @invalidPercentage="setInvalidPercentage"
          @invalid-procedure="invalidProcedure = $event"
          @update-invoice-days="updateInvoiceDays"
        />

        <b-tooltip
          v-if="!invoicingTissId"
          target="table-tab"
          placement="top"
          triggers="hover"
        >
          Ative o faturamento TISS e salve as alterações no convênio para habilitar esta aba.
        </b-tooltip>

        <Layouts
          v-if="isCurrentTab('layouts')"
          :clinicHealthPlan="clinicHealthPlan"
        />

        <Information
          v-if="isCurrentTab('informacoes')"
          :tiss="tiss"
          :validated="validated"
          @updateTiss="updateInvoicingTiss"
          @invalidSettingsNumbers="setInvalidSettingsNumbers"
        />

        <InformationSus
          v-if="isCurrentTab('informacoes_sus')"
          :sus="sus"
          :healthPlanId="healthPlanId"
          :validated="validated"
          @update-information-sus="updateInformationSus"
        />

        <Professionals
          :validated="validated"
          :tiss="tiss"
          :doctorsProps="doctors"
          @update-doctors="updateProfessionalDoctors"
          v-if="isCurrentTab('profissionais')"
        />

        <ProfessionalsSus
          :validated="validated"
          :doctorsProps="doctors"
          @update-doctors-sus="updateProfessionalDoctorsSus"
          v-if="isCurrentTab('profissionais_sus')"
        />

        <Settings
            v-if="isCurrentTab('configuracoes')"
            :clinicHealthPlan="clinicHealthPlan"
            :healthPlanId="healthPlanId"
        />
        <Orientations
          v-if="isCurrentTab('orientations')"
          :healthPlanId="healthPlanId"
          type="health_plan"
        />
      </Tab>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { showInvoicingTiss, showInvoicingSus } from '@/utils/permissionsHelp'
import { getCurrentClinic, userHasPermission } from '@/utils/localStorageManager'
import { mapGetters, mapActions } from 'vuex'
import FeatureRequestFatSus from '@/views/FeatureRequest/FatSusView.vue'

export default {
  components: {
    Tab: () => import('@/components/Tab'),
    Search: () => import('@/assets/icons/search.svg'),
    Plus: () => import('@/assets/icons/plus.svg'),
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
    HealthPlanImage: () => import('@/components/HealthPlanImage'),
    TableHealthPlanSettings: () => import('@tables/layouts/TableHealthPlanSettings'),
    TablePlanSettings: () => import('@tables/layouts/TablePlanSettings'),
    Invoicing: () => import('@/components/Tiss/Invoicing'),
    Information: () => import('@/components/Tiss/Information'),
    InformationSus: () => import('@/components/Sus/Information'),
    Professionals: () => import('@/components/Tiss/Professionals'),
    ProfessionalsSus: () => import('@/components/Sus/Professionals'),
    Layouts: () => import('@/layouts/InvoicingTiss/Layouts/Settings'),
    Breadcrumbs: () => import('@/components/General/Breadcrumbs'),
    Orientations: () => import('@/views/Configs/OrientationsView'),
    Settings: () => import('@/components/Tiss/HealthPlanRequiredTissFields.vue'),
    FeatureRequestFatSus
  },
  props: {
    loadHealthPlans: Function
  },
  mounted() {
    this.getTabByParams()
  },
  data() {
    return {
      loading: false,
      currentTabIndex: 0,
      invoice_days: '',
      invoicingTissActive: null,
      invoicingSusActive: null,
      clinic: getCurrentClinic(),
      currentTab: 'dados-gerais',
      validated: false,
      file: null,
      logoTemporaryUrl: null,
      clinicHealthPlanForm: this.getDefaultClinicHealthPlanForm(),
      clinicPlanForm: this.getDefaultClinicPlanForm(),
      plans: [],
      healthPlans: [],
      doctors: [],
      doctorsValue: [],
      invoicingTissId: null,
      tiss: this.getDefaultTissForm(),
      sus: this.getDefaultSusForm(),
      ignoreValidate: [
        'id',
        'clinic_health_plan_id',
        'surgeon',
        'first_assistant',
        'second_assistant',
        'third_assistant',
        'fourth_assistant',
        'instrumentator',
        'anesthetist',
        'assistant_anesthetist',
        'consultant',
        'perfusionist',
        'pediatrist',
        'assistant_sadt',
        'clinical',
        'intensivist',
        'honorary_bilateral',
        'honorary_same_way',
        'honorary_different_way',
        'operational_cost_bilateral',
        'operational_cost_same_way',
        'operational_cost_different_way',
        'operational_cost_monocular_bilateral',
        'anesthetic_port_bilateral',
        'anesthetic_port_same_way',
        'anesthetic_port_different_way',
        'm2_bilateral',
        'm2_same_way',
        'm2_different_way',
        'other_urgency',
        'other_dhe',
        'other_apartment',
        'item_id',
        'porte',
        'uco',
        'm2_filme',
        'attendance_character',
        'attendance_type',
        'accident_indication',
        'consultation_type',
        'procedure_description',
        'tiss_table_procedure_id', 
        'tiss_table_procedures',
        'health_agency_acronym'
      ],
      tiss_tab: [
        'payment_method_id',
        'bank_account_id',
        'tiss_table_procedure_id',
        'hired_executant_code',
        'hired_executant_name',
        'CNES',
        'invoice_create_days',
        'estimate_payment_time',
        'estimate_glosses_payment_time'
      ],
      InformationKeys: [
        'XML_version',
        'current_batch_number',
        'current_invoice_number',
        'current_guide_number'
      ],
      invalidPercentage: false,
      invalidSettingsNumbers: false,
      tissProcedures: [],
      canSave: true,
      oldAns: null,
      invalidProcedure: false,
      hideSaveTabs: [3, 4],
      healthPlanId: this.$route.params.id,
      clinicHealthPlan: {},
      reload: 0,
      debounceHealthPlans: null,
      debouncePlans: null,
      ansInputDebounce: null,
      isWaiting: true,
    }
  },
  created() {
    this.debounceHealthPlans = debounce(this.searchHealthPlan, 500)
    this.debouncePlans = debounce(this.searchPlan, 500)
    this.ansInputDebounce = debounce(this.ansInput, 800);
  },
  computed: {
    ...mapGetters('tiss', ['getHealthPlanFields']),
    breadcrumbs() {
      return [
        { label: 'Configurações da clínica', onClick: () => this.$router.push('/configuracoes-da-clinica') },
        { label: `Convênio ${this.clinicHealthPlanForm?.health_plan?.fantasy_name || ''}`, onClick: () => this.$router.push('/configuracoes-da-clinica/convenio') },
        { label: this.tabs ? this.tabs[this.currentTabIndex]?.label ??  this.tabs[this.currentTabIndex] : '', value: null },
      ]
    },
    waitingInvoicingId() {
      return Boolean(this.clinicHealthPlan?.id && this.invoicingTissActive && this.isWaiting)
    },
    ansDisabled() {
      return this.clinicHealthPlanForm?.health_plan?.type !== 'particular' && !this.clinicHealthPlan?.health_plan?.is_sus_health_plan && !this.clinicHealthPlanForm?.health_plan?.clinic_id
    },
    labelAns() {
      return this.ansDisabled ? 'Registro ANS' : 'Código/Registro'
    },
    showOrientationsTab() {
      return (userHasPermission('OrConv1') || userHasPermission('OrConv2'))
    },
    disableActivateTiss() {
      return !this.clinicHealthPlanForm.health_plan?.id || !this.canSave
    },
    disabledGeneralData() {
      return Boolean(this.clinicHealthPlan?.health_plan?.system_health_plan)
    },
    tabs() {
      const tabs = [
        {
          label: 'Dados gerais',
          id: 'dados-gerais'
        },
        {
          label: 'Tabelas',
          disabled: false,
          hidden: false,
          id: 'tables-tab'
        },
        {
          label: 'Planos',
          id: 'planos',
          hidden: this.clinicHealthPlan?.health_plan?.is_sus_health_plan, // não exibir se for convênio SUS
        },
        {
          label: 'Tabelas por plano',
          disabled: false,
          hidden: this.clinicHealthPlan?.health_plan?.is_sus_health_plan, // não exibir se for convênio SUS
          id: 'tables-plans-tab'
        },
        {
          label: 'Faturamento TISS',
          disabled: !this.canSave || this.loading,
          hidden: !this.invoicingTissActive,
          id: 'faturamento-tiss'
        },
        {
          id: 'informacoes',
          label: 'Informações',
          disabled: !this.canSave || this.loading,
          hidden: !this.invoicingTissActive,
        },
        // { label: 'Tabela', disabled: !this.invoicingTissId || !this.canSave  || this.loading, hidden: !this.invoicingTissActive, id: 'table-tab' },
        {
          label: 'Layouts',
          disabled: !this.canSave,
          hidden: !this.invoicingTissActive,
          id: 'layouts'
        },
        // { label: 'Troca de informações', disabled: !this.canSave || this.loading, hidden: !this.invoicingTissActive },
        {
          label: 'Profissionais',
          disabled: !this.canSave || this.waitingInvoicingId,
          hidden: !this.invoicingTissActive,
          id: 'profissionais'
        },
        {
          label: 'Configurações',
          disabled: !this.canSave || this.waitingInvoicingId,
          hidden: !this.invoicingTissActive,
          id: 'configuracoes'
        },
      ]

      if (this.clinicHealthPlan?.health_plan?.is_sus_health_plan) {
        tabs.push({
          label: 'Profissionais',
          // disabled: !this.canSave || this.waitingInvoicingId,
          hidden: !this.invoicingSusActive,
          id: 'profissionais_sus'
        });
        
        tabs.push({
          id: 'informacoes_sus',
          label: 'Informações',
          // disabled: !this.canSave || this.loading,
          hidden: !this.invoicingSusActive,
        });
      }

      if (this.showOrientationsTab) {
        tabs.push({
          label: 'Orientações',
          disabled: !this.canSave || this.waitingInvoicingId,
          id: 'orientations'
        });
      }

      return tabs
    },
  },
  methods: {
    ...mapActions('tiss', ['setHealthPlansFields']),
    showInvoicingTiss,
    showInvoicingSus,
    async ansInput(value) {
      if (this.clinicHealthPlanForm.health_plan.type === 'particular' || this.clinicHealthPlanForm.health_plan.is_sus_health_plan) {
        try {
          if(!this.clinicHealthPlanForm.health_plan.is_sus_health_plan) {
            const ans_code = '0'.repeat(6 - value.length) + value
            await this.updateHealthPlan({ ans_code: ans_code })
          } else {
            await this.updateHealthPlan({ ans_code: value })
          }
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    isCurrentTab(key) {
      return this.tabs[this.currentTabIndex].id === key
    },
    onChangeTab(index) {
      const id = this.$route.params.id
      const tab = this.tabs[index].id
      const url = `/convenio/${id}/${tab}`
      this.currentTabIndex = index

      if (url !== this.$route.path) {
        this.$router.push(url)
      }
    },
    changeFile(file) {
      this.file = file
    },
    setInvalidPercentage(validate) {
      this.invalidPercentage = validate
    },
    setInvalidSettingsNumbers(validate) {
      this.invalidSettingsNumbers = validate
    },
    updateInvoicingTiss(value) {
      this.tiss = value
    },
    updateProfessionalDoctors(value) {
      this.doctors = value
    },
    updateProfessionalDoctorsSus(value) {
      this.doctors = value
    },
    updateInformationSus(value) {
      this.sus = value
    },
    getDefaultClinicHealthPlanForm() {
      return { health_plan: {}, return: null, plans: [] }
    },
    getDefaultClinicPlanForm() {
      return { plan: null, return: null }
    },
    getDefaultTissForm() {
      return {
        id: null,
        payment_method_id: '',
        bank_account_id: '',
        tiss_table_procedure_id: '',

        clinic_health_plan_id: this.$route?.params?.id ?? '',
        invoicing_tiss_setting_id: 0,
        tiss_information_id: 0,
        tiss_required_custom_id: 0,
        tiss_using_guide_type_id: 0,
        tiss_participation_percentage_id: 0,

        provider_code: '',
        hired_executant_code: '',
        hired_executant_name: '',
        CNES: '',
        item: null,
        item_id: null,
        porte: 0,
        uco: 0,
        m2_filme: 31.59,
        surgeon: '',
        first_assistant: '',
        second_assistant: '',
        third_assistant: '',
        fourth_assistant: '',
        instrumentator: '',
        anesthetist: '',
        assistant_anesthetist: '',
        consultant: '',
        perfusionist: '',
        pediatrist: '',
        assistant_sadt: '',
        clinical: '',
        intensivist: '',
        honorary_bilateral: '',
        honorary_same_way: '',
        honorary_different_way: '',
        operational_cost_bilateral: '',
        operational_cost_same_way: '',
        operational_cost_different_way: '',
        operational_cost_monocular_bilateral: '',
        anesthetic_port_bilateral: '',
        anesthetic_port_same_way: '',
        anesthetic_port_different_way: '',
        m2_bilateral: '',
        m2_same_way: '',
        m2_different_way: '',
        other_urgency: '',
        other_dhe: '',
        other_apartment: '',
        attendance_character: '',
        attendance_type: '',
        accident_indication: '',
        consultation_type: '',
        invoice_create_days: '',
        estimate_payment_time: '',
        estimate_glosses_payment_time: '',
        procedure_description: '',

        XML_version: '',
        current_batch_number: '',
        current_invoice_number: '',
        max_batches_per_guide: 1,
        current_guide_number: '',

        date_sp_sadt: false,
        date_hospitalization: false,
        password_sp_sadt: false,
        password_hospitalization: false,
        expiration_date_sp_sadt: false,
        expiration_date_hospitalization: false,
        guide_number_consultation: false,
        guide_number_sp_sadt: false,
        guide_number_hospitalization: false,
        date_time_autorization_sp_sadt: false,
        guide_technique_sp_sadt: false,
        main_guide_number_sp_sadt: false,
        solicitation_date_sp_sadt: false,
        clinic_indication: false,
        purse_expiration_date: false,

        consultation_guide: false,
        sp_sadt_guide: false,
        hospitalization_summary_guide: false,
        hospitalization_request_guide: false,
        fee_guide: false,
        other_expenses_guide: false,
        guides: [],
        tiss_table_procedures: [],

        hide_performer: false,
        hide_performer_item_types: []
      }
    },
    getDefaultSusForm() {
      return {
        id: null,
        clinic_health_plan_id: '',
        name_establishment: '',
        cnaes_code: '',
        current_bpa_guide_number: '',
        current_bpa_batch_number: '',
        max_number_guides_bpa_batch: '',
        current_apac_guide_number: '',
        current_apac_batch_number: '',
        max_number_guides_apac_batc: '',
      }
    },
    async searchHealthPlan(query) {
      try {
        const { data } = await this.api.searchHealthPlan(this.clinic.id, query)
        this.healthPlans = data.filter(hp => hp.id !== 'ed6c5521-8e61-4754-9867-a2ebbb7962a5')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async addNewHealthPlan(name) {
      const data = {
        clinic_id: this.clinic.id,
        type: 'particular',
        ans_code: '',
        cnpj: '-',
        company_name: name,
        fantasy_name: name
      }
      try {
        const res = await this.api.createHealthPlan(data)
        this.healthPlans.push(res.data)
        this.clinicHealthPlanForm.health_plan = res.data
        await this.createOrUpdateHealthPlanBrand()
        this.$refs.healthPlanReturn.focus()
        this.$toast.success('Convênio criado com sucesso')
      } catch (ex) {
        this.$toast.error(ex.message)
      }
    },
    async updateHealthPlan(data) {
      try {
        await this.api.updateHealthPlan(this.clinicHealthPlanForm.health_plan.id, {
          ...data,
          clinic_id: this.clinic.id
        })
      } catch (error) {
        throw new Error(error.message)
      }
    },
    async createOrUpdateHealthPlanBrand(id) {
      if (this.file) {
        const dataForm = new FormData()
        dataForm.append(`file`, this.file)
        dataForm.append(`type`, 'png')
        await this.api.createOrUpdateHealthPlanBrand(id, dataForm)
      }
    },
    async addNewPlan(name) {
      const data = {
        health_plan_id: this.clinicHealthPlanForm.health_plan.id,
        ans_code: '-',
        name
      }
      try {
        const res = await this.api.createPlan(data)
        this.plans.push(res.data)
        this.clinicPlanForm.plan = res.data
        this.$refs.planReturn.focus()
        this.$toast.success('Plano criado com sucesso')
      } catch (ex) {
        this.$toast.error(ex.message)
      }
    },
    async searchPlan(query) {
      try {
        const res = await this.api.searchPlan(
          this.clinicHealthPlanForm.health_plan.id,
          query
        )
        this.plans = res.data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    addClinicPlan() {
      if (!this.clinicPlanForm.return) {
        this.$toast.warning('Informe um retorno válido')
        return
      }
      this.clinicHealthPlanForm.plans.push(this.clinicPlanForm)
      this.clinicPlanForm = this.getDefaultClinicPlanForm()
    },
    editPlan(index) {
      this.clinicPlanForm.plan = this.clinicHealthPlanForm.plans[index].plan
      this.clinicPlanForm.return = this.clinicHealthPlanForm.plans[index].return
      this.removePlan(index)
    },
    removePlan(index) {
      this.clinicHealthPlanForm.plans.splice(index, 1)
    },
    async saveHealthPlan() {
      this.tiss.tiss_table_procedures = this.tissProcedures
      
      if (
        this.isValid() &&
        this.validateTissSettings() &&
        this.validateSusSettings() &&
        this.validateDoctors() && !this.invalidProcedure
      ) {
        const tissSettings = {}
        if (
          this.clinicHealthPlan &&
          this.clinicHealthPlan.invoicing_tiss_setting
        ) {
          tissSettings.invoicing_tiss_setting_id =
            this.clinicHealthPlan.invoicing_tiss_setting.id
        }

        this.setDoctorsValue()
        Object.keys(this.tiss).map(key => {
          if (key === 'guides') {
            this.tiss.guides.find(element => element === 'Guia de Consulta')
              ? (tissSettings.consultation_guide = 1)
              : (tissSettings.consultation_guide = 0)
            this.tiss.guides.find(element => element === 'Guia SP/SADT')
              ? (tissSettings.sp_sadt_guide = 1)
              : (tissSettings.sp_sadt_guide = 0)
            this.tiss.guides.find(
              element => element === 'Guia Resumo de internação'
            )
              ? (tissSettings.hospitalization_summary_guide = 1)
              : (tissSettings.hospitalization_summary_guide = 0)
            this.tiss.guides.find(
              element => element === 'Guia Solicitação de internação'
            )
              ? (tissSettings.hospitalization_request_guide = 1)
              : (tissSettings.hospitalization_request_guide = 0)
            this.tiss.guides.find(element => element === 'Guia de Honorários')
              ? (tissSettings.fee_guide = 1)
              : (tissSettings.fee_guide = 0)
            this.tiss.guides.find(
              element => element === 'Anexo de outras despesas'
            )
              ? (tissSettings.other_expenses_guide = 1)
              : (tissSettings.other_expenses_guide = 0)
          } else {
            tissSettings[key] = this.tiss[key]
          }
        })

        this.$set(tissSettings, 'invoice_create_days', this.invoice_days)
        
        const data = {
          clinic_id: this.clinic.id,
          health_plan_id: this.clinicHealthPlanForm.health_plan.id || null,
          clinic_health_plan_id: this.healthPlanId,
          return: this.clinicHealthPlanForm.return,
          invoicing_tiss_active: this.invoicingTissActive === true ? 1 : 0,
          invoicing_sus_active: this.invoicingSusActive === true ? 1 : 0,
          plans: this.clinicHealthPlanForm.plans.map(clinicPlan => ({
            id: clinicPlan.id || null,
            clinic_health_plan_id: clinicPlan.clinic_health_plan_id,
            plan_id: clinicPlan.plan.id,
            return: clinicPlan.return
          })),
          tiss: tissSettings,
          sus: this.sus,
          professionals: this.doctorsValue
        }

        const isLoading = this.$loading.show()
        try {
          let id = null
          if (!this.clinicHealthPlanForm.id) {
            const res = await this.api.createClinicHealthPlan(data)
            id = res.data.id
            await this.findClinicHealthPlan(id)
            this.$toast.success('Convênio adicionado com sucesso')
          } else {
            if(this.getHealthPlanFields.length && this.healthPlanId){
              const payload = {
                clinic_health_plan_id: this.healthPlanId,
                fields: this.getHealthPlanFields
              }
              await this.api.updateHealthPlanRequiredFields(payload).then(() => {
                this.setHealthPlansFields([])
              }).catch(() => {
                this.$toast.error('Erro ao atualizar campos obrigatórios')
              })
            }
            await this.api.updateClinicHealthPlan(this.clinicHealthPlanForm.id, data)
            id = this.clinicHealthPlanForm.id
            await this.findClinicHealthPlan(id)

            this.$toast.success('Convênio atualizado com sucesso')
            await this.$store.dispatch('healthPlans/getAllHealthPlans', null, { root: true })
            await this.$store.dispatch('healthPlans/getTissClinicHealthPlans', null, { root: true })
          }
          await this.createOrUpdateHealthPlanBrand(id)
        } catch (ex) {
          this.$toast.error(ex.message)
        } finally {
          isLoading.hide()
        }
      }
    },
    async findClinicHealthPlan(id) {
      this.loading = true
      this.isWaiting = true
      try {
        const response = await this.api.findClinicHealthPlanToUpdate(id, this.clinic.id)
        if (response.data.invoicing_tiss_setting) {
          this.invoicingTissId = response.data.invoicing_tiss_setting.id
          this.tiss.invoicing_tiss_setting_id = response.data.invoicing_tiss_setting.id
        }
        
        this.clinicHealthPlan = response.data
        
        if(response.data.sus_information_setting){
          this.sus = response.data.sus_information_setting;
          this.sus.name_establishment = this.clinic.name;
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
        this.isWaiting = false
      }
    },
    setDoctorsValue() {
      this.doctorsValue = JSON.parse(JSON.stringify(this.doctors))
      this.doctorsValue.forEach(element => {
        if(element.tiss_professional_setting){
          Object.keys(element.tiss_professional_setting).map(key => {
            if (element.tiss_professional_setting[key]
              && typeof element.tiss_professional_setting[key] === 'object')
                element.tiss_professional_setting[key] = element.tiss_professional_setting[key].value;
          })
        }
        if(element.sus_professional_setting){
          Object.keys(element.sus_professional_setting).map(key => {
            if (element.sus_professional_setting[key]
              && typeof element.sus_professional_setting[key] === 'object')
                element.sus_professional_setting[key] = element.sus_professional_setting[key].value;
          })
        }
      });
    },
    isValid() {
      this.validated = true
      if (
        !this.clinicHealthPlanForm.health_plan.id ||
        (!this.clinicHealthPlan?.health_plan?.is_sus_health_plan && !this.clinicHealthPlanForm.return)
      ) {
        this.$toast.warning('Preenchimento obrigatório dos campos na aba dados gerais')
        this.currentTabIndex = 0
        return false
      }
      return true
    },
    validateTissSettings() {
      let isValid = true
      const errorPage = new Set()
      if (this.invoicingTissActive) {
        Object.keys(this.tiss).map(key => {
          // if (this.invalidSettingsNumbers) {
          //   errorPage.add(4)
          //   this.currentTabIndex = 4
          //   isValid = false
          //   return isValid
          // }
          if (
            !this.ignoreValidate.includes(key) &&
            (this.invalidPercentage ||
              this.tiss[key] === undefined ||
              this.tiss[key] === null ||
              this.tiss[key] === '')
          ) {
            if(this.tiss_tab.includes(key)) errorPage.add(2)
            else if(this.InformationKeys.includes(key)) errorPage.add(3)
            else if(key === 'item') errorPage.add(9)
            isValid = false
          }
        })

        if(!isValid) {
          if(errorPage.has(4)){
            this.$toast.warning('Preenchimento obrigatório dos campos na aba Mat/Med')
            this.currentTabIndex = 4
          }
          if(errorPage.has(3)){
            this.$toast.warning('Preenchimento obrigatório dos campos na aba informações')
            this.currentTabIndex = 5
          }
          if(errorPage.has(2)){
            this.$toast.warning('Preenchimento obrigatório dos campos na aba Faturamento TISS')
            this.currentTabIndex = 4
          }
          if(errorPage.has(9)){
            this.$toast.warning('Preenchimento obrigatório dos campos de procedimento padrão')
            this.currentTabIndex = 4
          }
        }
      }
      return isValid
    },
    validateSusSettings() {
      let isValid = true
      if (this.invoicingSusActive) {
        Object.keys(this.sus).map(key => {
          if (
            !this.ignoreValidate.includes(key) &&
            (this.invalidPercentage ||
              this.sus[key] === undefined ||
              this.sus[key] === null ||
              this.sus[key] === '')
          ) {
            isValid = false
          }
        })

        if(!isValid) {
          this.$toast.warning('Preenchimento obrigatório dos campos na aba Informações')
          this.currentTabIndex = 10
        }
      }
      return isValid
    },
    validateDoctors() {
      let isValid = true
      if (this.doctors.length > 0 && this.invoicingTissActive) {
        this.doctors.map(doctor => {
          if (doctor.active) {
            Object.keys(doctor.tiss_professional_setting).map(key => {
              if (
                doctor.tiss_professional_setting[key] === undefined ||
                doctor.tiss_professional_setting[key] === null ||
                doctor.tiss_professional_setting[key] === ''
              ) {
                this.$toast.warning('Preenchimento obrigatório dos campos na profissionais')
                this.currentTabIndex = 5
                isValid = false
              }
            })
          }
        })
      }
      return isValid
    },
    async getTissSettings() {
      if (
        this.clinicHealthPlan &&
        this.clinicHealthPlan?.invoicing_tiss_setting
      ) {
        try {
          const response = await this.api.getInvoicingTissSetting(
            this.clinicHealthPlan.invoicing_tiss_setting.id
          )
          Object.keys(this.tiss).map(key => {
            this.tiss.tiss_information_id = response.data.tiss_information.id
            this.tiss.tiss_using_guide_type_id =
              response.data.tiss_using_guide_type.id
            this.tiss.tiss_required_custom_id =
              response.data.tiss_information.tiss_required_custom_field.id

            this.tiss.tiss_participation_percentage_id =
              response.data.tiss_participation_percentage.id

            this.tiss.tiss_participation_percentage_special_case_id =
              response.data.tiss_participation_percentage_special_case.id

            if (response.data[key]) {
              this.tiss[key] = response.data[key]
            } else if (response.data.tiss_information[key]) {
              this.tiss[key] = response.data.tiss_information[key]
            } else if (response.data.tiss_using_guide_type[key]) {
              this.tiss[key] = response.data.tiss_using_guide_type[key]
            } else if (
              response.data.tiss_information.tiss_required_custom_field[key]
            ) {
              response.data.tiss_information.tiss_required_custom_field[key] ===
              1
                ? (this.tiss[key] = true)
                : (this.tiss[key] = false)
            } else if (response.data.tiss_participation_percentage[key]) {
              this.tiss[key] = response.data.tiss_participation_percentage[key]
            } else if (
              response.data.tiss_participation_percentage_special_case[key]
            ) {
              this.tiss[key] =
                response.data.tiss_participation_percentage_special_case[key]
            } else if (this.tiss[key] === response.data.tiss_table_procedures[key]) {
              this.tiss[key] = response.data.tiss_table_procedures[key]
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    updateInvoiceDays(value) {
      this.invoice_days = value
      this.$set(this.tiss, 'invoice_create_days', value)
    },
    getTabByParams() {
      const index = this.tabs.findIndex(
        tab => tab.id === this.$route.params.tab
      )

      this.onChangeTab(index !== -1 ? index : 0)
    }
  },
  watch: {
    clinicHealthPlan: {
      async handler(value) {
        this.invoicingTissId = null
        if (value) {
          this.clinicHealthPlanForm = value
          if (value.invoicing_tiss_active) {
            this.invoicingTissActive = true
            this.invoicingTissId = value.invoicing_tiss_setting?.id
            this.reload++
            await this.getTissSettings()
          } else if (value.invoicing_sus_active) {
            this.invoicingSusActive = true;
            this.reload++
          }
        }
      },
      deep: true
    },
    'clinicHealthPlanForm.health_plan': function (value) {
      if (!value)
        this.clinicHealthPlanForm = this.getDefaultClinicHealthPlanForm()
    },
    invoicingTissId(value) {
      this.tabs.forEach(tab => {
        if (typeof tab.disabled === "boolean") {
          tab.disabled = !value
        }
      })
    },
    'clinicHealthPlan.health_plan.ans_code': {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.oldAns = oldValue
        }
      },
      deep: true
    },
    healthPlanId: {
      handler(value) {
        if (value) {
          this.findClinicHealthPlan(value)
        } else{
          this.clinicHealthPlan = null
        }
      },
      immediate: true
    },
  }
}
</script>
<style lang="scss" scoped>
  .clinic-health-plan {
    min-height: 100%;
    padding: 30px 24px;
    text-align: left;
  }

  .health-plan-name {
    font-weight: 600;
  }

  .health-plan-type {
    color: var(--blue-500);
  }

  .general {
    margin: 1em 0;
  }

  .tab-container {
    .input-append {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .tab-content {
    .btn-add {
      height: 38px;
      margin-top: 28px;
    }

    .ps {
      margin-top: 16px;
    }

    .plus-icon {
      width: 24px;
      height: 24px;
      border: 2px solid var(--neutral-000);
    }

    .image-container {
      width: 240px;
      margin-bottom: 24px;

      .image {
        width: 240px;
        height: 120px;
        background-color: var(--neutral-100);
        border: 1px solid var(--neutral-200);
        border-radius: 8px;
        position: relative;
        margin-bottom: 16px;

        .icon {
          width: 24px;
          height: 24px;
          background-color: var(--blue-500);
          border: 2px solid var(--neutral-000);
          border-radius: 24px;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          right: 0;
          bottom: -12px;
          cursor: pointer;

          svg {
            width: 12px;
            fill: var(--neutral-000);
          }
        }
      }

      .help {
        font-weight: 600;
        font-size: 12px;
        color: var(--type-placeholder);
      }
    }

    .procedures-values {
      width: calc(100% + 48px);
      display: flex;
      align-items: stretch;
      flex-direction: row;
      margin: -24px -24px;

      .sidebar {
        width: 349px;
        border-right: 1px solid var(--neutral-200);

        .title {
          margin: 24px 0 8px 24px;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
          color: var(--type-active);
        }

        .no-plan {
          margin: 0 24px;
          padding: 16px 24px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--type-active);
          background-color: #fff0e5;
          border-radius: 8px;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          border-top: 1px solid var(--neutral-200);

          li {
            cursor: pointer;
            padding: 12px 24px;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: var(--type-active);
            border-bottom: 1px solid var(--neutral-200);
            border-left: 4px solid transparent;

            &.active {
              font-weight: 700;
              border-left: 4px solid var(--orange);
            }
          }
        }
      }

      .content {
        flex: 1;
        margin: 24px;

        .no-plan {
          margin-bottom: 16px;
          padding: 16px 24px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--type-active);
          background-color: #e6f9ff;
          border-radius: 8px;
        }

        .td-disabled {
          color: var(--type-placeholder);
        }
      }
    }
  }

  .top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: -20px;
    margin-bottom: 10px;
    justify-content: space-between;
  }

  .more-with-menu {
    width: 24px;
    height: 28px;
    position: relative;
    display: inline-block;

    .more-icon {
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      transition: all 0.5s;
      cursor: pointer;
    }

    .menu {
      width: 233px;
      position: absolute;
      top: 28px;
      right: 0;
      background: var(--neutral-000);
      border: 1px solid var(--neutral-200);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
        0px 4px 10px -8px rgba(12, 29, 89, 0.2);
      border-radius: 8px;
      padding: 24px;
      opacity: 0;
      transition: all 0.5s;
      flex-direction: column;
      align-items: start;
      display: none;

      .mtb-12 {
        margin: 12px 0;
      }

      .btn {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 16px;

        &.remove {
          color: var(--states-error);
        }
      }
    }

    &:hover {
      .more-icon {
        transform: rotate(90deg);
      }

      .menu {
        opacity: 1;
        display: flex;
        z-index: 10;
      }
    }
  }

  #ans::-webkit-outer-spin-button,
  #ans::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  #ans[type=number] {
    -moz-appearance: textfield;
  }
  .div-logo-healthplan{
    width: 270px;
  }
</style>
