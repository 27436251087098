var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course-dashboard-container"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.course?.name))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('NumberCard',{attrs:{"title":"Qtde. módulos","number":_vm.course?.modules.length}})],1),_c('div',{staticClass:"col-4"},[_c('NumberCard',{attrs:{"title":"Qtde. aulas","number":_vm.countVideoClass()}})],1),_c('div',{staticClass:"col-4"},[_c('NumberCard',{attrs:{"title":"Acessos únicos","number":_vm.uniqueViews}})],1),_c('div',{staticClass:"col-4"},[_c('NumberCard',{attrs:{"title":"Qtde. comentários","number":_vm.commentsCount}})],1),_c('div',{staticClass:"col-4"},[_c('NumberCard',{attrs:{"title":"Qtde. curtidas em comentários","number":_vm.commentReactionsCount}})],1),_c('div',{staticClass:"col-4"},[_c('NumberCard',{attrs:{"title":"Adoção médica","suffix":"%","number":_vm.adoptionPercent}})],1),_c('div',{staticClass:"col-4"},[_c('div',[_vm._v("Visualizações por aula")]),_c('VueApexCharts',{attrs:{"type":"polarArea","width":"100%","height":"auto","options":{
          labels: _vm.videoClassPolarArea.labels,
          stroke: { colors: ['#fff'] },
          fill: { opacity: 0.8 },
          yaxis: { show: false },
          legend: { position: 'bottom' },
        },"series":_vm.videoClassPolarArea.series}})],1),_c('div',{staticClass:"col-8"},[_c('div',[_vm._v("Status de visualização por aula")]),_c('VueApexCharts',{attrs:{"type":"bar","width":"100%","options":{
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: '100%'
          },
          labels: _vm.videoClassStatusBar.labels,
          stroke: { colors: ['#fff'] },
          fill: { opacity: 0.8 },
          yaxis: { show: false },
          legend: { position: 'bottom' },
        },"series":_vm.videoClassStatusBar.series}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }