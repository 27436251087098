<template>
  <div class="department-multiselect-call-panel form-group">
    <multiselect
      v-model="selected"
      :options="options"
      :track-by="'value'"
      :label="'label'"
      :close-on-select="false"
      :option-height="40"
      :showLabels="false"
      :searchable="false"
      :multiple="true"
      :placeholder="''"
      :loading="isLoading"
      :disabled="isLoading"
      openDirection="bottom"
      class="with-border multiple"
      @select="selectOption"
      @remove="removeOption"
    >
      <template v-if="!isLoading" slot="caret">
        <div class="chevron">
          <ChevronDown />
        </div>
      </template>
      <template slot="selection" slot-scope="{ values }">
        <span class="selectedOptions">
          <p>{{ generateLabels(values) }}</p>
        </span>
      </template>
      <template slot="noOptions"> Nenhuma opção </template>
      <template slot="noResult"> Nenhum resultado </template>
    </multiselect>
  </div>
</template>

<script>
export default {
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  props: {
    label: {
      default: 'Todos os departamentos',
      type: String
    },
    placeholder: String,
    departments: Array
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      options: [{ label: 'Todos os departamentos', value: '*' }],
      selected: [],
      isLoading: false
    }
  },
  mounted() {
    this.renderOptions()
  },
  methods: {
    allSelected() {
      return !(this.selected.length < this.options.length)
    },
    renderOptions() {
      this.isLoading = true
      this.api
        .getCallPanelDepartments(this.clinic.id)
        .then(({ data }) => {
          if (data.length) {
            data.map(el => {
              if (el.active) {
                this.options.push({ label: el.name, value: el.id })
              }
            })
          }
          this.handleSelected()
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    handleSelected() {
      if (this.departments?.length) {
        this.departments.forEach(department => {
          const option = this.options.find(
            opt => String(opt.value) === String(department.id)
          )
          if (option) {
            this.selected.push(option)
          }
        })

        if (
          this.selected.length ===
          this.options.filter(el => el.value !== '*').length
        ) {
          this.selected.unshift(this.options[0])
        }
      }
    },

    selectOption(option) {
      if (
        this.selected.length ===
        this.options.filter(el => el.value !== '*').length
      ) {
        this.selected.unshift(this.options[0])
      }
      if (option.value === '*') {
        this.selected = []
        this.options.map(el => {
          if (el.value) {
            this.selected.push(el)
          }
        })
      }
      this.$emit('input', this.selected)
    },

    removeOption(option) {
      if (option.value === '*') {
        this.selected = []
      } else {
        if (this.selected.some(el => el.value === '*')) {
          this.selected = this.selected.filter(el => el.value !== '*')
        }
      }
      this.$emit('input', this.selected)
    },

    generateLabels(values) {
      let labelString = ''
      if (values.length === 0) {
        if (this.isLoading) {
          labelString = 'Carregando...'
        } else {
          labelString = 'Nenhuma opção selecionada'
        }
      } else if (values.length === this.options.length) {
        labelString = 'Todos os departamentos'
      } else {
        values.forEach((el, index) => {
          if (index !== values.length - 1) {
            labelString += el.label + ', '
          } else {
            labelString += el.label
          }
        })
      }
      return labelString
    }
  }
}
</script>

<style lang="scss">
.department-multi-select-call-panel {
  .selectedOptions {
    width: 100%;
    display: flex;
    flex-direction: row;
    line-height: 100%;
    overflow: hidden;
    text-align: start !important;
    height: 50% !important;

    p {
      flex: 1;
    }
  }

  p {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    color: var(--type-active);
  }

  .multiselect .multiselect__tags .multiselect__spinner {
    transform: scale(0.8) !important;
    margin-right: 7px;
    background: none !important;
  }

  .multiselect .multiselect__tags .multiselect__single {
    display: none !important;
  }
}
</style>
