import Vue from 'vue'
import Vuex from 'vuex'

import mutations from './mutations'
import getters from './getters'
import actions from './actions'

Vue.use(Vuex)
const store = {
  namespaced: true,
  state: () => ({
    status: {
      'requested': { label: 'Solicitado', class: 'primary'},
      'active': { label: 'Ativo', class: 'success'},
      'canceled': { label: 'Cancelado', class: 'danger'},
      'paused': { label: 'Pausado', class: 'secondary'},
      'pending': { label: 'Pendente', class: 'warning'},
    },
  }),
  mutations,
  getters,
  actions,
}

export default store
