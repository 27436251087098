<template>
  <b-container class="oculosPrescription" fluid>
    <b-row align-h="center" align-v="center">
      <b-col class="bg-color" cols="auto">
        <div class="w-400px">
          <a href="/login"><img src="@/assets/images/eyecare-logo-colored.png" class="logo"></a>

          <p class="title">Olá, {{patient}}, Seja bem-vindo(a)!</p>
          <p class="oculosDescription">Abaixo você terá acesso à sua prescrição de óculos. </p>
          <p class="oculosDescription">{{professional}}, no dia <a class="text-primary">{{ this.moment(this.startTime).format('DD') }} de {{ this.moment(this.startTime).format('MMMM') }} de {{ this.moment(this.startTime).format('yyyy') }}</a>.</p>
          <p class="textdocument">Clique no botão abaixo para acessar os documentos</p>
          <div class="body">
            <span v-for="(doc, index) in docs" :key="index">
               <div class="file-container" v-if="parseInt(doc?.type_id) === 6">
                <v-pdf class="icon-file" @click="openFile(doc)"></v-pdf>
                
                <p v-if="parseInt(doc?.type_id) === 0 && doc?.attendance?.type === 'PRE_CONSULTATION'">
                  Pré-consulta
                </p>
                <p v-else-if="doc?.length >= 2">
                  {{ doc?.group_name }}
                </p>
                <p v-else-if="parseInt(doc?.type_id) >= 0">
                  {{ typeDoc[doc?.type_id] }}
                </p>
                <p v-else>
                  {{ doc?.custom_name ?? 'Arquivo' }}
                </p>
              </div>
            </span>
          </div>
          <div class="line"></div>
          <p class="textdocument">Visite a página de descontos dentro do seu <a class="text-primary">Benefício Visão</a> consulte as óticas próximas de você.</p> 
          <p class="oculosDescription">Apresente sua prescrição de óculos e seu cupom para comprar com desconto!</p>
        </div>
        <div class="line"></div>
        <p class="oculosDescription">Qualquer dúvida, estaremos à disposição</p>
        <p class="oculosDescription">Equipe Eyecare Health</p>
        <div class="line"></div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { getDocTypes } from '@/utils/documentHelper.js'
import Pdf from '@/assets/icons/pdfreceita.svg'

export default {
  name: 'PatientPrescription',
  components: {
    'v-pdf': Pdf,
  },
  props: {
  },
  data () {
    return {
      termo: false,
      typeDoc: getDocTypes(),
      docs: [],
      attendance: [],
      patient: null,
      professional: null,
      cellphone: null,
      checkedTerm: null,
      checkedPolicy: null,
      url: process.env.VUE_APP_URL,
      startTime: null
    }
  },
  created() {
    this.getAttendanceFiles(this.$route.params.attendanceid)
    this.getMedicalRecord(this.$route.params.attendanceid)
  },
  methods: {
    
    getMedicalRecord(attendanceId) {
      this.api.getAttendanceById(attendanceId)
      .then(res => {
        this.attendance = res.data 
        this.startTime = res.data.start_datetime
        this.patient = res.data?.patient?.name 
        this.professional = res.data?.professional?.name
      })
      .catch(err => { console.log('ERR getAttendanceFiles => ', err)})
      .finally(() => {  })
    },
    getAttendanceFiles(attendance) {
      const loading = this.$loading.show()
      this.api.getAttendanceFiles(attendance)
      .then(res => {
        this.docs = res.data 
      })
      .catch(err => { console.log('ERR getAttendanceFiles => ', err)})
      .finally(() => { loading.hide() })
    },
    getDocUrl(path){
      const loading = this.$loading.show()
      this.api.getDocTemporaryUrl(path)
      .then(res => {
        window.open(res.data, "_blank")
      })
      .catch(err => { console.log('ERR getAttendanceFiles => ', err)})
      .finally(() => { loading.hide() })
    },
     openFile(file) {
      if(file) {
         this.getDocUrl(file.path)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .oculosPrescription {
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;
    background-color: #EBF4FB;
    height: 100vh;

    .logo {
      width: 123px;
      height: 24px;
      margin: 50px 0 50px 0;
    }

    .bg-color {
      background-color: #FFFFFF;
    }
    .bg-color-logo {
      background-color: #0E3DE2;
      padding-right: 0px !important; 
      padding-left: 0px !important; 
    }

    .w-400px {
      width: 400px;
      margin: 0 auto;
    }

    .title {
      font-family: 'Red Hat Display';
      font-weight: 700;
      font-size: 28px;
      line-height: 40.32px;
      color: var(--dark-blue);
      margin-bottom: 20px;
      margin-top: 5px;
    }

    .oculosDescription {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--type-active);
    }

    .textdocument {
      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      color: var(--dark-blue);
      margin: 10px 0 !important;
      margin-bottom: 16px;
    }

    .body {
        overflow-x: hidden;
        white-space: nowrap;
        max-height: 300px;

        span {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .file-container {
            display: flex;
            flex-direction: row;
            gap: 20px;

            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              color: var(--type-active);
              align-items: center;
              display: flex;
            }
          }

          .print-options {
            display: flex;
            gap: 10px;

            .icon-print {
              width: 30px;
              height: 30px;
              stroke: var(--blue-700);
              cursor: pointer;
            }

            .icon-file {
              width: 24px;
              height: 24px;
              stroke: var(--blue-700);
              cursor: pointer;
            }
          }

          a {
            text-decoration: none;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: var(--blue-500);
            cursor: pointer;
          }
        }

        span + span {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .file-container {
            display: flex;
            flex-direction: row;
            gap: 20px;

            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              color: var(--type-active);
              align-items: center;
              display: flex;
            }
          }

          a {
            text-decoration: none;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: var(--blue-500);
            cursor: pointer;
          }
        }
      }
    .line{
      border-top: 1px dashed var(--neutral-300);
      margin: 20px 0 20px 0;
    }
  }
</style>
