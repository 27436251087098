import Vue from 'vue'
import {isObject} from "@/utils/dataHelper";

const store = {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  mutations: {
    setList: (state, list) => {
      if(list) {
        list.forEach(item => state.list.push(item))
      }
    }
  },
  actions: {
    /**
     *
     * @param commit
     * @returns {Promise<*>}
     */
    async getEyeExamsList({commit, state}) {
      return await Vue.prototype.api.getExamesOftalmologicos().then(res => {
        res.data.sort((a,b) => {
          if(a.value < b.value) {
            return -1
          }
          if(b.value > a.value) {
            return 1
          }
          return 0
        })

        commit('setList', res.data)
      })
    },

    addItem({commit}, item) {
      if(isObject(item)) {
        commit('setList', [item])
      }
    }
  },
  getters: {},
}

export default store
