<template>
  <div 
    v-can="'AgOnlProf1'"
    class="professionals-view"
  >
    <b-row>
      <p class="form-title mb-3 ml-2">
        Profissionais participantes do agendamento online

        <span id="help" class="icon-box no-print">
          <HelpCircle class="icon stroke" />
        </span>

        <b-tooltip
          target="help"
          custom-class="custom-tooltip"
          placement="bottom"
          class="w-25"
        >
          <span>
            <span class="d-block text-left">
             Ative ou inative profissionais para serem possíveis agendar por meio do agendamento online.
            </span>
          </span>
        </b-tooltip>
      </p>
    </b-row>

    <b-row v-if="professionals?.length && public_schedule_id">
      <b-col 
        v-for="(professional, index) of professionals"
        :key="index"
        cols="12"
        class="mb-3"  
      >
        <ProfessionalCard
          :public_schedule_id="public_schedule_id"
          :professional="professional"
          @reload-public-schedule="reloadPublicSchedule"
        />
      </b-col>
    </b-row>

    <b-row v-else-if="!professionals">
      <b-col cols="12">
        <small>Não há nenhum médico(a) cadastrado</small>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col cols="12">
        <small>Configure a clinica no agendamento online para continuar</small>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ProfessionalsView',
  props:  {
    professionals: Array,
    public_schedule_id: String
  },
  components: {
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    ProfessionalCard: () => import('./Modules/ProfessionalCard.vue')
  },
  data() {
    return {

    }
  },
  methods: {
    reloadPublicSchedule(){
      this.$emit('reload-public-schedule')
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-title{
    color: var(--neutral-600);
    font-size: 18px;
    font-weight: bolder;
    line-height: 28px;
  }

  .icon {
    height: 24px;
    width: 24px;
    margin-left: 5px;
    stroke: var(--neutral-500);
  }
</style>
