<template>
  <div>
    <b-modal
      id="protocol-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @hidden="closeModal(false)"
    >
      <div class="modal-header">
        <div class="modal-title">
          {{ protocol && protocol.hasOwnProperty('id') ? 'Atualizar' : 'Novo' }}
          protocolo
        </div>
        <span class="icon-box"
          ><v-close class="icon stroke" @click="closeModal(false)"
        /></span>
      </div>
      <div class="around-content">
        <validation-observer ref="ProtocolForm">
          <b-row>
            <b-col cols="7">
              <b-form-group>
                <label for="nome_protocolo">Nome do protocolo</label>
                <input
                  id="nome_protocolo"
                  autocomplete="off"
                  type="text"
                  placeholder="Ex: Glaucoma fase 01"
                  class="form-control"
                  v-model="form.name"
                />
                <div
                  v-if="validated && !form.name"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="5" v-if="!admin">
              <b-form-group>
                <label for="" class="d-block">Privacidade</label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="privacidade"
                    id="privado"
                    v-model="form.type"
                    value="privado"
                    checked
                  />
                  <label class="form-check-label" for="privado">
                    Privado
                  </label>

                  <input
                    class="form-check-input ml-4"
                    type="radio"
                    name="privacidade"
                    id="compartilhado"
                    value="compartilhado"
                    v-model="form.type"
                  />
                  <label class="form-check-label" for="compartilhado">
                    Compartilhado
                  </label>
                </div>
                <div
                  v-if="validated && !form.type && !admin"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="5" v-else>
              <b-form-group>
                <label for="" class="d-block">Tipo</label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="privacidade"
                    id="recomendado"
                    v-model="form.type"
                    value="recomendado"
                  />
                  <label class="form-check-label" for="recomendado">
                    Recomendado
                  </label>

                  <input
                    class="form-check-input ml-4"
                    type="radio"
                    name="privacidade"
                    id="literatura_medica"
                    value="literatura_medica"
                    v-model="form.type"
                  />
                  <label class="form-check-label" for="literatura_medica">
                    Literatura média
                  </label>
                </div>
                <div
                  v-if="validated && !form.type && !admin"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="7" v-if="form.type === 'recomendado' && admin">
              <b-form-group>
                <label for="nome_farmaceutica">Nome da farmacêutica</label>
                <input
                  id="nome_farmaceutica"
                  autocomplete="off"
                  type="text"
                  placeholder="Ex: FarmaPlus"
                  class="form-control"
                  v-model="form.pharmacist_name"
                />
                <div
                  v-if="validated && !form.pharmacist_name && admin"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <hr />

          <b-row>
            <b-col cols="5" class="field-name-multiline">
              <div class="form-group">
                <label class="d-flex" for="medicine">Nome do medicamento
                  <label for="antimicrobial" class="checkbox ml-auto">
                    <input
                      type="checkbox"
                      class="input-checkbox"
                      v-model="form.antimicrobial"
                      :disabled="typeof form.medicine === 'object'"
                      id="antimicrobial"
                    />
                    <span :class="`checkmark ${typeof form.medicine === 'object' ? 'checkmark-disabled' : ''}`">
                      <Check />
                    </span>
                    Antimicrobiano
                  </label>
                </label>

                <multiselect
                  v-if="typeof form.medicine === 'object'"
                  id="medicine"
                  v-model="form.medicine"
                  :options="medicines"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :internal-search="false"
                  @search-change="searchMedicines"
                  @select="selectMedicine"
                  placeholder="Pesquisar medicamento"
                  class="search-mode with-border"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <div class="text-truncate">
                      {{ props.option.product }}<br />
                      <small>
                        {{ props.option.company_name }} -
                        {{ props.option.description }}
                      </small>
                    </div>
                  </template>
                  <template slot="option" slot-scope="props">
                    {{ props.option.product }}<br />
                    <small>
                      {{ props.option.company_name }} -
                      {{ props.option.description }}
                    </small>
                  </template>
                  <template slot="caret">
                    <div class="search">
                      <v-search-plus />
                    </div>
                  </template>
                  <template slot="noOptions">
                    Digite para pesquisar um medicamento
                  </template>
                  <template slot="noResult" slot-scope="props">
                    <li @click="setCustomMedicine(props.search)">
                      <div class="multiselect__option custom-item">
                        <v-plus class="icon" />
                        Adicionar medicamento "{{ props.search }}"
                      </div>
                    </li>
                  </template>
                </multiselect>
                <div class="custom-input" v-else>
                  <v-close @click="setCustomMedicine(null)" />
                  <input
                    autocomplete="off"
                    v-model="form.medicine"
                    type="text"
                    class="form-control padding-right"
                  />
                </div>
                <div
                  v-if="validatedMedicine"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </div>
            </b-col>
            <b-col cols="3" class="field-name-multiline">
              <div class="form-group">
                <label for="via">Via</label>
                <multiselect
                  v-model="form.via"
                  id="via"
                  :options="['Ocular', 'Oral', 'Sublingual', 'Uso tópico', 'Intramuscular' , 'Intravenosa']"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  placeholder="Selecionar"
                  class="with-border"
                >
                  <perfect-scrollbar>
                    <template slot="caret">
                      <div class="chevron">
                        <v-chevron-down />
                      </div>
                    </template>
                  </perfect-scrollbar>
                  <template slot="noOptions"> Nenhuma opção </template>
                  <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
                <div
                  v-if="validatedMedicine && !form.via"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </div>
            </b-col>
            <b-col cols="4"  class="field-name-multiline">
              <div class="form-group">
                <label for="quantity">Quantidade</label>
                <span class="help" id="help"> (Opcional)</span>
                <multiselect
                  @search-change="changeOptionsQuantidade"
                  v-model="form.quantity"
                  id="quantity"
                  :options="optionsQuantity"
                  :option-height="40"
                  :showLabels="false"
                  :internal-search="true"
                  :searchable="true"
                  placeholder="Selecionar"
                  class="with-border"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <v-chevron-down />
                    </div>
                  </template>
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option }}
                  </template>
                  <template slot="noOptions">
                    <div>Digite para pesquisar...</div>
                  </template>
                  <template slot="noResult">
                    <div>Nenhuma informação encontrada...</div>
                  </template>
                </multiselect>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="4">
              <div class="form-group">
                <label for="dose">Dose</label>
                <span v-if="form.via === 'Uso tópico'" class="help">
                  (Opcional)</span
                >
                <multiselect
                  @search-change="changeDose"
                  :internal-search="true"
                  v-model="form.dose"
                  id="dose"
                  :options="optionsDose"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :allowEmpty="false"
                  placeholder="Descrever"
                  class="with-border"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <v-chevron-down />
                    </div>
                  </template>
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option }}
                  </template>
                  <template slot="noOptions">
                    <div>Digite para pesquisar...</div>
                  </template>
                  <template slot="noResult">
                    <div>Nenhuma informação encontrada...</div>
                  </template>
                </multiselect>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="form-group">
                <label for="intervals">Intervalo</label>
                <multiselect
                  @search-change="changeOptionsintervals"
                  v-model="form.intervals"
                  id="intervals"
                  :options="optionsintervals"
                  :option-height="40"
                  :showLabels="false"
                  :internal-search="true"
                  :searchable="true"
                  placeholder="Descrever"
                  class="with-border"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <v-chevron-down />
                    </div>
                  </template>
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option }}
                  </template>
                  <template slot="noOptions">
                    <div>Digite para pesquisar...</div>
                  </template>
                  <template slot="noResult">
                    <div>Nenhuma informação encontrada...</div>
                  </template>
                </multiselect>
                <div
                  v-if="validatedMedicine && !form.intervals"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="form-group">
                <label for="tempo_de_uso">Tempo de uso</label>
                <multiselect
                  @search-change="changeTempoDeUso($event, form)"
                  v-model="form.time_of_use"
                  id="tempo_de_uso"
                  :options="optionTempoDeUso"
                  :showLabels="false"
                  :searchable="true"
                  :internal-search="true"
                  placeholder="Descrever"
                  class="with-border"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <v-chevron-down />
                    </div>
                  </template>
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option }}
                  </template>
                  <template slot="noOptions">
                    <div>Digite para pesquisar...</div>
                  </template>
                  <template slot="noResult">
                    <div>Nenhuma informação encontrada...</div>
                  </template>
                </multiselect>

                <div
                  v-if="validatedMedicine && !form.time_of_use"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="10">
              <div class="form-group">
                <label for="">Orientação</label>
                <span class="help" id="help"> (Opcional)</span>
                <div class="form-group mb-0 break-spaces">
                  <v-text-area
                    id="orientacao"
                    v-model="form.orientation"
                    placeholder="Aplicar colírio no centro do olho e não esfregar"
                    rows="1"
                  />
                </div>
              </div>
            </b-col>
            <b-col cols="2" class="add-button">
              <b-button
                v-if="editIndex !== null"
                variant="primary"
                class="mt-4"
                block
                @click="
                  defaultType
                    ? $bvModal.show('confirm-update-protocol-modal')
                    : editItem()
                "
                >Alterar</b-button
              >
              <b-button
                v-else
                variant="primary"
                class="mt-4"
                block
                @click="
                  defaultType && isValidFormMedicine()
                    ? $bvModal.show('confirm-update-protocol-modal')
                    : saveItem()
                "
                >Adicionar</b-button
              >
            </b-col>
          </b-row>
        </validation-observer>
        <hr />

        <v-protocol-table-modal
          :protocolMedicines="protocolMedicines"
          @editProtocol="editProtocol"
          @removeProtocol="removeProtocol"
          :modal="false"
          :isDefaultProtocol="defaultType"
        />

        <div class="wrapper-button">
          <b-button
            v-if="form.id"
            variant="primary"
            size="lg"
            @click="
              defaultType
                ? $bvModal.show('confirm-update-protocol-modal')
                : updateProtocol()
            "
          >
            Atualizar protocolo
          </b-button>
          <b-button
            v-else
            variant="primary"
            size="lg"
            @click="createProtocol()"
          >
            Salvar protocolo
          </b-button>
        </div>
      </div>
    </b-modal>

    <v-confirm-update-protocol-modal :updateProtocol="updateProtocol" />
  </div>
</template>
<script>
import Check from '@/assets/icons/check.svg'
import Close from '@/assets/icons/close.svg'
import Search from '@/assets/icons/search.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Plus from '@/assets/icons/plus.svg'
import ProtocolMedicineTable from './ProtocolMedicineTable.vue'
import ConfirmUpdateProtocolModal from './ConfirmUpdateProtocolModal.vue'
import { EventBus } from '@/utils/eventBus'
import TextArea from '@/components/General/TextArea'

export default {
  name: 'ProtocolModal',
  components: {
    'v-close': Close,
    'v-search-plus': Search,
    'v-plus': Plus,
    'v-chevron-down': ChevronDown,
    'v-protocol-table-modal': ProtocolMedicineTable,
    'v-confirm-update-protocol-modal': ConfirmUpdateProtocolModal,
    Check,
    'v-text-area': TextArea,
  },
  props: {
    protocol: Object,
    admin: Boolean
  },
  data() {
    const clinic = JSON.parse(localStorage.getItem('clinic'))
    return {
      clinic_id: clinic.id,
      person: JSON.parse(localStorage.getItem('user')),
      validated: false,
      validatedMedicine: false,
      toDelete: null,
      medicines: [],
      optionTempoDeUso: [],
      optionsintervals: [],
      optionsDose: [],
      optionsQuantity: [],
      protocolMedicines: [],
      editIndex: null,
      defaultType: null,
      form: this.getDefaultForm(),
    }
  },
  methods: {
    addCustomOption(ev, options, key, value) {
      this[options].push(value);
      this.form[key] = value;
    },
    getDefaultForm() {
      return {
        id: null,
        name: null,
        pharmacist_name: null,
        type: null,
        medicine: null,
        antimicrobial: null,
        via: null,
        quantity: null,
        intervals: null,
        dose: null,
        time_of_use: null,
        orientation: null,
        protocolMedicineIdEdited: null
      }
    },
    isValidForm() {
      this.validated = true
      return this.form.name && (this.form.type || this.form.pharmacist_name) && this.protocolMedicines.length > 0
    },

    isValidFormMedicine() {
      this.validatedMedicine = true
      return (
        this.form.medicine &&
        this.form.via &&
        this.form.intervals &&
        this.form.time_of_use
      )
    },
    async saveItem() {
      if (!this.isValidFormMedicine()) {
        this.$toast.warning('Preencha todos os campos obrigatórios!')
        return
      }
      const protocolMedicine = {
        medicine: this.form.medicine,
        antimicrobial: this.form.antimicrobial,
        via: this.form.via,
        quantity: this.form.quantity,
        dose: this.form.dose,
        intervals: this.form.intervals,
        time_of_use: this.form.time_of_use,
        orientation: this.form.orientation,
        protocol_id: this.protocol ? this.protocol.id : null,
        medicine_id: this.form.medicine.id
      }
      if (this.protocol && this.protocol.id) {
        await this.createProtocolMedicine(protocolMedicine)
      }
      this.protocolMedicines.push(protocolMedicine)
      this.clearFormMedicine()
    },
    async editItem() {
      if (this.editIndex !== null) {
        const protocolMedicine = {
          id: null,
          medicine: this.form.medicine,
          antimicrobial: this.form.antimicrobial,
          via: this.form.via,
          quantity: this.form.quantity,
          dose: this.form.dose,
          intervals: this.form.intervals,
          time_of_use: this.form.time_of_use,
          orientation: this.form.orientation,
          medicine_id: null,
          protocol_id: null
        }

        if (this.form.protocolMedicineIdEdited) {
          protocolMedicine.id = this.form.protocolMedicineIdEdited
          protocolMedicine.medicine_id = protocolMedicine.medicine.id
          protocolMedicine.protocol_id = this.form.id
          await this.updateProtocolMedicine(protocolMedicine)
        }

        this.$set(this.protocolMedicines, this.editIndex, protocolMedicine)
        this.clearFormMedicine()
      }
    },
    async createProtocol() {
      if (!this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios!')
        return
      }
      const data = {
        name: this.form.name,
        type: this.form.type,
        pharmacist_name: this.form.pharmacist_name,
        clinic_id: ['recomendado', 'literatura_medica'].includes(this.form.type) ? null : this.clinic_id,
        person_id: this.person.id,
        protocol_medicines: this.protocolMedicines
      }

      const loading = this.$loading.show()
      try {
        const response = await this.api.createProtocol(data)
        const typeEventBus =
          response.data.type === 'literatura_medica' ||
          response.data.clinic_id === null;
        this.clearForm();
        this.$toast.success('Protocolo cadastrado com sucesso!')
        this.$emit('update-protocols', response.data)
        this.closeModal(typeEventBus)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        loading.hide()
      }
    },
    async updateProtocol() {
      if (!this.isValidForm()) return
      const data = {
        name: this.form.name,
        type: this.form.type,
        pharmacist_name: this.form.pharmacist_name,
        clinic_id: ['recomendado', 'literatura_medica'].includes(this.form.type) ? null : this.clinic_id,
        protocol_medicines: this.protocolMedicines
      }

      this.$bvModal.hide('confirm-update-protocol-modal')

      await this.saveItem()
      await this.editItem()
      try {
        const response = await this.api.updateProtocol(this.protocol.id, data)
        const typeEventBus =
          response.data.type === 'literatura_medica' ||
          response.data.clinic_id === null

        this.$toast.success('Protocolo atualizado com sucesso!')
        this.$emit('update-protocols', response.data)
        this.closeModal(typeEventBus)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    confirmUpdateProtocol() {
      this.$bvModal.show('confirm-update-protocol-modal')
    },
    async createProtocolMedicine(data) {
      try {
        await this.api.createProtocolMedicine(data)
        this.$toast.success('Medicamento criado com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async updateProtocolMedicine(data) {
      try {
        await this.api.updateProtocolMedicine(data.id, data)
        this.$toast.success('Medicamento atualizado com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async deleteProtocolMedicine(id) {
      try {
        await this.api.deleteProtocolMedicine(id)
        this.$toast.success('Medicamento deletado com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    closeModal(typeEventBus) {
      this.clearForm()
      this.clearFormMedicine()
      this.protocolMedicines = []
      this.defaultType = false
      if (typeEventBus) {
        EventBus.$emit('reloadProtocolsAdmin')
      }
      this.$emit('clear-protocol-props')
      this.$bvModal.hide('protocol-modal')
    },
    clearForm() {
      this.form = this.getDefaultForm();
      this.validated = false;
      this.validatedMedicine = false;
      this.editIndex = null;
      this.protocolMedicines = [];
    },
    clearFormMedicine() {
      this.form.medicine = null
      this.form.antimicrobial = null
      this.form.via = null
      this.form.quantity = null
      this.form.quantity = null
      this.form.intervals = null
      this.form.dose = null
      this.form.time_of_use = null
      this.form.orientation = null
      this.validatedMedicine = false
      this.editIndex = null
    },
    async searchMedicines(query) {
      if (query.length > 2) {
        this.medicines = []
        const response = await this.api.searchMedicines({ term: query })
        try {
          this.medicines = response.data.map(medicine => {
            return medicine
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    selectMedicine(medicine) {
      if (typeof medicine === 'object' && parseInt(medicine.antimicrobiano) === 1) {
        this.form.antimicrobial = true
      } else {
        this.form.antimicrobial = false
      }
      this.medicines = []
    },
    changeTempoDeUso(query) {
      const options = []
      const treatedText = query.replace(/\D/g, '')
      if (parseInt(treatedText)) {
        if (treatedText === '1') {
          options.push('1 dia', '1 semana', '1 mês', '1 ano')
        } else {
          options.push(
            `${treatedText} dias`,
            `${treatedText} semanas`,
            `${treatedText} meses`,
            `${treatedText} anos`
          )
        }
      } else {
        options.push('1 dia', '1 semana', '1 mês', '1 ano')
      }
      options.push('Uso contínuo')
      options.push('Até o retorno')
      !options.includes(query) && query && options.push(query);
      this.optionTempoDeUso = [...options]
    },
    changeDose(query) {
      const options = []
      const treatedText = query.replace(/\D/g, '')
      if (!query.includes('-') && parseInt(treatedText) && this.form.via) {
        if (treatedText === '1') {
          options.push(
            `${treatedText} ml`,
            `${treatedText} comprimido`,
            `${treatedText} sache`,
            `${treatedText} capsula`,
            `${treatedText} pílula`,
            `${treatedText} gota`,
            `${treatedText} mg/kg`
          )
        } else {
          options.push(
            `${treatedText} mls`,
            `${treatedText} comprimidos`,
            `${treatedText} saches`,
            `${treatedText} capsulas`,
            `${treatedText} pílulas`,
            `${treatedText} gotas`,
            `${treatedText} mg/kg`
          )
        }
      }
      !options.includes(query) && query && options.push(query);
      this.optionsDose = [...options]
    },
    changeOptionsintervals(query) {
      const options = []
      const treatedText = query.replace(/\D/g, '')
      if (parseInt(treatedText)) {
        if (treatedText === '1') {
          options.push(
            `De ${treatedText} em ${treatedText} minuto`,
            `De ${treatedText} em ${treatedText} hora`,
            `De ${treatedText} em ${treatedText} dia`,
            `${treatedText} vezes ao dia`,
            `De ${treatedText} em ${treatedText} semana`,
            `De ${treatedText} em ${treatedText} mês`
          )
        } else {
          options.push(
            `De ${treatedText} em ${treatedText} minutos`,
            `De ${treatedText} em ${treatedText} horas`,
            `De ${treatedText} em ${treatedText} dias`,
            `${treatedText} vezes ao dia`,
            `De ${treatedText} em ${treatedText} semanas`,
            `De ${treatedText} em ${treatedText} meses`
          )
        }
      } else {
        options.push(
          `De 1 em 1 minuto`,
          `De 1 em 1 hora`,
          `De 1 em 1 dia`,
          '1 vez ao dia',
          `1 vez por dia`,
          `De 1 em 1 semana`,
          `De 1 em 1 mês`
        )
      }
      options.push('Várias vezes ao dia');
      !options.includes(query) && query && options.push(query);
      this.optionsintervals = [...options]
    },
    changeOptionsQuantidade(query) {
      const options = []
      const treatedText = query.replace(/\D/g, '')
      if (parseInt(treatedText)) {
        if (treatedText === '1') {
          options.push(
            '1 frasco',
            '1 caixa',
            '1 comprimido',
            '1 bisnaga',
            '1 cápsula',
            '1 flaconete',
            '1 pílula'
          )
        } else {
          options.push(
            `${treatedText} frascos`,
            `${treatedText} caixas`,
            `${treatedText} comprimidos`,
            `${treatedText} bisnagas`,
            `${treatedText} cápsulas`,
            `${treatedText} flaconetes`,
            `${treatedText} pílulas`
          )
        }
      } else {
        options.push(
          '1 frasco',
          '1 caixa',
          '1 comprimido',
          '1 bisnaga',
          '1 cápsula',
          '1 flaconete',
          '1 pílula'
        )
      }
      !options.includes(query) && query && options.push(query);
      this.optionsQuantity = [...options]
    },
    editProtocol(protocol, index) {
      this.form.via = protocol.via
      this.form.quantity = protocol.quantity
      this.form.dose = protocol.dose
      this.form.intervals = protocol.intervals
      this.form.time_of_use = protocol.time_of_use
      this.form.orientation = protocol.orientation
      this.form.medicine = protocol.medicine
        ? protocol.medicine
        : protocol.medicine_name
      this.editIndex = index

      if (protocol.antimicrobial || (typeof protocol.medicine === 'object' && protocol.medicine?.antimicrobiano)) {
        this.form.antimicrobial = protocol.antimicrobial
      }

      if (Object.prototype.hasOwnProperty.call(protocol, 'id')) {
        this.form.protocolMedicineIdEdited = protocol.id
      }
    },
    removeProtocol(protocol, index) {
      this.protocolMedicines.splice(index, 1)

      if (Object.prototype.hasOwnProperty.call(protocol, 'id')) {
        this.deleteProtocolMedicine(protocol.id)
      }
    },
    setCustomMedicine(value) {
      this.form.antimicrobial = null
      this.form.medicine = value
    }
  },
  watch: {
    protocol(protocol) {
      if (protocol) {
        this.form = {
          id: protocol.id,
          name: protocol.name,
          type: protocol.type,
          pharmacist_name: protocol.pharmacist_name,
          medicine: {},
          via: null,
          quantity: null,
          intervals: null,
          dose: null,
          time_of_use: null,
          orientation: null
        }
        if (protocol.type === 'padrão') {
          this.defaultType = true
        }
        this.protocolMedicines = protocol.medicines
      }
    }
  }
}
</script>
<style lang="scss">
#protocol-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .form-group {
      .checkbox {
        margin: 0;
        padding: 0px 0px 0 25px;
        font-size: .8rem;
      }

      .input-checkbox ~ .checkmark {
        width: 22px;
        height: 22px;
      }

      .input-checkbox:disabled ~ .checkmark {
        background-color: var(--neutral-200);
        border: var(--neutral-200);
      }

      .form-check-input {
        width: 22px;
        height: 1.5em;
      }
    }
  }

  .add-button {
    align-items: end;
    display: flex;
    padding-bottom: 24px;
  }

  .around-content {
    padding: 24px 24px 0 24px;

    .field-name-multiline {
      display: inline-flex;

      .form-group {
        width: 100%;
      }

      .multiselect {
        margin-bottom: 0;
      }
    }
    .form-check-inline {
      margin-top: 3%;

      .form-check-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 0;
        display: inline-block;
        vertical-align: top;
      }
    }

    .custom-input {
      position: relative;

      button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
      }

      svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        fill: var(--neutral-500);
      }

    }

    .padding-right {
      padding-right: 30px;
    }

    .multiselect {
      .custom-item {
        color: var(--blue-500);
        display: flex;
        align-items: center;
        font-weight: 600;

        .icon {
          width: 24px;
          height: 24px;
          fill: var(--blue-500);
          margin-right: 12px;
        }
      }
    }
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .custom-option {
    background: var(--neutral-100);
    color: var(--type-placeholder);
    padding: 10px 15px;
  }
}
</style>
