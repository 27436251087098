<template>
  <div class="breadcrumbs-wrapper">
    <div
      v-for="(option, index) in options"
      :key="option.label"
      class="breadcrumbs"
    >
      <div
        :class="{ link: true, disabled: !option.onClick }"
        @click="() => option.onClick && option.onClick()"
      >
        {{ option.label }}
      </div>
      <v-chevron-right v-if="options[index+1]" class="chevron" />
    </div>
  </div>
</template>
<script>
import ChevronRight from '@/assets/icons/chevron-right.svg'
export default {
  components: {
    'v-chevron-right': ChevronRight,
  },
  props: {
    options: Array,
  }
}
</script>
<style lang="scss" scoped>
.breadcrumbs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  .breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 16px;

    .chevron {
      width: 24px;
      stroke: #7F91D2;
      margin-right: 10px;
    }

    .link {
      color: var(--blue-500);
      cursor: pointer;

      &.disabled {
        color: var(--type-active);
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
