<template>
    <div class="health-plan">
      <div class="health-plan__title">
        <span class="section-title">Convênios</span>
        <span class="section-subtitle">Adicione abaixo os convênios atendidos pelo médico</span>
      </div>
      <div class="health-plan__search">
        <multiselect
            v-model="selectedHealthPlan"
            :options="healthPlanSelectItems"
            track-by="id"
            label="name"
            :show-labels="false"
            :allow-empty="false"
            placeholder="Buscar convênio..."
            :loading="loading"
            :searchable="false"
        >
          <template slot="noResult">
            Nenhum resultado encontrado.
          </template>
        </multiselect>
        <b-button
            v-if="userCanEdit || editOwnData"
            variant="primary"
            :disabled="!selectedHealthPlan || addButtonLoading"
            @click="insertHealthPlan(selectedHealthPlan.id)"
        >
          <b-spinner
          v-if="addButtonLoading"
          small
          variant="primary"
          label="Spinning"
          />
          <span v-else>Adicionar</span>
        </b-button>
        <b-button
            v-else
            variant="primary"
            :disabled="true"
        >
          Adicionar
        </b-button>
      </div>
      <div class="health-plan__list">
        <b-table
            id="health-plans-list"
            class="health-plans-table"
            :items="healthPlans"
            :fields="healthPlanFields"
            :per-page="perPage"
            :current-page="currentPage"
            small
        >
          <template v-slot:cell(name)="data">
            <div class="d-flex justify-content-between">
              <div>
                {{ data.item.name }}
                <span 
                  v-if="data.item.enabled" 
                  class="enabled-indicator"
                >
                  (Habilitado)
                </span>
              </div>
              <button 
                v-if="!data.item.enabled && (userCanEdit || editOwnData)"
                class="remove-additional-plan"
                @click="removeAdditionalPlan(data.item.additional_plan_id)"
                :disabled="removeButtonLoading"
                title="Remover convênio da listagem"
              >HealthPlanSelect
                <b-spinner
                  v-if="removeButtonLoading"
                  small
                  label="Spinning"
                />
                <TrashBin style="fill: #FCBFBA" v-else/>
              </button>
            </div>
          </template>
      </b-table> 
        <div class="pagination-container">
          <div class="page-information">
            <span class="text-muted total-rows">Total de <b>{{ rows }}</b> registros</span>
          </div>
          <div class="d-flex per-page" style="align-items: center; gap: 8px;">
            <span class="text-muted total-rows">Linhas por página</span>
            <b-form-select v-model="perPage" :options="perPageOptions" class="mr-2"></b-form-select>
          </div>
          <div>
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="health-plans-list"
                align="right"
                size="sm"
                class="mb-0"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
export default {
  name: 'HealthPlan',
  props: ['professionalId', 'userCanEdit', 'editOwnData'],
  components: {
    TrashBin: () => import('@/assets/icons/trash_bin.svg'),
  },
  data() {
    return {
      clinic_id: getCurrentClinic().id,
      selectedHealthPlan: {},
      healthPlanSelectItems: [],
      healthPlanItems: [],
      additionalHealthPlans: [],
      healthPlanFields: [{key: 'name', label: 'Convênio', sortable: true}],
      currentPage: 1,
      perPage: 3,
      perPageOptions: [3, 5, 10, 20],
      loading: false,
      addButtonLoading: false,
      removeButtonLoading: false,
    }
  },
  mounted(){
    this.getClinicHealthPlans();
  },
  computed: {
    rows() {
      return this.healthPlans.length
    },
    healthPlans(){
      const tempArray = this.healthPlanItems.concat(this.additionalHealthPlans)
      return tempArray.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    }
  },

  methods: {
    getClinicHealthPlans() {
      this.loading = true
      this.api.getAllClinicHealthPlans(this.clinic_id)
      .then(({data}) => {
        if(data.length){
          data.map(plan => {
            if(plan.health_plan){
              const tempObj = {
                id: plan.health_plan.id,
                name: plan.health_plan.fantasy_name
              }
              this.healthPlanSelectItems.push(tempObj)
            }
          })
        }
      })
      .catch(err => {
        console.error('erro', err)
      }).finally(() => {
        this.loading = false
        this.healthPlanSelectItems = this.healthPlanSelectItems.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
      })
    },
    getTissSettings(id) {
      this.healthPlanItems = [];
      this.api.getHealthPlansByProfessionalId(id, this.clinic_id)
      .then(({data}) => {
        if(data.length){
          data.map(plan => {
            if(plan.hp_name){
              const tempObj = {
                id: plan.chp_health_plan_id,
                name: plan.hp_name,
                enabled: true
              }
              this.healthPlanItems.push(tempObj)
            }
          })
        }
      })
      .catch(err => {
        console.error('erro', err)
      })
    },
    insertHealthPlan(healthPlanId){
      if(!healthPlanId){
        this.$toast.warning('Selecione um convênio na listagem para adicionar!')
        return
      }
      if(this.healthPlans.some(el => el.id === healthPlanId && el.enabled)){
        this.$toast.warning('O profissional já está habilitado no convênio selecionado')
        return
      }
      if(this.healthPlans.some(el => el.id === healthPlanId && !el.enabled)){
        this.$toast.warning('O convênio selecionado já foi adicionado à lista')
        return
      }
      this.addButtonLoading = true;
      const payload = {
        clinic_id: this.clinic_id,
        person_id: this.professionalId,
        health_plan_id: healthPlanId
      }

      this.api.insertAdditionalHealthPlan(payload)
      .then(({data}) => {
        if(Object.keys(data).length){
          this.$toast.success('Convênio adicionado com sucesso!')
        }
      })
      .catch(err => {
        console.error('erro ao adicionar convenio', err)
        this.$toast.error('Ocorreu um erro ao adicionar o convênio')
      }).finally(() => {
        this.addButtonLoading = false;
        this.getAdditionalHealthPlans(this.professionalId)
        this.selectedHealthPlan = {};
      })
    },
    removeAdditionalPlan(additionalPlanId){
      this.removeButtonLoading = true;
      this.api.removeAdditionalHealthPlan(additionalPlanId)
      .then(({data}) => {
        if(Object.keys(data).length){
          this.$toast.success('Convênio removido com sucesso!')
        }
      })
      .catch(err => {
        console.error('erro ao remover convenio', err)
        this.$toast.error('Ocorreu um erro ao remover o convênio')
      }).finally(() => {
        this.removeButtonLoading = false;
        this.getAdditionalHealthPlans(this.professionalId)
      })
    },
    getAdditionalHealthPlans(professionalId){
      this.additionalHealthPlans = []
      const payload = {
        clinic_id: this.clinic_id,
        person_id: professionalId
      }

      this.api.getAdditionalHealthPlans(payload)
      .then(({data}) => {
        if(data.length){
          const tempArray = []
          data.map(el => {
            const tempObj = {
                id: el.health_plan_id,
                additional_plan_id: el.id,
                name: el.health_plan_name,
                enabled: false
              }
              tempArray.push(tempObj)
          })
          this.additionalHealthPlans = tempArray
        }
      })
      .catch(err => {
        console.error('erro ao listar convenios', err)
        this.$toast.error('Ocorreu um erro ao listar convênios adicionais')
      }).finally(() => {
        this.addButtonLoading = false;
      })
    }
  },
  watch: {
    professionalId: {
      handler: function (val) {
        if(val){
          this.getTissSettings(val);
          this.getAdditionalHealthPlans(val);
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  font-family: 'Red Hat Display',serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.section-subtitle {
  font-family: 'Nunito Sans',serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.health-plan {
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-000);
  border-radius: 8px;
  box-shadow: 0 2px 3px 0 #0000001F;

  &__title {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0 16px;
  }

  &__search {
    display: flex;
    gap: 8px;
    padding: 16px;

    ::v-deep .multiselect {
      border-radius: 5px;
      border: 1px solid #C6CEEB;
      padding: 0 10px 0 10px;

    }
    ::v-deep .multiselect__spinner {
      background: none;
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    input {
      font-size: 16px;
    }

    button {
      font-size: 16px;
      font-weight: 600;
      padding: 3px 6px;
      border-radius: 5px;
      box-shadow: 0 3px 1px -2px #00000033;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 0 16px 0 16px;

    .pagination-container {
      display: flex;
      justify-content: space-between;
      padding: 16px 0 16px 0;
      gap: 16px;

      .page-information {
        white-space: nowrap;
        align-items: center;
        display: flex;
        gap: 8px;
      }
      .total-rows {
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
      }
    }

    .health-plans-table {
      margin-bottom: 0;
      border-radius: 8px;

      ::v-deep thead th {
        font-size: 16px;
        font-weight: 600;
      }

      ::v-deep tbody td {
        font-size: 14px;
        font-weight: 400;
      }

      ::v-deep thead th {
        border-top: none;
      }

      ::v-deep tr {
        line-height: 32px;
      }
    }

    .remove-additional-plan {
      opacity: 0.6;
      padding: 3px;
      font-size: 12px;
      font-weight: bold;
      border: none;
      background: none;
      transition: 0.3s;

      &:hover {
        opacity: 1;
      }
    }
  }

  .enabled-indicator {
    font-size: 14px;
    font-weight: 400;
    color: var(--type-placeholder);
    margin-left: 10px;
  }
}
</style>