const moduleRoute = {
    path: '/itens',
    component: () => import('@/views/Home.vue'),
    children: [
        { 
            path: '', 
            component: () => import('./views/Home.vue')
        },
    ]
}

export default router => { 
    router.addRoute(moduleRoute)
}