
<template>
  <div class="input-search-wrapper">
    <v-search class="search" />
    <b-form-input
      :id="id"
      class="input"
      :autocomplete="autocomplete"
      :debounce="debounce"
      :placeholder="placeholder"
      v-model="query"
    />
  </div>
</template>
<script>
import Search from '@/assets/icons/search.svg'
export default {
  props: {
    id: String,
    value: String,
    autocomplete: { type: String, default: 'off' },
    debounce: { type: String, default: '0'},
    placeholder: String,
  },
  components: {
    'v-search': Search,
  },
  data() {
    return {
      query: this.value,
    }
  },
  watch: {
    query() {
      this.$emit('input', this.query);
    },
    value(newVal) {
      this.query = newVal;
    }
  }
}
</script>
<style lang="scss" scoped>
  .input-search-wrapper {
    width: 100%;
    position: relative;

    .search {
      position: absolute;
      width: 20px;
      height: 20px;
      left: 5px;
      top: 8px;
      stroke: var(--neutral-500);
    }
    .input {
      text-indent: 20px;
    }
  }
</style>
