
export default {
    allRequiredFields: async state => {
        return state.requiredFields
    },
    consultationRequiredFields: state => {
        return state.requiredFields
    },
    spSadtRequiredFields: state => {
        return state.requiredFields
    },
}
