<template>
  <div class="academy-container">
    <div class="academy-title">Eyecare Academy</div>
    <div class="academy-content">
      <div class="header">
        <div class="profile">
          <Avatar size="64" :src="user.picture" />
          <div class="text">
            Olá 👋, <br />
            {{ (user.gender === null || user.gender === 'ND') ? '' : user.gender === 'M' ? 'Dr. ' : 'Dra. ' }} {{ parseName(user) }}
          </div>
        </div>
        <div class="search-wrapper">
          <InputSearch
            class="search"
            placeholder="Busque por um curso ou especialidade"
            v-model="query"
            debounce="300"
          />
        </div>
      </div>

      <div class="content">
        <div class="content-title">Últimas aulas</div>
        <div :id="`courses`" class="courses">
          <CourseCard
            v-for="course in courses"
            :key="course.id"
            :course="course"
          />
        </div>

        <div 
          v-if="courses.length"  
          class="pagination-position"
        >
          <b-pagination
            v-model="page"
            :total-rows="count"
            :per-page="limit"
            first-number
            last-number
            size="sm"
          />
        </div>

        <div v-if="!loading && !courses.length" class="empty-list">
          Estamos trabalhando para trazer os melhores conteúdos. <br/>
          Notificaremos você, assim que tivermos novidades!
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { track } from '@/utils/mixPanel'
import { getCurrentUser, getCurrentClinic } from '@/utils/localStorageManager'

export default {
  components: {
    Avatar: () => import('@/components/General/Avatar'),
    InputSearch: () => import('@/components/General/InputSearch'),
    CourseCard: () => import('@/components/Academy/CourseCard')
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      loading: false,
      query: '',
      courses: [],

      page: 1,
      count: 0, 
      limit: 0

    }
  },
  mounted() {
    this.getCoursers()

    this.introJs()
      .setOption('tooltipClass', 'onboarding')
      .setOption('dontShowAgain', true)
      .setOption('dontShowAgainLabel', 'Não mostrar novamente')
      .setOption('nextLabel', 'Próximo')
      .setOption('prevLabel', 'Anterior')
      .setOption('doneLabel', 'Finalizar')
      .setOptions({
        steps: [
          {
            title: 'Eyecare Academy',
            intro: `
              <div>
                Conheça a nova plataforma de educação do Eyecare BI, o.
                <span class="highlighted">Eyecare Academy</span>, criado para levar para vocês as melhores práticas dentro da oftalmologia.
              </div>`,
          },
          {
            title: 'Aulas',
            intro: 'Aqui você irá encontrar cursos e aulas disponíveis para assistir, mas fique atento, pois algumas aulas ficarão disponíveis por tempo limitado.',
            element: document.querySelector('#courses'),
          },
        ]
      })
      .start();

      track('opened-academy', {
        clinic: this.clinic.name,
        user: this.user.name
      })
  },
  methods: {
    parseName(user) {
      const parts = user.name.split(' ');
      if (parts.length === 1) return user.name
      return `${parts[0]} ${parts[parts.length -1]}`
    },
    getCoursers() {
      const loading = this.$loading.show()
      this.loading = true
      this.api.getCoursers(this.page, this.query)
      .then(res => {
        this.courses = res.data.data
        this.count = res.data.total
        this.limit = res.data.per_page
      })
      .finally(() => {
        loading.hide()
        this.loading = false
      })
    },
  },
  watch: {
    query() { this.getCoursers() },
    page() { this.getCoursers() },

  }
}
</script>
<style lang="scss" scoped>
.academy-container {
  padding: 24px;
  background: var(--neutral-100);
  min-height: 100vh;
  .academy-title {
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
  }
  .academy-content {
    background: white;
    border-radius: 8px;
    margin: 20px 0;

    .header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--neutral-200);
      .profile {
        display: flex;
        align-items: center;
        padding: 16px 24px;
        border-right: 1px solid var(--neutral-200);
        gap: 20px;

        .text {
          font-size: 18px;
          font-weight: 700;
          color: var(--dark-blue);
          white-space: nowrap;
        }
      }

      .search-wrapper {
        width: 100%;
        padding: 0 24px;
      }
    }

    .content {
      padding: 24px 16px;

      .content-title {
        font-weight: 700;
        font-size: 18px;
        color: var(--dark-blue);
        margin-bottom: 24px;
      }
      .empty-list {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .courses {
        width: 100%;
        flex-flow: row wrap;
        display: flex;
        gap: 10px;
      }
    }

  }
}
</style>
