<template>
  <div class="orientations">
    <div class="orientations__title">
      <span class="section-title">Orientações</span>
      <span v-if="type === 'professional'" class="section-subtitle">Adicione abaixo orientações sobre o médico.</span>
      <span v-else-if="type === 'clinic'" class="section-subtitle">Registre abaixo as informações relacionadas a clínica para facilitar a comunicação e planejamento do agendamento.</span>
      <span v-else class="section-subtitle">Registre abaixo as informações relacionadas ao convênio</span>
    </div>
    <div class="h-100">
      <VueEditor
        id="orientations"
        class="orientations__editor"
        v-model="orientations.description"
        :editorToolbar="customToolbar"
        :disabled="loading || (!editOwnData && !userCanEdit)"
      />
    </div>
    <button
        v-if="editOwnData || userCanEdit"
        class="btn btn-primary orientations__update-button"
        :disabled="loading"
        @click="saveOrientations()"
    >
      <b-spinner
          v-if="loading"
          small
          variant="primary"
          label="Spinning"
      />
      <span v-else>
        Atualizar
      </span>
    </button>
    <button
        v-else
        class="btn btn-primary orientations__update-button"
        :disabled="true"
    >
      <span>Atualizar</span>
    </button>
  </div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import { VueEditor } from "vue2-editor"
export default {
  components: {
    VueEditor
  },
  name: 'Orientations',
  props: ['relatedId', 'type', 'editOwnData', 'userCanEdit'],
  data() {
    return {
      clinic_id: getCurrentClinic().id,
      orientations: {id: null, description: ''},
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
      ],
      loading: false
    }
  },
  mounted(){
    this.getOrientations();
  },

  methods: {
    saveOrientations(){
      this.loading = true;
      const payload = {
        id: this.orientations.id,
        clinic_id: this.clinic_id,
        related_id: this.relatedId,
        type: this.type,
        description: this.orientations.description
      }
      this.api.updateOrientations(payload).then(({data}) => {
        if(Object.keys(data).length) {
          this.$toast.success('Orientações atualizadas com sucesso!')
          this.getOrientations();
        } 
      }).catch(error => {
        console.error('erro ao inserir orientaçao', error)
        this.$toast.error('Ocorreu um erro ao atualizar orientações')
      }).finally(() => {
        this.loading = false;
      })
    },
    getOrientations(){
      this.loading = true;
      this.orientations.description = '<h4><strong style="color: rgb(187, 187, 187);">Carregando...</strong></h4>'
      const payload = {
        clinic_id: this.clinic_id,
        related_id: this.relatedId,
        type: this.type,
      }

      this.api.getOrientations(payload).then(({data}) => {
        if(Object.keys(data).length) {
          this.orientations.id = data.id;
          this.orientations.description = data.description;
        } else {
          this.orientations.description = '';
        }
      }).catch(error => {
        console.error('erro ao buscar orientaçoes', error)
        this.$toast.error('Ocorreu um erro ao buscar orientações')
        this.orientations.description = '';
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  watch: {
    relatedId: {
      handler: function (val) {
        if(val){
          this.getOrientations();
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  font-family: 'Red Hat Display',serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.section-subtitle {
  font-family: 'Nunito Sans',serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.orientations {
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-000);
  border-radius: 8px;
  box-shadow: 0 2px 3px 0 #0000001F;
  padding: 16px;
  gap: 16px;

  &__title {
    display: flex;
    flex-direction: column;
  }

  &__editor {
    background-color: var(--neutral-000);
    min-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    :deep(.ql-container) {
      display: flex;
      flex-direction: column;
      flex: 1;
      border-radius: 0px 0px 8px 8px;
    }

    :deep(.ql-toolbar) {
      border-radius: 8px 8px 0px 0px;
      background-color: #F4F5FB;
    }
  }

  &__update-button {
    width: 95px;
    height: 35px;
    font-family: "Nunito Sans";
    font-size: 14px !important;
    font-weight: 400 !important;
    color: white;
    padding: 16px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
  }
}
</style>