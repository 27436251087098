<!--- View da folha de produção - Responsável pelo gerenciamento da folha de produção das clínica. 

Criado pelos desenvolvedores:
Richard Moraes
Carlos Oliveira
--->

<template>
<div>
<label for="codes">Gerar folha de produção mensal</label><br>
    <ul style="padding: 0px">
      <label for="sheet">Id da planilha</label><br>
        <input autocomplete="off" type="text" id="sheet" name="sheet" v-model="sheet"><br><br>

      <label for="clinics">Clinica</label><br>
        <select name="clinics" id="clinics" v-model="clinicSheet" @change="teste()">
          <option v-for="clinic in clinicsList" :key="clinic.id" :value="clinic.id">{{clinic.name}}</option>
        </select><br><br>

      <label for="year">Ano</label><br>
        <input type="number" id="year" name="year" v-model="year"><br><br>

      <label for="month">Mês</label><br>
        <input type="number" id="month" name="month" v-model="month"><br><br>

      <button @click="createSheet()">Gerar planilha</button><br><br>
    </ul>
</div>
</template>

<script>

export default {
name: 'ProductionSheet',
  data () {
    return {
        sheet: '1Xa38begVZymAeOBcmpWysd3DFkg3mXfEG9RbDIpet8I',
        year: '2021',
        month: '09',
        clinicSheet: '',
        clinicsList: []
    }
  },
  mounted () {
      this.clinicId = JSON.parse(localStorage.getItem('clinic'))

      if(this.clinicId.id !== '0478aac7-182a-4883-95b2-097a55588fdb'){
        this.$router.push('/visao-geral')  
      }

      this.api
        .getClinics()
        .then(res=> {
            for(var clinic of res.data){
                this.clinicsList.push(clinic)
            }
        })
        .finally(
            console.log(this.clinicsList)
        )
  },
  methods: {
    teste(){
      console.log(this.clinicSheet+' - '+this.year+' - '+this.month)
    },
      createSheet(){
          this.api
          .createProductionSheet(this.clinicSheet, this.sheet, this.year, this.month)
            .then(res => {
              alert('sucesso')
            })
            .catch(err => {
              alert('erro')
              console.log(err)
            })
      },
  }
}
</script>

<style lang="scss">
  
</style>
