<template>
  <div class="comment-wrapper">
    <Avatar size="48" :src="videoComment.person.picture" />
    <div class="comment-infos">
      <div class="doctor-name">
        {{videoComment.person.gender === 'M' ? 'Dr.' : 'Dra.'}} {{ videoComment.person.name }}
      </div>
      <div class="comment">
        <div v-if="editMode" class="edit-wrapper">
          <b-form-input
            v-model="temporarilyComment"
          />
          <b-button
            variant="default"
            @click="editMode = false"
          >
            Cancelar
          </b-button>
          <b-button
            variant="link"
            @click="updateComment"
          >
            Salvar
          </b-button>
        </div>
        <div v-else>{{ videoComment.comment }}</div>
      </div>
      <div class="actions">
        <div class="flex">
          <div class="action" :class="{ cursor: true, reaction: reaction && reaction.id }" @click="createOrRemoveCommentReaction">
            Curtir ({{ videoComment.comment_reactions_count }})
          </div>
          <div class="action">{{ parseTime(videoComment.created_at) }}</div>
        </div>
        <div>
          <div v-if="!editMode && user.id === videoComment.person_id" class="options">
            <MoreVertical class="more-icon" />
            <div class="menu">
              <b-button
                variant="link"
                @click="startEdit"
              >
                Editar
              </b-button>

              <b-button
                variant="link remove mb-0"
                @click="onDeleteClick"
              >
                Excluir
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { parseTime } from '@/utils/timeHelper'

export default {
  props: {
    user: Object,
    videoComment: Object,
    removeFromComments: Function,
  },
  components: {
    Avatar: () => import('@/components/General/Avatar'),
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
  },
  data() {
    return {
      reaction: null,
      editMode: false,
    }
  },
  created() {
    this.getReaction()
  },
  methods: {
    parseTime,
    getReaction() {
      this.api.getReaction(this.user.id, this.videoComment.id)
      .then(res => {
        this.reaction = res.data
      })
    },
    async createOrRemoveCommentReaction() {
      const data = {
        person_id: this.user.id,
        video_class_comment_id: this.videoComment.id,
        type: 'like'
      }
      const res = await this.api.createOrRemoveVideoCommentReaction(data)
      this.videoComment.comment_reactions_count += res.data.id ? 1 : -1
      this.reaction = res.data
    },
    startEdit() {
      this.temporarilyComment = this.videoComment.comment
      this.editMode = true
    },
    async updateComment() {
      const loading = this.$loading.show()
      try {
        await this.api.updateVideoClassComment(this.videoComment.id, { comment: this.temporarilyComment })
        this.videoComment.comment = this.temporarilyComment
        this.editMode = false
        loading.hide()
        this.$toast.success('Comentário atualizado com sucesso')
      } catch (err) {
        loading.hide()
        this.$toast.error(err.message)
      }

    },
    onDeleteClick() {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir este comentáio? Essa ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(async res => {
        if (res.isConfirmed) {
          const loading = this.$loading.show()
          try {
            await this.api.deleteVideoClassComment(this.videoComment.id)
            this.removeFromComments(this.videoComment)
            loading.hide()
            this.$toast.success('Comentário excluído com sucesso')
          } catch (err) {
            loading.hide()
            this.$toast.error(err.message)
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .comment-wrapper {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    gap: 20px;
    .doctor-name {
      font-weight: 700;
      font-size: 16px;
      color: var(--dak-blue);
      padding-bottom: 10px;
    }

    .comment-infos {
      width: 100%;
    }
    .comment {
      font-weight: 400;
      font-size: 16px;
      color: var(--type-active);
    }

    .edit-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
    }
    .actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      font-weight: 400;
      font-size: 16px;
      color: var(--type-placeholder);

      .action {
        font-size: 14px;
      }

      .flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }
    }
    .cursor {
      cursor: pointer;
    }
    .reaction {
      color: var(--blue-500);
    }
  }

  .options {
    cursor: pointer;
    width: 24px;
    height: 28px;
    position: relative;
    display: inline-block;

    .more-icon {
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      transition: all 0.5s;
      cursor: pointer;
    }

    .menu {
      width: 141px;
      position: absolute;
      top: 28px;
      right: 0;
      background: var(--neutral-000);
      border: 1px solid var(--neutral-200);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
        0px 4px 10px -8px rgba(12, 29, 89, 0.2);
      border-radius: 8px;
      padding: 0;
      opacity: 0;
      transition: all 0.5s;
      flex-direction: column;
      align-items: start;
      display: none;

      .mtb-12 {
        margin: 12px 0;
      }

      .btn {
        font-weight: 600;
        width: 100%;

        &.remove {
          color: var(--states-error);
        }

        &.btn-link {
          text-align: left;
          padding: 12px 18px !important;
        }
      }
    }

    &:hover {
      .more-icon {
        transform: rotate(90deg);
      }

      .menu {
        opacity: 1;
        display: flex;
        z-index: 10;
      }
    }
  }
</style>
