<template>
  <div v-can="'RepMedReg4'">
    <h1 class="title">Regra #{{ rule.number }} - {{ rule.name }}</h1>

    <hr />

    <b-row>
      <b-col cols="12" class="mb-4">
        <div class="configuration-card">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Favorecidos</h2>

            <v-chevron-down
              class="icon"
              v-if="!expandedBeneficiaries"
              @click="expandedBeneficiaries = true"
            />
            <v-chevron-up
              class="icon"
              v-else
              @click="expandedBeneficiaries = false"
            />
          </div>

          <b-row>
            <b-col cols="11">
              <b-form-group class="mt-2 mb-0">
                <multiselect
                  v-if="!(beneficiariesOptions && beneficiariesOptions.length)"
                  class="with-border multiple"
                  v-model="roles"
                  placeholder="Selecionar funções..."
                  :options="rolesOptions"
                  :allow-empty="true"
                  :option-height="40"
                  :showLabels="false"
                  :multiple="true"
                  :searchable="false"
                  :close-on-select="false"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <v-chevron-down />
                    </div>
                  </template>
                  <template slot="selection" slot-scope="{ values }">
                    <span v-if="allRolesSelected()"> Todas as opções </span>
                    <span v-else-if="values.indexOf('Todas') !== -1">
                        {{values.slice(values.indexOf('Todas')).join(', ')}}
                    </span>
                    <span v-else>
                      {{ values.join(', ') }}
                    </span>
                  </template>
                  <template slot="noOptions"> Nenhuma opção </template>
                  <template slot="noResult"
                    >Nenhum resultado encontrado</template
                  >
                </multiselect>

                <multiselect
                  v-else
                  class="with-border multiple"
                  v-model="beneficiaries"
                  placeholder="Selecionar favorecidos..."
                  :options="beneficiariesOptions"
                  :allow-empty="true"
                  :option-height="40"
                  :showLabels="false"
                  track-by="id"
                  label="label"
                  :multiple="true"
                  :searchable="false"
                  :close-on-select="false"
                > 
                  <template slot="caret">
                    <div class="chevron">
                      <v-chevron-down />
                    </div>
                  </template>

                  <template slot="selection" slot-scope="{ values }">
                    <span v-if="allBeneficiariesSelected()"> Todas as opções </span>
                    <span v-else-if="values.length">
                      {{values.length + (values.length > 1 ? ' favorecidos selecionados' : ' favorecido selecionado')}}
                    </span>
                  </template>
                  <template slot="noOptions"> Nenhuma opção </template>
                  <template slot="noResult">Nenhum resultado encontrado </template>
                </multiselect>

                <div
                  v-if="validated && !roles"
                  class="custom-invalid-feedback mt-0"
                >
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="1" class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="!(beneficiariesOptions && beneficiariesOptions.length)"
                variant="primary"
                @click="getBeneficiariesAndRoles()"
                :disabled="!roles.length"
                block
              >
                Procurar
              </b-button>

              <b-button
                v-else-if="beneficiaries && beneficiaries.length && beneficiariesOptions && beneficiariesOptions.length"
                variant="primary"
                @click="addBeneficiaries()"
                block
              >
                Adicionar
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="refreshBeneficiaries()"
                block
              >
                Voltar
              </b-button>
            </b-col>
          </b-row>

          <b-row v-show="expandedBeneficiaries" class="mx-button">
            <b-table-simple v-if="rule.setting.beneficiaries" striped>
              <tbody>
                <tr
                  v-for="(beneficiary, index) in rule.setting.beneficiaries"
                  :key="index"
                  class="tr-header"
                >
                  <td width="90%">{{ getBeneficiaryLabel(beneficiary) }}</td>
                  <td width="10%">
                    <v-delete
                      @click="deleteBeneficiary(beneficiary.id)"
                      class="icon delete"
                    />
                  </td>
                </tr>
              </tbody>
            </b-table-simple>
          </b-row>
        </div>
      </b-col>

      <b-col cols="12" class="mb-4">
        <div class="configuration-card">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Itens</h2>

            <v-chevron-down
              class="icon"
              v-if="!expandedItems"
              @click="expandedItems = true"
            />
            <v-chevron-up class="icon" v-else @click="expandedItems = false" />
          </div>

          <b-row>
            <b-col cols="11">
              <ItemTwoStepSelect v-model="items" :ruleItems="rule?.setting?.items" />
            </b-col>
            <b-col cols="1">
              <b-button
                variant="primary"
                block
                @click="additems()"
              >
                Adicionar
              </b-button>
            </b-col>

            <!-- <b-col cols="11">
              <b-form-group class="mt-2 mb-0">
                <multiselect
                  v-if="!(itemsOptions && itemsOptions.length)"
                  class="with-border multiple"
                  v-model="types"
                  placeholder="Selecionar tipos..."
                  :options="typesOptions"
                  :allow-empty="true"
                  :option-height="40"
                  :showLabels="false"
                  :multiple="true"
                  :searchable="false"
                  :close-on-select="false"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <v-chevron-down />
                    </div>
                  </template>
                  <template slot="selection" slot-scope="{ values }">
                    <span v-if="allTypesSelected()"> Todas as opções </span>
                    <span v-else-if="values.indexOf('Todas') !== -1">
                        {{values.slice(values.indexOf('Todas')).join(', ')}}
                    </span>
                    <span v-else>
                      {{ values.join(', ') }}
                    </span>
                  </template>
                  <template slot="noOptions"> Nenhuma opção </template>
                  <template slot="noResult"
                    >Nenhum resultado encontrado</template
                  >
                </multiselect>

                <multiselect
                  v-else
                  class="with-border multiple"
                  v-model="items"
                  placeholder="Selecionar itens..."
                  :options="itemsOptions"
                  :allow-empty="true"
                  :option-height="40"
                  :showLabels="false"
                  track-by="id"
                  label="label"
                  :multiple="true"
                  :searchable="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <v-chevron-down />
                    </div>
                  </template>

                  <template slot="selection" slot-scope="{ values }">
                    <span v-if="allItemsSelected()"> Todas as opções </span>
                    <span v-else-if="values.length">
                      {{values.length + (values.length > 1 ? ' itens selecionados' : ' item selecionado')}}
                    </span>
                  </template>
                  <template slot="noOptions"> Nenhuma opção </template>
                  <template slot="noResult"
                    >Nenhum resultado encontrado</template
                  >
                </multiselect>

                <div
                  v-if="validated && !roles"
                  class="custom-invalid-feedback mt-0"
                >
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="1" class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="!(itemsOptions && itemsOptions.length)"
                variant="primary"
                @click="getItemsAndType()"
                block
                :disabled="!types.length"
              >
                Procurar
              </b-button>

              <b-button
                v-else-if="
                  items && items.length && itemsOptions && itemsOptions.length
                "
                variant="primary"
                block
                @click="additems()"
              >
                Adicionar
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="refreshItems()"
                block
              >
                Voltar
              </b-button>
            </b-col> -->
          </b-row>

          <b-row v-show="expandedItems" class="mx-button">
            <b-table-simple v-if="rule.setting.items" striped>
              <tbody>
                <tr
                  v-for="(item, index) in rule.setting.items"
                  :key="index"
                  class="tr-header"
                >
                  <td width="90%">{{  item.item.code ? item.item.code + ' - ' : '' }} {{ ITEM_CONSTANTS.parseTypeToLabel(item.item.type) }} - {{ item.item.name }}</td>
                  <td width="10%">
                    <v-delete
                      @click="deleteItem(item.id, index)"
                      class="icon delete"
                    />
                  </td>
                </tr>
              </tbody>
            </b-table-simple>
          </b-row>
        </div>
      </b-col>

      <b-col cols="12" class="mb-4">
        <div class="configuration-card">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Convênios</h2>

            <v-chevron-down
              class="icon"
              v-if="!expandedHealthPlans"
              @click="expandedHealthPlans = true"
            />
            <v-chevron-up class="icon" v-else @click="expandedHealthPlans = false" />
          </div>

          <b-row>
            <b-col cols="11">
              <b-form-group class="mt-2 mb-0">
                <!-- <multiselect
                  v-if="!(healthPlanOptions?.length)"
                  class="with-border multiple"
                  v-model="types"
                  placeholder="Selecionar convênios..."
                  :options="typesOptions"
                  :allow-empty="true"
                  :option-height="40"
                  :showLabels="false"
                  :multiple="true"
                  :searchable="false"
                  :close-on-select="false"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <v-chevron-down />
                    </div>
                  </template>
                  <template slot="selection" slot-scope="{ values }">
                    <span v-if="allTypesSelected()"> Todas as opções </span>
                    <span v-else-if="values.indexOf('Todas') !== -1">
                        {{values.slice(values.indexOf('Todas')).join(', ')}}
                    </span>
                    <span v-else>
                      {{ values.join(', ') }}
                    </span>
                  </template>
                  <template slot="noOptions"> Nenhuma opção </template>
                  <template slot="noResult">Nenhum resultado encontrado</template>
                </multiselect> -->

                <multiselect
                  class="with-border multiple"
                  v-model="clinicHealthPlans"
                  placeholder="Selecionar convênios..."
                  :options="healthPlanOptions"
                  :allow-empty="true"
                  :option-height="40"
                  :showLabels="false"
                  track-by="id"
                  label="label"
                  :multiple="true"
                  :searchable="true"
                  :close-on-select="false"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <v-chevron-down />
                    </div>
                  </template>

                  <template slot="selection" slot-scope="{ values }">
                    <span v-if="allHealthPlanSelected()"> Todas as opções </span>
                    <span v-else-if="values.length">
                      {{values.length + (values.length > 1 ? ' convênios selecionados' : ' convênio selecionado')}}
                    </span>
                  </template>
                  <template slot="noOptions"> Nenhuma opção </template>
                  <template slot="noResult"
                    >Nenhum resultado encontrado</template
                  >
                </multiselect>

                <div
                  v-if="validated && !roles"
                  class="custom-invalid-feedback mt-0"
                >
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="1" class="d-flex align-items-center justify-content-end">
              <!-- <b-button
                v-if="!healthPlanOptions?.length"
                variant="primary"
                @click="getItemsAndType()"
                block
                :disabled="!types.length"
              >
                Procurar
              </b-button> -->

              <b-button
                variant="primary"
                block
                :disabled="!clinicHealthPlans?.length || !healthPlanOptions?.length"
                @click="addHealthPlans()"
              >
                Adicionar
              </b-button>
            </b-col>
          </b-row>

          <b-row v-show="expandedHealthPlans" class="mx-button">
            <b-table-simple v-if="rule.setting.onlending_clinic_health_plans" striped>
              <tbody>
                <tr
                  v-for="(item, index) in rule.setting?.onlending_clinic_health_plans"
                  :key="index"
                  class="tr-header"
                >
                  <td width="90%">{{ item.clinic_health_plan?.health_plan?.fantasy_name }}</td>
                  <td width="10%">
                    <v-delete
                      @click="deleteClinicHealthPlan(item.id)"
                      class="icon delete"
                    />
                  </td>
                </tr>
              </tbody>
            </b-table-simple>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '@/modules/onlending/api.js'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import ChevronUp from '@/assets/icons/chevron-up.svg'
import Delete from '@/assets/icons/delete.svg'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { showFeature } from '@/utils/permissionsHelp'
import * as ITEM_CONSTANTS from '@items/utils/constants'

export default {
  name: 'Settings',
  metaInfo: {
    title: 'Eyecare - Regras de repasse - Configurações'
  },
  props: {
    rule: Object,
    validated: Boolean,
    getRule: Function
  },
  components: {
    ItemTwoStepSelect: () => import('@/modules/items/components/ItemTwoStepSelect'),
    'v-chevron-down': ChevronDown,
    'v-chevron-up': ChevronUp,
    'v-delete': Delete
  },
  data() {
    return {
      ITEM_CONSTANTS,
      clinic: getCurrentClinic(),

      expandedBeneficiaries: true,
      expandedItems: true,
      expandedHealthPlans: true,

      roles: [],
      rolesOptions: [
        'Todas as opções',
        'Enfermeiro',
        'Gestor',
        'Médico',
        'Médico-gestor',
        'Anestesista',
        'Secretária',
        'Técnico',
        'Tecnólogo',
        'Externo'
      ],
      canChangeRoles: true,
      beneficiaries: [],
      beneficiariesOptions: [],
      canChangeBeneficiaries: true,

      types: [],
      typesOptions: [
        'Todas as opções',
        'Consulta',
        'Exame',
        showFeature('Centro cirúrgico') ? 'Centro cirúrgico' : null,
        'Procedimento',
        'Telemedicina',
        'Retorno',
        'Produtos'
      ].filter(type => type),

      canChangeTypes: true,
      items: [],
      itemsOptions: [],
      canChangeItems: true,

      clinicHealthPlans: [],
      healthPlanOptions: [],
      canChangeHealthPlans: true
    }
  },
  mounted() {
    this.getAllClinicHealthPlans()
  }, 
  methods: {
    allRolesSelected() {
      return (
        this.roles.indexOf('Todas as opções') >= 0 ||
        (this.roles.indexOf('Todas as opções') === -1 &&
          this.roles.length === this.rolesOptions.length - 1)
      )
    },
    getBeneficiariesAndRoles() {
      const data = {
        clinic_id: this.clinic.id,
        roles: this.roles
      }
      this.api.getBeneficiariesAndRoles(data)
      .then(res => {
        res.data.map(data => {
          if (!this.rule?.setting?.beneficiaries.find(item => item.beneficiary_id === data.id)) {
            this.beneficiariesOptions.push(data)
          }
        })
      })
      .catch ((error) => {
        this.$toast.error(error.message)
      })
      .finally(() => {
        if (!this.beneficiariesOptions.length) this.$toast.info('Nenhum beneficiario encontrado deste tipo! Escolha outras.')
        else this.beneficiariesOptions.unshift({
          label: 'Todas as opções',
          id: null
        })
      })     
    },
    refreshBeneficiaries() {
      this.beneficiaries = []
      this.beneficiariesOptions = []
    },
    allBeneficiariesSelected() {
      return (
        this.beneficiaries.findIndex(opt => opt.label === 'Todas as opções') >= 0 ||
        (this.beneficiaries.findIndex(opt => opt.label === 'Todas as opções') === -1 &&
        this.beneficiaries.length === this.beneficiariesOptions.length - 1)
      )
    },
    getBeneficiaryLabel(beneficiary) {
      if(beneficiary.beneficiary.type === 'CLINIC_PROFESSIONAL' && beneficiary?.beneficiary?.person?.clinic_person){ 
        switch (beneficiary.beneficiary.person.clinic_person.role) {
          case 'SECRETARY':
            return 'Secretária - ' + beneficiary.beneficiary.name
          case 'NURSE':
            return 'Enfermeiro - ' + beneficiary.beneficiary.name
          case 'TECHNICIAN':
            return 'Técnico - ' + beneficiary.beneficiary.name
          case 'TECHNOLOGIST':
            return 'Tecnólogo - ' + beneficiary.beneficiary.name
          case 'DOCTOR':
            return (
              'Médico - ' +
              (beneficiary.beneficiary.person.gender === 'F' ? 'Dra. ' : 'Dr. ') +
              beneficiary.beneficiary.name
            )
          case 'DOCTOR_ANESTHESTIST':
          case 'DOCTOR_ANESTHETIST':
            return (
              'Anestesista - ' +
              (beneficiary.beneficiary.person.gender === 'F' ? 'Dra. ' : 'Dr. ') +
              beneficiary.beneficiary.name
            )
          case 'DOCTOR_MANAGER':
            return (
              'Médico Gestor - ' +
              (beneficiary.beneficiary.person.gender === 'F' ? 'Dra. ' : 'Dr. ') +
              beneficiary.beneficiary.name
            )
          case 'MANAGER':
            return 'Gestor - ' + beneficiary.beneficiary.name
          default:
            return(
              beneficiary.beneficiary.person.clinic_person.role +
              ' - ' +
              beneficiary.beneficiary.name
            )
        }
      }
      else{
        return 'Externo -' +beneficiary.beneficiary.name
      }
    },
    async addBeneficiaries() {
      const isLoading = this.$loading.show()
      const index = this.beneficiaries.findIndex(opt => opt.label === 'Todas as opções')
      if(index !== -1) {
        this.canChangeBeneficiaries = false
        this.beneficiaries.splice(index, 1)
      }
      const data = {
        rule_setting_id: this.rule.setting.id,
        beneficiaries: this.beneficiaries.map(data => {
          return data.id
        })
      }
      try {
        await api.createOnlendingBeneficiaries(data)
        this.$toast.success('Favorecido criado com sucesso')
        this.getRule()        
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
        this.refreshBeneficiaries()
        this.canChangeBeneficiaries = true
      }
    },
    async deleteBeneficiary(id) {
      if (id) {
        const isLoading = this.$loading.show()
        try {
          await api.deleteOnlendingBeneficiary(id)
          this.$toast.success('Favorecido removido com sucesso')
          this.getRule()
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      }
    },

    allTypesSelected() {
      return (
        this.types.indexOf('Todas as opções') >= 0 ||
        (this.types.indexOf('Todas as opções') === -1 &&
          this.types.length === this.typesOptions.length - 1)
      )
    },
    getItemsAndType() {
      const data = {
        clinic_id: this.clinic.id,
        types: this.types
      }
      api.getItemsAndTypes(data)
      .then(res => {
        res.data.map(data => {
          if (
            !this.rule.setting.items.find(item => item.item_id === data.id)
          ) {
            this.itemsOptions.push(data)
          }
        })
      })
      .catch ((error) => {
        this.$toast.error(error.message)
      })
      .finally(() => {
        if (!this.itemsOptions.length) this.$toast.info('Nenhum item encontrado deste tipo! Escolha outros.')
        else this.itemsOptions.unshift({
          label: 'Todas as opções',
          id: null
        })
      })
    },
    refreshItems() {
      this.items = []
      this.itemsOptions = []
    },
    allItemsSelected() {
      return (
        this.items.findIndex(opt => opt.label === 'Todas as opções') >= 0 ||
        (this.items.findIndex(opt => opt.label === 'Todas as opções') === -1 &&
        this.items.length === this.itemsOptions.length - 1)
      )
    },
    async additems() {
      const isLoading = this.$loading.show()
      const data = {
        rule_setting_id: this.rule.setting.id,
        items: this.items.map(item => ({ item_id: item.id }))
      }
      try {
        await api.createOnlendingItems(data)
        this.$toast.success('Item criado com sucesso')
        this.getRule()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
        this.refreshItems()
        this.canChangeItems = true
      }
    },
    async deleteItem(id) {
      if (id) {
        const isLoading = this.$loading.show()
        try {
          await api.deleteOnlendingItem(id)
          this.$toast.success('Item removido com sucesso')
          this.getRule()
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      }
    },
    getAllClinicHealthPlans() {
      this.api.getAllClinicHealthPlans(this.clinic.id)
      .then(({ data }) => {
        this.populateClinicHealthPlanOptions(data)
      })
      .catch(err => console.log(err))
      .finally(() => {})
    },
    populateClinicHealthPlanOptions(data) {
      const selectedClinicHealthPlans = this.rule.setting.onlending_clinic_health_plans.map(el => el.clinic_health_plan_id)
      this.healthPlanOptions = data.filter(el => !selectedClinicHealthPlans.includes(el.id)).map(item => {
          return {
            id: item.id,
            label: item.health_plan.fantasy_name
          }
        })
    },
    allHealthPlanSelected() {
      return (
        this.clinicHealthPlans.findIndex(opt => opt.label === 'Todas as opções') >= 0 ||
        (this.clinicHealthPlans.findIndex(opt => opt.label === 'Todas as opções') === -1 &&
        this.clinicHealthPlans.length === this.healthPlanOptions.length - 1)
      )
    },
    async addHealthPlans() {
      const isLoading = this.$loading.show()
      const index = this.items.findIndex(opt => opt.label === 'Todas as opções')
      if(index !== -1) {
        this.canChangeHealthPlans = false
        this.items.splice(index, 1)
      }
      const data = {
        rule_setting_id: this.rule.setting.id,
        clinic_health_plans: this.clinicHealthPlans.map(data => ({
          id: data.id,
        }))
      }
      try {
        await api.createOnlendingClinicHealthPlans(data)
        this.$toast.success('Item(s) adicionado(s) com sucesso')
        this.getRule()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
        this.refreshHealthPlans()
        this.canChangeHealthPlans = true
      }
    },
    async deleteClinicHealthPlan(id) {
      if (!id) return
      const isLoading = this.$loading.show()
      try {
        await api.deleteOnlendingClinicHealthPlan(id)
        this.$toast.success('Convênio removido com sucesso')
        this.getRule()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    refreshHealthPlans() {
      this.healthPlanOptions = this.healthPlanOptions.filter(el => !this.clinicHealthPlans.some(item => item.id === el.id))
      this.clinicHealthPlans = []
    },

    closeModal() {
      this.rule = {}
    }
  },
  watch: {
    roles: function (newValue, oldValue) {
      if (this.canChangeRoles) {
        if (
          oldValue.indexOf('Todas as opções') === -1 &&
          newValue.indexOf('Todas as opções') >= 0
        ) {
          this.canChangeRoles = false
          this.roles = this.rolesOptions
          setTimeout(() => {
            this.canChangeRoles = true
          }, '200')
        } else if (
          oldValue.indexOf('Todas as opções') >= 0 &&
          newValue.indexOf('Todas as opções') === -1
        ) {
          this.canChangeRoles = false
          this.roles = []
          setTimeout(() => {
            this.canChangeRoles = true
          }, '200')
        } else if (
          oldValue.indexOf('Todas as opções') >= 0 &&
          oldValue.length === this.rolesOptions.length &&
          newValue.indexOf('Todas as opções') >= 0 &&
          newValue.length === this.rolesOptions.length
        ) {
          this.canChangeRoles = false
          this.roles.splice(this.roles.indexOf('Todas as opções'), 1)
          setTimeout(() => {
            this.canChangeRoles = true
          }, '200')
        } else if (
          this.allRolesSelected() &&
          newValue.indexOf('Todas as opções') === -1
        ) {
          this.canChangeRoles = false
          this.roles.push(
            this.rolesOptions[this.rolesOptions.indexOf('Todas as opções')]
          )
          setTimeout(() => {
            this.canChangeRoles = true
          }, '200')
        } else {
          this.canChangeRoles = false
          this.roles = this.roles.filter(el => el !== 'Todas as opções')
          setTimeout(() => {
            this.canChangeRoles = true
          }, '200')
        }
      }
    },
    beneficiaries: function (newValue, oldValue) {
      if (this.canChangeBeneficiaries) {
        if (
          oldValue.findIndex(opt => opt.label === 'Todas as opções') === -1 &&
          newValue.findIndex(opt => opt.label === 'Todas as opções') >= 0
        ) {
          this.canChangeBeneficiaries = false
          this.beneficiaries = this.beneficiariesOptions
          setTimeout(() => {
            this.canChangeBeneficiaries = true
          }, '200')
        } else if (
          oldValue.findIndex(opt => opt.label === 'Todas as opções') >= 0 &&
          newValue.findIndex(opt => opt.label === 'Todas as opções') === -1
        ) {
          this.canChangeBeneficiaries = false
          this.beneficiaries = []
          setTimeout(() => {
            this.canChangeBeneficiaries = true
          }, '200')
        } else if (
          oldValue.findIndex(opt => opt.label === 'Todas as opções') >= 0 &&
          oldValue.length === this.beneficiariesOptions.length &&
          newValue.findIndex(opt => opt.label === 'Todas as opções') >= 0 &&
          newValue.length === this.beneficiariesOptions.length
        ) {
          this.canChangeBeneficiaries = false
          this.beneficiaries.splice(this.beneficiaries.findIndex(opt => opt.label === 'Todas as opções'), 1)
          setTimeout(() => {
            this.canChangeBeneficiaries = true
          }, '200')
        } else if (
          this.allBeneficiariesSelected() &&
          newValue.findIndex(opt => opt.label === 'Todas as opções') === -1
        ) {
          this.canChangeBeneficiaries = false
          this.beneficiaries.push(
            this.beneficiariesOptions[this.beneficiariesOptions.findIndex(opt => opt.label === 'Todas as opções')]
          )
          setTimeout(() => {
            this.canChangeBeneficiaries = true
          }, '200')
        } else {
          this.canChangeBeneficiaries = false
          this.beneficiaries = this.beneficiaries.filter(el => el.label !== 'Todas as opções')
          setTimeout(() => {
            this.canChangeBeneficiaries = true
          }, '200')
        }

        this.canChangeBeneficiaries = false
        this.beneficiaries = this.beneficiaries.filter(function (i) {
          return i;
        })
        setTimeout(() => {
            this.canChangeBeneficiaries = true
          }, '300')
      }
    },

    types: function (newValue, oldValue) {
      if (this.canChangeTypes) {
        if (
          oldValue.indexOf('Todas as opções') === -1 &&
          newValue.indexOf('Todas as opções') >= 0
        ) {
          this.canChangeTypes = false
          this.types = this.typesOptions
          setTimeout(() => {
            this.canChangeTypes = true
          }, '200')
        } else if (
          oldValue.indexOf('Todas as opções') >= 0 &&
          newValue.indexOf('Todas as opções') === -1
        ) {
          this.canChangeTypes = false
          this.types = []
          setTimeout(() => {
            this.canChangeTypes = true
          }, '200')
        } else if (
          oldValue.indexOf('Todas as opções') >= 0 &&
          oldValue.length === this.typesOptions.length &&
          newValue.indexOf('Todas as opções') >= 0 &&
          newValue.length === this.typesOptions.length
        ) {
          this.canChangeTypes = false
          this.types.splice(this.types.indexOf('Todas as opções'), 1)
          setTimeout(() => {
            this.canChangeTypes = true
          }, '200')
        } else if (
          this.allTypesSelected() &&
          newValue.indexOf('Todas as opções') === -1
        ) {
          this.canChangeTypes = false
          this.types.push(
            this.typesOptions[this.typesOptions.indexOf('Todas as opções')]
          )
          setTimeout(() => {
            this.canChangeTypes = true
          }, '200')
        } else {
          this.canChangeTypes = false
          this.types = this.types.filter(el => el !== 'Todas as opções')
          setTimeout(() => {
            this.canChangeTypes = true
          }, '200')
        }
      }
    },
    items: function (newValue, oldValue) {
      if (this.canChangeItems) {
        if (
          oldValue.findIndex(opt => opt.label === 'Todas as opções') === -1 &&
          newValue.findIndex(opt => opt.label === 'Todas as opções') >= 0
        ) {
          this.canChangeItems = false
          this.items = this.itemsOptions
          setTimeout(() => {
            this.canChangeItems = true
          }, '200')
        } else if (
          oldValue.findIndex(opt => opt.label === 'Todas as opções') >= 0 &&
          newValue.findIndex(opt => opt.label === 'Todas as opções') === -1
        ) {
          this.canChangeItems = false
          this.items = []
          setTimeout(() => {
            this.canChangeItems = true
          }, '200')
        } else if (
          oldValue.findIndex(opt => opt.label === 'Todas as opções') >= 0 &&
          oldValue.length === this.itemsOptions.length &&
          newValue.findIndex(opt => opt.label === 'Todas as opções') >= 0 &&
          newValue.length === this.itemsOptions.length
        ) {
          this.canChangeItems = false
          this.items.splice(this.items.findIndex(opt => opt.label === 'Todas as opções'), 1)
          setTimeout(() => {
            this.canChangeItems = true
          }, '200')
        } else if (
          this.allItemsSelected() &&
          newValue.findIndex(opt => opt.label === 'Todas as opções') === -1
        ) {
          this.canChangeItems = false
          this.items.push(
            this.itemsOptions[this.itemsOptions.findIndex(opt => opt.label === 'Todas as opções')]
          )
          setTimeout(() => {
            this.canChangeItems = true
          }, '200')
        } else {
          this.canChangeItems = false
          this.items = this.items.filter(el => el.label !== 'Todas as opções')
          setTimeout(() => {
            this.canChangeItems = true
          }, '200')
        }

        this.canChangeItems = false
        this.items = this.items.filter(function (i) {
          return i;
        })
        setTimeout(() => {
          this.canChangeItems = true
        }, '300')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin: 32px 0px;
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 134%;
  letter-spacing: -0.01em;
  color: #0c1d59;
}

.configuration-card {
  border: 1px solid #c6ceeb;
  border-radius: 8px;
  padding: 24px 16px;

  h2 {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--dark-blue);
  }
}
.icon {
  width: 28px;
  height: 28px;
  cursor: pointer;
  float: right;

  :hover {
    stroke: gray;
  }
}

.mx-button{
  margin: 5px 0px
}
</style>
