<template>
  <div class="call-panel-container">
    <div class="call-panel-settings-header">
      <div class="title">Painel de chamadas</div>
    </div>
    <section class="call-panel-content">
      <Tab
        :index="currentTabIndex"
        v-bind:keys="this.tabs"
        @onChange="onChangeTab"
      >
      <v-departments-tab v-if="currentTabIndex === 0" />
      <v-panels-tab v-else-if="currentTabIndex === 1" />
      <v-settings-tab v-else-if="currentTabIndex === 2" />
      <v-totem-activator v-else-if="currentTabIndex === 3" />
      </Tab>
    </section>
  </div>
</template>
<script>
import Tab from '@/components/Tab'
import Settings from './Settings'
import Departments from './Departments'
import TotemActivator from './TotemActivator'
import Panels from './Panels'
export default {
  metaInfo: {
    title: 'Eyecare - Painel de Chamadas'
  },
  components: {
    Tab,
    'v-settings-tab': Settings,
    'v-departments-tab': Departments,
    'v-totem-activator': TotemActivator,
    'v-panels-tab': Panels
  },
  mounted() {
    Object.keys(this.tabKeys).map(key => {
      // userHasPermission(key) &&
        this.tabs.push(this.tabKeys[key])
    })
    this.handleTabIndex()
  },
  data() {
    return {
      currentTabIndex: 0,
      tabs: [],
      tabKeys: {
        'CpDep1':'Departamentos e setores',
        'CpPan1':'Painéis',
        'CpSet1':'Configurações',
        'CpTot1':'Ativar totem',
      }
    }
  },
  methods: {
    onChangeTab(index, dontPush) {
      this.currentTabIndex = index
      if (!dontPush) {
        window.history.pushState(
          null,
          null,
          `/painel-de-chamadas/${this.getPathByIndex(index)}`
        )
      }
    },
    handleTabIndex() {
      const routes = {
        departments: 0,
        panels: 1,
        settings: 2,
        activate_totem: 3,
        default: 0
      }
      this.currentTabIndex = routes[this.$route.params.tab] || routes.default
    },
    getPathByIndex(index) {
      const path = {
        0: 'departments',
        1: 'panels',
        2: 'settings',
        3: 'activate_totem',
      }
      return path[index]
    }
  }
}
</script>

<style lang="scss" scoped>
.call-panel-container {
  padding: 40px 24px;
}
.call-panel-content {
  background-color: white;
  border-radius: 8px;
  min-height: 80vh;
}
.call-panel-settings-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.title {
  margin-bottom: 0px;
}
.tv-icon {
  cursor: pointer;
}
</style>
