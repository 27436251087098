export default {
  tissHealthPlansOptions(state) {
    const options = state.tissClinicHealthPlans.map(el => ({ label: el.health_plan.fantasy_name, value: el }))
    options.unshift({ label: 'Todas as operadoras', value: null })
    return options
  },
  tissHealthPlans(state) {
    return state.tissClinicHealthPlans
  }
}
