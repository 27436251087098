<template>
  <div class="admin-container">
    <div class="menu">
      <div class="menu-title">
        Painel Admin
        <LogOut class="icon" @click="$router.push('/')" />
      </div>
      <div class="scroll-wrapper">
        <div
          v-for="link in links"
          :key="link.key"
          :class="{ 'nav-item': true, 'selected': content === link }"
          @click="content = link"
        >
          {{ link.label }}
        </div>
      </div>
    </div>
    <div class="content">
      <div v-if="content.showClinics" class="top-bar">
        <multiselect
          class="with-border"
          v-model="clinicSelected"
          :options="clinics"
          track-by="id"
          label="name"
          placeholder="Selecionar clínica"
          :showLabels="false"
          :searchable="true"
          :allowEmpty="false"
          :preselect-first="true"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>

          <template slot="noOptions"> Nenhuma opção </template>

          <template slot="noResult"> Nenhum resultado </template>
        </multiselect>
      </div>

      <UsersLogin
        v-if="content.key === 'users-login'"
        :clinic="clinicSelected"
      />

      <Clinics
        v-if="content.key === 'clinics'"
        :clinics="clinics"
        :getAllClinics="getAllClinics"
      />

      <NewClinic v-if="content.key === 'new-clinic'" />

      <Invoices v-if="content.key === 'invoices'" />

      <Features
        v-if="content.key === 'features'"
        :clinics="clinics"
      />

      <ManageCodes
        v-if="content.key === 'manage-codes'"
        :clinic="clinicSelected"
      />

      <Onlendings
        v-if="content.key === 'onlendings'"
        :clinic="clinicSelected"
      />

      <CsvImporter
        v-if="content.key === 'import-csv'"
        :clinic="clinicSelected"
      />

      <Protocols v-if="content.key === 'protocols'" />

      <AcademyAdmin v-if="content.key === 'academy'" />

      <ExamRequestAdmin v-if="content.key === 'exam-request'" />

      <NotificationsAdmin v-if="content.key === 'notifications'" />

      <SurgicalNote v-if="content.key === 'surgicalNote'"/>

      <MedicalReport v-if="content.key === 'medicalReport'"/>

      <Orientation v-if="content.key === 'orientation'"/>

      <RootApiKeys v-if="content.key === 'apiKeys'"/>

      <HomeAdmin v-if="content.key === 'feature-requests'"/>
    </div>
  </div>
</template>
<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'
import LogOut from '@/assets/icons/log-out.svg'

export default {
  components: {
    ChevronDown,
    LogOut,

    AcademyAdmin: () => import('@/views/RootManager/AcademyAdmin'),
    Clinics: () => import('@/views/RootManager/Clinics'),
    CsvImporter: () => import('@/views/RootManager/CsvImporter'),
    ExamRequestAdmin: () => import('@/views/RootManager/ExamRequestAdmin'),
    Features: () => import('@/views/RootManager/Features'),
    ManageCodes: () => import('@/views/RootManager/ManageCodes'),
    Onlendings: () => import('@/views/RootManager/Onlendings'),
    MedicalReport: () => import('@/views/RootManager/MedicalReport'),
    NewClinic: () => import('@/views/RootManager/NewClinic'),
    NotificationsAdmin: () => import('@/views/RootManager/NotificationsAdmin'),
    Orientation: () => import('@/views/RootManager/Orientation'),
    Protocols: () => import('./Protocols.vue'),
    RootApiKeys: () => import('@/views/RootManager/RootApiKeys'),
    SurgicalNote: () => import('./SurgicalNote.vue'),
    UsersLogin: () => import('@/views/RootManager/UsersLogin'),
    HomeAdmin: () => import('@/modules/featureRequests/views/HomeAdmin'),
    Invoices: () => import('@/views/RootManager/Invoices.vue')
  },
  mounted() {
    const clinic = JSON.parse(localStorage.getItem('clinic'));
    if(clinic.id !== '221b7dba-7986-47ac-aaa1-ede896820dcb') {
      this.$router.push('/visao-geral')
    }
    this.getAllClinics()
  },
  data() {
    return {
      content: { label: 'Users', key: 'users-login', showClinics: true },
      clinicSelected: null,
      clinics: [],
      links: [
        { label: 'Users', key: 'users-login', showClinics: true },
        { label: 'Notas Fiscais', key: 'invoices' },
        { label: 'Clínicas', key: 'clinics', showClinics: false },
        { label: 'Nova clínica', key: 'new-clinic', showClinics: false },
        { label: 'Gerenciar códigos', key: 'manage-codes', showClinics: true },
        { label: 'Repasse médico', key: 'onlendings', showClinics: true },
        { label: 'Features', key: 'features', showClinics: false },
        { label: 'Importar csv', key: 'import-csv', showClinics: true },
        { label: 'Protocolos', key: 'protocols', showClinics: false },
        { label: 'Academy', key: 'academy', showClinics: false },
        { label: 'Solicitação de exames', key: 'exam-request', showClinics: false },
        { label: 'Notificações', key: 'notifications', showClinics: false },
        { label: 'Anotação cirúrgica', key: 'surgicalNote', showClinics: false },
        { label: 'Laudo médico', key: 'medicalReport', showClinics: false },
        { label: 'Orientação ao paciente', key: 'orientation', showClinics: false },
        { label: 'API Keys', key: 'apiKeys', showClinics: false },
        { label: 'Solicitações de clientes', key: 'feature-requests' },
      ]
    }
  },
  methods: {
    getAllClinics() {
      const loading = this.$loading.show()
      this.api
        .getAllClinics(true)
        .then(res => {
          this.clinics = res.data
        })
        .finally(() => {
          loading.hide()
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-container {
  margin: 0;
  width: 99%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  .menu {
    display: flex;
    max-width: 250px;
    min-width: 250px;
    flex-direction: column;
    flex-grow: 1;
    color: var(--default-white);
    background-color: var(--dark-blue);
    min-height: 100vh;


    .menu-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 87px;
      text-align: left;
      padding: 30px 20px;
      font-size: 18px;
      font-weight: 700;
      border-bottom: 1px solid var(--neutral-200);

      .icon {
        width: 20px;
        height: 20px;
        stroke: white;
        cursor: pointer;
      }
    }

    .scroll-wrapper {
      display: flex;
      flex-direction: column;
      height: 100vh;
      overflow-x: auto;
    }

    .nav-item {
      width: 100%;
      display: inline-block;
      padding: 20px;
      font-size: 14px;
      font-weight: 700;
      text-align: left;
      cursor: pointer;
      transition: all 300ms;

      &:hover {
        background-color: var(--neutral-200);
        color: var(--dark-blue);
      }

      &.selected {
        background-color: var(--neutral-200);
        color: var(--dark-blue);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 7;

    .top-bar {
      display: flex;
      align-items: center;
      width: 100%;
      height: 87px;
      padding: 0 20px;
      border-bottom: 1px solid var(--neutral-200);
      background-color: var(--neutral-000);
      position: relative;
    }
  }
}
</style>
