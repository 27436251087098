const getSelectedEntry = (state) => {
  return state.selectedEntry
}

const getSelectedFile = (state) => {
  return state.selectedFile
}

const getBillToPay = (state) => {
  return state.billToPay
}

export default {
  getSelectedEntry,
  getSelectedFile,
  getBillToPay
}