<!--- View de consulta - Responsável pela consulta

Alterado pelos desenvolvedores:
Richard Moraes
Carlos Oliveira
--->

<template>
  <div id="new-annotation">
    <div class="breadcrumbs">
      <div>
        <router-link 
          v-if="userHasPermission('FpPac1')"
          to="/pacientes" 
          tag="span" 
          class="link"
        >
          Meus pacientes 
        </router-link>
        <span v-else> Meus pacientes </span>

        <ChevronRight class="chevron" />
        <router-link
          :to="'/pacientes/' + patientData.id"
          tag="span"
          class="link"
          >{{ patientData.name }}</router-link
        >
        <ChevronRight class="chevron" />
        <span>Nova anotação</span>
      </div>


      <b-button
        v-can="'FpAno2'"
        class="cancel-btn"
        @click="cancelAnnotation"
        v-if="canEdit"
      >
        Cancelar anotação
      </b-button>
      <b-button
        v-can="'FpAno2'"
        class="add-btn"
        @click="startAnnotation"
        v-if="!canEdit"
      >
        Adicionar anotação
      </b-button>
    </div>

    <div class="container">
      <div class="sidebar">
        <div class="content">
          <div class="d-flex flex-row align-items-center">
            <Avatar class="patient-photo" />
            <div>
              <p class="patient-name">{{this.patientData.name}}</p>
              <p class="medical-record">
                Nº do prontuário:
                <span>{{this.patientData.medical_record_number}}</span>
              </p>
            </div>
          </div>
        </div>
        <ul class="resume-list">
          <li>
            <Avatar 
              size="35"
              class="mr-3" 
            />
            <p>{{professionalData.name}}</p>
          </li>
        </ul>
        <div
          class="
            content
            d-flex
            flex-row
            justify-content-between
            align-items-center
          "
          v-if="annotations[0]"
        >
          <p class="lastChangeText">Última atualização</p>

          <p class="lastChange">
            <span>{{moment(annotations[0].end_datetime).format('HH[h]mm')}}
               -
              {{moment(annotations[0].end_datetime).format('DD/MM/YYYY')}}
            </span>
          </p>
        </div>
      </div>
      <div class="annotations-form flex-1">
        <div class="header">
          <p class="title" v-if="canEdit">Nova anotação</p>
          <p class="title" v-else>Anotações</p>
        </div>
        <div class="annotations-body" v-if="canEdit">
          <p class="title">Anotação de equipe multidisciplinar</p>
          <textarea class="content"
            placeholder="Adicione uma nova anotação"
            v-model="annotationForm.content"/>
          <button
            v-can="'FpAno2'"
            class="add-annotation"
            @click="saveAnnotation"
            :disabled="annotationForm.content.length <= 0"
          >
            Adicionar anotação
          </button>
        </div>
        <div class="annotations-list">
          <ul class="annotations" :class="{filled: annotations.length > 0 && canEdit}">
            <li v-for="(annotation, idx) in annotations" :key="annotation.id">
              <div class="title">
                <div>
                  <p>{{ annotation.professional && annotation.professional.name }} </p>
                  às {{moment(annotation.created_at).format('HH[h]mm')}}
                  do dia {{moment(annotation.created_at).format('DD/MM/YYYY')}}
                </div>
                <div class="options" v-if="canEditAnnotation(annotation)">
                  <b-row class="row-icon">
                    <Icon>
                      <Edit class="icon edit" @click="updateAnnotationForm(annotation)"/>
                    </Icon>
                    <Icon>
                      <Trash class="icon trash" @click="deleteAnnotation(annotation)"/>
                    </Icon>
                  </b-row>
                  <div class="menu" v-if="annotation.old_content">
                    <p class="title">Última alteração</p>
                    <p class="content">
                      {{annotation.content}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="content" :class="{'last': idx === (annotations.length-1)}">
                <div v-if="annotationFormUpdate === annotation">
                  <textarea v-model="annotationFormUpdate.content"/>
                  <div style="display: flex; align-items: flex-end; flex-direction: column;">
                    <button @click="updateAnnotation">Salvar alterações</button>
                  </div>
                </div>
                <p v-else>{{annotation.content}}</p>
                <p class="edit-info"
                  v-if="annotation.old_content">
                  Editada às {{moment(annotation.updated_at).format('HH:mm')}} do dia {{moment(annotation.updated_at).format('DD/MM/YYYY')}}
                </p>
              </div>
            </li>
          </ul>
        </div>

        <div class="pagination-position">
          <b-pagination
            v-model="page"
            :total-rows="count"
            :per-page="limit"
            first-number
            last-number
            size="sm"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { userHasPermission } from '../../utils/localStorageManager'

export default {
  name: 'NewAnnotation',
  metaInfo: {
    title: 'Eyecare - Anotação'
  },
  components: {
    Avatar:() => import('@/components/General/Avatar.vue'),
    ChevronRight:() => import('@/assets/icons/chevron-right.svg'),
    Edit: () => import ('@/assets/icons/edit.svg'),
    Trash: () => import ('@/assets/icons/trash.svg'),
    Icon: () => import ('@/components/General/Icon'),
  },
  beforeRouteLeave(to, from, next) {
    if(this.annotationForm.id){
      this.api
        .cancelAnnotation(this.clinic.id, this.$route.params.id, {
          id: this.annotationForm.id})
        .then(res => {
          if(res.status === 200){
            next()
          }
        })
    }
    else {
      next()
    }
  },
  async mounted() {
    this.clinic = JSON.parse(localStorage.getItem('clinic'));
    this.user = localStorage.getItem('doctorId');
    this.patientData = await this.getPatient();
    this.getAnnotations();
    this.role = this.clinic.role;
  },
  watch: {
    page: function() { this.getAnnotations() },
  },
  data() {
    return {
      clinic: '',
      page: 1,
      limit: 0,
      count: 0,
      numberFormat: {
        decimal: ',',
        prefix: '+',
        suffix: '',
        precision: 2,
        masked: false
      },
      canEdit: false,
      canSave: false,
      canceled: false,
      attendanceStartDatetime: null,
      timerInterval: null,
      timerInSeconds: 0,
      patientData: {
        id: '',
        name: '',
        picture: '',
        medical_record_number: ''
      },
      professionalData: this.getProfessionalData(),
      user: '',
      annotationForm: {
        id: '',
        content: ''
      },
      annotationFormUpdate: {
        id: '',
        content: ''
      },
      annotations: {
        id: '',
        content: '',
      },
    }
  },
  methods: {
    userHasPermission,
    canEditAnnotation(annotation) {
      return moment().isSameOrBefore(moment(annotation.start_datetime).endOf('day')) &&
      this.professionalData.id === annotation.professional_id
    },
    getProfessionalData() {
        const user = JSON.parse(localStorage.getItem('user'));
        return { id: user.id, name: user.name }
    },
    updateAnnotationForm(annotation){
      if(this.annotationFormUpdate !== annotation){
        this.annotationFormUpdate = annotation
      } else {
        this.annotationFormUpdate = {
          id: '',
          content: ''
        }
      }
    },
    timerFormated(){
      const momentDuration = this.moment.duration(
        this.timerInSeconds,
        'seconds'
      )

      const hours =
        momentDuration.hours().toString().length === 2
          ? momentDuration.hours()
          : '0' + momentDuration.hours()
      const minutes =
        momentDuration.minutes().toString().length === 2
          ? momentDuration.minutes()
          : '0' + momentDuration.minutes()
      const seconds =
        momentDuration.seconds().toString().length === 2
          ? momentDuration.seconds()
          : '0' + momentDuration.seconds()

      return hours + ':' + minutes + ':' + seconds
    },
    getAnnotations(){
      const isLoading = this.$loading.show()

      this.api
        .getAnnotations(this.page, this.clinic.id, this.$route.params.id)
        .then(res => {
          this.annotations = res.data.data;
          this.count = res.data.total;
          this.limit = res.data.per_page;

          this.annotationForm = {
            id: '',
            content: ''
          }

          if(this.$route.params.action === 'criar'){
            this.startAnnotation()
          }
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    newAnnotation(){
      this.$router.push('/pacientes/' + this.$route.params.id +
        '/anotacoes/' + this.$route.params.professionalId + '/criar')
    },
    cancelAnnotation(){
      const isLoading = this.$loading.show()
      this.api
        .cancelAnnotation(this.clinic.id, this.$route.params.id, {
          id: this.annotationForm.id})
        .then(res => {
          this.canEdit = false

          this.annotationForm = {
            id: '',
            content: ''
          }

          this.$router.push(
            '/pacientes/' + this.$route.params.id +
            '/anotacoes/' + this.$route.params.professionalId + '/visualizar')
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    startAnnotation(){
      if(this.$route.params.action !== 'criar'){
        this.$router.push('/pacientes/' + this.$route.params.id +
          '/anotacoes/' + this.$route.params.professionalId + '/criar')
      }

      const isLoading = this.$loading.show()

      this.api
        .startAnnotation(this.clinic.id, this.$route.params.id)
        .then(res => {
          this.annotationForm = res.data
          this.canEdit = true
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    saveAnnotation(){
      const isLoading = this.$loading.show()
      this.api
        .saveAnnotation(this.clinic.id, this.$route.params.id, this.annotationForm)
        .then(() => {
          if(this.$route.params.action !== 'criar'){
            this.canEdit = false
          }
          this.$toast.success('Anotação salva com sucesso!');
          this.getAnnotations();
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },
    updateAnnotation(){
      const isLoading = this.$loading.show()
      this.api
        .saveAnnotation(this.clinic.id, this.$route.params.id, this.annotationFormUpdate)
        .then(() => {
          if(this.$route.params.action !== 'criar'){
            this.canEdit = false
          }
          this.$toast.success('Anotação atualizada com sucesso!');
          this.getAnnotations();
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },
    deleteAnnotation(annotation){
      const isLoading = this.$loading.show()
      annotation.content = ''
      this.annotationFormUpdate = annotation
      this.api
        .saveAnnotation(this.clinic.id, this.$route.params.id, this.annotationFormUpdate)
        .then(() => {
          this.$toast.success('Anotação apagada com sucesso!');
          this.getAnnotations();
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
           isLoading.hide()
        })
    },
    getPatient() {
      return new Promise((resolve, reject) => {
        this.api.getPerson(this.$route.params.id)
        .then(res => resolve(res.data))
        .catch(reject);
      });
    }
  }
}
</script>

<style lang="scss">
#new-annotation {
  width: 100%;
  height: auto;
  padding: 30px 24px;
  text-align: left;
  display: inline-block;

  .breadcrumbs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 16px;

    .chevron {
      width: 24px;
      stroke: var(--neutral-600);
      margin: 0 8px;
    }

    .link {
      color: var(--blue-500);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    .cancel-btn {
      background-color: var(--states-error);
      border-color: var(--states-error);
    }

    .add-btn {
      background-color: #305BF2;
      border-color: #305BF2;
    }
  }

  .container {
    width: 100%;
    max-height: 3025px;
    max-width: 100%;
    background-color: var(--neutral-000);
    border-radius: 8px;
    padding: 0;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .sidebar {
      width: 400px;
      border-right: 1px solid var(--neutral-200);
      display: flex;
      flex-direction: column;

      .content {
        padding: 24px;
        border-bottom: 1px solid var(--neutral-200);

        .patient-photo {
          width: 64px;
          height: 64px;
          border-radius: 32px;
          margin-right: 16px;
        }
        .patient-name {
          font-family: 'Red Hat Display';
          font-weight: 700;
          font-size: 24px;
          color: var(--dark-blue);
          margin-bottom: 4px;
        }

        .medical-record {
          font-family: 'Nunito Sans';
          font-weight: 400;
          font-size: 12px;
          color: var(--type-placeholder);

          span {
            font-weight: 600;
            color: var(--type-active);
          }
        }

        .tab-menu {
          display: flex;
          background-color: var(--neutral-100);
          border-radius: 8px;

          .tab-item {
            flex: 1;
            display: inline-block;
            padding: 4px 16px;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: var(--type-active);
            border-radius: 8px;
            cursor: pointer;
            text-align: center;

            &.active {
              background-color: var(--blue-500);
              color: var(--neutral-000);
            }
          }
        }

        .lastChangeText {
          font-weight: 600;
          font-size: 16px;
          color: #0C1D59;
        }

        .lastChange {
          padding: 8px 16px;
          font-size: 16px;
          color: #525C7A;
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .durationTimerClosed {
          background-color: #d9dff2;
          padding: 8px 16px;
          border-radius: 100px;
          font-weight: 700;
          font-size: 14px;
          color: #505050;
          display: flex;
          flex-direction: row;
          align-items: center;

          svg {
            width: 16px;
            height: 16px;
            stroke: #505050;
            margin-left: 10px;
          }
        }

        &.collapsible {
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
              font-weight: 600;
              font-size: 16px;
              color: var(--dark-blue);
            }

            svg {
              width: 24px;
              height: 24px;
              stroke: var(--neutral-600);
              transform: rotate(180deg);
              transition: all 0.5s;
              cursor: pointer;
            }
          }

          ul {
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s;

            .confirmed {
              stroke: var(--states-success);
            }

            .plus {
              color: var(--blue-500);

              svg {
                cursor: pointer;
                fill: var(--blue-500);
                stroke: transparent;
              }
            }
          }

          &.opened {
            .header {
              margin-bottom: 24px;
            }

            ul {
              max-height: 1000px;
              overflow: initial;
            }

            .header {
              svg {
                transform: rotate(0deg);
              }
            }
          }
        }

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            font-weight: 600;
            font-size: 16px;
            color: var(--type-active);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 8px 0;
            position: relative;
            cursor: pointer;

            span.button {
              padding: 8px 16px;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              border: 2px solid var(--type-active);
              border-radius: 8px;
              margin-top: 16px;
            }

            svg {
              width: 24px;
              height: 24px;
              stroke: var(--neutral-500);
            }

            &.active {
              font-weight: 700;

              &:before {
                width: 4px;
                height: 100%;
                background-color: var(--orange);
                content: ' ';
                position: absolute;
                top: 0;
                left: -24px;
              }
            }
          }
        }
      }
      .resume-list {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            padding: 16px 24px;

            border-bottom: 1px solid #d9dff2;

            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            svg.icon {
              width: 24px;
              height: 24px;
              fill: #6d789c;
              margin-right: 24px;
              margin-left: 8px;
            }
          }
        }
    }

    .annotations-form {
      display: flex;
      flex-direction: column;

      .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px;
        background-color: var(--neutral-000);
        z-index: 10;

        .title {
          font-family: 'Red Hat Display';
          font-weight: 700;
          font-size: 32px;
          color: var(--dark-blue);
        }
      }

      .annotations-body {
        padding: 32px;
        .title {
          //styleName: Text / Large;
          font-family: Nunito Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 10px;
        }

        .content {
          color: #525C7A;
          width: 100%;
          height: 235px;
          padding: 15px;
          border: 1px solid #C6CEEB;
          box-sizing: border-box;
          border-radius: 8px;
        }

        .content:focus {
          outline: none !important;
        }

        .add-annotation {
          float: right;
          margin: 10px 0px;
          padding: 10px;
          background: #305BF2;
          border-radius: 8px;
          color: #FFFFFF;
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
        }

        .add-annotation:disabled {
          opacity: 0.5;
        }
      }

      .annotations-list {
        .annotations {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          margin: 0px 30px;
          padding: 0px;

          &.filled {
            border-top: solid 1px #C6CEEB;
          }

          li{
            list-style: none;
            .title {
              padding-top: 30px;
              margin: 0px;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              color: #525C7A;
              display: flex;
              justify-content: space-between;

              p {
                float: left;
                color: #0B30B2;
                margin-right: 5px;
                font-family: Nunito Sans;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                letter-spacing: 0em;
              }

              .options {
                cursor: pointer;
                width: 18px;
                height: 24px;
                margin-right: 5px;
                position: relative;

                .menu {
                  position: absolute;
                  right: 0px;
                  width: 600px;
                  background: var(--neutral-000);
                  border: 1px solid var(--neutral-200);
                  box-sizing: border-box;
                  box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                  border-radius: 8px;
                  padding: 15px 20px;
                  opacity: 0;
                  transition: all 0.5s;
                  flex-direction: column;
                  align-items: start;
                  display: none;

                  .mtb-12 {
                    margin: 12px 0;
                  }

                  .title {
                    font-family: Nunito Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #0B30B2;
                    padding: 10px 0px;
                  }

                  .content {
                    color: #525C7A;
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 150%;
                    margin: 10px 0px;
                    border: none;
                    padding: 0px;
                  }
                }

                &:hover {
                  .menu {
                    opacity: 1;
                    display: flex;
                    z-index: 10;
                  }
                }
              }

              .row-icon {
                flex-wrap: nowrap;
                margin-left: 0px;
              }

              .icon {
                  width: 20px;
                  height: 20px;
                  fill: var(--blue-500);
                  cursor: pointer;

                  &.trash {
                    fill: none;
                    stroke: var(--states-red-soft);
                  }

                  &.edit {
                    fill: none;
                    stroke: var(--blue-500);
                  }

                  &.close {
                    fill: var(--type-active);
                  }
                }

            }

            .content {
              padding-bottom: 30px;
              padding-top: 10px;
              border-bottom: 1px dashed #C6CEEB;

              &.last {
              border-bottom: none;
              }

              textarea {
                width: 100%;
                padding: 10px;
                border: 1px solid #C6CEEB;
                box-sizing: border-box;
                border-radius: 8px;
                color: #525C7A;
                resize: none;
              }

              textarea:focus {
                outline: none !important;
              }

              button {
                margin-top: 10px;
                width: 160px;
                background: #0B30B2;
                color: #ffffff;
                border-radius: 8px;
                padding: 10px;
              }

              .edit-info {
                margin-top: 10px;
                color: #525C7A;
                font-family: Nunito Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
              }
            }
          }
        }
      }

      .scrollable-area {
        padding: 0 16px 32px 16px;

        .ps {
          max-height: 2647px;
        }

        .message-box {
          background-color: #e6f9ff;
          border-radius: 8px;
          padding: 16px 26px;
          display: flex;
          justify-content: center;
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          margin: 0 0 56px 0;
          align-items: center;

          .icon {
            width: 24px;
            height: 24px;
            margin-right: 25px;
            stroke: #0088b3;
          }

          &.warning {
            justify-content: start;
            margin: 0 16px;
            background-color: #fff0e6;

            .icon {
              stroke: #b34b00;
            }
          }
        }

        .form-group-title {
          font-family: 'Red Hat Display';
          font-weight: 700;
          font-size: 18px;
          color: var(--dark-blue);
          margin: 40px 16px 24px 16px;
        }
      }
    }
  }

  .modal-dialog {
    max-width: 550px !important;
    margin: 1.75rem auto;
  }

  .modal-dialog {
    width: 100% !important;
    max-width: 960px !important;
  }

  .pagination-position {
    width: 100%;
    display: inline-flex;
    justify-content: end;
    padding: 20px;
  }
}
</style>
