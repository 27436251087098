<template>
  <section id="glasses-standard-record">
    <div class="custom-appointment">
      <nav class="breadcrumbs">
        <div>
          <router-link to="/registros-padrao/registros" tag="span" class="link">
            Registros padrões
          </router-link>
          <ChevronRight class="chevron" />
          <router-link :to="`/registro-padrao/oculos/${form.id}`" tag="span">
            Prescrição de óculos
          </router-link>
        </div>
      </nav>
      <section class="container-data">
        <p class="alert">
          <InfoCircle class="icon" />
          Os registros padrões serão salvos automaticamente e você poderá
          usá-los dentro de sua consulta.
        </p>
        <b-row class="justify-content-start mb-3">
          <h2 class="standard-title">Prescrição de óculos</h2>
          <span id="help-icon" class="icon-box">
            <HelpCircle class="icon stroke" />
          </span>
          <b-tooltip target="help-icon" placement="bottom">
            <p>Caso habilitado "soma automática da prescrição"</p>
            <p>a soma será feita automaticamente pelo sistema.</p>
            <p>A visualização do campo ficará disponível no</p>
            <p>documento da prescrição de óculos.</p>
          </b-tooltip>
        </b-row>
        <b-row class="select-model-container">
          <b-col class="model-box">
            <div class="switch-model">
              <toggle-button
                id="modelo-padrao"
                :value="!form.value?.autoSum"
                class="autoFillBtn mb-1"
                :sync="true"
                :width="43"
                :height="23"
                @change="handleActiveModel"
                :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                :disabled="loading"
              />
              <label for="modelo-padrao"> Modelo padrão da prescrição </label>
            </div>
            <hr />
            <EyeValuesTemplate
              title="Prescrição para longe"
              :inputs="['Esférico', 'Cílindrico', 'Eixo', 'D.P']"
            />
            <div class="adicao-box">
              <EyeValuesTemplate title="Adição" :inputs="['Esférico']" />
            </div>
          </b-col>

          <b-col class="model-box">
            <div class="switch-model">
              <toggle-button
                id="modelo-soma-automatica"
                :value="form.value?.autoSum"
                class="autoFillBtn mb-1"
                :sync="true"
                :width="43"
                :height="23"
                @change="handleActiveModel"
                :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                :disabled="loading"
              />
              <label for="modelo-soma-automatica">
                Modelo com soma automática da prescrição
              </label>
            </div>
            <hr />
            <EyeValuesTemplate
              title="Prescrição para longe"
              :inputs="[
                'Esférico',
                'Cílindrico',
                'Eixo',
                'D.P',
                'Adição p/ perto'
              ]"
            />
            <div class="prescricao-perto-box">
              <EyeValuesTemplate
                title="Prescrição para perto"
                :inputs="['Esférico', 'Cilíndrico', 'Eixo']"
              />
            </div>
          </b-col>
        </b-row>
        <section id="instructions-container">
          <hr />
          <Instructions
            :instructions="form.value?.instructions"
            @updateInstructions="updateInstructions"
            canEdit
          />
        </section>
      </section>
    </div>
  </section>
</template>

<script>
import { getCurrentUser } from '@/utils/localStorageManager'
import standardRecordMixin from '@/mixins/StandardRecords/standardRecordMixin'

export default {
  name: 'StandardRecordGlassesView',
  props: {
    readonly: Boolean
  },
  components: {
    InfoCircle: () => import('@/assets/icons/info-circle.svg'),
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    EyeValuesTemplate: () =>
      import('@/components/Attendance/Forms/Templates/EyeValuesTemplate'),
    Instructions: () =>
      import('@/components/Attendance/Forms/Components/Instructions')
  },
  async created() {
    await this.getStandardById()
  },
  data() {
    return {
      id: this.$route.params.id,
      user: getCurrentUser(),
    }
  },
  mixins: [standardRecordMixin()],
  methods: {
    async updateStandard() {
      this.loading = true
      try {
        const { data } = await this.api.updateStandard(this.form.id, {
          value: this.form.value
        })
        this.form = data
        window.location.reload()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async handleActiveModel() {
      this.form.value.autoSum = !this.form.value.autoSum
      await this.updateStandard()
    },
    async updateInstructions(value) {
      this.form.value.instructions = value
      await this.updateStandard()
    }
  }
}
</script>

<style lang="scss" scoped>
#glasses-standard-record {
  .icon {
    height: 24px;
    width: 24px;
    margin-left: 5px;
    stroke: var(--neutral-500);
  }
  .alert {
    height: 56px;
    margin: 24px;
    background: #e6f9ff;
    border-radius: 8px;
  }
  .form-group {
    width: 100%;
    border-radius: 8px;
    margin: 24px 24px 0px 24px;
  }
  .standard-title {
    margin-top: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
  }
  .container-data {
    width: 100%;
    background-color: var(--neutral-000);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    > div {
      margin-left: 30px;
    }

    .select-model-container {
      width: 95%;
      div:nth-child(1) {
        margin-right: 35px;
      }
      .model-box {
        padding: 20px;
        border-radius: 16px;
        border: 1px solid var(--neutral-300);

        .adicao-box {
          margin-top: 10px;
          max-width: 32%;
        }
        .prescricao-perto-box {
          margin-top: 10px;
          max-width: 65%;
        }
      }

      .switch-model {
        margin-top: 20px;

        label {
          font-weight: 600;
          font-size: 18px;
          color: var(--dark-blue);
          margin: 0 5px;
        }
      }
    }
  }
  .custom-appointment {
    width: 100%;
    height: auto;
    padding: 30px 24px;
    text-align: left;
    display: inline-block;

    .breadcrumbs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 30px;
      font-weight: 600;
      font-size: 16px;

      .chevron {
        width: 24px;
        stroke: var(--neutral-600);
        margin: 0 8px;
      }

      .link {
        color: var(--blue-500);
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }

      button {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .icon-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #instructions-container {
    margin: 30px;
  }
}
</style>
