<template>
  <div class="comment-input-wrapper">
    <Avatar size="48" :src="user.picture" />
    <b-form-input
      :placeholder="placeholder || 'Escrever um comentário'"
      v-model="comment"
    />
    <b-button
      variant="primary"
      @click="sendComment"
    >
      Comentar
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
    placeholder: String,
    onComment: { type: Function, required: true }
  },
  components: {
    Avatar: () => import('@/components/General/Avatar')
  },
  data() {
    return {
      comment: ''
    }
  },
  methods: {
    sendComment() {
      this.comment &&
        this.onComment(this.comment) &&
          (this.comment = '')
    }
  }
}
</script>
<style lang="scss" scoped>
  .comment-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 24px 0;
    border-bottom: 1px solid var(--neutral-200);

    button {
      height: 38px;
    }
  }
</style>
