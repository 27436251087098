import { updateAttendanceForm } from '@/utils/attendanceFormHelper'

const store = {
  namespaced: true,
  state: () => ({
    id: 'refracao-estatica',
    name: 'Refração Subjetiva Estática',
    opened: true,
    showing: true,
    completed: false,
    multiple: false,
    scopes: ['ATTENDANCE', 'SURGERY', 'ESSILOR_ATTENDANCE'],
    fields: {
      olhoDireitoEsfericoEst: null,
      olhoDireitoCilindricoEst: null,
      olhoDireitoEixoEst: null,
      olhoDireitoDPEst: null,
      olhoDireitoAVEst: null,
      olhoEsquerdoEsfericoEst: null,
      olhoEsquerdoCilindricoEst: null,
      olhoEsquerdoEixoEst: null,
      olhoEsquerdoDPEst: null,
      olhoEsquerdoAVEst: null,
      olhoDireitoAdicaoEst: null,
      olhoEsquerdoAdicaoEst: null,
      usarValoresNaPrescricaoDeOculosEst: null,
    }
  }),
  mutations: {
    handleProps: (state, { key, value }) => {
      state[key] = value
    },
    handleFields: (state, { key, value }) => {
      state.fields[key] = value
    },
    checkComplete: state => {
      state.completed = Object.keys(state.fields).some(key => state.fields[key])
    }
  },
  getters: {},
  actions: {
    handleProps(context, { key, value }) {
      context.commit('handleProps', { key, value })

      if(context?.rootState?.attendance?.attendance?.id && ['showing' , 'opened'].includes(key)){
        const form = context?.rootGetters['attendance/form/getSelectedForm']
        const forms = form.forms
        const index = forms?.findIndex(form => {
          return form.id === context.state.id
        })
  
        if(index && index !== -1){
          forms[index][key] = value
          form.forms = forms
          updateAttendanceForm(context?.rootState?.attendance?.attendance?.attendance_form?.id, {forms: forms})
          context.dispatch(`attendance/form/updateAttendanceForms`, form, { root: true })
        }
      }
    },
    handleFields(context, { key, value }) {
      context.commit('handleFields', { key, value })
      context.commit('checkComplete')
    },
    disableUseGlass({ commit, state, dispatch }) {
      if ( state.fields.usarValoresNaPrescricaoDeOculosEst ) {
        commit('handleFields', { key: 'usarValoresNaPrescricaoDeOculosEst', value: false })
        dispatch('attendance/form/updateMedicalRecord', state, { root: true })
      }
    },
    useValuesOnGlasses(context, checked) {
      if (checked !== undefined) {
        context.commit('handleFields', { key: 'usarValoresNaPrescricaoDeOculosEst', value: checked })
      }
      const props = {
        suffix: 'Est',
        checked: context.state.fields.usarValoresNaPrescricaoDeOculosEst,
        fields: context.state.fields
      }

      if (context?.rootState?.attendance?.form?.oculos?.opened === false) {
        context.dispatch(`attendance/form/oculos/handleProps`, ({ key: 'opened', value: true }), { root: true })
      }

      if (context?.rootState?.attendance?.form?.oculos?.showing === false) {
        context.dispatch(`attendance/form/oculos/handleProps`, ({ key: 'showing', value: true }), { root: true })
      }
      
      context.dispatch('attendance/form/oculos/changeFieldsByRefraction', props, { root: true })
      context.dispatch('attendance/form/refracaoDinamica/disableUseGlass', null, { root: true })
      context.dispatch('attendance/form/lensometria/disableUseGlass', null, { root: true })
    },
    useValuesOnLenses(context, checked) {
      const props = {
        checked,
        suffix: 'Est',
        fields: context.state.fields
      }
      context.dispatch('attendance/form/lentesDeContato/changeFieldsByRefraction', props, { root: true })
    }
  },
}

export default store
