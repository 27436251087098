import Vue from 'vue'
import { getCurrentUser } from '@/utils/localStorageManager'


const getUserNotifications = async (context, filters) => {
  try {
    const user = getCurrentUser()
    const res = await Vue.prototype.api.getUserNotifications(user.id, filters.page, filters)
    context.commit('pushNotifications', res.data.notifications.data)
    context.commit('setNotificationsPages', res.data.notifications.last_page)
    context.commit('setUnreadNotifications', res.data.unread)
  } catch(err) {
    Vue.prototype.$toast.error(err.message)
  }
}

const createNotification = async (context, payload) => {
  try {
    const res = await Vue.prototype.api.createNotification(payload)
    context.commit('incrementUnreadNotifications', res.data.type)
    context.commit('createNotification', res.data)
  } catch(err) {
    Vue.prototype.$toast.error(err.message)
  }
}

const clearNotifications = (context) => {
  context.commit('clearNotifications')
}

const removeNotification = async (context, notificationId) => {
  try {
    context.commit('removeNotification', notificationId)
    await Vue.prototype.api.deleteNotification(notificationId)
  } catch (error) {
    Vue.prototype.$toast.error(error.message)
  }
}

const setNotificationAsVisualized = async (context, notification) => {
  try {
    context.commit('setNotificationAsVisualized', notification?.id)
    context.commit('decrementUnreadNotifications', notification?.type)
    await Vue.prototype.api.updateNotification(notification?.id, { visualized: true })
  } catch (error) {
    Vue.prototype.$toast.error(error.message)
  }
}

const setAllAsVisualized = async (context) => {
  try {
    const user = getCurrentUser()
    context.commit('setAllAsVisualized')
    await Vue.prototype.api.setAllAsVisualized(user.id)
    Vue.prototype.$toast.success('Todas notificações atualizadas!')
  } catch (error) {
    this.$toast.error(error.message)
  }
}

export default {
  clearNotifications,
  getUserNotifications,
  createNotification,
  removeNotification,
  setAllAsVisualized,
  setNotificationAsVisualized,
}