export const setFilterToday = (filters) => {
  localStorage.setItem('filterToday', JSON.stringify([filters]));
};

export const getFilterToday = () => {
  return JSON.parse(localStorage.getItem('filterToday'));
};

export const setFilterSituation = (filters) => {
  localStorage.setItem('filterSituation', JSON.stringify([filters]));
};

export const getFilterSituation = () => {
  return JSON.parse(localStorage.getItem('filterSituation'));
};

export const setFilterProcedimento = (filters) => {
  localStorage.setItem('filterProcedimento', JSON.stringify([filters]));
};

export const getFilterProcedimento = () => {
  return JSON.parse(localStorage.getItem('filterProcedimento')) ? JSON.parse(localStorage.getItem('filterProcedimento')) : ["Todos"];
};

export const setFilterConvenio = (filters) => {
  localStorage.setItem('filterConvenio', JSON.stringify([filters]));
};

export const getFilterConvenio = () => {
  return JSON.parse(localStorage.getItem('filterConvenio')) ? JSON.parse(localStorage.getItem('filterConvenio')) : ["Todos"];
};
