import { base } from '@/utils/api'

const ENDPOINT_COMPANY = '/nf-companies'
const ENDPOINT_NF = 'nfs'
const ENDPOINT_CONTRACT = 'nf-contracts'

export default {
  createCompany(data) {
    return base.post(`${ENDPOINT_COMPANY}`, data)
  },
  getCompanies(clinicId) {
    return base.get(`${ENDPOINT_COMPANY}/${clinicId}`)
  },
  getCompany(companyId) {
    return base.get(`${ENDPOINT_COMPANY}/${companyId}/show`)
  },
  updateCompany(id, data) {
    return base.put(`${ENDPOINT_COMPANY}/${id}`, data)
  },
  deleteCompany(id) {
    return base.delete(`${ENDPOINT_COMPANY}/${id}`)
  },
  createNF(
    clinic_id,
    prestador_id,
    cpf_cnpj_tomador,
    email_tomador,
    value,
    data_emissao
  ) {
    const config = {
      params: {
        clinic_id,
        prestador_id,
        cpf_cnpj_tomador,
        email_tomador,
        value,
        data_emissao
      }
    }

    return base.post(`${ENDPOINT_NF}`, config.params.clinic_id)
  },
  retry(id) {
    return base.put(`${ENDPOINT_NF}/${id}/retry`)
  },
  getAllNFs(clinic_id, status, nome_ou_cpf, from_date, to_date, page, numero) {
    return base.get(
      `${ENDPOINT_NF}?clinic_id=${clinic_id}&status=${status}&nome_ou_cpf=${nome_ou_cpf}&from_date=${from_date}&to_date=${to_date}&page=${page}&numero=${numero}`
    )
  },
  getNFbyId(nfId) {
    return base.get(`${ENDPOINT_NF}/${nfId}/show`)
  },
  resendEmail(nfId) {
    return base.post(`${ENDPOINT_NF}/${nfId}/resend-email`)
  },
  deleteNF(nfId) {
    return base.delete(`${ENDPOINT_NF}/${nfId}`)
  },
  getDashboard(clinic_id, from_date, to_date) {
    return base.get(
      `${ENDPOINT_NF}/dashboard/${clinic_id}?from_date=${from_date}&to_date=${to_date}`
    )
  },
  hasReachedLimitCompanies(clinic_id) {
    return base.get(`${ENDPOINT_CONTRACT}/${clinic_id}/companies-limit`)
  }
}
