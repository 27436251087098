var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-side"},[_c('carousel',{staticClass:"vh-100",attrs:{"autoplay":true,"loop":true,"autoplayTimeout":6000,"perPage":1,"paginationEnabled":true,"paginationSize":8,"paginationColor":'#A4B1DF',"paginationPadding":8,"paginationActiveColor":'#FFFFFF'}},[_c('slide',{style:({
            'background-image':
              'url(' + require('@/assets/images/carousel-img.png') + ')'
          })},[_c('div',{staticClass:"container"},[_c('p',{staticClass:"title"},[_vm._v("Olá,")]),_c('p',{staticClass:"text"},[_vm._v(" Bem vindo ao EyecareBI, sua plataforma customizada para a Oftalmologia ")])])]),_c('slide',{style:({
            'background-image':
              'url(' + require('@/assets/images/carousel-img2.png') + ')'
          })},[_c('div',{staticClass:"container"},[_c('p',{staticClass:"title"},[_vm._v("Dados inteligentes")]),_c('p',{staticClass:"text"},[_vm._v(" Aqui você terá acesso a dados que poderão otimizar seu dia-a-dia no consultório ")])])]),_c('slide',{style:({
            'background-image':
              'url(' + require('@/assets/images/carousel-img3.png') + ')'
          })},[_c('div',{staticClass:"container"},[_c('p',{staticClass:"title"},[_vm._v("Novo mindset")]),_c('p',{staticClass:"text"},[_vm._v(" Além de facilitar a incorporação das inovações tecnológicas a sua prática diária ")])])])],1),_c('img',{staticClass:"logo-eyecare",attrs:{"src":require("@/assets/images/eyecare-logo-white.png")}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }