import api from '@/modules/sus/manageGuides/api'
import { getCurrentClinic } from '@/utils/localStorageManager'

const getSusGuideRequiredFields = (context) => {
  const clinic = getCurrentClinic()
  api.getSusGuideRequiredFields(clinic.id)
    .then(({ data }) => context.commit('setRequiredFields', data))    
}

export default {
  getSusGuideRequiredFields,
}
