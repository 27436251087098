<template>
  <div class="card">
    <section class="card__header">
      <h4 class="card__title">{{ department.name }}</h4>
      <button @click="deleteDepartment" class="btn">
        <Trash class="card__icon card__icon--trash" />
      </button>
    </section>
    <section class="card__listeners">
      <div v-if="listenersToShow.length">
        <strong class="card__listeners__strong">
          Setores: ({{ department.listeners.length }})
        </strong>
        <br>
        <label class="card__listeners__label" v-for="(listener, key) in listenersToShow" :key="key">
          {{ key === listenersToShow.length - 1 ? listener.name : listener.name + ', ' }}
        </label>
        <b-badge
          id="badge"
          variant="primary"
          v-show="listenersOnTooltip.length"
        >
          + {{ listenersOnTooltip.length }}
        </b-badge>
        <b-tooltip target="badge" placement="bottom">
          <p v-for="(listener, key) in listenersOnTooltip" :key="key">
            {{ listener.name }}
          </p>
        </b-tooltip>
      </div>
      <label v-else>
        Nenhum  setor cadastrado
      </label>
    </section>
    <section class="card__footer">
      <div class="card__status">
        <label class="card__status__label" for="active">Ativo</label>
        <toggle-button
          v-model="department.active"
          id="active"
          :sync="true"
          :height="26"
          @change="switchStatus"
          :disabled="disableSwitch"
          :color="{checked: '#00C773', unchecked: '#E1E5F4'}"
        />
      </div>
      <b-button class="card__edit" variant="link" @click="updateDepartment">
        Editar
      </b-button>
    </section>
  </div>
</template>

<script>
import Trash from '@/assets/icons/trash.svg'

export default {
  components: {
    Trash,
  },
  props: {
    department: Object,
  },
  data(){
    return {
      disableSwitch: false,
      listenersToShow: [],
      listenersOnTooltip: [],
    }
  },
  mounted(){
    this.handleListeners()
  },
  methods: {
    handleListeners(){
      this.listenersToShow = []
      this.listenersOnTooltip = []
      let index = 0
      if( this.department.listeners ){
        while( index < this.department.listeners.length ){
          if( index < 6 ){
            this.listenersToShow.push(this.department.listeners[index])
          } else {
            this.listenersOnTooltip.push(this.department.listeners[index])
          }
          index++
        }
      }
    },
    updateDepartment(){
      this.$emit('update', this.department);
    },
    deleteDepartment(){
      this.$emit('delete', this.department);
    },
    async switchStatus(){
      try {
        this.disableSwitch = true
        await this.api.updateCallPanelDepartment(this.department.id, { active:this.department.active } )
      } catch (err){
        this.$toast.error(err.message);
      } finally {
        this.disableSwitch = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  box-sizing: border-box;
  width: calc(25% - 48px / 4);
  text-align: left;
  background: var(--neutral-000);
  border-radius: 8px !important;
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 5px;
    padding: 1rem;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
  }

  &__icon {
    &--trash {
      width: 25px;
      stroke: var(--states-red-soft);
    }
  }

  &__listeners {
    padding: 1rem;
    height: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    &__strong {
      font-size: 14px;
    }

    &__label {
      margin-right: 2px;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    padding: 1rem;
    margin-top: 10px;
    justify-content: space-between;
    background: var(--neutral-000);
    border-radius: 0px 0px 8px 8px !important;
    border-top: 1px solid var(--neutral-200);
  }

  &__edit {
    font-size: 1rem !important;
  }

  &__status {
    &__label {
      font-weight: 600;
      margin: 0 0.5rem 0 0;
    }
  }
}
</style>
