<template>
  <div id="no-filter-results">
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Os filtros aplicados não geraram nenhum resultado'
    }
  }
}
</script>

<style lang="scss">
#no-filter-results {
  display: flex;
  justify-content: center;

  p {
    padding: 64px 0;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 132%;
    color: var(--type-placeholder);
  }
}
</style>
