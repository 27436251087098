import { base } from '@/utils/api'

export default {
  getTissGuideRequiredFields(clinicId) {
    return base.get(`tiss-guides/clinic/${clinicId}/required-fields`);
  },
  updateTissGuideRequiredFields(id, data){
    return base.put(`tiss-guides/clinic/required-fields/${id}`, data);
  },
  getBatchHeader (batchId) {
    return base.get(`tiss-batch/${batchId}/header`);
  },
  getGuideHeader(guideId) {
    return base.get(`tissGuide/${guideId}/header`);
  },
  getTissGuideByBatchId(batchId, filters = []) {
      const config = {
        params: { filters }
      }
      return base.get(`tissGuide/batch/${batchId}/tiss-guides`, config);
  },
  getTissGuideItems(tissGuideId, filters = []) {
    const config = {
      params: { filters }
    }
    return base.get(`tissGuide/${tissGuideId}/items`, config);
  },
  getTissGuideNumbers(batchId) {
    return base.get(`tiss-guides/batch/${batchId}/numbers`);
  },
  bulkUpdateTissGuideItems(guideId, data) {
    return base.post(`tiss-guides/${guideId}/bulk-update-items`, data);
  },
  bulkUpdateTissGuides(data) {
    return base.post(`tiss-guides/bulk-update-situations`, data);
  },
  getBatchResume(batchId) {
    return base.get(`manage-tiss-guides/batch/${batchId}/resume`);
  },
  getGuideResume(guideId) {
    return base.get(`manage-tiss-guides/tiss-guide/${guideId}/resume`);
  },
  updateTissBatchInfo(id, data){
    return base.put(`tissBatch/updateInfos/${id}`, data);
  },
  getBatchBillData(batchId) {
    return base.get(`manage-tiss-guides/batch/${batchId}/bill-data`);
  },
  finishTissBatch(batchId){
    return base.put(`manage-tiss-guides/batch/${batchId}/finish`);
  },
  getTissBatches(clinicId, page, filters = {}) {
    return base.get(`invoice-tiss/batches?page=${page}`, {
      params: { clinicId, ...filters }
    });
  },
  getTissBatchesInfo(clinicId, page, filters = []) {
    return base.get(`getBatchesInfo?page=${page}`, {
      params: { clinicId, filters }
    });
  },
  getOtherExpenseGuide(id) {
    return base.get(`tiss-guides/other-expense/${id}`);
  },
  getExpensableId(tissGuideId) {
    return base.get(`tiss-guides/${tissGuideId}/expensable`);
  },
  getTissBatchFiles(clinicId, batchId) {
    return base.get(`tissBatchFile`, {
      params: { clinicId, tissBatchId: batchId }
    });
  },
  uploadTisssBatchFile(data) {
    return base.post(`tissBatchFile`, data);
  },
}