<template>
  <div 
    v-can="'AgOnlCli1'"
    class="clinic-view"
  >
    <b-row>
      <b-col cols="12">
        <p class="form-title mb-3">Clinica</p>
      </b-col>

      <b-col cols="12">
        <label for="name" class="title-styles">Nome da clínica</label>
        <b-form-input
          autocomplete="off"
          class="form-control"
          v-model="form.name"
        />
        <div v-if="validated && !form.name" class="custom-invalid-feedback">Campo obrigatório</div>
      </b-col>

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <b-col cols="3">
        <label for="zipCode" class="title-styles">CEP</label>
        <b-form-input
          autocomplete="off"
          class="form-control"
          v-model="form.zipCode"
          v-mask="'#####-###'"
        />
        <div v-if="validated && !form.zipCode" class="custom-invalid-feedback">Campo obrigatório</div>
      </b-col>

      <b-col cols="7">
        <label for="address" class="title-styles">Logradouro</label>
        <b-form-input
          autocomplete="off"
          class="form-control"
          v-model="form.address"
        />
        <div v-if="validated && !form.address" class="custom-invalid-feedback">Campo obrigatório</div>
      </b-col>

      <b-col cols="2">
        <label for="number" class="title-styles">Número</label>
        <b-form-input
          ref="addressNumber"
          autocomplete="off"
          class="form-control"
          v-model="form.number"
        />
        <div v-if="validated && !form.number" class="custom-invalid-feedback">Campo obrigatório</div>
      </b-col>

      <b-col cols="3" class="mt-3">
        <label for="neighborhood" class="title-styles">Bairro</label>
        <b-form-input
          autocomplete="off"
          class="form-control"
          v-model="form.neighborhood"
        />
        <div v-if="validated && !form.neighborhood" class="custom-invalid-feedback">Campo obrigatório</div>
      </b-col>

      <b-col cols="5" class="mt-3">
        <label for="complement" class="title-styles">Complemento</label>
        <b-form-input
          autocomplete="off"
          class="form-control"
          v-model="form.complement"
        />
      </b-col>

      <b-col cols="2" class="mt-3">
        <label for="city" class="title-styles">Cidade</label>
        <b-form-input
          autocomplete="off"
          class="form-control"
          v-model="form.city"
          readonly
        />
        <div v-if="validated && !form.city" class="custom-invalid-feedback">Campo obrigatório</div>
      </b-col>

      <b-col cols="2" class="mt-3">
        <label for="state" class="title-styles">Estado</label>
        <b-form-input
          autocomplete="off"
          class="form-control"
          v-model="form.state"
          readonly
        />
        <div v-if="validated && !form.state" class="custom-invalid-feedback">Campo obrigatório</div>
      </b-col>

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <b-col cols="3">
        <label for="phone" class="title-styles">Telefone</label>
        <b-form-input
          placeholder="Descrever"
          autocomplete="off"
          class="form-control"
          v-model="form.phone"
          v-mask="['(##) ####-####', '(##) #####-####']"
        />
        <div v-if="validated && !form.phone" class="custom-invalid-feedback">Campo obrigatório</div>
      </b-col>

      <b-col cols="3">
        <label for="cellphone" class="title-styles">Whatsapp</label>
        <b-form-input
          placeholder="Descrever"
          autocomplete="off"
          class="form-control"
          v-model="form.cellphone"
          v-mask="'(##) #####-####'"

        />
        <div v-if="validated && !form.cellphone" class="custom-invalid-feedback">Campo obrigatório</div>
      </b-col>

      <b-col cols="3">
        <label for="email" class="title-styles">E-mail</label>
        <b-form-input
          placeholder="Descrever"
          autocomplete="off"
          class="form-control"
          type="email"
          v-model="form.email"
        />
        <div v-if="validated && !form.email" class="custom-invalid-feedback">Campo obrigatório</div>
        <div v-if="validateEmail1 && form.email" class="custom-invalid-feedback">O campo deve conter um email</div>
      </b-col>

      <b-col cols="3">
        <label for="email2" class="title-styles">E-mail 2 <small>(Opcional)</small></label>
        <b-form-input
          placeholder="Descrever"
          autocomplete="off"
          class="form-control"
          type="email"
          v-model="form.email2"
        />
        <div v-if="validateEmail2 && form.email2" class="custom-invalid-feedback">O campo deve conter um email</div>
      </b-col>

      <div 
        v-can="'AgOnlCli2'"
        class="wrapper-button"
      >
        <b-button
          v-if="!public_schedule"
          variant="primary"
          size="lg"
          @click="createPublicSchedule"
        >
          Salvar
        </b-button>

        <b-button
          v-else
          variant="primary"
          size="lg"
          @click="updatePublicSchedule"
        >
          Editar
        </b-button>
      </div>
    </b-row>
  </div>
</template>

<script>
import * as cep from 'cep-promise'

export default {
  name: 'ClinicView',
  components: {
  },
  props:  {
    public_schedule: Object,
    clinic: Object
  },
  async mounted() {
    if(!this.public_schedule) await this.getClinicAddress()
    this.form = this.getDefaultForm()
  },
  data() {
    return {
      clinic_address: null,    
      
      form: {
        name: null,

        zipCode: null,
        address: null,
        number: null,
        neighborhood: null,
        complement: null,
        city: null,
        state: null,

        phone: null,
        cellphone: null,
        email: null,
        email2: null
      },
      validated: false,
      validateEmail1: false,
      validateEmail2: false
    }
  },
  methods: {      
    async getClinicAddress(){
      const isLoading = this.$loading.show();
      try {
        const response = await this.api.getClinicAddress(this.clinic.id);
        this.clinic_address = !this.public_schedule ? response.data : null
      } catch (err) {
        this.$toast.error(err.message);
      } finally {
        isLoading.hide();
      }
    },

    getDefaultForm() {
      return {
        name: this.public_schedule ? this.public_schedule.name : (this.clinic ? this.clinic.name : ''),

        zipCode: this.public_schedule && this.public_schedule.address ? this.public_schedule.address.zipCode : (this.clinic_address ? this.clinic_address.zipCode : ''),
        address: this.public_schedule && this.public_schedule.address ? this.public_schedule.address.address : (this.clinic_address ? this.clinic_address.address : ''),
        number: this.public_schedule && this.public_schedule.address ? this.public_schedule.address.number : (this.clinic_address ? this.clinic_address.number : ''),
        neighborhood: this.public_schedule && this.public_schedule.address ? this.public_schedule.address.neighborhood : (this.clinic_address ? this.clinic_address.neighborhood : ''),
        complement: this.public_schedule && this.public_schedule.address ? this.public_schedule.address.complement : (this.clinic_address ? this.clinic_address.complement : ''),
        city: this.public_schedule && this.public_schedule.address ? this.public_schedule.address.city : (this.clinic_address ? this.clinic_address.city : ''),
        state: this.public_schedule && this.public_schedule.address ? this.public_schedule.address.state : (this.clinic_address ? this.clinic_address.state : ''),
        country: 'Brasil',
        
        phone: this.public_schedule ? this.public_schedule.phone : null,
        cellphone: this.public_schedule ? this.public_schedule.cellphone : null,
        email: this.public_schedule ? this.public_schedule.email : null,
        email2: this.public_schedule ? this.public_schedule.email2 : null
      }
    },

    searchCEP() {
      if (this.form.zipCode.length === 9) {
        cep(this.form.zipCode)
          .then(res => {
            this.form.address = res.street
            this.form.neighborhood = res.neighborhood
            this.form.city = res.city
            this.form.state = res.state
            this.form.number = ''
            this.form.complement = ''

            this.$refs.addressNumber.focus()
          })
          .catch(err => {
            console.log('Err', err)
          })
      }
    },

    isValidForm() {
      this.validated = true;
      this.validateEmail1 = false
      this.validateEmail2 = false

      if(!this.form.email.includes("@") && !this.form.email.includes(".")) this.validateEmail1 = true
      if(this.form.email2 && (!this.form.email2.includes("@") && !this.form.email2.includes("."))) this.validateEmail2 = true
      if(this.validateEmail1 || this.validateEmail2) return false

      return this.form.name &&
        this.form.zipCode &&
        this.form.address &&
        this.form.number &&
        this.form.neighborhood &&
        this.form.city &&
        this.form.state &&
        this.form.phone &&
        this.form.cellphone &&
        this.form.email
    },

    async createPublicSchedule(){
      if(!this.isValidForm()) return;
      const isLoading = this.$loading.show();
      try {
        this.form.clinic_id = this.clinic.id

        if((this.form.zipCode === this.clinic_address.zipCode) && 
          (this.form.address === this.clinic_address.address) && 
          (this.form.number === this.clinic_address.number) && 
          (this.form.neighborhood === this.clinic_address.neighborhood) && 
          (this.form.complement === this.clinic_address.complement) && 
          (this.form.city === this.clinic_address.city) && 
          (this.form.state === this.clinic_address.state)
        ){
          this.form.address_id = this.clinic_address.id        
        }
        
        await this.api.createPublicSchedule(this.form);
        this.$toast.success('Clinica agendamento online criada com sucesso!');
        this.$emit('reload-public-schedule')
      } catch (err) {
        this.$toast.error(err.message);
      } finally {
        isLoading.hide();
      }
    },

    async updatePublicSchedule(){
      if(!this.isValidForm()) return;
      const isLoading = this.$loading.show();
      try {               
        this.form.address_id = this.public_schedule.address.id
        await this.api.updatePublicSchedule(this.public_schedule.id, this.form);
        this.$toast.success('Clinica agendamento online editada com sucesso!');
        this.$emit('reload-public-schedule')
      } catch (err) {
        this.$toast.error(err.message);
      } finally {
        isLoading.hide();
      }
    }
  },
  watch: {
    'form.zipCode': function (newValue, oldValue) {
      if (newValue.length === 9 && !!oldValue) {
        this.searchCEP()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-title{
    color: var(--neutral-600);
    font-size: 18px;
    font-weight: bolder;
    line-height: 28px;
  }

  .title-styles {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0C1D59;
    margin: 0;
  }

  .hr {
    margin: 30px 0px 30px 0px;
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0px 0px 0px !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-000);
    }
  }

  small {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #8696AC;
  }
</style>
