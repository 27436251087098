<template>
  <div class="pt-container">
    <div v-if="!modal">
      <v-tab-header-info
        title="Literatura médica"
        tooltip="Visualize os protocolos criados de acordo com a <strong>literatura médica</strong> pela Eyecare Health."
        :print="true"
      />

      <v-protocol-filter
        typeChangeProtocol="Medical"
        @changeFilter="changeFilter"
      />
    </div>

    <v-protocol-table
      v-for="protocol in protocols"
      :key="protocol.id"
      :protocol="protocol"
      :modal="modal"
      @selected-protocols="selectProtocol"
      @unselected-protocols="unselectProtocol"
      :selectProtocols="selectProtocols"
      @update-protocols="getProtocols"
    />

    <v-no-filter-results
      v-if="protocols && protocols.length == 0"
      :message="'Os filtros aplicados não geraram nenhum resultado.'"
    />

    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        first-number
        last-number
      />
    </div>
  </div>
</template>

<script>
import TabHeaderInfo from '@/components/General/TabHeaderInfo'
import ProtocolFilters from '@/components/Protocols/ProtocolFilters.vue'
import ProtocolTable from '@/components/Protocols/ProtocolTable.vue'
import NoFiltersResults from '@/components/General/noFilterResults'

export default {
  name: 'my-protocols-tab',
  async mounted() {
    await this.getProtocols()
  },
  data() {
    const clinic = JSON.parse(localStorage.getItem('clinic'))
    return {
      query: null,
      via: null,
      clinic,
      protocols: null,
      currentPage: null,
      perPage: 3,
      totalItems: 0
    }
  },
  props: {
    modal: Boolean,
    selectProtocols: Array,
    queryName: String,
    person: Object
  },
  components: {
    'v-tab-header-info': TabHeaderInfo,
    'v-protocol-filter': ProtocolFilters,
    'v-protocol-table': ProtocolTable,
    'v-no-filter-results': NoFiltersResults
  },
  methods: {
    async getProtocols() {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getProtocols(
          'medical_literature',
          this.clinic.id,
          this.query,
          null,
          this.via,
          null,
          null,
          this.currentPage,
          this.person.id
        )
        this.currentPage = response.data.current_page
        this.perPage = response.data.per_page
        this.totalItems = response.data.total
        this.protocols = response.data.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async changeFilter(filters) {
      this.query = filters.query
      this.via = filters.via
      await this.getProtocols()
    },
    selectProtocol(value) {
      this.$emit('select-protocols', value)
    },
    unselectProtocol(value) {
      this.$emit('unselect-protocols', value)
    }
  },
  watch: {
    async currentPage() {
      await this.getProtocols()
    },
    async queryName() {
      this.query = this.queryName
      await this.getProtocols()
    }
  }
}
</script>

<style lang="scss" scoped>
.pt-container {
  padding: 30px 10px;

  .pagination-position {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
