const store = {
  namespaced: true,
  state: () => ({
    show: false,
    module: null,
  }),
  mutations: {
    setShow: (state, show) => {
      state.show = show
    },
    setProps: (state, props) => {
      state.module = props.module
    }
  },
  actions: {
    closeModal(context) {
      context.commit('setShow', false)
      context.commit('setProps', { module: null })
    },
    setAttachmentFilesModalProps(context, payload) {
      context.commit('setShow', true)
      context.commit('setProps', payload)
    }
  },
  getters: {},
}

export default store
