<!-- View Redefiniçao de senha - Responsável pela redefinição de senha dos usuários. -->

<template>
  <b-container class="reset-password" fluid>
    <b-row>
      <b-col cols="6" d-flex class="vh-100 d-flex justify-content-center align-items-center reset-side__content left-section">
        <div class="form-side text-left">
          <img src="@/assets/images/eyecare-logo-colored.png" class="logo-eyecare">

          <validation-observer ref="loginForm" v-slot="{ invalid }">
            <p class="title">Redefinir senha de acesso</p>
            <p class="description" v-if="error === null">Redefina sua senha de acesso para entrar no EyecareBI.</p>
            <p class="error-message" v-else>
              <AlertOctagon class="icon"/>
              {{ error }}
            </p>

            <div>
              <div class="form-group">
                <label for="email">E-mail</label>
                <input autocomplete="off" id="email" type="text" v-model="email" class="form-control" placeholder="Digite seu email" readonly>
              </div>

              <div class="form-group">
                <label for="password">Senha</label>
                <validation-provider name="senha" rules="required|min:8|password_strength" v-slot="{ errors, dirty }" vid="password">
                  <input id="password" type="password" v-model="password" class="form-control" :class="{ 'error': errors.length > 0 && dirty }">
                  <span class="error-message" v-if="errors.length > 0 && dirty">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="confirmPassword">Confirmar senha</label>
                <validation-provider name="confirme sua senha" rules="required|password:@password" v-slot="{ errors, dirty }">
                  <input id="confirmPassword" type="password" v-model="password_confirmation" class="form-control" :class="{ 'error': errors.length > 0 && dirty }">
                  <span class="error-message" v-if="errors.length > 0 && dirty">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <b-button block variant="primary" :disabled="invalid" @click="resetPassword">Resetar senha e fazer login</b-button>
            </div>
          </validation-observer>
        </div>
      </b-col>
      <b-col cols="6" class="vh-100 p-0 carousel-side position-relative">
        <carousel class="vh-100" :autoplay="true" :loop="true" :autoplayTimeout="3000" :perPage="1" :paginationEnabled="true" :paginationSize="8" :paginationColor="'#A4B1DF'" :paginationPadding="8" :paginationActiveColor="'#FFFFFF'">
          <slide :style="{ 'background-image': 'url('+require('@/assets/images/carousel-img.png')+')'}">
            <div class="container">
              <p class="title">Olá,</p>
              <p class="text">Bem vindo ao EyecareBI, sua plataforma customizada para a Oftalmologia</p>
            </div>
          </slide>
          <slide :style="{ 'background-image': 'url('+require('@/assets/images/carousel-img2.png')+')'}">
            <div class="container">
              <p class="title">Dados inteligentes</p>
              <p class="text">Aqui você terá acesso a dados que poderão otimizar seu dia-a-dia no consultório</p>
            </div>
          </slide>
          <slide :style="{ 'background-image': 'url('+require('@/assets/images/carousel-img3.png')+')'}">
            <div class="container">
              <p class="title">Novo mindset</p>
              <p class="text">Além de facilitar a incorporação das inovações tecnológicas a sua prática diária</p>
            </div>
          </slide>
        </carousel>

        <img src="@/assets/images/eyecare-logo-white.png" class="logo-eyecare" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AlertOctagon from '@/assets/icons/alert-octagon.svg'

export default {
  name: 'Login',
  components: {
    AlertOctagon
  },
  created: function () {
    const isLoading = this.$loading.show()
    this.api.checkForgotPasswordToken({ token: this.$route.params.token }).then(res => {
      if (res.status === 200 && typeof res.data.email !== 'undefined') {
        const resData = res.data

        this.email = resData.email
      } else {
        this.$router.push('/login')
      }
    }).catch(err => {
      this.$router.push('/login')

      console.log('Err', err)
    }).finally(() => {
      isLoading.hide()
    })
  },
  data () {
    return {
      email: '',
      password: '',
      password_confirmation: '',
      error: null
    }
  },
  methods: {
    resetPassword () {
      const resetPasswordData = {
        token: this.$route.params.token,
        password: this.password
      }

      const isLoading = this.$loading.show()
      this.api.resetPassword(resetPasswordData)
      .then(res => {
        if (res.status === 200) this.$router.push('/login')
        this.$toast.success('Senha alterada com sucesso!');
      })
      .catch(err => {
        this.$toast.warning('A senha deve ser diferente da atual');
        console.log('Err', err)
      })
      .finally(() => {
        isLoading.hide()
      })
    }
  }
}
</script>

<style lang="scss">
  .reset-password {
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;

    .form-side {
      width: 70%;

      .logo-eyecare {
        width: 122px;
      }

      .title {
        font-family: 'Red Hat Display';
        font-weight: 700;
        font-size: 32px;
        line-height: 40.32px;
        color: var(--dark-blue);
        margin: 80px 0 20px 0;
      }

      .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--type-active);
        margin-bottom: 48px;
      }

      .error-message {
        background-color: #FEE8E6;
        border-radius: 8px;
        padding: 16px 26px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        margin: 4px 0 40px;

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 25px;
          stroke: var(--states-error);
        }
      }

      .mb-40 {
        margin-bottom: 40px;
      }

      .mt-48 {
        margin-top: 48px;
      }

      button {
        font-size: 18px;
        font-weight: 600;
        color: var(--neutral-000);
        padding: 16px;
        border-radius: 8px;
        line-height: 24px;
      }
    }
  }

  .carousel-side {
    .logo-eyecare {
      width: 82px;
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .VueCarousel {
    .VueCarousel-wrapper {
      height: 100%;

      .VueCarousel-inner {
        height: 100% !important;

        .VueCarousel-slide {
          background-size: cover;

          .container {
            position: absolute;
            bottom: 210px;
            padding: 0 125px;

            .title {
              font-family: 'Red Hat Display';
              font-weight: 700;
              font-size: 24px;
              line-height: 30.24px;
              color: var(--neutral-000);
            }

            .text {
              font-weight: 400;
              font-size: 18px;
              color: var(--neutral-100);
              margin-top: 24px;
            }
          }
        }
      }
    }

    .VueCarousel-pagination {
      position: absolute;
      bottom: 160px;

      .VueCarousel-dot {
        transition: all 500ms;
      }

      .VueCarousel-dot--active {
        width: 16px !important;
        border-radius: 16px !important;
      }
    }
  }

  @media (max-width: 680px) {
    .reset-password {
      .left-section {
        padding: 16px 0;
      }
      .form-side {
        button {
          font-size: .9rem !important;
          margin-bottom: 16px;
        }
      }
      .reset-side__content {
        min-width: 100%;
      }

      .carousel-side {
        display: none;
      }
    }
  }

  @media (max-width: 320px) {
    .reset-password {
      .reset-side__content {
        min-width: 100%;
      }

      .carousel-side {
        display: none;
      }
    }
  }
</style>
