<!--- View de configurações da clinica - Responsável pelo gerenciamento de configurações da clinica. -->

<template>
  <div 
    v-can="'AgOnlCli1'"
    class="clinic-settings"
  >
    <div class="d-flex justify-content-between mb-3">      
      <p class="title">Agendamento online</p>

      <b-button 
        v-can="'AgOnlProc1'"
        v-if="public_schedule?.id"
        variant="outline-primary"
        @click="getPublicScheduleUrl()"
      >
        <Copy/>
        Copiar link do autoagendamento
      </b-button>
    </div>

    <div class="container-data">
      <div class="tabs">
        <span  
          v-can="'AgOnlCli1'"
          class="tab" 
          :class="{ 'active': currentTab == 'clinic' }"
          @click="currentTab = 'clinic'"
        >
          Clínica
        </span>

        <span  
          v-can="'AgOnlProf1'"
          class="tab" 
          :class="{ 'active': currentTab == 'professionals' }" 
          @click="changeToTab('professionals')"
        >
          Profissionais
        </span>
        
        <!-- <span 
          class="tab" 
          :class="{ 'active': currentTab == 'custom-sorting' }" 
          @click="currentTab = 'custom-sorting'"
        >
          Triagem personalizada
        </span> -->

        <span 
          v-can="'AgOnlProc1'"
          class="tab" 
          :class="{ 'active': currentTab == 'professionals-procedures' }" 
          @click="changeToTab('professionals-procedures')"
        >
          Procedimento dos profissionais
        </span>
      </div>
      <div class="body flex-fill">
        <div v-if="currentTab === 'clinic'">
          <ClinicView 
            :public_schedule="public_schedule" 
            :clinic="clinic"
            @reload-public-schedule="getPublicScheduleByClinicId"            
          />
        </div>
        <div v-if="currentTab === 'professionals'">
          <ProfessionalsView 
            :public_schedule_id="public_schedule ? public_schedule.id : null" 
            :professionals="professionals" 
            @reload-public-schedule="getPublicScheduleByClinicId"
          />
        </div>
        <!-- <div v-if="currentTab === 'custom-sorting'">
          <CustomSortingView 
            :public_schedule="public_schedule"
            :clinic="clinic"
          />
        </div> -->
        <div v-if="currentTab === 'professionals-procedures'">
          <ProfessionalsProceduresView 
            :professionals="computedProfessionals"
            :clinic="clinic"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClinicView from '@/components/PublicScheduleSettings/ClinicView.vue'
// import CustomSortingView from '@/components/PublicScheduleSettings/CustomSortingView'
import ProfessionalsProceduresView from '@/components/PublicScheduleSettings/ProfessionalsProceduresView'
import ProfessionalsView from '@/components/PublicScheduleSettings/ProfessionalsView'
import { getCurrentClinic } from '@/utils/localStorageManager'
import Copy from '@/assets/icons/copy.svg'

export default {
  name: 'PublicScheduleView',
  metaInfo: {
    title: 'Eyecare - Agendamento online'
  },
  components: {
    Copy,
    ClinicView,
    // CustomSortingView,
    ProfessionalsProceduresView,
    ProfessionalsView
  },  
  computed: {
    computedProfessionals() {
      return this.professionals?.length ? (
        this.professionals.filter(
          person => person.public_schedule_professional && person.public_schedule_professional.active) 
      ) : []
    }
  },
  async created(){
    await this.getPublicScheduleByClinicId()
  },
  data () {
    return {
      clinic: getCurrentClinic(),
      public_schedule: null,
      professionals: {},

      currentTab: 'clinic',
    }
  },
  methods: {
    async getPublicScheduleByClinicId(){
      const isLoading = this.$loading.show();
      try {
        const response = await this.api.getPublicScheduleByClinicId(this.clinic.id);
        this.public_schedule = Object.keys(response.data).length ? response.data : null
      } catch (err) {
        this.$toast.error(err.message);
      } finally {
        isLoading.hide();
        if(this.public_schedule) this.getProfessionalsByClinicId();
      }
    },

    async getProfessionalsByClinicId(){
      const isLoading = this.$loading.show();
      try {
        const response = await this.api.getProfessionalsByClinicId(this.clinic.id, 'config');
        this.professionals = Object.keys(response.data).length ? response.data : null
      } catch (err) {
        this.$toast.error(err.message);
      } finally {
        isLoading.hide();
      }
    },
    changeToTab(tab){
      this.getProfessionalsByClinicId()
      this.currentTab = tab
    },
    getPublicScheduleUrl(){
      navigator.clipboard.writeText(process.env.VUE_APP_PUBLIC_SCHEDULE_URL+'clinic/'+this.clinic.id);
      this.$swal({
        icon: 'info',
        html: `<div class="body-alert">O link para o autoagendamento foi copiado com sucesso!</div>`,
        showCancelButton: false,
        confirmButtonColor: '#305BF2',
        confirmButtonText: 'Confirmar',
      })
    }
  }
}
</script>

<style lang="scss">
  .clinic-settings {
    min-height: 100%;
    padding: 30px 24px;
    text-align: left;

    .title {
      font-family: 'Red Hat Display';
      font-weight: 700;
      font-size: 24px;
      color: var(--dark-blue);
      margin-bottom: 27px;
    }

    .container-data {
      width: 100%;
      height: 100%;
      background-color: var(--neutral-000);
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      .tabs {
        height: 72px;
        border-bottom: 1px solid var(--neutral-200);
        display: flex;
        align-items: center;

        .tab {
          font-weight: 600;
          font-size: 16px;
          color: var(--type-active);
          padding: 22px 24px;
          cursor: pointer;
          border-bottom: 3px solid var(--neutral-000);

          &.active {
            color: var(--orange);
            border-bottom: 4px solid var(--orange);
          }
        }
      }

      .body {
        padding: 24px;

        .title {
          font-family: 'Red Hat Display';
          font-weight: 700;
          font-size: 18px;
          color: var(--dark-blue);
          margin-bottom: 24px;
        }

        .table {
          width: 100%;
          display: table;

          thead {
            th {
              font-weight: 700;
              font-size: 14px;
              color: var(--type-active);
              border-top: 0;
              padding: 4px 0;
              border-bottom: 2px solid var(--neutral-300);
            }
          }

          tbody {
            tr {
              border-bottom: 1px dashed var(--neutral-300);
            }

            td {
              font-weight: 400;
              font-size: 16px;
              color: var(--type-active);
              border-top: 1px dashed var(--neutral-300);
              padding: 16px 0;

              .more-with-menu {
                width: 24px;
                height: 28px;
                position: relative;
                display: inline-block;

                .more-icon {
                  width: 24px;
                  height: 24px;
                  stroke: var(--neutral-500);
                  transition: all 0.5s;
                  cursor: pointer;
                }

                .menu {
                  width: 233px;
                  position: absolute;
                  top: 28px;
                  right: 0;
                  background: var(--neutral-000);
                  border: 1px solid var(--neutral-200);
                  box-sizing: border-box;
                  box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                  border-radius: 8px;
                  padding: 24px;
                  opacity: 0;
                  transition: all 0.5s;
                  flex-direction: column;
                  align-items: start;
                  display: none;

                  .mtb-12 {
                    margin: 12px 0;
                  }

                  .btn {
                    font-weight: 600;
                    margin-bottom: 16px;

                    &.remove {
                      color: var(--states-error);
                    }
                  }
                }

                &:hover {
                  .more-icon {
                    transform: rotate(90deg);
                  }

                  .menu {
                    opacity: 1;
                    display: flex;
                    z-index: 10;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
