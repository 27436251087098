const getReloadContent = (state) => {
  return state.reloadContent
}

const getHealthPlanFields = (state) => {
  return state.healthPlanFields
}

export default {
  getReloadContent,
  getHealthPlanFields
}