import LacunaWebPKI from 'web-pki'
// const license = 'AhQAZXllY2FyZWhlYWx0aC5jb20uYnJqAGV5ZWNhcmViaS1zdGFnaW5nLmV5ZWNhcmVoZWFsdGguY29tLmJyLGlwNDoxMC4wLjAuMC84LGlwNDoxMjcuMC4wLjAvOCxpcDQ6MTcyLjE2LjAuMC8xMixpcDQ6MTkyLjE2OC4wLjAvMTYDAFBybwgAANh+AlcO3ggAAZ9BW6UfE4fG4r50NN8Cug6/1L1JRCE8yKzZOEUJeqQ83wIMAEAkF2X3Do9CNdZsOs6KCLoVvUdAR7XevCM03FlxiUTVNWz9GtZlQYZkNsmuIdhVXIdVkkUYDHlOj8LfxAaSDFxr4qX6UYnrLTcA5i0z6DXlzF4zehlxdr/Xyv5GCnKeVzl8a5Dv8s1CO/uvClPUBDD3BxpaCLU5sbwFuqhV4SmfFpvQ4mkGZAO4ycEbEK882jgfprTK/b1Yi4MHB7Pb3g9dDpbffs9Qbqir/OA5W16h5OhYHSl+MKE8nm2nFVdr2Q8RHJFMaY0PmsPj+Lpru31G6Es/3P9Y1rtgv1E=';

const pki = new LacunaWebPKI();

export const init = (token, thumbprint) => {
  return new Promise((resolve, reject) => {
    const sign = () => {
      pki.signWithRestPki({ token, thumbprint })
        .success(resolve)
        .fail(reject)
    }
    pki.init({ 
      // restPkiUrl: 'https://lacuna.dev.eyecarehealth.com.br/',
      ready: sign
    });
  })
}


export const readCertificate = (thumbprint) => {
  return new Promise((resolve, reject) => {
    pki.readCertificate(thumbprint)
      .success(resolve)
      .fail(reject)
  });
}


export const preAuthorizeSignatures = (thumbprint, documentsCount) => {
  return new Promise((resolve, reject) => {
    pki.preauthorizeSignatures({
      certificateThumbprint: thumbprint,
      signatureCount: documentsCount,
    })
    .success(resolve)
    .fail(reject);
    // .success(startBatch);
  });
}
