const updateSelectedEntry = (state, payload) => {
  state.selectedEntry = payload
}

const updateSelectedFile = (state, payload) => {
  state.selectedFile = payload
}

const updateBillToPay = (state, payload) => {
  state.billToPay = payload
}
export default {
  updateSelectedEntry,
  updateSelectedFile,
  updateBillToPay
}
