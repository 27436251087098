const setLoading = (state, payload) => {
    state.loadingTables = payload
}

const setTables = (state, payload) => {
    state.tables = payload
}


export default {
    setLoading,
    setTables,
}