
const medicalRecordsStore = {
  namespaced: true,
  state: () => ({
    medicalRecords: [],
    medicalRecordType: '',
    changeData: false,
  }),
  mutations: {
    setMedicalRecords(state, payload) {
      state.medicalRecords = payload
    },
    setMedicalRecordType(state, payload) {
      state.medicalRecordType = payload
    },
    setChangeData(state, payload) {
      state.changeData = payload
    }
  },
  actions: {
    setMedicalRecords(context, payload) {
      context.commit('setMedicalRecords', payload)
    },
    setMedicalRecordType(context, payload) {
      context.commit('setMedicalRecordType', payload)
    },
    setChangeData(context, payload) {
      context.commit('setChangeData', payload)
    }
  },
}

export default medicalRecordsStore
