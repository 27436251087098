<!--- View principal - Responsável Por renderizar a barra lateral e superior. --->

<template>
  <div class="min-vh-100 vh-100 d-flex no-scroll">
    <Sidebar />
    <div class="d-flex flex-column flex-1 no-scroll ml-72 hidden-on-print">
      <div v-if="url != 'eyecarebi.eyecarehealth.com.br'" class="not-prod-env">
        <span>AMBIENTE DE TESTES - {{ url.includes('staging') ? 'STAGING' : url.includes('-dev') ? 'DEVELOPMENT' : url.includes('homolog') ? 'HOMOLOGAÇÃO' : 'LOCAL HOST' }} ! </span>
      </div>
      <Topbar />
      <div class="flex-1 no-scroll when-print" ref="mainScroll" v-bind:class="{ 'bg-neutral-100':routePath !== '/agenda'}">
        <router-view />
      </div>
    </div>

    <v-warehouse-notifications-modal :notifications="notifications" />
    <v-checkterm-modal
      :checkedPolicy="checkedPolicy"
      :checkedTerm="checkedTerm"/>
  </div>
</template>

<script>
import Sidebar from '../components/Sidebar'
import Topbar from '../components/Topbar'
import WarehouseNotificationsModal from '../components/Warehouse/WarehouseNotificationsModal'
import CheckTermModal from '@/components/General/CheckTermModal.vue'

export default {
  name: 'Home',
  components: {
    Sidebar,
    Topbar,
    'v-warehouse-notifications-modal': WarehouseNotificationsModal,
    'v-checkterm-modal': CheckTermModal,
  },
  async mounted () {
    this.eventHub.$on('attendance-opened', (attendanceData) => {
      if (attendanceData !== null && typeof attendanceData.patient_id !== 'undefined' && typeof attendanceData.id !== 'undefined') {
        const attendancePath = '/pacientes/' + attendanceData.patient_id + '/consulta/' + attendanceData.id
        if (this.$router.currentRoute.path !== attendancePath) {
          this.$router.push(attendancePath)
        }
      }
    });
    this.getWarehouseNotifications();
    this.getCheckTerms();

    setTimeout(() => {
      window.Beamer && !window.Beamer.initialized && window.Beamer.init()
    }, 3000)
  },
  data () {
    return {
      notifications: [],
      routePath: window.location.pathname,
      checkedTerm: true,
      checkedPolicy: true,
      url: window.location.host
    }
  },
  methods: {
    getWarehouseNotifications() {
      const clinic = JSON.parse(localStorage.getItem('clinic'));
      this.api.getWarehouseNotifications(clinic.id)
      .then(res => {
        if (res.data.length) {
          this.notifications = res.data;
          this.$bvModal.show('warehouse-notifications');
        }
      })
      .catch(err => {
        console.log('Error loading warehouse notifications', err);
      })
    },
    getCheckTerms(){
      this.api.checkTerms()
      .then(res => {
        this.checkedTerm = res.data.checkedTerm
        this.checkedPolicy = res.data.checkedPolicy
        if(!this.checkedTerm || !this.checkedPolicy){
          this.$bvModal.show('check-term-modal')
        }
      })
      .catch(err => {
        console.log('Error check terms', err);
      })
    },
  },
  watch:{
    $route (from){
      this.routePath = from.path
    }
}
}
</script>

<style lang="scss" scoped>
  .no-scroll {
    overflow: hidden;
  }

  .bg-neutral-100 {
    background-color: var(--neutral-100);
    height: 100%;
    overflow-y: scroll;
  }

  .ml-72 {
    margin-left: 72px;
  }

  .not-prod-env {
    height: 30px;
    color: yellow;
    background-color: darkred;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: 800;
  }
</style>
