export const cpfFormated = cpf => {
  if (!cpf) return ''

  const numericCPF = cpf.replace(/\D/g, '')

  return numericCPF.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
}

export const cnpjFormated = cnpj => {
  if (!cnpj) return ''

  const numericCNPJ = cnpj.replace(/\D/g, '')

  return numericCNPJ.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    '$1.$2.$3/$4-$5'
  )
}

export const numberWithoutPercent = number => {
  if (!number) return

  const numberWithoutPercent = parseFloat(
    number?.replace(',', '.').replace('%', '')
  )

  return numberWithoutPercent
}

/**
 * Simple way to verify if a variable is an Object without employ the lodash or underscore size overloading
 * @param obj
 * @returns {boolean}
 */
export const isObject  = obj => {
  return typeof obj === 'object' && obj !== null && ! Array.isArray(obj)
}
