const updateSelectedEntry = async (context, payload) => {
  await context.commit('updateSelectedEntry', payload)
}

const updateSelectedFile = async (context, payload) => {
  await context.commit('updateSelectedFile', payload)
}

const updateBillToPay = async (context, payload) => {
  await context.commit('updateBillToPay', payload)
}

const clearState = async (context) => {
  await context.commit('updateSelectedEntry', null)
  await context.commit('updateSelectedFile', null)
  await context.commit('updateBillToPay', null)
}
export default {
  updateSelectedEntry,
  updateSelectedFile,
  updateBillToPay,
  clearState
}
