import { updateAttendanceForm } from '@/utils/attendanceFormHelper'

const store = {
  namespaced: true,
  state: () => ({
    id: 'anexar-arquivos',
    name: 'Anexar arquivos',
    opened: false,
    showing: false,
    completed: false,
    multiple: true,
    scopes: ['PRE_CONSULTATION', 'EXAM'],
    value: [],
  }),
  mutations: {
    pushFile: (state, value) => {
      state.value.push(value)
    },
    pushFiles: (state, value) => {
      state.value = state.value.concat(value)
    },
    removeFile: (state, index) => {
      state.value.splice(index, 1)
    },
    handleProps: (state, { key, value }) => {
      state[key] = value
    },
    handleFields: (state, { key, value }) => {
      state.value[key] = value
    },
    checkComplete: state => {
      state.completed = !!state.value.length
    }
  },
  getters: {},
  actions: {
    pushFile (context, value) {
      context.commit('pushFile', value)
      context.commit('checkComplete')
    },
    pushFiles (context, value) {
      context.commit('pushFiles', value)
      context.commit('checkComplete')
    },
    removeFile (context, index) {
      context.commit('removeFile', index)
      context.commit('checkComplete')
    },
    handleProps(context, { key, value }) {
      context.commit('handleProps', { key, value })
      if(context?.rootState?.attendance?.attendance?.id && ['showing' , 'opened'].includes(key)) {
        const form = context?.rootGetters['attendance/form/getSelectedForm']
        const forms = form.forms
        const index = forms?.findIndex(form => {
          return form.id === context.state.id
        })
  
        if(index && index !== -1){
          forms[index][key] = value
          form.forms = forms
          updateAttendanceForm(context?.rootState?.attendance?.attendance?.attendance_form?.id, {forms: forms})
          context.dispatch(`attendance/form/updateAttendanceForms`, form, { root: true })
        }
      }
    },
    handleFields(context, { key, value }) {
      context.commit('handleFields', { key, value })
      context.commit('checkComplete')
    }
  },
}

export default store
