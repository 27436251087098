const updateCNPJForm = (state, payload) => {
  state.CNPJForm = payload
}

const updateIsEdit = (state, payload) => {
  state.isEdit = payload
}

const updateShowCNPJForm = (state, payload) => {
  state.showCNPJForm = payload
}

const updateNfModalData = (state, payload) => {
  state.nfModalData = payload
}

const updateNfTableData = (state, payload) => {
  state.nfTableData = payload
}

const updateIsPatientDisabled = (state, payload) => {
  state.isPatientDisabled = payload
}

const updateProviderModalSelected = (state, payload) => {
  state.providerModalSelected = payload
}

const updateIsScheduledInvoice = (state, payload) => {
  state.isScheduledInvoice = payload
}
/**
 * Fetch companies from server and updates the company listing
 * @param state
 * @param payload
 */
const updateCompanies = (state, payload) => {
  state.companies = payload
}
/**
 * update a company by its list position index
 * @param state
 * @param payload
 */
const updateCompany = (state, payload) => {
  Object.keys(payload.data).forEach((key, idx) => {
    state.companies[payload.index][key] = payload.data[key]
    console.log(payload.data[key])
  })
}
export default {
  updateCNPJForm,
  updateIsEdit,
  updateShowCNPJForm,
  updateNfModalData,
  updateNfTableData,
  updateIsPatientDisabled,
  updateProviderModalSelected,
  updateIsScheduledInvoice,
  updateCompanies,
  updateCompany,
}
