<template>
  <div class="prescription">

    <div class="terms-content" v-if="!this.termo">
      <img src="@/assets/images/eyecare-logo-colored.png" class="prescription-logo">
      <div class="prescription-title">Bem-vindo(a), aqui estão seus documentos da consulta!</div>
      <p>
        Para prosseguir, pedimos que leia e aceite nossos <a target="_blank" href="https://eyecarehealth.com.br/termos-e-politicas/termos-de-uso">Termos de uso</a> e as <a href="https://eyecarehealth.com.br/termos-e-politicas/politica-de-privacidade">Políticas de privacidade</a>.
      </p>
      <b-button
        class="button"
        @click="aceitarTermos"
        variant="primary"
      >
        Aceitar e continuar
      </b-button>
      <p class="terms-description">
        Ao aceitar você declara ser o titular ou o representante legal do titutlar da receita/documento digital e estar de acordo com os <a target="_blank" href="https://eyecarehealth.com.br/termos-e-politicas/termos-de-uso">Termos de uso</a> e com a <a href="https://eyecarehealth.com.br/termos-e-politicas/politica-de-privacidade">Política de privacidade</a> da Eyecare Health
      </p>
    </div>
    <div class="prescription-content" v-else>
      <img src="@/assets/images/eyecare-logo-colored.png" class="prescription-logo">
      <div class="prescription-title">Olá, {{patient}}</div>
      <p class="description">
        Abaixo você terá acesso aos documentos emitidos na sua consulta com 
        {{ professional?.gender === 'F' ? 'Dra.' : 'Dr.' }} {{ professional?.name }}
        no dia 
        {{ this.moment(this.startTime).format('DD') }} de {{ this.moment(this.startTime).format('MMMM') }} de {{ this.moment(this.startTime).format('yyyy') }}
      </p>

      <div class="text-document">Selecione um documento para baixar e visualizar:</div>

      <div class="document-card-content">
        <div v-for="(doc, index) in docs.filter(doc => parseInt(doc?.type_id) !== 0)" :key="index" class="document-card" @click="openFile(doc)">
          <p v-if="parseInt(doc?.type_id) === 0 && doc?.attendance?.type === 'PRE_CONSULTATION'">
            Pré-consulta
          </p>
          <p v-else-if="doc?.length >= 2">
            {{ doc?.group_name }}
          </p>
          <p v-else-if="parseInt(doc?.type_id) >= 0">
            {{ typeDoc[doc?.type_id] }}
          </p>
          <p v-else>
            {{ doc?.custom_name ?? 'Arquivo' }}
          </p>
          <Download class="icon" />
        </div>
      </div>
     <div class="advertising">
        <AdvertisingOticas  @click="handleClickOnAdvertising('oticas')" class="advertising-oticas" v-if="isClinicBv"/>
        <AdvertisingOculli @click="handleClickOnAdvertising('oculli')" v-else/>
      </div>
      <div class="line">
     </div>
      <div class="text-document text-center">Mostre o QR Code na farmacia ou ótica e compre sem precisar imprimir sua receita</div>
      <div class="qrcode-content">
        <qrcode-vue 
          class="qrcode" 
          :value="this.url+'/receita-farmacia/'+ this.$route.params.attendanceid" 
          :size="150" 
          level="H" 
        />
      </div>
      
      <div class="share-content">
        <b-form-input
          id="cell"
          autocomplete="off"
          v-model="cellphone"
          v-mask="['(##) #####-####', '(##) #####-####']"
          placeholder="Informe um número WhatsApp para compartilhar"
        />
            
        <b-button
          :disabled="!cellphone"
          variant="primary"
          @click="openWhatsApp()">
          Compartilhar
        </b-button>

        <b-button
          variant="outline-secondary"
          @click="copyText()">
          Copiar link
        </b-button>
      </div>
          
    </div>
  </div>
</template>

<script>
import { getDocTypes, docsToShowOnEmail } from '@/utils/documentHelper.js'
import { debounce } from 'lodash';
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'PatientPrescription',
  metaInfo: {
    title: 'Eyecare - Documentos do paciente',
  },
  components: {
    Download: () => import('@/assets/icons/download.svg'),
    AdvertisingOculli: () => import('@/assets/images/advertising-oculli.svg'),
    AdvertisingOticas: () => import('@/assets/images/advertising-oticas.svg'),
    QrcodeVue,
  },
  props: {
  },
  computed: {
    clinicType() {
      return this.attendance?.clinic?.type
    },
    isClinicBv() {
      return this.clinicType === 'BV'
    },
  },
  data () {
    return {
      termo: false,
      typeDoc: getDocTypes(),
      docsToShow: docsToShowOnEmail(),
      docs: [],
      attendance: [],
      patient: null,
      professional: null,
      cellphone: null,
      checkedTerm: null,
      checkedPolicy: null,
      url: process.env.VUE_APP_URL,
      startTime: null
    }
  },
  created() {
    this.getAttendanceFiles(this.$route.params.attendanceid)
    this.getMedicalRecord(this.$route.params.attendanceid)
  },
  methods: {
    async onAdvertisingClick(advertising) {
      switch(advertising) {
        case 'oticas':
          window.open('https://app.beneficiovisao.com.br/descontos', '_blank');
          break;
        case 'oculli':
          break;
      }

      await this.api.trackAdvertisingPatientDocs({ advertise: advertising });
    },
    handleClickOnAdvertising: debounce(function(advertising) {
      this.onAdvertisingClick(advertising);
    }, 1000),   
    
    copyText(){
      navigator.clipboard.writeText(process.env.VUE_APP_URL+'/receita-farmacia/'+this.$route.params.attendanceid);
      this.$toast.success('Link copiado com sucesso')
    },
    openWhatsApp() {
      const patientName = this.attendance?.patient?.name
      const whatsappNumber = this.cellphone.replace(/\D/g, '')
      let linkWhatsapp = `https://api.whatsapp.com/send?phone=55${whatsappNumber}
        &text=Olá, este é um link de acesso aos documentos do paciente ${patientName}, assinados pelo médico(a) responsável. Clique e acesse: `
        linkWhatsapp += `${process.env.VUE_APP_URL+'/receita-farmacia/'+this.$route.params.attendanceid}`

        window.open(linkWhatsapp, "_blank")
    },
    async getCheckTerms(){
      const patientid = this.attendance?.patient_id

      await this.api.checkTermsPerson(patientid)
      .then(res => {
        this.checkedTerm = res.data.checkedTerm
        this.checkedPolicy = res.data.checkedPolicy
        if(this.checkedTerm && this.checkedPolicy){
          this.termo = true
        }else {
          this.termo = false
        }
      })
      .catch(err => {
        console.log('Error check terms', err);
      })
    },
    aceitarTermos() {
        const patientid = this.attendance?.patient_id
        this.api.acceptTermsPerson(patientid) 
      .then(res=> {
        this.getCheckTerms()
      })
      .catch(err => {
        console.log('Error check terms modal', err);
      })
    },
    getMedicalRecord(attendanceId) {
      this.api.getAttendanceById(attendanceId)
      .then(res => {
        this.attendance = res.data 
        this.startTime = res.data.start_datetime
        this.patient = res.data?.patient?.name 
        this.professional = res.data?.professional
        this.getCheckTerms()
      })
      .catch(err => { console.log('ERR getAttendanceFiles => ', err)})
      .finally(() => {  })
    },
    getAttendanceFiles(attendance) {
      const loading = this.$loading.show()
      this.api.getAttendanceFiles(attendance)
        .then(res => {
          this.docs = res.data.filter(el => {
          return this.docsToShow.includes(this.typeDoc[el?.type_id])
        })
      })
      .catch(err => { console.log('ERR getAttendanceFiles => ', err)})
      .finally(() => { loading.hide() })
    },
    getDocUrl(path){
      const loading = this.$loading.show()
      this.api.getDocTemporaryUrl(path)
      .then(res => {
        window.location.href = res.data
      })
      .catch(err => { console.log('ERR getAttendanceFiles => ', err)})
      .finally(() => { loading.hide() })
    },
     openFile(file) {
      if(file) {
         this.getDocUrl(file.path)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .prescription {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background: #f0f0f0;
    text-align: center;

    .success-message {
      width: 550px;
      background-color: var(--states-success-soft-green);
      border-radius: 8px;
      padding: 16px 26px;
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      margin: 4px 0 40px;

      .icon {
        width: 24px;
        height: 24px;
        margin-right: 24px;
        fill: var(--states-success);
      }
    }
    .error-message {
      width: 550px;
      background-color: #ffeae8;
      border-radius: 8px;
      padding: 16px 26px;
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      margin: 4px 0 40px;
    }
  }

  .line{
    border-top: 1px dashed var(--neutral-300);
    margin-bottom: 16px;
  }

  .prescription-logo {
    width: 123px;
    height: 24px;
    margin-bottom: 4rem;
  }

  .terms-content {
    max-width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    overflow-x: auto;
  }

  .prescription-content {
    max-width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    overflow-x: auto;
    justify-content: center;
  }

  .prescription-content .advertising {
    display: block; 
    text-align: center; 
    margin-top: 2rem;
    max-width: 100%;
  }

  .prescription-content .advertising .advertising-oticas {
     cursor: pointer;
  }
  
  .prescription-content .advertising svg {
    display: inline-block;
    max-width: 100%;
    width: 100%; 
    height: auto; 
    overflow: visible; 
  }
   .prescription-title {
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: 1.5rem;
    color: var(--dark-blue);
    margin-bottom: 20px;
  }
  .terms-description {
    font-size: 0.8rem;
    color: #7c7c7c;
  }
  .text-document {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: var(--dark-blue);
    margin: 5px !important;
  }
  .file-container {
    display: flex;
    flex-direction: row;
    gap: 20px;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: var(--type-active);
      align-items: center;
      display: flex;
    }
  }
  .qrcode-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .qrcode {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 165px;
    height: 165px;
    border: 5px solid var(--neutral-200);
    border-radius: 8px;
  }
  .document-card-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border: 1px dashed var(--neutral-300);
    border-radius: 8px;
    margin-bottom: 2rem;
  }
  .document-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding:0.75rem;
    cursor: pointer;
  }
  .icon {
    width: 24px;
    height: 24px;
    fill: var(--blue-500);
  }
  .doc-iframe {
    width: 100%;
    height: 100%;
  }
  .text-center {
    text-align: center;
  }
  .share-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1rem;
    button {
      font-size: 14px !important;
    }
  }
  .button {
    font-size: 14px !important;
  }
</style>
