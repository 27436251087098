import { updateAttendanceForm } from '@/utils/attendanceFormHelper'

const store = {
  namespaced: true,
  state: () => ({
    id: 'complemento-glaucoma',
    name: 'Complemento Glaucoma',
    opened: false,
    showing: false,
    completed: false,
    multiple: false,
    scopes: ['ATTENDANCE', 'SURGERY', 'EXAM'],
    fields: {
        medicamentoOpcao1: null,
        primeiroMesOpcao1: null,
        segundoMesOpcao1: null,
        terceiroMesOpcao1: null,
        posologiaOpcao1: null,
        olhoOpcao1: null,
        medicamentoOpcao2: null,
        primeiroMesOpcao2: null,
        segundoMesOpcao2: null,
        terceiroMesOpcao2: null,
        posologiaOpcao2: null,
        olhoOpcao2: null,
        medicamentoOpcao3: null,
        primeiroMesOpcao3: null,
        segundoMesOpcao3: null,
        terceiroMesOpcao3: null,
        posologiaOpcao3: null,
        olhoOpcao3: null,
        medicamentoOpcao4: null,
        primeiroMesOpcao4: null,
        segundoMesOpcao4: null,
        terceiroMesOpcao4: null,
        posologiaOpcao4: null,
        olhoOpcao4: null,
        anamnese: null,
        tratamentoSim: null,
        tratamentoNao: null,
        incapazSim: null,
        incapazNao: null
    }
  }),
  mutations: {
    handleProps: (state, { key, value }) => {
      state[key] = value
    },
    handleFields: (state, { key, value }) => {
      state.fields[key] = value
    },
    checkComplete: state => {
      state.completed = Object.keys(state.fields).some(key => state.fields[key])
    }
  },
  getters: {},
  actions: {
    handleProps(context, { key, value }) {
      context.commit('handleProps', { key, value })

      if(context?.rootState?.attendance?.attendance?.id && ['showing' , 'opened'].includes(key)){
        const form = context?.rootGetters['attendance/form/getSelectedForm']
        const forms = form.forms
        const index = forms?.findIndex(form => {
          return form.id === context.state.id
        })
  
        if(index && index !== -1){
          forms[index][key] = value
          form.forms = forms
          updateAttendanceForm(context?.rootState?.attendance?.attendance?.attendance_form?.id, {forms: forms})
          context.dispatch(`attendance/form/updateAttendanceForms`, form, { root: true })
        }
      }
    },
    handleFields(context, { key, value }) {
      context.commit('handleFields', { key, value })
      context.commit('checkComplete')
    },
  },
}

export default store
