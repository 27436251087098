<!--- View de cadastro de profissionais - Responsável pelo cadastro dos novos profissionais. --->

<template>
  <b-container fluid class="register">
    <b-row>
      <b-col cols="6"
      d-flex
      class="vh-100 p-0 form-side text-left d-flex justify-content-center flex-column align-items-center">
        <b-row class="tabs">
          <b-col cols="4" class="tab" v-for="step in steps" :key="step.id" :class="{ 'active': step.id == currentStep.id, 'confirmed': step.confirmed }">
            {{ step.name }}
            <Check class="icon" v-if="step.confirmed" />
          </b-col>
        </b-row>

        <div class="form d-flex align-items-center">
          <validation-observer ref="registerFormAccessData" class="w-100" v-slot="{ invalid }" v-show="!submitted && currentStep.id == 0">
            <p class="title">Dados de acesso</p>
            <p class="description">Define seu email de login e sua senha de acesso.</p>

            <div class="mb-5">
              <div class="form-group">
                <label for="email">E-mail</label>
                <validation-provider
                  mode="lazy"
                  name="e-mail"
                  :rules="{ required: true, email: true }"
                  v-slot="{ errors, dirty }"
                >
                  <input
                    autocomplete="off"
                    id="email"
                    type="text"
                    v-model="registerData.email"
                    class="form-control"
                    placeholder="Digite seu email"
                    :class="{
                      error:
                        registerData.email.length > 0 &&
                        errors.length > 0 &&
                        dirty
                    }"
                  />
                </validation-provider>
              </div>

              <div v-if="!userExists" class="form-group">
                <label for="password">Senha</label>
                <validation-provider
                  name="senha"
                  rules="required|min:8|password_strength"
                  v-slot="{ errors, dirty }"
                  vid="password"
                >
                  <div class="input-password">
                    <input
                      id="password"
                      :type="!showPassword ? 'password' : 'text'"
                      v-model="registerData.password"
                      class="form-control"
                      :class="{error: errors.length > 0 && dirty }"
                    />
                    <EyeSlash
                      class="eye"
                      v-if="showPassword == false"
                      @click="showPassword = !showPassword"
                    />
                    <Eye
                      class="eye"
                      v-if="showPassword == true"
                      @click="showPassword = !showPassword"
                    />
                  </div>
                  <span
                    class="error-message"
                      v-if="errors.length > 0 && dirty"
                      >{{ errors[0] }}
                  </span>
                </validation-provider>
              </div>

              <div v-if="!userExists" class="form-group">
                <label for="repeatPassword">Confirmar senha</label>
                <validation-provider
                  name="senha"
                  rules="required|password:@password"
                  v-slot="{ errors, dirty }"
                >
                  <div class="input-password">
                    <input
                      id="repeatPassword"
                      :type="!showPassword ? 'password' : 'text'"
                      v-model="registerData.repeatPassword"
                      class="form-control"
                      :class="{error: errors.length > 0 && dirty }"
                    />
                    <EyeSlash
                      class="eye"
                      v-if="showPassword == false"
                      @click="showPassword = !showPassword"
                    />
                    <Eye
                      class="eye"
                      v-if="showPassword == true"
                      @click="showPassword = !showPassword"
                    />
                  </div>
                    <span
                      class="error-message"
                      v-if="errors.length > 0 && dirty"
                      >{{ errors[0] }}
                    </span>
                </validation-provider>
              </div>

              <b-button
                block variant="primary"
                :disabled="invalid"
                @click="goToNextStep()"
                >Continuar
              </b-button>
            </div>
          </validation-observer>

          <validation-observer ref="registerFormProfessionalData" class="w-100" v-slot="{ invalid }" v-show="!submitted && currentStep.id == 1">
            <p class="title">Dados profissionais</p>
            <p class="description">Agora, nos informe os seus dados abaixo.</p>
            <p class="error-message-box" v-if="crmChecked != null && !crmChecked">
              <AlertOctagon class="icon"/>
              Verifique se o nome informado e o nome do CRM estão idênticos.
            </p>

            <div class="mb-5">
              <div class="form-group">
                <label for="name">Nome completo</label>
                <validation-provider name="nome completo" rules="required" v-slot="{ errors, dirty }">
                  <input autocomplete="off" id="name" type="text" v-model="registerData.name" class="form-control" :class="{ 'error': errors.length > 0 && dirty }">
                  <span class="error-message" v-if="errors.length > 0 && dirty">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="document">CPF</label>
                <validation-provider name="CPF" rules="required|cpf" v-slot="{ errors, dirty }" vid="document">
                  <input autocomplete="off" id="document" type="text" v-model="registerData.cpf" class="form-control" placeholder="000.000.000-00" v-mask="'###.###.###-##'" :class="{ 'error': errors.length > 0 && dirty }">
                  <span class="error-message" v-if="errors.length > 0 && dirty">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group" v-if="type.includes('DOCTOR')">
                <label for="crmState">CRM</label>
                <b-row>
                  <b-col cols="auto">
                    <div class="form-group state-width mb-0">
                      <validation-provider name="estado" :rules="type.includes('DOCTOR') ? 'required' : ''" v-slot="{ errors, dirty }">
                        <multiselect
                          v-model="registerData.crmState"
                          id="crmState"
                          :options="statesOptions"
                          @change="checkCRM"
                          :option-height="40"
                          :showLabels="false"
                          :searchable="true"
                          :allowEmpty="false"
                          placeholder="UF"
                          class="with-border"
                          :class="{ 'error': errors.length > 0 && dirty }"
                        >
                          <template slot="caret">
                            <div class="chevron">
                              <ChevronDown />
                            </div>
                          </template>

                          <template slot="noOptions">
                            Nenhuma opção
                          </template>

                          <template slot="noResult">
                            Nenhum resultado
                          </template>
                        </multiselect>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col>
                    <validation-provider name="número do CRM" :rules="type.includes('DOCTOR') ? 'required' : ''" v-slot="{ errors, dirty }">
                      <input
                        autocomplete="off"
                        id="crmNumber"
                        type="text"
                        v-model="registerData.crmNumber"
                        class="form-control"
                        @change="checkCRM"
                        :class="{ 'error': errors.length > 0 && dirty }"
                      >
                    </validation-provider>
                  </b-col>
                </b-row>
              </div>

              <div class="form-group">
                <label for="cellphone">Celular</label>
                <validation-provider name="celular" rules="required" v-slot="{ errors, dirty }">
                  <input
                    autocomplete="off"
                    id="cellphone"
                    type="text"
                    v-model="registerData.cellphone"
                    class="form-control"
                    placeholder="(xx) xxxx-xxxxx"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    :class="{ 'error': errors.length > 0 && dirty }"
                  >
                  <span class="error-message" v-if="errors.length > 0 && dirty">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <b-button block variant="primary" :disabled="invalid || (type.includes('DOCTOR') && !crmChecked)" @click="goToNextStep()">Continuar</b-button>
              <div class="d-inline-block">
                <p class="link" @click="backToStep(0)"><ChevronLeft /> Voltar</p>
              </div>
            </div>
          </validation-observer>

          <validation-observer ref="registerFormAddressData" class="w-100" v-slot="{ invalid }" v-show="!submitted && currentStep.id == 2">
            <p class="title">Endereço</p>
            <p class="description">Preencha todos os campos.</p>

            <div class="mb-5">
              <div class="form-group">
                <label for="zipcode">CEP</label>
                <validation-provider name="CEP" rules="required" v-slot="{ errors, dirty }">
                  <input autocomplete="off" id="zipcode" type="text" v-model="registerData.zipCode" class="form-control" @change="searchCEP" placeholder="00000-000" v-mask="'#####-###'" :class="{ 'error': errors.length > 0 && dirty }">
                </validation-provider>
              </div>

              <b-row>
                <b-col>
                  <div class="form-group">
                    <label for="address">Logradouro</label>
                    <validation-provider name="logradouro" rules="required" v-slot="{ errors, dirty }">
                      <input autocomplete="off" id="address" type="text" v-model="registerData.address" class="form-control" :class="{ 'error': errors.length > 0 && dirty }">
                    </validation-provider>
                  </div>
                </b-col>
                <b-col cols="auto">
                  <div class="form-group state-width">
                    <label for="number">Nº</label>
                    <validation-provider name="número" rules="required" v-slot="{ errors, dirty }">
                      <input autocomplete="off" id="number" ref="addressNumber" type="text" v-model="registerData.number" class="form-control" :class="{ 'error': errors.length > 0 && dirty }">
                    </validation-provider>
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <div class="form-group">
                    <label for="city">Cidade</label>
                    <validation-provider name="cidade" rules="required" v-slot="{ errors, dirty }">
                      <input autocomplete="off" id="city" type="text" v-model="registerData.city" class="form-control" :class="{ 'error': errors.length > 0 && dirty }">
                    </validation-provider>
                  </div>
                </b-col>
                <b-col cols="auto">
                  <div class="form-group state-width">
                    <label for="state">Estado</label>
                    <validation-provider name="estado" rules="required" v-slot="{ errors, dirty }">
                      <multiselect id="state" v-model="registerData.state" :options="statesOptions" :option-height="40" :showLabels="false" :searchable="true" :allowEmpty="false" placeholder="UF" class="with-border" :class="{ 'error': errors.length > 0 && dirty }">
                        <template slot="caret">
                          <div class="chevron">
                            <ChevronDown />
                          </div>
                        </template>

                        <template slot="noOptions">
                          Nenhuma opção
                        </template>

                        <template slot="noResult">
                          Nenhum resultado
                        </template>
                      </multiselect>
                    </validation-provider>
                  </div>
                </b-col>
              </b-row>

              <b-button block variant="primary" :disabled="invalid" @click="goToNextStep()">Continuar</b-button>
              <div class="d-inline-block">
                <p class="link" @click="backToStep(1)"><ChevronLeft /> Voltar</p>
              </div>
            </div>
          </validation-observer>

          <div v-if="submitted" class="form submitted text-center">
            <img src="@/assets/images/eyecare-logo-colored.png" class="logo-eyecare">
            <br/>
            <p class="check-container">
              <span class="check">
                <span class="container">
                  <Check class="icon" />
                </span>
              </span>
            </p>

            <p class="title">Cadastro criado</p>
            <p class="description">Verifique seu email para <b>confirmar o seu cadastro</b> na Eyecare.</p>

            <router-link to="/login">
              <b-button variant="primary" class="mt-5">Ir para página de login</b-button>
            </router-link>
          </div>
        </div>
      </b-col>
      <b-col cols="6" class="vh-100 p-0 carousel-side position-relative">
        <carousel class="vh-100" :autoplay="true" :loop="true" :autoplayTimeout="3000" :perPage="1" :paginationEnabled="true" :paginationSize="8" :paginationColor="'#A4B1DF'" :paginationPadding="8" :paginationActiveColor="'#FFFFFF'">
          <slide :style="{ 'background-image': 'url('+require('@/assets/images/carousel-img.png')+')'}">
            <div class="container">
              <p class="title">Olá,</p>
              <p class="text">Bem vindo ao EyecareBI, sua plataforma customizada para a Oftalmologia</p>
            </div>
          </slide>
          <slide :style="{ 'background-image': 'url('+require('@/assets/images/carousel-img2.png')+')'}">
            <div class="container">
              <p class="title">Dados inteligentes</p>
              <p class="text">Aqui você terá acesso a dados que poderão otimizar seu dia-a-dia no consultório</p>
            </div>
          </slide>
          <slide :style="{ 'background-image': 'url('+require('@/assets/images/carousel-img3.png')+')'}">
            <div class="container">
              <p class="title">Novo mindset</p>
              <p class="text">Além de facilitar a incorporação das inovações tecnológicas a sua prática diária</p>
            </div>
          </slide>
        </carousel>

        <img src="@/assets/images/eyecare-logo-white.png" class="logo-eyecare" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import * as cep from 'cep-promise'

import AlertOctagon from '@/assets/icons/alert-octagon.svg'
import Check from '@/assets/icons/check.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import ChevronLeft from '@/assets/icons/chevron-left.svg'
import Eye from '@/assets/icons/eye.svg'
import EyeSlash from '@/assets/icons/eye-slash.svg'

export default {
  name: 'Login',
  components: {
    AlertOctagon,
    Check,
    ChevronDown,
    ChevronLeft,
    Eye,
    EyeSlash,
  },
  async created() {
    const isLoading = this.$loading.show()
    try {
      await this.checkInvitationCode()
      await this.checkIfUserExists()
    } catch(err) {
      this.$router.push('/login')
    } finally {
      isLoading.hide()
    }
  },
  data () {
    return {
      userExists: false,
      type: null,
      steps: [
        { id: 0, name: 'Dados de acesso', confirmed: false, nextStep: 1 },
        { id: 1, name: 'Dados profissionais', confirmed: false, nextStep: 2 },
        { id: 2, name: 'Endereço', confirmed: false }
      ],
      currentStep: { id: 0, step: 'Dados de acesso', confirmed: false, nextStep: 1 },
      showPassword: false,
      submitted: false,
      registerData: {
        email: '',
        password: '',
        password_confirmation: '',
        name: '',
        cpf: '',
        crmState: '',
        crmNumber: '',
        cellphone: '',
        zipCode: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: ''
      },
      crmName: null,
      crmChecked: null,

      statesOptions: ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']
    }
  },
  watch: {
    'registerData.name': function (v) {
      if (this.crmName !== null) {
        this.crmChecked = (this.crmName === v)
      }
    },
    'registerData.zipCode': function (v) {
      if (v.length === 9) {
        this.searchCEP()
      }
    }
  },
  methods: {
    async checkInvitationCode() {
      try {
        const res = await this.api.checkInvitationCode({ code: this.$route.params.invitationCode });
        const resData = res.data
        this.clinicName = resData.clinic
        this.registerData.name = resData.person.name
        this.registerData.email = resData.person.email
        this.type = resData.person.type
      } catch (err) {

        console.log('Err', err)
      }
    },
    async checkIfUserExists() {
      const res = await this.api.userExists(this.registerData.email);
      this.userExists = res.data.userExists
    },
    goToNextStep () {
      let advanceToNextStep = true

      this.$refs.registerFormAccessData.reset()
      this.$refs.registerFormProfessionalData.reset()
      this.$refs.registerFormAddressData.reset()

      if (this.currentStep.id === 1) {
        advanceToNextStep = !this.type.includes('DOCTOR') || this.crmChecked
      }

      if (advanceToNextStep) {
        this.steps = this.steps.map(step => {
          if (step.id === this.currentStep.id) {
            return {
              ...step,
              confirmed: true
            }
          } else {
            return step
          }
        })

        if (typeof this.currentStep.nextStep !== 'undefined') {
          this.currentStep = this.steps.find(step => step.id === this.currentStep.nextStep)
        } else {
          this.acceptInvitation()
        }
      }
    },
    backToStep (stepId) {
      this.$refs.registerFormAccessData.reset()
      this.$refs.registerFormProfessionalData.reset()
      this.$refs.registerFormAddressData.reset()

      this.currentStep = this.steps.find(step => step.id === stepId)
    },
    checkCRM () {
      const isLoading = this.$loading.show()
      this.api.searchCrm({ state: this.registerData.crmState, number: this.registerData.crmNumber }).then(res => {
        const data = res.data

        this.crmName = data.nome
        this.crmChecked = (typeof data.nome !== 'undefined' && data.nome === this.registerData.name)
      }).catch(err => {
        const request = err.request

        if (request.status === 400) {
          this.crmChecked = false
        }
      }).finally(() => {
        isLoading.hide()
      })
    },
    searchCEP () {
      if (this.registerData.zipCode.length === 9) {
        cep(this.registerData.zipCode).then(res => {
          this.registerData.address = res.street
          this.registerData.neighborhood = res.neighborhood
          this.registerData.city = res.city
          this.registerData.state = res.state

          this.$refs.addressNumber.focus()
        }).catch(err => {
          console.log('Err', err)
        })
      }
    },
    acceptInvitation () {
      const inviteData = {
        invitationCode: this.$route.params.invitationCode,
        ...this.registerData
      }

      const isLoading = this.$loading.show()
      this.api
      .acceptInvite(inviteData)
      .then(res => {
        if (res.status === 200) {
          const resData = res.data

          if (typeof resData.accessToken !== 'undefined') {
            localStorage.setItem('access_token', resData.accessToken)
            localStorage.setItem('refresh_token', resData.refreshToken)
            localStorage.setItem('user', JSON.stringify(resData.user))
            localStorage.setItem('clinic', JSON.stringify({ value: resData.user.clinics[0].id, name: resData.user.clinics[0].name }))

            this.$router.push('/visao-geral')
          }
        }
      }).catch(err => {
        console.log('Err', err)
      }).finally(() => {
        isLoading.hide()
      })
    }
  }
}
</script>

<style lang="scss">
  .register {
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;

    .form-side {
      width: 100%;

      .tabs {
        width: 100%;
        height: 88px;
        justify-self: center;

        .tab {
          padding: 0 24px;
          font-size: 16px;
          font-weight: 600;
          color: var(--type-placeholder);
          border-bottom: 1px solid var(--neutral-200);
          display: flex;
          align-items: center;

          .icon {
            stroke: var(--states-success);
          }

          &:nth-child(2) {
            border-left: 1px solid var(--neutral-200);
            border-right: 1px solid var(--neutral-200);
          }

          &.active {
            position: relative;
            color: var(--type-active);

            &:before {
              width: 100%;
              height: 4px;
              background-color: var(--neutral-200);
              position: absolute;
              bottom: -1px;
              left: 0;
              content: ' ';
            }
          }

          &.confirmed {
            position: relative;
            color: var(--type-active);

            &:before {
              width: 100%;
              height: 4px;
              background-color: var(--states-success);
              position: absolute;
              bottom: -1px;
              left: 0;
              content: ' ';
            }

            .icon {
              width: 20px;
              height: 20px;
              right: 24px;
              position: absolute;
              content: '';
              stroke: var(--states-success);
              background-image: url(../assets/icons/check.svg);
            }
          }
        }
      }

      .logo-eyecare {
        width: 122px;
        margin-bottom: 80px;
      }

      .underline {
      text-decoration: underline;
    }

      .form {
        width: 70%;
        flex: auto;

        &.submitted {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .check-container {
          margin-bottom: 32px;
          display: flex;
          justify-content: center;

          .check {
            width: 96px;
            height: 96px;
            background-color: #DBFFF0;
            border: 16px solid #DBFFF0;
            border-radius: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;

            .container {
              width: 64px;
              height: 64px;
              border-radius: 16px;
              background-color: var(--states-success);
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .icon {
              width: 24px;
              height: 24px;
              stroke: var(--neutral-000);
            }
          }
        }

        .title {
          font-family: 'Red Hat Display';
          font-weight: 700;
          font-size: 32px;
          line-height: 40.32px;
          color: var(--dark-blue);
        }

        .description {
          width: 100%;
          font-size: 16px;
          color: var(--type-active);
          margin: 20px 0 48px 0;
        }

        .error-message-box {
          background-color: #FEE8E6;
          border-radius: 8px;
          padding: 16px 26px;
          display: flex;
          justify-content: center;
          font-size: 14px;
          font-weight: 600;
          margin: -38px 0 48px 0;

          .icon {
            width: 24px;
            height: 24px;
            margin-right: 25px;
            stroke: var(--states-error);
          }
        }

        button {
          font-size: 18px;
          font-weight: 600;
          color: var(--neutral-000);
          padding: 16px;
          border-radius: 8px;
          line-height: 24px;
        }
      }
    }

    .carousel-side {
      .logo-eyecare {
        width: 82px;
        position: absolute;
        bottom: 80px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .VueCarousel {
      .VueCarousel-wrapper {
        height: 100%;

        .VueCarousel-inner {
          height: 100% !important;

          .VueCarousel-slide {
            background-size: cover;

            .container {
              position: absolute;
              bottom: 210px;
              padding: 0 125px;
              display: flex;
              flex-direction: column;
              align-items: center;

              .title {
                font-family: 'Red Hat Display';
                font-weight: 700;
                font-size: 24px;
                line-height: 30.24px;
                color: var(--neutral-000);
              }

              .text {
                width: 370px;
                font-weight: 400;
                font-size: 18px;
                line-height: 24.84px;
                color: var(--neutral-100);
              }
            }
          }
        }
      }

      .VueCarousel-pagination {
        position: absolute;
        bottom: 160px;

        .VueCarousel-dot {
          transition: all 500ms;

          &:focus {
            outline: none;
          }
        }

        .VueCarousel-dot--active {
          width: 16px !important;
          border-radius: 16px !important;
        }
      }
    }

    .link {
      margin-top: 24px !important;
      display: flex;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
        stroke: var(--blue-500);
      }
    }

    @media (max-width: 768px) {
      .tabs {
        height: unset;
      }

      .form-side {
        .logo-eyecare {
          margin-bottom: 32px;
        }

        .form {
          button {
            margin: 16px 0 32px;
          }

          .description {
            margin: 20px 0 32px;
          }
        }

        .submitted {
          padding-top: 48px;  
        }

        .icon {
          right: 0;
          bottom: 8px;
        }
      }
    }
  }
</style>
