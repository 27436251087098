
import moment from 'moment'

const hasClinicConflict = (state) => (days, start, end) => {

    if (!days?.length || !moment(days[0]).isValid() || !moment(start, 'HH:mm').isValid() || !moment(end, 'HH:mm').isValid() ) return ''

    const date = moment(days[0])
    const target = moment(days[1])

    while (date.isSameOrBefore(target)) {
        const dayKey = Object.keys(state.clinicDays).find(key => state.clinicDays[key].day === parseWeekDay(date.weekday()) && state.clinicDays[key].status)
        if (!dayKey) {
            return `A clínica não funciona nesse dia ${date.format('DD/MM/YYYY')} (${date.format('dddd').toUpperCase()})`
        }

        const interval = state.clinicDays[dayKey].schedule_config_day_intervals[0]
        const intervalStart = moment(interval.start_time, 'HH:mm')
        const intervalEnd = moment(interval.end_time, 'HH:mm')

        if (!moment(start, 'HH:mm').isBetween(intervalStart.clone().subtract(1, 'minutes'), intervalEnd.clone().add(1, 'minutes')) || !moment(end, 'HH:mm').isBetween(intervalStart.clone().subtract(1, 'minutes'), intervalEnd.clone().add(1, 'minutes'))) {
            return `O horário escolhido não está dentro do intervalo de funcionamento da clínica (${date.format('ddd').toUpperCase()} - ${intervalStart.format('HH:mm')} ~ ${intervalEnd.format('HH:mm')})`
        }    

        date.add(1, 'days')
    }

    return ''
}


const parseWeekDay = weekday => {
    const weekdays = {
        0: 'DOMINGO',
        1: 'SEGUNDA-FEIRA',
        2: 'TERCA-FEIRA',
        3: 'QUARTA-FEIRA',
        4: 'QUINTA-FEIRA',
        5: 'SEXTA-FEIRA',
        6: 'SABADO'
    }
    return weekdays[weekday];
}


export default {
    hasClinicConflict
}
