<template>
  <div class="video-content-container">
    <div class="header">
      <div class="title">Aula {{ videoClass.position >= 0 ? videoClass.position + 1 : '' }} | {{ videoClass.name }}</div>
      <div class="actions">
        <div class="option" @click="goTo('previous')">
          <v-video-prev />
          Anterior
        </div>
        <div class="option" @click="goTo('next')">
          Próximo
          <v-video-next />
        </div>
      </div>
    </div>
    <div class="content">
      <v-video-player id="video" :videoClass="videoClass" />
      <div v-if="course && course.expiration_date" class="timer">
        <v-academy-timer :expirationDate="course.expiration_date" />
      </div>
      <v-video-infos :videoClass="videoClass" />
      <v-comments :videoClass="videoClass" :user="user" />
    </div>
  </div>
</template>
<script>
import VideoPlayer from '@/components/Academy/VideoPlayer'
import AcademyTimer from '@/components/Academy/AcademyTimer'
import VideoInfos from '@/components/Academy/VideoInfos'
import Comments from '@/components/Academy/Comments'
import VideoPrev from '@/assets/icons/video-prev.svg'
import VideoNext from '@/assets/icons/video-next.svg'

export default {
  props: {
    user: Object,
    course: Object,
    goTo: Function,
  },
  components: {
    'v-video-player': VideoPlayer,
    'v-academy-timer': AcademyTimer,
    'v-video-infos': VideoInfos,
    'v-comments': Comments,
    'v-video-prev':  VideoPrev,
    'v-video-next':  VideoNext,
  },
  data() {
    return {
      videoClass: {},
    }
  },
  watch: {
    course: {
      handler(val) {
        const module = val.modules.find(m => m.video_classes.some(v => v.selected))
        this.videoClass = module.video_classes.find(v => v.selected)
      },
      deep: true,
    }
  },
}
</script>
<style lang="scss" scoped>
  .video-content-container {
    width: 75%;
    border-radius: 8px;
    background: white;
    min-height: 100vh;
    .header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--neutral-200);

      .title {
        font-weight: 700;
        font-size: 18px;
        padding: 20px;
        margin-bottom: 0;
      }

      .actions {
        display: flex;

        .option {
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid var(--neutral-200);
          padding: 20px;
          gap: 10px;
          font-family: 'Red Hat Display';
          font-weight: 700;
          font-size: 16px;
          color: var(--dark-blue);
          cursor: pointer;
        }
      }
    }
    .content {
      padding: 0 24px 24px 24px;
      .timer {
        width: 100%;
        display: inline-flex;
        justify-content: flex-end;
        margin-bottom: -30px;
      }
    }
  }
</style>
