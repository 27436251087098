import { render, staticRenderFns } from "./CourseDashboard.vue?vue&type=template&id=c59b5666&scoped=true"
import script from "./CourseDashboard.vue?vue&type=script&lang=js"
export * from "./CourseDashboard.vue?vue&type=script&lang=js"
import style0 from "./CourseDashboard.vue?vue&type=style&index=0&id=c59b5666&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c59b5666",
  null
  
)

export default component.exports