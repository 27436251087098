<template>
  <b-modal
    v-if="form"
    id="department-modal"
    title="Adicionar novo departamento"
    hide-footer
    centered size="lg"
    @show="onShow"
  >
    <section class="body">
      <div class="row">
        <div class="col-12">
          <label for="name" class="title-styles">Nome do departamento</label>
          <b-form-input 
            id="name" 
            autocomplete="off"
            v-model="form.name"
            placeholder="Ex.: Recepção"
            
          ></b-form-input>
          <div
            v-if="validated && !form.name"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label for="prefix" class="title-styles">
            Prefixo
            <span class="help" id="help">(opcional)</span>
          </label>
          <b-form-input 
            id="prefix" 
            autocomplete="off"
            placeholder="Ex.: REC"
            v-model="form.prefix"
          ></b-form-input>
        </div>
        <div class="col-4">
          <label for="metodo-chamada" class="title-styles">
            Tipo de atendimento
          </label>
          <multiselect
            class="metodo-chamada with-border"
            track-by="value"
            label="name"
            v-model="form.order"
            :searchable="false"
            :showLabels="false" 
            :allow-empty="false" 
            placeholder="Selecionar"
            :options="orderTypes"
          >
            <template slot="caret">
              <div class="chevron">
                <v-chevron-down />
              </div>
            </template>
          </multiselect>
          <div
            v-if="validated && !form.order.value"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
        </div>

        <div class="col-4 checkbox-container">
          <b-form-checkbox
            id="mostrar-totem"
            name="mostrar-totem"
            v-model="form.is_public"
          >
            Mostrar no totem
          </b-form-checkbox>
        </div>
      </div>

      <div class="row listener" 
        v-for="(listener, index) in form.listeners" 
        :key="index"
        
      >
        <div class="col-11">
          <label for="listener" class="title-styles">
            Setor dentro deste departamento
            <span class="help" id="help">(opcional)</span>
          </label>
          <b-form-input 
            id="listener" 
            v-model="listener.name"
            :placeholder="`Ex.: Guichê ${index + 1}`"
          ></b-form-input>
        </div>
        <div class="col-1" v-if="!listener.toDelete">
          <button 
            class="btn"
            @click="deleteListener(index)"
            :disabled="form.listeners.length === 1"
          >
            <Trash 
            class="trash"
              :class="{ 
                'disabled-icon':form.listeners.length === 1, 
                'active':form.listeners.length > 1 
              }" 
            />
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-12 listener-btn-container">
          <b-button
            variant="primary-outline"
            @click="addListener"
          ><Plus class="icon"/>Adicionar novo setor</b-button>
        </div>
      </div>

    </section>

    <section class="modal-footer">
      <b-button variant="outline-danger" @click="closeModal">Cancelar</b-button>
      <b-button 
        variant="primary" 
        @click="save"
      >Salvar departamento
      </b-button>
    </section>
  </b-modal>
</template>

<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Plus from '@/assets/icons/plus.svg'
import Trash from '@/assets/icons/trash.svg'

export default {
  components: {
    Plus, Trash,
    'v-chevron-down': ChevronDown,
  },
  props: {
    department: Object,
  },
  data(){
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      form: null,
      validated: false,

      orderTypes: [
        { name: 'Agendamento', value: 'appointment' }, 
        { name: 'Chegada', value: 'arrival' }
      ],
    }
  },
  mounted(){
    this.form = this.getDefaultForm()
  },
  methods: {
    onShow() {
      this.validated = false
    },
    closeModal() {
      this.$bvModal.hide('department-modal');
      this.form = {}
    },
    getDefaultForm(){
      return {
        id: null,
        clinic_id: this.clinic.id,
        name: null,
        prefix: null,
        active: true,
        order: { name: 'Selecionar', value: '' },
        is_public: false,
        listeners: [
          {
            id: null, 
            name: null
          }
        ]
      }
    },
    addListener(){
      this.form.listeners.push({
        id: null, 
        name: null
      })
    },
    deleteListener(index){
      if( this.form.id ){
        if(!this.form.listenersToDelete){
          this.form.listenersToDelete = []
        }
        this.form.listenersToDelete.push(this.form.listeners[index])
      }
        this.form.listeners.splice(index, 1)
    },
    async save () {
      const loading = this.$loading.show()
      this.validated = true

      if (!this.form.name || !this.form.order.value) {
        this.$toast.warning('Preenchar os campos obrigatórios')
        loading.hide()

        return
      }

      try {
        this.form.order = this.form.order.value
        if (!this.form.id) {
          await this.api.createCallPanelDepartment(this.form)
          this.$emit('update');
          this.$toast.success('Departamento salvo com sucesso!')
        }
        else {
          await this.api.updateCallPanelDepartment(this.form.id, this.form)
          this.$emit('update')
          this.$toast.success('Alterações salvas com sucesso!')
        }

        this.closeModal()

      } catch (err){
        loading.hide()
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }

      this.form = this.getDefaultForm()
    }
  },
  watch: {
    department: function(value){
      if( value ){
        if( value.order === 'appointment' )
          value.order = { name: 'Agendamento', value: 'appointment' }
        else if( value.order === 'arrival' )
          value.order = { name: 'Chegada', value: 'arrival' }
          this.form = value
      } else {
        this.form = this.getDefaultForm()
      }
    }
  }

}
</script>

<style lang="scss">
#department-modal {
  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .row {
      margin: 10px 0;
    }

    .metodo-chamada {
      .multiselect__single {
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: var(--type-active);
      }
    }

    .error-message {
      font-size: 14px;
      margin-top: 4px;
      color: var(--states-error);
    }

    .help {
      font-size: 12px;
      color: var(--type-placeholder);
      font-weight: 600;
    }
  }

  .checkbox-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .listener {
    margin-bottom: 15px;
    div:nth-child(2){
      display: flex;
      flex-direction: column;
      justify-content: end;
      .trash {
        width: 25px;
      }

      .active {
        stroke: var(--states-red-soft);
      }

      .disabled-icon {
        stroke: var(--neutral-300);
      }

      button:hover {
        .active {
          stroke: var(--states-error);
        }
      }
    }
  }

  .listener-btn-container {
    display: flex;
    justify-content: center;
    button {
      font-weight: 700;
    }
    
    .icon {
      width: 20px;
      fill: var(--blue-500);
      margin-right: 10px;
    }
  }

  .modal-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
  }
}
</style>
