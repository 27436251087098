import Vue from 'vue'
import Vuex from 'vuex'

import mutations from './mutations'
import getters from './getters'
import actions from './actions'

Vue.use(Vuex)
const store = {
  namespaced: true,
  state: () => ({
    historyItems: [],
    loading: false,
    page: 1,
    count: 0,
    limit: 0
  }),
  mutations,
  getters,
  actions,
}

export default store
