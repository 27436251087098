
export const parseName = (name) => {
  const parts = name.split(' ');
  if (parts.length < 4) return name

  const middle = parts.slice(1, parts.length - 1)
  function normalizeMiddle() {
    return middle.map(el => el.length > 3 ? `${el[0]}.` : el).join(' ')
  }
  return `${parts[0]} ${normalizeMiddle()} ${parts[parts.length - 1]}`
}
