<template>
  <div class="number-card">
    <div class="card-number">{{ number }}{{ suffix }}</div>
    <div class="card-title">{{ title }}</div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    number: { type: Number, default: 0 },
    suffix: String,
  }
}
</script>
<style lang="scss" scoped>
.number-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--neutral-200);
  margin-bottom: 16px;
}
.card-number {
  font-size: 3rem;
  font-weight: 700;
}
.card-title {

}
</style>
