import Index from './views/rules/Index.vue'
import Definitions from './views/rules/Definitions.vue'
import Settings from './views/rules/Settings.vue'
import Specifications from './views/rules/Specifications.vue'

const moduleRoute = {
    path: '/repasse_medico',
    component: () => import('@/views/Home.vue'),
    children: [
        { 
            path: '', 
            component: () => import('./views/Home.vue'),
            children: [
                { path: 'relatorio_de_repasse', component: () => import('./views/Report.vue') },
                { path: 'extratos', component: () => import('./views/Extracts.vue') },
                { path: 'perfis', component: () => import('./views/Profiles.vue') },
                { path: 'regras_de_repasse', component: () => import('./views/Rules.vue') },              
                { path: 'configuracoes', component: () => import('./views/Configuration.vue') },
                { path: 'regra_repasse/:id/:tab', component: () => import('./views/rules/Index.vue') },
            ]
        }
    ],
    
}

const moduleRouteRules = {
        path: '/regras_repasse', 
        component: () => import('@/views/Home.vue'),
        children: [
            { 
                path: '', 
                component: Index,
                children: [
                    { path: ':id/definicoes',  component: Definitions},
                    { path: ':id/configuracoes',  component: Settings },
                    { path: ':id/especificacoes', component: Specifications }
                ]
            }
        ]
}

export default router => { 
    router.addRoute(moduleRoute)
    router.addRoute(moduleRouteRules)
}