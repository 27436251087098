<!--- View de Chat - Responsável por fazer a comunicação via Websocket com o backend do glauco.

Alterado pelos desenvolvedores:
Richard Moraes
Carlos Oliveira
--->

<template>
  <div class="chat">
    <div class="expandedImage" v-if="openedImage == true" @click="bigImage = []; openedImage = false">
      <img class="openedImage" :src="bigImage"/>
    </div>
    <p class="title">Chat</p>

    <div class="chat-container">
      <div class="sidebar">
        <div class="search-bar">
          <div class="position-relative">
            <input autocomplete="off" type="text" class="form-control" placeholder="Pesquisar">
            <Search class="icon" />
          </div>
        </div>
        <perfect-scrollbar class="messages">
              <div class="message"
              v-for="chat of chats"
              :key="chat.id"
              >                <div class="d-flex flex-row align-items-center"
                @click="renderChat(chat.id); patientName = chat.patient_name;
                patientPic = chat.patient_pic;
                chatId = chat.id;
                chatToken = chat.chatToken;
                startWebSocket()"
              >
                <div>
                  <Avatar 
                    size="40"
                    class="mr-3"
                    :src="chat?.patient_pic"
                  />
                </div>
                  <div class="text-container">
                    <p class="name">{{chat.patient_name}}</p>
                    <p class="last-message" :id="chat.id"
                    v-if="chat.messages[0] != undefined"
                    >{{chat.messages[0].message['content']}}</p>
                    <p class="last-message" :id="chat.id"
                    v-else></p>
                  </div>
                </div>
                <div class="right-info" v-if="chat.messages[0] != undefined">
                  <span class="time">{{new Date(chat.messages[0].status.sended_at).toLocaleDateString()}}</span>
                  <span class="tag" v-if="chat.messages[0].from['id'] != doctorId
                  && chat.messages[0].status['viewed_at'] == undefined">Não lida</span>
                </div>
              </div>
        </perfect-scrollbar>
      </div>

      <div class="conversation">
        <div class="header">
          <div>
            <Avatar 
              size="40"
              class="mr-3"
              :src="patientPic"
            />
            <span class="name">{{patientName}}</span>
          </div>
        </div>

        <perfect-scrollbar id="conversationBox" class="body flex-fill conversationBox" style="height: 500%;">
        <div v-for="messageMonth of monthOfMessages"
        :key="messageMonth.index">
            <div class="dateSeparator">
              <span>{{messageMonth.fullDate}}</span>
          </div>
          <div class="message"
            v-for="message of messages"
            :key="message.id+Math.random().toString(36).substring(7)">
            <div class="message-box" v-if="message.from['id'] != doctorId && new Date(message.status.sended_at).toLocaleDateString([], {month: '2-digit'}) == messageMonth.month">

              <span class="message" style="word-wrap: break-word;" v-if="message.message['contentType'].includes('application/pdf')">
                <Pdf class="icon" style="cursor: pointer" @click="openPdf(message.message['content'])"/>
                  <span class="time">{{new Date(message.status.sended_at).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}</span></span>

              <span class="message" style="word-wrap: break-word;" v-if="message.message['contentType'].includes('text')">
                {{message.message['content']}}
                  <span class="time">{{new Date(message.status.sended_at).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}</span></span>

              <span class="message" style="word-wrap: break-word;" v-if="message.message['contentType'].includes('image')">
                  <img class="messageImage" style="cursor: pointer" :src="message.message.content" @click="openImage(message.message.content)"/>
                <span class="time">{{new Date(message.status.sended_at).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}</span></span>

            </div>

            <div class="message-box from-me" style="text-align: right; word-wrap: break-word;" v-if="message.from['id'] == doctorId && new Date(message.status.sended_at).toLocaleDateString([], {month: '2-digit'}) == messageMonth.month">

              <span class="message" style="text-align: right; word-wrap: break-word;" v-if="message.message['contentType'].includes('application/pdf')">
                <Pdf class="icon" style="cursor: pointer" @click="openPdf(message.message['content'])"/>
                  <span class="time">{{new Date(message.status.sended_at).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}</span></span>

              <span class="message" style="text-align: right; word-wrap: break-word;" v-if="message.message['contentType'].includes('text')">
                {{message.message['content']}}
                  <span class="time">{{new Date(message.status.sended_at).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}</span></span>

              <span class="message" style="text-align: right; word-wrap: break-word;" v-if="message.message['contentType'].includes('image')">
                  <img class="messageImage" style="cursor: pointer" :src="message.message.content" @click="openImage(message.message.content)"/>
                <span class="time">{{new Date(message.status.sended_at).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}</span></span>
            </div>
          </div>
        </div>
        </perfect-scrollbar>

        <div :class="{ 'filesToUploadContainer': hasFilesToUpload }">
          <div class="filesToUpload"
          v-for="file of filesToUploadViewer" style="text-align: center;"
          :key="file">
            <img v-if="file.includes('image')" :src="file" />
            <Pdf v-else class="icon" style="margin-top: 30%;"/>
          </div>
        </div>
        <div class="footer">
          <span class="icon-box" :class="{ 'opened': attachOpened }">
            <Paperclip class="icon" @click="attachOpened = !attachOpened" />
            <div class="dropup">
              <label for="imageSelector" style="margin: 0px"><ImageIcon style="margin: 16px;" class="icon" /></label>
                <input type="file" id="imageSelector" ref="image" style="display: none;" multiple accept=".png, .jpg, .jpeg, .tiff" @change="onFileChange(item, $event); hasFilesToUpload = true; attachOpened = false">
              <label for="fileSelector" style="15px 0px 0px 0px"><File class="icon" /></label>
                <input type="file" id="fileSelector" ref="pdf" style="display: none;" multiple accept=".pdf" @change="onFileChange(item, $event); hasFilesToUpload = true; attachOpened = false">
            </div>
          </span>
          <div class="input-container flex-1">
            <input autocomplete="off" type="text" class="form-control" placeholder="Escrever mensagem"
            v-model="messageToSend" v-on:keyup.enter="sendMessage()">
            <span class="submit">Enter para enviar</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Ws from '@lukinco/adonis-websocket-client'

export default {
  name: 'ChatView',
  metaInfo: {
    title: 'Eyecare - Chat'
  },
  components: {
    Avatar: () => import('@/components/General/Avatar.vue'),
    File: () => import('@/assets/icons/file.svg'),
    ImageIcon: () => import('@/assets/icons/image.svg'),
    Paperclip: () => import('@/assets/icons/paperclip.svg'),
    Pdf: () => import('@/assets/icons/pdf.svg'),
    Search: () => import('@/assets/icons/search.svg'),
  },
  data () {
    return {
      attachOpened: false,
      doctorId: localStorage.getItem('doctorId'),
      user: JSON.parse(localStorage.getItem('user')),
      chats: [],
      chatId: [],
      chatToken: [],
      messages: [],
      patientName: '',
      patientPic: '',
      dateToday: new Date().toLocaleDateString(),
      dateSeparators: [],
      monthOfMessages: [],
      openedImage: false,
      bigImage: [],
      wsConnection: null,
      webSocket: [],
      currentChatWs: [],
      chatTmp: [],
      messageToSend: null,
      item: [],
      hasFilesToUpload: false,
      filesToUploadViewer: [],
      filesToUpload: []
    }
  },
  mounted () {
    const isLoading = this.$loading.show()

    this.api
    .getChats(this.doctorId, this.user.name)
      .then(res => {
        const resData = res.data

        this.chats = resData

      })
      .catch(err => {
        console.log('Err', err)
      })
      .finally(() => {
        isLoading.hide()
      })
  },
  methods: {
    async createDateSeparator(date){
      await this.dateSeparators.push(date)
      return date;
    },
    onFileChange(item, event) {
      var files = event.target.files || event.dataTransfer.files;
      console.log(files)
      if (!files.length)
        return;

      for(var f of files){
      this.createFile(item, f);
      }
    },
    createFile(item, file) {
      var reader = new FileReader();

      reader.onload = (event) => {
        this.filesToUploadViewer.push(event.target.result);
        this.filesToUpload.push(file)
      };

      reader.readAsDataURL(file);
    },
    removeImage: function (item) {
      item.image = false;
    },
    renderChat(chatId){
      this.monthOfMessages =  []
      this.messages = []
      const isLoading = this.$loading.show()
      this.api
      .getMessages(chatId)
      .then(async res=> {
        this.messages = res.data

        for(var message of this.messages){
          if (message.message.contentType !== 'text' ){
              const retrieveFile = await this.api
              .getFileGlauco(this.chatId, this.doctorId, message.message.contentType, message.message.content)
              message.message.content = retrieveFile.data
          }

          const messageMonth = new Date(message.status.sended_at).toLocaleDateString([], {month: '2-digit'})
          const findItem = this.monthOfMessages.find(element => element.month === messageMonth)

            if(!findItem){
              this.monthOfMessages.push({month: messageMonth, fullDate: new Date(message.status.sended_at).toLocaleDateString()})
            }
        }
      })
      .catch(err => {
        console.log('Err', err)
      })
      .finally(() => {
        isLoading.hide()
        var conversationBox = document.getElementById('conversationBox')
        conversationBox.scrollTop = conversationBox.scrollHeight
      })
    },
    async startWebSocket() {
      // try {
      //   const webSocket = Ws('wss://glauco.eyecarehealth.com.br');
      //   webSocket.connect();

      //   webSocket.on('open', () => {
      //     this.wsConnection = true;
      //     this.webSocket = webSocket;
      //     this.currentChatWs = this.webSocket.subscribe('chat:' + this.chatId)

      //     this.currentChatWs.on('error', () => {
      //       this.wsConnection = false;
      //     });

      //     this.currentChatWs.on('new-message', async (messageData) => {
      //       await this.messages.push(messageData);

      //       if (messageData.message.contentType !== 'text' ){
      //         const retrieveFile = await this.api
      //         .getFileGlauco(this.chatId, this.doctorId, messageData.message.contentType, messageData.message.content)
      //         messageData.message.content = retrieveFile.data
      //       }
      //       document.getElementById(this.chatId).innerText = messageData.message.content

      //       var conversationBox = document.getElementById('conversationBox')
      //       conversationBox.scrollTop = conversationBox.scrollHeight
      //     });
      //   });

      //   webSocket.on('error', () => {
      //     this.currentChatWs = false;
      //   });
      // } catch(ex) {
      //   console.log('Failed startWebSocket ', ex);
      // }

    },
    sendMessage() {

      if(this.messageToSend != null){
        this.currentChatWs.emit('message', {
          chatId: this.chatId,
          chatToken: this.chatToken,
          message: {
            contentType: 'text',
            content: this.messageToSend,
          },
        });
        this.messageToSend = null;
      }
        this.sendFile()
      },
      async sendFile(){
        if(this.filesToUpload.length > 0){
          for (const file of this.filesToUpload) {
          const dataForm = new FormData();
          dataForm.append(`file`, file);

            this.api
            .uploadFile(this.chatId, dataForm)
            .then(async res => {
              this.currentChatWs.emit('message', {
                chatId: this.chatId,
                chatToken: this.chatToken,
                message: {
                  contentType: res.data.contentType,
                  content: res.data.fileName,
                }
              })

            })
          }
        }

        this.filesToUploadViewer = []
        this.filesToUpload = []
        this.hasFilesToUpload = false
        document.getElementById('fileSelector').value = "";
        document.getElementById('imageSelector').value = "";
      },
      openImage(image){
        this.bigImage = image
        document.getElementsByClassName('openedImage').src=image
        this.openedImage = true
      },
      openPdf(content){
        const pdfWindow = window.open()
        pdfWindow.document.write(
            "<style>body {margin: 0px;} iframe {border: 0px; width: 100%; height: 100%}</style><iframe src='"+content+"'></iframe>"
        )
      }
    }
}
</script>

<style lang="scss" scoped>
  .chat {
    height: 100%;
    padding: 30px 24px;
    text-align: left;

    .expandedImage {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1002;
    background-color: rgba(6,15,45,0.95);
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;

      .openedImage {
        max-height: 80%;
        z-index: 1003;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        top: 10%;
        cursor: default;
      }
    }


    .title {
      font-family: 'Red Hat Display';
      font-weight: 700;
      font-size: 24px;
      color: var(--dark-blue);
      margin-bottom: 27px;
    }

    .chat-container {
      width: 100%;
      height: calc(100% - 60px);
      background-color: var(--neutral-000);
      border-radius: 8px;
      display: flex;
      flex-direction: row;

      .sidebar {
        width: 350px;
        border-right: 1px solid var(--neutral-200);
        display: flex;
        flex-direction: column;

        .search-bar {
          height: 80px;
          padding: 16px;
          border-bottom: 1px solid var(--neutral-200);
          position: relative;

          .icon {
            width: 24px;
            height: 24px;
            stroke: var(--neutral-500);
            position: absolute;
            top: 12px;
            left: 16px;
          }

          .form-control {
            padding: 11px 16px 11px 48px;
            position: relative;
          }
        }

        .box {
          width: 100%;
          height: 100%;
          overflow: hidden;
        }

        .conversationBox {
          height: 70%;
        }

        .messages {
          height: 100%;
          width: 350px;

          .message {
            height: 100px;
            padding: 24px 75px 24px 16px;
            border-bottom: 1px solid var(--neutral-200);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            cursor: pointer;

            &.active {
              border-left: 4px solid var(--orange);
            }

            .text-container {
              width: 194px;

              .name {
                font-weight: 600;
                font-size: 16px;
                color: var(--type-active);
                margin-bottom: 4px;
              }

              .last-message {
                font-weight: 400;
                font-size: 16px;
                color: var(--type-placeholder);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 0;
              }
            }

            .right-info {
              position: absolute;
              top: 28px;
              right: 16px;
              display: flex;
              flex-direction: column;
              text-align: right;

              .time {
                font-weight: 600;
                font-size: 12px;
                color: var(--type-placeholder);
                margin-bottom: 8px;
              }

              .tag {
                font-weight: 700;
                font-size: 11px;
                color: var(--dark-blue);
                background-color: #FFF4CC;
                border-radius: 100px;
                padding: 2px 8px;
              }
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }

      .conversation {
        flex: 1 1;
        background-color: #E8ECF7;
        display: flex;
        flex-direction: column;
        min-height: 70%;

        .header {
          min-height: 80px !important;
          background-color: var(--neutral-000);
          border-bottom: 1px solid var(--neutral-200);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 32px;

          .name {
            font-weight: 600;
            font-size: 18px;
          }

          .medical-record {
            font-weight: 600;
            font-size: 16px;
            color: var(--type-placeholder);
            display: flex;
            align-items: center;

            .number {
              margin-left: 5px;
              color: var(--blue-500);
            }

            .icon {
              width: 24px;
              height: 24px;
              background-color: var(--blue-100);
              border-radius: 8px;
              display: inline-block;
              position: relative;
              margin-left: 8px;

              svg {
                width: 16px;
                height: 16px;
                stroke: var(--blue-500);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }

        .body {
          padding: 32px;
          display: flex;
          flex-direction: column;

          .message-box {
            display: flex;
            flex-direction: row;

            .message {
              max-width: 300px;
              background-color: var(--neutral-000);
              border-radius: 0 8px 8px 8px;
              padding: 16px;
              margin-bottom: 24px;
              font-weight: 400;
              font-size: 16px;
              color: var(--type-active);
              position: relative;

              .time {
                display: block;
                margin-top: 5px;
                bottom: 4px;
                right: 8px;
                font-weight: 600;
                font-size: 12px;
                color: var(--type-placeholder);
              }

              .messageFile {
                max-width:50%;
                max-height:100%;
                width: auto;
                height: auto;
              }

              .messageImage{
                max-height: 200px;
                max-width: 250px;
                left: 0px;
                top: 0px;
                border-radius: 9px;

                img {
                  height: 90%;
                  width: 90%;
                  border-radius: 8px;
                }
              }
            }

            &.from-me {
              flex-direction: row-reverse;

              .message {
                color: var(--neutral-000);
                border-radius: 8px 0 8px 8px;
                background-color: var(--blue-500);

                .time {
                  color: var(--neutral-000);
                  opacity: 0.6;
                  margin-top: 5px;
                }
              }
            }
          }
              .dateSeparator {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 4px 16px;
                margin: 0px auto 30px auto;

                width: 115px;
                height: 24px;

                background: #D9DFF2;
                border-radius: 100px;


                span {
                  position: static;
                  width: 74px;
                  height: 16px;
                  left: 16px;
                  top: 4px;

                  /* Text / Extra Small */

                  font-family: Nunito Sans;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 130%;
                  /* identical to box height, or 16px */


                  /* Neutral / 700 */

                  color: #304388;


                  /* Inside Auto Layout */

                  flex: none;
                  order: 0;
                  flex-grow: 0;
                  margin: 0px 10px;
                }
              }
        }

        .filesToUploadContainer {
          min-height: 144px;
          width: 100%;
          left: 446px;
          top: 628px;
          border-radius: 0px;
          padding: 16px 16px;
          background-color: var(--neutral-200);
          display: flex;
          overflow: hidden;

          .filesToUpload{
            height: 112px;
            width: 112px;
            left: 0px;
            top: 0px;

            img {
              height: 90%;
              width: 90%;
              border-radius: 8px;
            }
          }
        }

        .footer {
          width: 100%;
          height: 100%;
          padding: 0 16px;
          background-color: var(--neutral-200);
          display: flex;
          align-items: center;

          .icon-box {
            width: 56px;
            height: 56px;
            position: relative;
            margin-right: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 78px;
            transition: all 500ms;

            .icon {
              width: 24px;
              stroke: var(--type-placeholder);
            }

            &:hover {
              background-color: var(--neutral-300);
            }

            .dropup {
              width: 56px;
              max-height: 0;
              overflow: hidden;
              background-color: var(--neutral-000);
              box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
              border-radius: 8px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position: absolute;
              bottom: 64px;
              transition: all 100ms;

              .icon {
                stroke: var(--neutral-500);
                cursor: pointer;
                margin: 16px;

                &:last-child {
                  margin: 0 16px 16px 16px;
                }
              }
            }

            &.opened {
              background-color: var(--neutral-300);

              .dropup {
                max-height: 100px;
              }
            }
          }

          .input-container {
            position: relative;

            .submit {
              font-weight: 400;
              font-size: 12px;
              color: var(--type-placeholder);
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 16px;
            }
          }
        }
      }

    }
  }
</style>
