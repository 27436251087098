<template>
  <div class="div-table">
    <b-table-simple class="p-table" fixed>
      <thead>
        <tr>
          <td v-if="modal" class="td-header">
            <label class="checkbox">
              <input
                type="checkbox"
                :value="selected"
                v-model="selected"
                @change="changeSelected"
              />
              <span class="checkmark">
                <v-check />
              </span>
            </label>
          </td>
          <td class="td-header" width="5%" v-if="this.makeDefault()">
            <div v-if="protocol.type !== 'padrão'">
              <input
                type="radio"
                name=""
                class="radio_default"
                :id="`radio_${protocol.id}`"
                @click="updateDefault"
              />
              <b-tooltip :target="`radio_${protocol.id}`" placement="bottom">
                Clique no ícone para transformar em um protocolo padrão
              </b-tooltip>
            </div>
            <div v-else>
              <v-checked
                :id="`checked_${protocol.id}`"
                @click="updateDefault"
              />
              <b-tooltip :target="`checked_${protocol.id}`" placement="bottom">
                Protocolo padrão {{ clinic.name }}, clique para desfazer
              </b-tooltip>
            </div>
          </td>
          <td v-else-if="protocol.pharmacist_name" class="td-header" width="5%">
            <v-recommended-star :id="`recommended_${protocol.id}`" />
            <b-tooltip
              :target="`recommended_${protocol.id}`"
              placement="bottom"
            >
              Protocolo recomendado
            </b-tooltip>
          </td>
          <td
            class="td-header"
            width="5%"
            v-else-if="protocol.type === 'privado'"
          >
            <v-user :id="`user_${protocol.id}`"  class="users-icon" />
            <b-tooltip :target="`user_${protocol.id}`" placement="bottom">
              Protocolo privado
            </b-tooltip>
          </td>
          <td
            class="td-header"
            width="5%"
            v-else-if="protocol.type === 'literatura_medica'"
          >
            <v-eyecare-logo-medical :id="`eyecare_${protocol.id}`" />
            <b-tooltip :target="`eyecare_${protocol.id}`" placement="bottom">
              Protocolo criado de acordo com a literatura médica
            </b-tooltip>
          </td>
          <td class="td-header" width="5%" v-else>
            <v-users :id="`users_${protocol.id}`" class="users-icon" />
            <b-tooltip :target="`users_${protocol.id}`" placement="bottom">
              Protocolo compartilhado
            </b-tooltip>
          </td>
          <td
            class="td-header td-protocol-name"
            width="45%"
            v-if="protocol.pharmacist_name"
          >
            {{ this.protocol.name }} <span class="vertical-bar">|</span>
            {{ this.protocol.pharmacist_name }}
          </td>
          <td v-else-if="protocol.type === 'literatura_medica'" width="50%" class="td-header td-protocol-name">
            {{ this.protocol.name }}
          </td>
          <td class="td-header td-protocol-name" width="50%" v-else>
            {{ this.protocol.name }} <span class="vertical-bar">|</span>
            {{ person.gender === 'M' ? 'Dr .' : 'Dra .' }}
            {{ this.protocol.person.name }}
          </td>
          <td class="td-header td-medicines" width="20%">
            {{
              this.protocol.medicines.length === 1
                ? `${this.protocol.medicines.length} Medicamento`
                : `${this.protocol.medicines.length} Medicamentos`
            }}
          </td>
          <td class="td-header text-center" width="10%">{{ this.via }}</td>
          <td class="td-header text-center" width="5%">
            <v-star
              :color="color"
              :stroke="border"
              @click.native="favoriteProtocol"
            />
          </td>
          <td class="td-header text-right" width="5%" v-if="!modal">
            <div
              class="more-with-menu"
              v-if="
                (protocol.person_id === person.id &&
                  !protocol.pharmacist_name &&
                  protocol.type !== 'literatura_medica') ||
                (protocol.pharmacist_name && $route.path === '/admin') ||
                (protocol.type === 'literatura_medica' &&
                  $route.path === '/admin')
              "
            >
              <v-more-vertical class="more-icon" />
              <div class="menu">
                <b-button variant="link" @click="editProtocol(protocol)"
                  >Editar</b-button
                >
                <b-button
                  variant="link remove mb-0"
                  @click="onDeleteClick(protocol)"
                  >Excluir</b-button
                >
              </div>
            </div>
          </td>
          <td class="td-header" v-else>
            <v-chevron-down
              class="chevron"
              @click="show = !show"
              :class="{ rotate: show }"
            />
          </td>
        </tr>
      </thead>
      <transition name="fade">
        <tbody v-if="show || !modal">
          <tr
            v-for="protocolMedicine in protocol.medicines"
            :key="protocolMedicine.id"
          >
            <td class="td-body" v-if="modal"></td>
            <td
              class="td-body text-center"
              v-if="
                protocolMedicine.time_of_use === 'Uso contínuo' ||
                !protocolMedicine
              "
            >
              <v-infinite></v-infinite>
            </td>

            <td v-else class="td-body tex-center">
              {{
                protocolMedicine.quantity ?
                  protocolMedicine.quantity
                    .split(/[^0-9]/g)
                    .filter(n => n !== '')[0]
                  : ''
              }}
            </td>
            <td
              class="td-body td-medicines"
              v-if="protocolMedicine.medicine_id"
            >
              <div>
                {{ protocolMedicine.medicine.product }}
                {{ protocolMedicine.medicine.company_name }} -
                {{ protocolMedicine.medicine.description }}
              </div>
              <div v-if="protocolMedicine.orientation" class="orientation">
                * {{ protocolMedicine.orientation }}
              </div>
              <div class="antimicrobial" v-if="protocolMedicine.antimicrobial || parseInt(protocolMedicine.medicine.antimicrobiano)">
                Antimicrobiano
              </div>
            </td>
            <td class="td-body td-medicines" v-else>
              <div>
                {{ protocolMedicine.medicine_name || protocolMedicine.medicine }}
              </div>
              <div v-if="protocolMedicine.orientation" class="orientation">
                * {{ protocolMedicine.orientation }}
              </div>
              <div class="antimicrobial" v-if="protocolMedicine.antimicrobial">
                Antimicrobiano
              </div>
            </td>
            <td class="td-body">
              <span v-if="protocolMedicine.dose === 'Ocular'"
                >Pingar {{ protocolMedicine.dose }}</span
              >
              <span v-else-if="protocolMedicine.dose == 'Oral'">{{
                protocolMedicine.dose
              }}</span>
              <span v-else>Aplicar {{ protocolMedicine.dose }}</span>
              <span v-if="protocolMedicine.intervals.includes('De')">
                a cada
                {{
                  protocolMedicine.intervals
                    .split(/[^0-9]/g)
                    .filter(n => n !== '')[0]
                }}
                {{
                  protocolMedicine.intervals.split(' ')[
                    protocolMedicine.intervals.split(' ').length - 1
                  ]
                }}
              </span>
              <span v-else>
                {{ protocolMedicine.intervals }}
              </span>
              <span v-if="protocolMedicine.time_of_use">
                <span v-if="protocolMedicine.time_of_use === 'Uso contínuo'">{{
                  protocolMedicine.time_of_use
                }}</span>
                <span v-else>durante {{ protocolMedicine.time_of_use }}</span>
              </span>
            </td>
            <td class="td-body td-via">
              {{ protocolMedicine.via }}
            </td>
            <td class="td-body"></td>
            <td class="td-body"></td>
          </tr>
        </tbody>
      </transition>
    </b-table-simple>
  </div>
</template>

<script>
import User from '@/assets/icons/user.svg'
import Checked from '@/assets/icons/checked.svg'
import Check from '@/assets/icons/check.svg'
import Users from '@/assets/icons/users.svg'
import infinite from '@/assets/icons/infinite.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import StarSVG from './StarSVG.vue'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import EyecareLogoMedical from '@/assets/icons/eyecare-logo-medical.svg'
import RecommendedStar from '@/assets/icons/recommended-star.svg'
import { EventBus } from '@/utils/eventBus'

export default {
  name: 'ProtocolTable',
  components: {
    'v-user': User,
    'v-checked': Checked,
    'v-check': Check,
    'v-users': Users,
    'v-infinite': infinite,
    'v-more-vertical': MoreVertical,
    'v-star': StarSVG,
    'v-eyecare-logo-medical': EyecareLogoMedical,
    'v-recommended-star': RecommendedStar,
    'v-chevron-down': ChevronDown
  },
  data() {
    return {
      via: null,
      color: null,
      border: null,
      favorite: null,
      selected: this.selectedDefault,
      person: JSON.parse(localStorage.getItem('user')),
      clinic: JSON.parse(localStorage.getItem('clinic')),
      role: null,
      show: false,
      click: false
    }
  },
  props: {
    protocol: Object,
    modal: Boolean,
    selectedDefault: Boolean,
    selectProtocols: Array
  },
  mounted() {
    this.verifyVias()
    this.role = this.person.clinics[0].role
    if (this.protocol.favorite.length) {
      const favorite = this.protocol.favorite[0]
      if (favorite.favorite) {
        this.color = '#FFCC18'
        this.border = '#FF6B00'
        this.favorite = true
      } else {
        this.favorite = false
        this.color = 'white'
        this.border = '#A4B1DF'
      }
    } else {
      this.favorite = false
      this.color = 'white'
      this.border = '#A4B1DF'
    }
  },
  methods: {
    verifyVias() {
      const vias = []
      this.protocol.medicines.forEach(protocolMedicine => {
        if (protocolMedicine.via === 'Oral') {
          vias.push('oral')
        } else if (protocolMedicine.via === 'Ocular') {
          vias.push('ocular')
        } else if (protocolMedicine.via === 'Uso tópico') {
          vias.push('uso tópico')
        } else if (protocolMedicine.via === 'Sublingual') {
          vias.push('sublingual')
        }
      })

      const filteredArray = vias.filter(function (ele, pos) {
        return vias.indexOf(ele) === pos
      })

      this.via = `Via ${filteredArray.toString()}`
    },
    editProtocol(protocol) {
      EventBus.$emit('editProtocol', protocol)
      this.$bvModal.show('protocol-modal')
    },
    onDeleteClick(protocol) {
      this.$bvModal
        .msgBoxConfirm(
          `Você tem certeza que deseja excluir ${protocol.name}? Não será possível reverter essa ação.`,
          {
            title: 'Excluir protocolo',
            size: 'md',
            okTitle: 'Confirmar',
            cancelTitle: 'Cancelar',
            cancelVariant: 'danger',
            centered: true
          }
        )
        .then(confirm => {
          confirm && this.onDelete(protocol)
        })
    },
    async onDelete(protocol) {
      try {
        await this.api.deleteProtocol(protocol.id)
        this.$emit('update-protocols')
        this.$toast.success('Protocolo removido com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async favoriteProtocol() {
      try {
        const data = {
          protocol_id: this.protocol.id,
          favorite: this.favorite,
          person_id: this.person.id
        }
        if (this.protocol.favorite.length) {
          if (this.favorite) {
            data.favorite = false
            this.color = 'white'
            this.border = '#A4B1DF'
          } else {
            this.border = '#FF6B00'
            this.color = '#FFCC18'
            data.favorite = true
          }
          const response = await this.api.updateFavorite(
            this.protocol.favorite[0].id,
            data
          )
          this.favorite = data.favorite
          if (response.data.favorite) {
            this.$toast.success('Protocolo favoritado com sucesso!')
          } else {
            this.$toast.success('Protocolo removido dos favoritos com sucesso!')
          }
        } else {
          data.favorite = true
          await this.api.createFavorite(data)
          this.favorite = true
          this.border = '#FF6B00'
          this.color = '#FFCC18'
          this.$toast.success('Protocolo favoritado com sucesso!')
        }
        this.$emit('update-protocols')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    makeDefault() {
      return (
        this.role === 'DOCTOR_MANAGER' &&
        this.protocol.type !== 'literatura_medica' &&
        !this.protocol.pharmacist_name &&
        this.protocol.type !== 'privado' &&
        (window.location.pathname === '/protocolos/protocolos_compartilhados' ||
          window.location.pathname === '/protocolos/protocolos_clinica')
      )
    },
    async updateDefault() {
      const type =
        this.protocol.type === 'compartilhado' ? 'padrão' : 'compartilhado'

      const data = {
        name: this.protocol.name,
        type,
        clinic_id: this.protocol.clinic_id ? this.protocol.clinic_id : null,
        person_id: this.protocol.person_id
      }
      try {
        const response = await this.api.updateProtocol(this.protocol.id, data)
        this.$emit('update-protocols')

        if (response.data.type === 'padrão') {
          this.$toast.success(
            'Protocolo salvo nos protocolos padrões com sucesso!'
          )
        } else {
          this.$toast.success(
            'Protocolo removido dos protocolos padrões com sucesso!'
          )
        }
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    changeSelected($event) {
      if (this.selected) {
        this.$emit('selected-protocols', this.protocol)
      } else {
        this.$emit('unselected-protocols', this.protocol.id)
      }
    }
  },
  watch: {
    selectProtocols(value) {
      const index = value.findIndex(
        protocol => protocol.id === this.protocol.id
      )
      if (index === -1) {
        this.selected = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.p-table {
  width: 100%;
  text-align: left;
  box-sizing: border-box;

  background: var(--neutral-000);

  border: 1px solid var(--neutral-100);
  box-shadow: 0px 2px 1px rgba(12, 29, 89, 0.04);
  border-radius: 8px;

  thead {
    tr {
      background: var(--neutral-100);
      border-radius: 8px;

      .td-protocol-name {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: var(--neutral-600);
      }

      .vertical-bar {
        color: var(--light-orange-500);
      }

      td {
        .more-with-menu {
          width: 24px;
          height: 28px;
          position: relative;
          display: inline-block;

          .more-icon {
            width: 24px;
            height: 24px;
            stroke: var(--neutral-500);
            transition: all 0.5s;
            cursor: pointer;
          }

          .menu {
            width: 233px;
            position: absolute;
            top: 28px;
            right: 0;
            background: var(--neutral-000);
            border: 1px solid var(--neutral-200);
            box-sizing: border-box;
            box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
              0px 4px 10px -8px rgba(12, 29, 89, 0.2);
            border-radius: 8px;
            padding: 24px;
            opacity: 0;
            transition: all 0.5s;
            flex-direction: column;
            align-items: start;
            display: none;

            .mtb-12 {
              margin: 12px 0;
            }

            .btn {
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 16px;

              &.remove {
                color: var(--states-error);
              }
            }
          }

          &:hover {
            .more-icon {
              transform: rotate(90deg);
            }

            .menu {
              opacity: 1;
              display: flex;
              z-index: 10;
            }
          }
        }
      }
    }
  }

  .td-header {
    font-weight: 700;
    font-size: 14px;
    color: var(--type-active);
    border: none;
    line-height: 150%;
    vertical-align: middle !important;

    .radio_default {
      box-sizing: border-box;
      width: 24px;
      height: 24px;
      background: var(--neutral-000);
      border: 1px solid var(--neutral-300);
      border-radius: 100%;
      cursor: pointer;
    }

    svg {
      cursor: pointer;
    }

    .chevron {
      width: 24px;
      height: 24px;
    }

    .rotate {
      transform: rotate(180deg);
    }
  }

  .td-body {
    vertical-align: middle !important;
    font-size: 16px;
    color: var(--type-active);
    line-height: 150%;
    text-align: left !important;
    border-top: none;
    border-bottom: 1px dashed var(--neutral-300);
  }

  .td-via {
    text-align: left;
  }

  .td-medicines {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--blue-500);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .orientation {
    font-size: 12px;
    color: var(--type-placeholder);
    font-style: italic;
    line-height: normal;
  }

  .antimicrobial {
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: var(--neutral-600);
  }

  .checkbox {
    margin-top: -10px;
  }

  .users-icon {
    width: 25px;
    height: 25px;
  }
}
</style>
