import store from '../store'

export const moduleExists = namespace => {
  return store.hasModule(namespace)
}

export const registerAdditionalExamModule = (id, name, status) => {
  if (store.hasModule(['attendance', 'form', id])) return
  store.registerModule(['attendance', 'form', id], {
    namespaced: true,
    state: () => ({
      id,
      name,
      opened: status,
      showing: status,
      completed: false,
      multiple: false,
      scopes: ['ATTENDANCE', 'SURGERY', 'EXAM'],
      fields: {
        customName: name,
        olhoDireito: null,
        olhoEsquerdo: null
      }
    }),
    mutations: {
      handleProps: (state, { key, value }) => {
        state[key] = value
      },
      handleFields: (state, { key, value }) => {
        state.fields[key] = value
      },
      checkComplete: state => {
        state.completed = state.fields.olhoDireito?.length || state.fields.olhoEsquerdo?.length
      }
    },
    getters: {},
    actions: {
      handleProps(context, { key, value }) {
        context.commit('handleProps', { key, value })
      },
      handleFields(context, { key, value }) {
        context.commit('handleFields', { key, value })
        context.commit('checkComplete')
      }
    },
  })
  store.dispatch(`attendance/menu/addAdditionalExamMenu`, id, { root: true })
}

export const removeAdditionalExamModule = formName => {
  store.dispatch(`attendance/menu/removeAdditionalExamMenu`, formName, { root: true })
  store.unregisterModule(['attendance', 'form', formName])
}
