import { render, staticRenderFns } from "./TextArea.vue?vue&type=template&id=e7940290&scoped=true"
import script from "./TextArea.vue?vue&type=script&lang=js"
export * from "./TextArea.vue?vue&type=script&lang=js"
import style0 from "./TextArea.vue?vue&type=style&index=0&id=e7940290&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7940290",
  null
  
)

export default component.exports