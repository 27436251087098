import api from '../api'
import moment from 'moment'
import { getCurrentClinic, getCurrentUser, userHasPermission } from '@/utils/localStorageManager'

const getAppointments = (context, props) => {
  const clinic = getCurrentClinic()
  context.commit('setLoading', true)
  api.getAppointments(clinic.id, props)
    .then(({ data }) => {
      const events = [];
      data.forEach(appointment => {
        events.push({
          id: appointment.id,
          title: appointment.patient.name,
          start: getEventStartTime(appointment),
          end: getEventEndTime(appointment),
          appointment: appointment,
          resourceId: appointment.professional_id,
          plans: [...new Set(appointment.appointment_items.map(ai => ai?.health_plan?.fantasy_name ?? ai?.health_plan?.company_name))]
        });
        
        if(props.view === 'resourceTimeGridDay' && appointment.room_id){
          events.push({
            id: appointment.id+'_room',
            title: appointment.patient.name,
            start: getEventStartTime(appointment),
            end: getEventEndTime(appointment),
            appointment: appointment,
            resourceId: appointment.room_id,
            plans: [...new Set(appointment.appointment_items.map(ai => ai?.health_plan?.fantasy_name ?? ai?.health_plan?.company_name))]
          });
        }

      });
      
      context.commit('setEvents', events)
    })
    .finally(() => context.commit('setLoading', false))
}

const getSurgicalMapAppoinments = (context, { start, filters }) => {
  const clinic = getCurrentClinic()
  context.commit('setLoading', true)
  start = moment(start).format('YYYY-MM-DD')
  Promise.all([
      getSurgicalMapData(clinic.id, start, filters),
      getRoomReservationByDay(clinic.id, start, filters),
  ]).then(([{ data: surgicalMapData }, { data: roomReservationData }]) => {
      const events = surgicalMapData.map(event => ({
        id: event.id,
        title: event.patient?.name,
        start: event.start_datetime,
        end: event.end_datetime,
        resourceId: event.room_id,
        appointment: event,
        plans: [...new Set(event.appointment_items.map(item => item?.health_plan?.fantasy_name ?? item?.health_plan?.company_name))]
      }))
      const roomReservations = roomReservationData.map(event => ({
        id: event.id,
        type: 'reservation',
        start: `${event.date} ${event.start_time}`,
        end: `${event.date} ${event.end_time}`,
        resourceId: event.room_id,
        data: {
          ...event,
          title: event.professional?.name
            ? 'Sala reservada'
            : 'Sala bloqueada',
          type: event.professional?.name ? 'reservation' : 'block'
        }      
      }))
      context.commit('setSurgicalEvents', [...events, ...roomReservations])
  })
  .finally(() => context.commit('setLoading', false))

  // api.getSurgicalMapData(clinic.id, start, filters)
  // .then(({ data }) => {
  //   const events = data.map(event => {
  //     return {
  //       id: event.id,
  //       title: event.patient?.name,
  //       start: event.start_datetime,
  //       end: event.end_datetime,
  //       resourceId: event.room_id,
  //       appointment: event,
  //       plans: [...new Set(event.appointment_items.map(item => item?.health_plan?.fantasy_name ?? item?.health_plan?.company_name))]
  //     }
  //   })
  //   context.commit('setSurgicalEvents', events)
  // })
  // .finally(() => context.commit('setLoading', false))
}

const getScheduleOptions = async (context, clinicId) => {
  const user = getCurrentUser()
  let managers = []
  try {
    const { data } = await api.getProfessionalsAndRooms(clinicId)
    let filtered = data;

    if(user.type === 'DOCTOR' && userHasPermission('FpAgenSal10')){
      filtered = data.filter(el => {
        return el.id === user.id || el.role === 'room';
      });

      filtered.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      context.commit('setSchedules', filtered)      
    } else {
      context.commit('setSchedules', filtered)
    }

    if (user.type === 'DOCTOR') {
      const schedule = filtered.find(el => el.id === user.id)
      schedule.userId = user.id;
      schedule.clinicId = clinicId;

      schedule && managers.push(schedule)
    } else {
      managers = data.reduce((acc, schedule) => {
        if (acc.length < 3 && schedule.role === 'DOCTOR_MANAGER') {
          schedule.userId = user.id;
          schedule.clinicId = clinicId;
          acc.push(schedule)
        }
        return acc
      }, [])
    }

    const scheduleOptions = JSON.parse(localStorage.getItem('scheduleOptions'));    
    const userScheduleOptions = scheduleOptions ? scheduleOptions.filter(el => el.userId === user.id && el.clinicId === clinicId) : [];
    context.commit('setSelectedSchedules', userScheduleOptions.length ? userScheduleOptions : managers);

  } catch (error) {
    console.error(error)
  }
}

const setSelectedSchedules = (context, payload) => {  
  context.commit('setSelectedSchedules', payload)
}

function getEventStartTime(appointment) {
  return (appointment.clinic?.attendance_type === 'ARRIVAL' && appointment.start_datetime) || appointment.start_datetime
}

function getEventEndTime(appointment) {
  const diff = moment(appointment.end_datetime).diff(moment(appointment.end_datetime), 'minutes')
  return (appointment.clinic?.attendance_type === 'ARRIVAL' && appointment.end_datetime && moment(appointment.end_datetime).add(diff, 'minutes').format('YYYY-MM-DD HH:mm:ss')) || appointment.end_datetime
}

function getSurgicalMapData(clinicId, start, filters) {
  return api.getSurgicalMapData(clinicId, start, filters)
}

function getRoomReservationByDay(clinicId, start, filters) {
  return api.getRoomReservationByDay(clinicId, start, filters)
}

export default {
  getAppointments,
  getScheduleOptions,
  setSelectedSchedules,
  getSurgicalMapAppoinments,
}
