import _cloneDeep from 'lodash/cloneDeep'

const formGroups = {
  'Anamnese oftalmológica completa': {
    opened: false,
    forms: {
      'queixa-principal': {
        id: 'queixa-principal',
        name: 'Queixa principal',
        showing: false,
        opened: true,
        active: false,
        fields: {
          queixaPrincipal: ''
        }
      },
      'historia-da-doenca-atual': {
        id: 'historia-da-doenca-atual',
        name: 'História da doença atual',
        showing: false,
        opened: true,
        active: false,
        fields: {
          historicoDaDoencaAtual: ''
        }
      },
      'antecedentes-oftalmologicos': {
        id: 'antecedentes-oftalmologicos',
        name: 'Antecedentes oftalmológicos',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: false,
          antecedente: null,
          situacao: null
        },
        value: [],
        editingIndex: null,
      },
      'antecedentes-pessoais': {
        id: 'antecedentes-pessoais',
        name: 'Antecedentes pessoais',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: false,
          antecedente: null,
          ano: null
        },
        value: [],
        editingIndex: null,
      },
      'antecedentes-familiares': {
        id: 'antecedentes-familiares',
        name: 'Antecedentes familiares',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: false,
          antecedente: null,
          familiar: null
        },
        value: [],
        editingIndex: null,
      },
      'cirurgias-oftalmologicas-anteriores': {
        id: 'cirurgias-oftalmologicas-anteriores',
        name: 'Cirurgias oftalmológicas anteriores',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: false,
          cirurgia: null,
          data: null
        },
        value: [],
        editingIndex: null,
      },
      'habitos-de-vida': {
        id: 'habitos-de-vida',
        name: 'Hábitos de vida',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: null
        },
        value: [],
      },
      'medicamentos-oftalmologicos-em-uso': {
        id: 'medicamentos-oftalmologicos-em-uso',
        name: 'Medicamentos oftalmológicos em uso',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          medicamento: null,
          emUso: true
        },
        value: [],
        editingIndex: null,
      },
      'outros-medicamentos-em-uso': {
        id: 'outros-medicamentos-em-uso',
        name: 'Outros medicamentos em uso',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          medicamento: null,
          emUso: true
        },
        value: [],
        editingIndex: null,
      },
      'alergias': {
        id: 'alergias',
        name: 'Alergias',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: false,
          alergia: null,
          comentario: null
        },
        value: [],
      },
      'informacoes-adicionais-anamnese': {
        id: 'informacoes-adicionais-anamnese',
        name: 'Informações adicionais',
        showing: false,
        opened: true,
        active: false,
        fields: {
          informacoesAdicionaisAnamnese: null
        }
      }
    },
  },
  'Triagem Benefício Visão': {
    opened: false,
    forms: {
      'bv-triagem': {
        id: 'bv-triagem',
        name: 'Triagem',
        showing: false,
        opened: true,
        active: false,
        fields: {
          bvTriagem: ''
        }
      },
      'bv-teste-acuidade-visual': {
        id: 'bv-teste-acuidade-visual',
        name: 'Teste Acuidade Visual',
        showing: false,
        opened: true,
        active: false,
        fields: {
          bvTesteAcuidadeVisual: ''
        }
      },
      'bv-teste-ishihara': {
        id: 'bv-teste-ishihara',
        name: 'Teste Ishihara',
        showing: false,
        opened: true,
        active: false,
        fields: {
          hits: 0,
          redGreenDeficiency: true,
          redDeficiency: false,
          greenDeficiency: false,
          miss: 0,
          hitsList: [],
          missList: [],
          diagnosysLabel: 'Não diagnósticado',
          answers: null
        }
      },
    },
  },
  'Exames oftalmológicos': {
    opened: false,
    forms: {
      'ectoscopia': {
        id: 'ectoscopia',
        name: 'Ectoscopia',
        showing: false,
        opened: true,
        active: false,
        fields: {
          ectoscopia: null
        }
      },
      'avsc-acuidade-visual-sem-correcao': {
        id: 'avsc-acuidade-visual-sem-correcao',
        name: 'AVSC - Acuidade visual sem correção',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'avcc-acuidade-visual-com-correcao': {
        id: 'avcc-acuidade-visual-com-correcao',
        name: 'AVCC - Acuidade visual com correção',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoDireitoEsferico: null,
          olhoDireitoCilindrico: null,
          olhoDireitoEixo: null,
          olhoEsquerdo: null,
          olhoEsquerdoEsferico: null,
          olhoEsquerdoCilindrico: null,
          olhoEsquerdoEixo: null,
          possuiAutorrefrator: false
        }
      },
      'avppsc-acuidade-visual-para-perto-sem-correcao': {
        id: 'avppsc-acuidade-visual-para-perto-sem-correcao',
        name: 'AVPPSC - Acuidade visual para perto s/ correção',
        showing: false,
        opened: true,
        multiple: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'avppcc-acuidade-visual-para-perto-com-correcao': {
        id: 'avppcc-acuidade-visual-para-perto-com-correcao',
        name: 'AVPPCC - Acuidade visual para perto c/ correção',
        showing: false,
        opened: true,
        multiple: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'lensometria': {
        id: 'lensometria',
        name: 'Lensometria',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireitoEsferico: null,
          olhoDireitoCilindrico: null,
          olhoDireitoEixo: null,
          olhoDireitoAdicao: null,
          olhoEsquerdoEsferico: null,
          olhoEsquerdoCilindrico: null,
          olhoEsquerdoEixo: null,
          olhoEsquerdoAdicao: null,
        },
      },
      'refracao-dinamica': {
        id: 'refracao-dinamica',
        name: 'Refração dinâmica',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireitoEsfericoDin: null,
          olhoDireitoCilindricoDin: null,
          olhoDireitoEixoDin: null,
          olhoDireitoDPDin: null,
          olhoDireitoAVDin: null,
          olhoDireitoAdicaoDin: null,
          olhoEsquerdoEsfericoDin: null,
          olhoEsquerdoCilindricoDin: null,
          olhoEsquerdoEixoDin: null,
          olhoEsquerdoDPDin: null,
          olhoEsquerdoAVDin: null,
          olhoEsquerdoAdicaoDin: null,
          usarValoresNaPrescricaoDeOculosDin: null,
        },
      },
      'refracao-estatica': {
        id: 'refracao-estatica',
        name: 'Refração estática',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireitoEsfericoEst: null,
          olhoDireitoCilindricoEst: null,
          olhoDireitoEixoEst: null,
          olhoDireitoDPEst: null,
          olhoDireitoAVEst: null,
          olhoDireitoAdicaoEst: null,
          olhoEsquerdoEsfericoEst: null,
          olhoEsquerdoCilindricoEst: null,
          olhoEsquerdoEixoEst: null,
          olhoEsquerdoDPEst: null,
          olhoEsquerdoAVEst: null,
          olhoEsquerdoAdicaoEst: null,
          usarValoresNaPrescricaoDeOculosEst: null,
        },
      },
      'acuidade-visual-para-longe-apos-refracao': {
        id: 'acuidade-visual-para-longe-apos-refracao',
        name: 'Acuidade Visual para longe após Refração',
        showing: false,
        opened: true,
        multiple: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'acuidade-visual-para-perto-apos-refracao': {
        id: 'acuidade-visual-para-perto-apos-refracao',
        name: 'Acuidade Visual para perto após Refração',
        showing: false,
        opened: true,
        multiple: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'biomicroscopia': {
        id: 'biomicroscopia',
        name: 'Biomicroscopia',
        showing: false,
        opened: true,
        active: false,
        fields: {
          ciliosEPalpebrasDireito: null,
          ciliosEPalpebrasEsquerdo: null,
          conjuntivaDireito: null,
          conjuntivaEsquerdo: null,
          corneaDireito: null,
          corneaEsquerdo: null,
          camaraAnteriorDireito: null,
          camaraAnteriorEsquerdo: null,
          irisDireito: null,
          irisEsquerdo: null,
          pupilaDireito: null,
          pupilaEsquerdo: null,
          cristalinoDireito: null,
          cristalinoEsquerdo: null,
          vitreoDireito: null,
          vitreoEsquerdo: null,
          biomicroscopiaDeFundoDireito: null,
          biomicroscopiaDeFundoEsquerdo: null
        },
        autoCompleted: false,
      },
      'pressao-intraocular': {
        id: 'pressao-intraocular',
        name: 'Pressão intraocular',
        showing: false,
        opened: true,
        active: false,
        fields: {
          aparelho: null,
          horario: null,
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'gonioscopia': {
        id: 'gonioscopia',
        name: 'Gonioscopia',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'esquiascopia': {
        id: 'esquiascopia',
        name: 'Esquiascopia',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'mapeamento-de-retina': {
        id: 'mapeamento-de-retina',
        name: 'Mapeamento de retina',
        showing: false,
        opened: true,
        active: false,
        fields: {
          vitreoDireito: null,
          vitreoEsquerdo: null,
          discoOpticoDireito: null,
          discoOpticoEsquerdo: null,
          escavacaoDireito: null,
          escavacaoEsquerdo: null,
          maculaDireito: null,
          maculaEsquerdo: null,
          vasosDireito: null,
          vasosEsquerdo: null,
          retinaDireito: null,
          retinaEsquerdo: null
        },
        autoCompleted: false,
      },
      'motilidade-ocular-extrinseca': {
        id: 'motilidade-ocular-extrinseca',
        name: 'Motilidade ocular extrínseca',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'cover-test': {
        id: 'cover-test',
        name: 'Cover Test',
        showing: false,
        opened: true,
        active: false,
        fields: {
          coverTest: null
        }
      },
      'reflexos': {
        id: 'reflexos',
        name: 'Reflexos',
        showing: false,
        opened: true,
        active: false,
        fields: {
          fotomotorDiretoDireito: null,
          fotomotorDiretoEsquerdo: null,
          consensualDireito: null,
          consensualEsquerdo: null,
          dparDireito: null,
          dparEsquerdo: null
        }
      },
      'informacoes-adicionais': {
        id: 'informacoes-adicionais',
        name: 'Informações adicionais',
        showing: false,
        opened: true,
        active: false,
        fields: {
          informacoesAdicionais: null
        }
      },
      'campo-confrontacao': {
        id: 'campo-confrontacao',
        name: 'Campo de confrontação',
        showing: false,
        opened: true,
        active: false,
        fields: {
          campoConfrontacao: null
        }
      },
      'teste-sensibilidade-mimica-facial': {
        id: 'teste-sensibilidade-mimica-facial',
        name: 'Teste de sensibilidade mímica facial',
        showing: false,
        opened: true,
        active: false,
        fields: {
          testeSensibilidadeMimicaFacial: null
        }
      },
    }
  },
  'Exames complementares': {
    opened: false,
    forms: {
      'biometria': {
        id: 'biometria',
        name: 'Biometria',
        showing: false,
        opened: true,
        active: false,
        value: [],
      },
      'retinografia': {
        id: 'retinografia',
        name: 'Retinografia',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'paquimetria': {
        id: 'paquimetria',
        name: 'Paquimetria',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'campo-visual': {
        id: 'campo-visual',
        name: 'Campo visual',
        showing: false,
        opened: true,
        active: false,
        fields: {
          aparelho: null,
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'oct-tomografia-de-coerencia-optica-macula': {
        id: 'oct-tomografia-de-coerencia-optica-macula',
        name: 'OCT (Tomografia de Coerência Óptica) de Mácula',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'oct-tomografia-de-coerencia-optica-nervo-optico': {
        id: 'oct-tomografia-de-coerencia-optica-nervo-optico',
        name: 'OCT (Tomografia de Coerência Óptica) de Nervo Óptico',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'oct-tomografia-de-coerencia-optica-segmento-anterior': {
        id: 'oct-tomografia-de-coerencia-optica-segmento-anterior',
        name: 'OCT (Tomografia de Coerência Óptica) de Segmento Anterior',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'oct-a-tomografia-de-coerencia-optica': {
        id: 'oct-a-tomografia-de-coerencia-optica',
        name: 'OCT-A (Angiotomografia de Coerência Óptica)',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'topografia': {
        id: 'topografia',
        name: 'Topografia',
        showing: false,
        opened: true,
        active: false,
        fields: {
          aparelho: null,
          olhoDireito: null,
          olhoDireitoK1Eixo: null,
          olhoDireitoK2Curvatura: null,
          olhoDireitoK2Eixo: null,
          olhoDireitoKmaxCurvatura: null,
          olhoDireitoKmaxEixo: null,
          DireitoImpressao: null,
          olhoEsquerdo: null,
          olhoEsquerdoK1Eixo: null,
          olhoEsquerdoK2Curvatura: null,
          olhoEsquerdoK2Eixo: null,
          olhoEsquerdoKmaxCurvatura: null,
          olhoEsquerdoKmaxEixo: null,
          EsquerdoImpressao: null,
        }
      },
      'tomografia-de-cornea-pentacam': {
        id: 'tomografia-de-cornea-pentacam',
        name: 'Tomografia de Córnea (Pentacam)',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'tomografia-de-cornea-galilei': {
        id: 'tomografia-de-cornea-galilei',
        name: 'Tomografia de Córnea (Galilei)',
        showing: false,
        opened: true,
        active: false,
        fields: {
          astigmatismoOlhoDireito: null,
          astigmatismoOlhoEsquerdo: null,
          curvoOlhoDireito: null,
          curvoOlhoEsquerdo: null,
          planoOlhoDireito: null,
          planoOlhoEsquerdo: null,
          finoOlhoDireito: null,
          finoOlhoEsquerdo: null,
          kappaOlhoDireito: null,
          kappaOlhoEsquerdo: null,
          kpiOlhoDireito: null,
          kpiOlhoEsquerdo: null,
          kmaxOlhoDireito: null,
          kmaxOlhoEsquerdo: null,
        }
      },
      'microscopia-especular-de-cornea': {
        id: 'microscopia-especular-de-cornea',
        name: 'Microscopia Especular de Córnea',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'indocianinografia-icg': {
        id: 'indocianinografia-icg',
        name: 'Indocianinografia (ICG)',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'angiofluoresceinografia-afg': {
        id: 'angiofluoresceinografia-afg',
        name: 'Angiofluoresceinografia (AFG)',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'usg-ocular': {
        id: 'usg-ocular',
        name: 'USG Ocular',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'eletrorretinograma-erg-de-campo-total': {
        id: 'eletrorretinograma-erg-de-campo-total',
        name: 'Eletrorretinograma (ERG) de Campo Total',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'erg-multifocal': {
        id: 'erg-multifocal',
        name: 'ERG Multifocal',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'potencial-visual-evocado-pve': {
        id: 'potencial-visual-evocado-pve',
        name: 'Potencial Visual Evocado (PVE)',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'pve-varredura': {
        id: 'pve-varredura',
        name: 'PVE varredura',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      },
      'potencial-acuidade-macular': {
        id: 'potencial-acuidade-macular',
        name: 'Potencial de Acuidade Macular (PAM)',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      }
    }
  },
  'Informações cirúrgicas': {
    opened: false,
    forms: {
      'dados-da-cirurgia': {
        id: 'dados-da-cirurgia',
        name: 'Dados da cirurgia',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: false,
          cirurgia: null,
          surgeryDataLaterality: "Ambos os olhos",
        },
        value: [],
        editingIndex: null
      },
      'anestesia': {
        id: 'anestesia',
        name: 'Anestesia',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: null,
          anestesia: null,
          anestesista: null
        },
        value: [],
        editingIndex: null
      },
      'produtos-utilizados': {
        id: 'produtos-utilizados',
        name: 'Produtos utilizados',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: null,
          produto: null,
          quantidade: null
        },
        value: [],
        editingIndex: null
      },
      'patologia-a-ser-tratada': {
        id: 'patologia-a-ser-tratada',
        name: 'Patologia a ser tratada',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: false,
          patologia: null,
          lateralidadePatologia: 'Ambos os olhos',
          comentario: null
        },
        value: [],
        editingIndex: null,
      },
      'anotacao-cirurgica': {
        id: 'anotacao-cirurgica',
        name: 'Anotação cirúrgica',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          anotacaoCirurgica: null,
        },
        value: [],
        editingIndex: null
      },
      'intercorrencias': {
        id: 'intercorrencias',
        name: 'Intercorrências',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          intercorrencias: null,
        },
        value: [],
        editingIndex: null
      }
    }
  },
  'Diagnóstico': {
    opened: false,
    forms: {
      'hipotese-diagnostica': {
        id: 'hipotese-diagnostica',
        name: 'Hipótese diagnóstica',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          hipoteseDiagnostica: null
        },
        value: [],
        editingIndex: null,
      },
      'cid': {
        id: 'cid',
        name: 'CID',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          cid: null,
          predominante: false
        },
        value: [],
        editingIndex: null,
      }
    }
  },
  'Conduta': {
    opened: false,
    forms: {
      'solicitacao-de-exames': {
        id: 'solicitacao-de-exames',
        name: 'Solicitação de exames',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          exams: [],
          observation: null,
        },
        value: [],
      },
      'apa-avaliacao-pre-anestesica': {
        id: 'apa-avaliacao-pre-anestesica',
        name: 'APA (Avaliação Pré Anestésica)',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: null
        },
        value: [],
      },
      'solicitacao-de-cirurgia': {
        id: 'solicitacao-de-cirurgia',
        name: 'Solicitação de cirurgia',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: false,
          cirurgia: null,
          comentario: null,
          surgeryLaterality: "Ambos os olhos",
        },
        value: [],
      },
      'encaminhamento': {
        id: 'encaminhamento',
        name: 'Encaminhamento',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: false,
          encaminhamento: null,
          comentario: null
        },
        value: [],
      },
      'prescricao-medicamentosa-v2': {
        id: 'prescricao-medicamentosa-v2',
        name: 'Prescrição Medicamentosa',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: null,
          customHour: null,
          medicamento: null,
          antimicrobiano: null,
          via: null,
          quantidade: null,
          dose: null,
          intervalo: null,
          lateralidade: null,
          tempo_uso: null,
          horarios: [],
          orientacao: null
        },
        value: [],
        editingIndex: null,
      },
      'oculos': {
        id: 'oculos',
        name: 'Óculos',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireitoEsferico: null,
          olhoDireitoCilindrico: null,
          olhoDireitoEixo: null,
          olhoDireitoDP: null,
          olhoDireitoAdicao: null,
          olhoEsquerdoEsferico: null,
          olhoEsquerdoCilindrico: null,
          olhoEsquerdoEixo: null,
          olhoEsquerdoDP: null,
          olhoEsquerdoAdicao: null,
          orientacao: null,
          usarValoresRefracao: null,
          materiaPrima: null,
          tratamentoLente: null,
          tipoLente: null,
          marcaRecomendada: null,
        },
      },
      'lentes-de-contato': {
        id: 'lentes-de-contato',
        name: 'Prescrição de lentes de contato',
        showing: false,
        opened: true,
        active: false,
        fields: {
          olhoDireitoEsferico: null,
          olhoDireitoCilindrico: null,
          olhoDireitoEixo: null,
          olhoDireitoDP: null,
          olhoDireitoAdicao: null,
          olhoEsquerdoEsferico: null,
          olhoEsquerdoCilindrico: null,
          olhoEsquerdoEixo: null,
          olhoEsquerdoDP: null,
          olhoEsquerdoAdicao: null,
          orientacao: null,
          usarValoresRefracao: null,
        },
      },
      'orientacao-pos-operatoria': {
        id: 'orientacao-pos-operatoria',
        name: 'Orientação ao paciente',
        showing: false,
        opened: true,
        active: false,
        multiple: true,
        fields: {
          custom: false,
          orientacao: null,
          descricao: null,
        },
        value: [],
        editingIndex: null
      },
      'mais-Informacoes': {
        id: 'mais-Informacoes',
        name: 'Mais Informações',
        showing: false,
        opened: true,
        active: false,
        fields: {
          MaisInformacoes: null
        }
      },
      'retorno': {
        id: 'retorno',
        name: 'Retorno',
        showing: false,
        opened: true,
        active: false,
        fields: {
          retorno: null
        },
      },
      'atestados-medicos': {
        id: 'atestados-medicos',
        name: 'Atestados Médicos',
        showing: false,
        opened: true,
        active: false,
        fields: {
          atestado: false,
          dias: null,
          cid: null,
          declaracaoDeComparecimento: false,
          declaracaoDeComparecimentoAcompanhante: false,
          nomeAcompanhante: null,
          cpfAcompanhante: null
        },
      },
      'laudo-medico': {
        id: 'laudo-medico',
        name: 'Laudo médico',
        active: false,
        showing: false,
        opened: true,
        multiple: true,
        fields: {
          laudo: null,
          descricao: null,
          professional_id: null,
        },
        value: [],
        editingIndex: null,
      },
      'complemento-glaucoma': {
        id: 'complemento-glaucoma',
        name: 'Complemento Glaucoma',
        opened: true,
        showing: false,
        completed: false,
        multiple: true,
        fields: {
          medicamentoOpcao1: null,
          primeiroMesOpcao1: null,
          segundoMesOpcao1: null,
          terceiroMesOpcao1: null,
          posologiaOpcao1: null,
          olhoOpcao1: null,
          medicamentoOpcao2: null,
          primeiroMesOpcao2: null,
          segundoMesOpcao2: null,
          terceiroMesOpcao2: null,
          posologiaOpcao2: null,
          olhoOpcao2: null,
          medicamentoOpcao3: null,
          primeiroMesOpcao3: null,
          segundoMesOpcao3: null,
          terceiroMesOpcao3: null,
          posologiaOpcao3: null,
          olhoOpcao3: null,
          medicamentoOpcao4: null,
          primeiroMesOpcao4: null,
          segundoMesOpcao4: null,
          terceiroMesOpcao4: null,
          posologiaOpcao4: null,
          olhoOpcao4: null,
          anamnese: null,
          tratamentoSim: null,
          tratamentoNao: null,
          incapazSim: null,
          incapazNao: null
        },
      },
    }
  }
}

export const getFormGroups = (dbForm) => {
  const newFormGroup = _cloneDeep(formGroups, {}, true)
  if (!dbForm) {
    Object.keys(newFormGroup).map(key => {
      if (key === 'Informações cirúrgicas') {
        return
      }
      Object.keys(newFormGroup[key].forms).map(formKey => {
        newFormGroup[key].forms[formKey].showing = true
      })
    })
    return newFormGroup
  }

  Object.keys(newFormGroup).map(key =>
    Object.keys(newFormGroup[key].forms).map(formKey => {
      const form = dbForm.forms.find(f => f.id === formKey);
      if (form) {
        newFormGroup[key].forms[formKey] = {
          ...newFormGroup[key].forms[formKey],
          ...form
        }
      }
    })
  );
  dbForm.forms.filter(form => form.id.includes('exame-personalizado')).map(customForm => {
    newFormGroup['Exames complementares'].forms[customForm.id] = customForm
  })
  return newFormGroup;
};

export const formCompleted = (form) => {
  function isProsCompleted(key, props) {
    // ignoring props that has default value
    if (['emUso', 'examLaterality', 'surgeryLaterality', 'surgeryDataLaterality', 'lateralidadePatologia'].includes(key)) {
      return false;
    }
    return (props && !Array.isArray(props)) || (Array.isArray(props) && props.length);
  }
  return (form.value && form.value.length) ||
    (!form.value && form.fields && Object.keys(form.fields).some(key => isProsCompleted(key, form.fields[key])));
}
