<template>
  <div class="timer-wrapper">
    <div class="time-unity">
      <div class="header">Dias</div>
      <div class="time">{{ String((days % 365)).padStart(2, '0') }}</div>
    </div>
    <div class="time-unity">
      <div class="header">Horas</div>
      <div class="time">{{ String((hours % 24)).padStart(2, '0') }}</div>
    </div>
    <div class="time-unity">
      <div class="header">Min</div>
      <div class="time">{{ String((minutes % 60)).padStart(2, '0') }}</div>
    </div>
    <div class="time-unity">
      <div class="header">Seg</div>
      <div class="time">{{ String((seconds % 60)).padStart(2, '0') }}</div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    expirationDate: String,
  },
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },
  mounted() {
    setInterval(() => {
      const lunchDate = moment(this.expirationDate).toDate()
      const currentDate = new Date()
      const lunchTime = lunchDate - currentDate
      this.seconds = parseInt(lunchTime / 1000)
      this.minutes = parseInt(this.seconds / 60)
      this.hours = parseInt(this.minutes / 60)
      this.days = parseInt(this.hours / 24)
    }, 1000);
  }
}
</script>
<style lang="scss" scoped>
  .timer-wrapper {
    display: flex;
    flex-direction: row;
    gap: 5px;
    .time-unity {
      width: 36px;
      border: 1px solid var(--blue-500);
      background: var(--blue-500);
      border-radius: 3px;
      .header {
        font-weight: 600;
        font-size: 10px;
        background: var(--blue-500);
        color: white;
        text-align: center;
        border: none;
        border-radius: 3px 3px 0 0;
      }
      .time {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 24px;
        font-weight: 700;
        font-size: 12px;
        color: var(--type-active);
        background: white;
        border-radius: 0 0 3px 3px;
      }
    }
  }
</style>
