<!--- View de Login - Responsável pelo login dos usuários. --->

<template>
	<b-container class="login" fluid>
		<b-row>
			<b-col d-flex class="vh-100 d-flex justify-content-center align-items-center left-section">
				<div class="form-side text-left">
					<img src="@/assets/images/eyecare-logo-colored.png" class="logo-eyecare-login" alt="" />

					<validation-observer ref="loginForm">
						<!-- <p class="description" v-if="error === null">
						  Informe seus dados de acesso
						</p> -->
						<p class="error-message" v-if="error">
							<AlertOctagon class="icon"/> {{ error }}
						</p>

						<div>
							<div v-if="clinics.length">
								<div class="welcome">
									Bem vindo(a), <span class="name">{{ name }}</span>
								</div>
								<span class="reset-email" @click="resetEmail">Não sou eu</span>
							</div>
							<br/>

							<div v-if="clinics.length">
								<div class="form-group">
									<label for="password">Clínica</label>
									<multiselect
										trackBy="id"
										label="name"
										v-model="clinic"
										:options="clinics"
										:showLabels="false"
										:allowEmpty="false"
										:internal-search="false"
										placeholder="Selecione a clínica"
										class="with-border"
									/>
								</div>
							</div>

							<div class="form-group" :style="{ display: !clinics.length ? 'block' : 'none' }">
								<label for="email">E-mail</label>
								<validation-provider mode="lazy" name="e-mail" :rules="{ required: true, email: true }" v-slot="{ errors, dirty }">
									<input
										autocomplete="off" id="email" type="email" v-model="email"
										@keyup.enter="getUserLogin" class="form-control" placeholder="Digite seu email"
										:class="{ error: email.length > 0 && errors.length > 0 && dirty }"
									/>
								</validation-provider>
							</div>

							<div class="form-group" v-if="clinic">
								<label for="password">Senha</label>
								<validation-provider name="senha" :rules="{ required: true, min: 8 }" v-slot="{ errors, dirty }">
									<div class="input-password">
										<input
											id="password" :type="!showPassword ? 'password' : 'text'" v-model="password"
											@keyup.enter="doLogin" class="form-control"
											:class="{ error: password.length > 0 && errors.length > 0 && dirty }"
										/>
										<EyeSlash class="eye" v-if="showPassword == false" @click="showPassword = !showPassword" />
										<Eye class="eye" v-else @click="showPassword = !showPassword" />
									</div>
								</validation-provider>
							</div>

							<b-row class="mb-40" v-if="clinic">
								<b-col cols="6">
									<label for="remember" class="checkbox">
										<input type="checkbox" id="remember"/>
										<span class="checkmark">
										  	<Check/>
										</span>
										Lembrar senha
									</label>
								</b-col>
								<b-col cols="6" class="text-right">
									<router-link to="/esqueci-minha-senha" tag="p" class="link">
										Esqueci minha senha
									</router-link>
								</b-col>
							</b-row>

							<b-button block variant="primary" class="forward-button" @click="!clinic ? getUserLogin() : doLogin()">
								{{ !clinic ? 'Avançar' : 'Fazer Login' }}
							</b-button>
						</div>
					</validation-observer>

					<p class="text-center mt-48">
						Não possui uma conta ainda?
						<a href="https://eyecarehealth.com.br/produtos/empresas/quero-contratar?type=hospital" target="_blank">
							Solicite uma demonstração
						</a>
					</p>
				</div>
			</b-col>
			<b-col class="vh-100 p-0 position-relative right-section">
				<LoginCarousel/>
			</b-col>

			<InvoiceOverdueModal @close="show_overdue = false" :show="show_overdue" :overdue="overdue" :name="name" />
		</b-row>
	</b-container>
</template>

<script>
import AlertOctagon from '@/assets/icons/alert-octagon.svg'
import Eye from '@/assets/icons/eye.svg'
import EyeSlash from '@/assets/icons/eye-slash.svg'
import Check from '@/assets/icons/check.svg'
import LoginCarousel from './LoginCarousel'
import {identify, track} from '@/utils/mixPanel'
import {
	getCurrentClinic,
	setAccessToken,
	setRefreshToken,
	setCurrentUser,
	setCurrentClinic,
	setPermissions,
	userHasPermission
} from '@/utils/localStorageManager'

export default {
	name: 'Login',
	metaInfo: {
		title: 'Eyecare - Login'
	},

	components: {
		AlertOctagon,
		Eye,
		EyeSlash,
		Check,
		LoginCarousel,
		InvoiceOverdueModal: () => import('@/components/General/InvoiceOverdueModal.vue')
	},
	created: function () {
		if (this.api.isLogged()) {
			const url =  this.clinic.role.includes('DOCTOR' || !userHasPermission('FpAgen1')) ? '/visao-geral' : '/agenda'
			this.$router.push(url)
		}

		if (
			typeof this.$route.params.confirmationCode !== 'undefined' &&
			this.$route.params.confirmationCode !== ''
		) {
			this.api
				.confirm({code: this.$route.params.confirmationCode})
				.then(res => {
					const resData = res.data

					if (resData.success) {
						this.$toast.success('Conta verificada com sucesso!');
					} else {
						this.$toast.error(resData.message);
					}
				})
				.catch(err => {
					console.log('Err', err)
				})
				.finally(() => {
					this.$router.push('/login')
				})
		}
	},

	data() {
		return {
			clinics: [],
			name: null,
			clinic: getCurrentClinic() ?? null,
			showPassword: false,
			email: '',
			password: '',
			error: null,
			overdue: null,
			show_overdue: false
		}
	},

	methods: {
		getUserLogin() {
			this.api.userLogin(this.email)
				.then(res => {
					this.name = res.data.name
					this.clinics = res.data.clinics
					if (this.clinics.length === 1) {
						this.clinic = this.clinics[0]
					}
				})
				.catch(this.setError)
		},

		resetEmail() {
			this.email = ''
			this.name = null
			this.clinics = []
			this.clinic = null
		},

		doLogin() {
			this.error = null
			const isLoading = this.$loading.show()
			this.api
				.login({email: this.email, password: this.password, clinicId: this.clinic.id})
				.then(async res => {
					if (res.status === 200) {
						const resData = res.data

						if (resData.overdue) {
							this.overdue = resData.overdue
							this.name = resData.user.name
							this.show_overdue = true
							this.$bvModal.show('invoice-overdue-modal')
						} else {
							if (typeof resData.accessToken !== 'undefined') {
								const clinic = resData.user.clinics.find(el => el.id === this.clinic.id)
								setAccessToken(resData.accessToken);
								setRefreshToken(resData.refreshToken);
								setCurrentUser(resData.user);
								setCurrentClinic(clinic);
								setPermissions(resData.user.permissions);

								if (clinic.role === 'DOCTOR' || clinic.role === 'DOCTOR_MANAGER' || !userHasPermission('FpAgen1')) {
									window.location.href = '/visao-geral'
								} else {
									window.location.href = '/agenda'
								}

								identify(resData.user)
								track('login', {
									name: resData.user.name,
									type: resData.user.type,
									gender: resData.user.gender,
									city: resData.user.address ? resData.user.address.city : null,
									state: resData.user.address ? resData.user.address.state : null,
									clinic: clinic ? clinic.name : null,
								});
							}
						}
					}
				})
				.catch(this.setError)
				.finally(() => {
					isLoading.hide()
				})
		},

		setError(err) {
			this.error = err.message
			setTimeout(() => {
				this.error = null;
			}, 8000)
		}
	}
}
</script>

<style lang="scss">
.login {
	margin: 0 !important;
	padding: 0 !important;
	overflow-x: hidden;

	.welcome {
		font-size: 22px;
		color: var(--type-active);
	}

	.name {
		font-weight: 600;
	}

	.forward-button {
		font-size: .9rem !important;
	}

	.reset-email {
		font-size: 14px;
		margin-bottom: 20px;
		color: var(--blue-500);
		cursor: pointer;
	}

	.form-side {
		width: 70%;

		.logo-eyecare-login {
			width: 150px;
			margin-bottom: 20px;
		}

		.title {
			font-family: 'Red Hat Display';
			font-weight: 700;
			font-size: 32px;
			line-height: 40.32px;
			color: var(--dark-blue);
			margin: 80px 0 20px 0;
		}

		.description {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: var(--type-active);
			margin-bottom: 48px;
		}

		.error-message {
			background-color: #fee8e6;
			border-radius: 8px;
			padding: 16px 26px;
			display: flex;
			justify-content: center;
			font-size: 14px;
			font-weight: 600;
			margin: 4px 0 40px;

			.icon {
				width: 24px;
				height: 24px;
				margin-right: 25px;
				stroke: var(--states-error);
			}
		}

		.mb-40 {
			margin-bottom: 40px;
		}

		.mt-48 {
			margin-top: 48px;
		}

		button {
			font-size: 18px;
			font-weight: 600;
			color: var(--neutral-000);
			padding: 16px;
			border-radius: 8px;
			line-height: 24px;
		}
	}
	
	@media (max-width: 680px) {
		.left-section {
			height: 100% !important;
			padding: 16px 0;

			.form-side {
				width: 75%;
			}
		}
		
		.right-section {
			display: none;
		}
	}

	@media (max-width: 320px) {
		.carousel-side {
			display: none;
		}
	}
}
</style>
