import Vue from 'vue'

const clearNotifications = (state) => {
  state.notifications = []
}

const removeNotification = (state, notificationId) => {
  state.notifications = state.notifications.filter(el => {
    return el.id !== notificationId
  })
}

const setNotificationAsVisualized = (state, notificationId) => {
    const index = state.notifications.findIndex(el => el.id === notificationId)
    state.notifications[index].visualized = true
}

const createNotification = (state, payload) => {
  state.notifications.unshift(payload)
}

const pushNotifications = (state, payload) => {
  state.notifications = state.notifications.concat(payload)
}

const setAllAsVisualized = (state) => {
  state.notifications.map(el => {
    el.visualized = 1
  }) 
  Object.keys(state.unreadNotifications).map(key => {
    state.unreadNotifications[key] = 0
  })
}

const setNotificationsPages = (state, total) => {
  state.notificationsPages = total
}

const setUnreadNotifications = (state, totalArray) => {
  state.unreadNotifications = totalArray
}

const incrementUnreadNotifications = (state, type) => {
  state.unreadNotifications?.total 
  ? Vue.prototype.$set(state.unreadNotifications, 'total', ++state.unreadNotifications.total)
  : Vue.prototype.$set(state.unreadNotifications, 'total', 1)

  if (type)
    state.unreadNotifications[type] 
    ? Vue.prototype.$set(state.unreadNotifications, type, ++state.unreadNotifications[type])
    : Vue.prototype.$set(state.unreadNotifications, type, 1)
}

const decrementUnreadNotifications = (state, type) => {
  if (state.unreadNotifications?.total > 0)
    state.unreadNotifications.total--
  if (type && state.unreadNotifications[type] > 0)
    state.unreadNotifications[type]--
}

export default {
  clearNotifications,
  removeNotification,
  createNotification,
  pushNotifications,
  setAllAsVisualized,
  setNotificationAsVisualized,
  setNotificationsPages,
  setUnreadNotifications,
  incrementUnreadNotifications,
  decrementUnreadNotifications,
}