const moduleRoute = {
    path: '/agenda',
    component: () => import('@/views/Home.vue'),
    children: [
        { 
            path: '', 
            component: () => import('./views/Home.vue'),
            children: [
                // { path: '', component: () => import('./views/Dashboard.vue') },
            ]
        },
    ]
}

export default router => { 
    router.addRoute(moduleRoute)
}