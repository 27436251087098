import { customBeforeEnter } from '@/router'; 

const moduleRoute = {
    path: '/pacientes',
    component: () => import('@/views/Home.vue'),
    children: [
        { 
            path: '', 
            component: () => import('@/views/Patients/PatientsList.vue'),
        },

        { 
            path: ':id', 
            component: () => import('./views/PatientProfile.vue'),
            beforeEnter: (to, from, next) => customBeforeEnter('FpPron1', to, from, next),
            children: [
                {
                    path: 'edit',
                    component: () => import('@/views/Patients/PatientForm.vue')
                },
                {
                    path: 'account',
                    component: () => import('@/components/AppointmentModal/AccountContent.vue')
                }
            ]
        },

    ],
    
}


export default router => { 
    router.addRoute(moduleRoute)
}