var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wh-container"},[_vm._m(0),_c('Tab',{ref:"medicalReports_tabs",attrs:{"index":_vm.currentTabIndex,"keys":_vm.tabs},on:{"onChange":_vm.onChangeTab}},[(
        this.tabs[_vm.currentTabIndex] === 'Laudos pendentes' &&
        _vm.userHasPermission('LaudoMedPen1')
      )?_c('PendingReportsTable'):_vm._e(),(
        this.tabs[_vm.currentTabIndex] === 'Exames laudados' &&
        _vm.userHasPermission('LaudoMedExam1')
      )?_c('FinishedReportsTable'):_vm._e(),(
        this.tabs[_vm.currentTabIndex] === 'Configurações' &&
        _vm.userHasPermission('LaudoMedConfigMed1') &&
        _vm.userHasPermission('LaudoMedConfigGer1')
      )?_c('ConfigTab'):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header no-print"},[_c('p',{staticClass:"title"},[_vm._v("Laudo Exame")])])
}]

export { render, staticRenderFns }