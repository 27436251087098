import Vue from 'vue'
import api from './api'

export const updateAttendanceForm = async (id, data) => {
  if (!id) return
  try {
    await api.updateAttendanceForm(id, data)
  } catch (err) {
    Vue.prototype.$toast.error(err.message)
  }
}