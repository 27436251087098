import Vue from 'vue'
import { getCurrentClinic } from '../../utils/localStorageManager'

const setChannel = async(context, payload)  => {
  context.commit('setChannel', payload)
}

const unsubscribe = async(context)  => {
  const clinic = getCurrentClinic()
  Vue.prototype.pusher.unsubscribe(`clinic-${clinic.id}-tab-${window.sessionStorage.getItem('tabId')}`)
  // Vue.prototype.pusher.unsubscribe(`clinic-${clinic.id}`)
  context.commit('setChannel', null)
}

export default {
  setChannel,
  unsubscribe,
}
