
const setCurrentCallPanelPassword = (state, payload) => {
  state.callPanelPassword = payload
}
const setDepartment = (state, payload) => {
  state.department = payload
}
const setListener = (state, payload) => {
  state.listener = payload
}
const setDepartments = (state, payload) => {
  state.departments = payload
}
const setListeners = (state, payload) => {
  state.listeners = payload
}
const setNextPasswords = (state, payload) => {
  state.nextPasswords = payload
}

export default {
  setCurrentCallPanelPassword,
  setDepartment,
  setListener,
  setDepartments,
  setListeners,
  setNextPasswords,
}
