import { track } from '@/utils/mixPanel'
import { getCurrentClinic } from '@/utils/localStorageManager'

export const trackFinishedAttendanceEvents = (attendance, form, { attendanceId, toPrint, toSign }) => {
  const clinic = getCurrentClinic();
  trackAttendanceWithMedications(clinic, attendanceId, form)
  trackAttendanceWithBrandRecommendation(clinic, attendanceId, form)
  trackSignedDocuments(clinic, attendanceId, toSign)
  trackPrintedDocuments(clinic, attendanceId, toPrint)
}

const trackAttendanceWithMedications = (clinic, attendanceId, form) => {
  if (!form.prescricaoMedicamentosaV2.value.length) return
  track('consulta_com_medicacoes', {
    clinic: clinic.name,
    distinct: attendanceId,
  })
}

const trackAttendanceWithBrandRecommendation = (clinic, attendanceId, form) => {
  if (!form.oculos.fields.marcaRecomendada) return
  track('oculos_marca_recomendada', {
    clinic: clinic.name,
    brand: form.oculos.fields.marcaRecomendada,
    distinct: attendanceId,
  })
}

const trackSignedDocuments = (clinic, attendanceId, toSign) => {
  if (!toSign.length) return
  toSign.map(type => {
    type !== 0 &&
    track('signed_document', {
      clinic: clinic.name,
      distinct: attendanceId,
      type,
    })
  })
}

const trackPrintedDocuments = (clinic, attendanceId, toPrint) => {
  if (!toPrint.length) return
  toPrint.map(type => {
    track('printed_document', {
      clinic: clinic.name,
      distinct: attendanceId,
      type,
    })
  })
}
