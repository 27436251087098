<template>
  <div>
    <section class="header">
      <div class="title-container">
        <h2>Configurações</h2>
        <span id="help-icon" class="icon-box">
          <HelpCircle class="icon stroke" />
        </span>
        <b-tooltip target="help-icon" placement="bottom">
          Configurações do painel de chamada
        </b-tooltip>
      </div>
    </section>
    <section >
      <b-form-group class="form-group">
        <label for="mensagem">
          Mensagem a ser mostrada no painel de chamadas
        </label>
        <b-form-input
          id="mensagem"
          v-model="form.panel_message"
          placeholder="Descrever"
        ></b-form-input>
      </b-form-group>

      <!-- configuration to show patient birthdate on call panel -->
      <b-form-group class="form-group">
        <div class="d-flex flex-wrap">
          <label for="show-birthday">
            Exibir data de nascimento do paciente no painel de chamada
          </label>
          <b-form-checkbox
          class="ml-2"
          switch
          id="show-birthday"
          :value="1"
          v-model="form.show_birthday"
          ></b-form-checkbox>
        </div>
      </b-form-group>

      <!-- <section class="header">
        <div class="title-container">
          <h2>Configurações de fala</h2>
          <span id="help-icon-spetch" class="icon-box">
            <HelpCircle class="icon stroke" />
          </span>
          <b-tooltip target="help-icon-spetch" placement="bottom">
            Configurações de fala no painel de chamada
          </b-tooltip>
        </div>
      </section> -->

      <!-- <b-form-group class="form-group">
        <label>Volume</label>
        <b-form-input
          type="range"
          min="0"
          max="1"
          v-model="form.volume"
        ></b-form-input>
      </b-form-group>

      <b-form-group class="form-group">
        <label>Velocidade</label>
        <b-form-input
          type="range"
          min="0"
          max="10"
          step="1"
          v-model="form.rate"
        ></b-form-input>
      </b-form-group>

      <b-form-group class="form-group">
        <label>Tom</label>
        <b-form-input
          type="range"
          min="0"
          max="2"
          v-model="form.pitch"
        ></b-form-input>
      </b-form-group> -->


      <div class="btn-container">
        <b-button
          variant="primary"
          @click="save"
        >
          Salvar configuração
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import HelpCircle from '@/assets/icons/help-circle.svg'
// import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  components: {
    HelpCircle,
    // 'v-chevron-down': ChevronDown,
  },
  data(){
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      form: {
        id: null,
        volume: 1,
        rate: 5,
        pitch: 1,
        panel_message: null,
        show_birthday: true
      }
    }
  },
  async mounted(){
    await this.getCallPanelSettings()
  },
  methods: {
    async getCallPanelSettings(){
      const loading = this.$loading.show();
      try {
        const response = await this.api.getCallPanelSettings(this.clinic.id)
        this.form = response.data
      } catch ( err ) {
        this.$toast.error(err.message);
      } finally {
        loading.hide();
      }
    },
    validateForm(){
      let valid = true
      if( !this.form.panel_message )
        valid = false
      this.validForm = valid
      return valid
    },
    async save(){
      if ( !this.validForm ) return
      const loading = this.$loading.show();

      try {
        if( this.form.id ){
          await this.api.updateCallPanelSettings(this.form.id, this.form)
        }
        else {
          this.form.clinic_id = this.clinic.id
          await this.api.createCallPanelSettings(this.form)
        }
        this.$toast.success('Alterações salvas com sucesso!');
      } catch ( err ){
        this.$toast.error(err.message);
      } finally {
        loading.hide();
      }
    }
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        this.validateForm()
      },
      deep: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 30px 0;

    .title-container {
      display: flex;
      align-items: center;
      h2 {
        font-family: 'Nunito Sans';
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 0;
        color: var(--type-active);

      }

      .icon {
        height: 24px;
        width: 24px;
        margin-left: 5px;
        stroke: var(--neutral-500);
      }
    }
  }

.config-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.form-group {
  width: 100%;
  div {
    margin-bottom: 25px;
  }
}

.btn-container {
  width: 100%;
  display: flex;
  justify-content: end;
}
</style>
