<template>
  <div class="academy-course-container">
    <v-breadcrumbs
      :options="[
        { label: 'Eyecare Academy', onClick: () => this.$router.push('/academy') },
        { label: course ? course.name : '' },
      ]"
    />
    <div class="content">
      <v-video-menu
        :course="course"
        :progress="progress"
        @selectVideoClass="selectVideoClass"
      />
      <v-video-content
        :user="user"
        :course="course"
        :progress="progress"
        :goTo="goTo"
      />
    </div>
  </div>
</template>
<script>
import { getCurrentUser } from '@/utils/localStorageManager'
import Breadcrumbs from '@/components/General/Breadcrumbs'
import VideoMenu from '@/components/Academy/VideoMenu'
import VideoContent from '@/components/Academy/VideoContent'
export default {
  components: {
    'v-breadcrumbs': Breadcrumbs,
    'v-video-menu': VideoMenu,
    'v-video-content': VideoContent
  },
  async mounted() {
    await this.getProgressByCourse()
    await this.getCourse()

    this.introJs()
      .setOption('tooltipClass', 'onboarding')
      .setOption('dontShowAgain', true)
      .setOption('dontShowAgainLabel', 'Não mostrar novamente')
      .setOption('nextLabel', 'Próximo')
      .setOption('prevLabel', 'Anterior')
      .setOption('doneLabel', 'Finalizar')
      .setOptions({
        steps: [
          {
            title: 'Menu lateral',
            intro: 'No menu lateral você poderá visualizar os módulos, aulas e o seu progresso, além de buscar por uma aula de forma rápida.',
            element: document.querySelector('#menu'),
          },
          {
            title: 'Vídeo',
            intro: 'Assista à aula, visualize a descrição e referências utilizadas durante a aula.',
            element: document.querySelector('#video'),
          },
          {
            title: 'Comentários',
            intro: 'Por fim, você pode nos dar feedbacks sobre a aula e interagir com nossa comunidade de oftalmologistas por meio da sessão de comentários.',
            element: document.querySelector('#comment'),
          },
        ]
      })
      .start();
  },
  data() {
    return {
      user: getCurrentUser(),
      course: null,
      progress: [],
    }
  },
  methods: {
    selectVideoClass({ moduleId, videoClassId }) {
      const course = { ...this.course }
      course.modules.map(m => m.video_classes.map(v => {
        v.selected = m.id === moduleId && v.id === videoClassId
      }))
      this.course = course;
    },
    async getProgressByCourse() {
      try {
        const res = await this.api.getProgressByCourse(this.$route.params.courseId, this.user.id)
        this.progress = res.data
      } catch (err) {
        console.log('getProgressByCourse ERR: ', err)
      }
    },
    async getCourse() {
      const loading = this.$loading.show()
      try {
        const res = await this.api.showCourse(this.$route.params.courseId)
        res.data.modules[0] && res.data.modules[0].video_classes[0] && (res.data.modules[0].video_classes[0].selected = true)
        this.course = res.data;
        loading.hide()
      } catch(err) {
        loading.hide()
        this.$toast.error(err.message)
      }
    },
    goTo(action) {
      const target = action === 'previous' ? -1 : 1
      const videoClasses = this.course.modules.reduce((acc, module) => {
        acc = acc.concat(module.video_classes.map(videoClass => videoClass))
        return acc
      }, [])

      videoClasses.every((element, index) => {
        if (element.selected) {
          const tartedClass = videoClasses[(index + target)]
          tartedClass && this.selectVideoClass({
            moduleId: tartedClass.module_id,
            videoClassId: tartedClass.id
          })
          return false
        }
        return true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .academy-course-container {
    padding: 24px;
    background: var(--neutral-100);
    min-height: 80vh;

    .content {
      width: 100%;
      display: flex;
      gap: 10px;
    }
  }
</style>
