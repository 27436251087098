import Api from "@/utils/api";
import moment from "moment";
export default {
	namespaced: true,
	state: {
		clinic: JSON.parse(localStorage.getItem('clinic')),
		data_loaded: false,
		on_gateway: undefined,
		subscription_id: undefined,
		users: [],
		invoices: [],
		subscription: {
			plan: 'Carregando...',
			start_at: 'Carregando...',
			seats: '-',
			users: '-',
			payment_method: 'Carregando...',
			next_billing_at: 'Carregando...'
		}
	},

	getters: {
		planSubscribe: (state) => state.on_gateway?.plan,
		nextBilling: (state) => state.on_gateway?.next_billing_at ? moment(state.on_gateway.next_billing_at).format('DD/MM/YYYY') : '',
		createAt: (state) => state.on_gateway?.created_at ? moment(state.on_gateway?.created_at).format('DD/MM/YYYY') : '',
		paymentMethod: (state) => state.on_gateway?.payment_method,
		seats: (state) => state.on_gateway?.product_items[0]?.quantity,
		currentUsers: (state) => state.users.length
	},

	mutations: {
		// eslint-disable-next-line no-return-assign
		setSubscription: (state, payload) => state.subscription = payload,

		// eslint-disable-next-line no-return-assign
		setLoaded: (state, payload) => state.data_loaded = payload,

		// eslint-disable-next-line no-return-assign
		setResponseGateway: (state, payload) => state.on_gateway = payload,

		// eslint-disable-next-line no-return-assign
		setSubscriptionId: (state, payload) => state.subscription_id = payload,

		// eslint-disable-next-line no-return-assign
		setUsers: (state, payload) => state.users = payload,

		// eslint-disable-next-line no-return-assign
		setInvoices: (state, payload) => state.invoices = payload
	},

	actions: {
		async getSubscription({commit, state}) {
			const clinic = state.clinic
			const response = (await Api.getSubscription(clinic.id)).data
			const users = (await Api.showClinicProfessionals(clinic.id)).data

			// eslint-disable-next-line camelcase
			const {on_gateway, subscription_id} = response

			commit('setResponseGateway', on_gateway)
			commit('setSubscriptionId', subscription_id)
			commit('setUsers', users)

			return response
		},

		async getInvoices({commit, state}, subscriptionId) {
			const clinic = state.clinic
			const response = (await Api.getSubscriptionInvoices(clinic.id, subscriptionId)).data

			commit('setInvoices', response)
			return response
		}
	}
}
