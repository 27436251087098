import Vue from 'vue'
import Vuex from 'vuex'

import mutations from './mutations'
import getters from './getters'
import actions from './actions'

Vue.use(Vuex)
const store = {
  namespaced: true,
  state: () => ({
    CNPJForm: null,
    isEdit: false,
    showCNPJForm: false,
    nfModalData: {},
    nfTableData: {},
    isPatientDisabled: false,
    providerModalSelected: {},
    isScheduledInvoice: false,
    companies: [],
  }),
  mutations,
  getters,
  actions
}

export default store
