<!--- View de redefinição de senha - Responsável pela redefinição de senha dos usuários. --->

<template>
  <b-container class="forgot-password text-center" fluid>
    <b-row align-h="center" align-v="center">
      <b-col cols="auto">
        <div class="w-400px forgot-password__content">
          <a href="/login"><img src="@/assets/images/eyecare-logo-colored.png" class="logo"></a>

          <p class="title">Redefinição de senha</p>
          <p class="description">Digite seu email de login para iniciar o processo de redefinição de senha.</p>

          <validation-observer ref="forgotPasswordForm" v-slot="{ invalid }" v-if="sended === null">
            <div class="form-group text-left">
              <label for="email">Email de login</label>
              <validation-provider name="e-mail" :rules="{ required: true, email: true }" v-slot="{ errors, dirty }">
                <input autocomplete="off" id="email" type="text" v-model="email" class="form-control" placeholder="Digite seu email" :class="{ 'error': email.length > 0 && errors.length > 0 && dirty }">
              </validation-provider>
            </div>

            <b-button
              block variant="primary"
              class="action-button"
              :disabled="invalid"
              @click="doForgotPassword"
            >
              Enviar link para redefinição
            </b-button>
          </validation-observer>
        </div>

        <p class="success-message" v-if="sended === true">
          <Check class="icon"/>
          Enviaremos um link de redefinição de senha para o email {{ email }} caso ele seja um usuário da Eyecare.
        </p>
        <p class="error-message" v-if="sended === false">
          Não identificamos o email {{ email }} como um de nossos usuários!
        </p>
        <div class="w-400px cta__footer">
          <p class="text-center mtb-48" v-if="sended === true">Já redefiniu sua senha? <router-link to="/login" tag="span" class="link">Faça login</router-link></p>
          <p class="text-center mtb-48" v-if="sended === false">Entre em contato conosco e <router-link to='/solicitar-demonstracao' tag="span" class="link">solicite uma demonstração.</router-link></p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Check from '@/assets/icons/check.svg'

export default {
  name: 'Login',
  components: {
    Check
  },
  data () {
    return {
      email: '',
      sended: null
    }
  },
  methods: {
    doForgotPassword () {
      const isLoading = this.$loading.show()
      this.api.forgotPassword({ email: this.email }).then(res => {
        if (res.data === 'found') {
          this.sended = true
        }
        else if(res.data === 'not found') {
          this.sended = false
        }
      }).catch(err => {
        console.log('Err', err)
      }).finally(() => {
        isLoading.hide()
      })
    }
  }
}
</script>

<style lang="scss">
  .forgot-password {
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;
    text-align: center;

    .logo {
      width: 123px;
      height: 24px;
      margin: 40px 0 50px;
    }

    .w-400px {
      width: 400px;
      margin: 0 auto;
    }

    .title {
      font-family: 'Red Hat Display';
      font-weight: 700;
      font-size: 32px;
      line-height: 40.32px;
      color: var(--dark-blue);
      margin-bottom: 38px;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--type-active);
      margin-bottom: 48px;
    }

    .form-group {
      margin-bottom: 2rem;
    }

    .mtb-48 {
      margin-top: 48px;
      margin-bottom: 48px;
    }

    .success-message {
      width: 550px;
      background-color: var(--states-success-soft-green);
      border-radius: 8px;
      padding: 16px 26px;
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      margin: 4px 0 40px;

      .icon {
        width: 24px;
        height: 24px;
        margin-right: 24px;
        fill: var(--states-success);
      }
    }

    .error-message {
      background-color: #ffeae8;
      border-radius: 8px;
      padding: 16px 26px;
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      margin: 4px 0 40px;
      max-width: 400px;
    }

    .cta__footer {
      max-width: 400px;
      width: 75%;
    }

    @media (max-width: 768px) {
      .forgot-password__content {
        .action-button {
          font-size: 14px;
        }
      }

      .success-message, .error-message {
        width: 75%;
        margin: 0 auto;
        align-items: center;

        .icon {
          margin: 0;
          width: 40%;
        }
      }
    }

    @media (max-width: 425px) {
      .forgot-password__content {
        width: 75%;
        padding: 1rem;

        .title {
          font-size: 1.5rem;
        }

        .form-group label {
          font-size: 14px;
        }

        .action-button {
          font-size: 14px;
        }
      }

      .success-message, .error-message {
        width: 75%;
        margin: 0 auto;
        align-items: center;

        .icon {
          margin: 0;
          width: 30%;
        }
      }

      .cta__footer {
        width: 75%;
        font-size: 14px;
      }
    }

    @media (max-width: 320px) {
      .forgot-password__content {
        width: 90%;
        padding: 1rem;  

        .title {
          font-size: 22px;
        }

        .description {
          font-size: 14px;
          margin: 0 auto 32px;
        }

        .form-group label {
          font-size: 14px;
        }

        .action-button {
          font-size: 14px;
        }
      }

      .success-message, .error-message {
        width: 90%;
        align-items: center;
        margin: 0 auto;

        .icon {
          margin: 0;
          width: 100%;
        }
      }

      .cta__footer {
        width: 90%;
        font-size: 14px;
      }
    }
  }
</style>
