<template>
  <div class="pt-header">
    <div class="pt-title">
      {{ title }}
      <span v-if="tooltip" :id="tooltipId" class="icon-box no-print">
        <v-help-circle class="icon stroke" />
      </span>

      <b-tooltip
        v-if="tooltip"
        :target="tooltipId"
        class="no-print"
        placement="bottom"
      >
        <span v-html="tooltip" />
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import HelpCircle from '@/assets/icons/help-circle.svg'

export default {
  name: 'TabHeaderInfo',
  props: {
    title: {
      required: true,
      type: String
    },
    tooltip: String,
    print: Boolean,
    tooltipId: {
      type: String,
      default: 'help-icon'
    }
  },
  components: {
    'v-help-circle': HelpCircle
  },
}
</script>

<style lang="scss" scoped>
.pt-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 30px 0;

  .pt-title {
    display: inline-flex;
    align-items: center;
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: min(2.6vw, 18px);
  }

  .gap-action-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .icon {
    height: 24px;
    width: 24px;
    margin-left: 5px;
    stroke: var(--neutral-500);
  }
}
</style>
