/* eslint-disable */
import { base, baseXlsx } from '@/utils/api'

const ENDPOINT = '/onlending'

export default {
    getOnlendingByBeneficiaryId(currentPage, data) {
        return base.get(`${ENDPOINT}/getByBeneficiaryId?page=${currentPage}`, {
            params: {...data}
        })
    },
    getOnlendingResume(data) {
        return base.get(`${ENDPOINT}/getResumes`, {
            params: {...data}
        })
    },

    generateOnlendingByClinicId(clinicId) {
        return base.get(`${ENDPOINT}/generateOnlendingByClinicId/${clinicId}`)
    },

    getBeneficiariesByProfile(profileId, itemId) {
        const config = {
          params: { profileId, itemId }
        }
        return base.get(`onlending/getBeneficiariesByProfile`, config)
    },
  
    getBeneficiaryFromAppointmentItems(clinicId, professionalId, itemIds) {
        return base.get(`${ENDPOINT}/beneficiary-from-appointment-items`, {
            params: { clinicId, professionalId, itemIds }
        })
    },
    getOnlendinClinicSettingsByClinicId(clinicId) {
        return base.get(`${ENDPOINT}/clinic-settings/${clinicId}/getByClinicId`)
    },
    createOnlendinClinicSettings(data) {
        return base.post(`${ENDPOINT}/clinic-settings`, data)
    },
    updateOnlendinClinicSettings(id, data) {
        return base.put(`${ENDPOINT}/clinic-settings/${id}`, data)
    },

    getOnlendingProfilesByClinicId(clinicId) {
        return base.get(`${ENDPOINT}/profile/${clinicId}/getAllByClinicId`)
    },
    createOnlendingProfile(data) {
        return base.post(`${ENDPOINT}/profile`, data)
    },
    updateOnlendingProfile(id, data) {
        return base.put(`${ENDPOINT}/profile/${id}`, data)
    },
    deleteOnlendingProfile(id) {
        return base.delete(`${ENDPOINT}/profile/${id}`)
    },

    getOnlendingRulesByClinicId(data) {
        const config = {
            params: {...data}
        }
        return base.get(`${ENDPOINT}/rule/getAllByClinicId`, config)
    },
    getOnlendingRulesForFilterByClinicId(clinicId) {
        return base.get(`${ENDPOINT}/rule/${clinicId}/getForFilterByClinicId`)
    },
    getOnlendingRulesByItemAndBeneficiary(data) {
        return base.post(`${ENDPOINT}/rule/getAllByItemAndBeneficiary`, data)
    },
    getOnlendingRule(id) {
        return base.get(`${ENDPOINT}/rule/${id}`)
    },
    createOnlendingRule(data) {
        return base.post(`${ENDPOINT}/rule`, data)
    },
    updateOnlendingRule(id, data) {
        return base.put(`${ENDPOINT}/rule/${id}`, data)
    },

    updateOnlendingRuleSpecification(id, data) {
        return base.put(`${ENDPOINT}/rule/specification/${id}`, data)
    },

    getOnlendingRuleDefinitionsByRule(ruleId) {
        return base.get(`${ENDPOINT}/rule/${ruleId}/definitions`)
    },
    createOnlendingRuleDefinitions(data) {
        return base.post(`${ENDPOINT}/rule/definition/storeMany`, data)
    },
    updateOnlendingRuleDefinitions(data) {
        return base.post(`${ENDPOINT}/rule/definition/updateMany`, data)
    },
    deleteOnlendingRuleDefinition(id) {
        return base.delete(`${ENDPOINT}/rule/definition/${id}`)
    },

    createOnlendingBeneficiaries(data) {
        return base.post(`${ENDPOINT}/beneficiary/storeMany`, data)
    },
    deleteOnlendingBeneficiary(id) {
        return base.delete(`${ENDPOINT}/beneficiary/${id}`)
    },

    getItemsAndTypes(data) {
    const config = {
        params: {...data}
    }
        return base.get(`${ENDPOINT}/item/getItemsAndTypes`, config)
    },
    createOnlendingItems(data) {
        return base.post(`${ENDPOINT}/item/storeMany`, data)
    },
    deleteOnlendingItem(id) {
        return base.delete(`${ENDPOINT}/item/${id}`)
    },

    createOnlendingClinicHealthPlans(data) {
        return base.post(`${ENDPOINT}/health-plan/storeMany`, data)
    },
    deleteOnlendingClinicHealthPlan(id) {
        return base.delete(`${ENDPOINT}/health-plans/${id}`)
    },

    getOnlendingsByIds(currentPage, data) {
        return base.post(`${ENDPOINT}/getByIds?page=${currentPage}`, data)
    },
    getOnlendingsByExtractId(currentPage, data) {
        return base.post(`${ENDPOINT}/getByExtractId?page=${currentPage}`, data)
    },
    getOnlendingsSumByIds(data) {
        return base.post(`${ENDPOINT}/getSumByIds`, data)
    },
    updateOnlending(id, data) {
        return base.put(`${ENDPOINT}/${id}`, data)
    },
    updateOnlendingByIds(data) {
        return base.post(`${ENDPOINT}/massUpdate`, data)
    },

    getOnlendingsByBillId(billId) {
        return base.get(`${ENDPOINT}/${billId}/getByBIllId`)
    },
    getOnlendingExtractsByClinicId(currentPage, data) {
        const config = {
            params: {...data}
        }
        return base.get(`${ENDPOINT}/extract/getByClinicId?page=${currentPage}`, config)
    },
    getOnlendingExtractsByExtractsId(data) {
        const config = {
            params: {...data}
        }
        return base.get(`${ENDPOINT}/extract/getByExtractId`, config)
    },
    // syncPrintOnlendingExtract(data) {
    //     const config = {
    //         params: { ...data, tabId: window.sessionStorage.getItem('tabId') }
    //       }
    //       return baseXlsx.get(`${ENDPOINT}/extract/print`,config);
    // },
    asyncPrintOnlendingExtract(data) {
        const config = {
            params: { ...data, tabId: window.sessionStorage.getItem('tabId') }
        }
        return base.get(`${ENDPOINT}/extract/print`,config);
    },

    getOnlendingExtractsByExtractsId(data) {
        const config = {
            params: {...data}
        }
        return base.get(`${ENDPOINT}/extract/getByExtractId`, config)
    },
    createOnlendingExtract(data) {
        return base.post(`${ENDPOINT}/extract`, data)
    },
    updateOnlendingExtract(id, data) {
        return base.put(`${ENDPOINT}/extract/${id}`, data)
    },
    changeOnlendingExtractStatus(data) {
        return base.post(`${ENDPOINT}/extract/changeStatus`, data)
    },
    deleteOnlendingExtract(id) {
        return base.delete(`${ENDPOINT}/extract/${id}`)
    },

    createOnlendingExtractFile(data) {
        return baseUpload.post(`${ENDPOINT}/extract/file`, data)
    },
    updateOnlendingExtracFile(data) {
        return baseUpload.post(`${ENDPOINT}/extract/file/update`, data)
    },
    deleteOnlendingExtractFile(id) {
        return base.delete(`${ENDPOINT}/extract/file/${id}`)
    },
    //FIM REPASSE MÈDICO(ONLENDING)    
}