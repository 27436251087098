const moduleRoute = {
  path: '/apps',
  component: () => import('@/views/Home.vue'),
  children: [
    {
      path: '',
      component: () => import('./views/Home.vue'),
      children: []
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
