import { updateAttendanceForm } from '@/utils/attendanceFormHelper'

const store = {
  namespaced: true,
  state: () => ({
    id: 'bv-triagem',
    name: 'Triagem',
    opened: true,
    showing: true,
    completed: false,
    multiple: false,
    scopes: ['ATTENDANCE', 'PRE_CONSULTATION', 'SURGERY', 'BV_EXAM'],
    fields: {
      lastVisitInMonths: null,
      pathologiesUnderTreatment: null,
      usesContinuousMeds: null,
      eyeDiscomfort: null,
      eyeConditions: null,
      glassesOrLenses: null,
      hasLastPrescription: null,
      informDegree: null,
      hasEPI: null,
      epiHasDegreeCorrection: null,
      hasEPISameDegreeAsGlasses: null,
      informDegreeEPI: null,
      esferico_E: null,
      esferico_D: null,
      cilindrico_D: null,
      eixo_D: null,
      // EPIesferico_E: null,
      // EPIcilindrico_E: null,
      // EPIeixo_E: null,
      // EPIesferico_D: null,
      // EPIcilindrico_D: null,
      // EPIeixo_D: null,
      medicinesList: [],
      seeWellWithoutGlasses: null,
      alreadyHadPrescription: null,
      canInformPrescriptionDegree: null
    }
  }),
  mutations: {
    handleProps: (state, { key, value }) => {
      state[key] = value
    },
    handleFields: (state, { key, value }) => {
      state.fields[key] = value
    },
    checkComplete: state => {
      state.completed = Object.keys(state.fields).some(key => state.fields[key])
    }
  },
  getters: {},
  actions: {
    handleProps(context, { key, value }) {
      context.commit('handleProps', { key, value })
      
      if(context?.rootState?.attendance?.attendance?.id && ['showing' , 'opened'].includes(key)){
        const form = context?.rootGetters['attendance/form/getSelectedForm']
        const forms = form.forms
        const index = forms?.findIndex(form => {
          return form.id === context.state.id
        })
  
        if(index !== -1){
          forms[index][key] = value
          form.forms = forms
          updateAttendanceForm(context?.rootState?.attendance?.attendance?.attendance_form?.id, {forms: forms})
          context.dispatch(`attendance/form/updateAttendanceForms`, form, { root: true })
        }
      }
    },
    handleFields(context, { key, value }) {
      context.commit('handleFields', { key, value })
      context.commit('checkComplete')
    }
  },
}

export default store
