import Vue from "vue"
import { updateAttendanceForm } from '@/utils/attendanceFormHelper'

const store = {
  namespaced: true,
  state: () => ({
    id: 'tonometria',
    name: 'Tonometria',
    opened: false,
    showing: false,
    completed: false,
    multiple: false,
    scopes: ['PRE_CONSULTATION'],
    fields: {
      aparelho: 'Tonômetro de sopro',
      horario: null,
      olhoDireito: null,
      olhoEsquerdo: null
    }
  }),
  mutations: {
    handleProps: (state, { key, value }) => {
      state[key] = value
    },
    handleFields: (state, { key, value }) => {
      Vue.prototype.$set(state.fields, key, value)
    },
    checkComplete: state => {
      state.completed = (!!state.fields.olhoDireito || !!state.fields.olhoEsquerdo)
    }
  },
  getters: {},
  actions: {
    handleProps(context, { key, value }) {
      context.commit('handleProps', { key, value })

      if(context?.rootState?.attendance?.attendance?.id && ['showing' , 'opened'].includes(key)){
        const form = context?.rootGetters['attendance/form/getSelectedForm']
        const forms = form.forms
        const index = forms?.findIndex(form => {
          return form.id === context.state.id
        })
  
        if(index && index !== -1){
          forms[index][key] = value
          form.forms = forms
          updateAttendanceForm(context?.rootState?.attendance?.attendance?.attendance_form?.id, {forms: forms})
          context.dispatch(`attendance/form/updateAttendanceForms`, form, { root: true })
        }
      }
    },
    handleFields(context, { key, value }) {
      context.commit('handleFields', { key, value })
      context.commit('checkComplete')
    }
  },
}

export default store
