import moment from 'moment'

const certificate = () => {
  let auxCert = JSON.parse(localStorage.getItem('singItemAutentic'))
  if (auxCert !== undefined && auxCert !== null) {
    auxCert.cert.validityEnd = moment(auxCert.cert.validityEnd).format('DD/MM/YYYY')
  } else {
    auxCert = { active: 'inative' }
  }
  return auxCert
}

const showTitle = (state) => (title) => {
  const formNames = state.attendance.menu.groups[title]
  return formNames.some(formName => state.attendance.form[formName].showing)
}

export default {
  certificate,
  showTitle
}
