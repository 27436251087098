const updateBatches = (state, payload) => {
  state.batches = payload
}

const updateSelectedRows = (state, payload) => {
  state.selectedRows = payload
}

const reloadContent = (state, payload) => {
  state.reloadContent = payload
}

const setHealthPlanFields = (state, fields) => {
  state.healthPlanFields = fields
}

export default {
  updateBatches,
  updateSelectedRows,
  reloadContent,
  setHealthPlanFields
}
