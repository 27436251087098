import api from '@tables/api'

const searchTables = (context, props) => {
    context.commit('setLoading', true)
    api.getHealthPlanTables(props.clinicHealthPlanId, props.query, props.types)
        .then(({ data }) => {
            const tables = data.filter(el => !this.clinicHealthPlanTables.some(clinicHealthPlanTable => clinicHealthPlanTable.table_id === el.id));
            context.commit('setTables', tables)
        })
        .catch(error => this.$toast.error(error.message))
        .finally(() => context.commit('setLoading', true));
}

export default {
    searchTables,
}
