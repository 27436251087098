import Vue from "vue"
import { updateAttendanceForm } from '@/utils/attendanceFormHelper'

const store = {
  namespaced: true,
  state: () => ({
    id: 'curva-tensional',
    name: 'Curva tensional',
    opened: false,
    showing: false,
    completed: false,
    multiple: false,
    scopes: ['ATTENDANCE', 'SURGERY', 'EXAM'],
    fields: {
      aparelho: null,
      laudo: null,
      value: [],
    }
  }),
  mutations: {
    handleProps: (state, { key, value }) => {
      state[key] = value
    },
    handleFields: (state, { key, value }) => {
      state.fields[key] = value
    },
    handleFieldValue: (state, { key, value, index }) => {
      let i = index
      if (!state.fields.value[i]) {
        state.fields.value.push({ hora: null, direito: null, esquerdo: null })
        i =  state.fields.value.length -1
      }
      Vue.prototype.$set(state.fields.value[i], key, value)
    },
    checkComplete: state => {
      const hasCompletedField = state.fields.value.some(val => {
        return val?.hora || val?.direito || val?.esquerdo
      })
      
      state.completed = state.fields?.aparelho || hasCompletedField || state.fields?.laudo
    },
    addValue: state => {
      state.fields.value.push({ hora: null, direito: null, esquerdo: null })
    },
    removeValue: (state, index) => {
      state.fields.value.splice(index, 1)
    },
    getDefaultValueFields: state => {
      state.fields.value = [
        { hora: null, direito: null, esquerdo: null },
        { hora: null, direito: null, esquerdo: null },
      ]
    }
  },
  getters: {},
  actions: {
    handleProps(context, { key, value }) {
      context.commit('handleProps', { key, value })
      if(context?.rootState?.attendance?.attendance?.id && ['showing' , 'opened'].includes(key)){
        const form = context?.rootGetters['attendance/form/getSelectedForm']
        const forms = form.forms
        const index = forms?.findIndex(form => {
          return form.id === context.state.id
        })
  
        if(index && index !== -1){
          forms[index][key] = value
          form.forms = forms
          updateAttendanceForm(context?.rootState?.attendance?.attendance?.attendance_form?.id, {forms: forms})
          context.dispatch(`attendance/form/updateAttendanceForms`, form, { root: true })
        }
      }
    },
    handleFields(context, { key, value }) {
      context.commit('handleFields', { key, value })
      context.commit('checkComplete')
    },
    handleFieldValue(context, { key, value, index }) {
      context.commit('handleFieldValue', { key, value, index })
      context.commit('checkComplete')
    },
    addValue(context) {
      context.commit('addValue')
      context.commit('checkComplete')
    },
    removeValue(context, index) {
      context.commit('removeValue', index)
      context.commit('checkComplete')
    },
    getDefaultValueFields (context) {
      context.commit('getDefaultValueFields')
    }
  },
}

export default store
