import Vue from 'vue'
import { getCurrentClinic } from '@/utils/localStorageManager'

const getHistoryItemsFromPatient = async (context, patientId) => {
  const clinic = getCurrentClinic()
  context.commit('setLoading', true)

  if(!patientId)
    return;

  Vue.prototype.api.getHistoryItemsFromPatient(context.state.page, clinic.id, patientId)
    .then(({ data }) => {
      context.commit('setHistoryItems', data)
    })
    .catch(err => Vue.prototype.$toast.error(err.message))
    .finally(() => {
      context.commit('setLoading', false)
    })
}

const changePage = (context, page) => {
  context.commit('changePage', page)
}

const changeCheckedItems = (context, checkedItems) => {
  context.commit('setCheckedItems', checkedItems)
}

export default {
  getHistoryItemsFromPatient,
  changeCheckedItems,
  changePage,
}
