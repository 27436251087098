<template>
  <div class="custom-appointment">
    <div class="breadcrumbs">
      <div>
        <router-link to="/registros-padrao/registros" tag="span" class="link">
          Registros padrões
        </router-link>
        <ChevronRight class="chevron" />
        <router-link :to="`/registro-padrao/${form.id}`" tag="span">
          {{ getTitleByType }}
        </router-link>
      </div>
    </div>
    <div class="container-data">
      <p class="alert">
        <InfoCircle class="icon-info" />
        Os registros padrões serão salvos automaticamente e você poderá usá-los
        dentro de sua consulta.
      </p>
      <p class="title">{{ getTitleByType }}</p>

      <div class="form-group" v-for="(field, index) of formValue" :key="index">
        <label class="position" for="fields">{{ field.title }}</label>

        <b-row>
          <b-col cols="6">
            <b-row>
              <b-col cols="6">
                <div class="form-group mb-0">
                  <div class="input-eye">
                    <p class="tag-eye"><EyeFill /> D</p>
                    <b-input
                      autocomplete="off"
                      :readonly="true"
                      id="biometria-direito"
                      placeholder="Resultado"
                    />
                  </div>
                </div>
              </b-col>
              <b-col cols="6">
                <div class="form-group mb-0">
                  <div class="input-eye">
                    <p class="tag-eye"><EyeFill /> E</p>
                    <b-input
                      autocomplete="off"
                      :readonly="true"
                      id="biometria-esquerdo"
                      placeholder="Resultado"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-row class="padding-row">
            <div v-if="canEdit" class="new-medical-report">
              <Icon
                tooltip="Deletar"
                v-if="field.title != 'AL' && field.title != 'ACD'"
              >
                <Trash
                  v-can="'ConfConReg3'"
                  class="icon trash"
                  @click="removeFields(field)"
                />
              </Icon>
            </div>
          </b-row>
        </b-row>
      </div>
      <b-row class="form-add">
        <b-col cols="12" v-if="!addNew">
          <b-row>
            <Icon tooltip="Adicionar novo campo">
              <button class="blue-underline" @click="addNew = true">
                <Plus v-can="'ConfConReg2'" class="icon" />
                Adicionar novo campo
              </button>
            </Icon>
          </b-row>
        </b-col>
        <b-row style="width: 100%" v-else>
          <b-col cols="6">
            <div class="form-group">
              <validation-provider
                name="lentes"
                :rules="{ required: false }"
                v-slot="{ errors, touched }"
              >
                <multiselect
                  v-model="selectedTitle"
                  :options="titles"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  :allowEmpty="false"
                  placeholder="Buscar e selecionar lente "
                  class="with-border"
                  :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="noOptions"> Nenhuma opção </template>
                  <template slot="noResult"> Nenhum resultado </template>
                  <template slot="noResult" slot-scope="props">
                    <li @click="addNewField(props.search)">
                      <div class="multiselect__option custom-item">
                        <Plus class="icon" />
                        <span class="highlighted">
                          Adicionar lente personalizada
                          {{ props.search ? `"${props.search}"` : '' }}
                        </span>
                      </div>
                    </li>
                  </template>
                </multiselect>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="2">
            <b-button
              block
              variant="primary"
              class="h-38"
              :disabled="!canEdit"
              @click="addNewField(selectedTitle)"
            >
              Adicionar
            </b-button>
          </b-col>
        </b-row>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getCurrentUser } from '@/utils/localStorageManager'
import standardRecordMixin from '@/mixins/StandardRecords/standardRecordMixin'

export default {
  name: 'StandardRecord',
  props: {
    readonly: Boolean
  },
  components: {
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    InfoCircle: () => import('@/assets/icons/info-circle.svg'),
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Icon: () => import('@/components/General/Icon'),
    Plus: () => import('@/assets/icons/plus.svg'),
    Trash: () => import('@/assets/icons/trash.svg')
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit
    })
  },
  async created() {
    await this.getStandardById()
    await this.getAllLentes()
  },
  data() {
    return {
      addNew: false,
      selectedTitle: null,
      titles: [],
      user: getCurrentUser(),
      id: this.$route.params.id
    }
  },
  mixins: [standardRecordMixin()],
  methods: {
    async getAllLentes() {
      const loading = this.$loading.show()
      try {
        const { data } = await this.api.getFillingOptions('biometria-lente')
        this.titles = data.map(data => {
          return data.value
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        loading.hide()
      }
    },
    async updateStandard() {
      const loading = this.$loading.show()
      try {
        const { data } = await this.api.updateStandard(this.id, {
          value: this.form.value
        })
        this.form = data
        this.formValue = data.value
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        loading.hide()
      }
    },
    async addNewField(title) {
      if (!title) return
      const field = { title: title, olhoDireito: null, olhoEsquerdo: null }
      this.addNew = false
      this.selectedTitle = null
      this.form.value.push(field)
      await this.updateStandard()
    },
    async removeFields(fields) {
      const index = this.form.value.indexOf(fields)
      this.form.value.splice(index, 1)
      await this.updateStandard(this.form)
    }
  }
}
</script>

<style lang="scss" scoped>
.padding-row {
  padding-left: 8px !important;
}
.new-medical-report {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  top: 0;
  right: 10px;
  z-index: 10;
  gap: 10px;

  .icon {
    width: 20px;
    height: 20px;
    fill: var(--blue-500);
    cursor: pointer;

    &.trash {
      fill: none;
      stroke: var(--states-red-soft);
    }

    &.edit {
      fill: none;
      stroke: var(--blue-500);
    }

    &.close {
      fill: var(--type-active);
    }
  }
}

.icon {
  width: 24px;
  height: 24px;
  fill: var(--blue-500);
  margin-right: 12px;
  cursor: pointer;
}
.icon-info {
  height: 24px;
  width: 24px;
  margin-left: 5px;
  stroke: var(--neutral-500);
}
.alert {
  height: 56px;
  margin: 24px;
  background: #e6f9ff;
  border-radius: 8px;
}
.form-group {
  width: 100%;
  border-radius: 8px;
  padding-left: 8px !important;
}

.position {
  padding-top: 15px;
}

.form-add {
  margin-left: 2px;
  padding-top: 24px;
  padding-left: 8px !important;
}
.form-col {
  margin-left: 30px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 132%;
  color: #0c1d59;
}
.title {
  margin-left: 30px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 132%;
  color: #0c1d59;
}
.container-data {
  width: 100%;
  background-color: var(--neutral-000);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}
.custom-appointment {
  width: 100%;
  height: auto;
  padding: 30px 24px;
  text-align: left;
  display: inline-block;

  .breadcrumbs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 16px;

    .chevron {
      width: 24px;
      stroke: var(--neutral-600);
      margin: 0 8px;
    }

    .link {
      color: var(--blue-500);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    button {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
