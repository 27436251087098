<template>
  <div class="ellipsis">
    <div :id="id" class="ellipsis__content" :style="{ paddingRight: paddingRight || 0 }">
      <slot></slot>
    </div>
    <b-tooltip v-if="tooltip?.length > 1" :target="id" triggers="hover" :placement="placement || 'bottom'">
      {{ tooltip }}
    </b-tooltip>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'Ellipsis',
  props: ['placement', 'paddingRight'],
  data() {
      return {
          id: `icon_${uuidv4()}`,
          tooltip: ''
      }
  },
  mounted() {
      this.tooltip = this.$el.getElementsByClassName("ellipsis__content")[0].innerText
  },
  updated() {
      this.tooltip = this.$el.getElementsByClassName("ellipsis__content")[0].innerText
  },
}
</script>
<style lang="scss" scoped>
.ellipsis {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.ellipsis__content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>