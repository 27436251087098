<template>
  <textarea
    :ref="id"
    wrap="hard"
    autocomplete="off"
    :placeholder="placeholder"
    @input="debounceResize(true)"
    type="text"
    class="form-control"
    :disabled="disabled"
    :value="value"
    :class="[{ error: touched && errors.length > 0 && 1 === 2 }, id]"
    @focus="ev => $emit('focus', ev)"
    @blur="ev => $emit('blur', ev)"
    :maxlength="maxLength ?? false"
  ></textarea>
</template>

<script>
import { debounce } from 'lodash';
export default {
  name: 'TextArea',
  props: {
    id: { type: String, required: true },
    errors: Array,
    touched: Boolean,
    placeholder: String,
    value: String,
    disabled: {
      type: Boolean,
      default: false
    },
    maxLength: Number,
  },
  created() {
    this.debounceResize = debounce(this.resize, 200);
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.resize)
    this.resizeObserver.observe(this.$refs[this.id])
    this.resize(false)
  },
  beforeDestroy(){
    this.resizeObserver.unobserve(this.$refs[this.id])
  },
  methods: {
    resize(emmit) {
      const element = this.$refs[this.id]
      if (element) {
        element.style.height = 'auto'
        element.style.height = `${element.scrollHeight}px`
        if (!emmit) return
        this.$emit('inputTextArea', element.value)
        this.$emit('input', element.value)
      }
    }
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue) {
        this.debounceResize(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
  overflow: hidden;
  min-height: 38px;
  font-size: 14px !important;
}
</style>
