import Vue from "vue";

const store = {
  namespaced: true,
  state: () => ({
    examPackages: [],
    count: 0,
  }),
  mutations: {
    setExamPackages: (state, payload) => {
      state.examPackages = payload
    },
    addExamPackage: (state, examPackage) => {
      state.examPackages.push(examPackage)
    },
    updateExamPackage(state, payload) {
      state.examPackages = state.examPackages.filter(examPkg => examPkg.id !== payload.id )
      state.examPackages.push(payload)
    },
    deleteExamPackage(state, id) {
      state.examPackages = state.examPackages.filter(examPkg => examPkg.id !== id )
    },
    setCount: (state, payload) => {
      state.count = payload
    }
  },
  getters: {},
  actions: {
    getExamPackages({commit, state}, { page, clinicId, search }) {
      Vue.prototype.api.getExamPackages(page, clinicId, search)
        .then(res => {
          commit('setExamPackages', res.data.data)
          commit('setCount', res.data.total)
        })
    },
    createExamPackage({commit}, payload) {
      return Vue.prototype.api.createExamPackage(payload).then( res => {
        commit('addExamPackage', res.data)
      })
    },
    updateExamPackage({commit}, payload) {
      return Vue.prototype.api.updateExamPackage(payload).then( res => {
        commit('updateExamPackage', payload)
      })
    },
    deleteExamPackage({commit}, id) {
      return Vue.prototype.api.deleteExamPackage(id).then(res => {
        commit('deleteExamPackage', id)
      })
    }
  },
}

export default store
