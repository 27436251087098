<template>
  <b-modal
    id="warehouse-notifications"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title">Avisos de estoque</div>
      <span class="icon-box"><v-close class="icon stroke" @click="closeModal"  /></span>
    </div>
    <div class="md-content">
      <div
        :class="{ notification: true, 'border-bottom': index !== notifications.length - 1 }"
        v-for="(notification, index) in notifications" :key="notification.type"
      >
        <v-warning v-if="['NEAR_EXPIRATION', 'LOW_STOCK'].includes(notification.type)" class="icon-notification" />
        <v-error v-else class="icon-notification"  />
        <div v-if="notification.type === 'NEAR_EXPIRATION'" class="text-information">
          <div>
            Atenção! Existem <span class="warning">{{normalizeProductNumber(notification.count, 2)}}</span> produtos com
          </div>
          <div class="warning">vencimento próximo</div>
        </div>
        <div v-if="notification.type === 'LOW_STOCK'" class="text-information">
          <div>
            Atenção! Existem <span class="warning">{{normalizeProductNumber(notification.count, 2)}}</span> produtos
          </div>
          <div class="warning">abaixo do estoque mínimo</div>
        </div>
        <div v-if="notification.type === 'EXPIRED'" class="text-information">
          <div>
            Atenção! Existem <span class="danger">{{normalizeProductNumber(notification.count, 2)}}</span> produtos
          </div>
          <div>
            <span class="danger">vencidos</span> em seu estoque
          </div>
        </div>

        <div class="md-button">
          <b-button
            class="wh-button"
            variant="primary"
            @click="goToWarehouseNotification(notification.type)"
          >
              Verificar produtos
          </b-button>
          <div
            class="ignore-button"
            @click="ignoreNotification(index)"
          >
              Ignorar aviso
          </div>
        </div>
      </div>
    </div>

  </b-modal>
</template>
<script>
import Warning from '@/assets/icons/warning.svg'
import Error from '@/assets/icons/error.svg'
import Close from '@/assets/icons/close.svg'
import { normalizeProductNumber } from '@/utils/product-helper'
export default {
  name: 'WarehouseNotificationsModal',
  components: {
    'v-close': Close,
    'v-warning': Warning,
    'v-error': Error
  },
  props: {
    notifications: Array,
  },
  methods: {
    normalizeProductNumber,
    goToWarehouseNotification(type) {
      let path = null;
      switch(type) {
        case 'NEAR_EXPIRATION':
          path = '/estoque/lotes?v=p'
          break;
        case 'LOW_STOCK':
          path = '/estoque/produtos?p=ls'
          break;
        case 'EXPIRED':
          path = '/estoque/lotes?v=v'
          break;
        default:
          path = '/estoque/dashboard'
      }
      if (this.$route.path.indexOf(path) === -1){
        this.$router.push(path);
      }
      this.closeModal();
    },
    ignoreNotification(index) {
      this.notifications.splice(index, 1);
      if (!this.notifications.length) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$bvModal.hide('warehouse-notifications');
    },
  }
}
</script>
<style lang="scss">
#warehouse-notifications {
  .modal-content {
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px !important;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #525C7A;
        }

        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }
    }
  }

  .icon-notification {
    width: 150px;
  }
  .md-content {
    padding: 30px 20px 10px 20px;
  }
  .notification {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    color: var(--dark-blue);
    font-size: 18px;
    font-weight: 600;
    padding: 20px 0;
  }
  .text-information {
    width: 100%;
    text-align: left !important;
    font-family: 'Nunito Sans';
    font-weight: 600;
    padding: 20px;
  }
  .md-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    white-space: nowrap;
  }
  .wh-button {
    margin: 0 10px;
  }
  .warning {
    color: var(--orange);
  }
  .danger {
    color: var(--states-error);
  }
  .border-bottom {
    border-bottom: 1px solid #D9DFF2;
  }
  .ignore-button {
    font-size: 16px;
    color: var(--type-placeholder);
    white-space: nowrap;
    margin-top: 15px;
    cursor: pointer;
  }
}
</style>
