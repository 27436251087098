<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5245 3.08156C13.6741 2.6209 14.3259 2.6209 14.4755 3.08156L16.4432 9.13729C16.644 9.75532 17.2199 10.1738 17.8697 10.1738H24.2371C24.7215 10.1738 24.9229 10.7936 24.531 11.0783L19.3797 14.8209C18.854 15.2029 18.634 15.8799 18.8348 16.498L20.8024 22.5537C20.9521 23.0143 20.4248 23.3974 20.033 23.1127L14.8817 19.3701C14.3559 18.9881 13.6441 18.9881 13.1183 19.3701L7.96701 23.1127C7.57515 23.3974 7.04791 23.0144 7.19759 22.5537L9.16522 16.498C9.36603 15.8799 9.14604 15.2029 8.62031 14.8209L3.469 11.0783C3.07714 10.7936 3.27853 10.1738 3.76289 10.1738H10.1303C10.7801 10.1738 11.356 9.75532 11.5568 9.13729L13.5245 3.08156Z"
      :fill="color"
      :stroke="stroke"
    />
  </svg>
</template>

<script>
export default {
  name: 'StarSVG',
  props: {
    color: String,
    stroke: String
  }
}
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;
}
</style>
