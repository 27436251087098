<template>
  <div id="menu" class="menu-container">
    <div class="menu-header">
      <v-status-half v-if="progress.some(p => p.status === 'in_progress' )" />
      <v-status-filled v-else-if="progress.length && progress.every(elem => elem.status === 'finished')" />
      <v-status-empty v-else />
      {{ course ? course.name : '' }}
    </div>
    <div class="search">
      <v-input-search
        placeholder="Buscar por uma aula"
        v-model="query"
        debounce="300"
      />
    </div>

    <div
      v-for="(_module, index) in getModules()"
      :key="_module.id"
      class="module"
    >
      <div class="module-header">
        <div class="module-title">Módulo {{ _module.position + 1 }}</div>
        <div class="actions">
          <div class="module-counter ">{{ finishedVideoClassCount(_module.video_classes) }}/{{ _module.video_classes.length }}</div>
          <v-chevron-down class="chevron" v-b-toggle="`collapse-module-${index}`" />
        </div>
      </div>
      <div class="module-subtitle">{{ _module.name }}</div>
      <b-collapse :id="`collapse-module-${index}`" visible>
        <div
          v-for="videoClass in _module.video_classes"
          :key="videoClass.id"
        >
          <div
            :class="{
              video: true,
              finished: finishedVideoClass(videoClass),
              selected: videoClass.selected
            }"
            @click="selectVideoClass(_module.id, videoClass.id)"

          >
            <v-status-filled v-if="finishedVideoClass(videoClass)" />
            <v-status-empty v-else />
            <div>
              <div class="video-title">Aula {{ videoClass.position + 1 }}</div>
              <div class="video-subtitle">{{ videoClass.name }}</div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>

  </div>
</template>
<script>
import InputSearch from '@/components/General/InputSearch'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import StatusEmpty from '@/assets/icons/status-empty.svg'
import StatusHalf from '@/assets/icons/status-half.svg'
import StatusFilled from '@/assets/icons/status-filled.svg'

export default {
  props: {
    course: Object,
    progress: Array,

  },
  components: {
    'v-input-search': InputSearch,
    'v-chevron-down': ChevronDown,
    'v-status-empty': StatusEmpty,
    'v-status-half': StatusHalf,
    'v-status-filled': StatusFilled,
  },
  data() {
    return {
      query: null,
      filteredModules: []
    }
  },
  methods: {
    getModules() {
      if (this.query) return this.filteredModules
      else if (this.course) return this.course.modules
      else return []
    },
    finishedVideoClassCount(videoClasses) {
      const ids = videoClasses.map(v => v.id)
      return this.progress.filter(p => ids.includes(p.video_class_id) && p.status === 'finished').length
    },
    finishedVideoClass(videoClass) {
      return this.progress.some(p => p.video_class_id === videoClass.id && p.status === 'finished')
    },
    selectVideoClass(moduleId, videoClassId) {
      this.$emit('selectVideoClass', { moduleId, videoClassId })
    }
  },
  watch: {
    query(val) {
      this.filteredModules = this.course.modules
        .filter(m => m.video_classes.some(v => v.name.toLowerCase().includes(val.toLowerCase())))
    }
  }
}
</script>
<style lang="scss" scoped>
  .menu-container {
    width: 25%;
    border-radius: 8px;
    background: white;
    min-height: 100vh;

    .menu-header {
      display: flex;
      align-items: center;
      gap: 20px;
      font-weight: 400;
      font-size: 18px;
      padding: 20px;
      color: var(--dark-blue);
      border-bottom: 1px solid var(--neutral-200);

      svg {
        min-width: 24px;
      }
    }
    .search {
      padding: 16px 20px;
      border-bottom: 1px solid var(--neutral-200);
    }
    .module {
      width: 100%;
      background: var(--blue-100);
      .module-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px 0px 16px;
        .module-title {
          font-weight: 700;
          font-size: 16px;
          color: var(--dark-blue);
        }
        .actions {
          display: inline-flex;
          align-items: center;
          gap: 10px;
          .module-counter {
            font-weight: 700;
            font-size: 14px;
            color: var(--type-active);
          }
          .chevron {
            height: 25px;
            width: 25px;
            svg {
              stroke: var(--dark-blue);
            }
            &.not-collapsed {
              animation: rotateUp .5s;
              -webkit-animation-fill-mode: forwards;
              -moz-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
            }
            &.collapsed {
                animation: rotateDown .5s;
              -webkit-animation-fill-mode: forwards;
              -moz-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
            }

              @keyframes rotateUp {
              0% {
                  transform: rotate(0deg);
              }
              100% {
                  transform: rotate(180deg);
              }
            }

            @keyframes rotateDown {
              0% {
                  transform: rotate(180deg);
              }
              100% {
                  transform: rotate(0deg);
              }
            }
          }
          .chevron:focus {
            outline: none !important;
          }
        }
      }
      .module-subtitle {
        font-weight: 400;
        font-size: 16px;
        padding: 0 16px 16px 16px;
        color: var(--dark-blue);
        border-bottom: 1px solid var(--neutral-200);
      }
      .video {
        width: 100%;
        display: flex;
        gap: 20px;
        align-items: center;
        padding: 16px;
        background: white;
        border-left: 4px solid transparent;
        border-bottom: 1px solid var(--neutral-200);
        cursor: pointer;

        svg {
          min-width: 24px;
        }

        &.finished {
          background: var(--states-success-soft-green);
        }
        &.selected {
          border-left: 4px solid var(--orange);
        }
        .video-title {
          font-weight: 400;
          font-size: 16px;
          color: var(--dark-blue);
        }
        .video-subtitle {
          font-weight: 400;
          font-size: 16px;
          color: var(--dark-blue);
        }
      }
    }

  }
</style>
