<!-- Componente utilizado para Para menu lateral do projeto. -->

<template>
  <div
    class="sidebar d-flex flex-column justify-content-between no-print"
    @mouseover="sidebarClosed = false"
    @mouseout="sidebarClosed = true"
  >
    <div class="header">
      <a
        href=""
        class="c-logo"
        :class="{
          'c-logo--closed': sidebarClosed,
          'c-logo--opened': !sidebarClosed
        }"
      >
        <svg
          width="102"
          height="20"
          viewBox="0 0 102 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.9282 0H18.2429L22.419 10.4401L26.673 0H29.9644L21.3248 20H18.0551L20.7998 13.93L21.9993 11.2694C20.8551 11.6578 19.6053 11.0962 19.136 9.98278L14.9282 0Z"
            fill="white"
          ></path>
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="14"
            y="0"
            width="16"
            height="20"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.9282 0H18.2429L22.419 10.4401L26.673 0H29.9644L21.3248 20H18.0551L20.7998 13.93L21.9993 11.2694C20.8551 11.6578 19.6053 11.0962 19.136 9.98278L14.9282 0Z"
              fill="#0C1E59"
            ></path>
          </mask>
          <g mask="url(#mask0)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.5316 5.05464L20.8499 5.05464L20.8499 6.3183L14.5316 6.3183L14.5316 5.05464Z"
              fill="#305BF2"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.532 3.79097L20.8503 3.79097L20.8503 5.05463L14.532 5.05463L14.532 3.79097Z"
              fill="#00C2FF"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.532 2.5273L20.8503 2.5273L20.8503 3.79095L14.532 3.79095L14.532 2.5273Z"
              fill="#FFCC18"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.532 1.26365L20.8503 1.26365L20.8503 2.52731L14.532 2.52731L14.532 1.26365Z"
              fill="#FF9900"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.532 -1.78377e-05L20.8503 -1.72853e-05L20.8503 1.26364L14.532 1.26364L14.532 -1.78377e-05Z"
              fill="#FF6B00"
            ></path>
          </g>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.36548 0C5.17464 0 3.35999 0.725215 1.96437 2.19314L1.96153 2.19615C0.646573 3.60438 0 5.38897 0 7.51237C0 9.62477 0.679359 11.4102 2.05016 12.8339C3.46437 14.3005 5.2966 15.0247 7.50471 15.0247C9.22661 15.0247 10.6754 14.6255 11.8133 13.7883C12.9437 12.9702 13.768 11.8963 14.2819 10.5768L14.5259 9.95053H11.2947L11.1649 10.1937C10.7923 10.892 10.3096 11.3981 9.72109 11.7328C9.1306 12.0686 8.40717 12.2468 7.53256 12.2468C6.26026 12.2468 5.24515 11.8621 4.44998 11.114C3.7812 10.4854 3.34567 9.7163 3.14528 8.79198H14.731V7.51237C14.731 5.38897 14.0844 3.60437 12.7694 2.19614L12.7666 2.19314C11.371 0.725215 9.55631 0 7.36548 0ZM4.44387 3.83417C5.22372 3.13443 6.18734 2.77774 7.36547 2.77774C8.5436 2.77774 9.50722 3.13443 10.2871 3.83417C10.8975 4.39314 11.3163 5.09622 11.5374 5.9592H3.1935C3.41469 5.09622 3.8334 4.39314 4.44387 3.83417Z"
            fill="white"
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M68.2376 0C66.0297 0 64.2207 0.724483 62.8598 2.19799L62.8572 2.20076C61.548 3.64187 60.9 5.42283 60.9 7.51237C60.9 9.6019 61.548 11.3829 62.8572 12.824L62.8598 12.8267C64.2164 14.2956 65.9763 15.0247 68.0984 15.0247C69.9473 15.0247 71.4684 14.4876 72.6234 13.3989V14.806H75.6309V0.218694H72.6234V1.64597C71.4795 0.548322 70.0078 0 68.2376 0ZM63.9075 7.51218C63.9075 6.1339 64.3115 5.02787 65.097 4.15797C65.9308 3.24127 67.0101 2.77774 68.3768 2.77774C69.7642 2.77774 70.8178 3.24221 71.5919 4.14768L71.5936 4.14967C72.3457 5.01923 72.7347 6.12815 72.7347 7.51218C72.7347 8.89621 72.3457 10.0051 71.5936 10.8747L71.5919 10.8767C70.8178 11.7822 69.7642 12.2466 68.3768 12.2466C67.0101 12.2466 65.9309 11.7831 65.097 10.8665C64.3115 9.99654 63.9075 8.89049 63.9075 7.51218Z"
            fill="white"
          ></path>
          <path
            d="M47.1112 2.19554C48.4874 0.727105 50.2747 0 52.4311 0C54.1474 0 55.6536 0.441886 56.9287 1.34154L56.9333 1.3448C58.1465 2.22338 58.9846 3.39006 59.4409 4.83136L59.6304 5.42958H56.437L56.321 5.14172C56.0381 4.43909 55.5919 3.87126 54.976 3.43005C54.3715 2.99705 53.6773 2.77792 52.8766 2.77792C52.0317 2.77792 51.3369 2.90758 50.7781 3.14915C50.222 3.39763 49.7564 3.73379 49.3751 4.15687L49.3712 4.16119C48.5473 5.05261 48.1288 6.15897 48.1288 7.51237C48.1288 8.86577 48.5472 9.97215 49.3712 10.8636L49.3737 10.8663C50.1988 11.7734 51.3463 12.2468 52.8766 12.2468C53.6773 12.2468 54.3715 12.0277 54.976 11.5947C55.5919 11.1535 56.0381 10.5856 56.321 9.88301L56.437 9.59516H59.6304L59.4409 10.1934C58.9846 11.6347 58.1465 12.8013 56.9333 13.6799L56.9287 13.6832C55.6537 14.5829 54.1474 15.0247 52.4311 15.0247C50.2747 15.0247 48.4874 14.2976 47.1112 12.8292L47.1108 12.8288C45.7791 11.4044 45.1213 9.62082 45.1213 7.51237C45.1213 5.40391 45.7791 3.62033 47.1108 2.19594L47.1112 2.19554Z"
            fill="white"
          ></path>
          <path
            d="M83.5751 0.480187C84.2636 0.153322 85.0762 0 85.9965 0H86.5952V2.96928H85.8851C84.5228 2.96928 83.4294 3.49665 82.5691 4.57257C81.7485 5.61376 81.3322 6.85925 81.3322 8.33247V14.806H78.3247V0.218694H81.3322V2.183C81.8925 1.47175 82.6433 0.903825 83.5751 0.480187Z"
            fill="white"
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M36.9863 0C34.7954 0 32.9808 0.725215 31.5852 2.19314L31.5823 2.19615C30.2674 3.60438 29.6208 5.38897 29.6208 7.51237C29.6208 9.62477 30.3001 11.4102 31.6709 12.8339C33.0852 14.3005 34.9174 15.0247 37.1255 15.0247C38.8475 15.0247 40.2963 14.6255 41.4343 13.7882C42.5646 12.9701 43.3888 11.8962 43.9027 10.5768L44.1467 9.95053H40.9155L40.7857 10.1937C40.4131 10.892 39.9304 11.3981 39.3419 11.7328C38.7514 12.0686 38.028 12.2468 37.1534 12.2468C35.881 12.2468 34.8659 11.8621 34.0708 11.114C33.402 10.4854 32.9665 9.7163 32.7661 8.79198H44.3518V7.51237C44.3518 5.38897 43.7052 3.60438 42.3902 2.19614L42.3874 2.19314C40.9918 0.725215 39.1771 0 36.9863 0ZM34.0646 3.83413C34.8444 3.13442 35.808 2.77774 36.9861 2.77774C38.1643 2.77774 39.1279 3.13442 39.9077 3.83413C40.5182 4.3931 40.9369 5.0962 41.1581 5.9592H36.9861H32.8142C33.0354 5.0962 33.4541 4.3931 34.0646 3.83413Z"
            fill="white"
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M94.558 0C92.3672 0 90.5525 0.725215 89.1569 2.19314L89.154 2.19615C87.8391 3.60438 87.1925 5.38897 87.1925 7.51237C87.1925 9.62477 87.8719 11.4102 89.2427 12.8339C90.6569 14.3005 92.4891 15.0247 94.6972 15.0247C96.4191 15.0247 97.8678 14.6255 99.0058 13.7883C100.136 12.9702 100.961 11.8963 101.474 10.5768L101.718 9.95053H98.4872L98.3574 10.1937C97.9848 10.892 97.5022 11.3981 96.9136 11.7328C96.3231 12.0686 95.5997 12.2468 94.7251 12.2468C93.4528 12.2468 92.4377 11.8621 91.6425 11.114C90.9737 10.4854 90.5382 9.7163 90.3378 8.79198H101.923V7.51237C101.923 5.38897 101.277 3.60438 99.9619 2.19614L99.9591 2.19314C98.5635 0.725215 96.7488 0 94.558 0ZM91.6859 3.81349C92.4658 3.11377 93.4294 2.75709 94.6075 2.75709C95.7856 2.75709 96.7492 3.11377 97.5291 3.81349C98.1396 4.37246 98.5583 5.07556 98.7795 5.93856H94.6075H90.4355C90.6567 5.07555 91.0755 4.37246 91.6859 3.81349Z"
            fill="white"
          ></path>
        </svg>
      </a>

      <ChevronLeft class="toggleMenu" @click="toggleSidebar" v-if="false" />
    </div>
    <div class="content">
      <nav class="w-100">
        <router-link
          v-if="showDoctorOverview()"
          to="/visao-geral"
          tag="div"
          class="nav-item"
          :class="{ active: $route.path.indexOf('/visao-geral') > -1 }"
        >
          <span class="icon-box"><Grid class="icon fill" /></span>
          <span class="text">Visão Geral</span>
        </router-link>

        <router-link
          v-can="'FpPac1'"
          to="/pacientes"
          tag="div"
          class="nav-item"
          :class="{
            active:
              $route.path.indexOf('/pacientes') > -1 &&
              $route.path.indexOf('consulta') === -1
          }"
        >
          <span class="icon-box"><Users class="icon stroke" /></span>
          <span class="text">Pacientes</span>
        </router-link>

        <router-link
          v-can="'FpAgen1'"
          to="/agenda"
          tag="div"
          class="nav-item"
          :class="{
            active:
              $route.path.indexOf('/agenda') > -1 ||
              $route.path.indexOf('/configuracoes-da-agenda') > -1
          }"
        >
          <span class="icon-box"><Calendar class="icon fill" /></span>
          <span class="text">Agenda</span>
        </router-link>

        <router-link
          v-show-feature="'nota-fiscal'"
          v-if="showFeature('Notas Fiscais')"
          to="/nota-fiscal"
          tag="div"
          class="nav-item"
          :class="{
            active: $route.path.indexOf('/nota-fiscal') > -1
          }"
        >
          <span
            class="icon-box"
            v-if="$route.path.indexOf('/nota-fiscal') > -1"
          >
            <Nfe2 class="icon fill" />
          </span>
          <span class="icon-box" v-else><Nfe class="icon fill" /></span>
          <span class="text">Emissão de nota</span>
        </router-link>

        <router-link
          v-can="'FpPreCon1'"
          to="/pre-consulta"
          tag="div"
          class="nav-item"
          :class="{
            active: $route.path.indexOf('/pre-consulta') > -1
          }"
        >
          <span class="icon-box"><Clipboard class="icon stroke" /></span>
          <span class="text">Pré-consulta</span>
        </router-link>

        <router-link
          v-can="'FpExam1'"
          to="/exames"
          tag="div"
          class="nav-item"
          :class="{
            active: $route.path.indexOf('/exames') > -1
          }"
        >
          <span class="icon-box"><v-eye-exams class="icon fill" /></span>
          <span class="text">Exames/Proced.</span>
        </router-link>

        <router-link
          v-if="showFeature('Solicitações Pendentes')"
          to="/solicitacoes-pendentes"
          tag="div"
          class="nav-item"
          :class="{
            active: $route.path.indexOf('/solicitacoes-pendentes') > -1
          }"
        >
          <span class="icon-box"><Glasses class="icon fill" /></span>
          <span class="text">Solicit. Pendentes</span>
        </router-link>

        <!--  do the same as faturamento, to financeiro, with 2 submenus only -->
        <ul 
          class="nav-item nav-treeview-fat"
          :class="{ active: $route.path.indexOf('/financeiro') > -1 }"
          @click="showFinancialSubMenu = !showFinancialSubMenu"
          v-b-toggle.menu-financial-center
          v-if="showFinancial()"
        >
          <b-row
            class="align-items-center"
            no-gutters
            v-if="showFinancial()"
          >
            <span class="icon-box">
              <Financial class="icon fill" />
            </span>
            <span class="text d-flex" v-show="!sidebarClosed">
              Financeiro
              <span class="chevron">
                <ChevronDown
                  class="stroke"
                  :class="{ 'opened-submenu': showFinancialSubMenu }"
                />
              </span>
            </span>
          </b-row>
          <b-collapse
            id="menu-financial-center"
            class="mt-2"
            v-if="showFinancial()"
          >
          <section class="sub-menu" v-show="!sidebarClosed">
            <div class="d-flex">
              <div
                :class="{ 'sub-menu-active': $route.path.indexOf('/financeiro/dashboard') > -1 }"
              />
              <router-link
                to="/financeiro/dashboard"
                tag="li"
                class="text-sub nav-item"
              >
                Financeiro
              </router-link>
                
            </div>
            <div class="d-flex"
              v-can="'GerPenPen1'"
            >
              <div
                :class="{ 'sub-menu-active': $route.path.indexOf('/financeiro/pendings/dashboard') > -1 }"
              />
              <router-link
                to="/financeiro/pendings/dashboard"
                tag="li"
                class="text-sub nav-item"
                v-can="'GerPenPen1'"
              >
                Gerenciar pendências
              </router-link>
                
            </div>

          </section>
          

          </b-collapse>

        </ul>

        <router-link
          v-can="'Estoque'"
          to="/estoque"
          tag="div"
          class="nav-item"
          :class="{
            active: $route.path.indexOf('/estoque') > -1
          }"
        >
          <span class="icon-box"><Warehouse class="icon fill" /></span>
          <span class="text">Estoque</span>
        </router-link>
        
        <!-- Faturamento -->
        <ul
          class="nav-item nav-treeview-fat"
          :class="{
            active: $route.path.indexOf('/faturamento-tiss') > -1
          }"
          @click="showInvoicingSubMenu = !showInvoicingSubMenu"
          v-b-toggle.menu-invoicing-center
          v-if="showFeature('Faturamento TISS') || showFeature('Faturamento SUS')"
        >
          <b-row
            class="align-items-center"
            no-gutters
            v-if="showFeature('Faturamento TISS') || showFeature('Faturamento SUS')"
          >
            <span class="icon-box">
              <Tiss class="icon fill" />
            </span>
            <span class="text d-flex" v-show="!sidebarClosed">
              Faturamento
              <span class="chevron">
                <ChevronDown
                  class="stroke"
                  :class="{ 'opened-submenu': showInvoicingSubMenu }"
                />
              </span>
            </span>
          </b-row>
          <b-collapse
            id="menu-invoicing-center"
            class="mt-2"
            v-if="showFeature('Faturamento TISS') || showFeature('Faturamento SUS')"
          >
            <section class="sub-menu" v-show="!sidebarClosed">
              <div class="d-flex" v-if="showFeature('Faturamento TISS')" v-can="'FatTissDash1'">
                <div
                  :class="{
                    'sub-menu-active':
                      $route.path.indexOf('/faturamento-tiss/dashboard') >
                      -1
                  }"
                />
                <router-link
                  to="/faturamento-tiss/dashboard"
                  tag="li"
                  class="text-sub nav-item"
                >
                  Faturamento TISS
                </router-link>
              </div>
              <div class="d-flex" v-if="showFeature('Faturamento SUS')" v-can="'FatSusDash'">
                <div
                  :class="{
                    'sub-menu-active':
                      $route.path.indexOf('/faturamento-sus/dashboard') >
                      -1
                  }"
                ></div>
                <router-link
                  to="/faturamento-sus/dashboard"
                  tag="li"
                  class="text-sub nav-item"
                >
                  Faturamento SUS
                </router-link>
              </div>
            </section>
          </b-collapse>
        </ul>

        <router-link
          @mouseenter.native="() => (glaucoActive = true)"
          @mouseleave.native="() => (glaucoActive = false)"
          to="/glauco/dashboard"
          tag="div"
          class="nav-item"
          v-if="showFeature('Glauco')"
          :class="{
            active: $route.path.indexOf('/glauco') > -1
          }"
        >
          <span class="icon-box"
            ><GlaucoIcon class="icon" :isActive="glaucoActive"
          /></span>
          <span class="text">Glauco</span>
        </router-link>

        <router-link
          v-can="'Laudo'"
          to="/laudos-medicos/laudos-pendentes"
          tag="div"
          class="nav-item"
          :class="{
            active: $route.path.indexOf('/laudos-medicos/laudos-pendentes') > -1
          }"
        >
          <span class="icon-box"><MedicalReport class="icon fill" /></span>
          <span class="text">Laudo exame</span>
        </router-link>

        <router-link
          v-if="showAcademy()"
          to="/academy"
          tag="div"
          class="nav-item"
          :class="{
            active: $route.path.indexOf('/academy') > -1
          }"
        >
          <span id="side-bar-academy" class="icon-box"
            ><Academy class="icon fill"
          /></span>
          <span class="text">Academy</span>
        </router-link>

        <ul
          class="nav-item nav-treeview"
          :class="{
            active: $route.path.indexOf('/centro-cirurgico') > -1
          }"
          @click="showSurgerySubMenu = !showSurgerySubMenu"
          v-b-toggle.menu-surgery-center
          v-can="'CenCirurg1'"
        >
          <b-row
            class="align-items-center"
            no-gutters
            v-if="showFeature('Centro cirúrgico')"
          >
            <span class="icon-box">
              <SurgetyCenter class="icon fill" />
            </span>
            <span class="text d-flex" v-show="!sidebarClosed">
              Centro cirúrgico
              <span class="chevron">
                <ChevronDown
                  class="stroke"
                  :class="{ 'opened-submenu': showSurgerySubMenu }"
                />
              </span>
            </span>
          </b-row>
          <b-collapse
            id="menu-surgery-center"
            class="mt-2"
            v-if="showFeature('Centro cirúrgico')"
          >
            <section class="sub-menu" v-show="!sidebarClosed">
              <div class="d-flex" v-can="'CenCirurg1'">
                <div
                  :class="{
                    'sub-menu-active':
                      $route.path.indexOf('/centro-cirurgico/mapa-cirurgico') >
                      -1
                  }"
                />
                <router-link
                  to="/centro-cirurgico/mapa-cirurgico"
                  tag="li"
                  class="text-sub nav-item"
                >
                  Mapa cirúrgico
                </router-link>
              </div>
              <div class="d-flex" v-can="'CenCirurg2'">
                <div
                  :class="{
                    'sub-menu-active':
                      $route.path.indexOf('/centro-cirurgico/lista-pacientes') >
                      -1
                  }"
                />
                <router-link
                  to="/centro-cirurgico/lista-pacientes"
                  tag="li"
                  class="text-sub nav-item"
                >
                  Cirurgias
                </router-link>
              </div>
            </section>
          </b-collapse>
        </ul>
      </nav>
    </div>
    <div class="footer">
      <span class="footer-prefix text">Versão do sistema &nbsp;</span>3.0.9
    </div>
  </div>
</template>

<script>
import ChevronLeft from '@/assets/icons/chevron-left.svg'
import Grid from '@/assets/icons/grid.svg'
import Users from '@/assets/icons/users.svg'
import Clipboard from '@/assets/icons/clipboard.svg'
import Financial from '@/assets/icons/financial.svg'
import Nfe from '@/assets/icons/nfe-sidebar-dark.svg'
import Nfe2 from '@/assets/icons/nfe2.svg'
import Warehouse from '@/assets/icons/warehouse.svg'
import Calendar from '@/assets/icons/calendar.svg'
import EyeExams from '@/assets/icons/eye-exams.svg'
import MedicalReport from '@/assets/icons/medical-report.svg'
import Academy from '@/assets/icons/academy.svg'
import Tiss from '@/assets/icons/tiss.svg'
import GlaucoIcon from '@/components/Glauco/GlaucoSVGSideBar'
import SurgetyCenter from '@/assets/icons/surgery-center.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Glasses from '@/assets/icons/glasses.svg'
import {
  showDoctorOverview,
  showAcademy,
  showFeature,
  showFinancial,
  showFinancialButtonSidebar
} from '@/utils/permissionsHelp'

export default {
  name: 'Sidebar',
  components: {
    ChevronLeft,
    Grid,
    Users,
    Clipboard,
    Financial,
    Nfe,
    Nfe2,
    Warehouse,
    Calendar,
    MedicalReport,
    Academy,
    Tiss,
    GlaucoIcon,
    SurgetyCenter,
    ChevronDown,
    Glasses,
    'v-eye-exams': EyeExams
  },
  data() {
    return {
      sidebarClosed: true,
      showSurgerySubMenu: false,
      glaucoActive: false,
      showInvoicingSubMenu: false,
      showFinancialSubMenu: false
    }
  },
  methods: {
    showDoctorOverview,
    showAcademy,
    showFeature,
    showFinancial,
    showFinancialButtonSidebar,
    toggleSidebar() {
      this.sidebarClosed = !this.sidebarClosed
      this.eventHub.$emit('resize')
    }
  }
}
</script>

<style scoped lang="scss">
$root: '.c-logo';
$color-foreground: #fff;
$color-background: #0c1d59;

$logo-closed-mask: polygon(14% 0, 31% 0, 21% 100%, 17% 100%);
$logo-opened-mask: polygon(0 0, 100% 0, 100% 100%, 0 100%);

.sidebar {
  width: 72px;
  height: 100%;
  position: fixed;
  z-index: 1100;
  background-color: #0c1d59;
  transition: all 500ms;
  overflow: hidden;

  * {
    transition: all 500ms;
  }

  #{$root} {
    display: block;
    overflow: hidden;
    --fill: forwards;

    transition: width 0.3s ease-in-out;

    svg {
      animation-name: var(--animation);
      animation-duration: 0.3s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: var(--fill);
      animation-play-state: var(--state);
      animation-direction: var(--direction);
      width: 102px;
    }

    &--opened:not(&--closing) {
      width: 102px;
      svg {
        clip-path: #{$logo-opened-mask};
        transform: translatex(0);
      }
    }

    &--opened {
      width: 102px;
      svg {
        clip-path: #{$logo-opened-mask};
        transform: translatex(0);
      }
    }

    &--closed {
      width: 15px;
      svg {
        clip-path: #{$logo-closed-mask};
        transform: translatex(-15px);
      }
    }

    &--opening {
      width: 102px;
      svg {
        --animation: logoOpening;
      }
    }

    &--closing {
      width: 15px;
      svg {
        --animation: logoClosing;
      }
    }
  }

  @keyframes logoOpening {
    0% {
      clip-path: #{$logo-closed-mask};
      transform: translatex(-15px);
    }
    100% {
      clip-path: #{$logo-opened-mask};
      transform: translatex(0);
    }
  }

  @keyframes logoClosing {
    0% {
      clip-path: #{$logo-opened-mask};
      transform: translatex(0);
    }
    100% {
      clip-path: #{$logo-closed-mask};
      transform: translatex(-15px);
    }
  }

  .header {
    height: 72px;
    border-bottom: 1px solid var(--neutral-600);
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      width: 0;
      height: 0;
      opacity: 0;
    }

    .toggleMenu {
      width: 24px;
      cursor: pointer;
      transition: all 500ms;
      stroke: var(--neutral-600);
    }
  }

  .content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;

    .nav-item {
      width: 226px;
      color: var(--neutral-500);
      margin-bottom: 14px;
      text-align: left;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.nav-treeview {
        list-style-type: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;

        @media (max-height: 768px) {
          justify-content: flex-start;
          align-items: flex-start;
          padding: 0 10px;
        }
      }

      &.nav-treeview-fat {
        list-style-type: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 0px 0px 8px;

        @media (max-height: 768px) {
          justify-content: flex-start;
          align-items: flex-start;
          padding: 0 10px;
        }
      }

      .sub-menu {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        li {
          color: var(--neutral-300);
          padding: 15px 10px;
          margin-bottom: 0;
          background: #303d6f;
          font-weight: 400;
          font-size: 16px;

          @media (max-height: 768px) {
            font-size: 12px !important;
          }
        }

        .sub-menu-active {
          width: 2% !important;
          background-color: var(--orange) !important;
        }

        .text-sub:hover {
          color: var(--neutral-000);
          font-weight: 700;
        }
      }

      .text {
        opacity: 0;
        animation: menuLabel ease 1s;
        animation-iteration-count: 1;
        -webkit-animation: menuLabel ease 1s;
        -webkit-animation-iteration-count: 1;
        -moz-animation: menuLabel ease 1s;
        -moz-animation-iteration-count: 1;
        -o-animation: menuLabel ease 1s;
        -o-animation-iteration-count: 1;
        -ms-animation: menuLabel ease 1s;
        -ms-animation-iteration-count: 1;

        @media (max-height: 768px) {
          font-size: small;
        }

        .chevron {
          svg {
            width: 20px;
            margin-left: 25px;

            &.stroke {
              stroke: var(--neutral-500) !important;
            }

            &.opened-submenu {
              transform: rotate(-180deg);
            }
          }
        }
      }

      .icon-box {
        padding: 12px;
        margin-right: 16px;
        border-radius: 16px;

        @media (max-height: 768px) {
          padding: 2px 10px !important;
          margin-right: 10px;
        }

        .icon {
          width: 24px;
          transition: all 0ms;

          @media (max-height: 768px) {
            transform: scale(0.75);
            overflow-x: visible;
          }

          &.fill {
            fill: var(--neutral-500);
          }

          &.stroke {
            stroke: var(--neutral-500);
          }
        }
      }

      &:hover {
        .text {
          color: var(--neutral-000);
          font-weight: 700;
        }

        .icon-box {
          background-color: var(--neutral-600);
          border-radius: 16px;

          .icon {
            &.fill {
              fill: var(--neutral-000);
            }

            &.stroke {
              stroke: var(--neutral-000);
            }
          }
        }

        .chevron {
          svg {
            &.stroke {
              stroke: var(--neutral-000) !important;
            }
          }
        }
      }

      &.active {
        .text {
          color: var(--neutral-000);
          font-weight: 700;
        }

        .icon-box {
          background-color: var(--orange);
          border-radius: 16px;

          .icon {
            &.fill {
              fill: var(--neutral-000);
            }

            &.stroke {
              stroke: var(--neutral-000);
            }
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    height: 64px;
    border-top: 1px solid var(--neutral-600);
    padding: 24px;
    text-align: left;
    font-weight: 400;
    color: var(--neutral-400);
    font-size: 12px;
  }

  .footer-prefix {
    display: none;
    opacity: 0;
  }

  &:hover {
    width: 250px;

    .header {
      .logo {
        height: 20px;
        opacity: 1;
      }

      .toggleMenu {
        transform: rotate(180deg);
      }
    }

    .content {
      .nav-item {
        .text {
          opacity: 1;
        }
      }
    }

    .footer-prefix {
      white-space: nowrap;
      display: block;
      opacity: 1;
    }
  }

  @keyframes menuLabel {
    0% {
      display: none;
    }
    50% {
      display: block;
    }
  }

  @-moz-keyframes menuLabel {
    0% {
      display: none;
    }
    50% {
      display: block;
    }
  }

  @-webkit-keyframes menuLabel {
    0% {
      display: none;
    }
    50% {
      display: block;
    }
  }

  @-o-keyframes menuLabel {
    0% {
      display: none;
    }
    50% {
      display: block;
    }
  }

  @-ms-keyframes menuLabel {
    0% {
      display: none;
    }
    50% {
      display: block;
    }
  }
}
</style>
