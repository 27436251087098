import { render, staticRenderFns } from "./Protocols.vue?vue&type=template&id=862d7734&scoped=true"
import script from "./Protocols.vue?vue&type=script&lang=js"
export * from "./Protocols.vue?vue&type=script&lang=js"
import style0 from "./Protocols.vue?vue&type=style&index=0&id=862d7734&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "862d7734",
  null
  
)

export default component.exports