<template>
    <main class="doc-container">
        <iframe 
            v-if="temporaryUrl"
            class="doc-iframe"
            :src="temporaryUrl" 
            frameborder="0" 
            allowfullscreen></iframe>  
        <div v-else class="doc-content">
            <div>
                <PrescriptionA class="icon" :class="{animation: loading}" />
                <PrescriptionB class="icon" :class="{animation: loading}" />
                <PrescriptionC class="icon" :class="{animation: loading}" />
            </div>
            <div class="description">Para acessar o documento informe a senha que se encontra abaixo do QR Code em sua receita</div>
            <div class="otp-container">
                <input
                    class="input-style form-control"
                    v-model="inputs[idx]"
                    @keyup="inputFocus"
                    @focus="check($event)"
                    @change="handleChange(idx, $event)"
                    v-for="(inp, idx) in inputCount"
                    :key="idx"
                    :tabindex="idx"
                    maxlength="1"
                    autocomplete="off"
                />
            </div>
            <b-button 
                variant="primary" 
                class="buttom"
                :disabled="loading || !isValidCode"
                @click="getDocument"
            >
                Ver documento
            </b-button>
            <p class="iti-description">
                Documento assinado digitalmente de acordo com a ICP-Brasil, MP 2.200-2/2001, no sistema Eyecare BI, pode ser validado em <a href="https://assinaturadigital.iti.gov.br">https://assinaturadigital.iti.gov.br</a>
            </p>
        </div>
    </main>
</template>
<script>

export default {
    metaInfo: {
        title: 'Eyecare - Documento',
    },
    components: {
        PrescriptionA: () => import("@/assets/icons/prescription-report.svg"),
        PrescriptionB: () => import("@/assets/icons/prescription-glasses.svg"),
        PrescriptionC: () => import("@/assets/icons/prescription-medicine.svg"),
    },
    computed: {
        isValidCode() {
            return this.inputs.filter(el => !!el).length === this.inputCount;
        }
    },
    data() {
        const count = 5;
        return {
            loading: false,
            code: this.$route.params.code,
            inputCount: count,
            inputs: Array(count),
            currentKey: 0,
            temporaryUrl: null,
        }
    },
    methods: {
        handleChange(key, event) {
            this.inputs[key] = event.target.value;
        },
        inputFocus(event) {
            const data = this.inputs.join("");
            const inputs = this.getChildren(event);

            this.$emit("input", data);
            if (data.length === this.inputCount) {
                this.$emit("completed", data);
            }

            if (event.key === "Delete" || event.key === "Backspace") {
                this.currentKey--;
                if (this.currentKey <= 0) {
                this.currentKey = 0;
                }
                if (inputs[this.currentKey]) {
                inputs[this.currentKey].focus();
                }
            } else {
                this.currentKey++;
                if (this.currentKey >= this.inputCount) {
                this.currentKey = this.inputCount;
                }
                if (inputs[this.currentKey]) {
                inputs[this.currentKey].focus();
                }
            }
        },
        getChildren(event) {
            return event.target.parentElement.children;
        },
        check(event) {
            const inputs = this.getChildren(event);
            if (inputs[this.currentKey]){
                inputs[this.currentKey].focus();
            }
        },
        getDocument() {
            this.loading = true;
            this.api.getByCode(this.code, this.inputs.join(''))
                .then(({ data }) => {
                    this.$toast.success('Carregando documento...');
                    this.temporaryUrl = data.temporaryUrl;
                })
                .catch(error => {
                    console.log('***** Get Document Error ***** ', error);
                    this.$toast.error(error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    watch: {
        value() {
            this.inputs = this.value.split("");
        },
    },
}
</script>
<style lang="scss" scoped>
@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}
.doc-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    padding: 2rem;
}
.doc-content {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.otp-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.icon {
    width: 52px;
    height: 52px;
}
.animation {
    animation: bounce 1.5s infinite;
}
.icon:nth-child(1) {
    animation-delay: 0s;
}

.icon:nth-child(2) {
    animation-delay: 0.3s;
}

.icon:nth-child(3) {
    animation-delay: 0.6s;
}
.input-style {
    width: 50px; 
    height: 50px;
    text-align: center;
    font-size: 1.2rem;
    text-transform: uppercase;
}
.buttom {
    font-size: 14px !important;
}
.description {
    text-align: center;
}
.iti-description {
    font-size: 0.8rem;
    text-align: center;
    color: #7c7c7c;
    a {
        color: #007bff;
    }
}
.doc-iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
</style>