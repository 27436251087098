<template>
  <div class="tab-container">
    <div class="tab-container-keys no-print">
      <ul class="nav">
        <li v-for="(key, index) in keys" :key="key.id">
          <a
            :style="cssVars"
            class="nav-link keys"
            v-bind:class="{ active: isActive(index), disabled: key.disabled, 'd-none': key.hidden }"
            @click="changeIndex(index)"
          >
          {{ key.label ?? key }}
        </a>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tab',
  props: {
    keys: Array,
    index: Number,
    fontSize: { type: String, default: '' },
    tabPadding: { type: String, default: '22px 14px' }
  },
  computed: {
    cssVars() {
      return {
        '--font': this.fontSize,
        '--tab-padding': this.tabPadding
      }
    }
  },
  data() {
    return {
      currentIndex: this.index || 0
    }
  },
  methods: {
    isActive(index) {
      return this.currentIndex === index
    },
    changeIndex(index) {
      this.currentIndex = index
      this.$emit('onChange', index)
    }
  },
  watch: {
    index: function () {
      this.currentIndex = this.index
    }
  }
}
</script>

<style scoped lang="scss">
.tab-container {
  background-color: white;
  border-radius: 10px;
}
.tab-container-keys {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
}
.keys {
  padding: var(--tab-padding);
  font-weight: 600;
  color: var(--type-active);
  cursor: pointer;
  font-size: var(--font);
}
.active {
  color: #ff6b00 !important;
  font-weight: 700;
  border-bottom: 3px solid;
}
.disabled {
  color: var(--type-placeholder) !important;
  cursor: not-allowed !important;
  border-bottom: none;
}
.tab-content {
  padding: 5px 24px;
}
</style>
