<template>
  <div class="wh-container">
    <div class="header no-print">
      <p class="title">Laudo Exame</p>
    </div>

    <Tab
      ref="medicalReports_tabs"
      :index="currentTabIndex"
      v-bind:keys="tabs"
      v-on:onChange="onChangeTab"
    >
      <PendingReportsTable
        v-if="
          this.tabs[currentTabIndex] === 'Laudos pendentes' &&
          userHasPermission('LaudoMedPen1')
        "
      />
      <FinishedReportsTable
        v-if="
          this.tabs[currentTabIndex] === 'Exames laudados' &&
          userHasPermission('LaudoMedExam1')
        "
      />
      <ConfigTab
        v-if="
          this.tabs[currentTabIndex] === 'Configurações' &&
          userHasPermission('LaudoMedConfigMed1') &&
          userHasPermission('LaudoMedConfigGer1')
        "
      />
    </Tab>
  </div>
</template>

<script>
import { userHasPermission } from '@/utils/localStorageManager'

export default {
  name: 'MedicalReport',
  metaInfo: {
    title: 'Eyecare - Laudos médicos'
  },

  components: {
    Tab: () => import('@/components/Tab.vue'),
    ConfigTab: () => import('./ConfigTab.vue'),
    PendingReportsTable: () => import('./components/PendingReportsTable.vue'),
    FinishedReportsTable: () => import('./components/FinishedReportsTable.vue')
  },

  mounted() {
    this.handleTabIndex()
    Object.keys(this.tabKeys).map(key => {
      userHasPermission(key) && this.tabs.push(this.tabKeys[key])
    })
  },
  model: {
    prop: 'currentIndex',
    event: 'onChange'
  },
  data() {
    return {
      tabs: [],
      currentTabIndex: 0,
      tabKeys: {
        LaudoMedPen1: 'Laudos pendentes',
        LaudoMedExam1: 'Exames laudados',
        LaudoMedConfigGer1: 'Configurações'
      },
      filters: {}
    }
  },
  methods: {
    userHasPermission,

    onChangeTab(index, dontPush) {
      if (this.$refs) this.currentTabIndex = index
      if (!dontPush) {
        window.history.pushState(
          null,
          null,
          `/laudos-medicos/${this.getPathByIndex(index)}`
        )
      }
    },
    handleTabIndex() {
      switch (this.$route.params.tab) {
        case 'laudo-pendente':
          this.currentTabIndex = 0
          break
        case 'exames-laudados':
          this.currentTabIndex = 1
          break
        case 'configuracoes':
          this.currentTabIndex = 2
          break
        default:
          this.currentTabIndex = 0
          break
      }
    },
    getPathByIndex(index) {
      switch (index) {
        case 0:
          return 'laudos-pendentes'
        case 1:
          return 'exames-laudados'
        case 2:
          return 'configuracoes'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wh-container {
  height: 100%;
  padding: 30px 24px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
  }
  .wh-button {
    margin: 0 5px;
    border-color: var(--blue-500);
    color: var(--blue-500);
  }
  .whw-button {
    margin: 0 5px;
    color: white;
  }
}
</style>
