const updateBatches = async (context, payload) => {
  await context.commit('updateBatches', payload)
}

const updateSelectedRows = async (context, payload) => {
  await context.commit('updateSelectedRows', payload)
}

const reloadContent = async (context, payload) => {
  await context.commit('reloadContent', payload)
}

const setHealthPlansFields = async (context, fields) => {
  await context.commit('setHealthPlanFields', fields)
}

export default {
  updateBatches,
  updateSelectedRows,
  reloadContent,
  setHealthPlansFields
}
