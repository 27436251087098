<!--- View de cadastro - Responsável pelo cadastro dos novos usuários. --->

<template>
	<b-container fluid class="register">
		<b-row>
			<b-col d-flex class="p-0 form-side text-left d-flex justify-content-center flex-column align-items-center col-sm-12 col-md-7">
				<b-row class="tabs">
					<b-col
						cols="3" class="tab" v-for="step in steps" :key="step.id"
						:class="{ active: step.id === actualStep, confirmed: step.id < actualStep}"
					>
						<span class="text">
							{{ step.name }}
						</span>
						<Check class="icon" v-if="step.id < actualStep"/>
					</b-col>
				</b-row>
				<div class="form d-flex align-items-center">
					<validation-observer
						class="w-100"
						v-show="actualStep === 0"
						ref="step0"
						>
						<p class="title">Dados de acesso</p>
						<p class="description">Defina seu email de login e sua senha de acesso.</p>
						<div class="mb-5">
							<div class="form-group">
								<label for="name">E-mail<span class="help">(Obrigatório)</span></label>
								<validation-provider
									mode="lazy"
									name="e-mail"
									:rules="{ required: true, email: true }"
									v-slot="{ errors, dirty }"
								>
									<input
										autocomplete="off"
										id="email"
										type="text"
										v-model="registerData.email"
										class="form-control"
										placeholder="Digite seu email"
										:class="{
											error:
												registerData.email.length > 0 &&
												errors.length > 0 &&
												dirty
										}"
									/>
									<span
										class="error-message p-0"
										v-if="errors.length > 0 && dirty"
									>
										{{ errors[0] }}
                  					</span>
								</validation-provider>
							</div>
							<div class="form-group">
								<label for="password">Senha<span class="help">(Obrigatório)</span></label>
								<validation-provider
									mode="lazy"
									name="senha"
									rules="required|min:8|password_strength"
									v-slot="{ errors, dirty }"
									vid="password"
								>
									<div class="input-password">
										<input
											id="password"
											:type="!showPassword ? 'password' : 'text'"
											v-model="registerData.password"
											class="form-control"
											placeholder="********"
											:class="{error: errors.length > 0 && dirty }"
										/>
										<EyeSlash
											class="eye"
											v-if="showPassword == false"
											@click="showPassword = !showPassword"
										/>
										<Eye
											class="eye"
											v-if="showPassword == true"
											@click="showPassword = !showPassword"
										/>
									</div>
									<span
										class="error-message p-0"
										v-if="errors.length > 0 && dirty"
									>
										{{ errors[0] }}
                  					</span>
								</validation-provider>
							</div>
							<div class="form-group">
								<label for="repeatPassword">Confirmar senha<span class="help">(Obrigatório)</span></label>
								<validation-provider
									mode="lazy"
									name="senha"
									rules="required|password:@password"
									v-slot="{ errors, dirty }"
								>
									<div class="input-password">
										<input
											id="repeatPassword"
											:type="!showPassword_confirmation ? 'password' : 'text'"
											v-model="registerData.repeatPassword"
											class="form-control"
											placeholder="********"
											:class="{error: errors.length > 0 && dirty }"
										/>
										<EyeSlash
											class="eye"
											v-if="showPassword_confirmation == false"
											@click="showPassword_confirmation = !showPassword_confirmation"
										/>
										<Eye
											class="eye"
											v-if="showPassword_confirmation == true"
											@click="showPassword_confirmation = !showPassword_confirmation"
										/>
									</div>
									<span
										class="error-message p-0"
										v-if="errors.length > 0 && dirty"
									>
										{{ errors[0] }}
                    				</span>
								</validation-provider>
							</div>
							<b-button
								block
								variant="primary"
								@click="goToNextStep('step0')"
							>
								Continuar
							</b-button>
						</div>
					</validation-observer>
					<validation-observer
						ref="step1"
						class="w-100"
						v-slot="{ invalid }"
						v-show="actualStep === 1"
					>
						<p class="title mt-5">Dados profissionais</p>
						<p class="description">Agora, nos informe os seus dados.</p>
						<div class="mb-5">
							<div class="form-group">
								<label for="name">Nome completo<span class="help">(Obrigatório)</span></label>
								<validation-provider
									name="nome completo"
									:rules="{ required: true }"

									v-slot="{ errors, dirty }"
								>
									<input
										autocomplete="off"
										id="name"
										type="text"
										v-model="registerData.name"
										class="form-control"
										placeholder="Digite seu nome"
										:class="{
											error:
												registerData.name.length > 0 &&
												errors.length > 0 &&
												dirty 
										}"
									/>
									<span
										class="error-message p-0"
										v-if="errors.length > 0 && dirty"
									>{{ errors[0] }}</span
									>
								</validation-provider>
							</div>
							<div class="form-group">
								<label for="document">CPF<span class="help">(Obrigatório)</span></label>
								<validation-provider
									name="CPF"
									:rules="{ required: true, cpf: true }"
									v-slot="{ errors, dirty }"
									vid="document"
								>
									<input
										autocomplete="off"
										id="document"
										type="text"
										v-model="registerData.cpf"
										class="form-control"
										placeholder="000.000.000-00"
										v-mask="'###.###.###-##'"
										:class="{
											error:
												registerData.cpf.length > 0 &&
												errors.length > 0 &&
												dirty
										}"
									/>
									<span
										class="error-message p-0"
										v-if="errors.length > 0 && dirty"
									>{{ errors[0] }}</span>

									<span
										v-if="err?.message?.includes('cpf')"
										class="error-message p-0"
									>
										{{ err?.message }}
									</span>
								</validation-provider>
							</div>
							<div class="form-group">
								<label for="possui-autorrefrator" class="checkbox">
									<input
										type="checkbox"
										id="possui-autorrefrator"
										v-model="isDoctor"
									/>
									<span class="checkmark">
										<Check/>
									</span>
									Sou médico
								</label>
							</div>
							<div class="form-group" v-if="isDoctor">
								<label for="crmState">CRM<span class="help">(Obrigatório)</span></label>
								<b-row>
									<b-col cols="auto">
										<div class="form-group state-width mb-0">
											<validation-provider
												name="estado"
												rules="required"
												v-slot="{ errors, dirty }"
											>
												<multiselect
													v-model="registerData.crmState"
													id="crmState"
													:options="statesOptions"
													:option-height="40"
													:showLabels="false"
													:searchable="true"
													:allowEmpty="isDoctor ? false : true"
													placeholder="UF"
													class="with-border"
													:class="{ error: errors.length > 0 && dirty }"
												>
													<template slot="caret">
														<div class="chevron">
															<ChevronDown/>
														</div>
													</template>

													<template slot="noOptions">
														Nenhuma opção
													</template>

													<template slot="noResult">
														Nenhum resultado
													</template>
												</multiselect>
											</validation-provider>
										</div>
									</b-col>
									<b-col>
										<validation-provider
											name="número do CRM"
											rules="required"
											v-slot="{ errors, dirty }"
										>
											<input
												autocomplete="off"
												id="crmNumber"
												type="text"
												v-model="registerData.crmNumber"
												class="form-control"
												:class="{ error: errors.length > 0 && dirty }"
											/>
										</validation-provider>
									</b-col>
								</b-row>
							</div>
							<div class="form-group">
								<label for="cellphone">Celular<span class="help">(Obrigatório)</span></label>
								<validation-provider
									mode="lazy"
									name="celular"
									rules="required"
									v-slot="{ errors, dirty }"
								>
									<input
										autocomplete="off"
										id="cellphone"
										type="text"
										v-model="registerData.cellphone"
										class="form-control"
										placeholder="(xx) xxxx-xxxxx"
										v-mask="['(##) ####-####', '(##) #####-####']"
										:class="{ error: errors.length > 0 && dirty }"
									/>
									<span
										class="error-message p-0"
										v-if="errors.length > 0 && dirty"
									>{{ errors[0] }}</span
									>
								</validation-provider>
							</div>
							<b-button
								block
								variant="primary"
								:disabled="
								(invalid || crmChecked === null || crmChecked === false) &&
								isDoctor
								"
								@click="goToNextStep('step1')"
							>
								Continuar
							</b-button>
							<div class="d-inline-block">
								<p class="link" @click="backToStep()">
									<ChevronLeft/>
									Voltar
								</p>
							</div>
						</div>
					</validation-observer>
					<validation-observer
						ref="step2"
						class="w-100"
						v-slot="{ invalid }"
						v-show="actualStep === 2"
					>
						<p class="title mt-5">Dados da Clínica</p>
						<p class="description">Insira os dados da sua clínica.</p>
						<div class="mb-5">
							<div class="form-group">
								<label for="clinicName">Nome da clínica<span class="help">(Obrigatório)</span></label>
								<validation-provider
									name="nome clinica"
									:rules="{ required: true }"
									v-slot="{ errors, dirty }"
								>
									<input
										autocomplete="off"
										id="clinicName"
										type="text"
										v-model="registerData.clinicName"
										placeholder="Digite o nome"
										class="form-control"
										:class="{
											error:
												registerData.clinicName.length > 0 &&
												errors.length > 0 &&
												dirty
										}"
									/>
								</validation-provider>
							</div>
							<b-row>
								<b-col>
									<div class="form-group">
										<label for="cnpj">CNPJ<span class="help">(Obrigatório)</span></label>
										<validation-provider
											name="CNPJ"
											rules="required"
											v-mask="'##.###.###/####-##'"
											v-slot="{ errors, dirty }"
										>
											<input
												autocomplete="off"
												id="cnpj"
												type="text"
												placeholder="00.000.000/0000-00"
												v-model="registerData.cnpj"
												class="form-control"
												:class="{
													error:
														registerData.cnpj.length > 0 &&
														errors.length > 0 &&
														dirty
												}"
											/>
											<span
												v-if="err?.message?.includes('cnpj')"
												class="error-message p-0"
											>
												{{ err?.message }}
											</span>
										</validation-provider>
									</div>
								</b-col>
								<b-col cols="auto">
									<div class="form-group state-width">
										<label for="cnes">CNES<span class="help">(Obrigatório)</span></label>
										<validation-provider
											name="CNES"
											rules="required"
											v-slot="{ errors, dirty }"
										>
											<input
												autocomplete="off"
												id="cnes"
												type="text"
												v-model="registerData.cnes"
												class="form-control"
												:class="{
													error:
														registerData.cnes.length > 0 &&
														errors.length > 0 &&
														dirty
												}"
											/>
										</validation-provider>
									</div>
								</b-col>
							</b-row>
							<div class="form-group">
								<label for="zipcode">CEP<span class="help">(Obrigatório)</span></label>
								<validation-provider
									name="CEP"
									rules="required"
									v-slot="{ errors, dirty }"
								>
									<input
										autocomplete="off"
										id="zipcode"
										type="text"
										v-model="registerData.zipCode"
										class="form-control"
										@change="searchCEP"
										placeholder="00000-000"
										v-mask="'#####-###'"
										:class="{
											error:
												registerData.zipCode.length > 0 &&
												errors.length > 0 &&
												dirty
										}"
									/>
								</validation-provider>
							</div>
							<b-row>
								<b-col>
									<div class="form-group">
										<label for="address">Logradouro<span class="help">(Obrigatório)</span></label>
										<validation-provider
											name="logradouro"
											rules="required"
											v-slot="{ errors, dirty }"
										>
											<input
												autocomplete="off"
												id="address"
												type="text"
												v-model="registerData.address"
												class="form-control"
												placeholder="Ex. Nome da rua"
												:class="{
													error:
														registerData.address.length > 0 &&
														errors.length > 0 &&
														dirty
												}"
											/>
										</validation-provider>
									</div>
								</b-col>
								<b-col cols="auto">
									<div class="form-group state-width">
										<label for="number">Nº<span class="help">(Obrigatório)</span></label>
										<validation-provider
											name="número"
											rules="required"
											v-slot="{ errors, dirty }"
										>
											<input
												autocomplete="off"
												id="number"
												ref="addressNumber"
												type="text"
												v-model="registerData.number"
												placeholder="Ex. 01"
												class="form-control"
												:class="{
													error:
														registerData.number.length > 0 &&
														errors.length > 0 &&
														dirty
												}"
											/>
										</validation-provider>
									</div>
								</b-col>
							</b-row>
							<div class="form-group">
								<label for="complement">Complemento</label>
								<validation-provider
									name="complemento"
									rules="required"
									v-slot="{ errors, dirty }"
								>
									<input
										autocomplete="off"
										id="complement"
										type="text"
										placeholder="Digite o complemento"
										v-model="registerData.complement"
										class="form-control"
										:class="{ error: errors.length > 0 && dirty }"
									/>
								</validation-provider>
							</div>
							<div class="form-group">
								<label for="neighborhood">Bairro<span class="help">(Obrigatório)</span></label>
								<validation-provider
									name="complemento"
									rules="required"
									v-slot="{ errors, dirty }"
								>
									<input
										autocomplete="off"
										id="neighborhood"
										type="text"
										placeholder="Digite o bairro"
										v-model="registerData.neighborhood"
										class="form-control"
										:class="{
											error:
												registerData.neighborhood.length > 0 &&
												errors.length > 0 &&
												dirty
										}"
									/>
								</validation-provider>
							</div>
							<b-row>
								<b-col>
									<div class="form-group">
										<label for="city">Cidade<span class="help">(Obrigatório)</span></label>
										<validation-provider
											name="cidade"
											rules="required"
											v-slot="{ errors, dirty }"
										>
											<input
												autocomplete="off"
												id="city"
												type="text"
												v-model="registerData.city"
												placeholder="Digite a cidade"
												class="form-control"
												:class="{
													error:
														registerData.city.length > 0 &&
														errors.length > 0 &&
														dirty
												}"
											/>
										</validation-provider>
									</div>
								</b-col>
								<b-col cols="auto">
									<div class="form-group state-width">
										<label for="state">Estado<span class="help">(Obrigatório)</span></label>
										<validation-provider
											name="estado"
											rules="required"
											v-slot="{ errors, dirty }"
										>
											<multiselect
												id="state"
												v-model="registerData.state"
												:options="statesOptions"
												:option-height="40"
												:showLabels="false"
												:searchable="true"
												:allowEmpty="false"
												placeholder="UF"
												class="with-border"
												:class="{
													error:
														registerData.state.length > 0 &&
														errors.length > 0 &&
														dirty
												}"
											>
												<template slot="caret">
													<div class="chevron">
														<ChevronDown/>
													</div>
												</template>

												<template slot="noOptions">
													Nenhuma opção
												</template>

												<template slot="noResult">
													Nenhum resultado
												</template>
											</multiselect>
										</validation-provider>
									</div>
								</b-col>
							</b-row>
							<b-button
								block
								variant="primary"
								:disabled="invalid"
								@click="goToNextStep('step2')"
							>
								Continuar
							</b-button
							>
							<div class="d-inline-block">
								<p class="link" @click="backToStep()">
									<ChevronLeft/>
									Voltar
								</p>
							</div>
						</div>
					</validation-observer>
					<validation-observer
						ref="step3"
						class="w-100"
						v-slot="{ invalid }"
						v-show="actualStep === 3"
					>
						<p class="title mt-colab mt-0">Equipe</p>
						<p class="description">Convide os membros da sua clínica.</p>
						<div class="mb-5">
							<div class="form-group">
								<label for="clinicName">Nome</label>
								<validation-provider
									name="nome colaborador"
									rules="min:5"
								>
									<input
										autocomplete="off"
										id="nameProfessional"
										type="text"
										v-model="inviteName"
										placeholder="Digite o nome"
										class="form-control"
									/>
								</validation-provider>
							</div>
							<div class="form-group">
								<label for="clinicName">Email</label>
								<validation-provider
									name="email colaborador"
									:rules="{ required: !!inviteName, email: true }"
								>
									<input
										autocomplete="off"
										id="emailProfessional"
										type="text"
										v-model="inviteEmail"
										placeholder="exemplo@contato.com.br"
										class="form-control"
									/>
								</validation-provider>
							</div>
							<b-row>
								<b-col cols="6">
									<div class="form-group">
										<label for="role">Atuação</label>
										<validation-provider
											name="role"
										>
											<multiselect
												v-model="inviteRole"
												id="role"
												:options="roles"
												@change="checkAdmin"
												:option-height="40"
												:showLabels="false"
												:searchable="true"
												placeholder="Selecione"
												class="with-border"
											>
												<template slot="caret">
													<div class="chevron">
														<ChevronDown/>
													</div>
												</template>

												<template slot="noOptions">
													Nenhuma opção
												</template>

												<template slot="noResult">
													Nenhum resultado
												</template>
											</multiselect>
										</validation-provider>
									</div>
								</b-col>
								<b-col cols="6">
									<div class="form-group">
										<label for="is-gestor">Permissão</label>
										<label for="is-gestor" class="checkbox mt-2">
											<input
												type="checkbox"
												id="is-gestor"
												v-model="inviteAdmin"
												:disabled="inviteRole !== 'Médico'"
											/>
											<span
												:class="`checkmark ${inviteRole !== 'Médico' ? 'checkmark-disabled' : ''}`">
												<Check/>
											</span>
											Médico gestor
										</label>
									</div>
								</b-col>
							</b-row>
							<div class="d-inline-block">
								<p class="link" @click="addProfessional(invalid)">
									<strong>Enviar convite</strong>
								</p>
							</div>
							<div class="divider"></div>
							<p class="invites-title">Quantidade</p>
							<b-row>
								<b-col>
									<div class="form-group">
										<label for="is-gestor">Quantidade de médicos</label>
										<b-form-input type="number" v-model="registerData.qty" min="1"></b-form-input>
									</div>
								</b-col>
							</b-row>
							<p class="invites-title">Convites a serem enviados</p>
							<div class="invites">
								<p v-if="registerData.professionals.length === 0">
									Nenhum convite enviado ainda.
								</p>
								<ul v-else>
									<li
										v-for="professional in registerData.professionals"
										:key="professional.email"
									>
										<img
											:src="`https://avatars.dicebear.com/api/initials/${professional.name}.svg`"
										/>
										<div>
											<h5>
												{{ professional.name }}
											</h5>
											<p>{{ professional.email }}</p>
											<button class="remove-button"
													@click="removeProfessional(professional.email)">Remover
											</button>
										</div>
										<svg
											viewBox="0 0 24 24"
											fill="none"
											stroke="#00C773"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M19 7L9 17.01L6 14.01"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</li>
								</ul>
							</div>
							<b-button
								block
								variant="primary"
								@click="goToNextStep('step3')"
							>
								Continuar
								{{
									registerData.professionals.length === 0 ? 'sem convidar' : null
								}}
							</b-button>
							<div class="d-inline-block">
								<p class="link" @click="backToStep()">
									<ChevronLeft/>
									Voltar
								</p>
							</div>
						</div>
					</validation-observer>
					<div v-if="submitted" class="form submitted text-center">
						<img
							src="@/assets/images/eyecare-logo-colored.png"
							class="logo-eyecare"
						/>
						<br/>
						<p class="check-container">
							<span class="check">
								<span class="container">
									<Check class="icon"/>
								</span>
							</span>
						</p>
						<p class="title">Cadastro criado</p>
						<p class="description">Agora você <b>já pode fazer login</b> na Eyecare.</p>
						<b-button
							variant="primary"
							class="mt-5"
							@click="redirectToLogin()"
							>
							Ir para página de login
						</b-button>
					</div>
				</div>
			</b-col>

			<!-- Carousel -->
			<b-col class="vh-100 p-0 carousel-side position-relative col-sm-0 col-md-5">
				<carousel
					class="vh-100"
					:autoplay="true"
					:loop="true"
					:autoplayTimeout="6000"
					:perPage="1"
					:paginationEnabled="true"
					:paginationSize="8"
					:paginationColor="'#A4B1DF'"
					:paginationPadding="8"
					:paginationActiveColor="'#FFFFFF'"
				>
					<slide :style="{'background-image': 'url(' + require('@/assets/images/carousel-img.png') + ')'}">
						<div class="container">
							<p class="title">Olá,</p>
							<p class="text">
								Bem vindo ao EyecareBI, sua plataforma customizada para a Oftalmologia
							</p>
						</div>
					</slide>
					<slide :style="{'background-image': 'url(' + require('@/assets/images/carousel-img2.png') + ')'}">
						<div class="container">
							<p class="title">Dados inteligentes</p>
							<p class="text">
								Aqui você terá acesso a dados que poderão otimizar seu dia-a-dia no consultório
							</p>
						</div>
					</slide>
					<slide :style="{'background-image': 'url(' + require('@/assets/images/carousel-img3.png') + ')'}">
						<div class="container">
							<p class="title">Novo mindset</p>
							<p class="text">
								Além de facilitar a incorporação das inovações tecnológicas a sua prática diária
							</p>
						</div>
					</slide>
				</carousel>

				<img src="@/assets/images/eyecare-logo-white.png" class="logo-eyecare"/>
			</b-col>
			<!-- /Carousel -->
		</b-row>
	</b-container>
</template>

<script>
import * as cep from 'cep-promise'

export default {
	name: 'Register',
	metaInfo: {
		title: 'Eyecare - Cadastre-se'
	},
	components: {
		Check: () => import('@/assets/icons/check.svg'),
		ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
		ChevronLeft: () => import('@/assets/icons/chevron-left.svg'),
		Eye: () => import('@/assets/icons/eye.svg'),
		EyeSlash: () => import('@/assets/icons/eye-slash.svg'),
	},
	created() {
		this.checkKey()
	},
	data() {
		return {
			steps: [
				{id: 0, name: 'Dados de acesso'},
				{id: 1, name: 'Dados profissionais'},
				{id: 2, name: 'Dados da clínica'},
				{id: 3, name: 'Equipe'}
			],
			actualStep: 0,
			registerData: {
				key: '',
				email: '',
				password: '',
				password_confirmation: '',
				name: '',
				cpf: '',
				crmState: '',
				crmNumber: '',
				cellphone: '',
				clinicName: '',
				cnpj: '',
				cnes: '',
				zipCode: '',
				address: '',
				number: '',
				complement: '',
				neighborhood: '',
				city: '',
				state: '',
				qty: 0,
				professionals: []
			},
			showPassword: false,
			showPassword_confirmation: false,
			submitted: false,	
			isDoctor: false,
			inviteName: '',
			inviteEmail: '',
			inviteRole: '',
			inviteAdmin: false,
			adminAvailable: false,
			qty: 0,
			roles: [
				'Secretária',
				'Enfermeiro',
				'Médico',
				'Gestor',
				'Técnico',
				'Tecnólogo'
			],
			statesOptions: [
				'AC',
				'AL',
				'AP',
				'AM',
				'BA',
				'CE',
				'DF',
				'ES',
				'GO',
				'MA',
				'MT',
				'MS',
				'MG',
				'PA',
				'PB',
				'PR',
				'PE',
				'PI',
				'RJ',
				'RN',
				'RS',
				'RO',
				'RR',
				'SC',
				'SP',
				'SE',
				'TO'
			],
			err: null
		}
	},
	computed: {
		crmChecked () {
			return this.registerData.crmState && this.registerData.crmNumber
		},
	},
	methods: {
		checkKey(){
			this.api
			.checkKey(this.$route.params.registerKey)
			.then(res => {
				this.registerData.key = res.data.key

				if (this.registerData.key !== this.$route.params.registerKey) {
					this.$router.push('/solicitar-demonstracao')
				}
			})
		},
		removeProfessional(email) {
			this.registerData.professionals = this.registerData.professionals.filter(professional => professional.email !== email)
		},
		addProfessional(invalid) {
			if (invalid) return
			let role = ''

			switch (this.inviteRole) {
				case 'Secretária':
					role = 'SECRETARY'
					break
				case 'Médico':
					role = 'DOCTOR'
					break
				case 'Enfermeiro':
					role = 'NURSE'
					break
				case 'Técnico':
					role = 'TECHNICAL'
					break
				case 'Tecnólogo':
					role = 'TECHNOLOGIST'
					break
				case 'Gestor':
					role = 'MANAGER'
					break
			}

			if (role === 'DOCTOR' && this.inviteAdmin) role = 'DOCTOR_MANAGER'

			this.registerData.professionals.push({
				name: this.inviteName,
				email: this.inviteEmail,
				role
			})
			this.inviteName = ''
			this.inviteEmail = ''
			this.inviteRole = ''
			this.inviteAdmin = false
		},
		checkAdmin() {
			if (this.inviteRole === 'Médico') this.adminAvailable = true
			else this.adminAvailable = false
		},
		goToNextStep(stepToValidate) {

			const validationObserver = this.$refs[stepToValidate]

			if (validationObserver) {
				validationObserver.validate().then((valid) => {

					if (valid) {
						
						if (this.actualStep === 3) {
							this.doRegister()
						}
						this.actualStep += 1
					}
				})
			}
		},
		backToStep() {

			this.actualStep--
		},
		searchCEP() {
			if (this.registerData.zipCode.length === 9) {
				cep(this.registerData.zipCode)
					.then(res => {
						this.registerData.address = res.street
						this.registerData.neighborhood = res.neighborhood
						this.registerData.city = res.city
						this.registerData.state = res.state

						this.$refs.addressNumber.focus()
					})
					.catch(err => {
						console.log('Err', err)
					})
			}
		},
		doRegister() {
			const isLoading = this.$loading.show()

			this.api
				.register(this.registerData)
				.then(res => {
					if (res.status === 201) {
						this.submitted = true
					}
				})
				.catch(err => {
					this.$toast.error(err.message)
					this.actualStep--
				})
				.finally(() => {
					isLoading.hide()
				})
		},
		async redirectToLogin(){
			try {
				await this.api.logout()
				localStorage.removeItem('access_token')
				localStorage.removeItem('refresh_token')
				localStorage.removeItem('user')
				localStorage.removeItem('clinic')
				localStorage.removeItem('permissions')
				localStorage.removeItem('session_cloud_token')
				this.$router.push('/login')
				window.location.href = '/login'
			} catch (err) {
				this.$toast.error(err.message)
			}
		}
	},
	watch: {
		'registerData.zipCode': function (v) {
			if (v.length === 9) {
				this.searchCEP()
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.register {
	margin: 0 !important;
	padding: 0 !important;
	overflow-x: hidden;

	.form-side {
		width: 100%;
		overflow-y: auto;

		.tabs {
			width: 100%;
			height: 88px;
			justify-self: center;

			.tab {
				position: relative;
				padding: .5rem 1.5rem;
				border-bottom: 1px solid var(--neutral-200);
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				text-align: center;
				
				.text {
					font-size: 16px;
					font-weight: 600;
					color: var(--type-placeholder);
					display: block;
					width: 100%;
				}

				.icon {
					stroke: var(--states-success);
					width: 1.5rem;
				}

				&:nth-child(3) {
					border-left: 1px solid var(--neutral-200);
					border-right: 1px solid var(--neutral-200);
				}

				&.active {
					position: relative;
					color: var(--type-active);

					&:before {
						width: 100%;
						height: 4px;
						background-color: var(--neutral-200);
						position: absolute;
						bottom: -1px;
						left: 0;
						content: ' ';
					}
				}

				&.confirmed {
					position: relative;
					color: var(--type-active);

					&:before {
						width: 100%;
						height: 4px;
						background-color: var(--states-success);
						position: absolute;
						bottom: -1px;
						left: 0;
						content: ' ';
					}

					.icon {
						width: 20px;
						height: 20px;
						left: 50%;
						transform: translateX(-50%);
						position: absolute;
						content: '';
						stroke: var(--states-success);
						background-image: url(../assets/icons/check.svg);

						@media screen and (min-width: 993px) {
							bottom: .5rem;
							height: 1.5rem;
						}
					}
				}

				@media screen and (max-width: 992px) {
					padding: .5rem .1rem;

					.text {
						font-size: .8rem;
					}
				}
			}
		}

		.logo-eyecare {
			width: 122px;
			margin-bottom: 80px;
		}

		.form {
			width: 80%;
			padding-top: 2rem;

			&.submitted {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}

			.help {
				margin-left: 3px;
			}

			.check-container {
				margin-bottom: 32px;
				display: flex;
				justify-content: center;

				.check {
					width: 96px;
					height: 96px;
					background-color: #dbfff0;
					border: 16px solid #dbfff0;
					border-radius: 32px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-bottom: 0;

					.container {
						width: 64px;
						height: 64px;
						border-radius: 16px;
						background-color: var(--states-success);
						display: flex;
						justify-content: center;
						align-items: center;
					}

					.icon {
						width: 24px;
						height: 24px;
						stroke: var(--neutral-000);
					}
				}
			}

			.title {
				font-family: 'Red Hat Display';
				font-weight: 700;
				font-size: 26px;
				line-height: 30.32px;
				color: var(--dark-blue);
			}

			.description {
				width: 100%;
				font-size: 16px;
				color: var(--type-active);
				margin: 10px 0 48px 0;
			}

			.error-message p-0-box {
				background-color: #fee8e6;
				border-radius: 8px;
				padding: 16px 26px;
				display: flex;
				justify-content: center;
				font-size: 14px;
				font-weight: 600;
				margin: -38px 0 48px 0;

				.icon {
					width: 24px;
					height: 24px;
					margin-left: 2px !important;
					margin-right: 25px;
					stroke: var(--states-error);
				}
			}

			button {
				font-size: 18px;
				font-weight: 600;
				color: var(--neutral-000);
				padding: 16px;
				border-radius: 8px;
				line-height: 24px;
			}
		}
	}

	.divider {
		margin-top: 40px;
		margin-bottom: 40px;
		width: 100%;
		height: 1px;
		background-color: #d9dff2;
	}

	.invites-title {
		font-weight: 600;
		font-size: 16px;
		color: var(--dark-blue);
		margin-bottom: 4px;
	}

	.invites {
		background: #f4f5fb;
		border-radius: 8px;
		color: #525c7a;
		font-size: 16px;
		margin-bottom: 32px;

		.remove-button {
			background-color: transparent !important;
			color: red !important;
			border: none !important;
			padding: 0 !important;
			margin: 0 !important;
			transition: 0.3s !important;
			cursor: pointer;
			font-size: 0.8em !important;

			&:hover {
				color: rgb(156, 0, 0) !important;
			}
		}

		p {
			padding: 16px;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				padding: 16px;
				border-bottom: solid 1px #d9dff2;

				img {
					width: 40px;
					height: 40px;
					border-radius: 40px;
					margin-right: 16px;
					background-color: rgba(0, 0, 0, 0.1);
				}

				div {
					margin-right: auto;
					color: #525c7a;

					h5 {
						font-size: 18px;
						margin: 0;
					}

					p {
						font-size: 14px;
						padding: 0;
						margin: 0;
					}
				}

				svg {
					width: 24px;
				}

				&:last-child {
					border-bottom: none;
				}
			}
		}
	}

	.carousel-side {
		.logo-eyecare {
			width: 82px;
			position: absolute;
			bottom: 80px;
			left: 50%;
			transform: translateX(-50%);
		}

		@media screen and (max-width: 992px) {
			display: none;
		}
	}

	.VueCarousel {
		.VueCarousel-wrapper {
			height: 100%;

			.VueCarousel-inner {
				height: 100% !important;

				.VueCarousel-slide {
					background-size: cover;

					.container {
						position: absolute;
						bottom: 210px;
						padding: 0 125px;
						display: flex;
						flex-direction: column;
						align-items: center;

						.title {
							font-family: 'Red Hat Display';
							font-weight: 700;
							font-size: 24px;
							line-height: 30.24px;
							color: var(--neutral-000);
						}

						.text {
							width: 370px;
							font-weight: 400;
							font-size: 18px;
							line-height: 24.84px;
							color: var(--neutral-100);
						}
					}
				}
			}
		}

		.VueCarousel-pagination {
			position: absolute;
			bottom: 160px;

			.VueCarousel-dot {
				transition: all 500ms;

				&:focus {
					outline: none;
				}
			}

			.VueCarousel-dot--active {
				width: 16px !important;
				border-radius: 16px !important;
			}
		}
	}

	.link {
		margin-top: 24px !important;
		display: flex;
		align-items: center;

		svg {
			width: 16px;
			height: 16px;
			stroke: var(--blue-500);
		}
	}

	.mt-colab{
		margin-top: 260px;
	}
}
</style>
