<template>
  <div 
    v-can="'RepMedReg4'"
    class="wh-container" 
    id="medical-transfer"
  >
    <header class="header no-print">
      <span class="title">
        <span 
          class="link" 
          @click="$router.push(`/repasse_medico/relatorio_de_repasse`)"
        >
          Repasse Médico
        </span> 

        <ChevronDown class="icon mx-1" />

        <span
          class="link" 
          @click="$router.push(`/repasse_medico/regras_de_repasse`)"
        >
          Regras de repasse
        </span> 

        <ChevronDown class="icon mx-1" />

        Regra #{{rule.number}} - {{rule.name}} 
      </span>
    </header>

    <Tab
      :index="currentTabIndex"
      v-bind:keys="tabs"
      v-on:onChange="onChangeTab"
    >
      <Settings 
        v-if="tabs[currentTabIndex] === 'Configurações'"
        :rule="rule" 
        class="tab" 
        :getRule="getRule"
      />

      <Specifications 
        v-else-if="tabs[currentTabIndex] === 'Especificações'"
        :rule="rule" 
        class="tab" 
      />

      <Definitons 
        v-else
        :rule="rule" 
        class="tab"
        :getRule="getRule"
      />
      
    </Tab>
  </div>
</template>

<script>
import api from '@/modules/onlending/api.js'

export default {
  name: 'Rules',
  data() {
    return {
      tabs: [
        'Definições',
        'Configurações',
        'Especificações'
      ],
      currentTabIndex: 0,

      rule: {}
    }
  },
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Settings: () => import('./Settings'),
    Tab: () => import('@/components/Tab'),
    Definitons: () => import('./Definitions'),
    Specifications: () => import('./Specifications'),
  },
  created() {
    this.getRule()
  },
  async mounted() {
    this.handleTabIndex()
  },
  methods: {
    getRule() {
      const isLoading = this.$loading.show()
      api.getOnlendingRule(this.$route.params.id)
      .then((res) => {
        this.rule = res.data
      })
      .catch ((error) => {
        this.$toast.error(error.message)
      })
      .finally (() => {
        isLoading.hide()
      })
    },
    onChangeTab(index, dontPush) {
      this.currentTabIndex = index
      if (!dontPush) {
        window.history.pushState(
          null,
          null,
          `/regras_repasse/${this.$route.params.id}/${this.getPathByIndex(index)}`
        )
      }
    },
    handleTabIndex() {
      const tabs = {
        'definicoes': 0,
        'configuracoes': 1,
        'especificacoes': 2
      }
      this.currentTabIndex = tabs[this.$route.path.split('/').slice(2).join('/')]
    },
    getPathByIndex(index) {
      const indexes = {
        0: 'definicoes',
        1: 'configuracoes',
        2: 'especificacoes',
      }
      return indexes[index] || 'definicoes'
    }
  }
}
</script>

<style lang="scss" scoped>
.wh-container {
  height: 100%;
  padding: 30px 24px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .tab {
    min-height: 70vh;
  }

  .title {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #525C7A;
  }
  .wh-button {
    margin: 0 5px;
    border-color: var(--blue-500);
    color: var(--blue-500);
  }
  .whw-button {
    margin: 0 5px;
    color: var(--default-white);
  }
}

 .icon {
    width: 24px;
    height: 22px;
    stroke: var(--neutral-500);
    transform: rotate(-90deg);
  }
</style>