import { base } from '@/utils/api'

const ENDPOINT_LABPACS = '/labpacs'

export default {
    getLabpabcsItem(itemId) {
        return base.get(`${ENDPOINT_LABPACS}/item/${itemId}`)
    },

    getUrlDicon(id) {
        return base.get(`${ENDPOINT_LABPACS}/obter-url/${id}`)
    }
}