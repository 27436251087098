import api from '@/modules/tiss/manageGuides/api'
import { getCurrentClinic } from '@/utils/localStorageManager'

const getTissGuideRequiredFields = (context) => {
  const clinic = getCurrentClinic()
  api.getTissGuideRequiredFields(clinic.id)
    .then(({ data }) => context.commit('setRequiredFields', data))    
}

export default {
  getTissGuideRequiredFields,
}
