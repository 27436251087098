const moduleRoute = {
  path: '/faturamento-tiss/matmed',
  component: () => import('@/views/Home.vue'),
  children: [
    {
      path: '',
      component: () => import('./views'),
      children: [
        { path: 'tabela/:tableType/:tableId', component: () => import('./views/TablePage.vue') },
        { path: '/materiais-medicamentos/configuracao/:clinicHealthPlanId/:configId', component: () => import('./views/ConfigPage.vue') },
      ]
    },
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}