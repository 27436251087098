import Vue from 'vue'
import { getCurrentClinic } from '@/utils/localStorageManager'

const getAllHealthPlans = async (context) => {
  const clinic = getCurrentClinic()
  const res = await Vue.prototype.api.getAllClinicHealthPlans(clinic.id)
  context.commit('setAllHealthPlans', res.data)
}

const getTissClinicHealthPlans = async (context) => {
  const clinic = getCurrentClinic()
  const res = await Vue.prototype.api.getActiveInvoicingTissClinicHealthPlans(clinic.id)
  context.commit('setTissClinicHealthPlans', res.data)
}

export default {
  getAllHealthPlans,
  getTissClinicHealthPlans
}
