<template>
  <div class="carousel-side">
    <carousel
          class="vh-100"
          :autoplay="true"
          :loop="true"
          :autoplayTimeout="6000"
          :perPage="1"
          :paginationEnabled="true"
          :paginationSize="8"
          :paginationColor="'#A4B1DF'"
          :paginationPadding="8"
          :paginationActiveColor="'#FFFFFF'"
        >
          <slide
            :style="{
              'background-image':
                'url(' + require('@/assets/images/carousel-img.png') + ')'
            }"
          >
            <div class="container">
              <p class="title">Olá,</p>
              <p class="text">
                Bem vindo ao EyecareBI, sua plataforma customizada para a
                Oftalmologia
              </p>
            </div>
          </slide>
          <slide
            :style="{
              'background-image':
                'url(' + require('@/assets/images/carousel-img2.png') + ')'
            }"
          >
            <div class="container">
              <p class="title">Dados inteligentes</p>
              <p class="text">
                Aqui você terá acesso a dados que poderão otimizar seu dia-a-dia
                no consultório
              </p>
            </div>
          </slide>
          <slide
            :style="{
              'background-image':
                'url(' + require('@/assets/images/carousel-img3.png') + ')'
            }"
          >
            <div class="container">
              <p class="title">Novo mindset</p>
              <p class="text">
                Além de facilitar a incorporação das inovações tecnológicas a
                sua prática diária
              </p>
            </div>
          </slide>
        </carousel>

        <img
          src="@/assets/images/eyecare-logo-white.png"
          class="logo-eyecare"
        />
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.carousel-side {
  .logo-eyecare {
    width: 82px;
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.VueCarousel {
  .VueCarousel-wrapper {
    height: 100%;

    .VueCarousel-inner {
      height: 100% !important;

      .VueCarousel-slide {
        background-size: cover;

        .container {
          position: absolute;
          bottom: 210px;
          padding: 0 125px;

          .title {
            font-family: 'Red Hat Display';
            font-weight: 700;
            font-size: 24px;
            line-height: 30.24px;
            color: var(--neutral-000);
          }

          .text {
            font-weight: 400;
            font-size: 18px;
            color: var(--neutral-100);
            margin-top: 24px;
          }
        }
      }
    }
  }

  .VueCarousel-pagination {
    position: absolute;
    bottom: 160px;

    .VueCarousel-dot {
      transition: all 500ms;
    }

    .VueCarousel-dot--active {
      width: 16px !important;
      border-radius: 16px !important;
    }
  }
}
</style>
