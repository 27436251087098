<template>
  <div class="totem-code-container">
    <div class="description">
      Informe o <b>código</b> que aparece na tela do seu <b>totem</b> para ativa-lo
    </div>
    <b-form-group class="form-group">
      <b-form-input
        autofocus
        class="code-input"
        v-model="code"
        maxLength="5"
        placeholder="DN5V4"
      ></b-form-input>
    </b-form-group>
    <b-button
      size="lg"
      variant="primary"
      :disabled="code?.length !== 5"
      @click="activateTotem"
    >
      <b-spinner v-if="loading" small variant="light"></b-spinner>
      Ativar totem
    </b-button>
  </div>

</template>
<script>
import { getCurrentClinic } from  '@/utils/localStorageManager'
export default {
  data() {
    return {
      clinic: getCurrentClinic(),
      code: null,
      loading: false,
    }
  },
  methods: {
    async activateTotem() {
      this.loading = true
      try {
        await this.api.activateTotem(this.clinic.id, this.code.toUpperCase())
        this.code = null
        this.$toast.success('Ativação enviada com sucesso')
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .totem-code-container {
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .description {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .code-input {
    width: 230px;
    height: 85px;
    font-size: 50px !important;
    font-weight: 700 !important;
    padding: 15px 5px;
    text-align: center;
    letter-spacing: 5px;
    text-transform: uppercase;
  }
</style>
