import moment from 'moment';

export const parseTime = (time) => {
  if (!time) return '-';

    const now = moment();
    const target = moment(time);
    if (Math.abs(now.diff(target, 'minutes') < 1)) {
        return 'Agora mesmo';
    } else if (Math.abs(now.diff(target, 'hours') < 1)) {
      const count = parseInt(Math.abs(now.diff(target, 'minutes')))
      return `Há ${count} minuto${count > 1 ? 's' : ''} atrás`;
    } else if (Math.abs(now.diff(target, 'days') < 1)) {
      const count = parseInt(Math.abs(now.diff(target, 'hours')))
      return `Há ${count} hora${count > 1 ? 's' : ''} atrás`;
    } else if (Math.abs(now.diff(target, 'month') < 1)) {
      const count = parseInt(Math.abs(now.diff(target, 'days')))
      return `Há ${count} dia${count > 1 ? 's' : ''} atrás`;
    } else if (Math.abs(now.diff(target, 'years') < 1)) {
      const count = parseInt(Math.abs(now.diff(target, 'months')))
      return `Há ${count} ${count > 1 ? 'meses' : 'mês'} atrás`;
    } else {
      return 'Há mais de 1 ano';
    }
}

export const getAge = (birthday) => {
  return Math.abs(moment(birthday).diff(moment(), 'YEARS'))
}

export const getTimeDiff = (time) => {
  const diff = moment(moment()).diff(time)
  const minutes = moment.duration(diff).asMinutes()
  
  if(minutes > 24*60 && minutes < 60*24*30 )
    return `Há ${Math.floor(minutes/(60*24))} dia${minutes > 2*24*60 ? 's': ''}`
  else if(minutes > 60 && minutes < 60*24)
    return `Há ${Math.floor(minutes/60)} hora${minutes > 60*2 ? 's': ''}`
  if (minutes < 2)
    return 'Agora mesmo'
  else if (minutes < 60)
    return `Há ${Math.floor(minutes)} minutos`
  else 
    return moment(time).format('DD [de] MMMM [de] YYYY')
}
