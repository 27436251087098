<!--- View de impressão - Página desenvolvida para impressão do atendimento. --->

<template>
  <div class="print-attendance">
    <div
      v-if="
        temPrescricaoMedicamentosa &&
        toPrint.includes('prescricaoMedicamentosa')
      "
    >
      <header>
        <LogoEyecare class="eyecare-logo" />

        <div class="centered-container">
          <p class="title">Precrição</p>
        </div>

        <div class="patient-data">
          <b-row class="d-flex justify-content-between">
            <b-col>
              <p class="label">Paciente</p>
              <p class="data">{{ patientData.name }}</p>
            </b-col>
            <b-col cols="auto">
              <p class="label">Data</p>
              <p class="data">{{ date.format('DD/MM/YYYY') }}</p>
            </b-col>
          </b-row>
        </div>
      </header>

      <div class="content">
        <div
          v-if="
            prescricaoMedicamentosa.filter(p => p.via === 'Ocular').length > 0
          "
        >
          <p class="section-title">Uso ocular</p>
          <ul class="medicines">
            <li
              v-for="(m, i) of prescricaoMedicamentosa.filter(
                p => p.via === 'Ocular'
              )"
              :key="i"
            >
              <p class="first-line">
                <span>{{
                  typeof m.medicamento === 'object'
                    ? m.medicamento.product
                    : m.medicamento
                }}</span>
                <span>{{ m.usoContinuo ? 'Uso contínuo' : m.quantidade }}</span>
              </p>
              <p class="second-line">{{ m.posologia }}</p>
            </li>
          </ul>
        </div>

        <div
          v-if="
            prescricaoMedicamentosa.filter(p => p.via === 'Oral').length > 0
          "
        >
          <p class="section-title">Uso oral</p>
          <ul class="medicines">
            <li
              v-for="(m, i) of prescricaoMedicamentosa.filter(
                p => p.via === 'Oral'
              )"
              :key="i"
            >
              <p class="first-line">
                <span>{{
                  typeof m.medicamento === 'object'
                    ? m.medicamento.product
                    : m.medicamento
                }}</span>
                <span>{{ m.usoContinuo ? 'Uso contínuo' : m.quantidade }}</span>
              </p>
              <p class="second-line">{{ m.posologia }}</p>
            </li>
          </ul>
        </div>

        <div v-if="prescricaoMedicamentosa.filter(p => p.via === 'Sublingual').length">
          <p class="section-title">Sublingual</p>
          <ul class="medicines">
            <li
              v-for="(m, i) of prescricaoMedicamentosa.filter(p => p.via === 'Sublingual')"
              :key="i"
            >
              <p class="first-line">
                <span>{{
                  typeof m.medicamento === 'object'
                    ? m.medicamento.product
                    : m.medicamento
                }}</span>
                <span>{{ m.usoContinuo ? 'Uso contínuo' : m.quantidade }}</span>
              </p>
              <p class="second-line">{{ m.posologia }}</p>
            </li>
          </ul>
        </div>
      </div>

      <footer>
        <p class="doctor">Dr(a). {{ professionalData.name }}</p>
        <p class="address" v-if="clinicData.address !== null">
          Endereço {{ clinicData.address.address }}
          {{ clinicData.address.number
          }}{{
            clinicData.address.complement !== null &&
            clinicData.address.complement !== ''
              ? ', ' + clinicData.address.complement + ', '
              : ', '
          }}
          {{ clinicData.address.neighborhood }}, CEP
          {{ clinicData.address.zipCode }}, {{ clinicData.address.city }} -
          {{ clinicData.address.state }}. Telefone: {{ clinicData.telephone }}.
        </p>
      </footer>
    </div>

    <div
      v-if="temSolicitacaoDeExames && toPrint.includes('solicitacaoDeExames')"
    >
      <header>
        <LogoEyecare class="eyecare-logo" />

        <div class="centered-container">
          <p class="title">Solicitação de Exames</p>
        </div>

        <div class="patient-data">
          <b-row class="d-flex justify-content-between">
            <b-col>
              <p class="label">Paciente</p>
              <p class="data">{{ patientData.name }}</p>
            </b-col>
            <b-col cols="auto">
              <p class="label">Data</p>
              <p class="data">{{ date.format('DD/MM/YYYY') }}</p>
            </b-col>
          </b-row>
        </div>
      </header>

      <div class="content">
        <p class="section-title">Solicito</p>
        <ul class="list">
          <li v-for="(e, i) of solicitadaoDeExames" :key="i">
            <p class="first-line">{{ i + 1 }}. {{ e.exame }}</p>
            <p
              class="second-line"
              v-if="e.comentario !== null && e.comentario !== ''"
            >
              {{ e.comentario }}
            </p>
          </li>
        </ul>
      </div>

      <footer>
        <p class="doctor">Dr(a). {{ professionalData.name }}</p>
        <p class="address" v-if="clinicData.address !== null">
          Endereço {{ clinicData.address.address }}
          {{ clinicData.address.number
          }}{{
            clinicData.address.complement !== null &&
            clinicData.address.complement !== ''
              ? ', ' + clinicData.address.complement + ', '
              : ', '
          }}
          {{ clinicData.address.neighborhood }}, CEP
          {{ clinicData.address.zipCode }}, {{ clinicData.address.city }} -
          {{ clinicData.address.state }}. Telefone: {{ clinicData.telephone }}.
        </p>
      </footer>
    </div>

    <div v-if="temAPA && toPrint.includes('avaliacaoPreAnestesica')">
      <header>
        <LogoEyecare class="eyecare-logo" />

        <div class="centered-container">
          <p class="title">Avaliação pré-anestésica</p>
        </div>

        <div class="patient-data">
          <b-row class="d-flex justify-content-between">
            <b-col>
              <p class="label">Paciente</p>
              <p class="data">{{ patientData.name }}</p>
            </b-col>
            <b-col cols="auto">
              <p class="label">Data</p>
              <p class="data">{{ date.format('DD/MM/YYYY') }}</p>
            </b-col>
          </b-row>
        </div>
      </header>

      <div class="content">
        <p class="section-title">Solicito</p>
        <ul class="list">
          <li v-for="(e, i) of APA" :key="i">
            <p class="first-line">{{ i + 1 }}. {{ e }}</p>
          </li>
        </ul>
      </div>

      <footer>
        <p class="doctor">Dr(a). {{ professionalData.name }}</p>
        <p class="address" v-if="clinicData.address !== null">
          Endereço {{ clinicData.address.address }}
          {{ clinicData.address.number
          }}{{
            clinicData.address.complement !== null &&
            clinicData.address.complement !== ''
              ? ', ' + clinicData.address.complement + ', '
              : ', '
          }}
          {{ clinicData.address.neighborhood }}, CEP
          {{ clinicData.address.zipCode }}, {{ clinicData.address.city }} -
          {{ clinicData.address.state }}. Telefone: {{ clinicData.telephone }}.
        </p>
      </footer>
    </div>

    <div v-if="temEncaminhamento && toPrint.includes('encaminhamento')">
      <header>
        <LogoEyecare class="eyecare-logo" />

        <div class="centered-container">
          <p class="title">Encaminhamento</p>
        </div>

        <div class="patient-data">
          <b-row class="d-flex justify-content-between">
            <b-col>
              <p class="label">Paciente</p>
              <p class="data">{{ patientData.name }}</p>
            </b-col>
            <b-col cols="auto">
              <p class="label">Data</p>
              <p class="data">{{ date.format('DD/MM/YYYY') }}</p>
            </b-col>
          </b-row>
        </div>
      </header>

      <div class="content">
        <p class="section-title">Encaminho para avaliação de:</p>
        <ul class="list">
          <li v-for="(e, i) of encaminhamento" :key="i">
            <p class="first-line">{{ i + 1 }}. {{ e.encaminhamento }}</p>
            <p
              class="second-line"
              v-if="e.comentario !== null && e.comentario !== ''"
            >
              {{ e.comentario }}
            </p>
          </li>
        </ul>
      </div>

      <footer>
        <p class="doctor">Dr(a). {{ professionalData.name }}</p>
        <p class="address" v-if="clinicData.address !== null">
          Endereço {{ clinicData.address.address }}
          {{ clinicData.address.number
          }}{{
            clinicData.address.complement !== null &&
            clinicData.address.complement !== ''
              ? ', ' + clinicData.address.complement + ', '
              : ', '
          }}
          {{ clinicData.address.neighborhood }}, CEP
          {{ clinicData.address.zipCode }}, {{ clinicData.address.city }} -
          {{ clinicData.address.state }}. Telefone: {{ clinicData.telephone }}.
        </p>
      </footer>
    </div>

    <div v-if="temOculos && toPrint.includes('prescricaoDeOculos')">
      <header>
        <LogoEyecare class="eyecare-logo" />

        <div class="centered-container">
          <p class="title">Prescrição de óculos</p>
        </div>

        <div class="patient-data">
          <b-row class="d-flex justify-content-between">
            <b-col>
              <p class="label">Paciente</p>
              <p class="data">{{ patientData.name }}</p>
            </b-col>
            <b-col cols="auto">
              <p class="label">Data</p>
              <p class="data">{{ date.format('DD/MM/YYYY') }}</p>
            </b-col>
          </b-row>
        </div>
      </header>

      <div class="content">
        <p class="section-title">Para longe</p>
        <div class="custom-input-group">
          <div class="custom-input">
            <div class="eye-area"><EyeFill /> Direito</div>
            <p class="text-area type-2 with-br">
              <span>Esférico</span>
              <span>{{ oculos.olhoDireitoEsferico }}</span>
            </p>
            <p class="text-area type-2 with-br">
              <span>Cilíndrico</span>
              <span>{{ oculos.olhoDireitoCilindrico }}</span>
            </p>
            <p class="text-area type-2 with-br">
              <span>Eixo</span>
              <span>{{ oculos.olhoDireitoEixo }}</span>
            </p>
            <p class="text-area type-2">
              <span>D.P.</span>
              <span>{{ oculos.olhoDireitoDP }}</span>
            </p>
          </div>
          <div class="custom-input no-bb">
            <div class="eye-area"><EyeFill /> Esquerdo</div>
            <p class="text-area type-2 with-br">
              <span></span>
              <span>{{ oculos.olhoEsquerdoEsferico }}</span>
            </p>
            <p class="text-area type-2 with-br">
              <span></span>
              <span>{{ oculos.olhoEsquerdoCilindrico }}</span>
            </p>
            <p class="text-area type-2 with-br">
              <span></span>
              <span>{{ oculos.olhoEsquerdoEixo }}</span>
            </p>
            <p class="text-area type-2">
              <span></span>
              <span>{{ oculos.olhoEsquerdoDP }}</span>
            </p>
          </div>
        </div>

        <p class="section-title">Para perto</p>
        <b-row>
          <b-col cols="4">
            <div class="custom-input-group">
              <div class="custom-input">
                <div class="eye-area"><EyeFill /> Direito</div>
                <p class="text-area type-2">
                  <span>Esférico</span>
                  <span>{{ oculos.olhoDireitoAdicao }}</span>
                </p>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area"><EyeFill /> Esquerdo</div>
                <p class="text-area type-2">
                  <span></span>
                  <span>{{ oculos.olhoEsquerdoAdicao }}</span>
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <footer>
        <p class="doctor">Dr(a). {{ professionalData.name }}</p>
        <p class="address" v-if="clinicData.address !== null">
          Endereço {{ clinicData.address.address }}
          {{ clinicData.address.number
          }}{{
            clinicData.address.complement !== null &&
            clinicData.address.complement !== ''
              ? ', ' + clinicData.address.complement + ', '
              : ', '
          }}
          {{ clinicData.address.neighborhood }}, CEP
          {{ clinicData.address.zipCode }}, {{ clinicData.address.city }} -
          {{ clinicData.address.state }}. Telefone: {{ clinicData.telephone }}.
        </p>
      </footer>
    </div>

    <div v-if="temAtestado && toPrint.includes('atestado')">
      <header>
        <LogoEyecare class="eyecare-logo" />

        <div class="centered-container">
          <p class="title">Atestado</p>
        </div>

        <div class="patient-data">
          <b-row class="d-flex justify-content-between">
            <b-col>
              <p class="label">Paciente</p>
              <p class="data">{{ patientData.name }}</p>
            </b-col>
            <b-col cols="auto">
              <p class="label">Data</p>
              <p class="data">{{ date.format('DD/MM/YYYY') }}</p>
            </b-col>
          </b-row>
        </div>
      </header>

      <div class="content flex">
        <div class="w-100">
          <p class="text">
            Atesto para os devidos fins, que o(a) Sr(a). {{ patientData.name }},
            inscrito no CPF n.º {{ patientData.cpf }}, foi atendido no dia
            {{ date.format('DD/MM/YYYY') }} às {{ date.format('HH') }}h e
            necessita de {{ atestado.dias }} dias afastado.
          </p>
          <p class="cid" v-if="atestado.cid !== 'Não informar'">
            CID: {{ atestado.cid }}
          </p>
        </div>

        <div class="bottom">
          <p class="date">
            {{ clinicData.address.city }},
            {{ date.format('DD [de] MMMM [de] YYYY') }}
          </p>
          <p class="patient">{{ patientData.name }}</p>
        </div>
      </div>

      <footer>
        <p class="doctor">Dr(a). {{ professionalData.name }}</p>
        <p class="address" v-if="clinicData.address !== null">
          Endereço {{ clinicData.address.address }}
          {{ clinicData.address.number
          }}{{
            clinicData.address.complement !== null &&
            clinicData.address.complement !== ''
              ? ', ' + clinicData.address.complement + ', '
              : ', '
          }}
          {{ clinicData.address.neighborhood }}, CEP
          {{ clinicData.address.zipCode }}, {{ clinicData.address.city }} -
          {{ clinicData.address.state }}. Telefone: {{ clinicData.telephone }}.
        </p>
      </footer>
    </div>

    <div
      v-if="
        temDeclaracaoDeComparecimento &&
        toPrint.includes('declaracaoDeComparecimento')
      "
    >
      <header>
        <LogoEyecare class="eyecare-logo" />

        <div class="centered-container">
          <p class="title">Declaração de Comparecimento</p>
        </div>

        <div class="patient-data">
          <b-row class="d-flex justify-content-between">
            <b-col>
              <p class="label">Paciente</p>
              <p class="data">{{ patientData.name }}</p>
            </b-col>
            <b-col cols="auto">
              <p class="label">Data</p>
              <p class="data">{{ date.format('DD/MM/YYYY') }}</p>
            </b-col>
          </b-row>
        </div>
      </header>

      <div class="content flex">
        <div class="w-100">
          <p class="text">
            Declaro para os devidos fins que o(a) Sr(a). {{ patientData.name }},
            inscrito(a) no CPF sob o número {{ patientData.cpf }} compareceu à
            consulta médica nesta data às {{ date.format('HH[h]mm') }}.
          </p>
        </div>

        <div class="bottom">
          <p class="date">
            {{ clinicData.address.city }},
            {{ date.format('DD [de] MMMM [de] YYYY') }}
          </p>
        </div>
      </div>

      <footer>
        <p class="doctor">Dr(a). {{ professionalData.name }}</p>
        <p class="address" v-if="clinicData.address !== null">
          Endereço {{ clinicData.address.address }}
          {{ clinicData.address.number
          }}{{
            clinicData.address.complement !== null &&
            clinicData.address.complement !== ''
              ? ', ' + clinicData.address.complement + ', '
              : ', '
          }}
          {{ clinicData.address.neighborhood }}, CEP
          {{ clinicData.address.zipCode }}, {{ clinicData.address.city }} -
          {{ clinicData.address.state }}. Telefone: {{ clinicData.telephone }}.
        </p>
      </footer>
    </div>

    <div
      v-if="
        temDeclaracaoDeComparecimentoAcompanhante &&
        toPrint.includes('declaracaoDeComparecimentoAcompanhante')
      "
    >
      <header>
        <LogoEyecare class="eyecare-logo" />

        <div class="centered-container">
          <p class="title">Declaração de Comparecimento</p>
        </div>

        <div class="patient-data">
          <b-row class="d-flex justify-content-between">
            <b-col>
              <p class="label">Paciente</p>
              <p class="data">{{ patientData.name }}</p>
            </b-col>
            <b-col cols="auto">
              <p class="label">Data</p>
              <p class="data">{{ date.format('DD/MM/YYYY') }}</p>
            </b-col>
          </b-row>
        </div>
      </header>

      <div class="content flex">
        <div class="w-100">
          <p class="text">
            Declaro para os devidos fins que o(a) Sr(a). {{ patientData.name }},
            inscrito(a) no CPF sob o número {{ patientData.cpf }} compareceu à
            consulta médica nesta data acompanhado(a) pelo Sr(a).
            {{ declaracaoDeComparecimentoAcompanhante.nome }}, inscrito(a) no
            CPF sob o número {{ declaracaoDeComparecimentoAcompanhante.cpf }},
            nesta data às {{ date.format('HH[h]mm') }}.
          </p>
        </div>

        <div class="bottom">
          <p class="date">
            {{ clinicData.address.city }},
            {{ date.format('DD [de] MMMM [de] YYYY') }}
          </p>
        </div>
      </div>

      <footer>
        <p class="doctor">Dr(a). {{ professionalData.name }}</p>
        <p class="address" v-if="clinicData.address !== null">
          Endereço {{ clinicData.address.address }}
          {{ clinicData.address.number
          }}{{
            clinicData.address.complement !== null &&
            clinicData.address.complement !== ''
              ? ', ' + clinicData.address.complement + ', '
              : ', '
          }}
          {{ clinicData.address.neighborhood }}, CEP
          {{ clinicData.address.zipCode }}, {{ clinicData.address.city }} -
          {{ clinicData.address.state }}. Telefone: {{ clinicData.telephone }}.
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
import LogoEyecare from '@/assets/icons/logo-eyecare-solid.svg'
import EyeFill from '@/assets/icons/eye-fill.svg'

export default {
  name: 'PrintAttendance',
  metaInfo: {
    title: 'Eyecare - Cadastro do Paciente'
  },
  components: {
    LogoEyecare,
    EyeFill
  },
  mounted() {
    // this.toPrint = this.$route.query.toPrint.split(',')
    // const dataSignature = JSON.parse(this.$route.query.signature)
    // const clinic = JSON.parse(localStorage.getItem('clinic'))

    // this.api
    //   .getAttendance(
    //     clinic.id,
    //     this.$route.params.id,
    //     this.$route.params.idConsulta
    //   )
    //   .then(res => {
    //     const resData = res.data

    //     this.date = this.moment(resData.start_datetime)
    //     this.clinicData = resData.clinic
    //     this.professionalData = resData.professional
    //     this.patientData = resData.patient
    //     this.medicalRecords = resData.medicalRecords

    //     const prescricaoMedicamentosa = resData.medicalRecords.find(
    //       mR => mR.type === 'precricao-medicamentosa'
    //     )
    //     if (typeof prescricaoMedicamentosa !== 'undefined') {
    //       this.temPrescricaoMedicamentosa = true
    //       this.prescricaoMedicamentosa = prescricaoMedicamentosa.value
    //     }

    //     const solicitacaoDeExames = resData.medicalRecords.find(
    //       mR => mR.type === 'solicitacao-de-exames'
    //     )
    //     if (typeof solicitacaoDeExames !== 'undefined') {
    //       this.temSolicitacaoDeExames = true
    //       this.solicitadaoDeExames = solicitacaoDeExames.value
    //     }

    //     const avaliacaoPreAnestecisa = resData.medicalRecords.find(
    //       mR => mR.type === 'apa-avaliacao-pre-anestesica'
    //     )
    //     if (typeof avaliacaoPreAnestecisa !== 'undefined') {
    //       this.temAPA = true
    //       this.APA = avaliacaoPreAnestecisa.value
    //     }

    //     const encaminhamento = resData.medicalRecords.find(
    //       mR => mR.type === 'encaminhamento'
    //     )
    //     if (typeof encaminhamento !== 'undefined') {
    //       this.temEncaminhamento = true
    //       this.encaminhamento = encaminhamento.value
    //     }

    //     const oculos = resData.medicalRecords.find(mR => mR.type === 'oculos')
    //     if (typeof oculos !== 'undefined') {
    //       this.temOculos = true
    //       this.oculos = oculos.value
    //     }

    //     const attendace = this.$route.params.idConsulta
    //     const atestado = resData.medicalRecords.find(
    //       mR => mR.type === 'atestados-medicos'
    //     )
    //     if (typeof atestado !== 'undefined') {
    //       this.temAtestado = atestado.value.atestado
    //       this.atestado = { dias: atestado.value.dias, cid: atestado.value.cid }

    //       this.temDeclaracaoDeComparecimento =
    //         atestado.value.declaracaoDeComparecimento

    //       this.temDeclaracaoDeComparecimentoAcompanhante =
    //         atestado.value.declaracaoDeComparecimentoAcompanhante
    //       this.declaracaoDeComparecimentoAcompanhante = {
    //         nome: atestado.value.nomeAcompanhante,
    //         cpf: atestado.value.cpfAcompanhante
    //       }
    //       this.api
    //         .sendSignatureComplete(attendace, dataSignature, 6)
    //         .then(res => {
    //           console.log('Res', res)
    //         })
    //         .catch(err => {
    //           console.log('Err', err)
    //         })
    //         .finally(() => {})
    //     }
    //   })
    //   .catch(err => {
    //     console.log('Err', err)
    //   })
    //   .finally(() => {
    //   })
  },
  data() {
    return {
      toPrint: [],

      patientData: {
        id: '',
        name: '',
        picture: '',
        medical_record_number: ''
      },
      professionalData: {
        id: '',
        name: ''
      },
      clinicData: {
        address: null
      },
      date: null,
      medicalRecords: null,

      temPrescricaoMedicamentosa: false,
      prescricaoMedicamentosa: null,

      temSolicitacaoDeExames: false,
      solicitadaoDeExames: null,

      temAPA: false,
      APA: null,

      temEncaminhamento: false,
      encaminhamento: null,

      temOculos: false,
      oculos: null,

      temAtestado: false,
      atestado: null,

      temDeclaracaoDeComparecimento: false,

      temDeclaracaoDeComparecimentoAcompanhante: false,
      declaracaoDeComparecimentoAcompanhante: null
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.print-attendance {
  text-align: left;
  color: #000000;

  > div {
    min-height: 100vh;
    height: 100%;
    padding: 75px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    header {
      .eyecare-logo {
        width: 101.92px;
        height: 20px;
        fill: var(--dark-blue);
      }

      .centered-container {
        text-align: center;
        margin: 20px 0 44px 0;

        img {
          margin-bottom: 40px;
        }

        .title {
          font-family: 'Arial';
          font-weight: 700;
          font-size: 16px;
          line-height: 21.17px;
        }
      }

      .patient-data {
        width: 100%;
        display: inline-block;

        .label {
          font-family: 'Arial';
          font-weight: 700;
          font-size: 12px;
          line-height: 15.88px;
          text-transform: uppercase;
          margin-bottom: 8px;
        }

        .data {
          font-family: 'Arial';
          font-weight: 400;
          font-size: 14px;
          line-height: 16.1px;
        }
      }
    }

    .content {
      flex: 1;

      &.flex {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }

      .section-title {
        width: 100%;
        display: inline-block;
        font-family: 'Arial';
        font-weight: 700;
        font-size: 16px;
        line-height: 21.17px;
        padding-bottom: 3px;
        border-bottom: 1px solid #000000;
        margin: 50px 0 25px 0;
      }

      .medicines {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          overflow: hidden;
          margin-bottom: 24px;

          &:before {
            float: left;
            width: 0;
            white-space: nowrap;
            content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ';
          }

          .first-line {
            width: 100%;
            display: inline-block;
            font-family: 'Arial';
            font-weight: 700;
            font-size: 14px;
            line-height: 16.1px;
            margin-bottom: 8px;

            span:first-child {
              float: left;
              background-color: #ffffff;
              padding: 0.13em 0.33em 0 0;
            }

            span:last-child {
              float: right;
              background-color: #ffffff;
              padding: 0.13em 0 0 0.33em;
            }
          }

          .second-line {
            font-family: 'Arial';
            font-weight: 400;
            font-size: 14px;
            line-height: 16.1px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          overflow: hidden;
          margin-bottom: 24px;

          .first-line {
            width: 100%;
            display: inline-block;
            font-family: 'Arial';
            font-weight: 700;
            font-size: 14px;
            line-height: 16.1px;
            margin-bottom: 8px;

            span:first-child {
              float: left;
              background-color: #ffffff;
              padding: 0.13em 0.33em 0 0;
            }

            span:last-child {
              float: right;
              background-color: #ffffff;
              padding: 0.13em 0 0 0.33em;
            }
          }

          .second-line {
            font-family: 'Arial';
            font-weight: 400;
            font-size: 14px;
            line-height: 16.1px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .text {
        width: 100%;
        display: inline-block;
        margin-top: 104px;
        font-family: 'Arial';
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }

      .cid {
        width: 100%;
        display: inline-block;
        font-family: 'Arial';
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        margin-top: 16px;
      }

      .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 80px;

        .patient {
          width: 270px;
          text-align: center;
          padding-top: 10px;
          border-top: 1px solid #000000;
          font-family: 'Arial';
          font-weight: 400;
          font-size: 12px;
          line-height: 13.8px;
          margin-top: 80px;
        }
      }

      .custom-input-group {
        display: flex;
        flex-direction: column;
        border: 1px solid #000000;
        border-radius: 8px;
        margin: 45px 0 16px 0;

        .custom-input {
          flex: 1;
          height: 38px;
          border: 8px;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid #000000;
          justify-content: center;

          &.no-bb {
            border-bottom: 0;

            .eye-area {
              border-radius: 0 0 0 8px;
            }
          }

          .eye-area {
            width: 180px;
            background-color: #e6e6e6;
            border-right: 1px solid #000000;
            display: flex;
            align-items: center;
            justify-content: start;
            border-radius: 8px 0 0 0;
            font-family: 'Arial';
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            padding: 15px;
            text-transform: uppercase;

            svg {
              width: 16px;
              height: 16px;
              fill: #000000;
              margin-right: 16px;
            }
          }

          .text-area {
            flex: 1;
            line-height: 55px;
            margin-left: 16px;
            font-weight: 400;
            font-size: 16px;
            color: #000000;

            .form-control {
              border: 0 !important;
              border-radius: 0 !important;

              &.with-bbr {
                border-radius: 0 0 8px 0 !important;
              }

              &.with-btr {
                border-radius: 0 8px 0 0 !important;
              }
            }

            &.type-2 {
              position: relative;
              margin-left: 0;
              text-align: center;

              span:first-child {
                width: 100%;
                position: absolute;
                top: -19px;
                left: 0;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                color: #000000;
              }
            }

            &.with-br {
              border-right: 1px solid #000000 !important;
            }

            &.with-brtr {
              border-radius: 0 8px 0 0 !important;
            }

            &.with-brbr {
              border-radius: 0 0 8px 0 !important;
            }
          }
        }
      }
    }

    footer {
      position: relative;
      width: 100%;
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      page-break-after: always;

      .doctor {
        width: 270px;
        text-align: center;
        padding-top: 10px;
        border-top: 1px solid #000000;
        font-family: 'Arial';
        font-weight: 400;
        font-size: 12px;
        line-height: 13.8px;
        margin-bottom: 40px;
      }

      .address {
        font-family: 'Arial';
        font-weight: 400;
        font-size: 9px;
        line-height: 10.35px;
      }
    }
  }
}
</style>
