const standardRecordMixin = () => ({
  data: () => ({
    form: {},
    formValue: {},
    loading: false,
  }),
  computed: {
    getTitleByType() {
      return {
        biomicroscopia: 'Biomicroscopia',
        oculos: 'Prescrição de óculos',
        biometria: 'Biometria',
        'mapeamento-de-retina': 'Mapeamento de retina/fundoscopia'
      }[this.form.type]
    }
  },
  methods: {
    async getStandardById() {
      const isLoading = this.$loading.show()
      this.loading = true
      try {
        const { data } = await this.api.getStandardById(this.id)
        this.form = data
        this.formValue = data?.value
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
        isLoading.hide()
      }
    },
  }
});

export default standardRecordMixin;