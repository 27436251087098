<template>
  <b-row>
    <b-col :cols="typeChangeProtocol === 'Medical' ? 9 : 5">
      <b-form-group class="form-group">
        <label for="search">Protocolos</label>
        <b-form-input
          autocomplete="off"
          id="search"
          debounce="500"
          v-model="query"
          placeholder="Buscar nome do protocolo"
        />
      </b-form-group>
    </b-col>

    <b-col cols="4" v-if="typeChangeProtocol === 'Privacidade'">
      <b-form-group id="field_category">
        <label for="field_category">Privacidade</label>
        <v-autocomplete
          v-model="type"
          :options="[
            { label: 'Privado', value: 'privado' },
            { label: 'Compartilhado', value: 'compartilhado' }
          ]"
          placeholder="Selecionar"
        />
      </b-form-group>
    </b-col>

    <b-col cols="4" v-else-if="typeChangeProtocol === 'Pharmacy'">
      <b-form-group id="field_category">
        <label for="field_category">Farmácia</label>
        <v-autocomplete
          v-model="pharmacist_name"
          :options="pharmacists"
          placeholder="Selecionar"
        />
      </b-form-group>
    </b-col>

    <b-col cols="4" v-else-if="typeChangeProtocol === 'Professional'">
      <b-form-group id="field_category">
        <label for="field_category">Profissional</label>
        <v-autocomplete
          v-model="professional"
          :options="professionals"
          placeholder="Selecionar"
        />
      </b-form-group>
    </b-col>

    <b-col cols="3">
      <b-form-group class="form-group">
        <label for="search">Via</label>
        <v-autocomplete
          v-model="via"
          :options="[
            { label: 'Ocular', value: 'ocular' },
            { label: 'Oral', value: 'oral' },
            { label: 'Sublingual', value: 'sublingual' },
            { label: 'Uso tópico', value: 'uso tópico' },
            { label: 'Intramuscular', value: 'intramuscular'},
            { label: 'Intravenosa', value: 'intravenosa'},
          ]"
          placeholder="Selecionar"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import Autocomplete from '@/components/Autocomplete'

export default {
  name: 'ProtocolFilters',
  props: {
    typeChangeProtocol: String,
    colQueryFilter: String,
    professionals: Array,
    pharmacists: Array
  },
  data() {
    return {
      query: null,
      type: null,
      via: null,
      pharmacist_name: null,
      professional: null
    }
  },
  components: {
    'v-autocomplete': Autocomplete
  },
  watch: {
    query() {
      const data = {
        query: this.query,
        type: this.type,
        via: this.via,
        professional: this.professional,
        pharmacist_name: this.pharmacist_name
      }
      this.$emit('changeFilter', data)
    },
    type() {
      const data = {
        query: this.query,
        type: this.type,
        via: this.via,
        professional: this.professional,
        pharmacist_name: this.pharmacist_name
      }
      this.$emit('changeFilter', data)
    },
    via() {
      const data = {
        query: this.query,
        type: this.type,
        via: this.via,
        professional: this.professional,
        pharmacist_name: this.pharmacist_name
      }
      this.$emit('changeFilter', data)
    },
    professional() {
      const data = {
        query: this.query,
        type: this.type,
        via: this.via,
        professional: this.professional,
        pharmacist_name: this.pharmacist_name
      }
      this.$emit('changeFilter', data)
    },
    pharmacist_name() {
      const data = {
        query: this.query,
        type: this.type,
        via: this.via,
        professional: this.professional,
        pharmacist_name: this.pharmacist_name
      }
      this.$emit('changeFilter', data)
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  width: 100%;
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 16px;
  color: var(--type-active);
  text-align: left !important;
  color: var(--dark-blue);
  margin-bottom: 4px;
}
</style>
