<template>
  <div class="overview">
    <b-container>
      <b-row>
        <b-col>
          <p class="title">{{ $t('views.overview.overviewView.visao_geral') }}</p>
        </b-col>
        <b-col class="schedule-btn">
          <b-button @click="goto('schedule-resume')" variant="outline-primary">
            <Calendar class="icon" />
            <label class="ml-2">Resumo da agenda</label>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <div class="container">
      <b-row>
        <b-col cols="9" class="mb-3">
          <b-row>
            <b-col cols="12">
              <div
                class="white-box doctor-bg"
                :class="{ closed: !highlightOpened }"
              >
                <div class="content">
                  <p class="highlight">
                    Olá 👋, <br v-if="highlightOpenedClass" /><span
                      v-if="user !== null"
                      >{{
                        user.gender === null
                          ? ''
                          : user.gender === 'M'
                          ? 'Dr. '
                          : 'Dra. '
                      }}{{ user.name }}</span
                    >
                  </p>
                  <ChevronDown
                    class="chevron"
                    @click="toggleHighlight"
                    v-if="false"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="white-box">
                <div class="content">
                  <div class="header">
                    <p class="main-title">{{ $t('views.overview.overviewView.seu_dia_hoje') }}</p>
                  </div>
                  <b-row class="your-day">
                    <b-col class="d-flex flex-row">
                      <div class="icon">
                        <Surgery />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <p class="number">
                          {{ appointmentsByType.appointment }}
                        </p>
                        <p class="info">Consultas</p>
                      </div>
                    </b-col>
                    <b-col class="d-flex flex-row">
                      <div class="icon return">
                        <Return />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <p class="number">{{ appointmentsByType.return }}</p>
                        <p class="info">Retornos</p>
                      </div>
                    </b-col>
                    <b-col class="d-flex flex-row">
                      <div class="icon telemedicine">
                        <VideoLine class="transparent-fill" />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <p class="number">
                          {{ appointmentsByType.telemedicine }}
                        </p>
                        <p class="info">Telemedicina</p>
                      </div>
                    </b-col>
                    <b-col class="d-flex flex-row">
                      <div class="icon exam">
                        <Exam />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <p class="number">{{ appointmentsByType.exam }}</p>
                        <p class="info">Exames</p>
                      </div>
                    </b-col>
                    <b-col class="d-flex flex-row">
                      <div class="icon">
                        <Procedure />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <p class="number">{{ appointmentsByType.procedure }}</p>
                        <p class="info">Procedimentos</p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-can="'LaudoMedPen1'">
            <b-col cols="12" ref="medical-reports-resume">
              <MedicalReportsStatusResume />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="white-box mb-0">
                <div class="content">
                  <div class="header">
                    <p class="main-title">Últimos pacientes atendidos</p>
                  </div>
                </div>
                <div class="tabs">
                  <span
                    class="tab"
                    :class="{ active: currentTab == 'opened' }"
                    @click="currentTab = 'opened'"
                    >Em aberto ({{
                      pausedAttendances.filter(
                        a =>
                          a.status === 'PAUSED' &&
                          a?.appointment?.status !== 'CANCELLED'
                      ).length
                    }})</span
                  >
                  <span
                    class="tab"
                    :class="{ active: currentTab == 'closed' }"
                    @click="currentTab = 'closed'"
                    >Finalizados ({{ finishedAppointments.length }})</span
                  >
                </div>
                <div class="tab-content">
                  <div v-if="currentTab == 'opened'">
                    <perfect-scrollbar>
                      <ul>
                        <li
                          v-for="attendance of pausedAttendances.filter(
                            a =>
                              a.status === 'PAUSED' &&
                              a?.appointment?.status !== 'CANCELLED'
                          )"
                          :key="attendance.id"
                        >
                          <div class="d-flex flex-row">
                            <Avatar
                              margin="0 10px 0 0"
                              :src="`${
                                attendance.patient.picture
                              }?x=${moment()}`"
                              :status="
                                attendance.appointment
                                  ? attendance.appointment.status
                                  : null
                              "
                            />

                            <div>
                              <p class="name">{{ attendance.patient.name }}</p>
                              <p class="status">
                                {{ parseStatus(attendance.appointment?.status) }} - Faltam
                                <strong
                                  >{{
                                    Math.floor(
                                      moment
                                        .duration(
                                          moment(attendance.start_datetime)
                                            .add(24, 'hour')
                                            .diff(moment())
                                        )
                                        .asHours()
                                    )
                                  }}h</strong
                                >
                                para encerrar
                              </p>
                            </div>
                          </div>
                          <div>
                            <b-button
                              variant="link"
                              class="fs-14 p-0 mr-4"
                              @click="unpauseAttendance(attendance)"
                              >Ir para consulta</b-button
                            >
                            <router-link
                              :to="'/pacientes/' + attendance.patient.id"
                            >
                              <b-button variant="link" class="fs-14 p-0"
                                >Ver perfil</b-button
                              >
                            </router-link>
                          </div>
                        </li>
                      </ul>
                    </perfect-scrollbar>
                  </div>
                  <div v-if="currentTab == 'closed'">
                    <perfect-scrollbar>
                      <ul>
                        <li
                          v-for="appointment of finishedAppointments"
                          :key="appointment.id"
                        >
                          <div class="d-flex flex-row">
                            <Avatar
                              margin="0 10px 0 0"
                              :src="appointment.patient.picture"
                              :status="appointment.status"
                            />

                            <div>
                              <p class="name">{{ appointment.patient.name }}</p>
                              <p class="status">
                                Finalizado em
                                <strong>{{
                                  moment(
                                    appointment?.attendance[0]?.end_datetime
                                  ).format('DD/MM/YYYY [às] HH[:]mm')
                                }}</strong>
                              </p>
                            </div>
                          </div>
                          <div>
                            <b-button
                              variant="link"
                              class="fs-14 p-0 mr-4"
                              @click="accessAttendance(appointment)"
                            >
                              Ir para consulta
                            </b-button>
                            <router-link
                              :to="'/pacientes/' + appointment.patient.id"
                            >
                              <b-button variant="link" class="fs-14 p-0">
                                Ver perfil
                              </b-button>
                            </router-link>
                          </div>
                        </li>
                      </ul>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3" class="mb-3">
          <div class="white-box h-100">
            <div class="content">
              <div class="header">
                <p class="main-title">Agenda de hoje</p>

                <router-link to="/agenda">
                  <b-button variant="link" class="fs-14 p-0">
                    Ver agenda
                  </b-button>
                </router-link>
              </div>

              <div class="search-patients">
                <InputSearch
                  placeholder="Buscar paciente..."
                  v-model="patientSearch"
                />
              </div>

              <div
                class="today-container"
                :class="{ closed: !highlightOpened }"
              >
                <perfect-scrollbar>
                  <ul class="today-list">
                    <div class="today-list-container">
                      <p>Situação:</p>

                      <multiselect
                        :options="schedulingStatus"
                        :showLabels="false"
                        :searchable="true"
                        :allow-empty="true"
                        v-model="schedulingStatusValue"
                        track-by="value"
                        label="label"
                        placeholder="Todos"
                        @select="onSelectStatus"
                      >
                        <template slot="caret">
                          <div class="chevron">
                            <ChevronDown />
                          </div>
                        </template>

                        <template slot="singleLabel" slot-scope="{ option }">
                          {{ option.label }}
                        </template>

                        <template slot="noOptions"> Nenhuma opção </template>

                        <template slot="noResult"> Nenhum resultado </template>
                      </multiselect>
                    </div>

                    <div
                      v-if="patientSearch && !todayAppointmentsCopy.length"
                      class="not-found-patients"
                    >
                      Nenhum paciente encontrado com esse filtro
                    </div>

                    <li
                      v-for="appointment of todayAppointmentsCopy"
                      :key="appointment.id"
                    >
                      <div
                        v-if="appointment?.attendance[0]?.status !== 'PAUSED'"
                      >
                        <TodaysAgenda :appointment="appointment" />
                      </div>
                    </li>
                  </ul>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <perfect-scrollbar>
      <b-row ref="schedule-resume">
        <ScheduleResume @update="getOverview" />
      </b-row>
    </perfect-scrollbar>

    <AcademyBanner />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getFilterToday } from '@/utils/filtersLocalStorageManager'

export default {
  name: 'OverviewView',
  metaInfo: {
    title: 'Eyecare - Visão Geral'
  },
  components: {
    Avatar: () => import('@/components/General/Avatar'),
    InputSearch: () => import('@/components/General/InputSearch'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Surgery: () => import('@/assets/icons/surgery.svg'),
    VideoLine: () => import('@/assets/icons/video-line.svg'),
    Procedure: () => import('@/assets/icons/procedure.svg'),
    Exam: () => import('@/assets/icons/exam.svg'),
    AcademyBanner: () => import('./AcademyBanner'),
    TodaysAgenda: () => import('@/components/Overview/TodaysAgenda'),
    Calendar: () => import('@/assets/icons/calendar.svg'),
    ScheduleResume: () => import('@/components/Overview/ScheduleResume'),
    Return: () => import('@/assets/icons/return.svg'),
    MedicalReportsStatusResume: () =>
      import('@/components/Overview/MedicalReportsStatusResume')
  },
  computed: {
    ...mapState('notifications', ['unreadNotifications'])
  },
  data() {
    return {
      clinicSelected: null,
      clinic: JSON.parse(localStorage.getItem('clinic')),
      schedulingStatusValue: null,
      schedulingStatus: [],
      highlightOpened: true,
      highlightOpenedClass: true,
      currentTab: 'opened',
      todayAppointments: [],
      todayAppointmentsCopy: [],
      appointmentsByType: {
        appointment: 0,
        telemedicine: 0,
        return: 0,
        procedure: 0,
        exam: 0
      },
      pausedAttendances: [],
      finishedAppointments: [],
      user: JSON.parse(localStorage.getItem('user')),
      doctorId: null,
      patientSearch: null,
      reloadInterval: null
    }
  },
  watch: {
    schedulingStatusValue: function (el) {
      if (el.value) {
        this.todayAppointmentsCopy = this.todayAppointments.filter(
          item => item.status === el.value
        )
      } else {
        this.todayAppointmentsCopy = [...this.todayAppointments]
      }
    },
    patientSearch(val) {
      if (val) {
        this.todayAppointmentsCopy = this.todayAppointments.filter(
          item =>
            item.patient &&
            item.patient.name.toLowerCase().includes(val.toLowerCase())
        )
        return
      }
      this.todayAppointmentsCopy = [...this.todayAppointments]
    }
  },
  async created() {
    this.reloadInterval = setInterval(() => {
      this.getOverview()
    }, 60000)
    await this.getOverview()
  },
  beforeDestroy() {
    this.reloadInterval && clearInterval(this.reloadInterval)
    this.reloadInterval = null
  },
  methods: {
    hasExam(appointment) {
      return appointment.appointment_items.some(
        appointmentItem => appointmentItem.item?.type === 'EXAM'
      )
    },
    async getOverview() {
      this.clinicSelected = this.clinic
      const isLoading = this.$loading.show()
      try {
        const res = await this.api.getOverview(this.clinicSelected.id)
        const resData = res.data
        this.todayAppointments = resData.appointments.filter(
          appointment =>
            !appointment.appointment_items.some(
              appointmentItem => appointmentItem.item?.type === 'SURGICAL'
            )
        )
        this.todayAppointmentsCopy = [...this.todayAppointments]
        this.schedulingStatusValue = getFilterToday()
        this.setupStatus(this.todayAppointments)
        this.appointmentsByType = resData.byType
        this.pausedAttendances = resData.pausedAttendances
        const finishedAppointments = resData.finishedAppointments.filter(
          appointment =>
            !appointment.appointment_items.some(appointmentItem =>
              ['PRE_CONSULTATION'].includes(appointmentItem.item?.type)
            )
        )

        const attendanceTypes = ['ATTENDANCE', 'EXAM', 'SURGERY']

        this.finishedAppointments = finishedAppointments?.map(appointment => ({
          ...appointment,
          attendance: appointment.attendance.sort((a, b) => {
            return (
              attendanceTypes.findIndex(type => type === a.type) -
              attendanceTypes.findIndex(type => type === b.type)
            )
          })
        }))
      } catch (err) {
        this.$toast.error(
          'Não foi possível carregar a visão geral, contate o suporte se o erro persistir.'
        )
      } finally {
        isLoading.hide()
      }
    },
    setupStatus(appointments) {
      const schedulingStatus = [
        ...new Set(appointments.map(item => item.status))
      ].map(status => ({ value: status, label: this.parseStatus(status) }))

      schedulingStatus.unshift({ value: null, label: 'Todos' })

      this.schedulingStatus = schedulingStatus
    },
    parseStatus(status) {
      const getStatus = {
        CANCELLED: 'Cancelado',
        CONFIRMED: 'Confirmado',
        MISSED: 'Faltou',
        SCHEDULED: 'Sem situação',
        ATTEND: 'Compareceu',
        FINISHED: 'Finalizado',
        WAITING: 'Aguardando atendimento',
        HAPPENING: 'Em atendimento',
        EXAMINATING: 'Em exames',
        DILATING: 'Dilatando',
        PRE_FINISHED: 'Pré consulta',
        IN_PRE_CONSULTATION: 'Em pré-consulta'
      }
      return getStatus[status]
    },
    onSelectStatus(option) {
      localStorage.setItem('filterToday', JSON.stringify(option))
      if (!option.value) {
        this.todayAppointmentsCopy = [...this.todayAppointments]
        return
      }
      this.todayAppointmentsCopy = this.todayAppointments.filter(
        item => item.status === option.value
      )
    },
    toggleHighlight: async function () {
      this.highlightOpened = !this.highlightOpened
      if (this.highlightOpened) {
        setTimeout(() => {
          this.highlightOpenedClass = this.highlightOpened
        }, 500)
      } else {
        this.highlightOpenedClass = this.highlightOpened
      }
    },
    async unpauseAttendance(attendance) {
      try {
        await this.api.unpauseAttendance(this.clinic.id, attendance.id)
        this.$router.push(
          '/pacientes/' + attendance.patient.id + '/consulta/' + attendance.id
        )
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    accessAttendance(appointment) {
      this.$router.push(
        `/pacientes/${appointment.patient.id}/consulta/${appointment.attendance[0].id}`
      )
    },
    goto(refName) {
      this.$refs[refName].scrollIntoView({ behavior: 'smooth' })
    },
    getUnreadNotificationsMsg(type) {
      const plural = this.unreadNotifications[type] > 1 ? 's' : ''
      return `Você possui ${this.unreadNotifications[type]} novo${plural} aviso${plural} não visualizado${plural}`
    }
  }
}
</script>
<style lang="scss">
.h-1200 {
  height: 1200px;
}
.overview {
  height: 100%;
  padding: 30px 24px;
  text-align: left;

  .title {
    text-align: start;
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
    margin-bottom: 27px;
  }

  .schedule-btn {
    text-align: end;

    button {
      label {
        cursor: pointer;
        font-weight: 700;
        color: var(--blue-500);
        margin-bottom: 0 !important;
        vertical-align: bottom;
      }
      svg {
        width: 20px;
        height: 20px;
        fill: var(--blue-500);
      }
    }
  }

  .container {
    width: 100%;
    max-width: 100%;
    padding: 0;

    .white-box {
      width: 100%;
      background-color: var(--neutral-000);
      border-radius: 8px;
      margin-bottom: 16px;

      &.doctor-bg {
        background-color: var(--blue-100);
        height: 205px;
        display: flex;
        position: relative;
        transition: all 0.5s;

        .content {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .mr-24 {
            margin-right: 24px;
          }

          .badge {
            width: 58px;
            height: 24px;
            border-radius: 12px;
            font-weight: 600;
            font-size: 12px;
            color: var(--neutral-000);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;

            .arrow {
              width: 8px;
              height: 8px;
              stroke: var(--neutral-000);
              margin-right: 4px;
            }

            &.green {
              background: var(--states-success);
            }

            &.blue {
              background: var(--blue-500);
            }
          }

          .text {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--type-active);
            margin: 16px 0;
          }
        }

        .chevron {
          width: 24px;
          height: 24px;
          position: absolute;
          top: 16px;
          right: 16px;
          cursor: pointer;
          stroke: var(--neutral-500);
          transition: all 0.5s;
          transform: rotate(180deg);
        }

        &.closed {
          height: 56px;

          .highlight {
            font-size: 18px;
          }

          .highlight-content {
            display: flex;
            flex-direction: row;

            .text {
              width: 305px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0 16px;
            }
          }

          .chevron {
            transform: rotate(0deg);
          }
        }
      }

      .content {
        padding: 24px;

        .highlight {
          font-family: 'Red Hat Display';
          font-weight: 700;
          font-size: 32px;
          color: var(--dark-blue);
        }

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;

          .main-title {
            font-family: 'Red Hat Display';
            font-weight: 700;
            font-size: 18px;
            color: var(--dark-blue);

            span {
              color: var(--orange);
            }
          }
        }

        .search-patients {
          margin-bottom: 15px;
        }

        .not-found-patients {
          text-align: center;
          color: var(--type-placeholder);
          margin: 20px 0;
        }

        .your-day {
          .icon {
            width: 48px;
            height: 38px;
            background-color: var(--light-orange-500);
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 16px;

            svg {
              width: 20px;
              height: 20px;
              fill: var(--neutral-000);

              &.transparent-fill {
                stroke: var(--neutral-000);
                fill: transparent;
              }
            }

            &.exam {
              svg {
                width: 20px;
                height: 20px;
                fill: var(--neutral-000);
              }
            }

            &.telemedicine {
              svg {
                width: 20px;
                height: 20px;
                transform: scale(0.909);
              }
            }
          }

          .number {
            font-family: 'Red Hat Display';
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: var(--dark-blue);
          }

          .info {
            font-family: 'Red Hat Display';
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
          }
        }

        .news-list {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: var(--type-active);
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 0;
            }

            .tag {
              width: 50px;
              text-align: center;
              display: inline-block;
              font-weight: 600;
              font-size: 12px;
              line-height: 15.6px;
              padding: 2px 8px;
              border-radius: 50px;
              margin-right: 16px;

              &.blue {
                background-color: var(--blue-500);
                color: var(--neutral-000);
              }

              &.yellow {
                background-color: var(--yellow);
                color: var(--neutral-600);
              }
            }
          }
        }

        .today-container {
          .ps {
            transition: all 0.5s;
          }

          .today-list {
            display: flex;
            flex-direction: column;
            list-style-type: none;
            margin: 0;
            padding: 0;

            .today-list-container {
              display: flex;
              flex-direction: row;

              p {
                font-family: 'Nunito Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: var(--type-placeholder);
              }

              .multiselect {
                width: auto;
                height: 24px;

                .multiselect__tags {
                  min-height: 24px;
                  height: 24px;
                  align-items: start;

                  .multiselect__placeholder {
                    font-family: 'Nunito Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    color: #8696ac;
                  }

                  .multiselect__input {
                    font-family: 'Nunito Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    color: #8696ac;
                  }

                  .multiselect__single {
                    font-family: 'Nunito Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    color: var(--type-active);
                  }
                }

                &.multiselect--active {
                  width: auto;
                  z-index: 0;
                }

                .multiselect__content-wrapper {
                  margin: 0;
                  padding: 0;
                  max-height: auto !important;
                  width: 12vw;

                  .multiselect__content {
                    li {
                      padding: 0;
                      margin: 0;

                      span {
                        display: flex;
                        align-items: center;
                        padding: 0;
                        margin: 0;
                        padding: 0 0 0 8px;
                      }
                    }
                  }
                }
              }

              svg {
                stroke: #305bf2;
              }
            }

            li {
              display: flex;
              flex-direction: column;

              > div > div {
                padding: 16px 0;
                border-bottom: 1px solid var(--neutral-200);
              }

              .time {
                font-weight: 700;
                font-size: 16px;
                line-height: 1.5;
                color: var(--type-placeholder);
              }

              &:first-child {
                padding-top: 0;

                .time {
                  color: var(--orange);
                }
              }

              .line {
                display: flex;
                flex-direction: row;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 8px;

                .avatar-container {
                  height: 32px;
                  width: 40px;
                  position: relative;

                  .avatar {
                    width: 32px;
                    height: 32px;
                    margin-right: 8px;
                  }

                  .avatar-status {
                    height: 15px;
                    width: 15px;
                    position: absolute;
                    border-radius: 100%;
                    bottom: 0;
                    right: 0;
                    cursor: pointer;

                    &.scheduled {
                      background: var(--neutral-000);
                      border: 3px solid #a4b1df;
                    }

                    &.confirmed {
                      background: #046340;
                      border: 3px solid #f2ffe6;
                    }
                    &.confirmed_by_patient {
                      background: #046340;
                      border: 3px solid #f2ffe6;
                    }

                    &.attend {
                      background: var(--blue-700);
                      border: 3px solid #e6f5ff;
                    }

                    &.missed {
                      background: #d82828;
                      border: 3px solid #fff7f7;
                    }

                    &.cancelled {
                      background: #ff6b00;
                      border: 3px solid #fff3e1;
                    }
                    &.cancelled_by_patient {
                      background: #ff6b00;
                      border: 3px solid #fff3e1;
                    }
                  }
                }

                .name {
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 23.76px;
                  color: var(--type-active);
                  flex: 1;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-left: 10px;
                }
              }

              .appointment-type {
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 12px;
                color: var(--type-placeholder);
                margin-bottom: 6px;

                .icon {
                  width: 20px;
                  height: 20px;
                  stroke: transparent;
                  fill: var(--type-placeholder);
                  margin-right: 8px;
                }
              }

              .buttons {
                margin-top: 10px;
              }
            }
          }
        }
      }

      .tabs {
        margin: -30px 24px 0 24px;
        height: 45px;
        border-bottom: 1px solid var(--neutral-200);
        display: flex;
        align-items: center;

        .tab {
          font-weight: 700;
          font-size: 16px;
          color: var(--type-active);
          padding: 4px 24px 13px 24px;
          cursor: pointer;
          border-bottom: 3px solid var(--neutral-000);

          &.active {
            color: var(--orange);
            border-bottom: 4px solid var(--orange);
          }
        }
      }

      .tab-content {
        padding-bottom: 30px;

        .ps {
          padding: 0 24px;
          height: 335px;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            padding: 24px 0;
            border-bottom: 1px solid var(--neutral-200);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .avatar {
              width: 40px;
              height: 40px;
              border-radius: 20px;
              margin-right: 16px;
            }

            .name {
              width: 240px;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: var(--type-active);
            }

            .status {
              font-weight: 600;
              font-size: 12px;
              line-height: 15.6px;
              color: var(--type-placeholder);

              span {
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background-color: var(--light-orange-500);
                margin-right: 8px;
                display: inline-block;

                &.ok {
                  background-color: var(--states-success);
                }
              }

              b {
                font-weight: 600;
                color: var(--type-active);
              }
            }
          }
        }
      }

      .messages-container {
        margin-top: -30px;
        padding-bottom: 30px;

        .ps {
          overflow-x: hidden;
          height: 312px;
        }

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            position: relative;
            padding: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            &:before {
              width: calc(100% - 48px);
              height: 1px;
              background-color: var(--neutral-200);
              position: absolute;
              bottom: 0;
              left: 24px;
              content: ' ';
            }

            &:hover {
              background-color: var(--neutral-100);
            }

            .image-container {
              width: 52px;
              height: 40px;
              margin-right: 16px;
              position: relative;

              img {
                width: 40px;
                height: 40px;
              }

              span {
                width: 24px;
                height: 24px;
                border-radius: 12px;
                background-color: var(--yellow);
                display: inline-block;
                text-align: center;
                font-weight: 700;
                font-size: 12px;
                line-height: 24px;
                color: var(--dark-blue);
                position: absolute;
                top: 0;
                right: 0;
              }
            }

            .name {
              flex: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            svg {
              width: 20px;
              height: 20px;
              stroke: var(--neutral-500);
              margin-left: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
