
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import mixpanel from 'mixpanel-browser';
mixpanel.init('bc7b1c9f73cbd74b0a5167acc5d0f31a');

export const track = (event, props) => {
    if (process.env.NODE_ENV === 'production') {

      const clinic = getCurrentClinic()
      const user = getCurrentUser()

      mixpanel.track(event, {
        ...props,
        user: user.name,
        type: user.type,
        clinic: clinic.name,
      });
    }
};

export const identify = (user) => {
  mixpanel.identify(user.id);
  mixpanel.people.set({
    name: user.name,
  });
}

