<template>
  <div class="wh-container">
    <div class="header no-print">
      <p class="title">Estoque</p>
      <div>
        <b-button v-can="'EstSai2'" class="wh-button" variant="outline-primary"
                  outline @click="$bvModal.show('warehouse-output-modal')"
        >
          <span class="icon-box">
            <MinusRounded class="icon stroke" />
          </span>
          Efetuar saída
        </b-button>
        <b-button v-can="'EstEnt2'" class="wh-button" variant="outline-primary"
                  outline @click="$bvModal.show('warehouse-entry-modal')"
        >
          <span class="icon-box">
            <Plus class="icon stroke" />
          </span>
          Efetuar entrada
        </b-button>
        <b-button v-can="'EstProd2'" class="whw-button" variant="primary" outline
                  @click="$bvModal.show('product-modal')">
          Cadastrar novo produto
        </b-button>
      </div>
    </div>

    <WarehouseOutputModal
      :output="output"
      :openBillToReceiveProductModal="openBillToReceiveProductModal"
    />

    <WarehouseEntryModal
      :entry="entry"
      :openBillToPayProductModal="openBillToPayProductModal"
      @onHide="hideEntryModal"
    />

    <GenericAttachFileModal
      file-type="arquivo"
      :editing-file="currentFile"
      @saveFile="saveFile"
    />


    <ProductModal :product="product" />

    <BillToReceiveProductModal
      :billToReceive="billToReceive"
      @onHide="onHideBillToReceiveProductModal"
    />

    <BillToPayProductModal
      :billToPay="billToPay"
      :entry="entry"
      @onHide="onHideBillToPayProductModal"
      @onFinish="saveAll"
    />

    <Tab
      ref="warehouse_tabs"
      :index="currentTabIndex"
      v-bind:keys="tabs"
      v-on:onChange="onChangeTab"
    >
      <Dashboard
        v-if="this.tabs[currentTabIndex] === 'Dashboard'"
        :onChangeTab="onChangeTab"
        :openBillToReceiveProductModal="openBillToReceiveProductModal"
      />
      <ProductsTab
        v-if="this.tabs[currentTabIndex] === 'Produtos'"
        :onProductEditClick="onProductEditClick"
      />
      <KitTemplatesTab
        v-if="this.tabs[currentTabIndex] === 'Template de Kit'"
      />
      <KitsTab
        v-if="this.tabs[currentTabIndex] === 'Kits'"
      />
      <EntriesTab
        v-if="this.tabs[currentTabIndex] === 'Entradas'"
        :onEditClick="onEditClick"
        ref="entries"
      />
      <OutputsTab
        v-if="this.tabs[currentTabIndex] === 'Saídas'"
        :onEditOutputClick="onEditOutputClick"
      />
      <BatchesTab
        v-if="this.tabs[currentTabIndex] === 'Lotes'"
        :onEditOutputClick="onEditOutputClick"
      />
      <ProvidersTab v-if="this.tabs[currentTabIndex] === 'Fornecedores'" />
      <ConfigurationsTab v-if="this.tabs[currentTabIndex] === 'Configurações'" />
    </Tab>

  </div>
</template>

<script>
import { userHasPermission } from '@/utils/localStorageManager'
import { mapActions, mapGetters } from 'vuex'
import { track } from '@/utils/mixPanel'

export default {
  name: 'Warehouse',
  metaInfo: {
    title: 'Eyecare - Estoque'
  },
  components: {
    MinusRounded: () => import('@/assets/icons/minus-rounded.svg'),
    Plus: () => import('@/assets/icons/plus-blue.svg'),
    Tab: () => import('@/components/Tab.vue'),
    WarehouseOutputModal: () => import('@/components/Warehouse/WarehouseOutputModal'),
    WarehouseEntryModal: () => import('@/components/Warehouse/WarehouseEntryModal.vue'),
    Dashboard: () => import('./DashboardTab.vue'),
    ProductModal: () => import('@/components/Warehouse/ProductModal'),
    KitsTab: () => import('@/layouts/Warehouse/Kits/KitsTab.vue'),
    KitTemplatesTab: () => import('@/layouts/Warehouse/Kits/KitTemplatesTab.vue'),
    ProductsTab: () => import('./ProductsTab.vue'),
    ProvidersTab: () => import('./ProvidersTab.vue'),
    EntriesTab: () => import('./EntriesTab.vue'),
    OutputsTab: () => import('./OutputsTab.vue'),
    BatchesTab: () => import('./BatchesTab.vue'),
    ConfigurationsTab: () => import('./ConfigurationsTab.vue'),
    BillToReceiveProductModal: () => import('@/components/Financial/BillToReceiveProductModal'),
    BillToPayProductModal: () => import('@/components/Financial/BillToPayProductModal'),
    GenericAttachFileModal: () => import('@/components/General/GenericAttachFileModal')
  },
  mounted() {
    this.handleTabIndex()
    Object.keys(this.tabKeys).map(key => {
      userHasPermission(key) &&
      this.tabs.push(this.tabKeys[key])
    })
  },
  model: {
    prop: 'currentIndex',
    event: 'onChange'
  },
  data() {
    return {
      tabs: [],
      clinic: JSON.parse(localStorage.getItem('clinic')),
      entry: null,
      file: null,
      product: null,
      output: null,
      billToReceive: null,
      billToPay: null,
      currentTabIndex: 0,
      products: [],
      productsCount: 0,
      tabKeys: {
        'EstDash1': 'Dashboard',
        'EstProd1': 'Produtos',
        'EstTempKits1': 'Template de Kit',
        'EstKits1': 'Kits',
        'EstEnt1': 'Entradas',
        'EstSai1': 'Saídas',
        'EstLot1': 'Lotes',
        'EstFor1': 'Fornecedores',
        'EstConf1': 'Configurações'
      }
    }
  },
  computed: {
    ...mapGetters('warehouse', ['getSelectedEntry', 'getSelectedFile', 'getBillToPay']),
    currentFile() {
      return this.getSelectedFile
    }
  },
  methods: {
    ...mapActions('warehouse', ['updateSelectedFile', 'updateSelectedEntry', 'clearState']),
    onEditClick(entry) {
      this.entry = {
        ...entry,
        transactions: entry.warehouse_product_transactions
      }
      this.updateSelectedEntry(this.entry)
      this.updateSelectedFile(this.entry.files[0])
      this.$bvModal.show('warehouse-entry-modal')
    },
    hideEntryModal() {
      this.clearState()
      this.entry = null
    },
    onEditOutputClick(output) {
      this.output = output
      this.$bvModal.show('warehouse-output-modal')
    },
    onProductEditClick(product) {
      this.product = { ...product }
      this.$bvModal.show('product-modal')
    },
    onChangeTab(index, dontPush) {
      this.currentTabIndex = index
      if (!dontPush) {
        window.history.pushState(null, null, `/estoque/${this.getPathByIndex(index)}`)
      }
    },
    async saveAll() {
      if (this.getSelectedEntry?.id) {
        await this.updateAll()
      } else {
        await this.createAll()
      }
      this.clearState()
      this.getPathByIndex(this.currentTabIndex) === 'entradas' && this.$refs.entries.getWarehouseEntries(false)
    },
    async createAll() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.createWarehouseEntry({
          ...this.getSelectedEntry,
          clinic_id: this.clinic.id,
          provider_id: this.getSelectedEntry.provider ? this.getSelectedEntry.provider.id : null
        })
        this.updateSelectedEntry({ ...this.getSelectedEntry, id: data.id })
        if (this.getSelectedEntry?.files?.length > 0) {
          await this.saveFile(this.getSelectedEntry.files[0])
        }
        track('entrada_de_produtos', {
          clinic: this.clinic.name
        })
        const bill = this.getBillToPay
        bill.billProducts.forEach(billProduct => {
          billProduct.warehouse_entry_id = data.id
        })
        await this.api.createBill(bill)
        isLoading.hide()
        this.$toast.success('Entrada e Conta a pagar cadastrada com sucesso!')
        this.$bvModal.hide('warehouse-entry-modal')
      } catch (ex) {
        isLoading.hide()
        this.$toast.error(ex.message)
      }
    },
    async updateAll() {
      const isLoading = this.$loading.show()
      try {
        await this.api.updateWarehouseEntry(this.getSelectedEntry.id, {
          ...this.getSelectedEntry,
          clinic_id: this.clinic.id,
          provider_id: this.getSelectedEntry.provider ? this.getSelectedEntry.provider.id : null
        })
        const bill = this.getBillToPay
        bill.billProducts.forEach(billProduct => {
          billProduct.warehouse_entry_id = this.getSelectedEntry.id
          billProduct.bill_id = this.getBillToPay.id
        })
        await this.api.updateWarehouseEntryBill(this.getSelectedEntry.id, bill)
        isLoading.hide()
        this.$toast.success('Entrada e Conta a pagar cadastrada com sucesso!')
        this.$bvModal.hide('warehouse-entry-modal')
      } catch (ex) {
        isLoading.hide()
        this.$toast.error(ex.message)
      }
    },
    async saveFile(file) {
      await this.updateSelectedFile(file)
      if (this.getSelectedEntry?.id) {
        const isLoading = this.$loading.show()
        if (file.id) {
          await this.api.updateWarehouseEntryFile(
            file.id,
            { filename: file.filename }
          ).then(() => {
            this.$toast.success('Arquivo atualizado com sucesso!')
          }).catch(
            () => {
              this.$toast.error('Ocorreu um erro ao atualizar o arquivo!')
            }
          ).finally(() => {
            isLoading.hide()
          })
        } else {
          const dataForm = new FormData()
          dataForm.append(`file`, file.file)
          dataForm.append(`filename`, file.filename ?? file.file.name)
          dataForm.append('clinic_id', this.clinic.id)
          dataForm.append('warehouse_entry_id', this.getSelectedEntry.id)
          await this.api.createWarehouseEntryFile(
            dataForm
          ).then(() => {
            this.$toast.success('Arquivo criado com sucesso!')
          }).catch(
            () => {
              this.$toast.error('Ocorreu um erro ao criar o arquivo!')
            }
          ).finally(() => {
            isLoading.hide()
          })
        }
        this.getPathByIndex(this.currentTabIndex) === 'entradas' && this.$refs.entries.getWarehouseEntries(false)
      }
    },
    handleTabIndex() {
      switch (this.$route.params.tab) {
        case 'dashboard':
          this.currentTabIndex = 0
          break
        case 'produtos':
          this.currentTabIndex = 1
          break
        case 'template-kits':
          this.currentTabIndex = 2
          break
        case 'kits':
          this.currentTabIndex = 3
          break
        case 'entradas':
          this.currentTabIndex = 4
          break
        case 'saidas':
          this.currentTabIndex = 5
          break
        case 'lotes':
          this.currentTabIndex = 6
          break
        case 'fornecedores':
          this.currentTabIndex = 7
          break
        case 'configuracoes':
          this.currentTabIndex = 8
          break
      }
    },
    getPathByIndex(index) {
      switch (index) {
        case 0:
          return 'dashboard'
        case 1:
          return 'produtos'
        case 2:
          return 'template-kits'
        case 3:
          return 'kits'
        case 4:
          return 'entradas'
        case 5:
          return 'saidas'
        case 6:
          return 'lotes'
        case 7:
          return 'fornecedores'
        case 8:
          return 'configuracoes'
      }
    },
    getProducts(clinicId, page, query, provider, start, end, quantity) {
      const isLoading = this.$loading.show()
      this.api.getProducts(clinicId, page, query, provider, start, end, quantity)
        .then(res => {
          this.products = res.data.data
          this.productsCount = res.data.total
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },
    openBillToReceiveProductModal(billToReceive) {
      this.billToReceive = billToReceive
      this.$bvModal.show('bill-to-receive-product-modal')
    },
    onHideBillToReceiveProductModal() {
      this.billToReceive = null
    },
    openBillToPayProductModal(billToReceive) {
      this.billToPay = billToReceive
      this.$bvModal.show('bill-to-pay-product-modal')
    },
    onHideBillToPayProductModal() {
      this.billToPay = null
    }
  }
}
</script>

<style lang="scss" scoped>
.wh-container {
  height: 100%;
  padding: 30px 24px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
  }

  .wh-button {
    margin: 0 5px;
    border-color: var(--blue-500);
    color: var(--blue-500);
  }

  .whw-button {
    margin: 0 5px;
    color: white;
  }
}
</style>

