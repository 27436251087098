<template>
  <div class="course-dashboard-container">
    <div class="title">{{ course?.name }}</div>
    <div class="row">
      <div class="col-4">
        <NumberCard
          title="Qtde. módulos"
          :number="course?.modules.length"
        />
      </div>
      <div class="col-4">
        <NumberCard
          title="Qtde. aulas"
          :number="countVideoClass()"
        />
      </div>
      <div class="col-4">
        <NumberCard
          title="Acessos únicos"
          :number="uniqueViews"
        />
      </div>
      <div class="col-4">
        <NumberCard
          title="Qtde. comentários"
          :number="commentsCount"
        />
      </div>
      <div class="col-4">
        <NumberCard
          title="Qtde. curtidas em comentários"
          :number="commentReactionsCount"
        />
      </div>
      <div class="col-4">
        <NumberCard
          title="Adoção médica"
          suffix="%"
          :number="adoptionPercent"
        />
      </div>
    <!-- </div> -->

      <div class="col-4">
        <div>Visualizações por aula</div>
        <VueApexCharts
          type="polarArea"
          width="100%"
          height="auto"
          :options="{
            labels: videoClassPolarArea.labels,
            stroke: { colors: ['#fff'] },
            fill: { opacity: 0.8 },
            yaxis: { show: false },
            legend: { position: 'bottom' },
          }"
          :series="videoClassPolarArea.series"
        />
      </div>

      <div class="col-8">
        <div>Status de visualização por aula</div>
        <VueApexCharts
          type="bar"
          width="100%"
          :options="{
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              stackType: '100%'
            },
            labels: videoClassStatusBar.labels,
            stroke: { colors: ['#fff'] },
            fill: { opacity: 0.8 },
            yaxis: { show: false },
            legend: { position: 'bottom' },
          }"
          :series="videoClassStatusBar.series"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import NumberCard from '@/components/Academy/Dashboard/NumberCard'

export default {
  metaInfo: {
    title: 'EyecareBI - Relatório de curso'
  },
  components: { VueApexCharts, NumberCard },
  async mounted() {
    await this.getCourse(this.$route.params.courseId)
    this.getUniqueViews()
    this.getCommentsCount()
    this.getCommentReactionsCount()
    this.getAdoptionPercent()
    this.getVideoClassPolarArea()
    this.getVideoClassStatusBar()
  },
  data() {
    return {
      course: null,
      uniqueViews: 0,
      commentsCount: 0,
      commentReactionsCount: 0,
      adoptionPercent: 0,
      videoClassPolarArea: { labels: [], series: [] },
      videoClassStatusBar: { labels: [], series: [] },
    }
  },
  methods: {
    async getCourse(courseId) {
      const loading = this.$loading.show()
      try {
        const res = await this.api.showCourse(courseId)
        this.course = res.data
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    getUniqueViews() {
      this.api.getUniqueViews(this.course.id)
      .then(res => (this.uniqueViews = res.data.uniqueViews))
    },
    getCommentsCount() {
      this.api.getCommentsCount(this.course.id)
      .then(res => (this.commentsCount = res.data.commentsCount))
    },
    getCommentReactionsCount() {
      this.api.getCommentReactionsCount(this.course.id)
      .then(res => (this.commentReactionsCount = res.data.commentReactionsCount))
    },
    getAdoptionPercent() {
      this.api.getAdoptionPercent(this.course.id)
      .then(res => (this.adoptionPercent = res.data.adoptionPercent))
    },
    getVideoClassPolarArea() {
      this.api.getVideoClassPolarArea(this.course.id)
      .then(res => (this.videoClassPolarArea = res.data))
    },
    getVideoClassStatusBar() {
      this.api.getVideoClassStatusBar(this.course.id)
      .then(res => (this.videoClassStatusBar = res.data))
    },
    countVideoClass() {
      if (!this.course) return 0
      return this.course.modules.reduce((acc, module) => {
        acc += module.video_classes.length
        return acc
      }, 0)
    }
  }
}
</script>
<style lang="scss" scoped>
.course-dashboard-container {
  padding: 20px;
}
.chart {
  position: relative;
  width: 100%;
}
</style>
