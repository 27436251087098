import Vue from 'vue'
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'

const loadCallPanelDepartments = async (context) => {
  try {
    const clinic = getCurrentClinic()
    const res = await Vue.prototype.api.getCallPanelDepartments(clinic.id, { active:true })
    context.commit('setDepartments', res.data)
  } catch(err) {
    Vue.prototype.$toast.error(err.message)
  }
}

const setCurrentCallPanelPassword = async(context, payload)  => {
  context.commit('setCurrentCallPanelPassword', payload)
}


const getCurrentPassword = async(context)  => {
  try {
    const res = await Vue.prototype.api.getCurrentPassword(context.state.department.id, context.state.listener?.id)
    context.commit('setCurrentCallPanelPassword', res.data?.id ? res.data : null)
  } catch(err) {
    Vue.prototype.$toast.error(err.message)
  }

}

const setDepartment = async(context, payload)  => {
  context.commit('setListener', null)
  context.commit('setDepartment', payload)
  payload && context.commit('setListeners', payload.listeners)
  payload && createCallPanelCheckIn(payload.id)
  payload && await loadNextPasswords(context, payload.id)
}

const setListener = async(context, payload)  => {
  context.commit('setListener', payload)
  payload && createCallPanelCheckIn(payload.department_id, payload.id)
}

const loadCalledPasswords = async(context, payload)  => {
  try {

  } catch(err) {
    Vue.prototype.$toast.error(err.message)
  }
}

const loadNextPasswords = async(context, payload)  => {
  try {
    const res = await Vue.prototype.api.getNextCallPanelPasswords(payload)
    context.commit('setNextPasswords', res.data)
  } catch(err) {
    context.commit('setNextPasswords', [])
    Vue.prototype.$toast.error(err.message)
  }
}


const createCallPanelCheckIn = (departmentId, listenerId = null) => {
  const user = getCurrentUser()
  const props = {
    person_id: user.id,
    department_id: departmentId,
    listener_id: listenerId
  }
  Vue.prototype.api.createCallPanelCheckIn(props)
    .then(() => console.log('success call panel check-in'))
}

export default {
  getCurrentPassword,
  setCurrentCallPanelPassword,
  loadCallPanelDepartments,
  setDepartment,
  setListener,
  loadCalledPasswords,
  loadNextPasswords,
}
