import Vue from 'vue'
import { getCurrentClinic } from '@/utils/localStorageManager'

const getPendingAppointmentItems = async (context, patientId) => {
  const clinic = getCurrentClinic()
  context.commit('setLoading', true)
  Vue.prototype.api.getPendingAppointmentItems(context.state.page, clinic.id, patientId)
    .then(({ data }) => {
      context.commit('setPendingItems', data)
    })
    .catch(err => {
      console.log(err);
      Vue.prototype.$toast.error(err.message)
    })
    .finally(() => {
      context.commit('setLoading', false)
    })
}

const changePage = (context, page) => {
  context.commit('changePage', page)
}

const changeCheckedItems = (context, checkedItems) => {
  context.commit('setCheckedItems', checkedItems)
}

export default {
  getPendingAppointmentItems,
  changeCheckedItems,
  changePage,
}
