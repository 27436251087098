var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wh-container"},[_c('div',{staticClass:"header"},[_c('p',{staticClass:"title"},[_vm._v("Protocolos")]),_c('div',[(_vm.doctorOrDoctorManager)?_c('b-button',{staticClass:"wh-button no-print",attrs:{"variant":"primary","outline":""},on:{"click":function($event){return _vm.$bvModal.show('protocol-modal')}}},[_vm._v(" Novo protocolo ")]):_vm._e()],1)]),_c('v-tab',{ref:"protocols_tabs",attrs:{"index":_vm.currentTabIndex,"fontSize":"0.9em","keys":_vm.tabs},on:{"onChange":_vm.onChangeTab}},[(_vm.currentTabIndex === 0 && _vm.doctorOrDoctorManager)?_c('my-protocols-tab',{attrs:{"onChangeTab":_vm.onChangeTab,"person":_vm.person,"updateProtocol":_vm.updateProtocol}}):_vm._e(),(
          (_vm.currentTabIndex === 1 && _vm.doctorOrDoctorManager) ||
          (_vm.currentTabIndex == 0 && !_vm.doctorOrDoctorManager)
        )?_c('shared-protocols-tab',{attrs:{"onChangeTab":_vm.onChangeTab,"updateProtocol":_vm.updateProtocol,"doctorOrDoctorManager":_vm.doctorOrDoctorManager}}):_vm._e(),(
          (_vm.currentTabIndex === 2 && _vm.doctorOrDoctorManager) ||
          (_vm.currentTabIndex == 1 && !_vm.doctorOrDoctorManager)
        )?_c('clinic-protocol-tab',{attrs:{"onChangeTab":_vm.onChangeTab,"updateProtocol":_vm.updateProtocol,"person":_vm.person}}):_vm._e(),(
          (_vm.currentTabIndex === 3 && _vm.doctorOrDoctorManager) ||
          (_vm.currentTabIndex == 2 && !_vm.doctorOrDoctorManager)
        )?_c('recommended-procols-tab',{attrs:{"onChangeTab":_vm.onChangeTab,"person":_vm.person}}):_vm._e(),(
          (_vm.currentTabIndex === 4 && _vm.doctorOrDoctorManager) ||
          (_vm.currentTabIndex == 3 && !_vm.doctorOrDoctorManager)
        )?_c('medical-literature-protocols-tab',{attrs:{"onChangeTab":_vm.onChangeTab,"person":_vm.person}}):_vm._e(),(
          (_vm.currentTabIndex === 5 && _vm.doctorOrDoctorManager) ||
          (_vm.currentTabIndex === 4 && !_vm.doctorOrDoctorManager)
        )?_c('protocol-favorites-tab',{attrs:{"onChangeTab":_vm.onChangeTab,"updateProtocol":_vm.updateProtocol,"person":_vm.person}}):_vm._e(),_c('protocol-modal',{attrs:{"protocol":_vm.protocol},on:{"update-protocols":_vm.updateProtocols,"clear-protocol-props":_vm.clearProtocolProps}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }