
export const statusOptions = [
  { label: 'Todas as situações', value: null },
  { label: 'Sem situação', value: 'SCHEDULED' },
  { label: 'Confirmado', value: 'CONFIRMED' },
  { label: 'Compareceu', value: 'ATTEND' },
  { label: 'Aguardando atendimento', value: 'WAITING' },
  { label: 'Pré-consulta finalizada', value: 'PRE_FINISHED' },
  { label: 'Em atendimento médico', value: 'HAPPENING' },
  { label: 'Em exames', value: 'EXAMINATING' },
  { label: 'Dilatando', value: 'DILATING' },
  { label: 'Finalizado', value: 'FINISHED' },
  { label: 'Faltou', value: 'MISSED' },
  { label: 'Cancelado', value: 'CANCELLED' },
];
