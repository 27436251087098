<template>
  <div class="wh-container">
    <div class="header no-print">
      <p class="title">Painel de Vendas</p>
      <div>

        <b-button class="whw-button d-none" variant="primary" outline @click="console.log('testing')">
          Novo Orçamento
        </b-button>
      </div>
    </div>

    <Tab
      ref="selling_panel_tabs"
      :index="currentTabIndex"
      v-bind:keys="tabs"
      v-on:onChange="onChangeTab"
    >
      <BudgetTab
          v-if="this.tabs[currentTabIndex] === 'Orçamentos'"
          :title="this.tabs[currentTabIndex]"
      />

      <ConfigurationsTab
          v-if="this.tabs[currentTabIndex] === 'Configurações'"
          :title="this.tabs[currentTabIndex]"
      />
    </Tab>

  </div>
</template>

<script>
// @todo uncomment to implement permissions
// import { userHasPermission } from '@/utils/localStorageManager'

export default {
  name: 'SellingPanelHome',
  metaInfo: {
    title: 'Eyecare - Painel de Vendas'
  },
  components: {
    Tab: () => import('@/components/Tab.vue'),
    ConfigurationsTab: () => import('./ConfigurationsTab.vue'),
    BudgetTab: () => import('./BudgetTab.vue')
  },
  mounted () {
    this.handleTabIndex();
    Object.keys(this.tabKeys).map(key => {
      this.tabs.push(this.tabKeys[key])
    })
  },
  model: {
    prop: 'currentIndex',
    event: 'onChange'
  },
  data() {
    return {
      tabs: [],
      entry: null,
      product: null,
      output: null,
      billToReceive: null,
      billToPay: null,
      currentTabIndex: 0,
      products: [],
      productsCount: 0,
      /**
       * permission keys
       */
      tabKeys: {
        'SPOrc': 'Orçamentos',
        'SPCfg': 'Configurações',
      },
      tabIndexes: [
        'orcamentos',
        'configuracoes'
      ]
    }
  },
  methods: {

    onChangeTab(index, dontPush) {
      this.currentTabIndex = index;
      if (!dontPush) {
        window.history.pushState(null, null, `/painel-vendas/${this.getPathByIndex(index)}`);
      }
    },
    handleTabIndex() {
      this.currentTabIndex = this.tabIndexes.indexOf(this.$route.params.tab) < 0 ?
          0 : this.tabIndexes.indexOf(this.$route.params.tab)
    },
    getPathByIndex(index) {
      return this.tabIndexes[index] === undefined ? this.tabIndexes[0] : this.tabIndexes[index]
    },
    // @todo use it as an example
    getProducts(clinicId, page, query, provider, start, end, quantity) {
      const isLoading = this.$loading.show();
      this.api.getProducts(clinicId, page, query, provider, start, end, quantity)
      .then(res => {
        this.products = res.data.data;
        this.productsCount = res.data.total;
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => {
        isLoading.hide();
      });
    }
  },
}
</script>

<style lang="scss" scoped>
  .wh-container {
    height: 100%;
    padding: 30px 24px;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
    }
    .title {
      font-family: 'Red Hat Display';
      font-weight: 700;
      font-size: 24px;
      color: var(--dark-blue);
    }
    .wh-button {
      margin: 0 5px;
      border-color: var(--blue-500);
      color: var(--blue-500);
    }
    .whw-button {
      margin: 0 5px;
      color: white;
    }
  }
</style>

