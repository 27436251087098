
const historyStore = {
  namespaced: true,
  state: () => ({
    opened: false,
    modules: null,
  }),
  mutations: {
    handle(state) {
      state.opened = !state.opened
    },
    setModules(state, payload) {
      state.modules = payload
    }
  },
  getters: {},
  actions: {
    setModules(context, payload) {
      context.commit('setModules', payload)
    },
    handleHistoryVisibility(context) {
      context.rootState.attendance.menu.opened &&
        !context.rootState.attendance.history.opened &&
          context.dispatch('attendance/menu/handleMenuVisibility', null, { root: true })

      context.commit('handle')
    }
  },
}

export default historyStore
