const setClinicDays = (state, clinicDays) => {
    state.clinicDays = clinicDays
}

const setClinicExceptions = (state, clinicExceptions) => {
    state.clinicExceptions = clinicExceptions
}

export default {
    setClinicDays,
    setClinicExceptions
}