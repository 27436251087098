<!-- View Solicitação de demonstração - Responsável pelo envio da notificação de demonstração a eyecare. 

Alterado pelos desenvolvedores:
Richard Moraes
Carlos Oliveira

-->

<template>
  <b-container class="request-demo" fluid>
    <b-row>
      <b-col cols="6" d-flex class="vh-100 p-0 d-flex justify-content-center align-items-center">
        <div class="form-side text-left" v-if="success === null">
          <img src="@/assets/images/eyecare-logo-colored.png" class="logo-eyecare">

          <validation-observer ref="requestDemoForm" v-slot="{ invalid }">
            <p class="title">Solicite uma demonstração</p>
            <p class="description">Informe seus dados que entraremos em contato com você</p>

            <div>
              <div class="form-group">
                <label for="name">Nome</label>
                <validation-provider mode="lazy" name="e-mail" :rules="{ required: true }" v-slot="{ errors, dirty }">
                  <input autocomplete="off" id="name" type="text" v-model="requestDemo.name" class="form-control" placeholder="Digite seu nome" :class="{ 'error': requestDemo.name.length > 0 && errors.length > 0 && dirty }">
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="email">E-mail</label>
                <validation-provider mode="lazy" name="e-mail" :rules="{ required: true, email: true }" v-slot="{ errors, dirty }">
                  <input autocomplete="off" id="email" type="text" v-model="requestDemo.email" class="form-control" placeholder="Digite seu email" :class="{ 'error': requestDemo.email.length > 0 && errors.length > 0 && dirty }">
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="cellphone">Celular</label>
                <input autocomplete="off" id="cellphone" type="text" v-model="requestDemo.cellphone" class="form-control" placeholder="Digite seu celular" v-mask="['(##) ####-####', '(##) #####-####']">
              </div>

              <b-button block variant="primary" :disabled="invalid" @click="doContact">Solicitar demonstração</b-button>
            </div>
          </validation-observer>
        </div>

        <div v-if="success" class="form-side submitted text-center">
          <img src="@/assets/images/eyecare-logo-colored.png" class="logo-eyecare">
          <br/>
          <p class="check-container">
              <span class="check">
                <span class="container">
                  <Check class="icon" />
                </span>
              </span>
          </p>

          <p class="title">Solicitação enviada com sucesso</p>
          <p class="description">Em breve alguém da Eyecare entrará em contato através dos meios informados.</p>

          <a href="https://www.eyecarehealth.com.br" title="Ir para o site">
            <b-button variant="primary" class="mt-5">Ir para o site</b-button>
          </a>
        </div>
      </b-col>
      <b-col cols="6" class="vh-100 p-0 carousel-side position-relative">
        <carousel class="vh-100" :autoplay="true" :loop="true" :autoplayTimeout="3000" :perPage="1" :paginationEnabled="true" :paginationSize="8" :paginationColor="'#A4B1DF'" :paginationPadding="8" :paginationActiveColor="'#FFFFFF'">
          <slide :style="{ 'background-image': 'url('+require('@/assets/images/carousel-img.png')+')'}">
            <div class="container">
              <p class="title">Olá,</p>
              <p class="text">Bem vindo ao EyecareBI, sua plataforma customizada para a Oftalmologia</p>
            </div>
          </slide>
          <slide :style="{ 'background-image': 'url('+require('@/assets/images/carousel-img2.png')+')'}">
            <div class="container">
              <p class="title">Dados inteligentes</p>
              <p class="text">Aqui você terá acesso a dados que poderão otimizar seu dia-a-dia no consultório</p>
            </div>
          </slide>
          <slide :style="{ 'background-image': 'url('+require('@/assets/images/carousel-img3.png')+')'}">
            <div class="container">
              <p class="title">Novo mindset</p>
              <p class="text">Além de facilitar a incorporação das inovações tecnológicas a sua prática diária</p>
            </div>
          </slide>
        </carousel>

        <img src="@/assets/images/eyecare-logo-white.png" class="logo-eyecare" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Check from '@/assets/icons/check.svg'

export default {
  name: 'Login',
  components: {
    Check
  },
  data () {
    return {
      success: null,
      requestDemo: {
        name: '',
        email: '',
        cellphone: ''
      }
    }
  },
  methods: {
    doContact () {
      const isLoading = this.$loading.show()
      const contactData = {
        ...this.requestDemo,
        source: 'request-demo'
      }

      this.api.contact(contactData).then(res => {
        if (res.status === 201) {
          this.success = true
        }
      }).catch(err => {
        console.log('Err', err)
      }).finally(() => {
        isLoading.hide()
      })
    }
  }
}
</script>

<style lang="scss">
  .request-demo {
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;

    .form-side {
      width: 70%;

      &.submitted {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo-eyecare {
          width: 122px;
          margin-bottom: 80px;
        }
      }

      .check-container {
        margin-bottom: 32px;
        display: flex;
        justify-content: center;

        .check {
          width: 96px;
          height: 96px;
          background-color: #DBFFF0;
          border: 16px solid #DBFFF0;
          border-radius: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0;

          .container {
            width: 64px;
            height: 64px;
            border-radius: 16px;
            background-color: var(--states-success);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .icon {
            width: 24px;
            height: 24px;
            stroke: var(--neutral-000);
          }
        }
      }

      .logo-eyecare {
        width: 122px;
      }

      .title {
        font-family: 'Red Hat Display';
        font-weight: 700;
        font-size: 32px;
        line-height: 40.32px;
        color: var(--dark-blue);
        margin: 80px 0 20px 0;
      }

      .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--type-active);
        margin-bottom: 48px;
      }

      .error-message {
        background-color: #FEE8E6;
        border-radius: 8px;
        padding: 16px 26px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        margin: 4px 0 40px;

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 25px;
          stroke: var(--states-error);
        }
      }

      .mb-40 {
        margin-bottom: 40px;
      }

      .mt-48 {
        margin-top: 48px;
      }

      button {
        font-size: 18px;
        font-weight: 600;
        color: var(--neutral-000);
        padding: 16px;
        border-radius: 8px;
        line-height: 24px;
      }
    }
  }

  .carousel-side {
    .logo-eyecare {
      width: 82px;
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .VueCarousel {
    .VueCarousel-wrapper {
      height: 100%;

      .VueCarousel-inner {
        height: 100% !important;

        .VueCarousel-slide {
          background-size: cover;

          .container {
            position: absolute;
            bottom: 210px;
            padding: 0 125px;

            .title {
              font-family: 'Red Hat Display';
              font-weight: 700;
              font-size: 24px;
              line-height: 30.24px;
              color: var(--neutral-000);
            }

            .text {
              font-weight: 400;
              font-size: 18px;
              color: var(--neutral-100);
              margin-top: 24px;
            }
          }
        }
      }
    }

    .VueCarousel-pagination {
      position: absolute;
      bottom: 160px;

      .VueCarousel-dot {
        transition: all 500ms;
      }

      .VueCarousel-dot--active {
        width: 16px !important;
        border-radius: 16px !important;
      }
    }
  }
</style>
