<template>
  <div v-can="'FpPron2'" class="new-attendance">
    <div class="breadcrumbs">
      <div>
        <router-link 
          v-if="userHasPermission('FpPac1')"
          to="/pacientes" 
          tag="span" 
          class="link"
        >
          Meus pacientes
        </router-link>
        <span v-else>Meus pacientes</span>


        <ChevronRight class="chevron" />
        <router-link
          :to="'/pacientes/' + patientData.id"
          tag="span"
          class="link"
        >
          {{ patientData.name }}
        </router-link>
        <ChevronRight class="chevron" />
        <span v-if="canEdit">Nova Consulta</span>
      </div>

      <b-button class="cancel-btn" @click="cancelAttendance" v-if="canEdit"
        >Cancelar nova consulta</b-button
      >
    </div>

    <div class="container">
      <div class="sidebar">
        <div class="content">
          <div class="d-flex flex-row align-items-center">
            <Avatar
              size="80px"
              margin="0 15px 0 0"
              :src="patientData.picture"
            />
            <div>
              <p class="patient-name">
                {{ patientData.name }}
                <span v-if="patientData.birthday" id="patient_age"
                  >({{
                    this.moment().diff(
                      this.moment(patientData.birthday),
                      'years'
                    ) + ' anos'
                  }})</span
                >
              </p>
              <p class="medical-record">
                Nº do prontuário:
                <span>{{ patientData.medical_record_number }}</span>
              </p>
              <b-button
                v-can="'FpPron11'"
                variant="link"
                class="historico fs-14 p-0"
                @click="
                  ev =>
                    showMedicalRecordsModal(
                      ev,
                      this.$route.params.idConsulta,
                      patientData.id
                    )
                "
              >
                <Clipboard class="clipboard" /> Visualizar histórico
              </b-button>
            </div>
          </div>
        </div>
        <ul class="resume-list">
          <li>
            <Avatar :src="professionalData.picture" margin="0 10px 0 0" />
            <p>{{ professionalData.name }}</p>
          </li>
        </ul>
        <div
          v-if="canEdit"
          class="
            content
            d-flex
            flex-row
            justify-content-between
            align-items-center
          "
        >
          <div>
            <p class="durationText">Consulta:</p>
          </div>
          <div class="col-8">
            <Select
              class="select-forms"
              v-model="selectedForm"
              :options="formOptions"
              @change="changeAppointmentType"
              border="none"
              colorOptions="#E7ECFE"
            />
          </div>
        </div>
        <div
          class="
            content
            d-flex
            flex-row
            justify-content-between
            align-items-center
          "
        >
          <p class="durationText">Duração da consulta</p>

          <p :class="{ durationTimer: canEdit, durationTimerClosed: !canEdit }">
            <span>{{ timerFormated() }}</span> <Clock />
          </p>
        </div>
        <div class="content attendanceDate">
          <p>Data</p>
          <span v-if="!canEdit">{{this.moment(this.attendanceDate).format('DD/MM/YY')}}</span>
          <span v-else-if="canEdit">{{
            this.moment().format('DD/MM/YY')
          }}</span>
        </div>
        <div
          class="content"
          v-bind:class="{ preConsultation: !!preConsultation }"
        >
          <p class="durationText" v-if="!!preConsultation">
            Pré-consulta realizada
          </p>
          <p class="durationText" v-if="!preConsultation">
            Pré-consulta não realizada
          </p>
          <button
            class="view"
            v-if="!!preConsultation"
            @click="$bvModal.show('preconsulting-modal')"
          >
            Visualizar
          </button>
        </div>

        <SideAppointment
          v-for="(key, index) in Object.keys(formGroups)"
          :key="index"
          :name="key"
          :group="formGroups[key]"
          :goTo="goTo"
          :addCustom="addCustom"
          :canEdit="canEdit"
        />
      </div>
      <div class="attendance-form flex-1">
        <div class="header">
          <p class="title" v-if="canEdit">Nova Consulta</p>
          <p class="title" v-if="!canEdit">Consulta</p>
          <div
            class="buttons-files-container"
            style="display: -webkit-inline-box"
          >
            <DocumentCount
              :attendanceId="$route.params.idConsulta"
            />

            <div class="button-files-section">
              <button
                variant="link"
                style="margin-top: -10px"
                v-b-modal.exams-modal
                class="files-button"
                v-if="Object.keys(exams).length > 0"
              >
                Exames cadastrados
              </button>

              <button
                variant="link"
                style="margin-top: -10px"
                v-b-modal.exams-modal
                class="files-button"
                v-else
                disabled
              >
                Exames cadastrados
              </button>

              <p class="examsLength">
                {{ Object.keys(exams).length }} exames cadastrados
              </p>
            </div>
          </div>
        </div>

        <div class="flex-1 scrollable-area">
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            ref="scroll"
            style="min-height: 100%"
          >
            <div v-for="(formGroup, index) in formGroups" :key="index">
              <p
                class="form-group-title"
                :class="{ 'mt-0': index == 0 }"
                v-if="
                  Object.keys(formGroups[index].forms).some(
                    formKey => formGroups[index].forms[formKey].showing
                  )
                "
              >
                {{ index }}
              </p>
              <div
                v-if="
                  index === 'Exames complementares' &&
                  Object.keys(formGroups[index].forms).some(
                    formKey => formGroups[index].forms[formKey].showing
                  )
                "
                class="message-box warning"
              >
                <InfoCircle class="icon" />
                <p>
                  Adicione exames complementares pelo menu lateral esquerdo.
                </p>
              </div>
              <FormComponent
                v-for="(form, index) in formGroup.forms"
                :form="form"
                :key="index"
                :ref="form.id"
                :attendanceReview="attendanceReview"
                :readonly="!canEdit"
                :setFormActive="setFormActive"
                :onBlurForm="onBlurForm"
                :editForm="editForm"
                :showSuccessMessage="showSuccessMessage"
                :preConsultation="preConsultation"
                @saveForm="saveForm"
                @restoreAttendanceReview="restoreAttendanceReview"
                :medicines="medicines"
                :medicinesAntimicrobianos="medicinesAntimicrobianos"
                :formsMedicalPrescription="formsMedicalPrescription"
                :cidOptions="cidOptions"
                :saveForms="saveForms"
                :goTo="goTo"
              />
            </div>

            <CardPrintMedicalRecords
              v-if="canEdit"
              :attendanceReview="attendanceReview"
              :attendanceId="attendanceId"
              :formGroups="formGroups"
              :medicines="medicines"
              :medicinesAntimicrobianos="medicinesAntimicrobianos"
              :patientData="patientData"
              :professionalData="professionalData"
              :professionalCrm="professionalCrm"
              :clinicsData="clinicsData"
              :getAttendanceFiles="getAttendanceFiles"
            />

            <b-modal
              size="lg"
              id="preconsulting-modal"
              hide-header
              hide-footer
              v-if="!!preConsultation"
            >
              <div
                class="
                  header
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-between
                  pb-3
                "
                style="border-bottom: solid 1px #d9dff2; margin-bottom: 40px"
              >
                <p class="title mb-0">Pré-consulta</p>
                <Close
                  style="width: 24px"
                  @click="$bvModal.hide('preconsulting-modal')"
                />
              </div>

              <div class="p-3">
                <div class="pre-consultation-container">
                  <p class="pre-consultation-subtitle">
                    Pré-consulta realizada por
                  </p>
                  <p class="pre-consultation-author">
                    {{ preConsultationRegister.preConsultationAuthor ? preConsultationRegister.preConsultationAuthor.name : '' }}
                  </p>
                </div>

                <hr />

                <div class="subtitle">Pré-anamnese</div>
                <div class="anamnese-form">
                  <textarea
                    v-model="preConsultation.pre_anamnese"
                    :readonly="true"
                    class="form-control"
                    rows="5"
                  ></textarea>
                </div>

                <div class="subtitle">AVSC - Acuidade visual sem correção</div>
                <div class="avsc-form">
                  <div>
                    <p>Olho direito</p>
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <Eye class="form-icon" />
                        D
                      </b-input-group-prepend>
                      <b-form-input
                        autocomplete="off"
                        v-model="preConsultation.avsc_right_eye"
                        :readonly="true"
                        aria-label="Olho direito"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                  <div>
                    <p>Olho esquerdo</p>
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <Eye class="form-icon" />
                        E
                      </b-input-group-prepend>
                      <b-form-input
                        autocomplete="off"
                        v-model="preConsultation.avsc_left_eye"
                        :readonly="true"
                        aria-label="Olho esquerdo"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </div>

                <div class="subtitle">AVCC - Acuidade visual com correção</div>
                <div class="avcc-form">
                  <div>
                    <p>Olho direito</p>
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <Eye class="form-icon" />
                        D
                      </b-input-group-prepend>
                      <b-form-input
                        autocomplete="off"
                        v-model="preConsultation.avcc_right_eye"
                        :readonly="true"
                        aria-label="Olho direito"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                  <div>
                    <p>Olho esquerdo</p>
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <Eye class="form-icon" />
                        E
                      </b-input-group-prepend>
                      <b-form-input
                        autocomplete="off"
                        v-model="preConsultation.avcc_left_eye"
                        :readonly="true"
                        aria-label="Olho esquerdo"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </div>

                <div class="subtitle">Autorrefração dinâmica</div>
                <div class="ar-labels">
                  <div>Esférico</div>
                  <div>Cilíndrico</div>
                  <div>Eixo</div>
                  <div>Adição</div>
                </div>
                <b-input-group class="form-ar-prepend-one">
                  <b-input-group-prepend is-text>
                    <Eye class="form-icon" />
                    D
                  </b-input-group-prepend>
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.auto_refraction_spherical_r"
                    aria-label="Esférico direito"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.auto_refraction_cylindrical_r"
                    aria-label="Cilíndrico direito"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.auto_refraction_axis_r"
                    aria-label="Eixo direito"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.auto_refraction_addition_r"
                    aria-label="Adição direito"
                  />
                </b-input-group>
                <b-input-group class="form-ar-prepend-two">
                  <b-input-group-prepend is-text>
                    <Eye class="form-icon" />
                    E
                  </b-input-group-prepend>
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.auto_refraction_spherical_l"
                    aria-label="Esférico esquerdo"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.auto_refraction_cylindrical_l"
                    aria-label="Cilíndrico esquerdo"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.auto_refraction_axis_l"
                    aria-label="Eixo esquerdo"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.auto_refraction_addition_l"
                    aria-label="Adição esquerdo"
                  />
                </b-input-group>

                <div class="subtitle">Autorrefração estática</div>
                <div class="ar-labels">
                  <div>Esférico</div>
                  <div>Cilíndrico</div>
                  <div>Eixo</div>
                  <div>Adição</div>
                </div>
                <b-input-group class="form-ar-prepend-one">
                  <b-input-group-prepend is-text>
                    <Eye class="form-icon" />
                    D
                  </b-input-group-prepend>
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.auto_refraction_static_spherical_r"
                    aria-label="Esférico direito"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="
                      preConsultation.auto_refraction_static_cylindrical_r
                    "
                    aria-label="Cilíndrico direito"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.auto_refraction_static_axis_r"
                    aria-label="Eixo direito"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.auto_refraction_static_addition_r"
                    aria-label="Adição direito"
                  />
                </b-input-group>
                <b-input-group class="form-ar-prepend-two">
                  <b-input-group-prepend is-text>
                    <Eye class="form-icon" />
                    E
                  </b-input-group-prepend>
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.auto_refraction_static_spherical_l"
                    aria-label="Esférico esquerdo"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="
                      preConsultation.auto_refraction_static_cylindrical_l
                    "
                    aria-label="Cilíndrico esquerdo"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.auto_refraction_static_axis_l"
                    aria-label="Eixo esquerdo"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.auto_refraction_static_addition_l"
                    aria-label="Adição esquerdo"
                  />
                </b-input-group>

                <div class="subtitle">Tonometria</div>

                <div class="tonometria-form">
                  <div>
                    <p>Aparelho de medição</p>
                    <input
                      class="form-control"
                      :readonly="true"
                      :value="preConsultation.tonometry_tool"
                    />
                  </div>

                  <div>
                    <p>Horário</p>
                    <the-mask
                      :mask="['##:##']"
                      :readonly="true"
                      :value="preConsultation.tonometry_hour"
                      class="form-control"
                      masked
                    />
                  </div>
                  <div>
                    <p>Olho direito</p>
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <Eye class="form-icon" />
                        D
                      </b-input-group-prepend>
                      <b-form-input
                        autocomplete="off"
                        :readonly="true"
                        :value="preConsultation.right_eye"
                        type="number"
                        aria-label="Olho direito"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                  <div>
                    <p>Olho esquerdo</p>
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <Eye class="form-icon" />
                        E
                      </b-input-group-prepend>
                      <b-form-input
                        autocomplete="off"
                        :readonly="true"
                        :value="preConsultation.left_eye"
                        type="number"
                        aria-label="Olho esquerdo"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </div>

                <div class="subtitle">Lensômetro</div>
                <div class="ar-labels">
                  <div>Esférico</div>
                  <div>Cilíndrico</div>
                  <div>Eixo</div>
                  <div>Adição</div>
                </div>
                <b-input-group class="form-ar-prepend-one">
                  <b-input-group-prepend is-text>
                    <Eye class="form-icon" />
                    D
                  </b-input-group-prepend>
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.lensometer_spherical_r"
                    aria-label="Esférico direito"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.lensometer_cylindrical_r"
                    aria-label="Cilíndrico direito"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.lensometer_axis_r"
                    aria-label="Eixo direito"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.lensometer_addition_r"
                    aria-label="Adição direito"
                  />
                </b-input-group>
                <b-input-group class="form-ar-prepend-two">
                  <b-input-group-prepend is-text>
                    <Eye class="form-icon" />
                    E
                  </b-input-group-prepend>
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.lensometer_spherical_l"
                    aria-label="Esférico esquerdo"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.lensometer_cylindrical_l"
                    aria-label="Cilíndrico esquerdo"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.lensometer_axis_l"
                    aria-label="Eixo esquerdo"
                  />
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    :readonly="true"
                    :value="preConsultation.lensometer_addition_l"
                    aria-label="Adição esquerdo"
                  />
                </b-input-group>
              </div>
            </b-modal>

            <div
              class="header"
              style="text-align: left; padding: 10px 0 10px; margin-top: 40px;"
              v-if="canEdit"
            >
              <p class="title" style="font-size: 19px;">
                Assinatura digital do prontuário
              </p>
            </div>
            <input type="hidden" id="certThumbField" name="certThumb" />
            <input type="hidden" id="certContentField" name="certContent" />
            <div v-if="canEdit">
              <div
                v-if="certAuth && certAuth.active === 'inative'"
                style="
                  background: #fff0e5;
                  border-radius: 8px;
                  flex-direction: row;
                  align-items: center;
                  padding: 16px 17px;
                  margin-right: 20px;
                "
              >
                <svg
                  style="margin-bottom: -22px"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z"
                    stroke="#B30F00"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 8V12"
                    stroke="#B30F00"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 16H12.01"
                    stroke="#B30F00"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span
                  style="
                    display: inline-table;
                    margin-left: 15px;
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 150%;
                    color: #525c7a;
                  "
                  ><strong>Assinatura digital não configurada</strong><br />
                  Configure o seu certificado digital para assinar digitalmente
                  seus prontuários.
                </span>
                <span
                  style="
                    float: right;
                    cursor: pointer;
                    margin-top: 1.5px;
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 150%;
                    align-items: center;
                    text-align: right;
                    color: #305bf2;
                  "
                  v-b-modal.signature
                >
                  Configurar
                </span>
              </div>
              <span v-else>
                <div
                  v-if="certAuth && certAuth.active == true"
                  style="
                    background: #dbfff0;
                    border-radius: 8px;
                    flex-direction: row;
                    align-items: center;
                    padding: 16px 24px;
                    margin-right: 20px;
                  "
                >
                  <svg
                    width="22"
                    height="23"
                    viewBox="0 0 22 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 11.0799V11.9999C20.9988 14.1563 20.3005 16.2545 19.0093 17.9817C17.7182 19.7088 15.9033 20.9723 13.8354 21.5838C11.7674 22.1952 9.55726 22.1218 7.53447 21.3744C5.51168 20.6271 3.78465 19.246 2.61096 17.4369C1.43727 15.6279 0.879791 13.4879 1.02168 11.3362C1.16356 9.18443 1.99721 7.13619 3.39828 5.49694C4.79935 3.85768 6.69279 2.71525 8.79619 2.24001C10.8996 1.76477 13.1003 1.9822 15.07 2.85986"
                      stroke="#00B368"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21 4L11 14.01L8 11.01"
                      stroke="#00B368"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span
                    v-if="certAuth.type == 1"
                    style="font-size: 15px"
                  >
                    {{
                      certAuth.cert.subjectName +
                      '-  ' +
                      certAuth.cert.issuerName +
                      ' - ' +
                      certAuth.cert.validityEnd
                    }}
                  </span>
                  <span
                    v-if="certAuth.type == 2"
                    style="font-size: 15px"
                  >
                    {{
                      certAuth.cert.name + '(' + certAuth.cert.provider + ')'
                    }}
                  </span>
                  <span
                    style="
                      float: right;
                      cursor: pointer;
                      margin-top: 1.5px;
                      font-family: Nunito Sans;
                      font-style: normal;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 150%;
                      align-items: center;
                      text-align: right;
                      color: #305bf2;
                    "
                    v-b-modal.signature
                  >
                    Trocar Certificado
                  </span>
                </div>
                <div
                  v-if="certAuth && certAuth.active == false"
                  style="
                    flex-direction: row;
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    background: #fff0e5;
                    border-radius: 8px;
                    padding: 16px 20px;
                    margin-right: 5px;
                  "
                >
                  <div style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  ">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        stroke="#B34B00"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 7V13.5"
                        stroke="#B34B00"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 17H12.01"
                        stroke="#B34B00"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        margin-left: 15px;
                        font-family: Nunito Sans;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 150%;
                        color: #525c7a;
                      "
                      >
                      <div>
                        <strong>Assinatura digital desligada</strong>
                      </div>
                      <div>
                        Para concluir o prontuário é necessário estar com a
                        assinatura digital ligada.
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      cursor: pointer;
                      font-family: Nunito Sans;
                      font-style: normal;
                      font-weight: 600;
                      font-size: 14px;
                      text-align: right;
                      color: #305bf2;
                    "
                    v-b-modal.signature
                  >
                    Ativar assinatura digital
                  </div>
                </div>
              </span>
            </div>
            <div
              class="mb-5 mr-3"
              v-if="canEdit || (certAuth && certAuth.active === true)"
            >
              <b-row class="d-flex justify-content-end">
                <b-button
                  variant="link"
                  :disabled="!this.canEdit"
                  v-b-modal.pause-modal
                  >
                  Finalizar depois
                </b-button>
                <b-button
                  variant="outline-success"
                  class="m-2 font-weight-bold"
                  :disabled="!this.canEdit"
                  v-b-modal.attendance-review-modal
                >
                  Finalizar e imprimir
                </b-button>
                <b-button
                  variant="success"
                  class="m-2"
                  :disabled="!this.canEdit"
                  @click="finishAttendance"
                >
                  {{ this.certActive() ? 'Finalizar e assinar' : 'Finalizar' }}
                </b-button>
              </b-row>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </div>

    <b-modal id="redirect-block" hide-header hide-footer centered size="lg">
      <div class="header d-flex flex-row justify-content-between">
        <p
          align="center"
          class="title"
          style="
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #525c7a;
          "
        >
          Assinatura em nuvem
        </p>
      </div>
      <hr />
      <div class="body" align="center">
        <svg
          class="mb-3"
          width="96"
          height="96"
          viewBox="0 0 96 96"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="96" height="96" rx="32" fill="#DBFFF0" />
          <rect x="16" y="16" width="64" height="64" rx="16" fill="#00C773" />
          <path
            d="M55 43L45 53.01L42 50.01"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h6
          class="mb-3"
          style="
            color: #525c7a;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
          "
        >
          Seu redirecionamento para finalização da assinatura foi bloqueado pelo
          navegador.
        </h6>
        <h6
          style="
            color: #525c7a;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
          "
          align="center"
        >
          Por favor clique no botão a baixo para continuar o processo de
          assinatura
        </h6>
        <b-row class="mb-3 mt-4">
          <b-col>
            <b-button variant="primary" @click="openWindowCloud(urlCloud)"
              >Continuar assinatura</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <PauseModal
      :cancelAttendance="cancelAttendance"
      @paused="pausedAttendance"
    />

    <SuccessAppointmentModal
      :goAttendance="goAttendance"
      :goPatient="goPatient"
    />

    <ExamsModal :exams="exams" />

    <AttendanceReviewModal
      :formGroups="formGroups"
      :canSave="canSave"
      :getForm="getForm"
      :appointmentType="appointmentType"
      :attendanceReview="attendanceReview"
      :attendanceReviewCopy="attendanceReviewCopy"
      :attendanceSignatureItem="attendanceSignatureItem"
      :attendanceSignatureItemCopy="attendanceSignatureItemCopy"
      :checkAttendanceReviewPrint="checkAttendanceReviewPrint"
      :medicines="medicines"
      :medicinesAntimicrobianos="medicinesAntimicrobianos"
      :patientData="patientData"
      :professionalData="professionalData"
      :professionalCrm="professionalCrm"
      :clinicsData="clinicsData"
      :selectedForm="selectedForm"
      @onFinished="loadAttendanceData"
    />

    <MedicalRecordsModal :patientId="patientData.id" />

    <SelectProtocolsModal @selected-protocols="getSelectedProtocols" />

  </div>
</template>
<script>
import { getFormGroups } from '@/utils/formGroups.js'
import { init, preAuthorizeSignatures, readCertificate } from '@/utils/lacuna/lacunaHelper'
import { getToPrint, getDocTypes } from '@/utils/documentHelper'
import cloneDeep from 'lodash/cloneDeep'
import { getCurrentClinic, userHasPermission } from '@/utils/localStorageManager'

export default {
  metaInfo: {
    title: 'Eyecare - Consulta'
  },
  components: {
    AttendanceReviewModal: () => import('./AttendanceReviewModal'),
    Avatar: () => import('@/components/General/Avatar'),
    CardPrintMedicalRecords: () => import('@/components/Appointment/CardPrintMedicalRecords.vue'),
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    Clipboard: () => import('@/assets/icons/clipboard.svg'),
    Clock: () => import('@/assets/icons/clock.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    DocumentCount: () => import('@/components/Document/DocumentCount'),
    ExamsModal: () => import('./ExamsModal'),
    Eye: () => import('../../assets/icons/eye.svg'),
    FormComponent: () => import('../../components/Forms'),
    InfoCircle: () => import('@/assets/icons/info-circle.svg'),
    MedicalRecordsModal: () => import('./MedicalRecordsModal.vue'),
    PauseModal: () => import('../../components/Appointment/PauseModal.vue'),
    Select: () => import('@/components/Select.vue'),
    SelectProtocolsModal: () => import('@/components/Protocols/SelectProtocolsModal.vue'),
    SideAppointment: () => import('@/components/Appointment/SideAppointment.vue'),
    SuccessAppointmentModal: () => import('../../components/General/SuccessAppointmentModal.vue'),
  },
  data() {
    return {
      attendanceRedirect: null,
      preConsultationRegister: null,
      clinic: getCurrentClinic(),
      forms: [],
      formOptions: [],
      selectedForm: null,
      formGroups: {},
      docs: [],
      pdfList: [],
      pdfListAntimicrobianos: [],
      pdfCount: 0,
      numberFormat: {
        decimal: ',',
        prefix: '+',
        suffix: '',
        precision: 2,
        masked: false
      },
      attendanceDate: null,
      preConsultation: null,
      urlCloud: null,
      laudo: {
        form: null
      },
      certAuth: null,
      canEdit: true,
      canSave: false,
      canceled: false,
      paused: false,
      pauseReason: null,
      attendanceType: null,
      appointmentType: 'completa',
      attendanceStartDatetime: null,
      timerInterval: null,
      timerInSeconds: 0,
      examsCurrentTab: 'exame',
      typeDoc: getDocTypes(),
      toPrint: [],
      patientData: {
        id: '',
        name: '',
        picture: '',
        medical_record_number: ''
      },
      professionalData: {
        id: '',
        name: ''
      },
      medicines: {
        oral: [],
        ocular: []
      },
      medicinesAntimicrobianos: {
        oral: [],
        ocular: []
      },
      professionalCrm: null,
      medicalRecords: null,
      clinicsData: null,
      yearsExams: [],
      exams: [],
      examsTypes: [],
      examsDates: [],
      activeExam: {},
      timerState: null,
      formsMedicalPrescription: [],

      printAll: false,
      attendanceReview: {
        solicitacaoDeExames: false,
        solicitacaoDeAPA: false,
        solicitacaoDeCirurgia: false,
        encaminhamento: false,
        prescricaoMedicamentosa: false,
        prescricaoDeOculos: false,
        prescricaoDeLentes: false,
        declaracaoDeComparecimento: false,
        declaracaoDeComparecimentoAcompanhante: false,
        atestado: false,
        retorno: false,
        mapeamentoRetina: false,
        posOperatorio: false,
      },
      attendanceSignatureItem: {
        solicitacaoDeExames: false,
        solicitacaoDeAPA: false,
        solicitacaoDeCirurgia: false,
        encaminhamento: false,
        prescricaoMedicamentosa: false,
        prescricaoDeOculos: false,
        prescricaoDeLentes: false,
        declaracaoDeComparecimento: false,
        declaracaoDeComparecimentoAcompanhante: false,
        atestado: false,
        retorno: false,
        mapeamentoRetina: false,
        posOperatorio: false,
      },
      saveCustomForm: false,
      customForm: [],
      cidOptions: [],
      cloneDeep,
      attendanceId: null,
      attendance: [],
      attendanceReviewCopy: null,
      attendanceSignatureItemCopy: null,
      summaryTypes: ['antecedentes-oftalmologicos', 'cirurgias-oftalmologicas-anteriores', 'alergias', 'antecedentes-pessoais', 'outros-medicamentos-em-uso',
          'antecedentes-familiares'],
      patientSummary: []
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.attendanceRedirect = to.path
    if (this.canceled === false && this.canEdit) {
      next(false)
      return this.$bvModal.show('pause-modal')
    } else if (!this.canEdit) {
      localStorage.removeItem(`attendance-print-${this.attendanceId}`)
      next()
    } else {
      if(!this.canceled) {
        await this.api.updateAttendance(this.clinic.id, this.$route.params.id, {
          action: 'end_interval'
        })
      }
      clearInterval(this.timerInterval)
      clearInterval(this.timerState)
      localStorage.removeItem(`attendance-print-${this.attendanceId}`)
      next()
    }
  },
  async mounted() {
    this.setCertificate();
    this.attendance = await this.getAttendance()
    await this.getPatientSummary()
    await this.loadAttendanceData();
    await this.getAttendanceFiles()
    await this.getAllExams()
    
    await this.getProfessionalCrm()

    this.api.loadForm(this.clinic.id).then(res => {
      const resData = res.data
      this.customForm = resData
    })

    if (this.canEdit) {
      this.getCidOptions()
    }
    this.timerInterval = setInterval(() => {
      if (this.canEdit) this.timerInSeconds += 1
    }, 1000)
  },
  created() {
    this.attendanceReviewCopy = Object.assign({}, this.attendanceReview)
    this.attendanceSignatureItemCopy = Object.assign({}, this.attendanceSignatureItem)
  },
  methods: {
    userHasPermission,
    async finishAttendance() {

      const cidForm = this.getForm('Diagnóstico', 'cid');
      if (!cidForm || !cidForm.value || !cidForm.value.length) {
        this.$toast.warning('Informe o CID antes de finalizar');
        return;
      };

      const data = {
        attendanceId: this.attendanceId,
        toPrint: [],
        toSign: [0, ...getToPrint(this.attendanceReviewCopy, this.medicines, this.medicinesAntimicrobianos)], // sign all printable docs including type 0
        quickPrint: false,
        certContent: null,
        service: null,
      }

      const loading = this.$loading.show();
      try {
        if(this.certAuth.active && this.certAuth.type === 1){
          await preAuthorizeSignatures(this.certAuth.cert.thumbprint, data.toSign.length);
          data.certContent = await readCertificate(this.certAuth.cert.thumbprint);
        } else if (this.certAuth.active && this.certAuth.type === 2) {
          data.service = this.certAuth.cert.name;
        }

        const res = await this.api.finishAttendance(data);

        if (res.data.authentication === 'oauth') {
          window.open(res.data.url, '_blank');
        } else if (res.data.authentication === 'rest') {
          for (const signature of res.data.signatures) {
            await init(signature.token, this.certAuth.cert.thumbprint);
          }
          await this.api.restFinishAttendance(data.attendanceId);
        }

        await this.api.simpleFinishAttendance(data.attendanceId);

        loading.hide();
        this.loadAttendanceData()
        this.$toast.success('Consulta finalizada com sucesso')
      } catch (err) {
        this.$toast.error(err.message);
        loading.hide();
      }
    },
    async pausedAttendance(reason) {
       try {
        await this.api.pauseAttendance(
          this.clinic.id,
          this.$route.params.idConsulta,
          reason
        )
        this.$toast.success('Consulta pausada com sucesso');
        this.canceled = true;
        this.attendanceRedirect ?
          this.$router.push(this.attendanceRedirect) :
          this.$router.push('/visao-geral');

        this.attendanceRedirect = null
      } catch(err) {
        this.$toast.error(err.message)
      }

    },
    restoreAttendanceReview(el) {
      switch(el.id) {
        case 'solicitacao-de-exames':
          this.attendanceReview.solicitacaoDeExames = false
          break;
        case 'solicitacao-de-cirurgia':
          this.attendanceReview.solicitacaoDeCirurgia = false
          break;
        case 'apa-avaliacao-pre-anestesica':
          this.attendanceReview.solicitacaoDeAPA = false
          break;
        case 'encaminhamento':
          this.attendanceReview.encaminhamento = false
          break;
        case 'prescricao-medicamentosa-v2':
          this.attendanceReview.prescricaoMedicamentosa = false
          break;
        case 'oculos':
          this.attendanceReview.prescricaoDeOculos = false
          break;
        case 'lentes-de-contato':
          this.attendanceReview.prescricaoDeLentes = false
          break;
        case 'orientacao-ao-paciente':
          this.attendanceReview.posOperatorio = false
          break;
        case 'laudo':
          this.attendanceReview.laudo = false
          break;
        case 'mapeamento-de-retina':
          this.attendanceReview.mapeamentoRetina = false
          break;
        case 'declaracaoDeComparecimento':
          this.attendanceReviewCopy.declaracaoDeComparecimento = false
          break;
        case 'declaracaoDeComparecimentoAcompanhante':
          this.attendanceReviewCopy.declaracaoDeComparecimentoAcompanhante = false
          break;
        case 'atestado':
          this.attendanceReviewCopy.atestado = false
          break;
      }
    },
    certActive() {
      return this.certAuth && this.certAuth.active && this.certAuth.active !== 'inative'
    },
    setCertificate() {
      let auxCert = JSON.parse(localStorage.getItem('singItemAutentic'))
      if (auxCert !== undefined && auxCert !== null) {
        const date = new Date(auxCert.cert.validityEnd)
        auxCert.cert.validityEnd =
          addZero(date.getDate().toString()) +
          '/' +
          addZero(date.getMonth() + 1).toString() +
          '/' +
          date.getFullYear()
      } else {
        auxCert = { active: 'inative' }
      }
      this.certAuth = auxCert

      function addZero(number) {
        if (number <= 9) return '0' + number
        else return number
      }
    },
    async loadAttendanceData() {
      const attendance = await this.getAttendance();
      this.attendanceId = attendance.id
      await this.getForms(attendance.form_id);
      this.patientData = attendance.patient
      this.professionalData = attendance.professional
      this.medicalRecords = attendance.medicalRecords
      this.clinicsData = attendance.clinic
      await this.verifyIfCanEdit(attendance)
      await this.populateMedicalRecords(attendance)
      this.setTime(attendance);
      this.timerInterval = null;

      if (
        this.attendance.appointment &&
        this.attendance.appointment.patient_pre_consultation
      ) {
        this.preConsultation = attendance.appointment.patient_pre_consultation
      }
    },
    async getAttendance() {
      const res = await this.api.getAttendance(
        this.clinic.id,
        this.$route.params.id,
        this.$route.params.idConsulta
      )
      const attendance = res.data
      this.attendanceDate = attendance.start_datetime

      this.preConsultationRegister = res.data.appointment

      attendance.medicalRecords.map(res => {
        if (res.type === 'precricao-medicamentosa') {
          res.type = 'prescricao-medicamentosa-v2'
        }
      })

      return attendance
    },
    async getForms(formId) {
      const user = JSON.parse(localStorage.getItem('user'))
      const res = await this.api.getForms(this.clinic.id, user.id, 'ATTENDANCE')
      this.forms = res.data.filter(form => form.active)
      this.formOptions = this.forms.map(form => ({
        label: form.name,
        value: form.id
      }))
      this.formOptions.unshift({ label: 'Completa', value: 'completa' })
      this.selectedForm = formId === null ? 'completa' : formId
    },
    async changeAppointmentType(selectedForm) {
      if (this.selectedForm === selectedForm) return;

      this.selectedForm = selectedForm

      const isLoading = this.$loading.show()
      try {
        await this.api.updateAttendanceApi(this.$route.params.idConsulta, {
          form_id: selectedForm === 'completa' ? null : selectedForm
        })
        const attendance = await this.getAttendance();
        await this.populateMedicalRecords(attendance)
      } catch (error) {
        console.log(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async populateMedicalRecords(attendance) {
      let form = {}
      if (this.selectedForm !== 'completa') {
        const formModel = this.forms.find(f => f.id === this.selectedForm)
        form = { ...getFormGroups(formModel) }
      } else {
        form = { ...getFormGroups() }
      }

      Object.keys(form).map(key =>
        Object.keys(form[key].forms).map(async (formKey) => {
          const f = form[key].forms[formKey]
          const medicalRecord = await this.getMedicalRecordOrSummary(attendance, formKey, f)
          if (medicalRecord) {
            f.showing = true
            f.opened = true
            if (Array.isArray(medicalRecord.value)) {
              f.value = Object.values(medicalRecord.value)
              if (medicalRecord.summary) {
                this.saveForm(f)
              }
            } else {
              f.fields = medicalRecord.value
            }
          } else {
            await this.fillCustomForm(f)
          }
        })
      )

      attendance.medicalRecords
        .filter(mr => mr.type.indexOf('exame-personalizado') > -1)
        .forEach(medicalRecord => {
          if (!form['Exames complementares']) {
            form['Exames complementares'] = {
              opened: true,
              showing: true,
              forms: {}
            }
          }
          form['Exames complementares'].forms[medicalRecord.type] = {
            id: medicalRecord.type,
            name: medicalRecord.value.name,
            active: false,
            showing: true,
            opened: true,
            editing: false,
            completed: () => {},
            fields: medicalRecord.value.fields
          }
        })

      this.formGroups = form;
      this.$forceUpdate();
    },
    async verifyIfCanEdit(attendance) {
      const timeSinceStart = this.moment.duration(
        this.moment().diff(this.moment(attendance.start_datetime))
      )
      this.canEdit = ['OPENED', 'PAUSED'].includes(attendance.status) && timeSinceStart.asHours() <= 24
      if (!this.canEdit && ['OPENED', 'PAUSED'].includes(attendance.status)) {
        try {
          await this.api.autoFinishAttendance(
            this.clinic.id,
            this.$route.params.idConsulta
          )
          this.canceled = false
          this.$router.push('/pacientes/' + this.$route.params.id);
        } catch (ex) {
          console.log(ex)
        }
      }
    },
    setTime(attendance) {
        this.timerInSeconds = 0
        const startTime = this.moment(attendance.start_datetime)
        const endTime =
          attendance.end_datetime !== null
            ? this.moment(attendance.end_datetime)
            : this.moment()
        this.timerInSeconds += endTime.diff(startTime, 'seconds')
    },
    async getAttendanceFiles() {
      const res = await this.api.getAttendanceFiles(
        this.$route.params.idConsulta
      )
      this.docs = res.data
    },
    async getAllExams() {
      const res = await this.api.getAllExams(this.$route.params.id)
      this.exams = res.data
    },
    async getProfessionalCrm() {
      const res = await this.api.getProfessionalCrm(
        this.clinic.id,
        this.$route.params.id,
        this.$route.params.idConsulta,
        this.professionalData.id
      )
      const resData = res.data
      this.professionalCrm = resData
    },

    showSuccessMessage(message) {
      this.$toast.success(message)
    },

    showMedicalRecordsModal(ev, prontuarioId, patientId) {
      ev.stopPropagation()
      this.attendanceId = prontuarioId
      this.$bvModal.show('medical-records-modal')
    },

    formatDate() {
      const date = new Date()

      const nowUtc = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      )

      return new Date(nowUtc)
    },
    async openFile(attendanceFile) {
      try {
        const response = await this.api.getDocTemporaryUrl(attendanceFile.path)
        window.open(response.data, '_blank')
      } catch (error) {
        this.$toast.error('Não foi possível abrir o arquivo!')
      }
    },
    getForm(formGroupName, formKey) {
      if (!this.formGroups[formGroupName]) return null
      return this.formGroups[formGroupName].forms[formKey]
    },
    updateLocalForm(form) {
      const formGroupName = Object.keys(this.formGroups).find(key =>
        Object.keys(this.formGroups[key].forms).includes(form.id)
      )
      if (formGroupName) {
        this.formGroups[formGroupName].forms[form.id] = form
      } else {
        console.error('Local form not updated');
      }
    },
    async goTo(form, getSummary) {
      if (!getSummary && !form.value.length && this.summaryTypes.includes(form.id)) {
        const data = await this.getMedicalRecordOrSummary(this.attendance, form.id, form)
        if (form.value) {
          data.value.forEach(value => {
            form.value.push(value)
            this.saveForm(form)
          })
        } else {
          form.fields = data.value
          this.saveForm(form);
        }
      }
      Object.keys(this.formGroups).forEach((key, index) => {
        Object.values(this.formGroups[key].forms).forEach(f => {
          if (f.id !== form.id) {
            f.active = false
          }
        })
      })
      function scrollTo(parentElement, element, to, duration) {
        const start = element.scrollTop
        const change = to.offsetTop - parentElement.scrollTop - start
        const increment = 20

        let currentTime = 0

        var animateScroll = function () {
          currentTime += increment
          const val = Math.easeInOutQuad(currentTime, start, change, duration)
          element.scrollTop = val
          if (currentTime < duration) {
            setTimeout(animateScroll, increment)
          }
        }
        animateScroll()
      }

      Math.easeInOutQuad = function (t, b, c, d) {
        t /= d / 2
        if (t < 1) return (c / 2) * t * t + b
        t--
        return (-c / 2) * (t * (t - 2) - 1) + b
      }

      if (!this.$refs[form.id]) {
        form.showing = true
        form.active = true
      }

      setTimeout(
        () => {
          scrollTo(
            this.$parent.$refs.mainScroll,
            this.$refs.scroll.$el,
            this.$refs[form.id][0].$el,
            1000
          )
        },
        !this.$refs[form.id] ? 1000 : 0
      )
    },
    async addCustom() {
      if (!this.formGroups['Exames complementares']) {
        this.formGroups['Exames complementares'] = {
          opened: true,
          forms: {}
        }
      }

      const customId = 'exame-personalizado-' + new Date().getTime()
      const form = {
        id: customId,
        name: 'Exame personalizado',
        active: true,
        showing: true,
        opened: true,
        editing: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      }
      this.formGroups['Exames complementares'].forms[customId] = form
      this.$forceUpdate()

      this.$nextTick(() => {
        this.goTo(form)
      })
    },
    setTimer() {
      const now = this.moment()
      const diff = now.diff(
        this.moment(this.attendanceStartDatetime),
        'seconds'
      )

      this.timer = this.moment
        .duration(diff, 'seconds')
        .format('hh:mm:ss', { trim: false })
    },
    setFormActive(formId) {
      Object.keys(this.formGroups).forEach((key, index) => {
        Object.values(this.formGroups[key].forms).forEach(f => {
          if (f.id === formId) {
            f.active = true
          } else {
            f.active = false
          }
        })
      })
    },
    async onBlurForm(form) {
      setTimeout(async () => {await this.saveForm(form)}
      , 300);
    },
    async saveForm(form) {
      this.updateLocalForm(form)

      if (form.id === 'cid') {
        this.getCidOptions()
      }

      let formData = null
      if (form.multiple || (form.value && form.value.length)) {
        formData = {
          action: 'medical-record',
          type: form.id,
          value: form.value
        }
      } else if (typeof form.editing !== 'undefined') {
        formData = {
          action: 'medical-record',
          type: form.id,
          value: {
            name: form.name,
            fields: form.fields
          }
        }
      } else {
        let formFilled = false
        Object.keys(form.fields).forEach(key => {
          if (form.fields[key] !== null && form.fields[key] !== '') {
            formFilled = true
          }
        })

        if (formFilled) {
          formData = {
            action: 'medical-record',
            type: form.id,
            value: form.fields
          }
        }
      }

      if (formData !== null) {
        try {
          await this.api.updateAttendance(
            this.clinic.id,
            this.$route.params.id,
            formData
          )
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    async saveForms(form) {
      const formData = {
        action: 'remove-medical-record',
        type: form.id
      }

      const clinic = JSON.parse(localStorage.getItem('clinic'))
      try {
        await this.api.updateAttendance(
          clinic.id,
          this.$route.params.id,
          formData
        )
        form.value = []
        form.fields && Object.keys(form.fields).forEach(key => {
          if (Array.isArray(form.fields[key])) {
            form.fields[key] = []
          } else {
            form.fields[key] = null
          }
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    goPatient() {
      this.$router.push('/pacientes/' + this.$route.params.id)
    },
    goAttendance() {
      location.reload()
    },
    async editForm(form) {
      form.editing = !form.editing

      if (!form.editing) {
        this.formGroups['Exames complementares'].forms[form.id] = form
      }

      this.$nextTick(() => {
        this.$refs[form.id][0].$el.querySelector('input').focus()
      })
    },
    async cancelAttendance() {
      clearInterval(this.timerState)
      const clinic = JSON.parse(localStorage.getItem('clinic'))
      const isLoading = this.$loading.show()

      try {
        const response = await this.api.updateAttendance(
          clinic.id,
          this.$route.params.id,
          {
            action: 'end_attendance',
            canceled: true
          }
        )
        if (response.data.success) {
          this.canceled = true
          this.$router.push(`/pacientes/${this.$route.params.id}`)
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    openWindow(url) {
      const newTab1 = window.open()
      if (newTab1 == null) {
        alert(
          'Redirecionamento bloqueado pelo navegador, por favor habilite o mesmo'
        )
      } else {
        newTab1.location.href = url
      }
    },
    openWindowCloud(url) {
      window.open(url, '_blank')
      this.$bvModal.hide('redirect-block')
      this.$bvModal.show('success-appointment-modal')
    },
    timerFormated() {
      const momentDuration = this.moment.duration(
        this.timerInSeconds,
        'seconds'
      )

      const hours =
        momentDuration.hours().toString().length === 2
          ? momentDuration.hours()
          : '0' + momentDuration.hours()
      const minutes =
        momentDuration.minutes().toString().length === 2
          ? momentDuration.minutes()
          : '0' + momentDuration.minutes()
      const seconds =
        momentDuration.seconds().toString().length === 2
          ? momentDuration.seconds()
          : '0' + momentDuration.seconds()

      return hours + ':' + minutes + ':' + seconds
    },
    createPdf(exam) {
      if (exam.type === 'pdf' && !exam.url) {
        const byteCharacters = atob(exam.file)
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512)

          const byteNumbers = new Array(slice.length)
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
          }

          const byteArray = new Uint8Array(byteNumbers)
          byteArrays.push(byteArray)
        }

        const blob = new Blob(byteArrays, { type: 'application/pdf' })
        var url = URL.createObjectURL(blob)
        exam.url = url
        return exam.url
      }
    },
    setActiveExam(exam) {
      // this.activeExam.url = null
      this.activeExam = exam
    },
    getExamsByYear(year) {
      return this.exams.filter(exam => {
        return this.moment(exam.date).format('YYYY') === year
      })
    },
    getFilesFromActiveExamByType(type) {
      return this.activeExam.files.filter(file => {
        return file.type === type
      })
    },
    checkAttendanceReviewPrint(returnAsText = false) {
      clearInterval(this.timerState)
      let toPrint = 0

      for (var [key, value] of Object.entries(this.attendanceReview)) {
        if (value && key !== '') {
          toPrint += 1
        }
      }

      if (returnAsText) {
        return toPrint
      } else {
        return toPrint > 0
      }
    },
    toggleAllPrint() {
      const currentStatus = this.checkAttendanceReviewPrint()

      Object.entries(this.attendanceReview).forEach(([key]) => {
        this.attendanceReview[key] = !currentStatus
      })
    },
    openImage(data) {
      var w = window.open('about:blank')
      w.document.write('<img src="' + data + '" style="height: 100%;" >')
    },
    getSelectedProtocols(value) {
      const copy = []
      value.forEach(protocolo => {
        protocolo.medicines.forEach(medicine => {
          const form = {
            fields: {
              custom: null,
              medicamento: medicine.medicine
                ? medicine.medicine
                : medicine.medicine_name,
              via: medicine.via,
              quantidade: medicine.quantity,
              dose: medicine.dose,
              intervalo: medicine.intervals,
              lateralidade: null,
              tempo_uso: medicine.time_of_use,
              horarios: [],
              orientacao: medicine.orientation,
              antimicrobiano: medicine.medicine ? parseInt(medicine.medicine.antimicrobiano) : medicine.antimicrobial,
            },
            value: []
          }
          copy.push(form)
        })
      })
      this.formsMedicalPrescription = copy

      this.$forceUpdate()
    },
    getCidOptions() {
      const cids = ['Não informar']
      const cidForm = this.getForm('Diagnóstico', 'cid')

      cidForm.value &&
        cidForm.value.length &&
        cidForm.value.map(cid => {
          cids.push(cid.cid)
        })
      this.cidOptions = cids
    },
    async getLastMedicalRecords(types, patientId) {
      try {
        const response = await this.api.findLastMedicalRecord(types, patientId)
        return response.data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    verifyFormToFindMedicalRecords(label) {
      if (this.selectedForm === 'completa') return false
      const form = this.formOptions.filter(f => f.value === this.selectedForm)[0]
      return form && form.label === label
    },
    async fillCustomForm(f) {
      const retorno = this.verifyFormToFindMedicalRecords('Retorno')
      const pos = this.verifyFormToFindMedicalRecords('Pós-operatório')
      if (pos || retorno && f.showing) {
        if (f.id === 'medicamentos-oftalmologicos-em-uso') {
          const response = await this.getLastMedicalRecords([f.id], this.$route.params.id)
          response.map(data => {
            data.value.map(value => {
              if (value.emUso) {
                f.value.push(value)
              }
            })
          })
          this.saveForm(f)
        } else if (f.id === 'avcc-acuidade-visual-com-correcao' && pos) {
          f.fields.possuiAutorrefrator = true
        }

        if (retorno) {
          if (f.id === 'cid') {
            const response = await this.getLastMedicalRecords([f.id], this.$route.params.id)
             response.map(data => {
              data.value.map(value => f.value.push(value))
            })
            this.saveForm(f)
          }
        }
      }
    },
    async getPatientSummary() {
      try {
        const response = await this.api.getPatientSummary(this.$route.params.id, this.summaryTypes)
        this.patientSummary = response.data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    filterSummaryItem(key) {
      return this.patientSummary.filter(item => {
        return item.type === key
      })
    },
    async getMedicalRecordOrSummary(attendance, formKey, form) {
      let medicalRecord = attendance.medicalRecords.find(
        mr => mr.type === formKey
      )
      if (this.canEdit && form.opened && !medicalRecord && this.summaryTypes.includes(formKey)) {
        const summaryItem = this.filterSummaryItem(formKey)
        if (summaryItem) {
          medicalRecord = { value: [] }
          medicalRecord.summary = true
          medicalRecord.value = this.fillSummaryData(summaryItem, formKey)
        }
      }
      return medicalRecord
    },
    fillSummaryData(data, type) {
      const datas = [];
      const names = data.map(d => d.name)
      const allergies = data.find(d => d.medical_record && d.type === 'alergias')
      const familyBackground = data.find(d => d.medical_record && d.type === 'antecedentes-familiares')
      const ophthalmologicalBackground = data.find(d => d.medical_record && d.type === 'antecedentes-oftalmologicos')
      const previousEyeSurgeries = data.find(d => d.medical_record && d.type === 'cirurgias-oftalmologicas-anteriores')
      const personalBackground = data.find(d => d.medical_record && d.type === 'antecedentes-pessoais')
      const medicinesInUse = data.find(d => d.medical_record && d.type === 'outros-medicamentos-em-uso')
      switch (type) {
        case 'antecedentes-pessoais':
          if (personalBackground) {
            personalBackground.medical_record.value.forEach(value => {
              if (names.includes(value.antecedente)) {
                datas.push({
                  'antecedente': value.antecedente,
                  'ano': value.ano,
                  'custom': null
                })
              }
            })
          }
          data.forEach(mr => {
            if (!mr.medical_record) {
              datas.push({
                'antecedente': mr.name,
                'ano': null,
                'custom': null
              })
            }
          })
          break;
        case 'antecedentes-oftalmologicos':
          if (ophthalmologicalBackground) {
            ophthalmologicalBackground.medical_record.value.forEach(value => {
              if (names.includes(value.antecedente)) {
                datas.push({
                  'antecedente': value.antecedente,
                  'situacao': value.situacao,
                  'ultimaAtualizacao': this.moment(value.ultimaAtualizacao).format('DD/MM/YYYY'),
                })
              }
            })
          }
          data.forEach(mr => {
            if (!mr.medical_record) {
              datas.push({
                'antecedente': mr.name,
                'situacao': null,
                'ultimaAtualizacao': this.moment().format('DD/MM/YYYY'),
              })
            }
          })
          break;
        case 'cirurgias-oftalmologicas-anteriores':
          if (previousEyeSurgeries) {
            previousEyeSurgeries.medical_record.value.forEach(value => {
              if (names.includes(value.cirurgia)) {
                datas.push({
                  'cirurgia': value.cirurgia,
                  'data': value.data,
                })
              }
            })
          }
          data.forEach(mr => {
            if (!mr.medical_record) {
              datas.push({
                'cirurgia': mr.name,
                'data': null,
              })
            }
          })
          break;
        case 'alergias':
          if (allergies) {
            allergies.medical_record.value.forEach(value => {
              if (names.includes(value.alergia)) {
                datas.push({
                  'alergia': value.alergia,
                  'comentario': value.comentario,
                })
              }
            })
          }
          data.forEach(mr => {
            if (!mr.medical_record) {
              datas.push({
                'alergia': mr.name,
                'comentario': null,
              })
            }
          })
          break;
        case 'outros-medicamentos-em-uso':
          if (medicinesInUse) {
            medicinesInUse.medical_record.value.forEach(value => {
              if (names.includes(value.medicamento)) {
                datas.push({
                  'medicamento': value.medicamento,
                  'emUso': value.emUso,
                })
              }
            })
          }
          data.forEach(mr => {
            if (!mr.medical_record) {
              datas.push({
                'medicamento': mr.name,
                'emUso': true,
              })
            }
          })
          break;
        case 'antecedentes-familiares':
          if (familyBackground) {
            familyBackground.medical_record.value.forEach(value => {
              if (names.includes(value.antecedente)) {
                datas.push({
                  'antecedente': value.antecedente,
                  'familiar': value.familiar,
                })
              }
            })
          }
          data.forEach(mr => {
            if (!mr.medical_record) {
              datas.push({
                'antecedente': mr.name,
                'familiar': [],
              })
            }
          })
          break;
      }
      return datas
    }
  },
  watch: {
    canEdit: function(){
      if(this.canEdit){
        this.timerInterval = setInterval(() => {
          this.timerInSeconds += 1
        }, 1000)
      } else {
        this.timerInterval = null
      }
    },
    formGroups: {
      deep: true,
      handler(formGroups, oldFormGroups) {
        const cidForm = this.getForm('Diagnóstico', 'cid')
        this.getForm('Conduta', 'retorno')

        this.canSave = cidForm.value.length > 0 // Pode salvar se: valor do "Formulário CID" é maior que 1 e se o campo "retorno" estiver preenchido // &&
        if (this.canSave) {
          localStorage.removeItem('attendance')
        } else {
          localStorage.setItem(
            'attendance',
            JSON.stringify({
              id: this.$route.params.idConsulta,
              patient_id: this.$route.params.id
            })
          )
        }
      }
    },
    attendanceReview: {
      deep: true,
      handler: function(val) {
        this.attendanceReviewCopy = {... this.attendanceReview}
      }
    },
    attendanceSignatureItem: {
      deep: true,
      handler: function(val) {
        this.attendanceSignatureItemCopy = {... this.attendanceSignatureItem}
      }
    },
  }
}
</script>
<style lang="scss" scoped>

hr {
  margin: 32px 0;
  padding: 0;
}

#patient_age {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #525c7a;
}
.new-attendance {
  width: 100%;
  height: auto;
  padding: 30px 24px;
  text-align: left;
  display: inline-block;

  .breadcrumbs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 16px;

    .chevron {
      width: 24px;
      stroke: var(--neutral-600);
      margin: 0 8px;
    }

    .link {
      color: var(--blue-500);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    .cancel-btn {
      background-color: var(--states-error);
      border-color: var(--states-error);
    }
  }

  .container {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    background-color: var(--neutral-000);
    border-radius: 8px;
    padding: 0;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .sidebar {
      width: 400px;
      border-right: 1px solid var(--neutral-200);
      display: flex;
      flex-direction: column;

      .content {
        padding: 24px;
        border-bottom: 1px solid var(--neutral-200);

        .patient-photo {
          width: 64px;
          height: 64px;
          border-radius: 32px;
          margin-right: 16px;
        }

        .patient-name {
          font-family: 'Red Hat Display';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 134%;
          letter-spacing: -0.01em;
          color: var(--dark-blue);
          margin-bottom: 4px;
        }

        .medical-record {
          font-family: 'Nunito Sans';
          font-weight: 400;
          font-size: 12px;
          color: var(--type-placeholder);

          span {
            font-weight: 600;
            color: var(--type-active);
          }
        }

        .historico {
          display: flex;
          flex-direction: row;
          align-items: center;

          .clipboard {
            margin-top: 2px;
            margin-left: -8px;
            transform: scale(0.75) !important;
            stroke: var(--blue-500) !important;
          }
        }

        .tab-menu {
          display: flex;
          background-color: var(--neutral-100);
          border-radius: 8px;

          .tab-item {
            flex: 1;
            display: inline-block;
            padding: 4px 16px;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: var(--type-active);
            border-radius: 8px;
            cursor: pointer;
            text-align: center;

            &.active {
              background-color: var(--blue-500);
              color: var(--neutral-000);
            }
          }
        }

        .durationText {
          font-weight: 600;
          font-size: 16px;
          color: var(--dark-blue);
        }

        .durationTimer {
          background-color: var(--states-success-soft-green);
          padding: 8px 16px;
          border-radius: 100px;
          font-weight: 700;
          font-size: 14px;
          color: var(--type-active);
          display: flex;
          flex-direction: row;
          align-items: center;

          svg {
            width: 16px;
            height: 16px;
            stroke: var(--states-success);
            margin-left: 10px;
          }
        }

        .durationTimerClosed {
          background-color: #d9dff2;
          padding: 8px 16px;
          border-radius: 100px;
          font-weight: 700;
          font-size: 14px;
          color: #505050;
          display: flex;
          flex-direction: row;
          align-items: center;

          svg {
            width: 16px;
            height: 16px;
            stroke: #505050;
            margin-left: 10px;
          }
        }

        &.preConsultation {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background-color: rgba(229, 249, 255, 1) !important;

          .view {
            color: var(--blue-500);
            font-weight: 700;

            &:hover {
              text-decoration: none;
            }
          }
        }

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            font-weight: 600;
            font-size: 16px;
            color: var(--type-active);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 8px 0;
            position: relative;
            cursor: pointer;

            span.button {
              padding: 8px 16px;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              border: 2px solid var(--type-active);
              border-radius: 8px;
              margin-top: 16px;
            }

            svg {
              width: 24px;
              height: 24px;
              stroke: var(--neutral-500);
            }

            &.active {
              font-weight: 700;

              &:before {
                width: 4px;
                height: 100%;
                background-color: var(--orange);
                content: ' ';
                position: absolute;
                top: 0;
                left: -24px;
              }
            }
          }
        }

        .select-forms {
          background: #e7ecfe !important;
          border-radius: 33px !important;
        }
      }

      .attendanceDate {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & > span {
          color: var(--type-active);
          font-weight: 600;
        }

        & > p {
          color: var(--dark-blue);
          font-weight: 600;
        }
      }

      .resume-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 16px 24px;
          border-bottom: 1px solid #d9dff2;

          .avatar {
            width: 35px;
            height: 35px;
            border-radius: 32px;
            margin-right: 16px;
          }

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          svg.icon {
            width: 24px;
            height: 24px;
            fill: #6d789c;
            margin-right: 24px;
            margin-left: 8px;
          }
        }
      }
    }

    .attendance-form {
      display: flex;
      flex-direction: column;

      .buttons-files-container {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .button-files-section {
          display: flex;
          flex-direction: column;
          gap: 4px;
          text-align: right;

          .files-button {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--blue-500);
            padding: 4px 16px;
            border: 2px solid #305bf2;
            border-radius: 8px;
          }
        }
      }

      .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px;
        background-color: var(--neutral-000);
        z-index: 10;

        .title {
          font-family: 'Red Hat Display';
          font-weight: 700;
          font-size: 32px;
          color: var(--dark-blue);
        }

        .examsLength {
          font-weight: 600;
          font-size: 12px;
          color: var(--type-placeholder);
        }
      }

      .scrollable-area {
        padding: 0 16px 32px 16px;

        .ps {
          max-height: 2647px;
        }

        .message-box {
          background-color: #e6f9ff;
          border-radius: 8px;
          padding: 16px 26px;
          display: flex;
          justify-content: center;
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          margin: 0 0 56px 0;
          align-items: center;

          .icon {
            width: 24px;
            height: 24px;
            margin-right: 25px;
            stroke: #0088b3;
          }

          &.warning {
            justify-content: start;
            margin: 0 16px;
            background-color: #fff0e6;

            .icon {
              stroke: #b34b00;
            }
          }
        }

        .form-group-title {
          font-family: 'Red Hat Display';
          font-weight: 700;
          font-size: 18px;
          color: var(--dark-blue);
          margin: 40px 16px 24px 16px;
        }
      }
    }
  }
}

#preconsulta-div {
  width: calc(100% - 32px);
  margin: 0 16px;
  background: #e6f9ff;
  border-radius: 8px;
  padding: 16px 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .inline {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 20px;
      stroke: #0088b2;
      width: 24px;
      height: 24px;
    }
  }

  #semibold {
    font-weight: 800;
    margin-right: 5px;
  }

  #preconsulta-button {
    color: #305bf2;
    font-weight: 600;
    background: none;
    border: none;
  }
}

#preconsulting-modal {
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #0c1d59;
  }

  .pre-consultation-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .pre-consultation-subtitle {
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 134%;
      letter-spacing: -0.01em;
      color: #0e3de2;
      opacity: 0.9;
    }

    .pre-consultation-author {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 132%;
      color: #0c1d59;
    }
  }

  .subtitle {
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    color: #0c1d59;
    margin-bottom: 24px;
  }

  .ar-labels {
    display: grid;
    grid-template-columns: repeat(4, 117px);
    margin-bottom: 2px;
    padding-left: 62px;

    div {
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
    }
  }

  .input-group {

    .input-group-text {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      font-weight: 600;
      width: 62px;
      color: #525c7a;
    }

    .form-control {
      border-radius: 0 8px 8px 0 !important;

      select {
        border: none !important;
      }
    }
  }

  .form-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .form-ar-prepend-one {
    .input-group-text {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0;
      border-bottom-width: 0.5px;
      font-weight: 600;
      width: 62px;
      color: #525c7a;
    }

    .form-control {
      text-align: center;
      max-width: 117px;
      border-radius: 0 !important;
      border-bottom-width: 0.5px;

      &:last-child {
        border-radius: 0 8px 0 0 !important;
      }
    }
  }

  .form-ar-prepend-two {
    margin-bottom: 58px;

    .input-group-text {
      border-radius: 0 0 0 8px !important;
      border-top-width: 0.5px;
      font-weight: 600;
      color: #525c7a;
      width: 62px;
    }

    .form-control {
      text-align: center;
      max-width: 117px;
      border-top-width: 0.5px;
      border-radius: 0 !important;

      &:last-child {
        border-radius: 0 0 8px 0 !important;
      }
    }
  }

  .anamnese-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-bottom: 56px;
  }

  .avsc-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
    margin-bottom: 56px;
  }

  .avcc-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
    margin-bottom: 56px;
  }

  .tonometria-form {
    display: grid;
    grid-template-columns: 3fr 1fr 2fr 2fr;
    grid-gap: 16px;
    margin-bottom: 56px;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 16px;
  }
}

.buttons-container {
  display: flex;
  gap: 20px;
  margin-left: auto;
  margin-right: 28px;

  .finish-later {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #305BF2;
  }

  .finish-and-print {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #00C773;
    border: 2px solid #00C773;
    border-radius: 8px;
    padding: 16px;
  }

  .finish-and-sign {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    background: #00C773;
    border-radius: 8px;
    padding: 16px;
  }
}
</style>
