import { updateAttendanceForm } from '@/utils/attendanceFormHelper'

const store = {
  namespaced: true,
  state: () => ({
    id: 'campo-visual',
    name: 'Campo visual',
    opened: true,
    showing: true,
    completed: false,
    multiple: false,
    scopes: ['ATTENDANCE', 'SURGERY', 'EXAM'],
    fields: {
      aparelho: null,
      olhoDireitoMd: null,
      olhoEsquerdoMd: null,
      olhoDireitoPsd: null,
      olhoEsquerdoPsd: null,
      laudoDireito: null,
      laudoEsquerdo: null,
      observacao: null,
    }
  }),
  mutations: {
    handleProps: (state, { key, value }) => {
      state[key] = value
    },
    handleFields: (state, { key, value }) => {
      state.fields[key] = value
    },
    checkComplete: state => {
      state.completed = Object.keys(state.fields).some(key => state.fields[key])
    }
  },
  getters: {},
  actions: {
    handleProps(context, { key, value }) {
      context.commit('handleProps', { key, value })

      if(context?.rootState?.attendance?.attendance?.id && ['showing' , 'opened'].includes(key)){
        const form = context?.rootGetters['attendance/form/getSelectedForm']
        const forms = form.forms
        const index = forms?.findIndex(form => {
          return form.id === context.state.id
        })
  
        if(index && index !== -1){
          forms[index][key] = value
          form.forms = forms
          updateAttendanceForm(context?.rootState?.attendance?.attendance?.attendance_form?.id, {forms: forms})
          context.dispatch(`attendance/form/updateAttendanceForms`, form, { root: true })
        }
      }
    },
    handleFields(context, { key, value }) {
      context.commit('handleFields', { key, value })
      context.commit('checkComplete')
    }
  },
}

export default store
