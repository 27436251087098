<template>
  <div class="container">
    Preparando documentos...
  </div>
</template>
<script>
export default {
  mounted () {
    this.startComplete();
  },
  methods: {
    async startComplete() {
      const isLoading = this.$loading.show();
      try {
        await this.api.oauthFinishAttendance({ session: this.$route.query.session, customState: this.$route.query.customState });
        window.close();
      } catch (e) {
        console.log('oauthFinishAttendance Error: ', e);
        isLoading.hide();
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
</style>
