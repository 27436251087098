import Vue from "vue"

const getConfigs = async (context, clinic) => {
  const res = await Vue.prototype.api.getSellingPanelConfigs(clinic)
  context.commit('updateConfigs', res.data)
}

const saveConfigs = async (context, payload) => {
  await Vue.prototype.api.upsertSellingPanelConfigs(payload.clinic, payload.data)
  context.commit('updateConfigs', payload.data)
}
export default {
  getConfigs,
  saveConfigs,
}
