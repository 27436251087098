
import Vue from 'vue'
import store from '../store'

export const vCan = {
    bind: function(el, binding, vnode) {
      if (!JSON.parse(localStorage.getItem('permissions'))
          .includes(binding.expression
          .replace(/'/g, '')
          .replace(/"/g, ''))
      ) {
          vnode.elm.style.display = 'none';
      }
    }
}

export const vShowFeature = {
    bind: async function(el, binding, vnode) {
      const key = await binding.expression.replace(/'/g, '').replace(/"/g, '')
      if (store.state.features[key] === undefined) {
        const res = await Vue.prototype.api.featureIsActive(key)
        store.dispatch(`features/updateFeature`, { key, value: res.data.isActive }, { root: true })
      }
      !store.state.features[key] && (vnode.elm.style.display = 'none')
    }
}

export const vShowProduct = {
  bind: function(el, binding, vnode) {
    if (!JSON.parse(localStorage.getItem('clinic'))
        .clinic_features.some(cf => cf.feature.name === binding.expression.replace(/'/g, '').replace(/"/g, ''))
    ) {
        vnode.elm.style.display = 'none';
    }
  }
}
