import Vue from 'vue'
import store from '../../store'
import { getCurrentClinic } from '../../utils/localStorageManager'
import { v4 as uuidv4 } from 'uuid';

export default {
  channel(state) {
    if (state.channel) {
      return state.channel
    }
    const clinic = getCurrentClinic()

    const tabId = uuidv4();
    window.sessionStorage.setItem('tabId', tabId);

    const channel = Vue.prototype.pusher.subscribe(`clinic-${clinic.id}-tab-${window.sessionStorage.getItem('tabId')}`)
    // const channel = Vue.prototype.pusher.subscribe(`clinic-${clinic.id}`)
    store.dispatch('pusher/setChannel', channel, { root: true })
    return channel
  }
}
