<template>
  <div id="app">
    <notifications group="app" position="top center" />
    <router-view />
    <select id="certId" style="display:none"> </select>
    <OrientationsFloatingComponents v-if="isAuthenticated"/>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import 'assets/scss/main';

#app {
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--type-active);
}
</style>

<style lang="scss" src="@/assets/scss/input.scss" />
<script>
import OrientationsFloatingComponents from "@/views/Configs/Components/OrientationsFloatingComponents.vue";
import { isAuthenticated } from "@/utils/localStorageManager";

export default {
  components: {
    OrientationsFloatingComponents
  },
  computed: {
    isAuthenticated() {
      return isAuthenticated();
    }
  }
}
</script>
