<template>
    <div>
        <section class="header">
            <div class="title-container">
                <h2>Painéis</h2>
                <span id="help-icon" class="icon-box">
                    <HelpCircle class="icon stroke" />
                </span>
                <b-tooltip target="help-icon" placement="bottom">
                    Gerencie os painéis de chamadas da sua clínica
                </b-tooltip>
            </div>
            <div>
                <b-button
                    variant="primary"
                    @click="openPanelModal()"
                >
                    Novo painel
                </b-button>
            </div>
        </section>
        <section class="content">
            <span v-if="!panels.length && !isLoading">
                Clique em “Novo painel” para adicionar painéis de chamadas
            </span>
            <span v-else-if="!panels.length && isLoading">
                Carregando painéis...
            </span>
            <div class="flex-container cards" v-else>
                <v-panel-card
                    v-for="(panel, index) in panels" 
                    :key="index"
                    :panel="panel"
                    @update="openPanelModal"
                    @delete="deletePanelModal"
                />
            </div>
        </section>
        <v-panel-modal 
            :panel="selectedPanel"
            @update="getCallPanels"
            @close="clearSelected"
        />
    </div>
</template>

<script>
import HelpCircle from '@/assets/icons/help-circle.svg'
import PanelCard from '@/components/CallPanel/PanelCard.vue'
import PanelModal from '@/components/CallPanel/Modals/PanelModal.vue'

export default {
    components: {
        HelpCircle,
        'v-panel-modal': PanelModal,
        'v-panel-card': PanelCard,
    },
    data(){
        return {
            clinic: JSON.parse(localStorage.getItem('clinic')),
            panels: [],
            selectedPanel: null,
            isLoading: false
        }
    },
    async mounted(){
        await this.getCallPanels()
    },  
    methods: {
        openPanelModal(panel=null){
            if(panel){
                this.selectedPanel = JSON.parse(JSON.stringify(panel));
            }
            this.$bvModal.show('panel-modal')
        },
        async deletePanelModal(panel) {
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">
                    Você tem certeza que deseja excluir o painel <span>${panel.name}</span>?
                    </div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(async res => {
                if (res.isConfirmed) {
                    await this.deletePanel(panel)
                }
            })
        },
        async deletePanel(panel){
            const isLoading = this.$loading.show()
            try {
                await this.api.deleteCallPanelGroup(panel.id)
                this.$toast.success('Painel excluído com sucesso!')
            } catch(error) {
                this.$toast.error(error.message)
            } finally {
                isLoading.hide() 
                this.getCallPanels()
            }
        },
        async getCallPanels(){
            const loading = this.$loading.show();
            try {
                this.panels = []
                this.isLoading = true
                const response = await this.api.getCallPanelGroups(this.clinic.id)
                this.panels = response.data
            } catch (err){
                this.$toast.error(err.message);
            } finally {
                loading.hide();
                this.isLoading = false
            }
        },

        clearSelected(){
            this.selectedPanel = null
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 30px 0;

    .title-container {
        display: flex;
        align-items: center;
        h2 {
            font-family: 'Nunito Sans';
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 0;
            color: var(--type-active);
        }
            
        .icon {
            height: 24px;
            width: 24px;
            margin-left: 5px;
            stroke: var(--neutral-500);
        }
    }
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    span {
        text-align: center;
        margin: 8% 0;
        font-weight: 600;
        font-size: 16px;
    }

    .cards {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        @media (max-width: 1000px) {
            flex-direction: column;
        }
    }
}
</style>