import { userHasPermission } from '@/utils/localStorageManager'

const moduleRoutes = {
    path: '/agenda',
    component: () => import('@/views/Home'),
    children: [
      {
        path: '',
        component: () => import('./views/Home'),
        meta: { requiresAuth: true },
		    beforeEnter: (to, from, next) => customBeforeEnter('FpAgen1', to, from, next)
      },
    ]
}

const customBeforeEnter = (key, to, from, next) => {
	userHasPermission(key) ? next() : next({name: 'noAccess'})
}

export default router => {
  router.addRoute(moduleRoutes)
}