import Vue from 'vue'
import * as PDFLib from 'pdf-lib'

export const mergePrintableAllPDFs = async (attendanceFiles) => {
  const pdfDoc = await PDFLib.PDFDocument.create()
  for (const attendanceFile of attendanceFiles) {
    const res = await Vue.prototype.api.getPrintableDocumentFile(attendanceFile.id)
    const contents = [res.data]
    if (attendanceFile.type_id === '9') {
      contents.push(contents[0])
    }
    for (const content of contents) {
      const blob = new Blob([content], { type: 'application/pdf' })
      const donorPdfBytes = await blob.arrayBuffer()
      const donorPdfDoc = await PDFLib.PDFDocument.load(donorPdfBytes)
      const docLength = donorPdfDoc.getPageCount()
      for (let k = 0; k < docLength; k++) {
        const [donorPage] = await pdfDoc.copyPages(donorPdfDoc, [k])
        pdfDoc.addPage(donorPage)
      }
    }
  }
  return pdfDoc.saveAsBase64({ dataUri: true })
}

export const base64ToBlob = ( base64, type = "application/pdf" ) => {
  const binStr = atob( base64 );
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[ i ] = binStr.charCodeAt( i );
  }
  return new Blob( [ arr ], { type: type } );
}

export const mergeAllPDFs = async (documents) => {
  const pdfDoc = await PDFLib.PDFDocument.create()
  for (let i = 0; i < documents.length; i++) {
      const file = await getDocumentFile(documents[i].id)
      const blob = new Blob([file], { type: 'application/pdf' })
      const donorPdfBytes = await blob.arrayBuffer()
      const donorPdfDoc = await PDFLib.PDFDocument.load(donorPdfBytes)
      const docLength = donorPdfDoc.getPageCount()
      for (let k = 0; k < docLength; k++) {
          const [donorPage] = await pdfDoc.copyPages(donorPdfDoc, [k])
          pdfDoc.addPage(donorPage)
      }
  }
  const base64 = await pdfDoc.saveAsBase64({ dataUri: false });
  const file64 = base64ToBlob(base64);
  const url = URL.createObjectURL( file64 );
  return url;
}

const getDocumentFile = (path) => {
  return new Promise((resolve, reject) => {
    Vue.prototype.api.getDocumentFile(path)
        .then(res => resolve(res.data))
        .catch(reject)
  })
}

export const getDocUrl = async path => {
  if (!path) return
  const loading = Vue.prototype.$loading.show()
  try {
    const { data } = await  Vue.prototype.api.getDocTemporaryUrl(path)
    window.open(data, '_blank')
  } catch(err) {
    Vue.prototype.$toast.error(err.message)
  } finally {
    loading.hide()
  }
}
