import { updateAttendanceForm } from '@/utils/attendanceFormHelper'

const store = {
  namespaced: true,
  state: () => ({
    id: 'pressao-intraocular-corrigida',
    name: 'Pressão intraocular corrigida (pela espessura corneana central)',
    opened: true,
    showing: true,
    completed: false,
    multiple: true,
    scopes: ['ESSILOR_ATTENDANCE'],
    value: [],
    fields: {
      olhoDireito: null,
      olhoEsquerdo: null,
    }
  }),
  mutations: {
    handleProps: (state, { key, value }) => {
      state[key] = value
    },
    handleFields: (state, { key, value }) => {
      state.fields[key] = value
    },
    checkComplete: state => {
      state.completed = state.value && state.value.length > 0
    },
    addValue: (state, value) => {
      state.value.push(value)
    },
  },
  getters: {},
  actions: {
    handleProps(context, { key, value }) {
      context.commit('handleProps', { key, value })
      context.commit('checkComplete')

      if(context?.rootState?.attendance?.attendance?.id && ['showing' , 'opened'].includes(key)){
        const form = context?.rootGetters['attendance/form/getSelectedForm']
        const forms = form.forms
        const index = forms?.findIndex(form => {
          return form.id === context.state.id
        })

        if(index && index !== -1){
          forms[index][key] = value
          form.forms = forms
          updateAttendanceForm(context?.rootState?.attendance?.attendance?.attendance_form?.id, {forms: forms})
          context.dispatch(`attendance/form/updateAttendanceForms`, form, { root: true })
        }
      }
    },
    handleFields(context, { key, value }) {
      context.commit('handleFields', { key, value })
      context.commit('checkComplete')
    },
    addValue(context, value){
      context.commit('addValue', value)
      context.commit('checkComplete')
    }
  },
}

export default store