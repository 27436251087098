
const setAllHealthPlans = (state, payload) => {
  state.allHealthPlans = payload
}

const setTissClinicHealthPlans = (state, payload) => {
  state.tissClinicHealthPlans = payload
}

export default {
  setAllHealthPlans,
  setTissClinicHealthPlans
}
