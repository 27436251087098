

export const ITEM_TYPE_PROCEDURE = 'PROCEDURE';
export const ITEM_TYPE_APPOINTMENT = 'APPOINTMENT';
export const ITEM_TYPE_EXAM = 'EXAM';
export const ITEM_TYPE_SURGICAL = 'SURGICAL';
export const ITEM_TYPE_RETURN = 'RETURN';
export const ITEM_TYPE_TELEMEDICINE = 'TELEMEDICINE';
export const ITEM_TYPE_OTHER_EXPENSES = 'OTHER_EXPENSES';
export const ITEM_TYPE_PRODUCT = 'PRODUCT';
export const ITEM_TYPE_BRASINDICE = 'BRASINDICE';
export const ITEM_TYPE_SIMPRO = 'SIMPRO';



export const ITEM_TYPES = [
    ITEM_TYPE_PROCEDURE,
    ITEM_TYPE_APPOINTMENT,
    ITEM_TYPE_EXAM,
    ITEM_TYPE_SURGICAL,
    ITEM_TYPE_RETURN,
    ITEM_TYPE_TELEMEDICINE,
    ITEM_TYPE_OTHER_EXPENSES,
    ITEM_TYPE_PRODUCT,
    ITEM_TYPE_BRASINDICE,
    ITEM_TYPE_SIMPRO
];

export const notImplementedTypes = [
    ITEM_TYPE_PRODUCT,
]

export const PROCEDURE_ITEM_TYPE_OPTIONS = [
    { label: 'Procedimento', value: ITEM_TYPE_PROCEDURE },
    { label: 'Consulta', value: ITEM_TYPE_APPOINTMENT },
    { label: 'Exame', value: ITEM_TYPE_EXAM },
    { label: 'Cirúrgico', value: ITEM_TYPE_SURGICAL },
    { label: 'Retorno', value: ITEM_TYPE_RETURN },
    { label: 'Telemedicina', value: ITEM_TYPE_TELEMEDICINE }
];

export const ITEM_TYPE_OPTIONS = [
    { label: 'Procedimento', value: ITEM_TYPE_PROCEDURE },
    { label: 'Consulta', value: ITEM_TYPE_APPOINTMENT },
    { label: 'Exame', value: ITEM_TYPE_EXAM },
    { label: 'Cirúrgico', value: ITEM_TYPE_SURGICAL },
    { label: 'Retorno', value: ITEM_TYPE_RETURN },
    { label: 'Telemedicina', value: ITEM_TYPE_TELEMEDICINE },
    { label: 'Outras despesas', value: ITEM_TYPE_OTHER_EXPENSES },
    { label: 'Produto', value: ITEM_TYPE_PRODUCT },
    { label: 'Brasindice', value: ITEM_TYPE_BRASINDICE },
    { label: 'Simpro', value: ITEM_TYPE_SIMPRO }
];

export const getByTypesByGroup = (type) => {
    if (!type) return ITEM_TYPES;
    const types = {
        [ITEM_TYPE_PROCEDURE]: [ITEM_TYPE_PROCEDURE, ITEM_TYPE_APPOINTMENT, ITEM_TYPE_EXAM, ITEM_TYPE_SURGICAL, ITEM_TYPE_RETURN, ITEM_TYPE_TELEMEDICINE],
        [ITEM_TYPE_OTHER_EXPENSES]: [ITEM_TYPE_OTHER_EXPENSES],
        [ITEM_TYPE_PRODUCT]: [ITEM_TYPE_PRODUCT],
        [ITEM_TYPE_BRASINDICE]: [ITEM_TYPE_BRASINDICE],
        [ITEM_TYPE_SIMPRO]: [ITEM_TYPE_SIMPRO]
    };
    return types[type] ?? ITEM_TYPES;
};

export const parseTypeToLabel = (type) => {
    const types = {
        [ITEM_TYPE_PROCEDURE]: 'Procedimento',
        [ITEM_TYPE_APPOINTMENT]: 'Consulta',
        [ITEM_TYPE_EXAM]: 'Exame',
        [ITEM_TYPE_SURGICAL]: 'Cirúrgico',
        [ITEM_TYPE_RETURN]: 'Retorno',
        [ITEM_TYPE_TELEMEDICINE]: 'Telemedicina',
        [ITEM_TYPE_OTHER_EXPENSES]: 'Outras despesas',
        [ITEM_TYPE_PRODUCT]: 'Produto',
        [ITEM_TYPE_BRASINDICE]: 'Brasindice',
        [ITEM_TYPE_SIMPRO]: 'Simpro'

    };
    return types[type] ?? ITEM_TYPES;
}

export const TISS_TABLE_OPTIONS = [
    { "label": "TUSS (00) - Tabela própria das operadoras", "value": "00" },
    { "label": "TUSS (18) - Diárias, taxas e gases medicinais", "value": "18" },
    { "label": "TUSS (19) - Materiais e Órteses, Próteses e Materiais Especiais (OPME)", "value": "19" },
    { "label": "TUSS (20) - Medicamentos", "value": "20" },
    { "label": "TUSS (22) - Procedimentos e eventos em saúde", "value": "22" },
    { "label": "TUSS (98) - Tabela de pacotes", "value": "98" }
];

export const EXPENSE_CD_TYPES = ['01', '02', '03', '05', '07', '08'];

export const ITEMS_WITH_HEALTH_PLAN_PROPS = [
    ITEM_TYPE_PROCEDURE,
    ITEM_TYPE_APPOINTMENT,
    ITEM_TYPE_EXAM,
    ITEM_TYPE_SURGICAL,
    ITEM_TYPE_RETURN,
    ITEM_TYPE_TELEMEDICINE,
];