<template>
  <div 
    v-can="'AgOnlProc1'"
    class="professional-procedures-view"
  >
    <b-row v-if="professionals.length">
      <b-col cols="4" class="border-right">
        <b-row>          
          <b-col cols="12" class="border-bottom">
            <p class="form-title mb-3">
              Procedimento

              <span id="help" class="icon-box no-print">
                <HelpCircle class="icon stroke" />
              </span>

              <b-tooltip
                target="help"
                custom-class="custom-tooltip"
                placement="bottom"
                class="w-25"
              >
                <span>
                  <span class="d-block text-left">
                    Para poder definir o procedimento para os profissionais, é necessário habilita-lo na segunda aba do agendamento online em “Profissionais”.
                  </span>
                  <span class="d-block text-left">
                    Os horários e intervalos dos profissionais devem ser configurados nas “configurações da agenda”, bem como a duração padrão geral da consulta.
                  </span>
                </span>
              </b-tooltip>
            </p>
          </b-col>

          <b-col 
            v-for="(professional, index) of professionals" :key="index"
            cols="12" 
            class="d-flex border-bottom align-items-center py-3 clickable"
            :class="{ selected : selected_professional.id === professional.id}"
            @click="selected_professional = professional"
          >
            <Avatar
              size="40"
              :src="professional?.picture" 
            />
            <p class="ml-2">Dr<span v-if="professional.gender == 'F'">a</span>. {{ professional.name}}</p>
          </b-col>
          
        </b-row>
      </b-col>

      <b-col cols="8" class="px-3">
        <div class="d-flex justify-content-between mt-1">
            <h2>Dr<span v-if="selected_professional.gender == 'F'">a</span>. {{selected_professional.name}}</h2>

            <b-button
              v-can="'AgOnlProc2'"
              variant="primary"
              size="lg"
              @click="addProcedure"
            >
              Adicionar procedimento
            </b-button>
        </div>

        <p class="subtitle">Defina os procedimentos do atendimento online para o profissional</p>

        <ProfessionalProceduresTable
          :key="procedures.length"
          :procedures="procedures"
          :editProcedure="editProcedure"
          @reload-procedures="getPublicScheduleProfessionalProceduresByProfessionalId"
        />
      </b-col>      
    </b-row>

    <b-row v-else>
      <b-col cols="12">
        <small>Configure a clinica no agendamento online para continuar</small>
      </b-col>
    </b-row>

    <ProcedureFormModal 
      :professional="selected_professional"
      @reload-procedures="getPublicScheduleProfessionalProceduresByProfessionalId"
      :procedureEdit="procedureEdit"
    />
  </div>
</template>

<script>
export default {
  name: 'ProfessionalProceduresView',
  components: {
    Avatar: () => import('@/components/General/Avatar.vue'),
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    ProcedureFormModal: () => import('./Modals/ProcedureFormModal.vue'),
    ProfessionalProceduresTable: () => import('./Modules/ProfessionalProceduresTable.vue')
  },
  props: {
    professionals: Array,
    clinic: Object,
  },
  created(){
    if(this.professionals.length) this.selected_professional = this.professionals.length ? this.professionals[0] : null
  },
  data() {
    return {
      selected_professional: null,
      procedures: [],
      procedureEdit: null
    }
  },
  methods: {
    async getPublicScheduleProfessionalProceduresByProfessionalId(){
      const isLoading = this.$loading.show();
      try {
        const response = await this.api.getPublicScheduleProceduresByProfessionalId(this.selected_professional.public_schedule_professional.id);
        this.procedures = response.data
      } catch (err) {
        this.$toast.error(err.message);
      } finally {
        isLoading.hide();
      }
    },

    addProcedure(){
      this.procedureEdit = null 
      this.$bvModal.show('procedure_form_modal');
    },

    editProcedure(procedure){
      this.procedureEdit = procedure  
      this.$bvModal.show('procedure_form_modal');
    },

    reloadPublicSchedule(){
      this.$emit('reload-public-schedule')
    }
  },
  watch: {
    'selected_professional': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getPublicScheduleProfessionalProceduresByProfessionalId()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-title{
    color: var(--neutral-600);
    font-size: 18px;
    font-weight: bolder;
    line-height: 28px;
  }

  .selected {
    border-left: 4px solid #FF6B00;
  }

  .clickable {
    cursor: pointer;
  }

  h2{
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 134%;
    letter-spacing: -0.01em;
    color: #525C7A;
  }

  .subtitle {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #525C7A;
  }

  .icon {
    height: 24px;
    width: 24px;
    margin-left: 5px;
    stroke: var(--neutral-500);
  }
</style>
