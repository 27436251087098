import { updateAttendanceForm } from '@/utils/attendanceFormHelper'

const store = {
  namespaced: true,
  state: () => ({
    id: 'biomicroscopia',
    name: 'Biomicroscopia',
    opened: true,
    showing: true,
    completed: false,
    multiple: false,
    scopes: ['ATTENDANCE', 'SURGERY'],
    fields: {
      olhoDireito: null,
      olhoEsquerdo: null,
      ciliosEPalpebrasDireito: null,
      ciliosEPalpebrasEsquerdo: null,
      conjuntivaDireito: null,
      conjuntivaEsquerdo: null,
      corneaDireito: null,
      corneaEsquerdo: null,
      camaraAnteriorDireito: null,
      camaraAnteriorEsquerdo: null,
      irisDireito: null,
      irisEsquerdo: null,
      pupilaDireito: null,
      pupilaEsquerdo: null,
      cristalinoDireito: null,
      cristalinoEsquerdo: null,
      vitreoDireito: null,
      vitreoEsquerdo: null,
      biomicroscopiaDeFundoDireito: null,
      biomicroscopiaDeFundoEsquerdo: null,
      simplified: false,
    }
  }),
  mutations: {
    handleProps: (state, { key, value }) => {
      state[key] = value
    },
    handleFields: (state, { key, value }) => {
      state.fields[key] = value
    },
    checkComplete: state => {
      if(state.fields?.simplified) {
        state.completed = state.fields?.olhoDireito || state.fields?.olhoEsquerdo
      } else {
        state.completed = Object.keys(state.fields)
        .filter(key => key !== 'simplified' && key !== 'olhoDireito' && key !== 'olhoEsquerdo')
        .some(key => state.fields[key])
      }
    }
  },
  getters: {},
  actions: {
    handleProps(context, { key, value }) {
      context.commit('handleProps', { key, value })

      if(context?.rootState?.attendance?.attendance?.id && ['showing' , 'opened'].includes(key)){
        const form = context?.rootGetters['attendance/form/getSelectedForm']
        const forms = form.forms
        const index = forms?.findIndex(form => {
          return form.id === context.state.id
        })
  
        if(index && index !== -1){
          forms[index][key] = value
          form.forms = forms
          updateAttendanceForm(context?.rootState?.attendance?.attendance?.attendance_form?.id, {forms: forms})
          context.dispatch(`attendance/form/updateAttendanceForms`, form, { root: true })
        }
      }
    },
    handleFields(context, { key, value }) {
      context.commit('handleFields', { key, value })
      context.commit('checkComplete')
    }
  },
}

export default store
