<template>
  <div class="custom-appointment">
    <div class="breadcrumbs">
      <div>
        <router-link to="/registros-padrao/registros" tag="span" class="link">
          Registros padrões
        </router-link>
        <ChevronRight class="chevron" />
        <router-link :to="`/registro-padrao/${form.id}`" tag="span">
          {{ getTitleByType }}
        </router-link>
      </div>
    </div>
    <div class="container-data">
      <p class="alert">
        <InfoCircle class="icon" /> Os registros padrões serão salvos
        automaticamente e você poderá usá-los dentro de sua consulta.
      </p>
      <b-row>
        <p class="title">{{ getTitleByType }}</p>
        <b-col cols="12">
          <section class="form-group">
            <toggle-button
              id="simplified"
              class="toggle"
              :width="30"
              :value="Boolean(form.simplified)"
              :sync="true"
              :height="18"
              :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
              @change="handleSimplifiedView"
            />
            <label for="simplified">
              Visualização padrão <strong>simplificada</strong>
            </label>
          </section>
        </b-col>
        <b-col cols="12">
          <div class="form-group simplified-view">
            <label>Visualização padrão simplificada</label>
            <b-row>
              <b-col cols="5">
                <div class="input-eye">
                  <span for="simplified-right" class="tag-eye">
                    <EyeFill /> D
                  </span>
                  <TextArea
                    id="simplified-right"
                    rows="1"
                    v-model="formValue.olhoDireito"
                    :readonly="readonly"
                    type="text"
                    class="form-control"
                    placeholder="Descrever"
                    @blur="updateStandard"
                  />
                </div>
              </b-col>
              <b-col cols="5">
                <div class="input-eye">
                  <span for="simplified-right" class="tag-eye">
                    <EyeFill /> E
                  </span>
                  <TextArea
                    id="simplified-left"
                    rows="1"
                    v-model="formValue.olhoEsquerdo"
                    :readonly="readonly"
                    type="text"
                    class="form-control"
                    placeholder="Descrever"
                    @blur="updateStandard"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <hr />
        </b-col>
        <b-col class="structure-view">
          <section cols="12" class="form-group">
            <label>Visualização padrão em estruturas</label>
          </section>
          <b-col
            cols="12"
            v-for="key in Object.keys(fieldsSequence)"
            :key="key"
          >
            <div class="form-group" v-if="formValue.hasOwnProperty(key)">
              <label>{{ getLabelByKey(key) }}</label>
              <b-row>
                <b-col cols="6">
                  <div class="input-eye">
                    <p class="tag-eye"><EyeFill /> A</p>
                    <TextArea
                      id="padrao"
                      rows="1"
                      v-model="formValue[key]"
                      :readonly="readonly"
                      type="text"
                      class="form-control"
                      placeholder="Descrever"
                      @blur="updateStandard"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { getCurrentUser } from '@/utils/localStorageManager'
import standardRecordMixin from '@/mixins/StandardRecords/standardRecordMixin'

export default {
  name: 'StandardRecord',
  props: {
    readonly: Boolean
  },
  components: {
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    InfoCircle: () => import('@/assets/icons/info-circle.svg'),
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    TextArea: () => import('@/components/General/TextArea')
  },
  async created() {
    await this.getStandardById()
  },
  data() {
    return {
      user: getCurrentUser(),
      id: this.$route.params.id,
      fieldsSequence: {
        ciliosEPalpebras: 'Cílios e pálpebras',
        conjuntiva: 'Conjuntiva',
        cornea: 'Córnea',
        camaraAnterior: 'Câmara anterior',
        iris: 'Iris',
        pupila: 'Pupila',
        cristalino: 'Cristalino',
        vitreo: 'Vítreo anterior',
        biomicroscopiaDeFundo: 'Biomicroscopia de fundo',
        discoOptico: 'Disco óptico',
        escavacao: 'Escavação',
        macula: 'Mácula',
        vasos: 'Vasos',
        retina: 'Retina',
        hipoteseDiagnostico: 'Hipótese/Diagnóstico',
      }
    }
  },
  mixins: [standardRecordMixin()],
  methods: {
    handleSimplifiedView() {
      this.form.simplified = !this.form.simplified
      this.updateStandard()
    },
    async updateStandard() {
      const loading = this.$loading.show()
      try {
        const { data } = await this.api.updateStandard(this.id, {
          value: this.form.value,
          simplified: this.form.simplified
        })
        this.form = data
        this.formValue = data.value
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        loading.hide()
      }
    },
    getLabelByKey(key) {
      const keyLabel = this.fieldsSequence
      if (key === 'vitreo' && this.form.type === 'mapeamento-de-retina')
        return 'Vítreo'

      return keyLabel[key]
    }
  }
}
</script>

<style lang="scss" scoped>
.structure-view {
  width: 100%;
}

.icon {
  height: 24px;
  width: 24px;
  margin-left: 5px;
  stroke: var(--neutral-500);
}

.alert {
  height: 56px;
  margin: 24px;
  background: #e6f9ff;
  border-radius: 8px;
}
.form-group {
  width: 100%;
  border-radius: 8px;
  margin: 24px 24px 0px 24px;
}
.title {
  margin-left: 30px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 132%;
  color: #0c1d59;
}
.container-data {
  width: 100%;
  background-color: var(--neutral-000);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}
.custom-appointment {
  width: 100%;
  height: auto;
  padding: 30px 24px;
  text-align: left;
  display: inline-block;

  .breadcrumbs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 16px;

    .chevron {
      width: 24px;
      stroke: var(--neutral-600);
      margin: 0 8px;
    }

    .link {
      color: var(--blue-500);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    button {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.toggle {
  margin-right: 5px;
}
</style>
