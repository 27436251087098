<template>
  <div id="video-infos">
    <v-tab
      :index="currentTabIndex"
      v-bind:keys="['Descrição','Referências']"
      v-on:onChange="onChangeTab"
    >
      <div class="tab-container" v-if="currentTabIndex === 0">
        <div class="tab-content">{{videoClass.description}}</div>
      </div>
      <div class="tab-container" v-if="currentTabIndex === 1">
        <div class="tab-content">{{videoClass.references}}</div>
      </div>
    </v-tab>
  </div>
</template>
<script>
import Tab from '@/components/Tab.vue'

export default {
  props: {
    videoClass: Object,
  },
  components: {
    'v-tab': Tab,
  },
  data() {
    return {
      currentTabIndex: 0,
    }
  },
  methods: {
    onChangeTab(index) {
      this.currentTabIndex = index;
    },
  }
}
</script>
<style lang="scss">
  #video-infos {
    .tab-container {
      .tab-content {
        white-space: pre-wrap;
        display: block;
        unicode-bidi: embed;
        padding: 24px 0 !important;
        font-weight: 400;
        font-size: 16px;
        color: var(--type-active)
      }
    }
  }
</style>
