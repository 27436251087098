<template>
    <div v-can="'RepMedReg4'">
        <h1 class="title">Regra #{{rule.number}} - {{rule.name}}</h1>

        <hr />

        <div class="mt-5 mb-4">
            <div class="d-flex">
                <h3 class="subtitle mb-4">Configurações específicas</h3>
                <v-info id="specification-tooltip" class="ml-2 mt-1" />

                <b-tooltip target="specification-tooltip" triggers="hover">
                    Ative ou inative as regras conforme as definições<br/>
                    necessárias. "<b>Regra de mínimo garantido</b>" só <br />
                    será aplicada caso a definição do repasse seja <br/>
                    "Equipe vinculada ao procedimento" ou <br/>
                    "Venda de produtos".
                </b-tooltip>  
            </div>

            <b-card class="card"> 
                <b-row class="d-flex mt-3">
                    <toggle-button
                        v-model="rule.specification.guaranteed_minimum_rule"
                        :sync="true"
                        :height="26"
                        :color="{checked: '#00C773', unchecked: '#E1E5F4'}"
                    />
                    <div class="ml-2">
                        <div class="label">Regra de mínimo garantido</div>
                        <div class="description">Valor mínimo assegurado ao médico</div>    
                    </div>
                </b-row>

                <b-row v-if="rule.specification.guaranteed_minimum_rule">
                    <b-col cols="5">
                        <labeL class="label">Mínimo garantido</labeL>
                        <money
                            v-model="rule.specification.guaranteed_minimum"
                            class="form-control"
                            @blur.native="updateSpecification"
                        />
                    </b-col>
                </b-row>            
                
                <!-- <hr class="line mt-4 mb-5"/>

                <b-row class="d-flex">
                    <toggle-button
                        v-model="rule.specification.highest_value_rule"
                        :sync="true"
                        :height="26"
                        :color="{checked: '#00C773', unchecked: '#E1E5F4'}"
                        @change="updateSpecification"
                    />
                    <div class="ml-2">
                        <div class="label">Regra de maior valor</div>
                        <div class="description">Se a produção for maior do que um valor mínimo, paga-se a produção; senão, o mínimo</div>
                    </div>
                </b-row>     -->
            </b-card>
 
        </div>

        <div class="mb-4">
            <h3 class="subtitle mb-4">Vigência da regra</h3>

            <b-card class="card"> 
                <b-row class="d-flex align-items-center">
                    <b-col cols="5">
                        <labeL class="label">Data de início da vigência da regra</labeL>
                        <date-picker
                            v-model="fromDate"
                            name="rule_from"
                            format="DD/MM/YYYY"
                            placeholder="DD/MM/AAAA"
                            :lang="langDatePicker"
                            :clearable="false"
                            class="full"
                        />
                    </b-col>
                </b-row>
            </b-card>
 
        </div>

    </div>
</template>

<script>
import api from '@/modules/onlending/api.js'
import Info from '@/assets/icons/info.svg'

export default {
    name: 'Specification',
    metaInfo: {
        title: 'Eyecare - Regras de repasse - Especificações'
    },
    props: {
        rule: Object,
        validated: Boolean
    },
    components: {
        'v-info': Info
    },
    data() {
        return {
            langDatePicker: {
                formatLocale: {
                    weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
                }
            },
        }
    },
    computed: {
        fromDate: {
            get() {
                return new Date(this.rule.specification.rule_from)
            },
            set(newValue) {
                this.rule.specification.rule_from = newValue
                this.updateSpecification()
            }
        }
    },
    methods: {
        async updateSpecification() {
            try {
                await api.updateOnlendingRuleSpecification(this.rule.specification.id, this.rule.specification)
                this.$toast.success('Configuração atualizada com sucesso')
            } catch (error) {
                this.$toast.error(error.message)
            }
        }
    },
    watch: {
        'rule.specification.guaranteed_minimum_rule': {
            handler(newValue, oldValue) {
                if(oldValue && !newValue){
                    this.rule.specification.guaranteed_minimum = 0;
                    this.updateSpecification()
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
.title {
    margin: 32px 0px;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 134%;
    letter-spacing: -0.01em;
    color: var(--dark-blue);
}

.card{
    border: 1px solid #D9DFF2;
    border-radius: 8px;
    padding: 10px 20px;
}

.subtitle { 
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: var(--type-active);
}

.label{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: var(--type-active);
}

.description{
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--type-placeholder);
}

.line{
    margin: 0px -15px;
}

</style>