<template>
  <div class="card">
    <section class="card__header">
      <h4 class="card__title">{{ panel.name }}</h4>
      <div class="button-container">
        <button v-if="panel.active && departmentsList.length" title="Abrir modo TV" class="btn">
          <TV 
            class="card__icon card__icon--tv" 
            @click="$router.push({
              name: 'CallPanelTelevisor', 
              params: {panelId: panel.id}
            })"
          />
        </button>
        <button v-if="!panel.protected" @click="deletePanel" class="btn">
          <Trash class="card__icon card__icon--trash" />
        </button>
      </div>
    </section>
    <section class="card__departments">
      <div v-if="departmentsList.length">
        <strong class="card__departments__strong">
          Departamentos: ({{ panel.departments.length }})
        </strong>
        <br>
        <div class="card__departments__container">
          <label class="card__departments__label" v-for="(department, key) in departmentsList" :key="key">
            {{ key === departmentsList.length - 1 ? department.name : department.name + ',' }}
          </label>
        </div>
        <b-badge
          id="badge"
          variant="primary"
          v-show="departmentsOnTooltip.length"
        >
          + {{ departmentsOnTooltip.length }}
        </b-badge>
        <b-tooltip target="badge" placement="bottom">
          <p v-for="(department, key) in departmentsOnTooltip" :key="key">
            {{ department.name }}
          </p>
        </b-tooltip>
      </div>
      <label v-else>
        Nenhum  setor cadastrado
      </label>
    </section>
    <section class="card__footer">
      <div class="card__status">
        <label class="card__status__label" for="active">Ativo</label>
        <toggle-button
          v-model="panel.active"
          id="active"
          :sync="true"
          :height="26"
          @change="switchStatus"
          :disabled="disableSwitch"
          :color="{checked: '#00C773', unchecked: '#E1E5F4'}"
        />
      </div>
      <b-button class="card__edit" variant="link" @click="updatePanel">
        Editar
      </b-button>
    </section>
  </div>
</template>

<script>
import Trash from '@/assets/icons/trash.svg'
import TV from '@/assets/icons/tv.svg'

export default {
  components: {
    Trash,
    TV
  },
  props: {
    panel: Object,
  },
  data(){
    return {
      disableSwitch: false,
      departmentsList: [],
      departmentsOnTooltip: [],
    }
  },
  mounted(){
    this.handleDepartments()
  },
  methods: {
    handleDepartments(){
      this.departmentsList = []
      this.departmentsOnTooltip = []
      let index = 0
      if( this.panel.departments ){
        while( index < this.panel.departments.length ){
          if( index < 6 ){
            this.departmentsList.push(this.panel.departments[index])
          } else {
            this.departmentsOnTooltip.push(this.panel.departments[index])
          }
          index++
        }
      }
    },
    updatePanel(){
      this.$emit('update', this.panel);
    },
    deletePanel(){
      this.$emit('delete', this.panel);
    },
    async switchStatus(){
      try {
        this.disableSwitch = true
        await this.api.updateCallPanelGroup(this.panel.id, { active:this.panel.active } )
      } catch (err){
        this.$toast.error(err.message);
      } finally {
        this.disableSwitch = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  box-sizing: border-box;
  width: calc(25% - 48px / 4);
  text-align: left;
  background: var(--neutral-000);
  border-radius: 8px !important;
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    padding-bottom: 5px;
    min-height: 60px;

    .button-container button{
      padding: 5px !important;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
  }

  &__icon {
    &--trash {
      width: 25px;
      stroke: var(--states-red-soft);
    }

    &--tv {
      width: 25px;
      fill: #7F91D2;
    }
  }

  &__departments {
    padding: 1rem;
    padding-top: 5px;
    height: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    &__strong {
      font-size: 14px;
    }

    &__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-height: 50px;
      overflow-y: auto;
    
      &__label {
        font-size: small;
        margin-right: 3px;
      }
    }

  }

  &__footer {
    width: 100%;
    display: flex;
    padding: 1rem;
    margin-top: 10px;
    justify-content: space-between;
    background: var(--neutral-000);
    border-radius: 0px 0px 8px 8px !important;
    border-top: 1px solid var(--neutral-200);
  }

  &__edit {
    font-size: 1rem !important;
  }

  &__status {
    &__label {
      font-weight: 600;
      margin: 0 0.5rem 0 0;
    }
  }

  .card__departments__container::-webkit-scrollbar {
    width: 8px;
  }

  .card__departments__container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .card__departments__container::-webkit-scrollbar-thumb {
    background: #A4B1DF;
    border-radius: 40px;
  }
  
  .card__departments__container::-webkit-scrollbar-thumb:hover {
    background: #7887bd;
  }

  .tv-icon {
    cursor: pointer;
  }
}
</style>
