<template>
  <div>
    <section class="header">
      <div class="title-container">
        <h2>Departamentos e setores</h2>
        <span id="help-icon" class="icon-box">
          <HelpCircle class="icon stroke" />
        </span>
        <b-tooltip target="help-icon" placement="bottom">
          Departamentos e setores
        </b-tooltip>
      </div>
      <div>
        <b-button
          variant="primary"
          @click="openDepartmentModal()"
        >
          Novo departamento
        </b-button>
      </div>
    </section>
    <section class="content">
      <span v-if="!departments.length">
        Clique em “Novo departamento” para adicionar departamentos para o painel de chamada
      </span>

      <div class="flex-container cards" v-else>
        <v-department-card
          v-for="(department, index) in departments" 
          :key="index"
          :department="department"
          @update="openDepartmentModal"
          @delete="deleteDepartmentModal"
        />
      </div>
    </section>
    <v-department-modal 
      :department="selectedDepartment"
      @update="getCallPanelDepartments"
    />
  </div>
</template>

<script>
import HelpCircle from '@/assets/icons/help-circle.svg'

import DepartmentCard from '@/components/CallPanel/DepartmentCard.vue'
import DepartmentModal from '@/components/CallPanel/Modals/DepartmentModal.vue'

export default {
  components: {
    HelpCircle,
    'v-department-modal': DepartmentModal,
    'v-department-card': DepartmentCard,
  },
  data(){
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),

      departments: [],
      selectedDepartment: null,
    }
  },
  async mounted(){
    await this.getCallPanelDepartments()
  },  
  methods: {
    openDepartmentModal(department=null){
      this.selectedDepartment = JSON.parse(JSON.stringify(department));
      this.$bvModal.show('department-modal')
    },
    async deleteDepartmentModal(department) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
          Você tem certeza que deseja excluir o departamento <span>${department.name}</span>?
          </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(async res => {
        if (res.isConfirmed) {
          await this.deleteDepartment(department)
        }
      })
    },
    async deleteDepartment(department){
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteCallPanelDepartment(department.id)
        this.$toast.success('Departamento excluído com sucesso!')
      } catch(error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide() 
        this.getCallPanelDepartments()
      }
    },
    async getCallPanelDepartments(){
      const loading = this.$loading.show();
      try {
        this.departments = []
        const response = await this.api.getCallPanelDepartments(this.clinic.id)
        this.departments = response.data
      } catch (err){
        this.$toast.error(err.message);
      } finally {
        loading.hide();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0;

  .title-container {
    display: flex;
    align-items: center;
    h2 {
      font-family: 'Nunito Sans';
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 0;
      color: var(--type-active);
      
    }
      
    .icon {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      stroke: var(--neutral-500);
    }
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  span {
    text-align: center;
    margin: 8% 0;
    font-weight: 600;
    font-size: 16px;
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }
}
</style>
