<template>
  <b-modal
    v-if="form"
    id="panel-modal"
    :title="panel ? 'Editar Painel' : 'Novo Painel'"
    hide-footer
    centered size="lg"
    @show="onShow"
    @close="closeModal"
  >
    <section class="body">
      <div class="row">
        <div class="col-12">
          <label for="name" class="title-styles">Nome do Painel</label>
          <b-form-input 
            id="name" 
            autocomplete="off"
            v-model="form.name"
            placeholder="Ex.: Painel de chamadas de exemplo"
            
          ></b-form-input>
          <div
            v-if="validated && !form.name"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-12">
          <label for="name" class="title-styles">Departamentos neste painel</label>
          <department-multiselect 
            v-model="form.departments"
            label="Todos"
            placeholder="Selecione os departamentos"
            :departments="form?.departments"
          />
          <div
            v-if="validated && !form.departments.length"
            class="custom-invalid-feedback"
          >
            Selecione pelo menos um departamento
          </div>
        </div>
      </div>
    </section>

    <section class="modal-footer">
      <b-button variant="outline-danger" @click="closeModal">Cancelar</b-button>
      <b-button 
        variant="primary" 
        @click="save"
      >Salvar painel
      </b-button>
    </section>
  </b-modal>
</template>

<script>
// import ChevronDown from '@/assets/icons/chevron-down.svg'
import DepartmentMultiselect from '../DepartmentMultiselect.vue'

export default {
  components: {
    // 'v-chevron-down': ChevronDown,
    'department-multiselect': DepartmentMultiselect
  },
  props: {
    panel: Object
  },
  data(){
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      form: null,
      validated: false,
    }
  },
  mounted(){
    this.form = this.getDefaultForm()
  },
  methods: {
    onShow() {
      this.validated = false
    },
    closeModal() {
      this.$bvModal.hide('panel-modal');
      this.$emit('close');
      this.form = this.getDefaultForm()
    },
    getDefaultForm(){
      return {
        id: null,
        clinic_id: this.clinic.id,
        name: null,
        departments: []
      }
    },
    async save () {
      const loading = this.$loading.show()
      this.validated = true

      
      if (!this.form.name || !this.form.departments.length) {
        this.$toast.warning('Preencha os campos obrigatórios')
        loading.hide()
        
        return
      }
      
      try {
        this.form.departments  = this.form.departments.filter(el => el.value && el.value !== "*")
          .map(el => el.value);
        if (!this.form.id) {
          await this.api.createCallPanelGroup(this.form)
          this.$emit('update');
          this.$toast.success('Departamento salvo com sucesso!')
        }
        else {
          await this.api.updateCallPanelGroup(this.form.id, this.form)
          this.$emit('update')
          this.$toast.success('Alterações salvas com sucesso!')
        }

        this.closeModal()

      } catch (err){
        loading.hide()
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }

      // this.form = this.getDefaultForm()
    }
  },
  watch: {
    panel: function(value){
      if( value ){
        this.form = value
      } else {
        this.form = this.getDefaultForm()
      }
    }
  }

}
</script>

<style lang="scss">

</style>
