<template>
  <b-modal id="confirm-update-protocol-modal" hide-header hide-footer centered>
    <div class="header">
      <div class="header-content">
        <p>Edição de protocolo</p>
        <v-close class="close-svg" @click="onClose" />
      </div>
    </div>

    <div class="body">
      <div class="body-content">
        <v-warning class="warning-svg" />
        <p>
          Atenção! Este é um <strong>protocolo padrão</strong> da Eyecare
          Health, ao editar você estará removendo seu protocolo dos padrões.
          Deseja continuar?
        </p>
        <div class="buttons">
          <b-button
            class="button otline"
            size="lg"
            variant="primary"
            outline
            @click="onClose"
            >Cancelar</b-button
          >
          <b-button
            class="button"
            size="lg"
            variant="primary"
            @click="updateProtocol"
            >Editar protocolo</b-button
          >
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Warning from '@/assets/icons/warning.svg'
import Close from '@/assets/icons/close.svg'

export default {
  name: 'ConfirmUpdateProtocolModal',
  components: {
    'v-warning': Warning,
    'v-close': Close
  },
  props: {
    updateProtocol: Function
  },
  methods: {
    onClose() {
      this.$bvModal.hide('confirm-update-protocol-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
#confirm-update-protocol-modal {
  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 674px;

    .modal-body {
      padding: 0;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: var(--type-active);

      .header {
        border-bottom: 1px solid var(--neutral-200);

        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 24px;

          .close-svg {
            height: 24px;
            width: 24px;
            fill: var(--neutral-500);
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .body-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 56px 0 56px 0;
          width: 50%;

          p {
            margin: 40px 0;
          }

          .warning-svg {
            height: 96px;
            width: 96px;
          }

          .buttons {
            display: flex;
            flex-direction: row;

            .button {
              width: 168px;
              height: 40px;
              &:last-child {
                margin-left: 2%;
              }
            }

            .outline {
              margin: 0 5px;
              color: var(--neutral-000);
            }
          }
        }
      }
    }
  }
}
</style>
