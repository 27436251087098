const setLoading = (state, payload) => {
  state.loading = payload
}

const setHistoryItems = (state, payload) => {
  state.historyItems = payload.data
  state.count = payload.total
  state.limit = payload.per_page
}

const changePage = (state, payload) => {
  state.page = payload
}

export default {
  setLoading,
  setHistoryItems,
  changePage,
}
