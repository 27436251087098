<template>
  <div>
    <div class="header">
      <div class="title">Log de documentos</div>
      <div>Acompanhe os status da geração de documentos em sua consultas</div>
    </div>

    <b-table-simple striped>
      <tbody>
        <tr>
          <td class="td-head">Status</td>
          <td class="td-head">Paciente</td>
          <td class="td-head">Documento</td>
          <td class="td-head">C/ assinatura</td>
          <td class="td-head">Clinica</td>
          <td class="td-head">Data</td>
          <td class="td-head">Resposta</td>
          <td class="td-head"></td>
        </tr>
        <tr v-for="log in logs" :key="log.id">
          <td>
            <b-badge
              class="badge"
              pill
              :variant="parseStatusVariant(log.status)">
              {{ parseStatus(log.status) }}
            </b-badge>
          </td>
          <td class="patient-name">{{ log.attendance?.patient?.name }}</td>
          <td>{{ log.key }}</td>
          <td>{{ log.sign ? 'Sim' : 'Não' }}</td>
          <td>{{ log.attendance?.clinic?.name }}</td>
          <td>{{ moment(log.created_at).format('DD/MM/YY HH:mm') }}</td>
          <td>{{ !log.message ? '-' : log.message }}</td>
          <td>
            <a v-if="log.attendance" :href="`/pacientes/${log.attendance.patient_id}/consulta/${log.attendance.id}`">Ver atendimento</a>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

  </div>
</template>

<script>
import { getCurrentUser, getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'SignLogs',
  metaInfo: {
    title: 'Eyecare - Log de assinaturas'
  },
  mounted() {
    this.getSignLogs()
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      logs: []
    }
  },
  methods: {
    async getSignLogs() {
      const loading = this.$loading.show()
      try {
        const { data } = await this.api.getSignLogs(this.clinic.id, this.user.id)
        this.logs = data
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async goToAttendance(documentJob) {
      if (!documentJob?.attendance) {
        this.$toast.warning('Não foi possível abrir o atendimento')
        return
      }
      this.$router.push(`/pacientes/${documentJob.attendance?.patient_id}/consulta/${documentJob.attendance?.id}`)
    },
    parseStatusVariant(status) {
      const options = {
        pending: 'info',
        finished: 'success',
        failed: 'danger',
        default: 'secondary'
      }
      return options[status] || options.default
    },
    parseStatus(status) {
      const options = {
        pending: 'Pendente',
        finished: 'Finalizado',
        failed: 'Falhou',
        default: status
      }
      return options[status] || options.default
    }
  }
}
</script>

<style lang="scss" scoped>
  .header {
    padding: 20px;
  }
  .td-head {
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    color: var(--type-active);
  }

  .patient-name {
    white-space: nowrap;
  }

  td {
    font-size: 12px;
    vertical-align: middle !important;
  }
  .badge {
    padding: 0.25em 0.6em;
    font-size: 12px;
  }
  .action-button {
    font-size: 12px;
    white-space: nowrap;
  }
</style>
