import api from '../api'
import { getCurrentClinic } from '@/utils/localStorageManager'

const getClinicDays = async ({ commit }) => {
    const clinic = getCurrentClinic()
    try {
        const { data } = await api.getClinicScheduleConfig(clinic.id)
        commit('setClinicDays', data.configs.days)
        commit('setClinicExceptions', data.configs.exceptions)
    } catch (error) {
        console.log(error);
    }
}


export default {
    getClinicDays
}
