<template>
  <div v-can="'RepMedReg4'">
    <section class="header">
      <div class="title">Regra #{{rule.number}} - {{rule.name}}</div>

      <b-button variant="primary" @click="saveConfig()">
        Salvar Configurações
      </b-button>
    </section>

    <b-row>
      <b-col 
        cols="12"  
        class="mb-4"
      >
        <div class="definition-card">
          <b-row>
            <b-col cols="3">
              <b-form-group>
                <label for="status">Perfil</label>
                <multiselect
                  class="with-border"
                  v-model="form.profile_id"
                  placeholder="Selecionar"
                  :options="profilesOptions"
                  :allow-empty="true"
                  :option-height="40"
                  :showLabels="false"
                  label="name"
                  value="id"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="noResult">Nenhum resultado encontrado</template>
                </multiselect>          
                <div v-if="validated && !form.profile_id" class="custom-invalid-feedback">
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group>
                <label for="status">Formato</label>
                <multiselect
                  class="with-border"
                  v-model="form.format"
                  placeholder="Selecionar"
                  :options="formatOptions"
                  :allow-empty="true"
                  :option-height="40"
                  :showLabels="false"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="noOptions"> Nenhuma opção </template>
                  <template slot="noResult">Nenhum resultado encontrado</template>
                </multiselect>    
              </b-form-group>
            </b-col>

            <b-col cols="5">
              <b-form-group>
                <label for="status">Valor</label>
                <money
                  v-if="form.format === 'R$ reais'"
                  v-model="form.value"
                  class="form-control"    
                />
                <b-form-input
                  v-else
                  autocomplete="off"
                  v-model="form.value"
                  type="number"
                  :min="0"
                  :max="100"
                />
                <div v-if="validated && !form.value" class="custom-invalid-feedback">
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="1" class="button mt-45">
              <b-button 
                variant="primary"
                size="lg"
                @click="addDefinition()"
              >
                Adicionar
              </b-button>
            </b-col>
          </b-row>

          <b-row v-if="showTable" :key="reloadTable">
            <b-table-simple striped>
              <tbody>
                <tr class="tr-header">
                  <th scope="col" width="30%">Perfil</th>
                  <th scope="col" width="30%">Formato</th>
                  <th scope="col" width="30%">Valor</th>
                  <th scope="col" width="10%"></th>
                </tr>

                <tr v-for="(definition, index) in rule.definitions" :key="index" class="tr-header">
                  <td width="30%">
                    <b-form-input
                      autocomplete="off"
                      class="form-control"
                      type="text"
                      v-model="profilesOptions.find(option => option.id === definition.profile_id).name"
                      readonly
                    />          
                  </td>              
                  <td width="30%">
                    <multiselect
                      class="with-border"
                      v-model="definition.format"
                      placeholder="Selecionar"
                      :options="formatOptions"
                      :allow-empty="false"
                      :showLabels="false"
                    >
                      <template slot="caret">
                        <div class="chevron">
                          <ChevronDown />
                        </div>
                      </template>
                      <template slot="noResult">Nenhum resultado encontrado</template>
                      <template slot="noOptions"> Nenhuma opção </template>
                    </multiselect>        
                  </td>
                  <td width="30%">
                    <money
                      v-if="definition.format === 'R$ reais'"
                      v-model="definition.value"
                      class="form-control"    
                    />
                    <b-form-input
                      v-else
                      autocomplete="off"
                      v-model="definition.value"
                      type="number"
                      :min="0"
                      :max="100"
                    />
                    <div v-if="validatedPercetage && definition.format === '% porcentagem'" class="custom-invalid-feedback">
                      A somátoria destes valores deve ser menor que 100%
                    </div>
                  </td>
                  <td width="10%">
                    <Delete
                      v-if="!isDefaultDefinition(definition)"
                      @click="deleteDefinition(definition.id ?? null, index)"
                      class="icon delete mt-3"
                    />
                  </td>
                </tr>
              </tbody>
            </b-table-simple>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '@/modules/onlending/api.js'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { parseNumberToMoney } from '@/utils/moneyHelper';

export default {
  name: 'Definitons',
  metaInfo: {
    title: 'Eyecare - Regras de repasse - Definições'
  },
  props: {
    rule: Object,
    getRule: Function
  },
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Delete: () => import('@/assets/icons/delete.svg'),
  },
  created(){
    this.getProfiles()
  },
  data() {
    return {
      clinic: getCurrentClinic(),

      validated: false,
      form: this.getDefaultForm(),

      firstTime: true,
      alreadyCreated: true,
      
      profilesOptions: [],
      formatOptions: [
        'R$ reais',
        '% porcentagem',
      ],
      validatedPercetage: false,
      reloadTable: 0
    }
  },
  computed: {
    showTable: function() {
      return this.rule?.definitions?.length > 0;
    }
  },
  methods: {
    parseNumberToMoney,
    getDefaultForm() {
      return {
        profile_id: null,
        value: 0,
        format: 'R$ reais',

        optMedicoExecutante: null,
        optMedicoLaudador: null,
        optMedicoSolicitante: null,
        optClinicaParceira: null
      }
    },
    getProfiles() {
      const isLoading = this.$loading.show()
      api.getOnlendingProfilesByClinicId(this.clinic.id)
      .then((res) => {
        this.profilesOptions = res.data.filter(profile => profile.active)
        this.optMedicoExecutante = this.profilesOptions.find(option => option.name === 'Médico Executante')
        this.optMedicoLaudador = this.profilesOptions.find(option => option.name === 'Médico Laudador')
        this.optMedicoSolicitante = this.profilesOptions.find(option => option.name === 'Médico Solicitante')
        this.optClinicaParceira = this.profilesOptions.find(option => option.name === 'Clínica Parceira')
      })
      .catch ((error) => {
        this.$toast.error(error.message)
      }) 
      .finally (() => {
        isLoading.hide()
      })
    },

    isDefaultDefinition(definition){
      return (this.optMedicoExecutante && definition.profile_id === this.optMedicoExecutante.id) || 
        (this.optMedicoLaudador && definition.profile_id === this.optMedicoLaudador.id) ||
        (this.optMedicoSolicitante && definition.profile_id === this.optMedicoSolicitante.id) ||
        (this.optClinicaParceira && definition.profile_id === this.optClinicaParceira.id)
    },

    getCreateDefinition(){
      if(!(this.rule.definitions && this.rule.definitions.length && this.profilesOptions.length)){   
        this.rule.definitions = []
        if(this.optMedicoExecutante){
          this.rule.definitions.push(
            {
              profile_id: this.optMedicoExecutante.id,
              value: 0,
              format: '% porcentagem'
            }
          )
        }

        if(this.optMedicoLaudador){
          this.rule.definitions.push(
            {
              profile_id: this.optMedicoLaudador.id,
              value: 0,
              format: '% porcentagem'
            }
          )
        }

        if(this.optMedicoSolicitante){
          this.rule.definitions.push(
            {
              profile_id: this.optMedicoSolicitante.id,
              value: 0,
              format: '% porcentagem'
            }
          )
        }

        if(this.optClinicaParceira){
          this.rule.definitions.push(
            {
              profile_id: this.optClinicaParceira.id,
              value: 0,
              format: '% porcentagem'
            }
          )
        }
        this.reloadTable ++
        this.alreadyCreated = false
      }
    },

    isValidForm() {
      this.validated = true;

      return Boolean(this.form.profile_id &&
      this.form.value &&
      this.form.format) 
    },

    addDefinition(){
      if(!this.isValidForm()) return
      if(this.rule.definitions.findIndex(option => option.profile_id === this.form.profile_id.id) !== -1) {
        this.$toast.error('Perfil já configurado nas definições')
      }
      else{       
        if (typeof this.form.profile_id === 'object') this.form.profile_id = this.form.profile_id ? this.form.profile_id.id : null
        
        this.rule.definitions.push(this.form)
        this.form = this.getDefaultForm();
        this.validated = false
      }
    },

    async saveConfig(){
      this.validatedPercetage = false
      let percetageValue = 0
      this.rule.definitions.forEach(definition => {
        if(definition.format === '% porcentagem') percetageValue += parseInt(definition.value)
      })
      if(percetageValue > 100) {
        this.$toast.warning('A somatória da porcentagem dos perfis acrescentados na regra não deve ser superior a 100%')
        this.validatedPercetage = true
        return
      }
      if(!this.alreadyCreated) await this.createDefinitons() 
      else await this.updateDefinitions()
    },
    async createDefinitons() {
      try {
        const data = {
          rule_id: this.rule.id,
          definitions: this.rule.definitions
        }
        await api.createOnlendingRuleDefinitions(data)
        .then(() => {
          this.$toast.success('Definições cadastradas com sucesso')
          this.getRule()
				})
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async updateDefinitions() {
      try {
        const data = {
          rule_id: this.rule.id,
          definitions: this.rule.definitions
        }
        await api.updateOnlendingRuleDefinitions(data)
        this.$toast.success('Definições atualizadas com sucesso')
        this.getRule()
      } catch (error) {
        this.$toast.error(error.message)
      }
    },

    async deleteDefinition(id, index){
      if(id){
        const isLoading = this.$loading.show()
        try {
          await api.deleteOnlendingRuleDefinition(id)
          this.getRule()
        } catch (error) {
            this.$toast.error(error.message)
        } finally {
            isLoading.hide()
        } 
      }
      this.rule.definitions.splice(index, 1)
      this.$toast.success('Definição removida com sucesso')
    }
  },
  watch: {
    rule: function(val) {
      if(val && this.profilesOptions?.length) this.getCreateDefinition()
      this.alreadyCreated = true
      this.reloadTable++
    },
    profilesOptions: function(val) {
      if(this.rule && val?.length) this.getCreateDefinition()
      this.reloadTable++
    },
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0;

  .title {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 134%;
    letter-spacing: -0.01em;
    color: var(--dark-blue);
    margin-bottom: -10px;
  }
}

.mt-45{
  margin-top: 31px;
}
</style>