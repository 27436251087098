<template>
  <div>
    <div class="wh-container">
      <div class="header">
        <p class="title">Protocolos</p>
        <div>
          <b-button
            v-if="doctorOrDoctorManager"
            class="wh-button no-print"
            variant="primary"
            outline
            @click="$bvModal.show('protocol-modal')"
          >
            Novo protocolo
          </b-button>
        </div>
      </div>
      <v-tab
        :index="currentTabIndex"
        fontSize="0.9em"
        ref="protocols_tabs"
        v-bind:keys="tabs"
        v-on:onChange="onChangeTab"
      >
        <my-protocols-tab
          v-if="currentTabIndex === 0 && doctorOrDoctorManager"
          :onChangeTab="onChangeTab"
          :person="person"
          :updateProtocol="updateProtocol"
        />

        <shared-protocols-tab
          v-if="
            (currentTabIndex === 1 && doctorOrDoctorManager) ||
            (currentTabIndex == 0 && !doctorOrDoctorManager)
          "
          :onChangeTab="onChangeTab"
          :updateProtocol="updateProtocol"
          :doctorOrDoctorManager="doctorOrDoctorManager"
        />

        <clinic-protocol-tab
          v-if="
            (currentTabIndex === 2 && doctorOrDoctorManager) ||
            (currentTabIndex == 1 && !doctorOrDoctorManager)
          "
          :onChangeTab="onChangeTab"
          :updateProtocol="updateProtocol"
          :person="person"
        />

        <recommended-procols-tab
          v-if="
            (currentTabIndex === 3 && doctorOrDoctorManager) ||
            (currentTabIndex == 2 && !doctorOrDoctorManager)
          "
          :onChangeTab="onChangeTab"
          :person="person"
        />

        <medical-literature-protocols-tab
          v-if="
            (currentTabIndex === 4 && doctorOrDoctorManager) ||
            (currentTabIndex == 3 && !doctorOrDoctorManager)
          "
          :onChangeTab="onChangeTab"
          :person="person"
        />

        <protocol-favorites-tab
          v-if="
            (currentTabIndex === 5 && doctorOrDoctorManager) ||
            (currentTabIndex === 4 && !doctorOrDoctorManager)
          "
          :onChangeTab="onChangeTab"
          :updateProtocol="updateProtocol"
          :person="person"
        />

        <protocol-modal
          :protocol="protocol"
          @update-protocols="updateProtocols"
          @clear-protocol-props="clearProtocolProps"
        />
      </v-tab>
    </div>
  </div>
</template>

<script>
import Tab from '@/components/Tab.vue'
import MyProtocolsTab from './MyProtocolsTab.vue'
import ProtocolFavoritesTab from './ProtocolFavoritesTab.vue'
import SharedProtocolsTab from './SharedProtocolsTab.vue'
import ClinicProtocolTab from './ClinicProtocolTab.vue'
import RecommendedProtocolsTab from './RecommendedProtocolsTab.vue'
import MedicalLiteratureProtocols from './MedicalLiteratureProtocols.vue'
import ProtocolModal from '@/components/Protocols/ProtocolModal.vue'
import { EventBus } from '@/utils/eventBus'

export default {
  name: 'Protocols',
  metaInfo: {
    title: 'Eyecare - Protocolos'
  },
  data() {
    const clinic = JSON.parse(localStorage.getItem('clinic'))
    return {
      clinic_name: clinic.name,
      currentTabIndex: 0,
      protocol: null,
      person: JSON.parse(localStorage.getItem('user')),
      role: null,
      tabs: null,
      doctorOrDoctorManager: null,
      updateProtocol: null
    }
  },
  components: {
    'v-tab': Tab,
    'my-protocols-tab': MyProtocolsTab,
    'clinic-protocol-tab': ClinicProtocolTab,
    'protocol-favorites-tab': ProtocolFavoritesTab,
    'recommended-procols-tab': RecommendedProtocolsTab,
    'shared-protocols-tab': SharedProtocolsTab,
    'medical-literature-protocols-tab': MedicalLiteratureProtocols,
    'protocol-modal': ProtocolModal
  },
  mounted() {
    this.role = this.person.clinics[0].role
    this.handleTabIndex()
    this.doctorOrDoctorManager =
      this.person.type === 'DOCTOR' || this.person.type === 'DOCTOR_MANAGER';

    this.tabs = [
      'Meus protocolos',
      this.role === 'DOCTOR_MANAGER'
        ? 'Todos os protocolos'
        : 'Protocolos compartilhados',
      `Protocolos da clínica`,
      'Protocolos recomendados',
      'Literatura médica',
      'Protocolos favoritos'
    ]
    if (!this.doctorOrDoctorManager) {
      this.tabs.splice(0, 1)
    }
    EventBus.$on('editProtocol', data => {
      this.protocol = data
    })
  },
  model: {
    prop: 'currentIndex',
    event: 'onChange'
  },
  methods: {
    onChangeTab(index, dontPush) {
      this.currentTabIndex = index
      if (!dontPush) {
        window.history.pushState(
          null,
          null,
          `/protocolos/${this.getPathByIndex(index)}`
        )
      }
    },
    handleTabIndex() {
      let routes = {}
      if (!this.doctorOrDoctorManager) {
        routes = {
          protocolos_compartilhados: 0,
          protocolos_clinica: 1,
          protocolos_recomendados: 2,
          literatura_medica: 3,
          protocolos_favoritos: 4
        }
      } else {
        routes = {
          meus_protocolos: 0,
          protocolos_compartilhados: 1,
          protocolos_clinica: 2,
          protocolos_recomendados: 3,
          literatura_medica: 4,
          protocolos_favoritos: 5
        }
      }
      this.currentTabIndex = routes[this.$route.params.tab] || 0
    },
    getPathByIndex(index) {
      let path = {}
      if (!this.doctorOrDoctorManager) {
        path = {
          0: 'protocolos_compartilhados',
          1: 'protocolos_clinica',
          2: 'protocolos_recomendados',
          3: 'literatura_medica',
          4: 'protocolos_favoritos'
        }
      } else {
        path = {
          0: 'meus_protocolos',
          1: 'protocolos_compartilhados',
          2: 'protocolos_clinica',
          3: 'protocolos_recomendados',
          4: 'literatura_medica',
          5: 'protocolos_favoritos'
        }
      }
      return path[index]
    },
    updateProtocols(protocol) {
      this.updateProtocol = protocol
    },
    clearProtocolProps() {
      this.protocol = null
    }
  }
}
</script>

<style lang="scss" scoped>
.wh-container {
  height: 100%;
  padding: 30px 24px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 24px;
  }
  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
  }
  .wh-button {
    margin: 0 5px;
    color: var(--neutral-000);
  }
}
</style>
