const setLoading = (state, payload) => {
    state.loadingAppointments = payload
}

const setEvents = (state, payload) => {
    state.events = payload
}

const setSchedules = (state, payload) => {
    state.schedules = payload
}

const setSelectedSchedules = (state, payload) => {
    const scheduleOptions = JSON.parse(localStorage.getItem('scheduleOptions'));
    const user = JSON.parse(localStorage.getItem('user'));
    const clinic = JSON.parse(localStorage.getItem('clinic'));

    let newScheduleOptions = [];
    
    if(scheduleOptions){
        const otherScheduleOptions = scheduleOptions.filter(el => el.userId !== user.id && el.clinicId !== clinic.id);
        newScheduleOptions = otherScheduleOptions.concat(payload);
    }

    localStorage.setItem('scheduleOptions', JSON.stringify(newScheduleOptions));
    state.selectedSchedules = payload;
}

const setSurgicalEvents = (state, payload) => {
    state.surgicalEvents = payload
}

export default {
    setLoading,
    setEvents,
    setSchedules,
    setSelectedSchedules,
    setSurgicalEvents,
}