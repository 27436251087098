<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="glauco_sidebar"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.8996 17.2121C23.3045 15.7612 24.0046 13.8799 24 12.0001C24.0047 10.1202 23.3046 8.23888 21.8996 6.78788C21.8996 6.78788 17.6687 2.58667 17.2682 2.18557C14.3586 -0.728526 9.64135 -0.728523 6.73181 2.18557C6.32563 2.59239 2.10042 6.78788 2.10042 6.78788C0.69663 8.23764 -0.00350702 10.117 1.33266e-05 11.9953C8.38805e-06 11.9969 3.94585e-06 11.9984 0 12C3.91785e-06 12.0016 8.32603e-06 12.0032 1.32248e-05 12.0047C-0.00350047 13.883 0.696637 15.7624 2.10043 17.2121C2.10043 17.2121 6.32566 21.4076 6.73181 21.8144C9.64135 24.7285 14.3587 24.7285 17.2682 21.8144C17.6744 21.4076 21.8521 17.1646 21.8996 17.2121Z"
        fill="#7F91D2"
      />
    </mask>
    <g mask="url(#glauco_sidebar)">
      <path
        d="M21.5634 18.2107C21.683 18.0932 21.7898 17.9891 21.881 17.9012L21.905 17.9253L22.2588 17.5599C23.7577 16.012 24.5046 14.0044 24.5 12.0001C24.5047 9.99576 23.7577 7.9881 22.2588 6.44007L22.2588 6.44L22.2519 6.43308L21.8996 6.78788L22.2518 6.43303L22.2517 6.43289L22.2511 6.43232L22.2488 6.43004L22.2397 6.42102L22.2043 6.38581L22.0693 6.25179L21.5846 5.7704L20.0864 4.28241C18.978 3.18131 17.8213 2.03189 17.622 1.8323C14.5172 -1.27743 9.48285 -1.27743 6.37798 1.8323C6.17585 2.03475 5.01916 3.18418 3.91143 4.28455L2.41463 5.7712L1.93046 6.25202L1.79567 6.38587L1.76025 6.42103L1.75118 6.43004L1.74889 6.43232L1.74831 6.43289L1.74817 6.43303L2.10042 6.78788C0.69663 8.23764 -0.00350702 10.117 1.33266e-05 11.9953L-0.499984 11.9937L-0.499986 11.9962L-0.500005 11.9988L-0.499998 12.0013L-0.499986 12.0038C-0.499987 12.0042 -0.499987 12.0046 -0.499988 12.005C-0.503439 14.0077 0.243546 16.0132 1.74122 17.5599L1.74115 17.56L1.74812 17.5669L2.10043 17.2121L1.74817 17.567L1.74831 17.5671L1.74889 17.5677L1.75118 17.57L1.76025 17.579L1.79567 17.6141L1.93046 17.748L2.41464 18.2288L3.91144 19.7155C5.01917 20.8158 6.17586 21.9653 6.37798 22.1677C9.48285 25.2774 14.5172 25.2774 17.622 22.1677C17.6885 22.1011 17.8612 21.9265 18.1025 21.6826C18.5855 21.1943 19.343 20.4284 20.0727 19.6957C20.623 19.1432 21.1608 18.6061 21.5634 18.2107Z"
        :stroke="stroke"
      />
      <path
        d="M10.3397 10.6097C11.2567 9.64648 12.7433 9.64648 13.6603 10.6097L30.6831 28.4923C31.6 29.4555 31.6 31.0173 30.6831 31.9805L13.6603 49.863C12.7433 50.8263 11.2567 50.8263 10.3397 49.863L-6.68306 31.9805C-7.60001 31.0173 -7.60001 29.4555 -6.68306 28.4923L10.3397 10.6097Z"
        :stroke="stroke"
        stroke-width="2"
      />
      <path
        d="M10.3671 -25.9518C11.284 -26.915 12.7707 -26.915 13.6876 -25.9518L30.7104 -8.06927C31.6274 -7.106 31.6274 -5.54425 30.7104 -4.58098L13.6876 13.3015C12.7707 14.2648 11.284 14.2648 10.3671 13.3015L-6.65572 -4.58098C-7.57268 -5.54425 -7.57268 -7.106 -6.65572 -8.06927L10.3671 -25.9518Z"
        :stroke="stroke"
        stroke-width="2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3196 11.9474L14.3409 14.0261C13.0431 15.3894 10.9391 15.3894 9.64134 14.0261L7.67969 11.9654L9.65845 9.88672C10.9562 8.52344 13.0602 8.52344 14.358 9.88672L16.3196 11.9474Z"
        :fill="stroke"
      />
      <path
        d="M21.2457 16.4489C21.2238 16.4664 21.2055 16.4819 21.1931 16.4925C21.1422 16.5362 21.0813 16.5921 21.0191 16.6505C20.891 16.7708 20.7158 16.9407 20.5123 17.1405C20.1035 17.542 19.5609 18.0839 19.0099 18.6372C18.2697 19.3804 17.5257 20.1326 17.0476 20.6161C16.8052 20.8611 16.6312 21.0371 16.5605 21.1079C14.0416 23.6307 9.95836 23.6307 7.43948 21.1079C7.23449 20.9026 6.07432 19.7497 4.96856 18.6513L3.47162 17.1645L2.9874 16.6836L2.85259 16.5497L2.81717 16.5146L2.81223 16.5097C1.60121 15.2554 0.99697 13.6323 1.00002 12.0066L1.00001 12.0016L1.00001 11.9984H1.00002L1.00001 11.9934C0.996965 10.3677 1.60121 8.74461 2.81224 7.49032L2.81717 7.48542L2.85259 7.45025L2.9874 7.31639L3.47161 6.83553L4.96855 5.34874C6.07431 4.25032 7.23447 3.09745 7.43947 2.89213L6.73181 2.18557L7.43947 2.89213C9.95836 0.369292 14.0416 0.36929 16.5605 2.89212C16.7627 3.09459 17.9228 4.24747 19.0293 5.34659L20.5276 6.83472L21.0124 7.31615L21.1473 7.45018L21.1828 7.4854L21.1877 7.49029C22.3998 8.74566 23.0041 10.3704 23 11.9976L23 12.0026C23.004 13.6035 22.419 15.2022 21.2457 16.4489Z"
        :stroke="stroke"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'GlacoSVGSidebar',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    glaucoActive: false
  }),
  computed: {
    stroke() {
      return this.isActive || this.glaucoActive
        ? 'var(--neutral-000)'
        : '#7F91D2'
    }
  },
  watch: {
    '$route.path'(newPath) {
      if (newPath.indexOf('/glauco') > -1) {
        this.glaucoActive = true
        return
      }
      this.glaucoActive = false
    }
  }
}
</script>
