import { base } from '@/utils/api'

export default {
    getProfessionalsAndRooms(clinicId) {
        return base.get(`schedule/${clinicId}/professionals-rooms`)
    },
    getAppointments(clinicId, params) {
        return base.get(`appointments/${clinicId}/getScheduleAppointments`, { params: { ...params, clinicId } })
    },
    getSurgicalMapData(clinicId, start, filters) {
        return base.get(`surgical-map/${clinicId}`, {
          params: { start, filters }
        })
    },
    getRoomReservationByDay(clinicId, date, filters) {
        return base.get(`roomReservations-getByDay/${clinicId}/${date}`, {
          params: { filters }
        })
    },
}