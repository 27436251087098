<template>
  <b-row>
    <b-col>
      <table class="table-eyecare">
        <thead>
          <tr>
            <th width="50%">Nome do medicamento</th>
            <th width="17%" class="text-center">Quantidade</th>
            <th width="10%">Via</th>
            <th width="23%">Posologia e orientação</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(protocolMedicine, index) of protocolMedicines"
            :key="index"
          >
            <td
              class="td-large"
              v-if="
                typeof protocolMedicine.medicine === 'object' &&
                !protocolMedicine.medicine_name
              "
            >

              {{ protocolMedicine.medicine.product }}
              <br />
              <small>
                {{ protocolMedicine.medicine.company_name }} -
                {{ protocolMedicine.medicine.description }}
              </small>
              <div v-if="protocolMedicine.orientacao" class="orientation">
                * {{ protocolMedicine.orientacao }}
              </div>
              <div class="antimicrobial" v-if="protocolMedicine.antimicrobial || parseInt(protocolMedicine.medicine.antimicrobiano)">
                Antimicrobiano
              </div>
            </td>
            <td v-else>
              {{
                protocolMedicine.medicine_name
                  ? protocolMedicine.medicine_name
                  : protocolMedicine.medicine
              }}
              <div class="antimicrobial" v-if="protocolMedicine.antimicrobial">
                Antimicrobiano
              </div>
            </td>
            <td class="text-center">{{ protocolMedicine.quantity }}</td>
            <td>{{ protocolMedicine.via }}</td>
            <td>
              <span v-if="protocolMedicine.via === 'Ocular'"
                >Pingar {{ protocolMedicine.dose }}</span
              >
              <span v-else-if="protocolMedicine.via == 'Oral'">{{
                protocolMedicine.dose
              }}</span>
              <span v-else>{{ protocolMedicine.dose }}</span>
              <span v-if="protocolMedicine.intervals.includes('De')">
                a cada
                {{
                  protocolMedicine.intervals
                    .split(/[^0-9]/g)
                    .filter(n => n !== '')[0]
                }}
                {{
                  protocolMedicine.intervals.split(' ')[
                    protocolMedicine.intervals.split(' ').length - 1
                  ]
                }}
              </span>
              <span v-else>
                {{ protocolMedicine.intervals }}
              </span>
              <span v-if="protocolMedicine.time_of_use">
                <span v-if="!parseInt(protocolMedicine.time_of_use.replace(/\D/g, ''))">{{
                  protocolMedicine.time_of_use
                }}</span>
                <span v-else>durante {{ protocolMedicine.time_of_use }}</span>
              </span>
            </td>
            <td class="text-right">
              <div class="more-with-menu">
                <v-more-vertical class="more-icon" />
                <div class="menu">
                  <b-button
                    variant="link"
                    @click="editProtocol(protocolMedicine, index)"
                    >Editar</b-button
                  >
                  <b-button
                    variant="link remove mb-0"
                    @click="removeProtocol(protocolMedicine, index)"
                    >Remover</b-button
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </b-col>
  </b-row>
</template>
<script>
import MoreVertical from '@/assets/icons/more-vertical.svg'

export default {
  name: 'ProtocolMedicineTable',
  components: {
    'v-more-vertical': MoreVertical
  },
  props: {
    protocolMedicines: Array,
    isDefaultProtocol: Boolean,
    modal: Boolean
  },
  methods: {
    editProtocol(protocolMedicine, index) {
      this.$emit('editProtocol', protocolMedicine, index)
    },
    removeProtocol(protocolMedicine, index) {
      this.$emit('removeProtocol', protocolMedicine, index)
    }
  }
}
</script>
<style lang="scss" scoped>
  .table-eyecare {
    &.with-laterality {
        tr {
          th {
            width: 25%;
            max-width: 25%;
          }
        }
      }
  }

  .td-large {
    min-height: 120px;
    .medicine {
      /* font-size: max(12px, 1vw); */
    }
    .form-check {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        width: 150px;
      }
    }
  }

  .orientation {
    font-size: 12px;
    color: var(--type-placeholder);
    font-style: italic;
    line-height: normal;
  }

  .antimicrobial {
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: var(--neutral-600);
  }
</style>
