/* eslint-disable */
import { base, baseUpload } from '@/utils/api'

const TABLE_ENDPOINT = '/tables'
const HEALTH_PLAN_PROPS_ENDPOINT = '/item-health-plan-props'

export default {
    getTable(id) {
        return base.get(`${TABLE_ENDPOINT}/${id}`);
    },
    getTables(page, clinicId, tableQuery, healthPlanQuery, types) {
        const config = {
            params: { page, clinicId, tableQuery, healthPlanQuery, types }
        };
        return base.get(`${TABLE_ENDPOINT}`, config);
    },
    createTable(props) {
        return base.post(`${TABLE_ENDPOINT}`, props);
    },
    updateTable(id, props) {
        return base.put(`${TABLE_ENDPOINT}/${id}`, props);
    },
    deleteTable(id) {
        return base.delete(`${TABLE_ENDPOINT}/${id}`);
    },

    getHealthPlanTables(clinicHealthPlanId, query, types, clinicId = null) {
        const config = {
            params: { query, types, clinicId }
        };
        return base.get(`${TABLE_ENDPOINT}/${clinicHealthPlanId}/clinic-health-plan/tables`, config);
    },
    getClinicHealthPlanTableFromTable(clinicHealthPlanId, tableId) {
        return base.get(`${TABLE_ENDPOINT}/${clinicHealthPlanId}/${tableId}/clinic-health-plan-table`);
    },
    getClinicHealthPlanTables(clinicHealthPlanId) {
        return base.get(`${TABLE_ENDPOINT}/${clinicHealthPlanId}/clinic-health-plan-tables`);
    },
    getClinicPlanTables(clinicHealthPlanId) {
        return base.get(`${TABLE_ENDPOINT}/${clinicHealthPlanId}/clinic-plan-tables`);
    },
    createClinicHealthPlanTable(props) {
        return base.post(`${TABLE_ENDPOINT}/clinic-health-plans`, props);
    },
    updateClinicHealthPlanTable(id, props) {
        return base.put(`${TABLE_ENDPOINT}/clinic-health-plans/${id}`, props);
    },
    deleteClinicHealthPlanTable(id) {
        return base.delete(`${TABLE_ENDPOINT}/clinic-health-plans/${id}`);
    },
    importCbhpmXlsx(data) {
        return baseUpload.post(`${TABLE_ENDPOINT}/cbhpm/import`, data);
    },
    cloneAndMoveItemsToOtherTables(table_id) {
        return base.post(`items/run-move-items`, { table_id });
    },
}