export const isAuthenticated = () => {
  return localStorage.getItem('access_token') !== null;
};

export const setAccessToken = (accessToken) => {
  localStorage.setItem('access_token', accessToken);
};

export const getAccessToken = () => {
  return localStorage.getItem('access_token');
};

export const setRefreshToken = (refreshToken) => {
  localStorage.setItem('refresh_token', refreshToken);
};

export const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
};

export const setCurrentUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

export const updateCurrentUser = props => {
  const user = getCurrentUser();
  Object.assign(user, props);
  localStorage.setItem('user', JSON.stringify(user));
};

export const setCurrentClinic = (clinic) => {
  localStorage.setItem('clinic',JSON.stringify(clinic));
};

export const getCurrentClinic = () => {
  return JSON.parse(localStorage.getItem('clinic'));
};

export const getCallPanelGroup = () => {
  return localStorage.getItem('callPanelGroup') || null;
};

export const userHasRole = role => {
  const clinic = getCurrentClinic();
  if (!clinic || !clinic.role) return false;
  return clinic.role === role;
};

export const userHasRoles = roles => {
  const clinic = getCurrentClinic();
  if (!clinic || !clinic.role) return false;
  return roles.includes(clinic.role);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};




export const setPermissions = (permissions) => {
  localStorage.setItem('permissions', JSON.stringify(permissions));
};

export const getPermissions = () => {
  return JSON.parse(localStorage.getItem('permissions'));
};

export const userHasPermission = key => {
  const permissions = getPermissions();
  if (!permissions || !permissions.length) return false;
  return permissions.includes(key)
};

export const setCalendarView = (cpf, view) => {
  const calendarView = JSON.parse(localStorage.getItem('calendarView')) || {}
  calendarView[cpf] = view
  localStorage.setItem('calendarView', JSON.stringify(calendarView));
};

export const getCalendarView = (cpf) => {
  const calendarView = JSON.parse(localStorage.getItem('calendarView'));
  return calendarView && calendarView[cpf] ? calendarView[cpf] : 'timeGridWeek'
};

export const setCalendarInterval = (calendarInterval) => {
  localStorage.setItem('calendarInterval', JSON.stringify(calendarInterval));
};

export const getCalendarInterval = () => {
  return JSON.parse(localStorage.getItem('calendarInterval'));
};

export const getEditingUser = () => {
  return localStorage.getItem('editingUser') || null;
}