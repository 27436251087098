export default [
  {
    "initial": "AC",
    "name": "Acre"
  },{
    "initial": "AL",
    "name": "Alagoas"
  },{
    "initial": "AM",
    "name": "Amazonas"
  },{
    "initial": "AP",
    "name": "Amapá"
  },{
    "initial": "BA",
    "name": "Bahia"
  },{
    "initial": "CE",
    "name": "Ceará"
  },{
    "initial": "DF",
    "name": "Distrito Federal"
  },{
    "initial": "ES",
    "name": "Espírito Santo"
  },{
    "initial": "GO",
    "name": "Goiás"
  },{
    "initial": "MA",
    "name": "Maranhão"
  },{
    "initial": "MG",
    "name": "Minas Gerais"
  },{
    "initial": "MS",
    "name": "Mato Grosso do Sul"
  },{
    "initial": "MT",
    "name": "Mato Grosso"
  },{
    "initial": "PA",
    "name": "Pará"
  },{
    "initial": "PB",
    "name": "Paraíba"
  },{
    "initial": "PE",
    "name": "Pernambuco"
  },{
    "initial": "PI",
    "name": "Piauí"
  },{
    "initial": "PR",
    "name": "Paraná"
  },{
    "initial": "RJ",
    "name": "Rio de Janeiro"
  },{
    "initial": "RN",
    "name": "Rio Grande do Norte"
  },{
    "initial": "RO",
    "name": "Rondônia"
  },{
    "initial": "RR",
    "name": "Roraima"
  },{
    "initial": "RS",
    "name": "Rio Grande do Sul"
  },{
    "initial": "SC",
    "name": "Santa Catarina"
  },{
    "initial": "SE",
    "name": "Sergipe"
  },{
    "initial": "SP",
    "name": "São Paulo"
  },{
    "initial": "TO",
    "name": "Tocantins"
  }
];
