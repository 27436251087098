<!--- View de Dados pessoais da clinica - Responsável pelo gerenciamento dos Dados pessoais da profissional. --->

<template>
  <div>
    <p class="data-title">Dados pessoais</p>
    <div class="data-section">
      <div class="left-panel-container">
        <div class="section">
          <div
              class="d-flex flex-row flex-fill"
              :class="{ 'align-items-center': !editingPersonalData }"
          >
            <div class="more-with-menu">
              <div class="avatar-container">
                <span v-if="!image?.cropedImage">
                  <Avatar :src="personData?.picture" size="64" class="mr-4"/>
                </span>

                <img v-else :src="image?.cropedImage" class="avatar"/>

                <div
                    v-if="editingPersonalData"
                    class="camera-button"
                    @click="switchPictureMenu()"
                >
                  <Camera class="camera"/>
                </div>
              </div>

              <div class="menu" :class="{ opened: openedPictureMenu }">
                <b-button
                    variant="link"
                    @click="$bvModal.show('profile-picture-modal')"
                >
                  Enviar nova foto
                </b-button>
                <b-button
                    v-if="image?.cropedImage || personData?.picture"
                    variant="link"
                    class="remove"
                    @click="removePic"
                >
                  Remover foto
                </b-button>
                <b-button
                    variant="link"
                    class="cancel mb-0"
                    @click="switchPictureMenu()"
                >
                  Cancelar
                </b-button>
              </div>
            </div>
            <div class="flex-fill">
              <div v-show="!editingPersonalData">
                <p class="name">
                  Olá,
                  {{
                    personData.gender === null || personData.gender === 'ND'
                        ? ''
                        : personData.gender === 'M'
                            ? 'Dr. '
                            : 'Dra. '
                  }}{{ personData.name }}
                </p>
                <p class="email">{{ personData.email }}</p>
              </div>

              <div v-show="editingPersonalData">
                <validation-observer
                    ref="personalForm"
                    class="w-100"
                    v-slot="{ invalid }"
                >
                  <b-row>
                    <b-col cols="3">
                      <div class="form-group">
                        <validation-provider
                            name="estado"
                            rules="required"
                            v-slot="{ touched, errors }"
                        >
                          <multiselect
                              id="title"
                              v-model="personalData.gender"
                              :options="titlesOptions"
                              track-by="name"
                              label="name"
                              :showLabels="false"
                              :searchable="false"
                              :allowEmpty="false"
                              placeholder=""
                              class="with-border"
                              :class="{ error: touched && errors.length > 0 }"
                          >
                            <template slot="caret">
                              <div class="chevron">
                                <ChevronDown/>
                              </div>
                            </template>

                            <template slot="noOptions"> Nenhuma opção</template>

                            <template slot="noResult">
                              Nenhum resultado
                            </template>
                          </multiselect>
                        </validation-provider>
                      </div>
                    </b-col>
                    <b-col cols="7">
                      <div class="form-group">
                        <validation-provider
                            name="nome"
                            rules="required"
                            v-slot="{ touched, errors }"
                        >
                          <input
                              autocomplete="off"
                              id="name"
                              v-model="personalData.name"
                              type="text"
                              class="form-control"
                              :class="{ error: touched && errors.length > 0 }"
                          />
                          <span
                              class="error-message"
                              v-if="touched && errors.length > 0"
                          >{{ errors[0] }}</span
                          >
                        </validation-provider>
                      </div>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="10">
                      <div class="form-group">
                        <input
                            autocomplete="off"
                            id="email"
                            v-model="personalData.email"
                            type="text"
                            class="form-control z-index-0"
                            disabled
                        />
                      </div>
                    </b-col>
                  </b-row>

                  <div class="d-flex justify-content-end">
                    <b-button
                        variant="primary-outline"
                        class="no-border btn-cancel"
                        @click="cancel('personal')"
                    >Cancelar
                    </b-button
                    >
                    <b-button
                        variant="primary"
                        :disabled="invalid"
                        @click="save('personal')"
                    >Salvar
                    </b-button
                    >
                  </div>
                </validation-observer>
              </div>
            </div>
          </div>
          <span
              v-if="editOwnData"
              class="link"
              v-show="!editingPersonalData"
              @click="edit('personal')"
          >Editar</span
          >
        </div>
        <div class="section">
          <div class="flex-fill">
            <p class="section-title" v-show="!editingPassword">Senha</p>
            <p class="section-text" v-show="!editingPassword">••••••••••</p>

            <div v-show="editingPassword">
              <validation-observer
                  ref="passwordForm"
                  class="w-100"
                  v-slot="{ invalid }"
              >
                <b-row>
                  <b-col cols="10">
                    <div class="form-group">
                      <label for="actual-password">Senha atual</label>
                      <b-row>
                        <b-col cols="6">
                          <validation-provider
                              mode="lazy"
                              name="senha atual"
                              rules="required"
                              v-slot="{ touched, errors }"
                              vid="actualPassword"
                          >
                            <div class="input-password">
                              <input
                                  id="actual-password"
                                  v-model="passwordData.actual"
                                  :type="!showActualPassword ? 'password' : 'text'"
                                  class="form-control z-index-2"
                                  :class="{ error: touched && errors.length > 0 }"
                              />
                              <EyeSlash
                                  class="eye"
                                  v-if="showActualPassword == false"
                                  @click="showActualPassword = !showActualPassword"
                              />
                              <Eye
                                  class="eye"
                                  v-if="showActualPassword == true"
                                  @click="showActualPassword = !showActualPassword"
                              />
                            </div>
                          </validation-provider>
                        </b-col>
                        <b-col cols="5" class="d-flex align-items-center">
                          <span class="link" @click="forgotPassword"
                          >Esqueci minha senha atual</span
                          >
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <div class="form-group mb-0">
                      <label for="new-password">Nova senha</label>
                      <validation-provider
                          mode="lazy"
                          name="nova senha"
                          rules="required|min:8|password_strength|diff:@actualPassword"
                          v-slot="{ touched, errors }"
                          vid="password"
                      >
                        <div class="input-password">
                          <input
                              id="new-password"
                              v-model="passwordData.new"
                              :type="!showNewPassword ? 'password' : 'text'"
                              class="form-control"
                              :class="{ error: touched && errors.length > 0 }"
                          />
                          <EyeSlash
                              class="eye"
                              v-if="showNewPassword == false"
                              @click="showNewPassword = !showNewPassword"
                          />
                          <Eye
                              class="eye"
                              v-if="showNewPassword == true"
                              @click="showNewPassword = !showNewPassword"
                          />
                        </div>
                        <span class="error-message" v-if="errors.length > 0">{{
                            errors[0]
                          }}</span>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col cols="5">
                    <div class="form-group mb-0">
                      <label for="repeat-new-password">Repetir nova senha</label>
                      <validation-provider
                          mode="lazy"
                          name="repetir nova senha"
                          rules="required|password:@password"
                          v-slot="{ touched, errors }"
                      >
                        <div class="input-password">
                          <input
                              id="repeat-new-password"
                              v-model="passwordData.new_confirmation"
                              :type="
                              !showNewPasswordConfirmation ? 'password' : 'text'
                            "
                              class="form-control"
                              :class="{ error: touched && errors.length > 0 }"
                          />
                          <EyeSlash
                              class="eye"
                              v-if="showNewPasswordConfirmation == false"
                              @click="
                              showNewPasswordConfirmation =
                                !showNewPasswordConfirmation
                            "
                          />
                          <Eye
                              class="eye"
                              v-if="showNewPasswordConfirmation == true"
                              @click="
                              showNewPasswordConfirmation =
                                !showNewPasswordConfirmation
                            "
                          />
                        </div>
                        <span class="error-message" v-if="errors.length > 0">{{
                            errors[0]
                          }}</span>
                      </validation-provider>
                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="10">
                    <div class="form-group">
                      <span class="help"
                      >Dica: utilize símbolos, números e letras maiúsculas para
                        tornar a senha mais forte.</span
                      >
                    </div>
                  </b-col>
                </b-row>

                <div class="d-flex justify-content-end">
                  <b-button
                      variant="primary-outline"
                      class="no-border btn-cancel"
                      @click="cancel('password')"
                  >Cancelar
                  </b-button
                  >
                  <b-button
                      variant="primary"
                      :disabled="invalid"
                      @click="save('password')"
                  >Salvar
                  </b-button
                  >
                </div>
              </validation-observer>
            </div>
          </div>
          <span v-if="editOwnData" class="link" v-show="!editingPassword" @click="edit('password')"
          >Mudar senha de acesso</span
          >
        </div>
        <div class="section">
          <div>
            <p class="section-title">CPF</p>
            <p class="section-text">{{ personData.cpf }}</p>
          </div>
        </div>

        <div v-if="personData.type?.includes('DOCTOR')" class="section">
          <div v-show="!editingMedicalData">
            <p class="section-title">CRM</p>
            <p
                class="section-text"
                v-for="(crm, index) of personData.crms"
                :key="index"
            >
              <span class="state">{{ crm.state }}</span> {{ crm.number }}
              <span class="tag" v-if="crm.main">Principal</span>
            </p>
          </div>

          <div class="flex-fill" v-show="editingMedicalData">
            <validation-observer
                ref="crmsForm"
                class="w-100"
                v-slot="{ invalid }"
            >
              <div class="form-group" v-for="(crm, index) in crms" :key="index">
                <b-row>
                  <b-col>
                    <label>CRM</label>
                  </b-col>
                  <b-col>
                    <Close
                        class="close"
                        v-if="index === 0"
                        :disabled="invalid"
                        @click="save('crms')"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="auto">
                    <div class="form-group state-width mb-0">
                      <validation-provider
                          name="estado"
                          rules="required"
                          v-slot="{ touched, errors }"
                      >
                        <multiselect
                            v-model="crm.state"
                            :options="statesOptions"
                            :showLabels="false"
                            :searchable="false"
                            :allowEmpty="false"
                            placeholder="UF"
                            class="with-border"
                            :class="{ error: touched && errors.length > 0 }"
                        >
                          <template slot="caret">
                            <div class="chevron">
                              <ChevronDown/>
                            </div>
                          </template>

                          <template slot="noOptions"> Nenhuma opção</template>

                          <template slot="noResult"> Nenhum resultado</template>
                        </multiselect>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col cols="4">
                    <validation-provider
                        name="nome"
                        rules="required"
                        v-slot="{ touched, errors }"
                    >
                      <input
                          autocomplete="off"
                          v-model="crm.number"
                          type="text"
                          class="form-control"
                          :class="{ error: touched && errors.length > 0 }"
                          @blur="checkCrm(index)"
                      />
                    </validation-provider>
                  </b-col>
                  <b-col class="d-flex align-items-center justify-content-start">
                    <label class="container ml-0"
                    >Principal
                      <input
                          type="radio"
                          v-model="mainCrm"
                          name="type"
                          :value="index"
                      />
                      <span class="radio"></span>
                    </label>
                    <Icon
                        tooltip="Remover CRM"
                        :class="{ invisible: crms.length === 1 }"
                    >
                      <Delete
                          class="icon"
                          variant="link remove-button"
                          @click="removeCrm(index)"
                      />
                    </Icon>
                  </b-col>
                </b-row>
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <b-button
                    variant="primary-outline"
                    class="no-border btn-cancel p-0"
                    @click="addCrm()"
                >Adicionar mais um CRM
                </b-button
                >
              </div>
            </validation-observer>
          </div>

          <span class="link" v-if="!editingMedicalData && (editOwnData || userCanEdit())" @click="edit('crms')"
          >Editar</span
          >
        </div>

        <div v-if="personData.type?.includes('DOCTOR')" class="section">
          <div v-show="!editingRqeData">
            <p class="section-title">RQE / Subespecialidade</p>
            <p class="section-empty-text" v-if="!personData.rqes">
              Nenhuma rqe definido
            </p>
            <p
                class="section-text"
                v-for="(rqe, index) of personData.rqes"
                :key="index"
            >
              <span class="state">{{ rqe.state }}</span> {{ rqe.number }}
              <span class="state"> / {{ rqe.specialty }}</span>
              <span class="tag" v-if="rqe.main">Principal</span>
            </p>
          </div>

          <div class="flex-fill" v-show="editingRqeData">
            <validation-observer
                ref="rqesForm"
                class="w-100"
                v-slot="{ invalid }"
            >
              <div class="form-group" v-for="(rqe, index) in rqes" :key="index">
                <b-row>
                  <b-col>
                    <label>RQE</label>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="auto">
                    <div class="form-group state-width mb-0">
                      <validation-provider
                          name="estado"
                          rules="required"
                          v-slot="{ touched, errors }"
                      >
                        <multiselect
                            v-model="rqe.state"
                            :options="statesOptions"
                            :showLabels="false"
                            :searchable="false"
                            :allowEmpty="false"
                            placeholder="UF"
                            class="with-border"
                            :class="{ error: touched && errors.length > 0 }"
                        >
                          <template slot="caret">
                            <div class="chevron">
                              <ChevronDown/>
                            </div>
                          </template>

                          <template slot="noOptions"> Nenhuma opção</template>

                          <template slot="noResult"> Nenhum resultado</template>
                        </multiselect>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col cols="3">
                    <div class="form-group mb-0 specialty-multiselect">
                      <validation-provider
                          name="nome"
                          rules="required"
                          v-slot="{ touched, errors }"
                      >
                        <input
                            autocomplete="off"
                            v-model="rqe.number"
                            type="text"
                            class="form-control"
                            :class="{ error: touched && errors.length > 0 }"
                        />
                      </validation-provider>
                    </div>
                  </b-col>

                  <b-col cols="4">
                    <b-row>
                      <b-col>
                        <div class="form-group mb-0 specialty-multiselect">
                          <multiselect
                              v-model="rqe.specialty"
                              :options="specialtiesOptions"
                              :showLabels="false"
                              :searchable="false"
                              :allowEmpty="false"
                              placeholder="Selecionar"
                              class="with-border"
                          >
                            <template slot="caret">
                              <div class="chevron">
                                <ChevronDown/>
                              </div>
                            </template>

                            <template slot="noOptions"> Nenhuma opção</template>

                            <template slot="noResult">
                              Nenhum resultado
                            </template>
                          </multiselect>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                      class="d-flex align-items-center justify-content-between"
                  >
                    <label class="container-radio">
                      Principal
                      <input
                          class="radio"
                          type="radio"
                          v-model="mainRqe"
                          name="type"
                          :value="index"
                      />
                      <span class="checkmark"></span>
                    </label>

                    <Delete
                        variant="link remove-button"
                        @click="removeRqe(index)"
                    />
                  </b-col>
                </b-row>
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <b-button
                    variant="primary-outline"
                    class="no-border btn-cancel p-0"
                    @click="addRqe()"
                >Adicionar mais um RQE
                </b-button
                >
                <div class="d-flex flex-row">
                  <b-button
                      variant="primary-outline"
                      class="no-border btn-cancel"
                      @click="cancel('rqes')"
                  >Cancelar
                  </b-button
                  >
                  <b-button
                      variant="primary"
                      :disabled="invalid"
                      @click="save('rqes')"
                  >Salvar
                  </b-button
                  >
                </div>
              </div>
            </validation-observer>
          </div>

          <div v-if="!editingRqeData && (editOwnData || userCanEdit())">
            <span
              class="link"
              v-if="personData.rqes"
              @click="edit('rqes')"
            >Editar</span>
            <span
                class="link"
                v-if="!personData.rqes"
                @click="addRqe(true)"
            >Adicionar</span>
          </div>
        </div>

        <div class="section">
          <div class="flex-fill">
            <p class="section-title">CNS</p>
            <p class="section-text" v-show="!editingCns">
              {{ personData.cns }}
            </p>

            <div v-show="editingCns">
              <validation-observer
                  ref="cnsForm"
                  class="w-100"
                  v-slot="{ invalid }"
              >
                <div class="d-flex">
                  <div class="form-group">
                    <validation-provider
                        name="celular"
                        rules="required"
                        v-slot="{ touched, errors }"
                    >
                      <input
                          autocomplete="off"
                          id="cns"
                          type="text"
                          v-model="cnsData.cns"
                          class="form-control"
                          placeholder="Descrever"
                          :class="{ error: touched && errors.length > 0 }"
                      />
                      <span
                          class="error-message"
                          v-if="touched && errors.length > 0"
                      >{{ errors[0] }}</span
                      >
                    </validation-provider>
                  </div>
                </div>

                <div class="d-flex justify-content-end">
                  <b-button
                      variant="primary-outline"
                      class="no-border btn-cancel"
                      @click="cancel('cns')"
                  >Cancelar
                  </b-button
                  >
                  <b-button
                      variant="primary"
                      :disabled="invalid"
                      @click="save('cns')"
                  >Salvar
                  </b-button
                  >
                </div>
              </validation-observer>
            </div>
          </div>

          <span v-if="editOwnData" class="link" v-show="!editingCns" @click="edit('cns')"
          >Editar</span
          >
        </div>

        <div class="section">
          <div class="flex-fill">
            <p class="section-title">Celular</p>
            <p class="section-text" v-show="!editingCellphone">
              {{ personData.cellphone }}
            </p>

            <div v-show="editingCellphone">
              <validation-observer
                  ref="cellphoneForm"
                  class="w-100"
                  v-slot="{ invalid }"
              >
                <div class="d-flex">
                  <div class="form-group">
                    <validation-provider
                        name="celular"
                        rules="required"
                        v-slot="{ touched, errors }"
                    >
                      <input
                          autocomplete="off"
                          id="cellphone"
                          type="text"
                          v-model="cellphoneData.cellphone"
                          class="form-control"
                          placeholder="(xx) xxxx-xxxxx"
                          v-mask="['(##) ####-####', '(##) #####-####']"
                          :class="{ error: touched && errors.length > 0 }"
                      />
                      <span
                          class="error-message"
                          v-if="touched && errors.length > 0"
                      >{{ errors[0] }}</span
                      >
                    </validation-provider>
                  </div>
                </div>

                <div class="d-flex justify-content-end">
                  <b-button
                      variant="primary-outline"
                      class="no-border btn-cancel"
                      @click="cancel('cellphone')"
                  >Cancelar
                  </b-button
                  >
                  <b-button
                      variant="primary"
                      :disabled="invalid"
                      @click="save('cellphone')"
                  >Salvar
                  </b-button
                  >
                </div>
              </validation-observer>
            </div>
          </div>

          <span v-if="editOwnData" class="link" v-show="!editingCellphone" @click="edit('cellphone')"
          >Editar</span
          >
        </div>
        <div class="section">
          <div class="flex-fill">
            <p class="section-title" v-show="!editingAddress">Endereço</p>
            <p class="section-text" v-show="!editingAddress">
              {{ personData.address.address }}, {{
                personData.address.number
              }}{{
                personData.address.complement != null &&
                personData.address.complement != ''
                    ? ' - ' + personData.address.complement
                    : ''
              }}<br/>
              {{ personData.address.neighborhood }}<br/>{{
                personData.address.city
              }}, {{ personData.address.state }}<br/>{{
                personData.address?.zipCode
              }}
            </p>

            <div v-show="editingAddress">
              <validation-observer
                  ref="addressForm"
                  class="w-100"
                  v-slot="{ invalid }"
              >
                <b-row>
                  <b-col cols="3">
                    <div class="form-group">
                      <label for="cep">CEP</label>
                      <validation-provider
                          name="cep"
                          rules="required"
                          v-slot="{ touched, errors }"
                      >
                        <input
                            autocomplete="off"
                            id="cep"
                            v-model="addressData.zipCode"
                            type="text"
                            class="form-control"
                            placeholder="xxxxx-xxx"
                            v-mask="'#####-###'"
                            :class="{ error: touched && errors.length > 0 }"
                        />
                        <span
                            class="error-message"
                            v-if="touched && errors.length > 0"
                        >{{ errors[0] }}</span
                        >
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col cols="7">
                    <div class="form-group">
                      <label for="address">Logradouro</label>
                      <validation-provider
                          name="logradouro"
                          rules="required"
                          v-slot="{ touched, errors }"
                      >
                        <input
                            autocomplete="off"
                            id="address"
                            v-model="addressData.address"
                            type="text"
                            class="form-control"
                            :class="{ error: touched && errors.length > 0 }"
                        />
                        <span
                            class="error-message"
                            v-if="touched && errors.length > 0"
                        >{{ errors[0] }}</span
                        >
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col cols="2">
                    <div class="form-group">
                      <label for="number">Nº</label>
                      <validation-provider
                          name="número"
                          rules="required"
                          v-slot="{ touched, errors }"
                      >
                        <input
                            autocomplete="off"
                            id="number"
                            ref="addressNumber"
                            v-model="addressData.number"
                            type="text"
                            class="form-control"
                            :class="{ error: touched && errors.length > 0 }"
                        />
                        <span
                            class="error-message"
                            v-if="touched && errors.length > 0"
                        >{{ errors[0] }}</span
                        >
                      </validation-provider>
                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <div class="form-group">
                      <label for="neighborhood">Bairro</label>
                      <validation-provider
                          name="bairro"
                          rules="required"
                          v-slot="{ touched, errors }"
                      >
                        <input
                            autocomplete="off"
                            id="neighborhood"
                            v-model="addressData.neighborhood"
                            type="text"
                            class="form-control"
                            :class="{ error: touched && errors.length > 0 }"
                        />
                        <span
                            class="error-message"
                            v-if="touched && errors.length > 0"
                        >{{ errors[0] }}</span
                        >
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="form-group">
                      <label for="complement">Complemento</label>
                      <validation-provider name="complement" v-slot="{ touched }">
                        <input
                            autocomplete="off"
                            id="complement"
                            v-model="addressData.complement"
                            type="text"
                            class="form-control"
                            :class="{ touched }"
                        />
                      </validation-provider>
                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <div class="form-group">
                      <label for="city">Cidade</label>
                      <validation-provider
                          name="cidade"
                          rules="required"
                          v-slot="{ touched, errors }"
                      >
                        <input
                            autocomplete="off"
                            id="city"
                            v-model="addressData.city"
                            type="text"
                            class="form-control"
                            :class="{ error: touched && errors.length > 0 }"
                        />
                        <span
                            class="error-message"
                            v-if="touched && errors.length > 0"
                        >{{ errors[0] }}</span
                        >
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col cols="auto">
                    <div class="form-group state-width">
                      <label for="state">Estado</label>
                      <validation-provider
                          name="estado"
                          rules="required"
                          v-slot="{ touched, errors }"
                      >
                        <multiselect
                            id="state"
                            v-model="addressData.state"
                            :options="statesOptions"
                            :showLabels="false"
                            :searchable="false"
                            :allowEmpty="false"
                            placeholder="UF"
                            class="with-border"
                            :class="{ error: touched && errors.length > 0 }"
                        >
                          <template slot="caret">
                            <div class="chevron">
                              <ChevronDown/>
                            </div>
                          </template>

                          <template slot="noOptions"> Nenhuma opção</template>

                          <template slot="noResult"> Nenhum resultado</template>
                        </multiselect>
                      </validation-provider>
                    </div>
                  </b-col>
                </b-row>

                <div class="d-flex justify-content-end">
                  <b-button
                      variant="primary-outline"
                      class="no-border btn-cancel"
                      @click="cancel('address')"
                  >Cancelar
                  </b-button
                  >
                  <b-button
                      variant="primary"
                      :disabled="invalid"
                      @click="save('address')"
                  >Salvar
                  </b-button
                  >
                </div>
              </validation-observer>
            </div>
          </div>

          <span v-if="editOwnData" class="link" v-show="!editingAddress" @click="edit('address')"
          >Editar</span
          >
        </div>
      </div>
      <div v-if="personData.type?.includes('DOCTOR')" class="right-panel-container">
        <HealthPlanSelector :professionalId="editingProfessionalId"  :userCanEdit="userCanEdit()" :editOwnData="editOwnData"/>
        <Orientations v-if="showOrientations()" :relatedId="editingProfessionalId" :type="'professional'" :userCanEdit="userCanEdit()" :editOwnData="editOwnData" />
      </div>
      <ProfilePictureModal
          :personId="personData?.id"
          @change-croped-image="changeCropedImage"
          @update-image="updateImage"
      />
    </div>
  </div>
</template>

<script>
import * as cep from 'cep-promise'
import {getCurrentUser, getEditingUser, userHasPermission} from '@/utils/localStorageManager'
import HealthPlanSelector from "./Components/HealthPlanSelector.vue";
import Orientations from "./Components/Orientations.vue"

export default {
  name: 'PersonalDataView',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    Icon: () => import('@/components/General/Icon'),
    Delete: () => import('@/assets/icons/delete.svg'),
    Avatar: () => import('@/components/General/Avatar.vue'),
    Camera: () => import('@/assets/icons/camera.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Eye: () => import('@/assets/icons/eye.svg'),
    EyeSlash: () => import('@/assets/icons/eye-slash.svg'),
    ProfilePictureModal: () => import('@/components/Patient/ProfilePictureModal'),
    HealthPlanSelector,
    Orientations
  },
  computed: {
    editOwnData() {
      return this.editingProfessionalId === getCurrentUser().id
    },
    rows() {
      return this.healthPlanItems.length
    }
  },
  created() {
    this.getPersonalData()
  },
  data() {
    return {
      isLoading: null,
      dataLoaded: false,

      editingProfessionalId: null,
      editingPersonalData: false,
      editingPassword: false,
      editingMedicalData: false,
      editingRqeData: false,
      editingSpecialtyData: false,

      editingCellphone: false,
      editingCns: false,
      editingAddress: false,

      showActualPassword: false,
      showNewPassword: false,
      showNewPasswordConfirmation: false,

      personData: {
        picture: null,
        gender: null,
        name: null,
        email: null,
        actualPassword: null,
        newPassword: null,
        newPasswordConfirmation: null,
        cpf: null,
        crms: [],
        rqes: [],
        specialty: [],
        cellphone: '',
        cns: '',
        address: {
          zipCode: null,
          address: null,
          number: null,
          complement: null,
          neighborhood: null,
          city: null,
          state: null,
        }
      },

      personalData: {
        gender: '',
        name: '',
        email: ''
      },

      passwordData: {
        actual: '',
        new: '',
        new_confirmation: ''
      },

      crms: [],
      mainCrm: 0,

      rqes: [],
      mainRqe: 0,

      specialty: [],
      mainSpecialty: 0,

      cellphoneData: {
        cellphone: ''
      },

      cnsData: {
        cns: '',
      },

      addressData: {
        zipcode: '',
        address: '',
        number: '',
        complement: '',
        city: '',
        state: ''
      },

      titlesOptions: [
        {value: 'ND', name: ''},
        {value: 'M', name: 'Dr.'},
        {value: 'F', name: 'Dra.'}
      ],
      statesOptions: ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'],

      specialtiesOptions: [
        'Especialista em Retina',
        'Especialista em Uveítes',
        'Oftalmopediatria',
        'Especialista em Cirurgia Refrativa',
        'Especialista em Estrabismo',
        'Especialista em Glaucoma',
        'Especialista em Refração',
        'Especialista em Visão Subnormal',
        'Especialista em Catarata',
        'Plástica Ocular',
        'Especialista em Córnea',
        'Especialista em Lentes de Contato',
        'Neuroftalmologia',
        'Especialista em Cirurgia Refrativa',
        'Especialista em Retina Clínica',
        'Especialista em Retina Clínica e Cirúrgica',
        'Especialista em Oncologia Ocular',
        'Especialista em Vias Lacrimais',
        'Especialista em Órbita',
        'Especialista em Ultrassonografia Ocular',
        'Oftalmologista'
      ],

      openedPictureMenu: false,
      image: {
        selectedFile: '',
        cropedImage: '',
        cropedBlob: ''
      },
      editPicture: false,
      removePicture: false,
      searchPlan: '',
      healthPlanItems: [
        {name: 'Amil', id: 1},
        {name: 'Bradesco', id: 2},
        {name: 'SulAmérica', id: 3},
        {name: 'Unimed', id: 4},
        {name: 'Porto Seguro', id: 5},
      ],
      healthPlanFields: [{key: 'name', label: 'Convênio', sortable: true}],
      currentPage: 1,
      perPage: 3,
      perPageOptions: [3, 5, 10, 20]
    }
  },
  methods: {
    showOrientations(){
      if(userHasPermission('OrPro1') || userHasPermission('OrPro2')){
        return true
      }
    },
    userCanEdit() {
      return userHasPermission('OrPro2')
    },
    getCurrentUser,
    getPersonalData() {
      const isLoading = this.$loading.show()

      if (this.$route.params.professionalId && !getEditingUser()) {
        this.editingProfessionalId = this.$route.params.professionalId
      } else if (!this.$route.params.professionalId && getEditingUser()) {
        this.editingProfessionalId = getEditingUser()
      } else if (this.$route.params.professionalId && getEditingUser()) {
        this.editingProfessionalId = this.$route.params.professionalId
      } else {
        this.editingProfessionalId = getCurrentUser().id
      }

      localStorage.setItem('editingUser', this.editingProfessionalId)

      this.api.getPersonalData(this.editingProfessionalId).then(({data}) => {
        if (Object.keys(data).length) {
          this.formatPersonObject(data)
        }
      }).catch(err => {
        console.log('Err', err)
      }).finally(() => {
        this.dataLoaded = true
        isLoading.hide()
      })
    },

    formatPersonObject(personInfo) {
      this.personData = {
        professionalId: personInfo.professional_id,
        picture: personInfo.picture,
        gender: personInfo.gender,
        name: personInfo.name,
        email: personInfo.email,
        actualPassword: null,
        newPassword: null,
        newPasswordConfirmation: null,
        cpf: personInfo.cpf,
        crms: personInfo.crms,
        rqes: personInfo.rqes,
        specialty: personInfo.specialty,
        cellphone: personInfo.cellphone,
        cns: personInfo.cns,
        type: personInfo.type,
        address: {
          zipCode: personInfo?.address?.zipCode,
          address: personInfo?.address?.address,
          number: personInfo?.address?.number,
          complement: personInfo?.address?.complement,
          neighborhood: personInfo?.address?.neighborhood,
          city: personInfo?.address?.city,
          state: personInfo?.address?.state,
        }
      }
    },

    forgotPassword() {
      // TODO call API
      this.$toast.success('Enviamos um e-mail para você recuperar a sua senha!');
    },
    async checkCrm(index, removeOnInvalid = false) {
      const crm = this.crms[index]

      if (crm.state !== null && crm.number !== null && crm.number !== '') {
        const isLoading = this.$loading.show()
        this.api.searchCrm({state: crm.state, number: crm.number}).then(res => {
          const data = res.data

          if (this.personData.name !== data.nome) {
            if (removeOnInvalid === false) {
              return this.$toast.error(`O CRM ${crm.state} ${crm.number} não pertence a este nome. Por favor, verifique!`);
            }

            this.crms = this.crms.map((crm, i) => {
              if (i === index) {
                crm.number = ''
              }

              if (removeOnInvalid && i === index) {
                return false
              } else {
                return crm
              }
            })
          }
        }).catch(err => {
          console.log('erro', err)
          const request = err.request

          if (request?.status === 400 && removeOnInvalid === false) {
            const responseParsed = JSON.parse(request.response)
            return this.$toast.error(responseParsed.error);
          }

          this.personData.crms = this.personData.crms.map((crm, i) => {
            if (i === index) {
              crm.number = ''
            }

            if (removeOnInvalid && i === index) {
              return false
            } else {
              return crm
            }
          })
        }).finally(() => {
          isLoading.hide()
        })
      }
    },
    addCrm() {
      this.crms.push({id: null, state: '', number: '', main: false})
    },
    removeCrm(index) {
      if (this.crms.length > 1) {
        const isMainCrm = this.crms[index].main

        this.crms.splice(index, 1)

        if (isMainCrm) {
          this.mainCrm = 0
        }
      }
    },

    addRqe(create) {
      this.rqes.push({id: null, state: '', number: '', specialty: '', main: false})
      if (create) {
        this.$refs.rqesForm.reset()
        this.editingRqeData = true
      }
    },
    removeRqe(index) {
      const isMainRqe = this.rqes[index].main

      this.rqes.splice(index, 1)

      if (isMainRqe) {
        this.mainRqe = 0
      }
    },

    searchCEP() {
      if (this.addressData.zipCode.length === 9) {
        cep(this.addressData.zipCode).then(res => {
          this.addressData.address = res.street
          this.addressData.number = ''
          this.addressData.neighborhood = res.neighborhood
          this.addressData.city = res.city
          this.addressData.state = res.state

          this.$refs.addressNumber.focus()
        }).catch(err => {
          console.log('Err', err)
        })
      }
    },

    edit(form) {
      if (form === 'personal') {
        let gender = ''

        if (this.personData.gender === 'M') {
          gender = {value: 'M', name: 'Dr.'}
        } else if (this.personData.gender === 'F') {
          gender = {value: 'F', name: 'Dra.'}
        } else if (this.personData.gender === 'ND') {
          gender = {value: 'ND', name: ''}
        }

        this.personalData = {
          gender: gender,
          name: this.personData.name,
          email: this.personData.email
        }

        this.$refs.personalForm.reset()
        this.editingPersonalData = true
      } else if (form === 'password') {
        this.$refs.passwordForm.reset()
        this.editingPassword = true
      } else if (form === 'crms') {
        this.crms = this.personData.crms

        this.personData.crms.forEach((crm, index) => {
          if (crm.main) {
            this.mainCrm = index
          }
        })

        this.$refs.crmsForm.reset()
        this.editingMedicalData = true
      } else if (form === 'rqes') {
        this.rqes = this.personData.rqes

        this.personData.rqes.forEach((rqe, index) => {
          if (rqe.main) {
            this.mainRqe = index
          }
        })

        this.$refs.rqesForm.reset()
        this.editingRqeData = true
      } else if (form === 'cns') {
        this.cnsData = {
          cns: this.personData.cns
        }

        this.$refs.cnsForm.reset()
        this.editingCns = true
      } else if (form === 'cellphone') {
        this.cellphoneData = {
          cellphone: this.personData.cellphone
        }

        this.$refs.cellphoneForm.reset()
        this.editingCellphone = true
      } else if (form === 'address') {
        this.addressData = {
          zipCode: this.personData.address.zipCode,
          address: this.personData.address.address,
          number: this.personData.address.number,
          complement: this.personData.address.complement,
          city: this.personData.address.city,
          state: this.personData.address.state,
          neighborhood: this.personData.address.neighborhood,
        }

        this.$refs.addressForm.reset()
        this.editingAddress = true
      }
    },
    cancel(form) {
      if (form === 'personal') {
        this.personalData = {
          gender: null,
          name: null,
          email: null
        }

        this.editingPersonalData = false
      } else if (form === 'password') {
        this.passwordData = {
          actual: null,
          new: null,
          newConfirm: null
        }

        this.editingPassword = false
      } else if (form === 'crms') {
        this.crms = []

        this.editingMedicalData = false
      } else if (form === 'rqes') {
        this.rqes = []

        this.editingRqeData = false
      } else if (form === 'cns') {
        this.cnsData = {
          cns: null
        }

        this.editingCns = false
      } else if (form === 'cellphone') {
        this.cellphoneData = {
          cellphone: null
        }

        this.editingCellphone = false
      } else if (form === 'address') {
        this.addressData = {
          zipCode: null,
          address: null,
          number: null,
          complement: null,
          city: null,
          state: null
        }

        this.editingAddress = false
      }
    },
    async save(form) {
      let formData = {}

      if (form === 'personal') {
        formData = {
          gender: this.personalData.gender.value,
          name: this.personalData.name
        }
      } else if (form === 'password') {
        formData = {
          ...this.passwordData
        }
      } else if (form === 'crms') {
        this.crms = await Promise.all(this.crms.map(async (crm, i) => {
          await this.checkCrm(i, true)
          return crm
        }))

        formData = {
          crms: [...this.crms.map((crm, index) => {
            crm.main = (index === this.mainCrm)
            return crm
          })]
        }
      } else if (form === 'rqes') {
        formData = {
          rqes: [...this.rqes.map((rqe, index) => {
            rqe.main = (index === this.mainRqe)
            rqe.specialty = rqe.specialty !== "" ? rqe.specialty : "Não informado"
            return rqe
          })]
        }
      } else if (form === 'cns') {
        formData = {
          ...this.cnsData
        }
      } else if (form === 'cellphone') {
        formData = {
          ...this.cellphoneData
        }
      } else if (form === 'address') {
        formData = {
          ...this.addressData
        }
      }

      const picData = new FormData()
      if (this.removePicture) {
        formData.removePicture = true
      } else if (this.editPicture) {
        picData.append('newPicture', this.image.cropedBlob)
        await this.api.updateMyPicture(picData)
            .then(() => {
            })
            .catch(err => this.$toast.error(err.message))
      }
      formData.type = form

      if (this.editOwnData) {
        this.updateMyData(formData, form)
      } else {
        this.updateOthersData(formData, form)
      }
    },

    switchPictureMenu() {
      if (this.editingPersonalData) this.openedPictureMenu = !this.openedPictureMenu
    },
    updateImage(image) {
      this.image.cropedImage = image?.cropedImage
      this.editPicture = true
      this.openedPictureMenu = false
    },
    changeCropedImage(image) {
      this.image.cropedBlob = image
      this.editPicture = true
      this.openedPictureMenu = false
    },
    removePic() {
      this.removePicture = true
      this.editPicture = false
      this.image = {
        selectedFile: '',
        cropedImage: '',
        cropedBlob: ''
      }
      this.personData.picture = null
      this.openedPictureMenu = false
    },
    updateMyData(formData, form) {
      const isLoading = this.$loading.show()
      this.api.updateMyData(formData).then(res => {
        const resData = res.data
        this.personData = {
          type: resData.type,
          picture: resData.picture,
          gender: resData.gender,
          name: resData.name,
          email: resData.email,
          actualPassword: null,
          newPassword: null,
          newPasswordConfirmation: null,
          cpf: resData.cpf,
          crms: resData.crms,
          rqes: resData.rqes,
          specialty: resData.specialty,
          cellphone: resData.cellphone,
          cns: resData.cns,
          address: {
            zipCode: resData.address.zipCode,
            address: resData.address.address,
            number: resData.address.number,
            complement: resData.address.complement,
            neighborhood: resData.address.neighborhood,
            city: resData.address.city,
            state: resData.address.state,
          }
        }

        localStorage.setItem('user', JSON.stringify(resData))
        this.eventHub.$emit('userUpdated')

        this.removePicture = false
        this.editPicture = false
        this.cancel(form)
        if (form === 'password') this.$toast.success('Senha alterada com sucesso!')
      })
          .catch(err => {
            this.$toast.error(err?.response?.data?.error ?? err.message)
          })
          .finally(() => isLoading.hide())
    },
    updateOthersData(formData, form) {
      const isLoading = this.$loading.show()
      this.api.updatePersonalData(formData, this.personData).then(res => {
        const resData = res.data
        this.personData = {
          type: resData.type,
          picture: resData.picture,
          gender: resData.gender,
          name: resData.name,
          email: resData.email,
          actualPassword: null,
          newPassword: null,
          newPasswordConfirmation: null,
          cpf: resData.cpf,
          crms: resData.crms,
          rqes: resData.rqes,
          specialty: resData.specialty,
          cellphone: resData.cellphone,
          cns: resData.cns,
          address: {
            zipCode: resData.address.zipCode,
            address: resData.address.address,
            number: resData.address.number,
            complement: resData.address.complement,
            neighborhood: resData.address.neighborhood,
            city: resData.address.city,
            state: resData.address.state,
          }
        }

        // localStorage.setItem('user', JSON.stringify(resData))
        // this.eventHub.$emit('userUpdated')

        this.removePicture = false
        this.editPicture = false
        this.cancel(form)
        if(form === 'password') this.$toast.success('Senha alterada com sucesso!')
      })
          .catch(err => {
            this.$toast.error(err?.response?.data?.error ?? err.message)
          })
          .finally(() => isLoading.hide())
    }
  },
  watch: {
    'addressData.zipCode': function (v) {
      if (this.dataLoaded && v?.length === 9 && v !== this.personData.address.zipCode) {
        this.searchCEP()
      }
    },
    'editingPersonalData': function (value) {
      if (!value) this.openedPictureMenu = false
    },
    '$route.params.professionalId': function (newProfessionalId, oldProfessionalId) {
      this.editingProfessionalId = newProfessionalId
      this.getPersonalData()
    }
  }
}
</script>

<style lang="scss" scoped>
.radio:active {
  background-color: white;
  color: blue;
  outline: 1px solid blue;
}

.icon {
  height: 30px;
}

.close {
  width: 24px;
}

.data-title {
  font-family: 'Red Hat Display';
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-blue);
  margin: 24px 0px 0px 24px;
}

.data-section {
  padding: 30px 24px;
  text-align: left;
  display: flex;
  gap: 20px;
  justify-content: space-between;

  .left-panel-container {
    background-color: var(--neutral-000);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex: 1;
    box-shadow: 0px 2px 3px 0px #0000001F;

    .section {
      padding: 24px;
      border-bottom: 1px solid var(--neutral-200);
      display: flex;
      justify-content: space-between;

      .more-with-menu {
        position: relative;
        display: inline-block;

        .menu {
          width: 233px;
          position: absolute;
          top: 72px;
          left: 0;
          background: var(--neutral-000);
          border: 1px solid var(--neutral-200);
          box-sizing: border-box;
          box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
          0px 4px 10px -8px rgba(12, 29, 89, 0.2);
          border-radius: 8px;
          padding: 24px;
          opacity: 0;
          transition: all 0.5s;
          flex-direction: column;
          align-items: start;
          z-index: 1;
          display: none;

          .mtb-12 {
            margin: 12px 0;
          }

          .btn {
            width: 100%;
            font-weight: 600;
            margin-bottom: 16px;
            text-align: left;

            &.remove {
              color: var(--states-error);
            }

            &.cancel {
              color: var(--type-active);
            }
          }
        }

        .opened {
          opacity: 1 !important;
          display: block !important;
        }
      }

      .avatar-container {
        position: relative;

        .icon {
          width: 12px;
          height: 12px;
        }
      }

      .avatar {
        width: 64px;
        height: 64px;
        margin-right: 24px;
        border-radius: 80%;
      }

      .link {
        color: var(--blue-500);
        cursor: pointer;
      }

      .section-title {
        font-weight: 600;
        font-size: 16px;
        color: var(--dark-blue);
        margin-bottom: 4px;
      }

      .name {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 4px;
      }

      .email {
        font-weight: 400;
        font-size: 16px;
        color: var(--type-placeholder);
        margin-bottom: 0;
      }

      .section-empty-text {
        font-weight: 400;
        font-size: 16px;
        color: #8696ac;
        margin: 6px 0 0 0;
      }

      .section-text {
        font-weight: 400;
        font-size: 16px;
        color: var(--type-active);
        margin: 4px 0 0 0;

        .state {
          font-weight: 700;
        }

        .tag {
          font-weight: 700;
          font-size: 12px;
          background-color: var(--light-orange-200);
          border-radius: 100px;
          padding: 2px 8px;
        }
      }

      .btn-cancel {
        margin-right: 4px;
      }

      .remove-button {
        font-weight: 600;
        color: var(--states-error);
        margin: 0;
        padding: 0;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .right-panel-container {
    display: flex;
    flex-direction: column;
    flex: 1.2;
    gap: 15px;

    .orientations {
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: var(--neutral-000);
      border-radius: 8px;
      box-shadow: 0px 2px 3px 0px #0000001F;
    }
  }
}

.camera-button {
  width: 24px;
  height: 24px;
  background-color: var(--blue-500);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 25px;
  cursor: pointer;

  .camera {
    width: 12px;
    height: 12px;
    fill: var(--neutral-000);
  }
}

.z-index-0 {
  z-index: 0;
}

.z-index-2 {
  z-index: 2;
}
</style>
