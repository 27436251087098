import Vue from 'vue'
import moment from 'moment'
import { registerAdditionalExamModule } from '@/store/registerModule'

export const summaryTypes = [
  'antecedentes-oftalmologicos',
  'cirurgias-oftalmologicas-anteriores',
  'alergias',
  'antecedentes-pessoais',
  'outros-medicamentos-em-uso',
  'medicamentos-oftalmologicos-em-uso',
  'antecedentes-familiares'
]

export const addAdditionalExamsToForm = attendance => {
  attendance && attendance.medicalRecords.map(medicalRecord => {
    if (!medicalRecord.type.includes('exame-personalizado')) return
    registerAdditionalExamModule(medicalRecord.type, medicalRecord.value.customName, true)
  })
}

export const addAdditionalExamsFromForms = (id, name, status) => {
  registerAdditionalExamModule(id, name, status)
}

export const populateMedicalRecords = (canEdit, attendance, form, patientSummaries) => {
  if (canEdit && attendance.type === 'ATTENDANCE') {
    Object.keys(form).map(key => {
      if (form[key] && form[key].id) {
        form[key] = getMedicalRecordOrSummary(attendance, form[key], key, patientSummaries)
      }
    })
  }

  const medicalRecordsMap = attendance?.medicalRecords.reduce((acc, medicalRecord) => {
    acc[medicalRecord.type] = medicalRecord.value
    return acc
  }, {})

  Object.keys(form).map(key => {
    if (form[key] && typeof form[key] === 'object' && medicalRecordsMap[form[key].id]) {
      const medicalRecordValue = medicalRecordsMap[form[key].id]
      const type = !Array.isArray(medicalRecordValue) ? 'fields' : 'value'
      form[key] = {
        ...form[key],
        showing: true,
        opened: true,
        completed: isNotEmptyArray(medicalRecordValue) || hasSomeField(medicalRecordValue),
        [type]: medicalRecordValue,
      }
    }
  })

  return form
}

const getMedicalRecordOrSummary = (attendance, form, key, patientSummaries) => {
  const medicalRecord = attendance.medicalRecords.find(mr => mr.type === form.id)
  if (!medicalRecord && summaryTypes.includes(form.id)) {
    const summaryItems = filterSummaryItem(form.id, patientSummaries)
    if (summaryItems.length) {
      form = {
        ...form,
        summary: true,
        showing: true,
        opened: true,
        completed: true,
        value: fillSummaryData(summaryItems, form.id),
      }
    }
  }
  return form
}

const filterSummaryItem = (key, patientSummaries) => {
  return patientSummaries.filter(item => {
    return item.type === key
  })
}

const fillSummaryData = (summaryItems, type) => {

  return summaryItems.reduce((acc, summary) => {
    if (!summary.medical_record) {
      acc.push(getDefaultByType(summary))
      return acc
    }

    summary.medical_record.value.map(medicalRecord => {
      !acc.some(el => JSON.stringify(el) === JSON.stringify(medicalRecord)) && acc.push(medicalRecord)
    })
    return acc
  }, [])
}

const getDefaultByType = (summary) => {
    switch (summary.type) {
    case 'antecedentes-pessoais':
      return {
        'antecedente': summary.name,
        'ano': null,
        'custom': null
      }
    case 'antecedentes-oftalmologicos':
      return {
        'antecedente': summary.name,
        'situacao': null,
        'ultimaAtualizacao': moment().format('DD/MM/YYYY'),
      }
    case 'cirurgias-oftalmologicas-anteriores':
      return {
        'cirurgia': summary.name,
        'data': null,
      }
    case 'alergias':
      return {
        'alergia': summary.name,
        'comentario': null,
      }
    case 'outros-medicamentos-em-uso':
      return {
        'medicamento': summary.name,
        'emUso': true,
      }
    case 'antecedentes-familiares':
      return {
        'antecedente': summary.name,
        'familiar': [],
      }
    }
}

const hasSomeField = (fields) => {
  return Object.keys(fields).some(key => isNotEmptyArray(fields[key]) || isNotEmptyField(fields[key]))
}

const isNotEmptyArray = (data) => {
  return Array.isArray(data) && data.length
}

const isNotEmptyField = (field) => {
  return !Array.isArray(field) && field !== undefined && field !== null
}

export const gotToOption = moduleId => {
  const forms = document.getElementById('forms-container')
  const element = document.getElementById(`module-wrapper-${moduleId}`)
  if (!element) {
    Vue.prototype.$toast.warning('Adicione este item à consulta para navegar')
    return
  }
  forms.scrollTo({
    top: element.offsetTop - (window.innerHeight / 3),
    behavior: 'smooth'
  });
}

export const translateType = (type) => {
  switch(type) {
    case 'ATTENDANCE': return 'Consulta'
    case 'PRE_CONSULTATION': return 'Pré-consulta'
    case 'MEDICAL_REPORT': return 'Laudo médico'
    case 'SURGERY': return 'Consulta cirúrgica'
    case 'ANESTHETIST_EVOLUTION': return 'Evolução do anestesista'
    case 'TEAM_EVOLUTION': return 'Evolução da equipe multidisciplinar'
    case 'TEAM_ANNOTATIONS': return 'Anotações da equipe multidisciplinar'
    case 'EXAM': return 'Exame'
    default: return 'Consulta'
  }
}