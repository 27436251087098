<template>
  <div class="comments-container">
    <v-comment-input id="comment" :user="user" :onComment="onComment" />
    <div
      v-for="videoClassComment in comments"
      :key="videoClassComment.id"
    >
      <v-comment-module
        :videoComment="videoClassComment"
        :user="user"
        :onComment="onComment"
        :removeFromComments="removeFromComments"
      />
    </div>
    <div v-if="showMore" class="more" @click="moreVideoClassComments">
      <div>Ver mais</div>
    </div>
  </div>
</template>
<script>
import CommentInput from '@/components/Academy/CommentInput'
import CommentModule from '@/components/Academy/CommentModule'

export default {
  props: {
    user: Object,
    videoClass: Object,
  },
  components: {
    'v-comment-input': CommentInput,
    'v-comment-module': CommentModule,
  },
  data() {
    return {
      comments: [],
      showMore: true,
    }
  },
  methods: {
    async getVideoClassComments() {
      try {
        const res = await this.api.getVideoClassComments(this.videoClass.id, 0)
        this.comments = res.data
        this.showMore = res.data.length === 10;
      } catch(err) {
        this.$toast.warning('Sentimos muito, mas não foi possível carregar os comentários')
      }
    },
    async moreVideoClassComments() {
      try {
        const res = await this.api.getVideoClassComments(this.videoClass.id, this.comments.length)
        if (!res.data.length) {
          this.showMore = false;
          return;
        }
        this.comments = this.comments.concat(res.data)
      } catch(err) {
        this.$toast.warning('Sentimos muito, mas não foi possível carregar os comentários')
      }
    },
    async onComment(comment, videoClassCommentId = null) {
      const data = {
        video_class_comment_id: videoClassCommentId,
        video_class_id: this.videoClass.id,
        person_id: this.user.id,
        comment,
      }
      try {
        await this.api.createVideoClassComment(data)
        this.$toast.success('Comentário adicionado')
        await this.getVideoClassComments()
      } catch(err) {
        this.$toast.error(err.message)
      }
    },
    removeFromComments(videoComment) {
      const comments = [...this.comments]
      for (let index = 0; index < comments.length; index++) {
        if (comments[index].id === videoComment.id) {
          comments.splice(index, 1)
          break
        }

        const childVideoClassComments = comments[index].video_class_comments
        for (let b = 0; b < childVideoClassComments.length; b++) {
          if (childVideoClassComments[b].id === videoComment.id) {
            childVideoClassComments.splice(b, 1)
            break
          }
        }
        comments[index].video_class_comments = childVideoClassComments
      }

      this.comments = comments
    }
  },
  watch: {
    async videoClass() {
      await this.getVideoClassComments()
    }
  }
}
</script>
<style lang="scss" scoped>
  .comments-container {
    border-top: 1px solid var(--neutral-200);

    .more {
      display: flex;
      justify-content: center;

      div {
        padding: 10px;
        font-weight: 600;
        font-size: 18px;
        color: var(--blue-500);
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }
</style>
