<template>
    <div class="body">
        <div class="consent-form">
            <div class="content">
                <div class="company">
                    <p>EYECARE DN SOLUÇÕES DIGITAIS</p>
                </div>
                <div class="title">
                    <p>TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO</p>
                </div>
                <div class="description">
                    <p>Estou ciente e concordo através do presente <span id="bold">TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO</span> que a EYECARE DN SOLUÇÕES DIGITAIS LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 35.800.459/0001-54, colete os dados pessoais, realize seu tratamento e tome decisões referentes aos dados com a finalidade de gerir o cadastro nos procedimentos oftalmológicos a serem realizados, bem como conduza o tratamento com a base legal disposta no artigo 11, inciso II, alínea “f” da Lei n.º 13.709/2018, que determina a utilização por médicos ou serviços clínicos com a finalidade da tutela da saúde.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConsentForm',
    metaInfo: {
    title: 'Eyecare - Termo de Consentimento'
    }
}
</script>

<style lang="scss" scoped>
.body {
    background-color: #e1e1e6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .consent-form {
        height: 150vh;
        width: 65vw;
        background-color: white;
        margin: 3vh 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

        .content {
            margin-top: 15vh;
            max-width: 45vw;
            text-align: justify;
            display: flex;
            flex-direction: column;
            align-items: center;

            .company {
                margin: 0;
                padding: 0;
                font-size: 1.3vw;
            }

            .title {
                margin: 5vh 0 0 0;
                padding: 0;
                font-size: 1.3vw;
                font-weight: bold;
                color: var(--dark-blue);
            }
            
            .description {
                margin: 5vh 0 0 0;
                padding: 0;
                font-size: 1.3vw;

                #bold {
                    font-weight: bold;
                    color: var(--dark-blue);
                }
            }
        }
    }
}
</style>