import pusher from './pusher'
import callPanel from './callPanel'
import healthPlans from './healthPlans'
import attendance from './attendance'
import notifications from './notifications'
import features from './features'
import pendingItems from './pendingItems'
import historyItems from './historyItems'
import nfcontracts from "@/store/nfcontracts";
import Subscriptions from './subscriptions'
import preConsultations from '@/store/preConsultations'
import tiss from './tiss'
import sus from './sus'
import sellingPanel from "@/store/sellingPanel"
import warehouse from '@/store/warehouse'

export default {
  	pusher,
  	callPanel,
  	healthPlans,
  	attendance,
  	notifications,
  	features,
  	pendingItems,
  	historyItems,
	Subscriptions,
	nfcontracts,
	tiss,
	sus,
	sellingPanel,
	preConsultations,
	warehouse,
}
