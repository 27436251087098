import Vue from 'vue'
import {addAdditionalExamsFromForms} from '@/utils/attendanceHelper'
import {removeAdditionalExamModule} from '@/store/registerModule'
// import { debounce } from 'lodash';
import anexarArquivos from './anexarArquivos'

import queixaPrincipal from './queixaPrincipal'
import historiaDaDoencaAtual from './historiaDaDoencaAtual'
import anamnese from './anamnese'
import antecedentesOftalmologicos from './antecedentesOftalmologicos'
import antecedentesPessoais from './antecedentesPessoais'
import antecedentesFamiliares from './antecedentesFamiliares'
import cirurgiasOftalmologicasAnteriores from './cirurgiasOftalmologicasAnteriores'
import habitosDeVida from './habitosDeVida'
import medicamentosOftalmologicosEmUso from './medicamentosOftalmologicosEmUso'
import outrosMedicamentosEmUso from './outrosMedicamentosEmUso'
import alergias from './alergias'
import informacoesAdicionaisAnamnese from './informacoesAdicionaisAnamnese'

import exameFisico from './exameFisico'
import ectoscopia from './ectoscopia'
import avscAcuidadeVisualSemCorrecao from './avscAcuidadeVisualSemCorrecao'
import avccAcuidadeVisualComCorrecao from './avccAcuidadeVisualComCorrecao'
import avppscAcuidadeVisualParaPertoSemCorrecao from './avppscAcuidadeVisualParaPertoSemCorrecao'
import avppccAcuidadeVisualParaPertoComCorrecao from './avppccAcuidadeVisualParaPertoComCorrecao'
import avbplAcuidadeVisualBinocularParaLonge from './avbplAcuidadeVisualBinocularParaLonge'
import avbppAcuidadeVisualBinocularParaPerto from './avbppAcuidadeVisualBinocularParaPerto'
import aberrometria from './aberrometria'
import lensometria from './lensometria'
import refracaoDinamica from './refracaoDinamica'
import refracaoEstatica from './refracaoEstatica'
import autorrefracaoDinamica from './autorrefracaoDinamica'
import autorrefracaoEstatica from './autorrefracaoEstatica'
import acuidadeVisualParaLongeAposRefracao from './acuidadeVisualParaLongeAposRefracao'
import acuidadeVisualParaPertoAposRefracao from './acuidadeVisualParaPertoAposRefracao'
import biomicroscopia from './biomicroscopia'
import pressaoIntraocular from './pressaoIntraocular'
import pressaoIntraocularCorrigida from './pressaoIntraocularCorrigida'
import curvaTensional from './curvaTensional'
import gonioscopia from './gonioscopia'
import esquiascopia from './esquiascopia'
import mapeamentoDeRetina from './mapeamentoDeRetina'
import motilidadeOcularExtrinseca from './motilidadeOcularExtrinseca'
import coverTest from './coverTest'
import reflexos from './reflexos'
import informacoesAdicionais from './informacoesAdicionais'

import biometria from './biometria'
import retinografia from './retinografia'
import paquimetria from './paquimetria'
import campoVisual from './campoVisual'
import octTomografiaDeCoerenciaOptica from './octTomografiaDeCoerenciaOptica'
import octTomografiaDeCoerenciaOpticaNervoOptico from './octTomografiaDeCoerenciaOpticoNervoOptico'
import octTomografiaDeCoerenciaOpticaSegmentoAnterior from './octTomografiaDeCoerenciaOpticoSegmentoAnterior'
import octATomografiaDeCoerenciaOptica from './octATomografiaDeCoerenciaOptica'
import topografia from './topografia'
import tomografiaDeCorneaPentacam from './tomografiaDeCorneaPentacam'
import tomografiaDeCorneaGalilei from './tomografiaDeCorneaGalilei'
import microscopiaEspecularDeCornea from './microscopiaEspecularDeCornea'
import indocianinografiaIcg from './indocianinografiaIcg'
import angiofluoresceinografiaAfg from './angiofluoresceinografiaAfg'
import usgOcular from './usgOcular'
import eletrorretinogramaErgDeCampoTotal from './eletrorretinogramaErgDeCampoTotal'
import ergMultifocal from './ergMultifocal'
import potencialVisualEvocadoPve from './potencialVisualEvocadoPve'
import pveVarredura from './pveVarredura'
import potencialAcuidadeMacular from './potencialAcuidadeMacular'


import dadosDaCirurgia from './dadosDaCirurgia'
import anestesia from './anestesia'
import produtosUtilizados from './produtosUtilizados'
import patologiaASerTratada from './patologiaASerTratada'
import anotacaoCirurgica from './anotacaoCirurgica'
import intercorrencias from './intercorrencias'

import hipoteseDiagnostica from './hipoteseDiagnostica'
import cid from './cid'

import solicitacaoDeExames from './solicitacaoDeExames'
import apaAvaliacaoreAnestesica from './apaAvaliacaoreAnestesica'
import solicitacaoDeCirurgia from './solicitacaoDeCirurgia'
import solicitacaoDeTratamento from './solicitacaoDeTratamento'
import encaminhamento from './encaminhamento'
import prescricaoMedicamentosaV2 from './prescricaoMedicamentosaV2'
import oculos from './oculos'
import oculosExtra from './oculosExtra'
import lentesDeContato from './lentesDeContato'
import orientacaoPosOperatoria from './orientacaoPosOperatoria'
import maisInformacoes from './maisInformacoes'
import retorno from './retorno'
import atestadosMedicos from './atestadosMedicos'
import laudoMedico from './laudoMedico'
import ceratometria from './ceratometria'
import tonometria from './tonometria'
import preAnamnese from './preAnamnese'
import informacoesAdicionaisExam from './informacoesAdicionaisExam'
import bvTriagem from './bvTriagem'
import bvTesteAcuidadeVisual from './bvTesteAcuidadeVisual'
import bvTesteIshihara from './bvTesteIshihara'
import campoConfrontacao from './campoConfrontacao'
import testeSensibilidadeMimicaFacial from './testeSensibilidadeMimicaFacial'
import imagensExamesEssilor from './imagensExamesEssilor'
import complementoGlaucoma from './complementoGlaucoma'

const formStore = {
  namespaced: true,
  state: () => ({
    selected: null,
    active: null,
    updated: false,
  }),
  mutations: {
    handleProps: (state, { key, value }) => {
      state[key] = value
    },
    setAttendanceSelectForm(state, payload) {
      state.selected = payload
    },
    setAttendanceSelectFormLabel(state, payload) {
      state.selected.label = payload
    },
    mapForms(state, payload) {
      Object.keys(state).map(key => {
        if (key.includes('exame-personalizado')) {
          removeAdditionalExamModule(key)
        }
      })

      if (!payload || !payload.forms) {
        Object.keys(state).map(key => {
          if (state[key] && typeof state[key] === 'object' && state[key].id) {
            state[key] = {
              ...state[key],
              showing: !['dadosDaCirurgia', 'anestesia', 'produtosUtilizados',
                'patologiaASerTratada', 'anotacaoCirurgica', 'intercorrencias'].includes(key),
              opened: true
            }
          }
        })
        return
      }

      const formsMap = payload.forms.reduce((acc, form) => {
        if (form.id.includes('exame-personalizado')) {
          addAdditionalExamsFromForms(form.id, form.customName ?? form.name, form.showing)
        }
        acc[form.id] = form
        return acc
      }, {})

      Object.keys(state).map(key => {
        if (state[key] && typeof state[key] === 'object' && formsMap[state[key].id]) {
          delete formsMap[state[key].id].fields
          delete formsMap[state[key].id].active
          delete formsMap[state[key].id].name
          state[key] = {
            ...state[key],
            ...formsMap[state[key].id]
          }
        }
      })
    },
    handleActiveModule(state, payload) {
      state.active = payload
    },
    updateForms (state, payload) {
      payload.forEach( form => {
        const index = state.selected.forms.findIndex( el => {
          return el.id === form.id
        })
        if  (index >= 0)
          state.selected.forms[index] = form
        else
          state.selected.forms.push(form)
      })
    }
  },
  getters: {
    additionalExams(state) {
      return Object.keys(state).reduce((acc, key) => {
        if (key.includes('exame-personalizado')) {
          acc.push(key)
        }
        return acc
      }, [])
    },
    getSelectedForm (state) {
      return state.selected
    }
  },
  actions: {
    async setAttendanceSelectFormLabel({commit, getters}, payload) {
      commit('setAttendanceSelectFormLabel', payload)
      const data = getters.getSelectedForm
      try {
        await Vue.prototype.api.updateForm(data.value, {
          name: data.label
        })
      } catch (err) {
        Vue.prototype.$toast.error(err.message)
      }
    },
    setAttendanceSelectForm(context, payload) {
      context.commit('setAttendanceSelectForm', payload || null)
      context.commit('mapForms', payload)
      context.dispatch('attendance/populateMedicalRecords', null, { root: true })
      context.dispatch('attendance/getTreatmentRequests', null, { root: true })
    },
    handleActiveModule(context, module) {
      context.commit('handleActiveModule', module)
    },
    updateMedicalRecord: async (context, form) => {
      if(!context.rootState.attendance.canEdit && !form.editingFinishedReport) return;
      try {
        const clinicId = context?.rootState?.attendance?.attendance?.clinic?.id
        const patientId = context?.rootState?.attendance?.attendance?.patient?.id
        const data = {
          action: 'medical-record',
          type: form.id,
          value: form.multiple ? form.value : form.fields,
          attendanceId: context?.rootState?.attendance?.attendance?.id,
        }
        if(clinicId && patientId && data){
          return await Vue.prototype.api.updateAttendance(clinicId, patientId, data)
        }
        return;
      } catch (err) {
        Vue.prototype.$toast.error(err.message)
      }
    },
    destroyMedicalRecord: async (context, form) => {
      if(!context.rootState.attendance.canEdit) return;

      try {
        const data = {
          type: form.id,
          attendanceId: context?.rootState?.attendance?.attendance?.id,
        }
        const response = await Vue.prototype.api.destroyMedicalRecord(data)
        return response
      } catch (err) {
        Vue.prototype.$toast.error(err.message)
      }
    },
    handleProps(context, { key, value }) {
      context.commit('handleProps', { key, value })
    },
    async updateForms ({commit, getters}, payload) {
      commit('updateForms', payload)
      const data = getters.getSelectedForm
      try {
        await Vue.prototype.api.updateForm(data.value, {
          forms: data.forms
        })
      } catch (err) {
        Vue.prototype.$toast.error(err.message)
      }
    },
    async defaultUpdateForm({commit, getters}, payload){
      
      const fieldValues = Object.values(payload.fields);
     const value =[{
        id: payload.id,
        name: payload.name,
        opened: payload.opened,
        showing: payload.showing,
        default_text: fieldValues[0],
      }]
      commit('updateForms', value)
      const data = getters.getSelectedForm
      try {
        await Vue.prototype.api.updateForm(data.value, {
          forms: data.forms
        })
      } catch (err) {
        Vue.prototype.$toast.error(err.message)
      }
    },
    updateAttendanceForms(context, payload) {
      context.commit('setAttendanceSelectForm', payload || null)
    },
  },
  modules: {
    anexarArquivos,

    preAnamnese,
    queixaPrincipal,
    historiaDaDoencaAtual,
    anamnese,
    antecedentesOftalmologicos,
    antecedentesPessoais,
    antecedentesFamiliares,
    cirurgiasOftalmologicasAnteriores,
    habitosDeVida,
    medicamentosOftalmologicosEmUso,
    outrosMedicamentosEmUso,
    alergias,
    informacoesAdicionaisAnamnese,

    bvTriagem,
    bvTesteAcuidadeVisual,
    bvTesteIshihara,

    exameFisico,
    ectoscopia,
    avscAcuidadeVisualSemCorrecao,
    avccAcuidadeVisualComCorrecao,
    avppscAcuidadeVisualParaPertoSemCorrecao,
    avppccAcuidadeVisualParaPertoComCorrecao,
    avbplAcuidadeVisualBinocularParaLonge,
    avbppAcuidadeVisualBinocularParaPerto,
    aberrometria,
    lensometria,
    tonometria,
    refracaoDinamica,
    refracaoEstatica,
    autorrefracaoDinamica,
    autorrefracaoEstatica,
    acuidadeVisualParaLongeAposRefracao,
    acuidadeVisualParaPertoAposRefracao,
    biomicroscopia,
    pressaoIntraocular,
    pressaoIntraocularCorrigida,
    curvaTensional,
    gonioscopia,
    esquiascopia,
    mapeamentoDeRetina,
    motilidadeOcularExtrinseca,
    coverTest,
    reflexos,
    informacoesAdicionais,
    campoConfrontacao,
    testeSensibilidadeMimicaFacial,
    informacoesAdicionaisExam,

    biometria,
    retinografia,
    paquimetria,
    campoVisual,
    octTomografiaDeCoerenciaOptica,
    octTomografiaDeCoerenciaOpticaNervoOptico,
    octTomografiaDeCoerenciaOpticaSegmentoAnterior,
    octATomografiaDeCoerenciaOptica,
    topografia,
    tomografiaDeCorneaPentacam,
    tomografiaDeCorneaGalilei,
    microscopiaEspecularDeCornea,
    indocianinografiaIcg,
    angiofluoresceinografiaAfg,
    usgOcular,
    eletrorretinogramaErgDeCampoTotal,
    ergMultifocal,
    potencialVisualEvocadoPve,
    pveVarredura,
    potencialAcuidadeMacular,
    ceratometria,

    dadosDaCirurgia,
    anestesia,
    produtosUtilizados,
    patologiaASerTratada,
    anotacaoCirurgica,
    intercorrencias,

    hipoteseDiagnostica,
    cid,
    solicitacaoDeExames,
    apaAvaliacaoreAnestesica,
    solicitacaoDeCirurgia,
    solicitacaoDeTratamento,
    encaminhamento,
    prescricaoMedicamentosaV2,
    oculos,
    oculosExtra,
    lentesDeContato,
    orientacaoPosOperatoria,
    maisInformacoes,
    retorno,
    atestadosMedicos,
    laudoMedico,
    imagensExamesEssilor,
    complementoGlaucoma
  },
}

export default formStore
