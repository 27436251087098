<template>
  <b-modal
      id="check-term-modal"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="header">
        <div class="header-content">
            <p>Alteração de {{!checkedTerm ? ' Termos de uso ' : '' }} <span v-if="!checkedTerm && !checkedPolicy"> e </span> {{!checkedPolicy ? ' Politica de privacidade ' : ''}} </p>
        </div>
      </div>
      <div class="body">
        <v-warning />
        <div>
          <p> Clicando em "Concordar e continuar" você concordara com as novas alterações. </p>
        </div>
        <div class="form-group">
            <label class="checkbox">
              <input
                type="checkbox"
                id="Term"
                />
                <span :class="`checkmark`">
                  <Check />
                </span>
                <span></span>
                  Aceitar <a class="underline" v-if="!checkedTerm" href="https://eyecarehealth.com.br/termos-e-politicas/termos-de-uso" target="_blank">termos de uso</a>
                  <span v-if="!checkedTerm && !checkedPolicy">e</span>  <a class="underline" v-if="!checkedPolicy" href="https://eyecarehealth.com.br/termos-e-politicas/politica-de-privacidade" target="_blank">políticas de privacidade</a>
              </label>
            </div>
      </div>

      <div class="actions">
        <b-button
          class="cancel-button"
          variant="outline-primary"
          @click="logout"
        >
          Sair do sistema
        </b-button>
        <b-button
          variant="primary"
          @click="accepTerm"
        >
          Concordar e continuar
        </b-button>
      </div>
  </b-modal>
</template>

<script>
import Warning from '@/assets/icons/warning.svg'
import Check from '@/assets/icons/check.svg'

export default {
  name: 'ConfirmProcedureDeletion',
  components: {
    'v-warning': Warning,
    Check,
  },
  props: {
    checkedTerm: Boolean,
    checkedPolicy: Boolean,
  },
  data() {
    return {
    }
  },

  methods: {
    logout() {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('user')
      localStorage.removeItem('clinic')
      localStorage.removeItem('permissions')
      localStorage.removeItem('session_cloud_token')
      window.location.href = '/login'
    },

    accepTerm() {
      const checkbox = document.getElementById('Term');

      if(!checkbox.checked){
        this.$toast.warning('Necessário aceitar termos e políticas de uso')
        return
      }

      this.api.acceptTerms()
      .then(res=> {
        this.$bvModal.hide('check-term-modal');
      })
      .catch(err => {
        console.log('Error check terms modal', err);
      })
    },
  }
}
</script>

<style lang="scss" >
  #check-term-modal {
    .modal-content {
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 675px;
      text-align: center;

      .modal-body {
        padding: 0;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);

        .header {
          border-bottom: 1px solid var(--neutral-200);

          .header-content {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin: 24px;

              .close-svg {
                  height: 24px;
                  width: 24px;
                  fill: var(--neutral-500);
              }
          }
        }
        .body {
          padding: 45px;
          padding-bottom: 0;
          color: var(--type-active);
          font-size: 18px;
          font-weight: 600;

          svg {
            aspect-ratio: 1;
            width: 96px;
          }

          > div {

            text-align: left;

            label {
              font-size: 16px;
              white-space: nowrap;
              cursor: pointer;

              &:last-child {
                margin-bottom: 0;
              }

              span {
                margin-left: 10px;
                color: var(--type-active);
                white-space: normal;
              }
            }
          }

          p {
            margin-top: 40px;
            margin-bottom: 25px;
            text-align: center;
          }

          span {
            color: var(--blue-500);
          }
        }

        .underline {
          text-decoration: underline;
        }
        .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 60px;
          padding-top: 26px;

          button {
            margin-left: 10px !important;
            font-size: 16px;
          }

          .cancel-button {
            font-weight: 700 !important;
            color: var(--blue-500) !important;
          }
        }
      }
    }
  }
</style>
