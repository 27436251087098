<template>
  <div class="orientations-container">
    <draggable-button v-if="isVisible && hasPermission()" class="orientations-button" :isClick.sync="btnIsClick" title="Exibir orientações">
      <div class="close-button" @click="hideOrientationsButton"><Close /></div>
      <div @click="openOrientationsModal" style="padding: 20px; border-radius: 50%"><Orientations id="orientations-icon" @click.stop="openOrientationsModal"/></div>
    </draggable-button>
    <draggable-modal :show.sync="showModal" />
  </div>
</template>

<script>
import { getPermissions } from "@/utils/localStorageManager";

export default {
  name: "OrientationsFloatingComponents",
  components: {
    Orientations: () => import('@/assets/icons/orientations.svg'),
    DraggableModal: () => import('@/components/Config/DraggableOrientationsModal.vue'),
    Close: () => import('@/assets/icons/close.svg'),
  },
  data() {
    return {
      isVisible: localStorage.getItem('show_orientations_button')  !== null ? JSON.parse(localStorage.getItem('show_orientations_button')) : false,
      btnIsClick: false,
      showModal: false,
    }
  },
  async mounted() {
    window.addEventListener('toggle-orientations-button', (event) => {
      this.isVisible = event.detail.storage;
    });
    console.log('hasPerm', this.hasPermission())
  },
  methods: {
    hideOrientationsButton() {
      this.isVisible = false;
      localStorage.setItem('show_orientations_button', false);
    },
    openOrientationsModal(){
      if (this.btnIsClick) {
        this.showModal = true
      }
    },
    hasPermission(){
      const permissions = getPermissions();
      // Permissão de visualizar e editar orientações
      const elementsToFind = ['OrConv1', 'OrConv2', 'OrCli1', 'OrCli2', 'OrPro1', 'OrPro2'];
      return permissions.some(permission => elementsToFind.includes(permission));
    }
  },
  watch: {
    showModal(val) {
      this.isVisible = !val;
    }
  },
}
</script>

<style scoped>
.orientations-button {
  bottom: 20px;
  right: 90px;
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 1px 1px 6px 0 black;
}

.orientations-container ::v-deep .backIcon {
  left: unset;
  top: unset;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9997;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px;
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid lightgray;
  border-radius: 50%;
  font-size: x-small;
  width: 30%;
  text-align: center;
  box-shadow: inset 0 0 2px 0
}

#orientations-icon {
  fill: white;
  transform: scale(1.3);
}
</style>