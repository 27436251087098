const setLoading = (state, payload) => {
  state.loading = payload
}

const setPendingItems = (state, payload) => {
  state.pendingItems = payload.data
  state.count = payload.total
  state.limit = payload.per_page
}

const setCheckedItems = (state, payload) => {
  state.checkedItems = payload
}

const changePage = (state, payload) => {
  state.page = payload
}

export default {
  setLoading,
  setPendingItems,
  setCheckedItems,
  changePage,
}
