import { base } from '@/utils/api'

const ENDPOINT = '/schedule-config'

export default {
    getClinicScheduleConfig(clinicId) {
        return base.get(`clinics/${clinicId}/schedule/config/days`)
    },
    createScheduleException(data) {
        return base.post(`${ENDPOINT}/exceptions`, data)
    },
    updateScheduleException(id, data) {
        return base.put(`${ENDPOINT}/exceptions/${id}`, data)
    },
    deleteScheduleException(id) {
        return base.delete(`${ENDPOINT}/exceptions/${id}`)
    },
}