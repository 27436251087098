<template>
  <div class="pt-container">
    <div v-if="!modal">
      <v-tab-info-header
        :title="
          doctorOrDoctorManager
            ? 'Todos os protocolos'
            : 'Protocolos compartilhados'
        "
        :tooltip="
          doctorOrDoctorManager
            ? 'Visualize todos os protocolos criados pelos profissionais da sua clínica/instituto. <br> Você pode clicar no ícone à esquerda de cada protocolo para torná-los <strong>protocolos padrões</strong>.'
            : 'Visualize os protocolos criados pelos profissionais da sua clínica/instituto.'
        "
        :print="true"
      />

      <v-protocol-filter
        typeChangeProtocol="Professional"
        :professionals="professionals"
        @changeFilter="changeFilter"
      />
    </div>

    <v-protocol-table
      v-for="protocol in protocols"
      :key="protocol.id"
      :protocol="protocol"
      :modal="modal"
      @selected-protocols="selectProtocol"
      @unselected-protocols="unselectProtocol"
      :selectProtocols="selectProtocols"
      @update-protocols="getProtocols"
    />

    <v-no-filter-results
      v-if="protocols && protocols.length == 0"
      :message="'Os filtros aplicados não geraram nenhum resultado.'"
    />

    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        first-number
        last-number
      />
    </div>
  </div>
</template>

<script>
import TabHeaderInfo from '@/components/General/TabHeaderInfo.vue'
import ProtocolFilters from '@/components/Protocols/ProtocolFilters.vue'
import ProtocolTable from '@/components/Protocols/ProtocolTable.vue'
import NoFiltersResults from '@/components/General/noFilterResults'
import { EventBus } from '@/utils/eventBus'

export default {
  name: 'my-protocols-tab',
  async mounted() {
    this.role = this.person.clinics[0].role
    await this.getProtocols()
    this.getProfessionals()
    EventBus.$on('reloadProtocols', async () => await this.getProtocols())
  },
  data() {
    const clinic = JSON.parse(localStorage.getItem('clinic'))
    return {
      query: null,
      professional: null,
      via: null,
      clinic,
      protocols: null,
      professionals: null,
      currentPage: null,
      totalItems: null,
      perPage: 3,
      person: JSON.parse(localStorage.getItem('user')),
      role: null
    }
  },
  props: {
    modal: Boolean,
    selectProtocols: Array,
    queryName: String,
    updateProtocol: Object,
    doctorOrDoctorManager: Boolean
  },
  components: {
    'v-tab-info-header': TabHeaderInfo,
    'v-protocol-filter': ProtocolFilters,
    'v-protocol-table': ProtocolTable,
    'v-no-filter-results': NoFiltersResults
  },
  methods: {
    async getProtocols() {
      const isLoading = this.$loading.show()
      const param = this.doctorOrDoctorManager ? 'all_protocols' : 'shared'
      try {
        const response = await this.api.getProtocols(
          param,
          this.clinic.id,
          this.query,
          null,
          this.via,
          this.professional,
          null,
          this.currentPage,
          this.person.id
        )
        this.currentPage = response.data.current_page
        this.perPage = response.data.per_page
        this.totalItems = response.data.total
        this.protocols = response.data.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    getProfessionals() {
      const professionals = this.protocols?.map(protocol => {
        return {
          label: protocol.person.name,
          value: protocol.person.name
        }
      })

      this.professionals = professionals.filter(function (a) {
        return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
      }, Object.create(null))
    },
    async changeFilter(filters) {
      this.query = filters.query
      this.via = filters.via
      this.professional = filters.professional
      await this.getProtocols()
    },
    selectProtocol(value) {
      this.$emit('select-protocols', value)
    },
    unselectProtocol(value) {
      this.$emit('unselect-protocols', value)
    }
  },
  watch: {
    async currentPage() {
      await this.getProtocols()
    },
    async queryName() {
      this.query = this.queryName
      await this.getProtocols()
    },
    async updateProtocol(value) {
      if (value) {
        await this.getProtocols()
        this.$emit('clear-update', null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pt-container {
  padding: 30px 10px;

  .pagination-position {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
