import { hasLenteGelatinosa, hasLenteRigida } from '@/utils/documentHelper'
import { updateAttendanceForm } from '@/utils/attendanceFormHelper'

const store = {
  namespaced: true,
  state: () => ({
    id: 'lentes-de-contato',
    name: 'Adaptação de lentes de contato',
    opened: true,
    showing: true,
    completed: false,
    multiple: false,
    scopes: ['ATTENDANCE', 'SURGERY', 'ESSILOR_ATTENDANCE'],
    fields: {
      olhoDireitoEsferico: null,
      olhoDireitoCilindrico: null,
      olhoDireitoEixo: null,
      olhoDireitoDP: null,
      olhoDireitoAdicao: null,
      olhoDireitoAV: null,
      olhoEsquerdoEsferico: null,
      olhoEsquerdoCilindrico: null,
      olhoEsquerdoEixo: null,
      olhoEsquerdoDP: null,
      olhoEsquerdoAdicao: null,
      olhoEsquerdoAV: null,
      orientacao: null,
      usarValoresRefracao: null,
      marcaDaLente: null,
      olhoDireitoModelo: null,
      olhoDireitoCurva: null,
      olhoDireitoDiametro: null,
      olhoDireitoGrau: null,
      olhoDireitoFabricante: null,
      olhoDireitoAVRigida: null,
      olhoEsquerdoModelo: null,
      olhoEsquerdoCurva: null,
      olhoEsquerdoDiametro: null,
      olhoEsquerdoGrau: null,
      olhoEsquerdoFabricante: null,
      olhoEsquerdoAVRigida: null,
      orientacaoRigida: null,
      usarGelatinosa: false,
      usarRigida: false,
    }
  }),
  mutations: {
    handleProps: (state, { key, value }) => {
      state[key] = value
    },
    handleFields: (state, { key, value }) => {
      state.fields[key] = value
    },
    checkComplete: state => {
      state.completed = hasLenteGelatinosa(state.fields) || hasLenteRigida(state.fields)
    }
  },
  getters: {},
  actions: {
    handleProps(context, { key, value }) {
      context.commit('handleProps', { key, value })

      if(context?.rootState?.attendance?.attendance?.id && ['showing' , 'opened'].includes(key)){
        const form = context?.rootGetters['attendance/form/getSelectedForm']
        const forms = form.forms
        const index = forms?.findIndex(form => {
          return form.id === context.state.id
        })
  
        if(index && index !== -1){
          forms[index][key] = value
          form.forms = forms
          updateAttendanceForm(context?.rootState?.attendance?.attendance?.attendance_form?.id, {forms: forms})
          context.dispatch(`attendance/form/updateAttendanceForms`, form, { root: true })
        }
      }
    },
    handleFields(context, { key, value }) {
      context.commit('handleFields', { key, value })
      context.commit('checkComplete')
    },
    changeFieldsByRefraction(context, { suffix, checked, fields }) {
      const newFields = context.state.fields
      Object.keys(fields).map(key => {
        const localKey = key.replace(suffix, '')
        if (newFields[localKey] !== undefined) {
          newFields[localKey] = checked ? fields[key] : null
        }
      })
      newFields.usarValoresRefracao = checked ? suffix : null
      context.commit('handleProps', { key: 'fields', value: newFields })
      context.dispatch('attendance/form/updateMedicalRecord', context.state, { root: true })
    }
  },
}

export default store
