<template>
    <div>
        <div class="row my-3">
            <div class="col text-center">
                <img :src="featureRequestImage" class="online-doctor center-block" height="250px">
            </div>
        </div>

        <div  v-if="(statusRequestFatSus !== 'em aberto' && statusRequestFatSus !== 'finalizado')">
          <div class="row my-3">
              <div class="col">
                  <p class="text-center">
                      Clique abaixo em "Contratar" para ativar o Faturamento SUS
                  </p>
              </div>
          </div>
          <div class="row">
              <div class="col text-center">
                  <b-button variant="primary" @click="submitCommercialFeatureRequest">Contratar</b-button>
              </div>
          </div>
        </div>

        <div  v-if="(statusRequestFatSus === 'em aberto')" class="row my-3">
            <div class="col-12 text-center">
              <label>Nosso time comercial entrará em contato em breve para fornecer mais informações!</label>
            </div>
            <div class="col-12 text-center">
                <label class="label label-secondary mb-3">Pedido Solicitado</label>
            </div>
            <div class="col-12 text-center">
              <b-link href="#" class="link-secondary" @click="isModelCancel = true">Cancelar solicitação</b-link>
            </div>
        </div>

        <!-- Modal Agradecimento SUS -->
        <b-modal 
        v-model="isModelConfirmation" 
        hide-footer 
        centered
        content-class="custom-fade"
        title="Contratar">
          <div class="row my-3">
            <div class="col">
              <p class="text-center">
                <strong>Obrigado por escolher o Faturamento SUS!</strong>
              </p>
            </div>
          </div>
          <div class="row my-3">
            <div class="col">
              <p class="text-center">
              Nosso time comercial entrará em contato em breve para fornecer mais informações e dar sequência no processo de aquisição dessa funcionalidade.
              </p>
            </div>
          </div>
        </b-modal>

        <!-- Modal Cancelamento de Solicitação Fat. SUS -->
        <b-modal 
        v-model="isModelCancel" 
        hide-footer 
        centered
        content-class="custom-fade"
        title="Cancelar">
          <div class="row my-3">
            <div class="col">
              <p class="text-center">
                Deseja realmente cancelar a solicitação referente ao Faturamento SUS?
              </p>
            </div>
          </div>
          <div class="row my-3">
            <div class="col text-right">
              <b-button class="mr-3" @click="isModelCancel = false">Voltar</b-button>
              <b-button class="btn btn-primary" @click="submitCancelCommercialFeatureRequest">Confirmar</b-button>
            </div>
          </div>
        </b-modal>
        
    </div>
</template>

<script>
import { getCurrentClinic, getCurrentUser} from '@/utils/localStorageManager'
import featureRequestImage from '@/assets/images/online-doctor.png';

export default {
  name: 'FeatureRequestFatSus',
  components: {
  },
  created() {
    this.getRequestFaturamentoSus();
  },
  props: {
    clinicHealthPlan: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      isModelConfirmation: false,
      isModelCancel: false,
      statusRequestFatSus: null,
      featureRequestImage
    }
  },
  methods: {
    async getRequestFaturamentoSus() {
      const data = {
        'clinic_id': this.clinic.id,
        'feature': 'faturamento-sus'
      };

      await this.api.getFeatureRequestContactStatus(data)
      .then(res => {
        this.idRequestFatSus = res.data.id;
        this.statusRequestFatSus = res.data.status;
      })
      .catch(err => this.$toast.error(err.message));      
    },
    async submitCommercialFeatureRequest() {
      const data = {
        'clinic_id': this.clinic.id,
        'person_id': this.user.id,
        'feature': 'faturamento-sus'
      };

      await this.api.storeFeatureRequestContact(data)
      .then(res => {
        this.isModelConfirmation = true;
        this.idRequestFatSus = res.data.id;
        this.statusRequestFatSus = res.data.status;
      })
      .catch(err => this.$toast.error(err.message));      
    },
    async submitCancelCommercialFeatureRequest() {
      const data = {
        'clinic_id': this.clinic.id,
        'person_id': this.user.id,
        'feature': 'faturamento-sus'
      };

      await this.api.updateFeatureRequestContact(this.idRequestFatSus, data)
      .then(res => {
        this.isModelCancel = false;
        this.idRequestFatSus = res.data.id;
        this.statusRequestFatSus = res.data.status;
        this.$toast.success('Solicitação cancelada com sucesso')
      })
      .catch(err => this.$toast.error(err.message));      
    },
  },
}
</script>

<style lang="scss" scoped>
    .center-block{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .label-secondary{
      border-radius: 8px;
      background: var(--surface-tertiary, #E7ECFE);
      color: var(--content-brand, #305BF2);
      padding: 12px;
    }

    .link-secondary{
      color: #8696AC;
    }
</style>
