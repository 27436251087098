<template>
  <div class="video">
    <iframe
      :src="videoClass?.path"
      frameborder="0"
      width="100%"
      height="550"
      allow="autoplay; encrypted-media"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
    ></iframe>
  </div>
</template>
<script>
import { getCurrentUser } from '@/utils/localStorageManager'
import Vimeo from '@vimeo/player'

export default {
  props: {
    videoClass: Object,
  },
  data() {
    return {
      user: getCurrentUser(),
      videoClassProgress: null,
      inProgress: false,
      player: null,
      counter: null,
      showMsg: true
    }
  },
  methods: {
    setTrackProgress() {
      if (this.counter) return
      this.counter = setInterval(async () => {
        if (this.inProgress) {
          const currentTime = await this.player.getCurrentTime()
          const duration = await this.player.getDuration()
          await this.updateProgress(currentTime, duration)
        }
      }, 15000)
    },
    async onPlay() {
      this.inProgress = true
      this.handleShowMsg()

    },
    handleShowMsg() {
      if (this.showMsg) {
        this.showMsg = false
        this.$toast.info('Todos os direitos de propriedade intelectual reservados')
      }
    },
    async onPause() {
      this.inProgress = false
      const currentTime = await this.player.getCurrentTime()
      const duration = await this.player.getDuration()
      await this.updateProgress(currentTime, duration)
    },
    getSavedTime() {
      return this.videoClassProgress && this.videoClassProgress.time_progress ? this.videoClassProgress.time_progress : 0
    },
    async updateProgress(currentTime, duration) {
      const data = {
        'video_class_id': this.videoClass.id,
        'person_id': this.user.id,
        'time_progress': currentTime,
        'status': ((currentTime * 100) / duration <= 95) ?  'in_progress' : 'finished'
      }
      await this.api.createOrUpdateVideoClassProgress(data)
    },
  },
  watch: {
    async videoClass() {
      if (!this.videoClass) return
      const res = await this.api.getVideoClassProgress(this.videoClass.id, this.user.id)
      this.videoClassProgress = res.data
      setTimeout(() => {
        const iframe = document.querySelector('iframe');
        this.player = new Vimeo(iframe);
        this.player.setCurrentTime(this.getSavedTime());
        this.player.on('play', this.onPlay, this);
        this.player.on('pause', this.onPause, this);
        this.setTrackProgress(this)
      }, 2000)
    }
  }
}
</script>
<style lang="scss" scoped>
  .video {
    padding: 16px 0;
    margin: 0 auto;

    iframe {
      .player {
        .vp-video-wrapper {
          border-radius: 8px;
        }
      }
    }
  }
</style>
