const moduleRoutes = [
    {
        path: '/tables',
        component: () => import('@/views/Home'),
        children: [
            { 
                path: 'd/:id', 
                component: () => import('./layouts/TableDetails'),
            },
            { 
                path: ':tableId/matmed/:id', 
                component: () => import('./layouts/LayoutMatMedDetails'),
            },
        ]
    }
]

export default router => { 
    router.addRoutes(moduleRoutes)
}