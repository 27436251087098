<template>
  <div class="comment-module">
    <v-comment
      :user="user"
      :videoComment="videoComment"
      :removeFromComments="removeFromComments"
    />
    <v-comment-input
      v-if="!videoComment.video_class_comments.length"
      :user="user"
      placeholder="Responder comentário"
      :onComment="comment => onComment(comment, videoComment.id)"
    />
    <div v-else class="comments">
      <v-comment
        v-for="childVideoComment in videoComment.video_class_comments"
        :key="childVideoComment.id"
        :user="user"
        :videoComment="childVideoComment"
        :removeFromComments="removeFromComments"
      />
      <v-comment-input
        :user="user"
        placeholder="Responder comentários"
        :onComment="comment => onComment(comment, videoComment.id)"
      />
    </div>
  </div>
</template>
<script>
import Comment from '@/components/Academy/Comment'
import CommentInput from '@/components/Academy/CommentInput'

export default {
  props: {
    user: Object,
    videoComment: Object,
    onComment: Function,
    removeFromComments: Function,
  },
  components: {
    'v-comment': Comment,
    'v-comment-input': CommentInput
  },
}
</script>
<style lang="scss" scoped>
  .comment-module {
    padding: 16px 0;
    .comments {
      padding: 0 16px 0 58px;
    }
  }
</style>
