<!--- View do formulário da pré consulta - Responsável pelo formulário utilizado na pré-consulta.

Alterado pelos desenvolvedores:
Richard Moraes
Carlos Oliveira
--->

<template>
  <div class="preconsultation-data">
    <div class="breadcrumbs">
      <router-link to="/pre-consulta" tag="span" class="link">
        Lista de Pacientes
      </router-link>
      <ChevronRight class="chevron" />
      <span v-if="data">{{ data.patient.name }}</span>
    </div>
    <div class="content">
      <div class="resume">
        <header v-if="data" class="patient_header">
          <Avatar 
            :src="`${data.patient.picture}?x=${moment()}`"
            size="45"
          />
          <div>
            <h5 v-if="data">{{ data.patient.name }}</h5>
            <p v-if="data">
              Nº do prontuário: <b>{{ data.patient.medical_record_number }}</b>
            </p>
            <p v-if="data">
              Data de nascimento:
              <b>
                {{
                  data.patient.birthday && moment(data.patient.birthday).isValid() ? moment(data.patient.birthday).format('DD/MM/YYYY') : '-'
                }}
              </b>
            </p>
          </div>
        </header>
        <ul class="resume_list">
          <li>
            <Avatar 
              size="40"
              class="mr-3"
            />
            <p v-if="data">{{ getUser() }}</p>
          </li>
          <li>
            <Calendar class="icon" />
            <p v-if="data">
              {{ dayjs(data.start_datetime).format('HH[h]mm [-] DD/MM/YYYY') }}
            </p>
          </li>
        </ul>
        <div
          class="contentSidebar collapsible"
          v-for="(group, index) in formGroups"
          :key="index"
          :class="{ opened: group.opened }"
        >
          <div class="header">
            <p class="title">{{ group.name }} ({{ group.forms.length }})</p>
            <ChevronDown @click="group.opened = !group.opened" />
          </div>
          <ul>
            <li
              v-for="(form, index) in group.forms"
              :key="index"
              :class="{ plus: !form.showing, active: form.active }"
            >
              <p style="max-width: 85%">{{ form.name }}</p>

              <Check
                v-if="form.showing"
                :class="{ confirmed: form.completed() }"
              />
              <Plus v-if="!form.showing" @click="showForm(form)" style="max-width: 25%"/>
            </li>
            <li
              v-if="group.name === 'Exames complementares'"
              @click="addCustom()"
            >
              <span class="button"> Adicionar exame complementar </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="form">
        <div class="title">
          Pré-consulta
          <button v-if="completed" @click="editMode" class="btn-edit">
            Editar
          </button>
        </div>

        <div v-if="this.formGroups[0].forms[0].showing">
          <div class="subtitle">Pré-anamnese
            <div class="d-flex flex-row" v-if="this.formGroups[0].forms[0].opened">
              <p class="remove" @click="closeForm(formGroups[0].forms[0])">remover</p>
              <ChevronUp class="chevron" @click="formGroups[0].forms[0].opened = !formGroups[0].forms[0].opened" />
            </div>
            <div v-else>
              <ChevronDown class="chevron" @click="formGroups[0].forms[0].opened = !formGroups[0].forms[0].opened" />
            </div>
          </div>
            <div class="anamnese-form" v-if="formGroups[0].forms[0].opened">
              <textarea
                v-model="formGroups[0].forms[0].fields.pre_anamnese"
                :readonly="completed"
                class="form-control"
                rows="5"
              />
            </div>
        </div>

        <div v-if="this.formGroups[0].forms[1].showing">
          <div class="subtitle">AVSC - Acuidade visual sem correção
            <div class="d-flex flex-row" v-if="formGroups[0].forms[1].opened == true">
              <p class="remove" @click="closeForm(formGroups[0].forms[1])">remover</p>
              <ChevronUp class="chevron" @click="formGroups[0].forms[1].opened = !formGroups[0].forms[1].opened" />
            </div>
            <div v-else>
              <ChevronDown class="chevron" @click="formGroups[0].forms[1].opened = !formGroups[0].forms[1].opened" />
            </div>
          </div>
          <div class="avsc-form" v-if="formGroups[0].forms[1].opened">
            <div>
              <p>Olho direito</p>
              <b-input-group>
                <b-input-group-prepend is-text >
                  <Eye class="form-icon" />
                  D
                </b-input-group-prepend>
                <b-form-input
                  id="avsc-direito"
                  autocomplete="off"
                  v-model="formGroups[0].forms[1].fields.avsc_right_eye"
                  :readonly="completed"
                  aria-label="Olho direito"
                  @click="setActiveForm('avscDireito')"
                ></b-form-input>
                <div class="input-options margin-top">
                  <MultipleEyesOptions :form="formGroups[0].forms[1]" :options="options" inputName="avsc_right_eye" activeInput="avscDireito" :setActiveInput="this.setActiveForm" v-if="activeForm==='avscDireito' && !completed"/>
                </div>
              </b-input-group>
            </div>
            <div>
              <p>Olho esquerdo</p>
              <b-input-group>
                <b-input-group-prepend is-text
                style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;">
                  <Eye class="form-icon" />
                  E
                </b-input-group-prepend>
                <b-form-input
                  id="avsc-esquerdo"
                  autocomplete="off"
                  v-model="formGroups[0].forms[1].fields.avsc_left_eye"
                  :readonly="completed"
                  aria-label="Olho esquerdo"
                  @click="setActiveForm('avscEsquerdo')"
                ></b-form-input>
                <div class="input-options margin-top">
                  <MultipleEyesOptions :form="formGroups[0].forms[1]" :options="options" inputName="avsc_left_eye" activeInput="avscEsquerdo" :setActiveInput="this.setActiveForm" v-if="activeForm==='avscEsquerdo' && !completed"/>
                </div>
              </b-input-group>
            </div>
          </div>
        </div>

        <div v-if="this.formGroups[0].forms[2].showing">
          <div class="subtitle">AVCC - Acuidade visual com correção
            <div class="d-flex flex-row" v-if="formGroups[0].forms[2].opened">
              <p class="remove" @click="closeForm(formGroups[0].forms[2])">remover</p>
              <ChevronUp class="chevron" @click="formGroups[0].forms[2].opened = !formGroups[0].forms[2].opened" />
            </div>
            <div v-else>
              <ChevronDown class="chevron" @click="formGroups[0].forms[2].opened = !formGroups[0].forms[2].opened" />
            </div>
          </div>
          <div class="avcc-form" v-if="formGroups[0].forms[2].opened">
            <div>
              <p>Olho direito</p>
              <b-input-group>
                <b-input-group-prepend is-text
                style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;">
                  <Eye class="form-icon" />
                  D
                </b-input-group-prepend>
                <b-form-input
                  id="avcc-direito"
                  autocomplete="off"
                  v-model="formGroups[0].forms[2].fields.avcc_right_eye"
                  :readonly="completed"
                  aria-label="Olho direito"
                  @click="setActiveForm('avccDireito')"
                ></b-form-input>
                <div class="input-options margin-top">
                  <MultipleEyesOptions :form="formGroups[0].forms[2]" :options="options" inputName="avcc_right_eye" activeInput="avccDireito" :setActiveInput="this.setActiveForm" v-if="activeForm==='avccDireito' && !completed"/>
                </div>
              </b-input-group>
            </div>
            <div>
              <p>Olho esquerdo</p>
              <b-input-group>
                <b-input-group-prepend is-text
                style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;">
                  <Eye class="form-icon" />
                  E
                </b-input-group-prepend>
                <b-form-input
                  id="avcc-esquerdo"
                  autocomplete="off"
                  v-model="formGroups[0].forms[2].fields.avcc_left_eye"
                  :readonly="completed"
                  aria-label="Olho esquerdo"
                  @click="setActiveForm('avccEsquerdo')"
                ></b-form-input>
                <div class="input-options margin-top">
                  <MultipleEyesOptions :form="formGroups[0].forms[2]" :options="options" inputName="avcc_left_eye" activeInput="avccEsquerdo" :setActiveInput="this.setActiveForm" v-if="activeForm==='avccEsquerdo' && !completed"/>
                </div>
              </b-input-group>
            </div>
          </div>
        </div>

        <div v-if="formGroups[0].forms[3].showing">
          <div class="subtitle">Autorrefração dinâmica
            <div class="d-flex flex-row" v-if="formGroups[0].forms[3].opened">
              <p class="remove" @click="closeForm(formGroups[0].forms[3])">remover</p>
              <ChevronUp class="chevron" @click="formGroups[0].forms[3].opened = !formGroups[0].forms[3].opened" />
            </div>
            <div v-else>
              <ChevronDown class="chevron" @click="formGroups[0].forms[3].opened = !formGroups[0].forms[3].opened" />
            </div>
          </div>
          <div class="ar-labels" v-if="formGroups[0].forms[3].opened">
            <div>Esférico</div>
            <div>Cilíndrico</div>
            <div>Eixo</div>
            <div>Adição</div>
          </div>
          <b-input-group class="form-ar-prepend-one" v-if="formGroups[0].forms[3].opened">
            <b-input-group-prepend is-text >
              <Eye class="form-icon" />
              D
            </b-input-group-prepend>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control regular" :readonly="completed" @click="openOptions('esfericoDireitoDin')"
                v-model.lazy="formGroups[0].forms[3].fields.auto_refraction_spherical_r" aria-label="Esférico direito" />
              <div class="input-options" v-if="activeEyeBox==='esfericoDireitoDin' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[3]" activeInput="esfericoDireitoDin"
                  :options="formGroups[0].forms[3].fields.auto_refraction_spherical_r !== null ? (parseFloat(formGroups[0].forms[3].fields.auto_refraction_spherical_r.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsPositive"
                  inputName="auto_refraction_spherical_r" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'auto_refraction_spherical_r', null, 3);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'auto_refraction_spherical_r', null, 3)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control regular" :readonly="completed" @click="openOptions('cilindricoDireitoDin')"
                v-model.lazy="formGroups[0].forms[3].fields.auto_refraction_cylindrical_r" aria-label="Cilíndrico direito" />
              <div class="input-options" v-if="activeEyeBox==='cilindricoDireitoDin' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[3]" activeInput="cilindricoDireitoDin"
                  :options="optionsNegative"
                  inputName="auto_refraction_cylindrical_r" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'auto_refraction_cylindrical_r', null, 3);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'auto_refraction_cylindrical_r', null, 3)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control regular" :readonly="completed" @click="openOptions('EixoDireitoDin')"
                v-model.lazy="formGroups[0].forms[3].fields.auto_refraction_axis_r" aria-label="Eixo direito" />
              <div class="input-options" v-if="activeEyeBox==='EixoDireitoDin' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[3]" activeInput="EixoDireitoDin"
                  :options="optionsEixo" inputName="auto_refraction_axis_r" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 1, 'auto_refraction_axis_r', 'eixo', 3);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 1, 'auto_refraction_axis_r', 'eixo', 3)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control top-border" :readonly="completed" @click="openOptions('AdicaoDireitoDin')"
                v-model.lazy="formGroups[0].forms[3].fields.auto_refraction_addition_r" aria-label="Adição direito" />
              <div class="input-options" v-if="activeEyeBox==='AdicaoDireitoDin' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[3]" activeInput="AdicaoDireitoDin"
                  :options="optionsPositive" inputName="auto_refraction_addition_r" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'auto_refraction_addition_r', null, 3);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'auto_refraction_addition_r', null, 3)"><p>-</p></button>
                  </div>
              </div>
            </div>

          </b-input-group>
          <b-input-group class="form-ar-prepend-two" v-if="formGroups[0].forms[3].opened">
            <b-input-group-prepend is-text >
              <Eye class="form-icon" />
              E
            </b-input-group-prepend>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control regular" :readonly="completed" @click="openOptions('EsfericoEsquerdoDin')"
                v-model.lazy="formGroups[0].forms[3].fields.auto_refraction_spherical_l" aria-label="Esférico esquerdo" />
              <div class="input-options" v-if="activeEyeBox==='EsfericoEsquerdoDin' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[3]" activeInput="EsfericoEsquerdoDin"
                  :options="formGroups[0].forms[3].fields.auto_refraction_spherical_l !== null ? (parseFloat(formGroups[0].forms[3].fields.auto_refraction_spherical_l.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsPositive"
                  inputName="auto_refraction_spherical_l" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'auto_refraction_spherical_l', null, 3);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'auto_refraction_spherical_l', null, 3)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control regular" :readonly="completed" @click="openOptions('CilindricoEsquerdoDin')"
                v-model.lazy="formGroups[0].forms[3].fields.auto_refraction_cylindrical_l" aria-label="Cilíndrico esquerdo" />
              <div class="input-options" v-if="activeEyeBox==='CilindricoEsquerdoDin' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[3]" activeInput="CilindricoEsquerdoDin"
                  :options="optionsNegative"
                  inputName="auto_refraction_cylindrical_l" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'auto_refraction_cylindrical_l', null, 3);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'auto_refraction_cylindrical_l', null, 3)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control regular" :readonly="completed" @click="openOptions('EixoEsquerdoDin')"
                v-model.lazy="formGroups[0].forms[3].fields.auto_refraction_axis_l" aria-label="Eixo esquerdo" />
              <div class="input-options" v-if="activeEyeBox==='EixoEsquerdoDin' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[3]" activeInput="EixoEsquerdoDin"
                  :options="optionsEixo" inputName="auto_refraction_axis_l" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 1, 'auto_refraction_axis_l', 'eixo', 3);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 1, 'auto_refraction_axis_l', 'eixo', 3)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control bottom-border" :readonly="completed" @click="openOptions('AdicaoEsquerdoDin')"
                v-model.lazy="formGroups[0].forms[3].fields.auto_refraction_addition_l" aria-label="Adição esquerdo" />
              <div class="input-options" v-if="activeEyeBox==='AdicaoEsquerdoDin' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[3]" activeInput="AdicaoEsquerdoDin"
                  :options="optionsPositive" inputName="auto_refraction_addition_l" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'auto_refraction_addition_l', null, 3);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'auto_refraction_addition_l', null, 3)"><p>-</p></button>
                  </div>
              </div>
            </div>

          </b-input-group>
        </div>


        <div v-if="formGroups[0].forms[4].showing">
          <div class="subtitle">Autorrefração estática
            <div class="d-flex flex-row" v-if="formGroups[0].forms[4].opened">
              <p class="remove" @click="closeForm(formGroups[0].forms[4])">remover</p>
              <ChevronUp class="chevron" @click="formGroups[0].forms[4].opened = !formGroups[0].forms[4].opened" />
            </div>
            <div v-else>
              <ChevronDown class="chevron" @click="formGroups[0].forms[4].opened = !formGroups[0].forms[4].opened" />
            </div>
          </div>
          <div class="ar-labels" v-if="formGroups[0].forms[4].opened">
            <div>Esférico</div>
            <div>Cilíndrico</div>
            <div>Eixo</div>
            <div>Adição</div>
          </div>
          <b-input-group class="form-ar-prepend-one" v-if="formGroups[0].forms[4].opened">
            <b-input-group-prepend is-text
            style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;">
              <Eye class="form-icon" />
              D
            </b-input-group-prepend>

            <div class="eye-box-container">
              <input type="text" class="form-control regular" :readonly="completed" @click="openOptions('EsfericoDireitoEst')"
                v-model.lazy="formGroups[0].forms[4].fields.auto_refraction_static_spherical_r" aria-label="Esférico direito" />
              <div class="input-options" v-if="activeEyeBox==='EsfericoDireitoEst' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[4]" activeInput="EsfericoDireitoEst"
                  :options="formGroups[0].forms[4].fields.auto_refraction_static_spherical_r !== null ? (parseFloat(formGroups[0].forms[4].fields.auto_refraction_static_spherical_r.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsPositive"
                  inputName="auto_refraction_static_spherical_r" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'auto_refraction_static_spherical_r', null, 4);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'auto_refraction_static_spherical_r', null, 4)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input type="text" class="form-control regular" :readonly="completed" @click="openOptions('CilindricoDireitoEst')"
                v-model.lazy="formGroups[0].forms[4].fields.auto_refraction_static_cylindrical_r" aria-label="Cilíndrico direito" />
              <div class="input-options" v-if="activeEyeBox==='CilindricoDireitoEst' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[4]" activeInput="CilindricoDireitoEst"
                  :options="optionsNegative"
                  inputName="auto_refraction_static_cylindrical_r" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'auto_refraction_static_cylindrical_r', null, 4);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'auto_refraction_static_cylindrical_r', null, 4)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input type="text" class="form-control regular" :readonly="completed" @click="openOptions('EixoDireitoEst')"
                v-model.lazy="formGroups[0].forms[4].fields.auto_refraction_static_axis_r" aria-label="Eixo direito" />
              <div class="input-options" v-if="activeEyeBox==='EixoDireitoEst' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[4]" activeInput="EixoDireitoEst"
                  :options="optionsEixo" inputName="auto_refraction_static_axis_r" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 1, 'auto_refraction_static_axis_r', 'eixo', 4);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 1, 'auto_refraction_static_axis_r', 'eixo', 4)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input type="text" class="form-control top-border" :readonly="completed" @click="openOptions('AdicaoDireitoEst')"
                v-model.lazy="formGroups[0].forms[4].fields.auto_refraction_static_addition_r" aria-label="Adição direito" />
              <div class="input-options" v-if="activeEyeBox==='AdicaoDireitoEst' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[4]" activeInput="AdicaoDireitoEst"
                  :options="optionsPositive" inputName="auto_refraction_static_addition_r" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                <div>
                  <button @click="changeInputValue('increase', 0.25, 'auto_refraction_static_addition_r', null, 4);"><p>+</p></button>
                </div>
                <div>
                  <button @click="changeInputValue('decrease', 0.25, 'auto_refraction_static_addition_r', null, 4)"><p>-</p></button>
                </div>
              </div>
            </div>

          </b-input-group>
          <b-input-group class="form-ar-prepend-two" v-if="formGroups[0].forms[4].opened">
            <b-input-group-prepend is-text>
              <Eye class="form-icon" />
              E
            </b-input-group-prepend>

            <div class="eye-box-container">
              <input type="text" class="form-control regular" :readonly="completed" @click="openOptions('EsfericoEsquerdoEst')"
                v-model.lazy="formGroups[0].forms[4].fields.auto_refraction_static_spherical_l" aria-label="Esférico esquerdo" />
              <div class="input-options" v-if="activeEyeBox==='EsfericoEsquerdoEst' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[4]" activeInput="EsfericoEsquerdoEst"
                  :options="formGroups[0].forms[4].fields.auto_refraction_static_spherical_l !== null ? (parseFloat(formGroups[0].forms[4].fields.auto_refraction_static_spherical_l.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsPositive"
                  inputName="auto_refraction_static_spherical_l" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'auto_refraction_static_spherical_l', null, 4);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'auto_refraction_static_spherical_l', null, 4)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input type="text" class="form-control regular" :readonly="completed" @click="openOptions('CilindricoEsquerdoEst')"
                v-model.lazy="formGroups[0].forms[4].fields.auto_refraction_static_cylindrical_l" aria-label="Cilíndrico esquerdo" />
              <div class="input-options" v-if="activeEyeBox==='CilindricoEsquerdoEst' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[4]" activeInput="CilindricoEsquerdoEst"
                  :options="optionsNegative"
                  inputName="auto_refraction_static_cylindrical_l" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'auto_refraction_static_cylindrical_l', null, 4);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'auto_refraction_static_cylindrical_l', null, 4)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input type="text" class="form-control regular" :readonly="completed" @click="openOptions('EixoEsquerdoEst')"
                v-model.lazy="formGroups[0].forms[4].fields.auto_refraction_static_axis_l" aria-label="Eixo esquerdo" />
              <div class="input-options" v-if="activeEyeBox==='EixoEsquerdoEst' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[4]" activeInput="EixoEsquerdoEst"
                  :options="optionsEixo" inputName="auto_refraction_static_axis_l" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 1, 'auto_refraction_static_axis_l', 'eixo', 4);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 1, 'auto_refraction_static_axis_l', 'eixo', 4)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input type="text" class="form-control bottom-border" :readonly="completed" @click="openOptions('AdicaoEsquerdoEst')"
                v-model.lazy="formGroups[0].forms[4].fields.auto_refraction_static_addition_l" aria-label="Adição esquerdo" />
              <div class="input-options" v-if="activeEyeBox==='AdicaoEsquerdoEst' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[4]" activeInput="AdicaoEsquerdoEst"
                  :options="optionsPositive" inputName="auto_refraction_static_addition_l" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'auto_refraction_static_addition_l', null, 4);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'auto_refraction_static_addition_l', null, 4)"><p>-</p></button>
                  </div>
              </div>
            </div>

          </b-input-group>
        </div>


          <div v-if="formGroups[0].forms[5].showing">
            <div class="subtitle">Tonometria
              <div class="d-flex flex-row" v-if="formGroups[0].forms[5].opened">
                <p class="remove" @click="closeForm(formGroups[0].forms[5])">remover</p>
                <ChevronUp class="chevron" @click="formGroups[0].forms[5].opened = !formGroups[0].forms[5].opened" />
              </div>
              <div v-else>
                <ChevronDown class="chevron" @click="formGroups[0].forms[5].opened = !formGroups[0].forms[5].opened" />
              </div>
            </div>
          </div>
          <div class="tonometria-form" v-if="formGroups[0].forms[5].opened">
            <div>
              <p>Aparelho de medição</p>
              <input
                class="form-control"
                v-model="formGroups[0].forms[5].fields.tonometry_tool"
                :readonly="true"
              />
            </div>
            <div>
              <p>Horário</p>
              <the-mask
                :mask="['##:##']"
                v-model="formGroups[0].forms[5].fields.tonometry_hour"
                :readonly="completed"
                class="form-control"
                masked
              />
            </div>
            <div class="eye-box-container">
              <p>Olho direito</p>
              <b-input-group>
                <b-input-group-prepend is-text
                style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;">
                  <Eye class="form-icon" />
                  D
                </b-input-group-prepend>
                  <b-form-input
                    autocomplete="off"
                    v-model="formGroups[0].forms[5].fields.right_eye"
                    :readonly="completed"
                    type="text"
                    aria-label="Olho direito"
                    @click="setActiveForm('tonometriaDireito')"
                  ></b-form-input>
                  <div class="input-options margin-top" v-if="activeForm==='tonometriaDireito' && !completed">
                    <MultipleEyesOptions :form="formGroups[0].forms[5]" activeInput="tonometriaDireito"
                      :options="optionsTonometria" inputName="right_eye" :setActiveInput="this.setActiveForm" />
                  </div>
                  <div class="eyeValueChange tonometriaValueChange" v-if="!completed">
                    <div>
                      <button @click="changeInputValue('increase', 1, 'right_eye', 'tonometria', 5);"><p>+</p></button>
                    </div>
                    <div>
                      <button @click="changeInputValue('decrease', 1, 'right_eye', 'tonometria', 5)"><p>-</p></button>
                    </div>
                  </div>
              </b-input-group>
            </div>
            <div>
              <div class="eye-box-container">
              <p>Olho esquerdo</p>
              <b-input-group>
                <b-input-group-prepend is-text
                style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;">
                  <Eye class="form-icon" />
                  E
                </b-input-group-prepend>
                  <b-form-input
                    autocomplete="off"
                    v-model="formGroups[0].forms[5].fields.left_eye"
                    :readonly="completed"
                    type="text"
                    @click="setActiveForm('tonometriaEsquerdo')"
                    aria-label="Olho esquerdo"
                  ></b-form-input>
                  <div class="input-options tonometriaEsquerdo" v-if="activeForm==='tonometriaEsquerdo' && !completed">
                    <MultipleEyesOptions :form="formGroups[0].forms[5]" activeInput="tonometriaEsquerdo"
                      :options="optionsTonometria" inputName="left_eye" :setActiveInput="this.setActiveForm" />
                  </div>
                  <div class="eyeValueChange tonometriaValueChange" v-if="!completed">
                    <div>
                      <button @click="changeInputValue('increase', 1, 'left_eye', 'tonometria', 5);"><p>+</p></button>
                    </div>
                    <div>
                      <button @click="changeInputValue('decrease', 1, 'left_eye', 'tonometria', 5)"><p>-</p></button>
                    </div>
                  </div>
              </b-input-group>
            </div>
            </div>
          </div>

        <div v-if="formGroups[0].forms[6].showing">
          <div class="subtitle">Lensômetro
            <div class="d-flex flex-row" v-if="formGroups[0].forms[6].opened">
              <p class="remove" @click="closeForm(formGroups[0].forms[6])">remover</p>
              <ChevronUp class="chevron" @click="formGroups[0].forms[6].opened = !formGroups[0].forms[6].opened" />
            </div>
            <div v-else>
              <ChevronDown class="chevron" @click="formGroups[0].forms[6].opened = !formGroups[0].forms[6].opened" />
            </div>
          </div>
          <div class="ar-labels" v-if="formGroups[0].forms[6].opened">
            <div>Esférico</div>
            <div>Cilíndrico</div>
            <div>Eixo</div>
            <div>Adição</div>
          </div>
          <b-input-group class="form-ar-prepend-one" v-if="formGroups[0].forms[6].opened">
            <b-input-group-prepend is-text
            style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;">
              <Eye class="form-icon" />
              D
            </b-input-group-prepend>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control regular" :readonly="completed" @click="openOptions('EsfericoDireitoLens')"
                v-model.lazy="formGroups[0].forms[6].fields.lensometer_spherical_r" aria-label="Esférico direito" />
              <div class="input-options" v-if="activeEyeBox==='EsfericoDireitoLens' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[6]" activeInput="EsfericoDireitoLens"
                  :options="formGroups[0].forms[6].fields.lensometer_spherical_r !== null ? (parseFloat(formGroups[0].forms[6].fields.lensometer_spherical_r.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsPositive"
                  inputName="lensometer_spherical_r" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'lensometer_spherical_r', null, 6);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'lensometer_spherical_r', null, 6)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control regular" :readonly="completed" @click="openOptions('CilindricoDireitoLens')"
                v-model.lazy="formGroups[0].forms[6].fields.lensometer_cylindrical_r" aria-label="Cilíndrico direito" />
              <div class="input-options" v-if="activeEyeBox==='CilindricoDireitoLens' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[6]" activeInput="CilindricoDireitoLens"
                  :options="optionsNegative"
                  inputName="lensometer_cylindrical_r" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'lensometer_cylindrical_r', null, 6);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'lensometer_cylindrical_r', null, 6)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control regular" :readonly="completed" @click="openOptions('EixoDireitoLens')"
                v-model.lazy="formGroups[0].forms[6].fields.lensometer_axis_r" aria-label="Eixo direito" />
              <div class="input-options" v-if="activeEyeBox==='EixoDireitoLens' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[6]" activeInput="EixoDireitoLens"
                  :options="optionsEixo" inputName="lensometer_axis_r" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 1, 'lensometer_axis_r', 'eixo', 6);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 1, 'lensometer_axis_r', 'eixo', 6)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control top-border" :readonly="completed" @click="openOptions('AdicaoDireitoLens')"
                v-model.lazy="formGroups[0].forms[6].fields.lensometer_addition_r" aria-label="Adição direito" />
              <div class="input-options" v-if="activeEyeBox==='AdicaoDireitoLens' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[6]" activeInput="AdicaoDireitoLens"
                  :options="optionsPositive" inputName="lensometer_addition_r" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'lensometer_addition_r', null, 6);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'lensometer_addition_r', null, 6)"><p>-</p></button>
                  </div>
              </div>
            </div>

          </b-input-group>
          <b-input-group class="form-ar-prepend-two" v-if="formGroups[0].forms[6].opened">
            <b-input-group-prepend is-text
            style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;">
              <Eye class="form-icon" />
              E
            </b-input-group-prepend>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control regular" :readonly="completed" @click="openOptions('EsfericoEsquerdoLens')"
                v-model.lazy="formGroups[0].forms[6].fields.lensometer_spherical_l" aria-label="Esférico esquerdo" />
              <div class="input-options" v-if="activeEyeBox==='EsfericoEsquerdoLens' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[6]" activeInput="EsfericoEsquerdoLens"
                  :options="formGroups[0].forms[6].fields.lensometer_spherical_l !== null ? (parseFloat(formGroups[0].forms[6].fields.lensometer_spherical_l.replace(',', '.')) < 0.00 ? optionsNegative : optionsPositive) : optionsPositive"
                  inputName="lensometer_spherical_l" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'lensometer_spherical_l', null, 6);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'lensometer_spherical_l', null, 6)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control regular" :readonly="completed" @click="openOptions('CilindricoEsquerdoLens')"
                v-model.lazy="formGroups[0].forms[6].fields.lensometer_cylindrical_l" aria-label="Cilíndrico esquerdo" />
              <div class="input-options" v-if="activeEyeBox==='CilindricoEsquerdoLens' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[6]" activeInput="CilindricoEsquerdoLens"
                  :options="optionsNegative"
                  inputName="lensometer_cylindrical_l" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'lensometer_cylindrical_l', null, 6);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'lensometer_cylindrical_l', null, 6)"><p>-</p></button>
                  </div>
              </div>
            </div>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control regular" :readonly="completed" @click="openOptions('EixoEsquerdoLens')"
                v-model.lazy="formGroups[0].forms[6].fields.lensometer_axis_l" aria-label="Eixo esquerdo" />
              <div class="input-options" v-if="activeEyeBox==='EixoEsquerdoLens' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[6]" activeInput="EixoEsquerdoLens"
                  :options="optionsEixo" inputName="lensometer_axis_l" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                    <div>
                      <button @click="changeInputValue('increase', 1, 'lensometer_axis_l', 'eixo', 6);"><p>+</p></button>
                    </div>
                    <div>
                      <button @click="changeInputValue('decrease', 1, 'lensometer_axis_l', 'eixo', 6)"><p>-</p></button>
                    </div>
                </div>
            </div>

            <div class="eye-box-container">
              <input autocomplete="off" type="text" class="form-control bottom-border" :readonly="completed" @click="openOptions('AdicaoEsquerdoLens')"
                v-model.lazy="formGroups[0].forms[6].fields.lensometer_addition_l" aria-label="Adição esquerdo" />
              <div class="input-options" v-if="activeEyeBox==='AdicaoEsquerdoLens' && !completed">
                <MultipleEyesOptions :form="formGroups[0].forms[6]" activeInput="AdicaoEsquerdoLens"
                  :options="optionsPositive" inputName="lensometer_addition_l" :setActiveInput="this.setActiveEyeBox" />
              </div>
              <div class="eyeValueChange positionValueChange" v-if="!completed">
                  <div>
                    <button @click="changeInputValue('increase', 0.25, 'lensometer_addition_l', null, 6);"><p>+</p></button>
                  </div>
                  <div>
                    <button @click="changeInputValue('decrease', 0.25, 'lensometer_addition_l', null, 6)"><p>-</p></button>
                  </div>
              </div>
            </div>

          </b-input-group>
        </div>

        <footer class="form-footer" v-if="!completed">
          <button class="btn-cancel" @click="goTo('/pre-consulta')">
            Cancelar pré-consulta
          </button>
          <button v-can="'FpPreCon2'" class="btn-save" @click="save()">Salvar pré-consulta</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { VMoney } from 'v-money'
import { track } from '@/utils/mixPanel'
import moment from 'moment'

export default {
  name: 'PreConsultation',
  metaInfo: {
    title: 'Eyecare - Nova pré consulta'
  },
  components: {
    Avatar: () => import('@/components/General/Avatar.vue'),
    Calendar: () => import('@/assets/icons/calendar.svg'),
    Check: () => import('@/assets/icons/check.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    ChevronUp: () => import('@/assets/icons/chevron-up.svg'),
    Eye: () => import('@/assets/icons/eye.svg'),
    MultipleEyesOptions: () => import('@/assets/icons/avatar.svg'),
    Plus: () => import('@/assets/icons/plus.svg'),
  },
  data() {
    return {
      startDate: moment().toDate(),
      userName: null,
      user: null,
      clinic: null,
      activeForm: String,
      activeEyeBox: String,
      options: [['20/10', '20/15', '20/20', '20/25', '20/30'], ['20/40', '20/50', '20/70', '20/100', '20/200'],['20/400', 'CD 3m', 'CD 2m', 'CD 1m', 'CD 0,5m'], ['MM', 'PL', 'SPL']],
      optionsPositive: [['+0,25', '+0,50', '+0,75', '+1,00', '+1,25'], ['+1,50', '+1,75', '+2,00', '+2,25', '+2,50'], ['+2,75', '+3,00', '+3,25', '+3,50', '+3,75'], ['+4,00', '+4,25', '+4,50', '+4,75', '+5,00']],
      optionsNegative: [['-0,25', '-0,50', '-0,75', '-1,00', '-1,25'], ['-1,50', '-1,75', '-2,00', '-2,25', '-2,50'], ['-2,75', '-3,00', '-3,25', '-3,50', '-3,75'], ['-4,00', '-4,25', '-4,50', '-4,75', '-5,00']],
      optionsEixo: [['5º', '10º', '15º', '20º', '25º', '30º'], ['35º', '40º', '45º', '50º', '55º', '60º'], ['65º', '70º', '75º', '80º', '85º', '90º'], ['95º', '100º', '105º', '110º', '115º', '120º'], ['125º', '130º', '135º', '140º', '145º', '150º'], ['155º', '160º', '165º', '170º', '175º', '180º']],
      optionsTonometria: this.createTonometriaOptions(),

      numberFormat: {
        decimal: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },

      editting: false,
      completed: false,

      data: null,

      form: [],

      formGroups: [
        {
          name: 'Anamnese oftalmológica completa',
          opened: true,
          forms: [
            {
              id: 'pre-anamnese',
              name: 'Pré Anamnese',
              active: false,
              showing: true,
              opened: true,
              completed: () => {},
              fields: {
                pre_anamnese: ''
              }
            },
            {
              id: 'avsc',
              name: 'AVSC - Acuidade visual sem correção',
              active: false,
              showing: true,
              opened: true,
              completed: () => {},
              fields: {
                avsc_right_eye: '',
                avsc_left_eye: ''
              }
            },
            {
              id: 'avcc',
              name: 'AVCC - Acuidade visual com correção',
              active: false,
              showing: true,
              opened: true,
              completed: () => {},
              fields: {
                avcc_right_eye: '',
                avcc_left_eye: ''
              }
            },
            {
              id: 'auto-refracao',
              name: 'Autorrefração dinâmica',
              active: false,
              showing: true,
              opened: true,
              completed: () => {},
              fields: {
                auto_refraction_spherical_r: '',
                auto_refraction_cylindrical_r: '',
                auto_refraction_axis_r: '',
                auto_refraction_addition_r: '',
                auto_refraction_spherical_l: '',
                auto_refraction_cylindrical_l: '',
                auto_refraction_axis_l: '',
                auto_refraction_addition_l: ''
              }
            },
            {
              id: 'auto-refracao-estatica',
              name: 'Autorrefração estática',
              active: false,
              showing: true,
              opened: true,
              completed: () => {},
              fields: {
                auto_refraction_static_spherical_r: '',
                auto_refraction_static_cylindrical_r: '',
                auto_refraction_static_axis_r: '',
                auto_refraction_static_addition_r: '',
                auto_refraction_static_spherical_l: '',
                auto_refraction_static_cylindrical_l: '',
                auto_refraction_static_axis_l: '',
                auto_refraction_static_addition_l: ''
              }
            },
            {
              id: 'tonometria',
              name: 'Tonometria',
              active: false,
              showing: true,
              opened: true,
              completed: () => {},
              fields: {
                tonometry_tool: 'Tonometria de sopro',
                tonometry_hour: '',
                right_eye: '',
                left_eye: ''
              }
            },
            {
              id: 'lensometro',
              name: 'Lensômetro',
              active: false,
              showing: true,
              opened: true,
              completed: () => {},
              fields: {
                lensometer_spherical_r: '',
                lensometer_cylindrical_r: '',
                lensometer_axis_r: '',
                lensometer_addition_r: '',
                lensometer_spherical_l: '',
                lensometer_cylindrical_l: '',
                lensometer_axis_l: '',
                lensometer_addition_l: ''
              }
            },
          ]
        }
      ]
    }
  },
  mounted() {
    this.startDate =  moment().toDate()
    const user = localStorage.getItem('user')
    this.user = JSON.parse(user)
  },
  created() {
    const isLoading = this.$loading.show()

    this.clinic = JSON.parse(localStorage.getItem('clinic'))

    this.api
      .getAppointment(this.clinic.id, this.$route.params.id)
      .then(res => {
        this.data = res.data.appointment
        this.completed = !!res.data.appointment.patient_pre_consultation

        if (this.completed) {
          this.formGroups[0].forms[0].fields.pre_anamnese = res.data.appointment.patient_pre_consultation.pre_anamnese

          this.formGroups[0].forms[1].fields.avsc_right_eye = res.data.appointment.patient_pre_consultation.avsc_right_eye
          this.formGroups[0].forms[1].fields.avsc_left_eye = res.data.appointment.patient_pre_consultation.avsc_left_eye

          this.formGroups[0].forms[2].fields.avcc_right_eye = res.data.appointment.patient_pre_consultation.avcc_right_eye
          this.formGroups[0].forms[2].fields.avcc_left_eye = res.data.appointment.patient_pre_consultation.avcc_left_eye
          this.formGroups[0].forms[2].fields.avcc_right_eye = res.data.appointment.patient_pre_consultation.avcc_right_eye

          this.formGroups[0].forms[3].fields.auto_refraction_spherical_r = res.data.appointment.patient_pre_consultation.auto_refraction_spherical_r
          this.formGroups[0].forms[3].fields.auto_refraction_cylindrical_r = res.data.appointment.patient_pre_consultation.auto_refraction_cylindrical_r
          this.formGroups[0].forms[3].fields.auto_refraction_axis_r = res.data.appointment.patient_pre_consultation.auto_refraction_axis_r
          this.formGroups[0].forms[3].fields.auto_refraction_addition_r = res.data.appointment.patient_pre_consultation.auto_refraction_addition_r
          this.formGroups[0].forms[3].fields.auto_refraction_spherical_l = res.data.appointment.patient_pre_consultation.auto_refraction_spherical_l
          this.formGroups[0].forms[3].fields.auto_refraction_cylindrical_l = res.data.appointment.patient_pre_consultation.auto_refraction_cylindrical_l
          this.formGroups[0].forms[3].fields.auto_refraction_axis_l = res.data.appointment.patient_pre_consultation.auto_refraction_axis_l
          this.formGroups[0].forms[3].fields.auto_refraction_addition_l = res.data.appointment.patient_pre_consultation.auto_refraction_addition_l

          this.formGroups[0].forms[4].fields.auto_refraction_static_spherical_r = res.data.appointment.patient_pre_consultation.auto_refraction_static_spherical_r
          this.formGroups[0].forms[4].fields.auto_refraction_static_cylindrical_r = res.data.appointment.patient_pre_consultation.auto_refraction_static_cylindrical_r
          this.formGroups[0].forms[4].fields.auto_refraction_static_axis_r = res.data.appointment.patient_pre_consultation.auto_refraction_static_axis_r
          this.formGroups[0].forms[4].fields.auto_refraction_static_addition_r = res.data.appointment.patient_pre_consultation.auto_refraction_static_addition_r
          this.formGroups[0].forms[4].fields.auto_refraction_static_spherical_l = res.data.appointment.patient_pre_consultation.auto_refraction_static_spherical_l
          this.formGroups[0].forms[4].fields.auto_refraction_static_cylindrical_l = res.data.appointment.patient_pre_consultation.auto_refraction_static_cylindrical_l
          this.formGroups[0].forms[4].fields.auto_refraction_static_axis_l = res.data.appointment.patient_pre_consultation.auto_refraction_static_axis_l
          this.formGroups[0].forms[4].fields.auto_refraction_static_addition_l = res.data.appointment.patient_pre_consultation.auto_refraction_static_addition_l

          this.formGroups[0].forms[5].fields.tonometry_tool = res.data.appointment.patient_pre_consultation.tonometry_tool
          this.formGroups[0].forms[5].fields.tonometry_hour = res.data.appointment.patient_pre_consultation.tonometry_hour
          this.formGroups[0].forms[5].fields.right_eye = res.data.appointment.patient_pre_consultation.right_eye
          this.formGroups[0].forms[5].fields.left_eye = res.data.appointment.patient_pre_consultation.left_eye

          this.formGroups[0].forms[6].fields.lensometer_spherical_r = res.data.appointment.patient_pre_consultation.lensometer_spherical_r
          this.formGroups[0].forms[6].fields.lensometer_cylindrical_r = res.data.appointment.patient_pre_consultation.lensometer_cylindrical_r
          this.formGroups[0].forms[6].fields.lensometer_axis_r = res.data.appointment.patient_pre_consultation.lensometer_axis_r
          this.formGroups[0].forms[6].fields.lensometer_addition_r = res.data.appointment.patient_pre_consultation.lensometer_addition_r
          this.formGroups[0].forms[6].fields.lensometer_spherical_l = res.data.appointment.patient_pre_consultation.lensometer_spherical_l
          this.formGroups[0].forms[6].fields.lensometer_cylindrical_l = res.data.appointment.patient_pre_consultation.lensometer_cylindrical_l
          this.formGroups[0].forms[6].fields.lensometer_axis_l = res.data.appointment.patient_pre_consultation.lensometer_axis_l
          this.formGroups[0].forms[6].fields.lensometer_addition_l = res.data.appointment.patient_pre_consultation.lensometer_addition_l
        }
      })
      .catch(() => {
        this.$router.push('/pre-consulta')
      })
      .finally(() => {
        isLoading.hide()
      })
  },
  methods: {
    createTonometriaOptions(){
      const options = []
      let row = []
      for(let i = 1; i <= 60; i++){
        row.push(i)
        if(i%10 === 0){
          options.push(row)
          row = []
        }
      }
      return options
    },
    changeInputValue(change, amount, value, type, formNum) {
      if(this.formGroups[0].forms[formNum].fields[value] === '' || this.formGroups[0].forms[formNum].fields[value] === null){
        this.formGroups[0].forms[formNum].fields[value] = '0'
      }
      if(!(isNaN(parseFloat(this.formGroups[0].forms[formNum].fields[value])))){
        if(type === 'eixo') {
          if(change === 'increase'){
            this.formGroups[0].forms[formNum].fields[value] = ((parseFloat(this.formGroups[0].forms[formNum].fields[value].replace(',', '.')) + amount).toFixed(0)).replace('.', ',')
          } else if(change === 'decrease'){
            this.formGroups[0].forms[formNum].fields[value] = ((parseFloat(this.formGroups[0].forms[formNum].fields[value].replace(',', '.')) - amount).toFixed(0)).replace('.', ',')
          }
          if(parseFloat(this.formGroups[0].forms[formNum].fields[value].replace(',', '.')) > 0) {
            this.formGroups[0].forms[formNum].fields[value] = this.formGroups[0].forms[formNum].fields[value]+'º'
          }
        } else if(type === 'tonometria'){
          if(change === 'increase'){
            this.formGroups[0].forms[formNum].fields[value] = ''+((parseFloat(this.formGroups[0].forms[formNum].fields[value]) + amount).toFixed(0)).replace('.', ',')
          } else if(change === 'decrease'){
            this.formGroups[0].forms[formNum].fields[value] = ''+((parseFloat(this.formGroups[0].forms[formNum].fields[value]) - amount).toFixed(0)).replace('.', ',')
          }

        } else if(!(isNaN(parseFloat(this.formGroups[0].forms[formNum].fields[value])))){
          if(change === 'increase'){
            this.formGroups[0].forms[formNum].fields[value] = ((parseFloat(this.formGroups[0].forms[formNum].fields[value].replace(',', '.')) + amount).toFixed(2)).replace('.', ',')

          } else if(change === 'decrease'){
            this.formGroups[0].forms[formNum].fields[value] = ((parseFloat(this.formGroups[0].forms[formNum].fields[value].replace(',', '.')) - amount).toFixed(2)).replace('.', ',')
          }
          if(parseFloat(this.formGroups[0].forms[formNum].fields[value].replace(',', '.')) > 0){
            this.formGroups[0].forms[formNum].fields[value] = '+'+this.formGroups[0].forms[formNum].fields[value]
          }
        }


      }
    },

    setActiveForm(FormName) {
      this.activeForm = FormName
    },
    setActiveEyeBox(BoxName){
      this.activeEyeBox = BoxName
    },
    openOptions(input, clickable){
        this.activeEyeBox === input ? this.activeEyeBox = null : this.activeEyeBox = input
    },

    getUser() {
      if(!this.user.name) {
        switch (this.user.type) {
          case 'PROFESSIONAL':
          case 'DOCTOR':
            this.userName = 'Médico(a)';
            break;
          case 'NURSE':
              this.userName = 'Enfermeiro(a)';
            break;
          case 'MANAGER':
              this.userName = 'Gerente';
            break;
          case 'SECRETARY':
              this.userName = 'Secretária(o)';
            break;
          case 'DOCTOR_MANAGER':
              this.userName = 'Médico(a) Gerente';
            break;
          case 'TECHNICIAN':
              this.userName = 'Enfermeiro(a)';
            break;
          case 'TECHNOLOGIST':
              this.userName = 'Enfermeiro(a)';
            break;
        }
      } else {
        this.userName = this.user.name
      }

      return this.userName
    },
    goTo(path) {
      this.$router.push(path)
    },
    editMode() {
      this.completed = false
      this.editting = true
    },
    save() {
      const isLoading = this.$loading.show()

      const formData = {
        ...this.formGroups[0].forms[0].fields,
        ...this.formGroups[0].forms[1].fields,
        ...this.formGroups[0].forms[2].fields,
        ...this.formGroups[0].forms[3].fields,
        ...this.formGroups[0].forms[4].fields,
        ...this.formGroups[0].forms[5].fields,
        ...this.formGroups[0].forms[6].fields,
        patient_id: this.data.patient.id,
        professional_id: this.user.id,
        appointment_id: this.data.id
      }

      if (this.editting) {
        this.api
          .updatePreConsultation(
            this.clinic.id,
            this.data.patient_pre_consultation.id,
            formData
          )
          .then(res => {
            this.completed = true
            this.editting = false
          })
          .catch(err => {
            console.log('Erro', err)
          })
          .finally(() => {
            isLoading.hide()
          })

        return
      }
      formData.start_datetime = this.startDate ? this.startDate : moment().toDate()
      formData.end_datetime = moment().toDate()
      this.api
        .storePreConsultation(this.clinic.id, formData)
        .then(() => {
          track('pre_consulta_finalizada', {
            clinic: this.clinic.name,
          })
          this.$router.push('/pre-consulta')
        })
        .catch(err => {
          console.log('err ', err)
          alert('Verifique se todos os dados foram inseridos!')
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    showForm(form) {
      form.showing = true
      form.opened = true
    },
    closeForm(form) {
      form.showing = false
      form.opened = false

      console.log(form)

      // for(var indexField in form.fields){
      //   form.fields[indexField] = null
      // }
    },
  },
  directives: { numberFormat: VMoney }
}
</script>

<style lang="scss" scoped>

.input-options {
  margin-top: 0px;
  position: absolute;
}

.margin-top {
  margin-top: 50px;
  margin-left: -120px !important;
}

.tonometriaEsquerdo {
  margin-top: 50px;
  margin-left: -250px !important;
}


.eye-box-container {
  .eyeValueChange {
    visibility: hidden;
  }

  &:hover {
    .eyeValueChange {
      visibility: visible;
    }
  }
}




.eyeValueChange {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 !important;
  position: absolute;
  z-index: 5;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 20px;

    button {
      height: 20px;
      width: 30px;
      color: var(--greys-60);
      background-color: #fff;
      position: relative !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1.5px solid var(--blue-100) !important;

      p {
        font-size: 10.5pt;
        font-weight: 700;
      }

    }
  }
}

.positionValueChange {
  margin-left: 88px !important;
  margin-top: -48px !important;
}

.tonometriaValueChange {
  margin-left: 180px !important;
}


.preconsultation-data {
  padding: 30px 24px;
  text-align: left;

  .breadcrumbs {
    width: 100%;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 16px;

    .chevron {
      width: 24px;
      stroke: var(--neutral-600);
      margin: 0 8px;
    }

    .link {
      color: var(--blue-500);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-right: 16px;
  }

  *:focus {
    outline: none;
  }

        .contentSidebar {
        padding: 24px;
        border-bottom: 1px solid var(--neutral-200);

        .patient-photo {
          width: 64px;
          height: 64px;
          border-radius: 32px;
          margin-right: 16px;
        }

        .patient-name {
          font-family: 'Red Hat Display';
          font-weight: 700;
          font-size: 24px;
          color: var(--dark-blue);
          margin-bottom: 4px;
        }

        .medical-record {
          font-family: 'Nunito Sans';
          font-weight: 400;
          font-size: 12px;
          color: var(--type-placeholder);

          span {
            font-weight: 600;
            color: var(--type-active);
          }
        }

        .tab-menu {
          display: flex;
          background-color: var(--neutral-100);
          border-radius: 8px;

          .tab-item {
            flex: 1;
            display: inline-block;
            padding: 4px 16px;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: var(--type-active);
            border-radius: 8px;
            cursor: pointer;
            text-align: center;

            &.active {
              background-color: var(--blue-500);
              color: var(--neutral-000);
            }
          }
        }

        .durationText {
          font-weight: 600;
          font-size: 16px;
          color: var(--dark-blue);
        }

        .durationTimer {
          background-color: var(--states-success-soft-green);
          padding: 8px 16px;
          border-radius: 100px;
          font-weight: 700;
          font-size: 14px;
          color: var(--type-active);
          display: flex;
          flex-direction: row;
          align-items: center;

          svg {
            width: 16px;
            height: 16px;
            stroke: var(--states-success);
            margin-left: 10px;
          }
        }

        &.collapsible {
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
              font-weight: 600;
              font-size: 16px;
              color: var(--dark-blue);
            }

            svg {
              width: 24px;
              height: 24px;
              stroke: var(--neutral-600);
              transform: rotate(180deg);
              transition: all 0.5s;
              cursor: pointer;
            }
          }

          ul {
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s;

            .confirmed {
              stroke: var(--states-success);
            }

            .plus {
              color: var(--blue-500);

              svg {
                cursor: pointer;
                fill: var(--blue-500);
                stroke: transparent;
              }
            }
          }

          &.opened {
            .header {
              margin-bottom: 24px;
            }

            ul {
              max-height: 1000px;
              overflow: initial;
            }

            .header {
              svg {
                transform: rotate(0deg);
              }
            }
          }
        }

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            font-weight: 600;
            font-size: 16px;
            color: var(--type-active);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 8px 0;
            position: relative;
            cursor: pointer;

            span.button {
              padding: 8px 16px;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              border: 2px solid var(--type-active);
              border-radius: 8px;
              margin-top: 16px;
            }

            svg {
              width: 24px;
              height: 24px;
              stroke: var(--neutral-500);
            }

            &.active {
              font-weight: 700;

              &:before {
                width: 4px;
                height: 100%;
                background-color: var(--orange);
                content: ' ';
                position: absolute;
                top: 0;
                left: -24px;
              }
            }
          }
        }
      }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    background-color: var(--neutral-000);
    border-radius: 8px;

    min-height: calc(100vh - 200px);

    .resume {
      border-right: 1px solid #d9dff2;
      padding-right: 0;
      width: 25%;

      .patient_header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 28px;
        border-bottom: 1px solid #d9dff2;

        svg,
        img {
          width: 64px;
          height: 64px;
          border-radius: 64px;
          margin-right: 16px;
        }

        div {
          h5 {
            font-family: 'Red Hat Display';
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: -0.01em;
            text-overflow: ellipsis;
            color: var(--dark-blue);
          }

          p {
            color: #8696ac;

            &:nth-child(2) {
              margin: 0;
            }

            b {
              color: var(--dark-blue);
              font-weight: 600;
            }
          }
        }
      }

      .resume_list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          padding: 16px 24px;

          border-bottom: 1px solid #d9dff2;

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          svg.icon {
            width: 24px;
            height: 24px;
            fill: #6d789c;
            margin-right: 24px;
            margin-left: 8px;
          }
        }
      }
    }

    .form {
      padding: 44px 34px;
      width: 100%;

      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        font-family: 'Red Hat Display';
        font-size: 32px;
        font-weight: 600;
        line-height: 28px;
        color: #0c1d59;
        margin-bottom: 44px;
      }

      .subtitle {
        font-family: 'Nunito Sans';
        font-size: 18px;
        font-weight: 600;
        line-height: 1.5;
        color: #0c1d59;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
      }

      .ar-labels {
        display: grid;
        grid-template-columns: repeat(4, 117px);
        margin-bottom: 2px;
        padding-left: 62px;

        div {
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: center;
        }
      }

      .input-group {

        .input-group-text {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          font-weight: 600;
          width: 62px;
          color: #525c7a;

        }

        .form-control {
          border-radius: 0 8px 8px 0 !important;
        }

        .regular {
          border-radius: 0 !important;
        }
        .top-border {
            border-radius: 0 8px 0 0 !important;
          }

        .bottom-border {
            border-radius: 0 0 8px 0 !important;
          }

      }

      .bottom-icon-container {
        color: red !important;
        border-radius: 0px 0px 0px 8px !important;
      }

      .form-icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      .form-ar-prepend-one {
        .input-group-text {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 0;
          border-bottom-width: 0.5px;
          font-weight: 600;
          width: 62px;
          color: #525c7a;
        }

        .eye-box-container {
          .form-control {
            text-align: center;
            max-width: 117px;
            border-radius: 0;
            border-bottom-width: 0.5px;

            &:last-child {
              border-radius: 0 8px 0 0;
            }
          }

          &:last-child {
            .eyeValueChange  {
              div:nth-child(1) {
                  button {
                    border-radius: 0 8px 0 0 !important;
                  }
                }
              }
            }
          }
      }

      .form-ar-prepend-two {
        margin-bottom: 58px;

          .input-group-text {
            border-top-left-radius: 0 !important;
            border-top-width: 0.5px;
            font-weight: 600;
            color: #525c7a;
            width: 62px;
          }

        .eye-box-container {
          .form-control {
            text-align: center;
            max-width: 117px;
            border-top-width: 0.5px;
            border-radius: 0 ;

            &:last-child {
              border-radius: 0 0 8px 0;
            }
          }

          &:last-child {
            .eyeValueChange  {
              div:nth-child(2) {
                button {
                    border-radius: 0 0 8px 0  !important;
                }
              }
            }
          }
        }
      }

      .btn-edit {
        border: none;
        background-color: transparent;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        color: #305bf2;

        transition: all ease 0.3s;

        &:hover {
          opacity: 0.7;
        }
      }

      .anamnese-form {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
        margin-bottom: 56px;
      }

      .avsc-form {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 16px;
        margin-bottom: 56px;
      }

      .avcc-form {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 16px;
        margin-bottom: 56px;
      }

      .tonometria-form {
        display: grid;
        grid-template-columns: 3fr 1fr 2fr 2fr;
        grid-gap: 16px;
        margin-bottom: 56px;
      }

      .remove {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: var(--states-error);
      cursor: pointer;
      margin-right: 14px;
    }

      .chevron {
        width: 24px;
        height: 24px;
        stroke: var(--neutral-600);
        cursor: pointer;
        transition: all 0.5s;
      }

      .form-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        button {
          padding: 16px;
          border: none;
          border-radius: 8px;
          margin-left: 8px;
          background-color: transparent;

          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;

          transition: all ease 0.3s;

          &.btn-save {
            background: #00c773;
            color: #fff;
          }

          &.btn-cancel {
            color: #305bf2;
          }

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
</style>
