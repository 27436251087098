<!--- View de Analitycs - Não está sendo utilizada ainda no projeto, valores ainda estão estáticos --->

<template>
  <div class="analytics">
    <p class="title">Analytics</p>
    <div class="container">
      <b-row>
        <b-col cols="3">
          <div class="white-box h-413">
            <div class="content">
              <div class="header mb-0">
                <p class="main-title">Total de pacientes</p>
                <b-button variant="link" class="fs-14 p-0">Ver detalhes</b-button>
              </div>
              <p class="number mb-32">140</p>

              <div class="header mb-0">
                <p class="main-title">Pacientes em tratamento</p>
              </div>
              <p class="number mb-32">98</p>

              <div class="header mb-0">
                <p class="main-title">Pacientes novos em <span>setembro</span></p>
              </div>
              <div class="d-flex flex-row align-items-center mb-32">
                <p class="number">140</p>
                <p class="badge green"><ArrowUp class="arrow" /> 5%</p>
              </div>

              <div class="header mb-0">
                <p class="main-title">Gênero dos pacientes</p>
              </div>
              <b-row class="mb-32">
                <b-col cols="4">
                  <p class="number mb-0">75%</p>
                  <p class="small">Homens</p>
                </b-col>
                <b-col cols="4">
                  <p class="number mb-0">25%</p>
                  <p class="small">Mulheres</p>
                </b-col>
                <b-col cols="4">
                  <p class="number mb-0">25%</p>
                  <p class="small">Outros</p>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="white-box h-413">
            <div class="content">
              <div class="header">
                <p class="main-title">Consultas Canceladas x Remarcadas</p>

                <div class="d-flex flex-row">
                  <div>
                    <multiselect class="multiselect-small small-chevron" v-model="chartType" :options="['Anual', 'Mensal']" placeholder="Local de atendimento 01" :showLabels="false" :searchable="false" :allowEmpty="false" :preselect-first="true">
                      <template slot="caret">
                        <div class="chevron">
                          <ChevronDown />
                        </div>
                      </template>

                      <template slot="noOptions">
                        Nenhuma opção
                      </template>

                      <template slot="noResult">
                        Nenhum resultado
                      </template>
                    </multiselect>
                  </div>

                  <b-button variant="link" class="fs-14 p-0">Ver detalhes</b-button>
                </div>
              </div>

              <div class="chart-container">
                <bar-chart :data="appointmentsCanceledsRescheduledsData" :options="appointmentsCanceledsRescheduledsOptions" />
              </div>

              <div class="labels">
                <div class="label orange">Remarcadas</div>
                <div class="label blue">Canceladas</div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="white-box h-413">
            <div class="content">
              <div class="header">
                <p class="main-title">Tipos de consulta</p>
                <b-button variant="link" class="fs-14 p-0">Ver detalhes</b-button>
              </div>

              <div class="d-flex flex-row align-items-center">
                <div class="doughnut-chart-container">
                  <doughnut-chart :data="appointmentsTypesData" :options="appointmentsTypesOptions" />
                </div>
                <div class="labels doughnut">
                  <div class="label light-blue">Operadora</div>
                  <div class="label blue">Particular</div>
                  <div class="label orange">Cortesia</div>
                </div>
              </div>

              <hr class="line-separator">

              <div class="doughnut-infos">
                <b-row>
                  <b-col cols="6">
                    <p class="quantity light-blue">90</p>
                    <p class="info">Operadora</p>
                  </b-col>
                  <b-col cols="6">
                    <p class="quantity blue">45</p>
                    <p class="info">Particular</p>
                  </b-col>
                  <b-col cols="6">
                    <p class="quantity orange">45</p>
                    <p class="info">Cortesia</p>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="white-box h-332">
            <div class="content">
              <div class="header">
                <p class="main-title">Simulação de receita</p>
                <b-button variant="link" class="fs-14 p-0">Ver detalhes</b-button>
              </div>

              <div class="revenue-chart">
                <bar-chart :data="revenueData" :options="revenueOptions" />
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="white-box h-332">
            <div class="content">
              <div class="header">
                <p class="main-title">Operadoras por relevância</p>
                <b-button variant="link" class="fs-14 p-0">Ver detalhes</b-button>
              </div>

              <ul class="health-plans-list">
                <li>Hapvida <span>43</span></li>
                <li>Unimed <span>32</span></li>
                <li>Qualicorp <span>22</span></li>
                <li>Agemed <span>13</span></li>
                <li>Notre Dame <span>13</span></li>
                <li>Allianz Care <span>12</span></li>
                <li>Amil <span>9</span></li>
                <li>Golden Cross <span>7</span></li>
                <li>Bradesco <span>4</span></li>
                <li>Humana Saúde <span>1</span></li>
              </ul>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="mt-64 mb-80">
      <p class="title"><GlaucoWithLabel /></p>
      <div class="container">
        <b-row>
        <b-col cols="3">
          <div class="white-box h-413">
            <div class="content">
              <div class="header mb-0">
                <p class="main-title">Total de pacientes</p>
                <b-button variant="link" class="fs-14 p-0">Ver detalhes</b-button>
              </div>
              <p class="number mb-32">140</p>

              <div class="header mb-0">
                <p class="main-title">Pacientes em tratamento</p>
              </div>
              <p class="number mb-32">98</p>

              <div class="header mb-0">
                <p class="main-title">Pacientes novos em <span>setembro</span></p>
              </div>
              <div class="d-flex flex-row align-items-center mb-32">
                <p class="number">140</p>
                <p class="badge green"><ArrowUp class="arrow" /> 5%</p>
              </div>

              <div class="header mb-0">
                <p class="main-title">Gênero dos pacientes</p>
              </div>
              <b-row class="mb-32">
                <b-col cols="4">
                  <p class="number mb-0">75%</p>
                  <p class="small">Homens</p>
                </b-col>
                <b-col cols="4">
                  <p class="number mb-0">25%</p>
                  <p class="small">Mulheres</p>
                </b-col>
                <b-col cols="4">
                  <p class="number mb-0">25%</p>
                  <p class="small">Outros</p>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="white-box h-199">
            <div class="content">
              <div class="header mb-0">
                <p class="main-title">Aderência média</p>
              </div>
              <p class="number">85%</p>
            </div>
          </div>

          <div class="white-box h-199">
            <div class="content">
              <div class="header mb-0">
                <p class="main-title">Última PIO Média</p>
              </div>
              <p class="number">23</p>
            </div>
          </div>
        </b-col>

        <b-col cols="3">
          <div class="white-box h-413">
            <div class="content">
              <div class="header with-subtitle">
                <p class="main-title">Número de colírios utilizados</p>
                <p class="main-subtitle">Por pessoa</p>
              </div>

              <div class="eye-drops-chart">
                <horizontal-bar-chart :data="eyeDropsData" :options="eyeDropsOptions" />
              </div>
            </div>
          </div>
        </b-col>

        <b-col cols="3">
          <div class="white-box h-413">
            <div class="content">
              <div class="header">
                <p class="main-title">Colírios mais receitados</p>
              </div>

              <div class="eye-drops-scroll">
                <perfect-scrollbar>
                <ul class="eye-drops-list">
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                  <li>Nome do colírio <span>43</span></li>
                </ul>
              </perfect-scrollbar>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'
import ArrowUp from '@/assets/icons/arrow-up.svg'
import GlaucoWithLabel from '@/assets/icons/glauco-with-label.svg'

import BarChart from '@/components/BarChart'
import HorizontalBarChart from '@/components/HorizontalBarChart'
import DoughnutChart from '@/components/DoughnutChart'

export default {
  name: 'AnalyticsView',
  components: {
    ChevronDown,
    ArrowUp,
    GlaucoWithLabel,
    BarChart,
    HorizontalBarChart,
    DoughnutChart,
  },
  data () {
    return {
      chartType: 'Anual',
      appointmentsCanceledsRescheduledsData: {
        labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        datasets: [
          {
            label: 'Canceladas',
            backgroundColor: '#305BF2',
            lineTension: 0,
            data: [5, 18, 13, 9, 5, 4, 7, 6, 6, 5, 11, 12]
          },
          {
            label: 'Remarcadas',
            backgroundColor: '#FF6B00',
            lineTension: 0,
            data: [7, 7, 17, 12, 6, 5, 8, 4, 13, 8, 7, 8]
          }
        ]
      },
      appointmentsCanceledsRescheduledsOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            gridLines: {
              color: '#D9DFF2',
              drawBorder: false,
            },
            ticks: {
              stepSize: 5,
              beginAtZero: true,
              fontSize: 10,
              fontColor: '#8696AC'
            },
          }],
          xAxes: [{
            categoryPercentage: 0.5,
            barPercentage: 0.8,
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              fontSize: 10,
              fontColor: '#8696AC'
            }
          }]
        },
        tooltips: {
          mode: 'label',
          bodyAlign: 'center'
        }
      },
      appointmentsTypesData: {
        labels: ['Operadora', 'Particular', 'Cortesia'],
        datasets: [
          {
            backgroundColor: [
              '#00C2FF',
              '#7F91D2',
              '#FF6B00'
            ],
            data: [90, 45, 45],
            borderWidth: 0
          }
        ]
      },
      appointmentsTypesOptions: {
        cutoutPercentage: 90,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        }
      },
      revenueData: {
        labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        datasets: [
          {
            label: 'Canceladas',
            backgroundColor: '#00C773',
            lineTension: 0,
            data: [12500, 4500, 18000, 6000, 7500, 12500, 18000, 7500, 3500, 4500, 6000, 12500]
          },
        ]
      },
      revenueOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            gridLines: {
              color: '#D9DFF2',
              drawBorder: false,
            },
            ticks: {
              display: false,
              stepSize: 5000,
              beginAtZero: true,
              fontSize: 10,
              fontColor: '#8696AC',
              callback: function (value, index, values) {
                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
              }
            },
          }],
          xAxes: [{
            categoryPercentage: 0.5,
            barPercentage: 0.4,
            gridLines: {
              color: '#D9DFF2',
              display: false,
              drawBorder: false,
            },
            ticks: {
              fontSize: 10,
              fontColor: '#8696AC'
            }
          }]
        },
        tooltips: {
          mode: 'label',
          bodyAlign: 'center'
        }
      },
      eyeDropsData: {
        labels: ['1', '2', '3', '4', '5+'],
        datasets: [
          {
            backgroundColor: [
              '#FFCC18',
              '#305BF2',
              '#FF6B00',
              '#00C2FF',
              '#7F91D2'
            ],
            lineTension: 0,
            data: [28, 34, 22, 16, 4]
          },
        ]
      },
      eyeDropsOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            gridLines: {
              display: false,
            },
          }],
          xAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              display: false,
              fontSize: 10,
              fontColor: '#8696AC'
            }
          }]
        },
        tooltips: {
          mode: 'label',
          bodyAlign: 'center'
        }
      },
    }
  }
}
</script>

<style lang="scss">
  .analytics {
    height: 100%;
    padding: 30px 24px;
    text-align: left;

    .title {
      font-family: 'Red Hat Display';
      font-weight: 700;
      font-size: 24px;
      color: var(--dark-blue);
      margin-bottom: 27px;
    }

    .mt-64 {
      margin-top: 64px;
    }

    .mb-80 {
      margin-bottom: 80px;
    }

    .container {
      width: 100%;
      max-width: 100%;
      padding: 0;

      .white-box {
        width: 100%;
        background-color: var(--neutral-000);
        border-radius: 8px;
        margin-bottom: 16px;

        &.h-413 {
          height: 413px;
        }

        &.h-332 {
          height: 332px;
        }

        &.h-199 {
          height: 199px;
        }

        .content {
          padding: 24px;

          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            &.with-subtitle {
              align-items: start;
              flex-direction: column;
            }

            .main-title {
              font-family: 'Red Hat Display';
              font-weight: 500;
              font-size: 16px;
              color: var(--dark-blue);

              span {
                color: var(--orange);
              }
            }

            .main-subtitle {
              font-weight: 600;
              font-size: 14px;
              margin-top: 8px;
              color: var(--type-placeholder);
            }

            .multiselect-small {
              .multiselect__tags {
                min-height: auto;
                padding: 0 19px 0 0;
              }

              .multiselect__single {
                font-size: 14px;
              }

              &.multiselect--active {
                .multiselect__tags {
                  padding: 1px 20px 0 1px;
                  border: 0;
                }
              }
            }
          }

          .mb-32 {
            margin-bottom: 32px;
          }

          .number {
            font-family: 'Red Hat Display';
            font-weight: 400;
            color: var(--type-placeholder);
            font-size: 32px;
            line-height: 40px;
          }

          .badge {
            width: 47px;
            height: 24px;
            border-radius: 12px;
            font-weight: 600;
            font-size: 12px;
            color: var(--neutral-000);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
            margin-left: 16px;

            .arrow {
              width: 8px;
              height: 8px;
              stroke: var(--neutral-000);
              margin-right: 4px;
            }

            &.green {
              background-color: var(--states-success);
            }
          }

          .small {
            font-family: 'Nunito Sans';
            font-weight: 600;
            font-size: 12px;
            color: var(--type-active);
            margin-top: -5px;
          }

          .chart-container {
            flex-grow: 1;
            min-height: 0;

            > div {
              position: relative;
              height: 269px;
            }
          }

          .doughnut-chart-container {
            min-height: 0;

            > div {
              position: relative;
              width: 124px;
              height: 124px;
            }
          }

          .labels {
            display: flex;
            flex-direction: row-reverse;
            text-align: right;
            margin-top: 14px;

            .label {
              font-weight: 600;
              font-size: 14px;
              color: var(--type-placeholder);
              padding-left: 16px;
              margin-left: 16px;
              position: relative;

              &:before {
                width: 8px;
                height: 8px;
                border-radius: 4px;
                left: 0;
                top: 6px;
                position: absolute;
                content: ' ';
              }

              &.orange {
                &:before {
                  background-color: var(--orange);
                }
              }

              &.blue {
                &:before {
                  background-color: var(--blue-500);
                }
              }
            }

            &.doughnut {
              flex-direction: column;
              margin: 0 0 0 24px;
              text-align: left;

              .label {
                margin-bottom: 8px;

                &.light-blue {
                  &:before {
                    background-color: var(--light-blue);
                  }
                }

                &.blue {
                  &:before {
                    background-color: var(--neutral-500);
                  }
                }

                &.orange {
                  &:before {
                    background-color: var(--orange);
                  }
                }
              }
            }
          }

          .line-separator {
            margin: 20px 0;
            border-top-color: var(--neutral-200);
          }

          .doughnut-infos {
            .quantity {
              width: 40px;
              height: 40px;
              border-radius: 16px;
              background-color: #CCF3FF;
              font-weight: 600;
              font-size: 16px;
              line-height: 40px;
              color: var(--dark-blue);
              display: inline-block;
              margin: 0;
              text-align: center;

              &.light-blue {
                background-color: #CCF3FF;
              }

              &.blue {
                background-color: #D9DFF2;
              }

              &.orange {
                background-color: #FFE1CC;
              }
            }

            .info {
              font-weight: 600;
              font-size: 14px;
              color: var(--type-placeholder);
              margin: 8px 0 16px 0;
            }
          }

          .revenue-chart {
            min-height: 0;

            > div {
              position: relative;
              height: 240px;
            }
          }

          .health-plans-list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            columns: 2;
            column-gap: 64px;
            -webkit-columns: 2;
            -webkit-column-gap: 64px;
            -moz-columns: 2;
            -moz-column-gap: 64px;

            li {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 24px;
              color: var(--type-active);
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              span {
                text-align: right;
                color: var(--dark-blue);
              }
            }
          }
        }

        .eye-drops-chart {
          flex-grow: 1;
          min-height: 0;

          > div {
            position: relative;
            height: 292px;
          }
        }

        .eye-drops-scroll {
          .ps {
            height: 321px;
          }

          .eye-drops-list {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 24px;
              color: var(--type-active);
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              span {
                text-align: right;
                color: var(--dark-blue);
              }
            }
          }
        }

      }
    }
  }
</style>
