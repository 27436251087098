<template>
  <div class="preconsultation-data">
    <p class="title">Pré-consulta</p>

    <div class="row">
        <div class="patient-input input col-4">
          <Search id="search" />
          <b-form-input
            trim
            class="search-input"
            placeholder="Pesquisar por paciente ou Nº de prontuário"
            debounce="500"
            autocomplete="off"
            v-model="query"
          />
        </div>
      <div class="input col">
        <multi-select-priority
          v-model="priorityLevels"
          placeholder="Prioridade"
          />
          </div>

        <div class="input col">
          <LimitedMultipleSelect
            trackBy="value"
            optionLabel="label"
            allSelectedLabel="Todas as Situações"
            :value="selectedAppointmentStatuses"
            :options="schedulingStatus"
            @onChange="changeSelectedAppointmentStatuses"
            :limit="1"
            placeholder="Situações"
          />
        </div>
        <div class="input col">

          <LimitedMultipleSelect
          trackBy="value"
          optionLabel="label"
          allSelectedLabel="Todos Status"
          :value="selectedStatuses"
          :options="statusArray.map(s => ({ label: s.name, value: s.value }))"
          @onChange="changeSelectedPreConsultationStatuses"
          :limit="1"
          placeholder="Pré-consultas"
          />
        </div>

        <div class="input col">
          <LimitedMultipleSelect
            trackBy="id"
            optionLabel="name"
            allSelectedLabel="Todos Médicos"
            :value="selectedDoctors"
            :options="doctorsOptions"
            @onChange="changeSelectedDoctors"
            :limit="1"
            placeholder="Médicos"
          />
        </div>
    </div>

    <div class="content">
      <b-table-simple responsive class="table">
        <b-thead>
          <b-tr>
            <b-th class="time">Horário</b-th>
            <b-th class="name">Nome</b-th>
            <b-th class="medical_record">Nº do prontuário</b-th>
            <b-th class="doctor">Médico</b-th>
            <b-th width="24"></b-th>
          </b-tr>
        </b-thead>

        <b-tbody>
          <b-tr v-for="(appointment, i) of appointments" :key="i">
            <b-td class="time">{{
              moment(appointment.start_datetime).format('HH:mm')
            }}</b-td>

            <b-td class="name">
              <div class="avatar-container">
                <PatientInformation :appointment="appointment" :status="appointment.status" showCpf />
              </div>
            </b-td>

            <b-td class="medical_record">
              <div
                class="medical-record"
                @click="openMedicalRecord(appointment)"
              >
                <p class="medical_record_number m-0">
                  {{ appointment.patient.medical_record_number }}
                </p>
                <Export
                  v-can="'FpPac1'"
                  v-if="appointment.patient.medical_record_number !== null"
                  class="export medical_record_icon"
                />
              </div>
            </b-td>

            <b-td class="doctor">
              <div class="doctor-container">
                <Avatar
                  :src="
                    appointment.professional
                      ? appointment.professional.picture
                      : null
                  "
                />
                <p class="professional-name m-0">
                  {{
                    appointment.professional
                      ? appointment.professional.name
                      : '-'
                  }}
                </p>
              </div>
            </b-td>
            <b-td class="actions">
              <div class="d-flex align-items-center">
                <button
                  v-can="'FpPreCon2'"
                  v-if="!appointment?.pre_consultation?.id"
                  class="preconsultation-button"
                  @click="startPreConsultation(appointment)"
                  :disabled="['CANCELLED', 'MISSED', 'FINISHED', 'PRE_FINISHED'].includes(appointment.status) || appointment?.appointment_items.find(a => a?.item?.deleted_at !== null)"
                >
                Pré-consulta
                </button>
                <button
                  v-can="'FpPreCon2'"
                  class="preconsultation-button"
                  @click="
                    goTo(
                      `/pacientes/${appointment.patient_id}/pre-consulta/${appointment?.pre_consultation?.id}`
                    )
                  "
                  v-else-if="
                    appointment?.status === 'IN_PRE_CONSULTATION' &&
                    appointment?.pre_consultation?.id
                  "
                >
                  Pré-consulta
                </button>
                <button
                  v-else
                  v-can="'FpPreCon1'"
                  class="seepreconsultation-button"
                  @click="
                    goTo(
                      `/pacientes/${appointment.patient_id}/pre-consulta/${appointment?.pre_consultation?.id}`
                    )
                  "
                >
                  <Calendar class="icon fill" />
                </button>
                <InfoWarning 
                  v-b-tooltip.hover title="Pré-consulta idisponível. Por favor, atualize o procedimento excluído no agendamento para habilitá-lo e realizar a Pré-consulta."
                  v-if="appointment?.appointment_items.find(a => a?.item?.deleted_at !== null)" class="icon stroke pt-2 pl-2" 
                />
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <NoFilterResults
        v-if="!appointments.length && !isLoading"
        message="Os filtros aplicados não geraram nenhum resultado"
      />
      <div class="pagination-position">
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          first-number
          last-number
          size="sm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { statusOptions } from '@/utils/appointmentHelper'
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PreConsultation',
  metaInfo: {
    title: 'Eyecare - Pré consultas'
  },
  components: {
    Avatar: () => import('@/components/General/Avatar'),
    Calendar: () => import('@/assets/icons/calendar.svg'),
    Export: () => import('@/assets/icons/export.svg'),
    Search: () => import('@/assets/icons/search.svg'),
    NoFilterResults: () => import('@/components/General/noFilterResults'),
    MultiSelectPriority: () =>
      import('@/modules/schedule/components/MultiSelectPriority'),
    PatientInformation: () => import('@/components/Overview/ScheduleTablePatientInformation'),
    LimitedMultipleSelect: () =>
      import('@/components/General/LimitedMultipleSelect'),
      InfoWarning: () => import('@/assets/icons/info-circle-warning.svg'),
  },

  async created() {
    await this.loadFilters()
    this.getAllDoctors()
    this.getDayAppointments()
  },
  computed: {
    ...mapGetters('preConsultations', ['filters'])
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      query: '',
      selectedStatuses: [],
      selectedDoctors: [],
      peopleImageUrl: null,
      appointments: [],
      doctors: [],
      priorityLevels: [],
      doctorsOptions: [],
      statusArray: [
        { name: 'Pré-consulta pendente', value: 'pending' },
        { name: 'Pré-consulta realizada', value: 'finished' }
      ],
      page: 1,
      count: 0,
      limit: 0,
      schedulingStatusValue: null,
      appointmentStatus: null,
      schedulingStatus: statusOptions.slice(0),
      selectedAppointmentStatuses: [],
      isLoading: false
    }
  },
  methods: {
    ...mapActions('preConsultations', ['updateFilters']),
    goTo(path) {
      this.$router.push(path)
    },
    async getForm() {
      try {
        const response = await this.api.getForms(
          this.clinic.id,
          this.user.id,
          'PRE_CONSULTATION'
        )
        const form = response.data.find(el => {
          return el.name === 'Pré-consulta'
        })
        return form.id
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async startPreConsultation(appointment) {
      const isLoading = this.$loading.show()
      try {
        const formId = await this.getForm()
        const response = await this.api.startAttendance(
          this.clinic.id,
          appointment.patient_id,
          appointment.id,
          formId,
          'PRE_CONSULTATION'
        )
        this.$router.push(
          '/pacientes/' +
            appointment.patient_id +
            '/pre-consulta/' +
            response.data.id
        )
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    openMedicalRecord(appointment) {
      window.open(`/pacientes/${appointment.patient_id}`, '_blank')
    },
    getDayAppointments() {
      const isLoading = this.$loading.show()
      const params = {
        clinicId: this.clinic.id,
        page: this.page,
        query: this.query,
        status: this.status,
        doctorIds: this.selectedDoctors.map(el => el.id),
        appointmentStatuses: this.selectedAppointmentStatuses.map(el => el.value),
        statuses: this.selectedStatuses.map(el => el.value),
        priorityLevel: this.priorityLevels.map(el => el.value)
      }
      this.api
        .getDayAppointments(params)
        .then(res => {
          this.appointments = res.data.data

          this.appointments.map(app => {
            const preConsultation = app.attendance.find(
              att => att.type === 'PRE_CONSULTATION'
            )
            this.$set(app, 'pre_consultation', preConsultation)
          })

          this.count = res.data.total
          this.limit = res.data.per_page
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    getAllDoctors() {
      const isLoading = this.$loading.show()
      this.api
        .getClinicDoctors(this.clinic.id)
        .then(res => {
          this.doctorsOptions = res.data.doctors
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    changeSelectedAppointmentStatuses(selectedAppointmentStatuses) {
      if(selectedAppointmentStatuses === null) {
        this.selectedAppointmentStatuses = []
      } else {
        this.selectedAppointmentStatuses = selectedAppointmentStatuses
      }
      this.updateFiltersState()
      this.getDayAppointments()
    },
    changeSelectedPreConsultationStatuses(selectedStatuses) {
      this.selectedStatuses = selectedStatuses
      this.updateFiltersState()
      this.getDayAppointments()
    },
    changeSelectedDoctors(selectedDoctors) {
      this.selectedDoctors = selectedDoctors
      this.updateFiltersState()
      this.getDayAppointments()
    },
    updateFiltersState() {
      this.updateFilters({
        query: this.query,
        selectedAppointmentStatuses: this.selectedAppointmentStatuses,
        selectedStatuses: this.selectedStatuses,
        selectedDoctors: this.selectedDoctors
      })
    },
    async loadFilters() {
      this.query = this.filters.query
      this.selectedAppointmentStatuses =
        this.filters.selectedAppointmentStatuses
      this.selectedStatuses = this.filters.selectedStatuses
      this.selectedDoctors = this.filters.selectedDoctors
    }
  },
  watch: {
    query: function () {
      this.getDayAppointments()
    },
    page: function () {
      this.getDayAppointments()
    },
    status: function () {
      this.getDayAppointments()
    },
    doctor: function (newValue, oldValue) {
      if (!newValue && oldValue) {
        this.doctorId = null
        this.getDayAppointments()
      }
    },
    doctorId: function () {
      this.getDayAppointments()
    },
    appointmentStatus: function () {
      this.getDayAppointments()
    },
    priorityLevels: function () {
      this.getDayAppointments()
    }
  }
}
</script>

<style lang="scss" scoped>
.selectedOptions {
  width: 100%;
  display: flex;
  flex-direction: row;
  line-height: 100%;
  overflow: hidden;
  text-align: start !important;
  height: 50% !important;
}
button:disabled,
button[disabled] {
  background: #305bf2;
  border-radius: 8px;
  padding: 8px 16px;
  color: white;
  border: none;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  opacity: 50%;
}
.preconsultation-data {
  padding: 30px 24px;
  text-align: left;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .form-group {
      margin-bottom: 0 !important;
    }
    .input {
      padding: 0 3px !important;
    }

    .patient-input {
      position: relative;
      input {
        text-indent: 30px;
      }
    }

  }
  *:focus {
    outline: none;
  }

  .preconsultation-button {
    background: #305bf2;
    border-radius: 8px;
    padding: 8px 16px;
    color: white;
    border: none;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;

    &:hover {
      background: #224bdf;
    }
  }

  .seepreconsultation-button {
    width: 48px;
    height: 40px;
    background: #e7ecfe;
    border-radius: 8px;
    border: none;

    &:hover {
      background: #d7ddf5;
    }

    .icon {
      width: 18px;
      transition: all 0ms;

      &.fill {
        fill: #305bf2;
      }

      &.stroke {
        stroke: #305bf2;
      }
    }
  }

  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
    margin-bottom: 27px;
  }

  .ellipsis {
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .content {
    background-color: var(--neutral-000);
    border-radius: 8px;
    margin-top: 25px;
    min-height: calc(100vh - 250px);

    .table {
      width: 100%;
      display: table;
      padding-left: 24px;

      thead {
        tr {
          width: 100%;

          th {
            font-weight: 700;
            font-size: 16px;
            color: var(--dark-blue);
            border-top: 0;
            padding: 16px 0;
            border-bottom: 1px solid #d9dff2;
          }
        }
      }

      tbody {
        tr {
          padding: 0 24px;
          border-bottom: 1px solid #d9dff2;

          &:last-child {
            border-bottom: 0;
          }

          &:hover {
            background-color: var(--neutral-100);
          }
        }

        td {
          font-weight: 400;
          font-size: 16px;
          color: var(--type-active);
          border-top: 1px solid #d9dff2;
          padding: 16px 0;

          &:last-child {
            padding-right: 24px;
          }

          .doctor-container {
            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: center;

            .avatar {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }

            .professional-name {
              max-width: 65%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .pagination-position {
      width: 100%;
      display: inline-flex;
      justify-content: end;
    }
  }

  #search {
    position: absolute;
    height: 24px;
    width: 24px;
    top: 7px;
    left: 12px;
    stroke: var(--neutral-400);
  }

  .medical-record {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: var(--blue-500);

    .medical_record_number {
      cursor: pointer;
      margin-bottom: 0;
    }

    .medical_record_icon {
      cursor: pointer;
    }
  }

  .export {
    width: 24px;
    height: 24px;
    margin-top: -4px;
    margin-left: 5px;
  }

  .inline {
    max-width: 22ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex !important;
    align-items: center;
    padding-top: 5px;

    p {
      width: 20ch;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 32px 0 0;
    }
  }

  @media (max-width: 820px) {
    .row {
      gap: 8px;

      .right-inputs {
        gap: 8px;
      }
    }

    #search {
      top: 8px;
      left: 6px;
      width: 20px;
      height: 20px;
    }

    #patient-input input {
      width: 270px;
      text-indent: 18px;
    }

    .content {
      overflow: scroll;
      .table {
        thead {
          tr {
            .time {
              min-width: 80px;
            }
            .name {
              width: 80px !important;
              min-width: 100px;
            }
            .medical_record {
              min-width: 130px;
            }
            .birthdate {
              min-width: 170px;
            }
            .doctor {
              min-width: 130px;
            }
          }
        }
        tbody {
          tr {
            .name {
              .avatar-container {
                .avatar-container {
                  display: none;
                }
              }
            }
            .medical_record {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .doctor {
              .doctor-container {
                .avatar-container {
                  display: none;
                }
              }
            }
            .actions button {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
