const moduleRoute = {
  path: '/nota-fiscal',
  component: () => import('@/views/Home.vue'),
  children: [
    {
      path: '',
      component: () => import('./views/Home.vue'),
      children: [
        { path: '', component: () => import('./views/Dashboard.vue') },
        { path: 'dashboard', component: () => import('./views/Dashboard.vue') },
        { path: 'fluxo_de_nfe', component: () => import('./views/NFflow.vue') },
        {
          path: 'contas',
          component: () => import('./views/Register.vue')
        },
        {
          path: 'cadastros',
          component: () => import('./views/Register.vue')
        }
      ]
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
